import { DropdownOption, IState } from 'interfaces'
import { createSelector } from 'reselect'
import { Tax } from '..'

export const getTaxObj = (state: IState) => state.taxes.taxes
const getTaxIDs = (state: IState) => state.taxes.allIDs

export const getTaxes$ = createSelector(
  [getTaxObj, getTaxIDs],
  (taxObj, ids): Tax[] => {
    const taxes = []

    for (const id of ids) {
      taxes.push(taxObj[id])
    }

    return taxes
  },
)

export const getTaxesAsOptions$ = createSelector(
  [getTaxObj, getTaxIDs],
  (taxObj, ids): DropdownOption[] => {
    const taxes = []

    for (const id of ids) {
      const tax = taxObj[id]
      taxes.push({
        id: tax.id,
        key: tax.id,
        text: `${tax.name} (${tax.percentage}%)`,
        value: tax.id,
      })
    }

    return taxes
  },
)
