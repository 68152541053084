import { authGet } from 'lib/http-request'
import { Client } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { fetchClientSoapsSuccess } from 'containers/soap-notes/redux/fetch-soaps'
import { fetchClientDetailsSuccess } from '../client-info'
import { fetchAppointmentsForClientSuccess } from 'containers/appointments'
import {
  IAppointment,
  IClient,
  ClientsState,
  IAction,
  IResponse,
} from 'interfaces'
import { fetchTreatmentsSuccess } from 'containers/treatment'

const clientAction = (action: string) => `client-trackyr/clients/${action}`
const FETCH_CLIENTS_FOR_DAY = clientAction('FETCH_TODAYS_CLIENT')
const FETCH_CLIENTS_FOR_DAY_SUCCESS = clientAction(
  'FETCH_TODAYS_CLIENTS_SUCCESS',
)
const FETCH_CLIENTS_FOR_DAY_ERROR = clientAction('FETCH_CLIENTS_FOR_DAY_ERROR')
const FETCH_CLIENTS_FOR_DAY_NO_CLIENTS = clientAction(
  'FETCH_CLIENTS_FOR_DAY_ERROR',
)

export const defaultState = {} as ClientsState

export const reducer = (
  state: ClientsState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case FETCH_CLIENTS_FOR_DAY_SUCCESS:
      return {
        ...state,
        todaysClients: action.todaysClients,
        loadingTodaysClients: false,
        todaysClientErrors: '',
      }

    case FETCH_CLIENTS_FOR_DAY_ERROR:
      return {
        ...state,
        loadingTodaysClients: false,
        todaysClientErrors: action.error,
      }

    case FETCH_CLIENTS_FOR_DAY:
      return {
        ...state,
        loadingTodaysClients: true,
      }

    case FETCH_CLIENTS_FOR_DAY_NO_CLIENTS:
      return {
        ...state,
      }

    default:
      return state
  }
}

export const fetchTodaysClients = (clientIDs: Array<string>): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_CLIENTS_FOR_DAY, clientIDs })

  if (clientIDs.length === 0) {
    dispatch({
      type: FETCH_CLIENTS_FOR_DAY_NO_CLIENTS,
      msg: 'No ClientIDs Specified',
    })
    return
  }

  return authGet(Client.TodaysClients(clientIDs.join(',')))
    .then((response: IResponse<Array<ITodayClient>>) => {
      dispatch(fetchClientsForDaySuccess(response.data))

      for (const result of response.data) {
        if (result.client) {
          dispatch(fetchClientDetailsSuccess(result.client))
        }

        if (result.notes) {
          dispatch(
            fetchClientSoapsSuccess(
              { items: result.notes },
              result.client.id,
              1,
            ),
          )
        }

        if (result.appointments) {
          dispatch(
            fetchAppointmentsForClientSuccess(
              result.appointments,
              result.client.id,
            ),
          )
        }

        if (result.treatments) {
          dispatch(fetchTreatmentsSuccess(result.treatments, result.client.id))
        }
      }
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data === 'Email Address Not Verified'
      ) {
        return
      }

      captureMessage(`fetchClientsForDay Failed. ${error}`, Severity.Error)
      dispatch(fetchClientsForDayError(error.message))
    })
}

const fetchClientsForDaySuccess = (todaysClients: any) => ({
  type: FETCH_CLIENTS_FOR_DAY_SUCCESS,
  todaysClients,
})

const fetchClientsForDayError = (error: any) => ({
  type: FETCH_CLIENTS_FOR_DAY_ERROR,
  error,
})

interface ITodayClient {
  client: IClient
  notes: Array<any> // TODO - Array<INote>
  appointments: Array<IAppointment>
  treatments: Array<any> // TODO - ITreatment
}
