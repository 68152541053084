import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Text, Icon, Segment, Label } from 'components/ui'
import { Audit as IAudit, TableName } from './interfaces'
import {
  getAuditListIcon,
  getAuditListIconColour,
  getAuditListName,
  getAuditReason,
  getAuditTableName,
} from './utils'
import { useSelector } from 'react-redux'
import { getCTUser$ } from 'containers/account'
import { IClient, IUser } from 'interfaces'
import { AppointmentValue } from './value-views/appointments-value'
import { RawAudit } from './value-views/raw'
import { PublicIntakeValue } from './value-views/public-intake-value'
import { ClientValue } from './value-views/client-value'
import { TreatmentValue } from './value-views/treatment-value'
import { NotesValue } from './value-views/notes-value'
import { FilesValue } from './value-views/files-value'
import { getClientFullName } from 'containers/clients'
import { EmailValue } from './value-views/email-value'
import { SMSValue } from './value-views/sms-value'

const AuditContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`

interface ALIProps {
  opened: boolean
}

const AuditListItem = styled.div<ALIProps>`
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      !props.opened && props.theme.BackgroundColourHover};
  }
`

interface Props {
  audit: IAudit
  client?: IClient
}

export const Audit = ({ audit, client }: Props) => {
  const user: IUser = useSelector(getCTUser$)
  const [opened, setOpened] = useState(false)
  const onClick = () => setOpened(!opened)

  const auditUserID =
    user.id === audit.userID ? `${user.email} (you)` : audit.userID
  const auditUserIDShort = user.id === audit.userID ? '(you)' : audit.userID

  const getValue = (auditValue: string) => {
    switch (audit.tableName) {
      case TableName.Clients:
        return (
          <>
            {!auditValue && <Label>Client Details</Label>}
            <ClientValue value={auditValue} />
          </>
        )
      case TableName.Appointments:
        return (
          <>
            {!auditValue && <Label>Appointment Details</Label>}
            <AppointmentValue value={auditValue} />
          </>
        )
      case TableName.PublicIntake:
        return (
          <>
            {!auditValue && <Label>Public Intake Details</Label>}
            <PublicIntakeValue value={auditValue} />
          </>
        )
      case TableName.Treatments:
        return <TreatmentValue value={auditValue} />
      case TableName.Notes:
        return <NotesValue audit={audit} value={auditValue} />
      case TableName.Files:
        return <FilesValue value={auditValue} />
      case TableName.Email:
        return <EmailValue value={auditValue} />
      case TableName.SMS:
        return <SMSValue value={auditValue} />
      default:
        return <>{audit.value && <Text>{`Value: ${auditValue}`}</Text>}</>
    }
  }

  return (
    <AuditListItem opened={opened}>
      <AuditContainer onClick={onClick}>
        <div style={{ display: 'flex' }}>
          <Icon
            color={getAuditListIconColour(audit.type)}
            name={getAuditListIcon(audit.type)}
            size="large"
          />

          <div>
            <Text fontSize={16} fontWeight={600}>
              {`${getAuditListName(audit.type)} (${getAuditTableName(
                audit.tableName,
              )}) - ${getClientFullName(client)} - ${auditUserIDShort}`}
              &nbsp;
            </Text>
          </div>
        </div>

        <Text>{moment(audit.dateTime).format('MMM DD YYYY h:mm:ss a')}</Text>
      </AuditContainer>

      {opened && (
        <Segment>
          {audit.value && getValue(audit.value)}

          {audit.prevValue && <Label>Previous Value</Label>}
          {audit.prevValue && getValue(audit.prevValue)}

          <Text>{`User: ${auditUserID}`}</Text>
          {typeof audit.reason !== 'undefined' && <Text>{getAuditReason(audit.reason)}</Text>}
          <RawAudit audit={audit} />
        </Segment>
      )}
    </AuditListItem>
  )
}
