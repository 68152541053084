import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	Button,
	ButtonContainer,
	FinePrint,
	Form,
	Header,
	Modal,
} from 'components/ui'
import { Input, TextArea, InputNumber } from 'components/forms'
import { formatTaxAmount } from 'lib'
import { Dispatch, IEvent, IResponse, IState } from 'interfaces'

import { Tax } from '../interfaces'
import { postTax } from '../redux/post-tax'
import { deleteTax } from '../redux/delete-tax'
import { ConfirmCancelModal } from 'components/modals'
import { fetchTaxes } from '../redux/fetch-taxes'

interface Props {
	open: boolean
	tax: Tax
	onClose: () => void
}

export const TaxModal = (props: Props) => {
	const dispatch: Dispatch = useDispatch()
	const [isDirty, setIsDirty] = useState(false)
	const [taxData, setTaxData] = useState({} as Tax)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const onCloseConfirm = () => {
		setConfirmOpen(false)
	}
	const onOpenConfirm = () => {
		setConfirmOpen(true)
	}

	const posting = useSelector((state: IState) => state.taxes.flags.posting)
	const deleting = useSelector((state: IState) => state.taxes.flags.deleting)

	const { tax } = props
	useEffect(() => {
		if (tax?.id) {
			setTaxData({ ...tax })
		}
	}, [tax])

	const { onClose } = props

	const onCloseCallback = useCallback(() => {
		setTaxData({} as Tax)
		onClose()
	}, [onClose])

	const onSubmit = useCallback(() => {
		dispatch(postTax(taxData)).then((response: IResponse<Tax>) => {
			if (response.status === 200) {
				onCloseCallback()
				dispatch(fetchTaxes())
			}
		})
	}, [dispatch, onCloseCallback, taxData])

	const id = tax?.id
	const onDelete = useCallback(() => {
		dispatch(deleteTax(id)).then((response: IResponse<any>) => {
			if (response.status === 204) {
				onCloseCallback()
			}
		})
	}, [dispatch, onCloseCallback, id])

	if (!props.open) {
		return null
	}

	const disabled = !taxData.name || !taxData.percentage

	const onChange = ({ target }: IEvent) => {
		setIsDirty(true)
		setTaxData((prev) => ({ ...prev, [target.name]: target.value }))
	}

	const onPercentageFormat = ({ target }: IEvent) => {
		if (!target.value) {
			return
		}

		target.value = formatTaxAmount(target.value)
		onChange({ target })
	}

	return (
		<>
			<ConfirmCancelModal
				message="Delete this Tax?"
				modalOpen={confirmOpen}
				onAccept={onDelete}
				onCancel={onCloseConfirm}
			/>

			<Modal open={props.open}>
				<Modal.Header icon="wrench">
					<Header icon="wrench">{props.tax.id ? 'Edit Tax' : 'New Tax'}</Header>
				</Modal.Header>

				<Modal.Content>
					<Form>
						<Input
							label="Tax Name"
							name="name"
							onChange={onChange}
							validationRules={{ required: true }}
							value={taxData.name}
						/>

						<InputNumber
							inputLabel={{
								basic: true,
								content: '%',
							}}
							label="Price"
							name="percentage"
							onBlur={onPercentageFormat}
							onChange={onChange}
							placeholder="%, eg: 19 for 19%"
							validationRules={{ required: true }}
							value={taxData.percentage}
						/>

						<Input
							label="Tax Number"
							name="taxNumber"
							onChange={onChange}
							placeholder="Tax Number, ex) GST#12345"
							value={taxData.taxNumber}
						/>
						<FinePrint>Appears under subtotal</FinePrint>

						<TextArea
							label="Details"
							name="details"
							onChange={onChange}
							placeholder="Extra details for your reference."
							value={taxData.details}
						/>
					</Form>
				</Modal.Content>

				<Modal.Actions>
					<ButtonContainer>
						{tax.id && (
							<Button
								content="Delete"
								icon="trash"
								loading={deleting}
								negative
								onClick={onOpenConfirm}
							/>
						)}

						<div>
							<Button
								content={isDirty ? 'Cancel' : 'Close'}
								icon="arrow alternate circle left"
								onClick={onCloseCallback}
								secondary
							/>
							<Button
								content="Save"
								disabled={disabled}
								icon="checkmark"
								loading={posting}
								onClick={onSubmit}
								primary
							/>
						</div>
					</ButtonContainer>
				</Modal.Actions>
			</Modal>
		</>
	)
}
