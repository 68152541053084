import React from 'react'

import { ReadonlyFormFont } from 'styles'
import { Divider, Line } from '.'

interface Props {
  value: any
  height?: number
  multiLines?: boolean
}

export const PrintableInputs = ({
  value,
  height = 1,
  multiLines = false,
}: Props) => {
  const getMultiLines = () => {
    if (height <= 1) {
      return null
    }

    const divider = []

    for (let i = 0; i < height - 1; i++) {
      divider.push(<Line key={i} style={{ marginTop: '2em' }} />)
    }

    return divider
  }

  const getSpacing = () => {
    const divider = []

    for (let i = 0; i < height - 1; i++) {
      divider.push(<Divider hidden />)
    }

    return divider
  }

  return (
    <>
      <ReadonlyFormFont style={{ paddingBottom: 0 }}>
        {value || ''}
      </ReadonlyFormFont>

      {!value && !multiLines && getSpacing()}
      {!value && multiLines && getMultiLines()}

      {height > 1 && <Divider hidden />}
      {!value && (
        <Line
          style={{
            marginBottom: '1em',
            marginTop: height === 1 ? '2em' : '',
          }}
        />
      )}
    </>
  )
}
