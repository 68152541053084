import React from 'react'
import styled from 'styled-components'
import { Font, LinkFont, SFont } from 'styles/font'

interface IFont {
  colour?: string
  fontSize?: number
  fontWeight?: number
}
export const ErrorText = styled.p`
  ${SFont};
  color: ${(props) => props.theme.ErrorTextColour};
`

export const Text = styled.p<IFont>`
  ${Font};
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};
  word-wrap: wrap;
`

export const UnderlinedText = styled.u<IFont>`
  ${Font};
  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};
`

export const SelectableText = styled.p<IFont>`
  ${Font};
  ${(props) => props.colour && `color: ${props.colour}`};

  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

interface ILinkText {
  onClick?: Function | any
  href?: string
}

export const LinkText = styled.p<IFont & ILinkText>`
  ${LinkFont};

  ${(props) => props.fontSize && `font-size: ${props.fontSize}px`};
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight}`};

  :hover {
    cursor: pointer;
  }
`

export const ImportantText = styled.p`
  ${Font};

  font-size: 16px;
  font-weight: 800;
`

export const FinePrint = styled.p`
  ${Font};

  font-size: 10px;

  :hover {
    font-size: 15px;
  }
`

export const SubText = styled.p`
  ${Font};

  font-size: 12px;
`

export const S = styled.s`
  ${Font};
`

interface StrikethroughProps {
  toggle?: boolean
  children: React.ReactNode
}
export const Strikethrough = (props: StrikethroughProps) => (
  <>{props.toggle ? <S>{props.children}</S> : props.children}</>
)
