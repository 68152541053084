import styled from 'styled-components'
import { MobileBreakPoint } from './constants'

interface IAppLayout {
  authenticated: boolean
  sidebarCollapsed: boolean
}

const AppContainer = styled.div`
  background-color: ${(props) => props.theme.BackgroundColour};
`

const AppLayout = styled.div<IAppLayout>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.authenticated
      ? props.sidebarCollapsed
        ? '4.0em auto'
        : '10em auto'
      : '0 auto'};
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  grid-template-areas:
    'sidebar header'
    'sidebar content'
    'footer footer';

  @media (max-width: ${MobileBreakPoint}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 0 1fr auto;

    grid-template-areas:
      'header'
      'sidebar'
      'content'
      'footer';
  }

  @media print {
    display: initial;
    height: initial !important;
    overflow: initial !important;
  }

  background-color: ${(props) => props.theme.BackgroundColour};
  color: ${(props) => props.theme.TextColour};
`

const AppHeader = styled.div`
  background: ${(props) => props.theme.BackgroundColour};
  padding: 0;
  height: auto;
  margin: 0;
  line-height: normal;
  grid-area: header;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;

  @media print {
    display: none;
    visibility: hidden;
  }
`

const AppContent = styled.div`
  grid-area: content;

  @media print {
    display: initial;
    height: initial;
    overflow: initial;
  }
`

const AppSideBar = styled.div`
  position: fixed;
  top: 0;
  grid-area: sidebar;
  z-index: 8;

  @media print {
    display: none;
    visibility: hidden;
  }

  @media (max-width: ${MobileBreakPoint}) {
    visibility: hidden;
    height: 0;
    max-height: 0;
  }
`

const AppSideBarMobile = styled.div`
  background-color: ${(props) => props.theme.BackgroundColour};
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  background-color: ${(props) => props.theme.BackgroundColour};
`

const Sticky = styled.div`
  display: inline;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  @media print {
    display: none;
    visibility: hidden;
  }
`

export {
  AppContainer,
  AppLayout,
  AppHeader,
  AppContent,
  AppSideBar,
  AppSideBarMobile,
  Sticky,
}
