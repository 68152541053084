import { authPost } from 'lib/http-request'
import { Emails } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, EmailState, Dispatch } from 'interfaces'

export const defaultState = {
  flags: {
    sendingAppointmentEmail: false,
    sendingAppointmentEmailError: {},
  },
}

const emailsAction = (action: string) => `client-trackyr/emails/${action}`
const SEND_APPOINTMENT_EMAIL = emailsAction('SEND_APPOINTMENT_EMAIL')
const SEND_APPOINTMENT_EMAIL_SUCCESS = emailsAction(
  'SEND_APPOINTMENT_EMAIL_SUCCESS',
)
const SEND_APPOINTMENT_EMAIL_FAILURE = emailsAction(
  'SEND_APPOINTMENT_EMAIL_FAILURE',
)

export const reducer = (
  state = defaultState as EmailState,
  action: IAction,
) => {
  switch (action.type) {
    case SEND_APPOINTMENT_EMAIL:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentEmail: true,
          sendingAppointmentEmailError: {},
        },
      }

    case SEND_APPOINTMENT_EMAIL_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentEmail: false,
          sendingAppointmentEmailError: {},
        },
      }

    case SEND_APPOINTMENT_EMAIL_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentEmail: false,
          sendingAppointmentEmailError: { ...action.error },
        },
      }

    default:
      return state
  }
}

export const sendAppointmentEmail = (
  appointmentID: string,
  showNotification = false,
): any => async (dispatch: Dispatch): Promise<any> => {
  dispatch({ type: SEND_APPOINTMENT_EMAIL, appointmentID, showNotification })

  return authPost(Emails.AppointmentReminder, { appointmentID })
    .then((response) => {
      dispatch(onSendSuccess())
      showNotification && message.success('Email Sent!')
      return response
    })
    .catch((error) => {
      showNotification &&
        message.error(
          'Failed to Send Appointment Email, please try again later',
        )
      captureMessage(`Failed to Send Email: ${error}`, Severity.Error)
      dispatch(onSendFailure(error))

      return error
    })
}

const onSendSuccess = () => ({ type: SEND_APPOINTMENT_EMAIL_SUCCESS })

const onSendFailure = (error: any) => ({
  type: SEND_APPOINTMENT_EMAIL_FAILURE,
  error,
})
