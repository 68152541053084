import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Checkbox } from 'components/ui'
import { getHumanReadableFeatures } from 'constants/features'

import { setFeature } from './redux/set-feature'
import { IState, Feature, Dispatch } from 'interfaces'

export const FeatureToggleCheckbox = (props: IFeatureToggleCheckbox) => {
	const dispatch: Dispatch = useDispatch()

	const settingFeature = useSelector(
		(state: IState) => state.features.settingFeature,
	)

	const setFeatureCallback = useCallback(
		(value) => {
			dispatch(
				setFeature({
					userID: props.userID,
					key: props.label,
					value: value,
				}),
			)
		},
		[dispatch, props],
	)

	return (
		<div>
			<Checkbox
				checked={props.value}
				disabled={settingFeature}
				label={getHumanReadableFeatures(props.label)}
				onChange={({ target }) => setFeatureCallback(target.value)}
				toggle
			/>
		</div>
	)
}

interface IFeatureToggleCheckbox {
	label: Feature
	userID: string
	value: boolean
}
