import React, { useState } from 'react'
import styled from 'styled-components'

import {
  Popup,
  Button,
  Label,
  ColourBlock,
  ColourPicker,
  Icon,
} from 'components/ui'
import {
  CALENDAR_COLOURS,
  DEFAULT_EVENT_COLOUR,
} from 'constants/calendar-colours'
import { IEvent } from 'interfaces'

const ColourPickerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1em;
  max-width: 20em;
`

const ColourPickerTrigger = styled.div`
  border: 1px solid ${(props) => props.theme.InputBorderColour};
  border-radius: 5px;

  display: flex;
  align-items: center;

  padding: 0.25em 0.5em;
  margin-left: 1em;

  :hover {
    cursor: pointer;
  }
`

interface Props {
  colour: string
  disabled?: boolean
  onChange: Function
}

export const FormTypeColourPicker = (props: Props) => {
  const [colourPopupOpen, setColourPopupOpen] = useState(false)

  return (
    <Popup
      content={
        <div>
          <ColourPicker
            defaultColour={DEFAULT_EVENT_COLOUR}
            defaultColours={CALENDAR_COLOURS}
            onChange={(e: IEvent) =>
              props.onChange({
                target: {
                  name: 'appointmentColour',
                  value: e.target.value,
                },
              })
            }
            value={props.colour}
          />
          <Button
            disabled={props.disabled}
            icon="checkmark"
            onClick={() => setColourPopupOpen(false)}
            primary
            style={{ marginLeft: '87.5%' }}
          />
        </div>
      }
      flowing
      hoverable
      inverted
      on="click"
      onOpen={() => !props.disabled && setColourPopupOpen(true)}
      open={colourPopupOpen}
      position="bottom left"
      trigger={
        <ColourPickerContainer>
          <Label>Appointment Colour: </Label>
          <ColourPickerTrigger onClick={() => setColourPopupOpen(true)}>
            <ColourBlock
              colour={props.colour || DEFAULT_EVENT_COLOUR}
              onClick={() => setColourPopupOpen(true)}
              size="1em"
              style={{ padding: '0.25em' }}
            />
            <Icon
              name="angle down"
              onClick={() => !props.disabled && setColourPopupOpen(true)}
              size="large"
            />
          </ColourPickerTrigger>
        </ColourPickerContainer>
      }
      wide="very"
    />
  )
}
