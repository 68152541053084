import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/ui'

const SubmitButton = styled(Button)`
  width: 100%;
`

const SubmitAuthButton = ({ content, isSubmitting, disabled }: Props) => (
  <SubmitButton
    disabled={disabled}
    loading={isSubmitting}
    primary
    size="large"
    type="submit"
  >
    {content}
  </SubmitButton>
)

interface Props {
  content: string
  isSubmitting?: boolean
  disabled?: boolean
}

export default SubmitAuthButton
