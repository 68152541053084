import React from 'react'
import moment from 'moment'
import {
  ActivableListItem,
  ListContent,
  ListHeader,
  ListDescription,
  Icon,
  ListIcon,
  Strikethrough,
} from 'components/ui'
import { IAppointment } from 'interfaces'
import { AppointmentStatus, AppointmentType } from 'containers/appointments'

const getIcon = (status: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.Cancelled:
      return 'calendar times'
    case AppointmentStatus.Deleted:
      return 'trash'
    case AppointmentStatus.NoShow:
    case AppointmentStatus.CancelledTooLate:
      return 'x'
    case AppointmentStatus.Supervision:
      return 'pencil alternate'
    case AppointmentStatus.Unknown:
      return 'question circle'
    default:
      return 'calendar'
  }
}

const getTherapistIcon = (status: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.Supervision:
      return 'pencil alternate'

    default:
      return 'calendar'
  }
}

const Appointment = (props: IAppointmentProps) => {
  const cancelled =
    props.appointment.status === AppointmentStatus.Cancelled ||
    props.appointment.status === AppointmentStatus.NoShow ||
    props.appointment.status === AppointmentStatus.CancelledTooLate

  const appointmentNumber = props.appointmentNumber
    ? `#${props.appointmentNumber}: `
    : ''
  const start = moment(props.start).format('MMMM D YYYY')
  const isDirty = props.isDirty ? ' * ' : ''
  const icon =
    props.appointment.appointmentType === AppointmentType.Therapist
      ? getTherapistIcon(props.appointment.status)
      : getIcon(props.appointment.status)
  const extra =
    props.appointment.status === AppointmentStatus.Supervision
      ? ' (Supervision)'
      : ''

  return (
    <ActivableListItem
      active={props.active}
      onClick={() => props.onClick(props.appointment)}
    >
      {!props.hideIcon && (
        <ListIcon>
          <Icon name={icon} size="big" style={{ margin: 'auto' }} />
        </ListIcon>
      )}

      <ListContent>
        <ListHeader>
          <Strikethrough toggle={cancelled}>
            {`${appointmentNumber}${start}${extra} ${isDirty}`}
          </Strikethrough>
        </ListHeader>
        <ListDescription>
          <Strikethrough toggle={cancelled}>{`${moment(props.start).format(
            'h:mma',
          )} - ${moment(props.end).format('h:mma')}`}</Strikethrough>
        </ListDescription>
      </ListContent>
    </ActivableListItem>
  )
}

interface IAppointmentProps {
  active: boolean
  appointment: IAppointment
  appointmentNumber: number
  end: string
  hideIcon: boolean
  isDirty: boolean
  onClick: Function
  start: string
}

Appointment.defaultProps = {
  displayClient: false,
  hideIcon: false,
}

export default Appointment
