import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Form, Header, Modal, PDFView } from 'components/ui'
import { Input } from 'components/forms'
import { getClients$ } from 'containers/clients'
import { Dispatch, IEvent, IResponse, IState } from 'interfaces'
import { Spinner } from 'components/loaders'
import { FeatureToggle } from 'containers/features'
import { CanEmailInvoices } from 'constants/features'
import { authGet, dataURLtoFile } from 'lib/http-request'
import { PDF } from 'constants/endpoints'

import { emailReceipt } from '../redux/email-receipt'
import { Receipt } from '../interfaces'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface Props {
	receipt: Receipt
	onClose: () => void
	open: boolean
}

export const ReceiptPDF = ({ open, onClose, receipt }: Props) => {
	const dispatch: Dispatch = useDispatch()
	const clients = useSelector(getClients$)
	const emailing = useSelector((state: IState) => state.receipts.flags.emailing)

	const clientID = receipt?.clientID
	const client = clientID ? clients[clientID] : null

	const [emailToggle, sendEmailToggle] = useState(false)
	const [email, setEmail] = useState(clients[clientID]?.email)
	const onToggleEmail = () => {
		sendEmailToggle(true)
		setEmail(client?.email)
	}
	const onEmailChanged = ({ target }: IEvent) => setEmail(target.value)
	const [fetchingPDF, setfetchingPDF] = useState(false)
	const [pdf, setPDF] = useState('')

	const sendAsEmail = async () => {
		dispatch(emailReceipt(email || client.email, receipt))
	}

	const receiptNumber = receipt?.receiptNumber
	useEffect(() => {
		if (open && receiptNumber) {
			fetchReceipt()
		}
	}, [receiptNumber, open])

	const fetchReceipt = async () => {
		try {
			setfetchingPDF(true)
			const response: IResponse<any> = await authGet(
				PDF.GetReceipt(receiptNumber),
			)

			setPDF(response.data)

			setfetchingPDF(false)
		} catch (ex) {
			setfetchingPDF(false)
		}
	}

	const onPrint = () => {
		const pdfFile = dataURLtoFile(pdf, 'receipt.pdf')
		const url = URL.createObjectURL(pdfFile)
		window.open(url)
	}

	if (!open) {
		return null
	}

	const close = () => {
		sendEmailToggle(false)
		setEmail('')
		setPDF('')
		onClose()
	}

	let scale = window.innerWidth / 675

	if (scale > 1.25) {
		scale = 1.25
	}

	return (
		<>
			<Modal closeIcon closeOnDimmerClick={false} onClose={close} open={open}>
				<Header content='Receipt' icon='print' />

				<Modal.Content>
					<Spinner size='large' spinning={fetchingPDF}>
						<FeatureToggle features={[CanEmailInvoices]}>
							<ButtonContainer>
								{emailToggle ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Form>
											<Input
												icon='mail'
												label='Email Address'
												name='email'
												onChange={onEmailChanged}
												placeholder='Clients Email'
												type='email'
												value={email || client.email}
											/>
										</Form>
										<Button
											content='Send'
											disabled={fetchingPDF}
											icon='mail'
											loading={emailing}
											onClick={sendAsEmail}
											primary
											style={{ marginLeft: '1em', marginTop: '1.7em' }}
										/>
									</div>
								) : (
									<div>
										<Button
											content='Email to Client'
											disabled={fetchingPDF}
											icon='mail'
											onClick={onToggleEmail}
											primary
											style={{ marginBottom: '1em' }}
										/>
									</div>
								)}
								<div>
									<Button
										content='Print'
										disabled={fetchingPDF}
										icon='print'
										onClick={onPrint}
										style={{
											marginBottom: '1em',
											marginTop: emailToggle ? '1.7em' : 0,
										}}
									/>
								</div>
							</ButtonContainer>
						</FeatureToggle>

						{pdf && (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<PDFView filePathUrl={pdf} firstPage scale={scale} />
							</div>
						)}
					</Spinner>
				</Modal.Content>

				<Modal.Actions>
					<ButtonContainer>
						<Button
							content='Close'
							icon='arrow alternate circle left'
							onClick={close}
							secondary
						/>
					</ButtonContainer>
				</Modal.Actions>
			</Modal>
		</>
	)
}
