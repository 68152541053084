import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import localforage from 'localforage'

import { ContainerLayout, Form, Card, Button } from 'components/ui'
import { Input, DatePicker, Dropdown } from 'components/forms'
import { IEvent, IForm } from 'interfaces'
import shortid from 'shortid'
import { CLIENT_TRACKYR_STORE, FORM_BUILDER } from 'constants/storage'

import { ComponentTools } from './component-tools'
import { FormSheet } from './form-sheet'
import { FormInfo, FormItemComponent } from './interfaces'
import { ConfirmCancelModal, FormPreviewModal } from 'components/modals'
import { generateJSON, getFromJSON } from './generate-json'
import { mapToTree } from './helpers'
import { message } from 'components/notifications'
import { useSelector } from 'react-redux'
import { getFormTemplate$ } from 'containers/forms/selectors'
import { uploadForm } from 'containers/account'
import moment from 'moment'
import { OrphanedData } from './components/orphaned-data'
import { MobileBreakPointMedium } from 'styles'
import { FeatureToggle } from 'containers/features'
import { CustomForms, GlobalCustomForms } from 'constants/features'

const store = localforage.createInstance({
	name: CLIENT_TRACKYR_STORE,
	storeName: FORM_BUILDER,
})

const FB_TEMP_CACHE = 'temp-form-save'
const FB_TEMP_CACHE_INFO = 'temp-form-save-info'

const FormBuilderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  max-height: 80vh;
  overflow-y: scroll;

  @media (max-width: ${MobileBreakPointMedium}) {
    flex-wrap: wrap-reverse;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 52.5em;
  margin-top: 2.75em;
`

const ToolsView = styled.div`
  width: 60em;
  max-width: 50%;
  max-height: 100vh;

  overflow-y: scroll;

  @media (max-width: ${MobileBreakPointMedium}) {
    width: 100%;
    max-width: 100%;
  }
`

interface Props {
	formID?: string
	formJson?: IForm
	onUploadSuccess: (formID: string, newID?: string) => void
	setChangesMade: () => void
}

export const FormBuilder = ({
															formID = null,
															formJson = null,
															onUploadSuccess,
															setChangesMade,
														}: Props) => {
	const FORM_BUILDER_TEMP_CACHE = formID
		? `${FB_TEMP_CACHE}:${formID}`
		: formJson
			? `${FB_TEMP_CACHE}:${formJson.id}`
			: FB_TEMP_CACHE
	const FORM_BUILDER_TEMP_CACHE_INFO = formID
		? `${FB_TEMP_CACHE_INFO}:${formID}`
		: formJson
			? `${FB_TEMP_CACHE_INFO}:${formJson.id}`
			: FB_TEMP_CACHE_INFO

	const [formItems, setFormItems] = useState([] as Array<any>)
	const [formInfo, setFormInfo] = useState({
		id: null,
		type: 'user',
		name: '',
		screen: 'client',
		dateCreated: null,
		dateModified: null,
		tab: 'any',
	} as FormInfo)
	const [orphanedData, setOrphanedData] = useState([] as any[])
	const [formPreviewModal, setFormPreviewModal] = useState({
		json: null as any,
		open: false,
	})
	const [clearModalOpen, setClearModalOpen] = useState(false)
	const [progress, setProgress] = useState(0)
	const existingForm = useSelector(getFormTemplate$(formID))

	const loadLocalSave = useCallback(async () => {
		const items: Array<FormItemComponent> = await store.getItem(
			FORM_BUILDER_TEMP_CACHE,
		)
		const info: FormInfo = await store.getItem(FORM_BUILDER_TEMP_CACHE_INFO)

		if (items && !formItems?.length) {
			setFormItems(items)
		}
		if (info && !formInfo) {
			setFormInfo(info)
		}
	}, [
		FORM_BUILDER_TEMP_CACHE,
		FORM_BUILDER_TEMP_CACHE_INFO,
		formInfo,
		formItems,
	])

	useEffect(() => {
		if (!formID) {
			loadLocalSave()
		}
	}, [loadLocalSave, formID])

	useEffect(() => {
		if (formID) {
			const existingItems: any = getFromJSON(existingForm)
			setFormItems(existingItems.children)
			setFormInfo({
				id: formID,
				type: existingForm.type,
				name: existingForm.name,
				screen: 'client',
				dateCreated: moment(existingForm.dateCreated),
				dateModified: moment(existingForm.dateModified),
				tab: existingForm.tab || 'any',
			})
			existingForm.orphanedData && setOrphanedData(existingForm.orphanedData)
		} else if (formJson) {
			const existingItems: any = getFromJSON(formJson)
			setFormItems(existingItems.children)
			setFormInfo({
				id: formID,
				type: formJson.type,
				name: formJson.name,
				screen: 'client',
				dateCreated: moment(formJson.dateCreated),
				dateModified: moment(formJson.dateModified),
				tab: formJson.tab || 'any',
			})
			existingItems.orphanedData && setOrphanedData(existingItems.orphanedData)
		}
	}, [formID, formJson, setFormItems, existingForm])

	useEffect(() => {
		if (formItems.length) {
			store.setItem(FORM_BUILDER_TEMP_CACHE, formItems)
		}
	}, [formItems, FORM_BUILDER_TEMP_CACHE])

	const onAddComponent = (formItemComponent: FormItemComponent) => {
		const item = mapToTree(formItemComponent)
		item.id = shortid.generate()
		setFormItems((prev) => [...prev, { ...item }])
		setChangesMade()
	}

	const onAddOrphanedDataBack = (node: any) => {
		const filteredOrphanData = orphanedData.filter(
			(o) => o.title !== node.title,
		)

		setFormItems((prev) => [...prev, node])
		setOrphanedData(filteredOrphanData)
		setChangesMade()
	}

	const onRemoveComponent = (node: any) => {
		if (formID) {
			setOrphanedData((prev: IForm[]) => {
				if (!prev || !Array.isArray(prev)) {
					return [node]
				}
				return [...prev, node]
			})
		}
		setChangesMade()
	}

	const onSetComponents = (components: Array<FormItemComponent>) => {
		setFormItems(components)
		store.setItem(FORM_BUILDER_TEMP_CACHE, components)
		setChangesMade()
	}

	const onFormInfoChange = ({ target }: IEvent) => {
		setFormInfo((prev) => ({ ...prev, [target.name]: target.value }))
		setChangesMade()
	}

	const onFormInfoChangeBlur = async () => {
		await store.setItem(
			FORM_BUILDER_TEMP_CACHE_INFO,
			JSON.parse(JSON.stringify(formInfo)),
		)
	}

	const onPreview = () => {
		const json = generateJSON(formInfo, formItems)
		setFormPreviewModal({
			open: true,
			json,
		})
	}

	const onFailure = () => {
		message.error('Failed to Save Form')
	}

	const saveSuccess = async (data: any) => {
		const id = data.id
		onUploadSuccess(formID, id)
		setFormInfo((prev) => ({
			...prev,
			id,
		}))
		await store.removeItem(FORM_BUILDER_TEMP_CACHE)
		await store.removeItem(FORM_BUILDER_TEMP_CACHE_INFO)
		message.success('Form Saved')
	}

	const onSave = () => {
		const formJSON = generateJSON(formInfo, formItems)
		const form: IForm = {
			...formInfo,
			...formJSON,
			dateModified: moment().format(),
			orphanedData,
		}

		const blob = new Blob([JSON.stringify(form, null, 2)], {
			type: 'application/json',
		})
		const file = new File([blob], `${form.id}`)

		uploadForm(file, form, setProgress, saveSuccess, onFailure)
	}

	const clearModal = async () => {
		if (formID) {
			setOrphanedData((prev: IForm[]) => {
				if (!prev || !Array.isArray(prev)) {
					return [...formItems]
				}
				return [...prev, ...formItems]
			})
		}

		setClearModalOpen(false)
		setFormItems([])
		setChangesMade()
		await store.removeItem(FORM_BUILDER_TEMP_CACHE)
	}

	const onSaveDisabled = () => {
		if (!formItems.length) {
			message.warning('You have no form items')
		}

		if (!formInfo.name) {
			message.warning('A form must have a name')
		}
	}

	return (
		<FeatureToggle features={[GlobalCustomForms, CustomForms]}>
			<FormPreviewModal
				fileJson={[formPreviewModal.json]}
				modalOpen={formPreviewModal.open}
				name={formInfo.name}
				onCancel={() => setFormPreviewModal({ json: null, open: false })}
			/>

			<ConfirmCancelModal
				content='This will remove all items you have in your form.'
				message='Clear Entire Form?'
				modalOpen={clearModalOpen}
				onAccept={clearModal}
				onCancel={() => setClearModalOpen(false)}
			/>

			<ContainerLayout more>
				<Card hideTitle>
					<FormBuilderContainer>
						<div style={{ width: '100%' }}>
							<FormSheet
								components={formItems}
								onRemoveComponent={onRemoveComponent}
								setComponents={onSetComponents}
							/>

							<OrphanedData
								onAddComponentBack={onAddOrphanedDataBack}
								orphanedData={orphanedData}
							/>
						</div>

						<ToolsView>
							<Card hideTitle>
								<Form>
									<Input
										label='Form Name'
										name='name'
										onBlur={onFormInfoChangeBlur}
										onChange={onFormInfoChange}
										placeholder='Name for your form'
										validation={{ required: true }}
										value={formInfo.name}
									/>

									<Dropdown
										label='Primary Purpose'
										name='tab'
										onChange={onFormInfoChange}
										options={purposeOptions}
										value={formInfo.tab}
									/>

									{formInfo.dateCreated && (
										<DatePicker
											format='MMMM D YYYY'
											label='Date Created'
											onChange={() => {
											}}
											readonly
											value={formInfo.dateCreated}
										/>
									)}

									{formInfo.dateModified && (
										<DatePicker
											format='MMMM D YYYY'
											label='Date Last Modified'
											onChange={() => {
											}}
											readonly
											value={formInfo.dateModified}
										/>
									)}
								</Form>
							</Card>

							<ComponentTools onAddComponent={onAddComponent} />
						</ToolsView>
					</FormBuilderContainer>

					<ButtonContainer>
						<Button
							content='Clear'
							disabled={!formItems.length}
							icon='x'
							onClick={() => setClearModalOpen(true)}
							secondary
						/>
						<Button
							content='Preview'
							disabled={!formItems.length}
							icon='eye'
							onClick={onPreview}
							primary
						/>
						<Button
							content='Save'
							disabled={!formInfo.name || !formItems.length}
							icon='checkmark'
							loading={progress !== 0 && progress !== 100}
							onClick={onSave}
							onClickDisabled={onSaveDisabled}
							primary
						/>
					</ButtonContainer>
				</Card>
			</ContainerLayout>
		</FeatureToggle>
	)
}

const purposeOptions = [
	{
		id: 'client-info',
		key: 'client-info',
		value: 'client-info',
		text: 'Client Information',
	},
	{
		id: 'notes',
		key: 'notes',
		value: 'notes',
		text: 'Notes',
	},
	{
		id: 'therapist',
		key: 'therapist',
		value: 'therapist',
		text: 'Therapist',
	},
	{
		id: 'report',
		key: 'report',
		value: 'report',
		text: 'Report',
	},
	{
		id: 'misc',
		key: 'misc',
		value: 'misc',
		text: 'Miscellaneous',
	},
	{
		id: 'any',
		key: 'any',
		value: 'any',
		text: 'Anything',
	},
]
