import React, { useState } from 'react'
import { Receipt } from '.'
import { ReceiptModal } from './components/receipt-modal'
import { ReceiptPDF } from './components/receipt-pdf'

interface PDFOptionalModal {
  open: boolean
  receipt: Receipt
}

interface Props {
  onClose: () => void
  onPDFViewClose?: () => void
  open: boolean
  pdfView?: PDFOptionalModal
  receipt?: Receipt
}

export const ReceiptModals = (props: Props) => {
  const [pdfModal, setPDFModal] = useState({ open: false, receipt: null })

  const onPdfModalClose = () => {
    setPDFModal({ open: false, receipt: null })
    props.onPDFViewClose && props.onPDFViewClose()
  }

  const onSuccessCreated = (receipt: Receipt) => {
    setPDFModal({ open: true, receipt: { ...receipt } })
  }

  return (
    <>
      <ReceiptModal
        onClose={props.onClose}
        onSuccessCreated={onSuccessCreated}
        open={props.open}
        receipt={props.receipt}
      />
      <ReceiptPDF
        onClose={onPdfModalClose}
        open={pdfModal.open || props.pdfView?.open}
        receipt={pdfModal.receipt || props.pdfView?.receipt}
      />
    </>
  )
}
