import { IFile } from 'interfaces'
import fuzzysearch from 'fuzzysearch'

export const getAmountUsedText = (value: number) =>
  value < 1000000
    ? `${(value / 1000).toFixed(0)} KB`
    : value < 1000000000
    ? `${(value / 1000000).toFixed(0)} MB`
    : `${(value / 1000000000).toFixed(0)} GB`

export const getContentType = (file: IFile) => {
  if (!file || !file.contentType) {
    return ''
  }

  const tokens = file.contentType.split('/')
  const majorType = tokens[0]
  const minorType = tokens[1]

  if (majorType === 'image') {
    return 'image'
  } else if (minorType === 'pdf') {
    return 'file pdf'
  } else if (
    file.contentType === 'text/plain' ||
    file.contentType === 'text/rtf'
  ) {
    return 'file text'
  } else if (
    minorType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return 'file word'
  } else if (
    minorType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return 'file excel'
  } else if (majorType === 'application' && minorType === 'octet-stream') {
    return 'code'
  } else if (majorType === 'video') {
    return 'file video'
  } else {
    return 'file'
  }
}

export const isImageContentType = (file: IFile) => {
  if (!file || !file.contentType) {
    return ''
  }

  const tokens = file.contentType.split('/')
  const majorType = tokens[0]

  return majorType === 'image'
}

export const isPDFContentType = (file: IFile) => {
  if (!file || !file.contentType) {
    return ''
  }

  const tokens = file.contentType.split('/')
  const minorType = tokens[1]

  return minorType === 'pdf'
}

export const getTagFromMimeType = (file: IFile): string => {
  if (file.contentType.includes('image')) {
    return 'image,'
  }
  if (file.contentType.includes('pdf')) {
    return 'pdf,'
  }
  if (
    file.contentType.includes(
      'vnd.openxmlformats-officedocument.wordprocessingml.document',
    )
  ) {
    return 'doc,'
  }
  if (
    file.contentType.includes(
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    )
  ) {
    return 'excel,'
  }
  if (file?.storagePath.includes('/forms/')) {
    return 'form,'
  }
  if (file.contentType.includes('plain')) {
    return 'text,'
  }
  if (file.contentType.includes('video')) {
    return 'video,'
  }
  return ''
}

export const getExtension = (fileName: string): string => {
  if (!fileName) {
    return ''
  }

  const tokens = fileName.split('.')

  if (tokens.length === 1) {
    return ''
  }

  return `.${tokens[tokens.length - 1]}`
}

export const getTagsLike = (
  str: string,
  tagsFromSetting: string,
): Array<string> => {
  const tags = tagsFromSetting.split(',')
  const searchTerm = str.toLowerCase()
  const results = tags.filter((t) => fuzzysearch(searchTerm, t))
  return results
}
