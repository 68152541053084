import React from 'react'

import { InputArea as IA, Label, FormField } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps, InputSizeProps } from 'components/forms/interfaces'

const InputAreaForm = ({
												 label,
												 name,
												 onChange,
												 onValidate,
												 placeholder,
												 printParams,
												 validation,
												 validationRules,
												 value,
												 size,
												 style,
												 readonly,
												 autoSave,
											 }: InputProps & InputSizeProps) => (
	<FormField
		required={!readonly && validationRules && validationRules.required}
		{...validation}
	>
		<Label>{label}</Label>

		<AutoSaveWrapper
			afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
			autosaveDisabled={autoSave.autosaveDisabled}
			autosaveMode={autoSave.autosaveMode}
			endpoint={autoSave.patchEndpoint}
			entityID={autoSave.entityID}
			formType={autoSave.patchType}
			patch={autoSave.patch}
		>
			<IA
				name={name}
				onBlur={
					onValidate
						? () => onValidate(validationRules, label, name, value)
						: null
				}
				onChange={onChange}
				placeholder={placeholder || label}
				printParams={printParams}
				readonly={readonly}
				size={size}
				style={style}
				value={value}
			/>
		</AutoSaveWrapper>
	</FormField>
)

InputAreaForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
	validation: {},
}

export const InputArea = React.memo(InputAreaForm) // TODO - still necessary?
