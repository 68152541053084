import React from 'react'

import {
  FlexContainerCenter,
  Button,
  Message,
  Icon,
  Text,
  H4,
} from 'components/ui'
import { sendEmailVerification } from 'lib/auth'
import { SuccessColour } from 'styles/colours'

class VerifyEmail extends React.Component {
  state = {
    loading: false,
    sent: false,
    error: '',
  }

  sendEmailVerification = () => {
    this.setState(() => ({ loading: true }))
    sendEmailVerification()
      .then(() => this.setState(() => ({ sent: true, loading: false })))
      .catch(() => {
        this.setState(() => ({
          loading: false,
          sent: false,
          error:
            'Couldn&apos;t send reset email at this time, please try again later.',
        }))
      })
  }

  render() {
    return (
      <Message
        content={
          <>
            <FlexContainerCenter key="email">
              <div>
                <Text>
                  Please verify your Email Address. Check your inbox for an
                  email containing a link to complete account verificaiton.
                </Text>
                <Text>
                  This page will update automatically once you verify. If it
                  doesn$apos;t reload the page manually.
                </Text>
              </div>
              <div>
                {this.state.sent ? (
                  <H4>
                    <Icon
                      name="checkmark"
                      style={{ fontSize: 16, color: SuccessColour }}
                    />
                    Email Sent
                  </H4>
                ) : (
                  <Button
                    loading={this.state.loading}
                    onClick={() => this.sendEmailVerification()}
                  >
                    Resend Verification Email
                  </Button>
                )}
              </div>
            </FlexContainerCenter>
            <FlexContainerCenter key="error">
              {this.state.error && (
                <div>
                  <Text>{this.state.error}</Text>
                </div>
              )}
            </FlexContainerCenter>
          </>
        }
        icon="info"
        warning
      />
    )
  }
}

export default VerifyEmail
