import React from 'react'
import { ReportItem } from './report-item'
import { compare } from './invoiceCompare'
import { postTaxHeaders, preTaxHeaders } from 'containers/taxes'
import { InvoiceReportItem } from 'interfaces'
import { TableRenderer } from 'components/ui'

interface Props {
  fetching?: boolean
  items: InvoiceReportItem[]
}


export const InvoiceReportsTableView = (props: Props) => {
  const taxHeaders = props.items[0].taxes.sort(compare).map((tax) => tax.name)
  const headers = [...preTaxHeaders, ...taxHeaders, ...postTaxHeaders]

  return (
    <TableRenderer
      headers={headers}
      items={props.items}
      itemType='Invoices'
      loading={props.fetching}
      noItemsMessage='No Invoices'
      pagination={null}
      renderer={(item: InvoiceReportItem) => (
        <ReportItem reportItem={item} />
      )}
    />
  )
}