import { createSelector } from 'reselect'

export const getOffices$ = (state) => state.account.offices
const getOfficeIDList$ = (state) => state.account.officesByID || []

export const getOfficesList$ = createSelector(
  [getOffices$, getOfficeIDList$],
  (offices, officeIDList) =>
    (officeIDList.length &&
      officeIDList
        .map((officeID) => offices[officeID])
        .sort((a, b) =>
          a.id === 'new' ? -9 : a.name ? a.name.localeCompare(b.name) : 0,
        )) ||
    [],
)

export const getOfficesSimpleList = createSelector(
  [getOffices$, getOfficeIDList$],
  (offices, officeIDList) =>
    (officeIDList.length &&
      officeIDList.map((officeID) => {
        const office = offices[officeID]

        return {
          id: office.id,
          name: office.name,
        }
      })) ||
    [],
)
