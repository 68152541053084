import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import colours from 'styles/colours'
import { HeaderButtons } from 'components/calendar'
import { Button, Form, ErrorText, Modal, ModalHeader } from 'components/ui'
import {
	getAllAppointmentsForCalendar$,
	fetchAppointments as fa,
	AppointmentStatus,
} from 'containers/appointments'
import {
	getCalendarDefaultAppointmentDuration,
	getSetting,
} from 'containers/settings'
import { Calendar } from 'components/calendar'
import { getClientFullName } from 'containers/clients'
import NewAppointmentDayModal from './new-appointment-day-modal'
import { IAppointment } from 'interfaces/appointments'
import { IState, IClient, FullCalendarEvent, ICalendarEvent, Dispatch } from 'interfaces'
import { parseServiceTimeToMinutes } from 'containers/services/lib/service-time-to-minutes-parser'
import { CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW } from 'constants/settings'

const validationSchema = Yup.object().shape({})

interface Props {
	client: IClient
	defaultDate?: Date | moment.Moment | any
	existingEvent?: IAppointment
	isEditMode?: boolean
	modalOpen: boolean
	onCancel: Function
	onSubmit: Function
	serviceID?: string
	treatmentNumber: number
}

const NewAppointmentNotesModal = (props: Props) => {
	const dispatch: Dispatch = useDispatch()

	const calendarRef: any = useRef(null)
	const savingAppointment = useSelector(
		(state: IState) => state.appointments.savingAppointment, // TODO -default root state
	)
	const appointments = useSelector(getAllAppointmentsForCalendar$)
	const calendarDefaultAppointmentLengthSettings = useSelector(
		getCalendarDefaultAppointmentDuration,
	)
	const service = useSelector(
		(state: IState) => state.services.services[props.serviceID],
	)
	const defaultNewAppointmentView = useSelector((state: IState) =>
		getSetting(state, CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW),
	)

	const [newEvent, setNewEvent] = useState({} as IAppointment)
	const [newAppointmentDayModalEvent, setNewAppointmentDayModalEvent] =
		useState({} as ICalendarEvent)
	const [newAppointmentDayModalOpen, setNewAppointmentDayModalOpen] =
		useState(false)
	const [activeTab, setActiveTab] = useState(0)
	const [duration, setDuration] = useState(
		calendarDefaultAppointmentLengthSettings,
	)

	const { existingEvent } = props
	useEffect(() => {
		if (existingEvent) {
			setNewEvent(existingEvent)
		}
	}, [existingEvent])

	useEffect(() => {
		if (props.serviceID) {
			const serviceTime = parseServiceTimeToMinutes(service?.duration)

			if (serviceTime) {
				setDuration(serviceTime)
			}
		}
	}, [props.serviceID])

	const fetchAppointments = useCallback(
		(queryParams) => {
			dispatch(fa(queryParams))
		},
		[dispatch],
	)

	const onSubmit = (setSubmitting: Function, resetForm: Function) => {
		setSubmitting(true)
		const appointment = newEvent

		if (!appointment.start || !appointment.end) {
			setSubmitting(false)
			return
		}

		resetForm()
		props.onSubmit(appointment)
		resetData()
	}

	const onEvent = (event: FullCalendarEvent) => {
		setNewEvent((prev) => ({
			...prev,
			clientID: props.client.id,
			start: event.start,
			end: event.end,
			title: getClientFullName(props.client),
			treatmentNumber: props.treatmentNumber,
			status: AppointmentStatus.Created,
		}))

		const calendarApi = calendarRef.current.getApi()
		calendarApi.unselect()
	}

	const onEventChange = (event: FullCalendarEvent) => {
		setNewEvent((prev) => ({
			...prev,
			clientID: props.client.id,
			start: event.start,
			end: event.end,
			title: getClientFullName(props.client),
			treatmentNumber: props.treatmentNumber,
			status: AppointmentStatus.Created,
		}))
	}

	const onAcceptEventEdit = (event: FullCalendarEvent) => {
		setNewEvent((prev) => {
			const newObj = {
				...prev,
				start: event.start.format(),
				end: event.end.format(),
			}

			return newObj
		})
		setNewAppointmentDayModalOpen(false)
	}

	const { client } = props

	const events = useMemo(() => {
		const e = appointments.map((a) => ({
			...a,
			editable: false,
			borderColor:
				a.clientID === client.id
					? colours.CalendarEventBorder
					: colours.CalendarUneditableEventBorder,
			backgroundColor:
				a.clientID === client.id
					? colours.CalendarEvent
					: colours.CalendarUneditableEvent,
			displayEventEnd: true,
			display: 'block',
		}))

		return [
			...e,
			{
				...newEvent,
				...newEvent,
				backgroundColor: colours.NewCalendarEvent,
				borderColor: colours.NewCalendarEventBorder,
				display: 'block',
				displayEventEnd: true,
				droppable: true,
				editable: true,
				eventDurationEditable: true,
				eventStartEditable: true,
				new: true,
				selectable: true,
			},
		]
	}, [appointments, newEvent, client])

	const resetData = () => {
		setNewEvent({} as IAppointment)
		setNewAppointmentDayModalEvent({} as ICalendarEvent)
	}
	const onClose = () => {
		resetData()
		props.onCancel()
	}

	return (
		<Formik
			initialValues={{
				appointmentDate: '',
			}}
			onSubmit={(_, { setSubmitting, resetForm }) => {
				onSubmit(setSubmitting, resetForm)
			}}
			validationSchema={validationSchema}
		>
			{({ errors, touched, handleSubmit, isSubmitting, resetForm }) => (
				<Modal
					closeOnDimmerClick={false}
					onClose={onClose}
					open={props.modalOpen}
					size='large'
					style={{ margin: 0 }}
				>
					<ModalHeader
						content={
							props.isEditMode
								? `Edit Appointment for ${props.client.firstName} ${props.client.lastName}`
								: `New Appointment for ${props.client.firstName} ${props.client.lastName}`
						}
						icon='calendar'
					/>

					<NewAppointmentDayModal
						calendarDefaultAppointmentLengthSettings={
							calendarDefaultAppointmentLengthSettings
						}
						event={newAppointmentDayModalEvent}
						modalOpen={newAppointmentDayModalOpen}
						onAccept={onAcceptEventEdit}
						onCancel={() => setNewAppointmentDayModalOpen(false)}
					/>
					<Modal.Content
						style={{
							maxHeight: '80vh',
							overflowY: 'scroll',
							scrollbarWidth: 'none',
						}}
					>
						<Form>
							<HeaderButtons
								activeTab={activeTab}
								calendarRef={calendarRef}
								fetchAppointments={fetchAppointments}
								setActiveTab={(e: any) => setActiveTab(e)}
							/>
							<Calendar
								key='AppointmentModal'
								calendarRef={calendarRef}
								dateClick={(e: FullCalendarEvent) => {
									if (
										calendarRef.current.getApi().view.type === 'dayGridMonth'
									) {
										calendarRef.current.getApi().gotoDate(e.date)
										calendarRef.current
											.getApi()
											.changeView(defaultNewAppointmentView)
									}
								}}
								defaultDate={props.defaultDate}
								editable
								eventChange={({ event }) => onEventChange(event)}
								eventClick={(info: FullCalendarEvent) => {
									if (
										info.event.extendedProps.new &&
										!newAppointmentDayModalOpen
									) {
										setNewAppointmentDayModalEvent(info.event)
										setNewAppointmentDayModalOpen(true)
									}
								}}
								events={events}
								isInModal
								select={(e: FullCalendarEvent) => {
									if (e.allDay) {
										setActiveTab(2)
										return
									}

									const defaultEnd = moment(e.start).add(duration, 'minutes')

									if (!moment(e.end).isAfter(defaultEnd)) {
										e.end = defaultEnd.format()
									}

									onEvent(e)
								}}
								selectable
								selectMirror
							/>
							<ErrorText>
								{errors.appointmentDate &&
									touched.appointmentDate &&
									errors.appointmentDate}
							</ErrorText>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								resetForm()
								props.onCancel()
							}}
							secondary
						>
							Cancel
						</Button>
						<Button
							disabled={!newEvent.start || !newEvent.end}
							loading={isSubmitting || savingAppointment}
							onClick={handleSubmit}
							primary
							type='submit'
						>
							{props.isEditMode ? 'Save' : 'Next'}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</Formik>
	)
}

export { NewAppointmentNotesModal }
export default NewAppointmentNotesModal
