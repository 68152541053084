import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import shortid from 'shortid'

import { generateGUID } from 'lib/guid'
import {
	Accordion,
	Card,
	List,
	ListHeader,
	ListDescription,
	ActivableListItem,
	ListIcon,
	ListContent,
	ListText,
	Avatar,
	Icon,
	Text,
	H3,
	H4,
	AccordionTitle,
	AccordionContent,
	Button,
} from 'components/ui'
import { fetchAccountForms } from 'containers/account'
import { FormPreviewModal } from 'components/modals'
import { deleteForm, /*fetchForms,*/ restoreForm } from 'containers/forms'
import { getFirebaseUser, getAccountForms } from 'containers/account'
import { FeatureToggle } from 'containers/features'
import {
	FileUpload as FileUploadFeature,
	BetaUser,
	GlobalCustomForms,
	CustomForms,
} from 'constants/features'
import { BASIC_FALL_BACK_FORM_ID } from 'constants/form'
import { Spinner } from 'components/loaders'
import { Dispatch, IForm, IFormItem, IResponse, IState } from 'interfaces'
import { FileUpload } from 'containers/files'
import { FormBuilderModal } from 'containers/form-builder'
import { FormType } from 'interfaces/forms'

const UploadZone = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const getTab = (tab: FormType) => {
	switch (tab) {
		case 'client-info':
			return 'Client Info'

		case 'notes':
			return 'Notes'

		case 'any':
			return 'Any Tab'

		default:
			return tab ? `${tab}`.replace(/-/g, ' ') : 'UNKNOWN'
	}
}

interface Props {
	deleteForm: (formID: string) => Promise<IResponse<any>>
	deletingForm: boolean
	fetchAccountForms: () => Promise<IResponse<any>>
	// fetchForms: (forms: any[]) => Promise<IResponse<any>>
	fetchingAccountForms: boolean
	fetchingForms: boolean
	forms: Array<any> // TODO - forms
	restoreForm: (formID: string) => Promise<IResponse<any>>
	templates: Object // TODO - templates
}

interface State {
	hasFile: boolean
	fileJson?: {
		id?: string
		name?: string
	}
	uploading: boolean
	accordianIndex: number
	formPreviewReadonly: boolean
	editFormModal: {
		formID?: string
		formJson?: IForm
		open: boolean
	}
}

class FormSettings extends React.Component<Props> {
	public static defaultProps: Partial<Props> = {
		forms: [],
	}

	state: State = {
		hasFile: false,
		fileJson: {},
		uploading: false,
		accordianIndex: -1,
		formPreviewReadonly: false,
		editFormModal: {
			open: false,
			formID: null,
			formJson: null,
		},
	}

	onCancel = () =>
		this.setState(() => ({
			hasFile: false,
			file: {},
			fileJson: {},
		}))

	onDelete = () => {
		this.props
			.deleteForm(this.state.fileJson.id)
			.then((response: IResponse<any>) => {
				if (response.status >= 200 && response.status < 300) {
					this.onCancel()
					this.props.fetchAccountForms()
				}
			})
	}

	onRestore = () => {
		this.props
			.restoreForm(this.state.fileJson.id)
			.then((response: IResponse<any>) => {
				if (response.status >= 200 && response.status < 300) {
					this.onCancel()
					this.props.fetchAccountForms()
				}
			})
	}

	accordianHandleClick = (_: any, { index }) => {
		const { accordianIndex } = this.state
		const newIndex = accordianIndex === index ? -1 : index

		this.setState({ accordianIndex: newIndex })
	}

	assignIDsToFormItems = (parsedFile: IForm) => {
		this.recursivelyAssignIDsToFormItems(parsedFile.form)
	}

	recursivelyAssignIDsToFormItems = (forms: IFormItem[]) => {
		if (!forms) {
			return
		}

		for (const form of forms) {
			form.id = shortid.generate()
			this.recursivelyAssignIDsToFormItems(form.form)
		}
	}

	onDropOverride = (accept: any[], progress: (progress: number) => void) => {
		progress(-1)

		const receivedText = (e: any) => {
			const lines = e.target.result
			const parsedFile = JSON.parse(lines)

			this.assignIDsToFormItems(parsedFile)

			parsedFile.id = generateGUID()
			parsedFile.created = new Date()
			parsedFile.modified = new Date()

			const { id, screen, tab, order, type, created, modified } = parsedFile

			const duplicateNames = Object.entries(this.props.templates)
				.map((a) => a[1].name)
				.filter((a) => a.toLowerCase().includes(parsedFile.name.toLowerCase()))

			let name = parsedFile.name
			if (duplicateNames.length) {
				name = `${name} (${duplicateNames.length})`
				parsedFile.name = name
			}

			const accountFormData = {
				name,
				id,
				screen,
				tab,
				type,
				order,
				dateCreated: created,
				dateModified: modified,
			}

			const fileJson = {
				...parsedFile,
				...accountFormData,
				name,
			}

			this.setState(() => ({
				editFormModal: {
					open: true,
					formID: null,
					formJson: fileJson,
				},
			}))
		}

		const fr = new FileReader()
		fr.onload = receivedText
		fr.readAsText(accept[0])
	}

	list = (props: ListProps) => (
		<List
			collapseOnMobile={false}
			items={props.forms}
			itemType='Forms'
			noItemsMessage='No Forms'
			renderer={(form: IForm) => {
				const listItem = this.props.templates[form.id]

				if (!listItem) {
					return null
				}

				return (
					<ActivableListItem key={form.id} onClick={() => {
					}}
					>
						<ListIcon>
							<Avatar size='48px'>
								<Icon name='file' size='big' style={{ margin: 'auto' }} />
							</Avatar>
						</ListIcon>

						<ListContent
							onClick={() =>
								this.setState(() => ({
									hasFile: true,
									fileJson: listItem,
									formPreviewReadonly: true,
								}))
							}
						>
							<ListHeader>
								<ListText>
									<H4>{`${form.name}`}</H4>
									<Text>&nbsp;{`(${getTab(form.tab)})`}</Text>
								</ListText>
							</ListHeader>
							<ListDescription as='a'>
								<Text>
									{`Created: ${moment(form.dateCreated).format(
										'MMM D YYYY h:mm',
									)} | Modified: ${moment(form.dateModified).format(
										'MMM D YYYY h:mm',
									)}`}
								</Text>
							</ListDescription>
						</ListContent>

						{!form.deleted && (
							<FeatureToggle features={[GlobalCustomForms, CustomForms]}>
								<ListIcon
									onClick={() => {
										this.setState(() => ({
											editFormModal: {
												open: true,
												formID: form.id,
												formJson: null,
											},
										}))
									}}
								>
									<Avatar size='48px'>
										<Icon name='pencil' size='big' style={{ margin: 'auto' }} />
									</Avatar>
								</ListIcon>
							</FeatureToggle>
						)}
					</ActivableListItem>
				)
			}}
		/>
	)

	render() {
		const form = this.props.forms.find((f) => f.id === this.state.fileJson.id)
		const forms = this.props.forms.filter(
			(t) => t.id !== BASIC_FALL_BACK_FORM_ID && t.id && !t.deleted,
		)

		const deletedForms = this.props.forms.filter(
			(t) => t.id !== BASIC_FALL_BACK_FORM_ID && t.id && t.deleted,
		)

		return (
			<>
				<FormPreviewModal
					canDelete={true}
					fileJson={[this.state.fileJson]}
					isDeleted={form ? form.deleted : false}
					modalOpen={this.state.hasFile}
					name={this.state.fileJson.name}
					onAccept={() => {
					}}
					onCancel={this.onCancel}
					onDelete={this.onDelete}
					onRestore={this.onRestore}
					readonly={this.state.formPreviewReadonly}
					uploading={this.state.uploading || this.props.deletingForm}
				/>
				<FormBuilderModal
					formID={this.state.editFormModal.formID}
					formJson={this.state.editFormModal.formJson}
					// onAccept={() =>
					//   this.setState(() => ({
					//     editFormModal: {
					//       open: false,
					//       formID: '',
					//       formJson: null,
					//     },
					//   }))
					// }
					onClose={() =>
						this.setState(() => ({
							editFormModal: {
								open: false,
								formID: '',
								formJson: null,
							},
						}))
					}
					open={this.state.editFormModal.open}
				/>

				<Card
					extra={
						<FeatureToggle features={[GlobalCustomForms, CustomForms]}>
							<Button
								circular
								icon='plus'
								onClick={() =>
									this.setState(() => ({
										editFormModal: {
											open: true,
											formID: null,
											formJson: null,
										},
									}))
								}
								primary
							/>
						</FeatureToggle>
					}
					title='Forms'
				>
					<Spinner
						spinning={
							this.props.fetchingAccountForms || this.props.fetchingForms
						}
						tip='Fetching forms...'
					>
						<H3>Current Forms</H3>
						{this.list({ forms })}

						<FeatureToggle features={[FileUploadFeature, BetaUser]}>
							<UploadZone>
								<>
									<H3>Upload Form</H3>
									<FileUpload
										accept={{ 'application/json': ['.json'] }}
										onDropOverride={this.onDropOverride}
									/>
								</>
							</UploadZone>
						</FeatureToggle>

						{!!deletedForms.length && (
							<Accordion style={{ width: '100%' }}>
								<AccordionTitle
									active={this.state.accordianIndex === 0}
									index={0}
									onClick={this.accordianHandleClick}
								>
									<Icon name='dropdown' />
									Deleted Forms
								</AccordionTitle>
								<AccordionContent active={this.state.accordianIndex === 0}>
									{/* <this.list forms={deletedForms} /> */}
									{this.list({ forms: deletedForms })}
								</AccordionContent>
							</Accordion>
						)}
					</Spinner>
				</Card>
			</>
		)
	}
}

interface ListProps {
	forms: IForm[]
}

const mapStateToProps = (state: IState) => ({
	templates: state.forms.templates,
	forms: getAccountForms(state),
	deletingForm: state.forms.deleting,
	firebaseUser: getFirebaseUser(state),
	fetchingAccountForms: state.account.fetchingAccountForms,
	fetchingForms: state.forms.fetchingForms,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	deleteForm: (formID: string) => dispatch(deleteForm(formID)),
	restoreForm: (formID: string) => dispatch(restoreForm(formID)),
	fetchAccountForms: () => dispatch(fetchAccountForms()),
	// fetchForms: (forms: any[]) => dispatch(fetchForms(forms)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormSettings)
