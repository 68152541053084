import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Appointments from 'components/appointments/appointments'
import {
	setSelectedAppointment,
	fetchAppointmentsForClient,
} from 'containers/appointments'
import { getSelectedClientsAppointmentsFiltered } from 'containers/appointments/selectors'
import { onSoapsSearch } from 'containers/soap-notes'
import { getSelectedClientID$, getClientFullName } from 'containers/clients'
import { getSeletectedTreatment$ } from 'containers/treatment'
import {
	IAppointment,
	IEvent,
	IState,
} from 'interfaces'
import { IQueryParams } from 'interfaces/fetch'

const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: 4em;
`

const SoapAppointments = (props: ISoapAppointments) => {
	return (
		<Sticky>
			<Appointments
				appointmentError={props.fetchSoapsFailure}
				appointments={props.appointments}
				clientID={props.clientID}
				clientName={props.clientName}
				disabled={props.selectedTreatment.closed}
				isDirty={props.dirtySoap}
				loading={props.loading}
				onClick={props.setSelectedAppointment}
				onFilter={(e: IEvent) => {
					const search = e.target.value
					props.onSearch(search)
				}}
				onSearch={() => props.onSearch(props.searchTerm)}
				onSearchClear={props.onSearchClear}
				reverseEmphasis
				searchTerm={props.searchTerm}
				selectedAppointment={props.selectedAppointment}
			/>
		</Sticky>
	)
}

interface ISoapAppointments {
	appointments: Array<IAppointment>
	clientID: string
	clientName: string
	dirtySoap?: string
	fetchSoapsFailure?: string
	loading?: boolean
	onSearch: Function
	onSearchClear: Function
	searchTerm?: string
	selectedAppointment: IAppointment
	selectedTreatment?: any // TODO - ITreatment
	setSelectedAppointment: Function
}

const mapStateToProps = (state: IState) => ({
	clientID: getSelectedClientID$(state),
	clientName: getClientFullName(state),
	appointments: getSelectedClientsAppointmentsFiltered(state),
	loading: state.soapNotes.loading || state.soapNotes.soapFilteringLoading,
	selectedAppointment: state.appointments.selectedAppointment,
	searchTerm: state.soapNotes.soapSearchTerm,
	dirtySoap: state.soapNotes.dirtySoap,
	selectedTreatment: getSeletectedTreatment$(state),
})

const mapDispatchToProps = (dispatch: any) => ({
	setSelectedAppointment: (appointment: IAppointment) =>
		dispatch(setSelectedAppointment(appointment)),
	onSearch: (search: string) => dispatch(onSoapsSearch(search)),
	onSearchClear: () => dispatch(onSoapsSearch('')),
	fetchAppointmentsForClient: (
		clientID: string,
		treatmentNumber: number,
		queryParams: IQueryParams,
	) =>
		dispatch(
			fetchAppointmentsForClient(clientID, treatmentNumber, queryParams, false),
		),
})

export default connect(mapStateToProps, mapDispatchToProps)(SoapAppointments)
