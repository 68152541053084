import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Card, ContainerLayout, Dropdown, Label, Text } from 'components/ui'
import { AuditContainer } from 'containers/audits'
import { getAllClientsList$, getClientFullName } from 'containers/clients'
import { IClient, IEvent, parseSearchLocation } from 'interfaces'
import { getQueryParams } from 'constants/endpoints'
import { useNavigate } from 'react-router-dom'

export const AuditReports = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const [params, setParams] = useState(parseSearchLocation(location.search))
	const [clientID, setClientID] = useState(params.client)

	const onParamChange = (newParam: { [key: string]: string }) => {
		const p = { ...params, ...newParam }
		setParams(p)

		navigate(`/reports/audits/${getQueryParams(p)}`)
	}

	const onClientIDChange = ({ target }: IEvent) => {
		setClientID(target.value)
		onParamChange({ client: target.value })
	}

	const onQueryClear = () => {
		setClientID('')
		setParams({})
		navigate('/reports/audits')
	}

	const clientOptions = useSelector(getAllClientsList$).map((c: IClient) => ({
		id: c.id,
		key: c.id,
		value: c.id,
		text: getClientFullName(c),
	}))

	return (
		<ContainerLayout>
			<Card title='Audits'>
				<Text>
					Audit&apos;s are a history of changes and accesses generated for all
					client data.
				</Text>
				<Label>Client</Label>
				<Dropdown
					clearable
					onChange={onClientIDChange}
					options={clientOptions}
					placeholder='Pick a Client to run a history report on'
					style={{ marginBottom: '1em' }}
					value={clientID}
				/>
				<AuditContainer
					entityID={clientID}
					onParamChange={onParamChange}
					onQueryClear={onQueryClear}
					pickTable
				/>
			</Card>
		</ContainerLayout>
	)
}
