import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { authPost } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'

import {
	Button,
	Card,
	Divider,
	Form,
	FormColumnArea,
	Label,
	Segment,
	SubText,
	Text,
} from 'components/ui'
import { InputArea, TextArea } from 'components/forms'
import {
	getSetting,
	getSettingNoDefault,
	saveSettings as saveS,
} from 'containers/settings'
import {
	PUBLIC_INTAKE_EMAIL_GREETING,
	PUBLIC_INTAKE_EMAIL_SUBJECT,
	PUBLIC_INTAKE_COMPANY,
	PUBLIC_INTAKE_FAREWELL,
	USER_BANNER_RECTANGLE,
	USER_BANNER_SQUARE,
	ORG_NAME,
	ORG_NAME_WITH_ADDRESS,
	getDefaultSettings,
} from 'constants/settings'
import CTImage from 'components/image/ct-image'
import { IState, Dispatch, SettingKey } from 'interfaces'

export const PublicIntakeEmailSettings = () => {
	const dispatch: Dispatch = useDispatch()

	const greetingSetting = useSelector((state: IState) =>
		getSettingNoDefault(state, PUBLIC_INTAKE_EMAIL_GREETING),
	)
	const emailSubjectSetting = useSelector((state: IState) =>
		getSettingNoDefault(state, PUBLIC_INTAKE_EMAIL_SUBJECT),
	)
	const companySetting = useSelector((state: IState) =>
		getSettingNoDefault(state, PUBLIC_INTAKE_COMPANY),
	)
	const farewellSetting = useSelector((state: IState) =>
		getSettingNoDefault(state, PUBLIC_INTAKE_FAREWELL),
	)
	const rectangleBrandImageURL = useSelector((state: IState) =>
		getSetting(state, USER_BANNER_RECTANGLE),
	)
	const squareBrandImageURL = useSelector((state: IState) =>
		getSetting(state, USER_BANNER_SQUARE),
	)
	const orgName = useSelector((state: IState) => getSetting(state, ORG_NAME))
	const orgNameWithAddress = useSelector((state: IState) =>
		getSetting(state, ORG_NAME_WITH_ADDRESS),
	)
	const user = useSelector((state: IState) => state.user.ctUser)

	const [isSendingTestEmail, setIsSendingTestEmail] = useState(false)
	const [emailState, setEmailState] = useState({
		[PUBLIC_INTAKE_EMAIL_GREETING]: greetingSetting,
		[PUBLIC_INTAKE_EMAIL_SUBJECT]: emailSubjectSetting,
		[PUBLIC_INTAKE_COMPANY]: companySetting,
		[PUBLIC_INTAKE_FAREWELL]: farewellSetting,
	})

	useEffect(() => {
		setEmailState((prev) => ({
			...prev,
			[PUBLIC_INTAKE_EMAIL_GREETING]: greetingSetting,
			[PUBLIC_INTAKE_EMAIL_SUBJECT]: emailSubjectSetting,
			[PUBLIC_INTAKE_COMPANY]: companySetting,
			[PUBLIC_INTAKE_FAREWELL]: farewellSetting,
		}))
	}, [greetingSetting, emailSubjectSetting, companySetting, farewellSetting])

	const saveSettings = (setting: SettingKey, value: string) =>
		dispatch(
			saveS({
				setting,
				value,
			}),
		)

	const onChange = ({ target }) =>
		setEmailState((prev) => ({
			...prev,
			[target.name]: target.value,
		}))

	const sendTestEmail = useCallback(() => {
		setIsSendingTestEmail(true)
		authPost(PublicIntake.TestEmail, { email: user.email })
			.then(() => setIsSendingTestEmail(false))
			.catch(() => setIsSendingTestEmail(false))
	}, [user])

	const formatText = (str: string = '') =>
		str
			.replace('{CLIENT_NAME}', 'John Smith')
			.replace('{CLIENT_FIRST_NAME}', 'John')
			.replace('{CLIENT_LAST_NAME}', 'Smith')
			.replace('{USER_NAME}', `${user.firstName || ''} ${user.lastName || ''}`)
			.replace('{USER_FIRST_NAME}', user.firstName)
			.replace('{USER_LAST_NAME}', user.lastName)
			.replace('{ORG}', orgName)
			.replace('{ORG_ADDRESS}', orgNameWithAddress)
			.replace('{EMPTY}', '')

	return (
		<Card collapsible startCollapsed title='Intake Forms Emails'>
			<FormColumnArea>
				<Card title='Email Text'>
					<Form>
						<InputArea
							autoSave={{
								autosaveMode: 'onBlurOnly',
								entityID: 'setting',
								patch: () =>
									saveSettings(
										PUBLIC_INTAKE_EMAIL_SUBJECT,
										emailState[PUBLIC_INTAKE_EMAIL_SUBJECT],
									),
							}}
							label='Subject'
							name={PUBLIC_INTAKE_EMAIL_SUBJECT}
							onChange={onChange}
							placeholder={getDefaultSettings(PUBLIC_INTAKE_EMAIL_SUBJECT)}
							value={emailState[PUBLIC_INTAKE_EMAIL_SUBJECT]}
						/>

						<TextArea
							autoSave={{
								autosaveMode: 'onBlurOnly',
								entityID: 'setting',
								patch: () =>
									saveSettings(
										PUBLIC_INTAKE_EMAIL_GREETING,
										emailState[PUBLIC_INTAKE_EMAIL_GREETING],
									),
							}}
							label='Greeting'
							name={PUBLIC_INTAKE_EMAIL_GREETING}
							onChange={onChange}
							placeholder={getDefaultSettings(PUBLIC_INTAKE_EMAIL_GREETING)}
							value={emailState[PUBLIC_INTAKE_EMAIL_GREETING]}
						/>

						<TextArea
							autoSave={{
								autosaveMode: 'onBlurOnly',
								entityID: 'setting',
								patch: () =>
									saveSettings(
										PUBLIC_INTAKE_FAREWELL,
										emailState[PUBLIC_INTAKE_FAREWELL],
									),
							}}
							label='Farewell Text'
							name={PUBLIC_INTAKE_FAREWELL}
							onChange={onChange}
							placeholder={getDefaultSettings(PUBLIC_INTAKE_FAREWELL)}
							value={emailState[PUBLIC_INTAKE_FAREWELL]}
						/>

						<InputArea
							autoSave={{
								autosaveMode: 'onBlurOnly',
								entityID: 'setting',
								patch: () =>
									saveSettings(
										PUBLIC_INTAKE_COMPANY,
										emailState[PUBLIC_INTAKE_COMPANY],
									),
							}}
							label='Footer: Company'
							name={PUBLIC_INTAKE_COMPANY}
							onChange={onChange}
							placeholder={getDefaultSettings(PUBLIC_INTAKE_COMPANY)}
							value={emailState[PUBLIC_INTAKE_COMPANY]}
						/>
					</Form>
				</Card>

				<Card title='Preview'>
					<Label>
						{formatText(
							emailState[PUBLIC_INTAKE_EMAIL_SUBJECT] ||
							getDefaultSettings(PUBLIC_INTAKE_EMAIL_SUBJECT),
						)}
					</Label>

					<Segment>
						{rectangleBrandImageURL && (
							<CTImage imageID={rectangleBrandImageURL} />
						)}
						<Divider hidden />

						{emailState[PUBLIC_INTAKE_EMAIL_GREETING]
							? emailState[PUBLIC_INTAKE_EMAIL_GREETING].split(
								'\n',
							).map((text: string, index: number) => (
								<Text key={index}>{formatText(text)}</Text>
							))
							: formatText(getDefaultSettings(PUBLIC_INTAKE_EMAIL_GREETING))}

						<Button primary>Complete Intake Form</Button>

						<SubText>
							Or copy and paste this link into your browser:
							https://link-here.com
						</SubText>
						<SubText>{`This form expires on: ${moment()
							.add(30, 'days')
							.format('MMMM D YYYY h:mm a')}`}</SubText>

						<Text>
							{emailState[PUBLIC_INTAKE_FAREWELL]
								? emailState[PUBLIC_INTAKE_FAREWELL].split(
									'\n',
								).map((text: string, index: number) => (
									<Text key={index}>{formatText(text)}</Text>
								))
								: formatText(getDefaultSettings(PUBLIC_INTAKE_FAREWELL))}
						</Text>
					</Segment>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<SubText>{formatText(emailState[PUBLIC_INTAKE_COMPANY])}</SubText>

						{squareBrandImageURL && (
							<CTImage height={128} imageID={squareBrandImageURL} width={128} />
						)}

						<SubText>Don&apos;t like these emails? Unsubscribe. </SubText>

						<Divider hidden />

						<SubText>Powered by HTMLemail. </SubText>
					</div>

					<Divider hidden />
					<Divider />

					<Button
						content='Send Test Email to Yourself'
						loading={isSendingTestEmail}
						onClick={sendTestEmail}
						primary
					/>
				</Card>
			</FormColumnArea>
		</Card>
	)
}
