import {
	reducer as saveAppointment,
	defaultState as saveAppointmentDefaultState,
} from './save-appointment'
import { reducer as fetchAppointmentsForClient } from './fetch-appointments-for-client'
import {
	reducer as fetchAllAppointments,
	defaultState as fetchAllAppointmentsDefaultState,
} from './fetch-all-appointments'
import {
	reducer as deleteAppointment,
	defaultState as deleteAppointmentDefaultState,
} from './delete-appointment'
import {
	reducer as fetchAppointment,
	defaultState as fetchAppointmentDefaultState,
} from './fetch-appointment'
import { IAppointment, IAction, AppointmentState, Severity } from 'interfaces'
import moment from 'moment'
import { maxBy } from 'lodash'
import { emptyGuid } from 'lib/guid'
import colours from 'styles/colours'
import {
	captureException,
	captureMessage,
} from '@sentry/react'

const reducers = [
	saveAppointment,
	fetchAppointment,
	fetchAppointmentsForClient,
	fetchAllAppointments,
	deleteAppointment,
]

const prefix = 'client-trackyr/appointments/'
const appointmentAction = (action: string) =>
	`client-trackyr/appointments/${action}`
const SET_SELECTED_APPOINTMENT = appointmentAction('SET_SELECTED_APPOINTMENT')
const SET_SELECTED_APPOINTMENT_BY_ID = appointmentAction(
	'SET_SELECTED_APPOINTMENT_BY_ID',
)
const UNSELECT_APPOINTMENT = appointmentAction('UNSELECT_APPOINTMENT')
const SELECT_MOST_CURRENT_SOAP = appointmentAction('SELECT_MOST_CURRENT_SOAP')
const SELECT_BEST_DATE_SOAP = appointmentAction('SELECT_BEST_DATE_SOAP')
const ON_APPOINTMENT_CHANGED = `${prefix}/ON_APPOINTMENT_CHANGED`
const ON_ADD_APPOINTMENT = `${prefix}/ON_ADD_APPOINTMENT`

export const defaultState = {
	...fetchAllAppointmentsDefaultState,
	...saveAppointmentDefaultState,
	...deleteAppointmentDefaultState,
	...fetchAppointmentDefaultState,
	appointments: {},
	clientAppointments: {},
	fetchingAppointmentsForClient: false,
	selectedAppointment: {},
	allAppointmentsByID: [],
} as AppointmentState

export const reducer = (
	state: AppointmentState = defaultState,
	action: IAction,
) => {
	switch (action.type) {
		case SET_SELECTED_APPOINTMENT:
			return {
				...state,
				selectedAppointment: action.appointment,
			}

		case SET_SELECTED_APPOINTMENT_BY_ID: {
			const appointment = state.appointments[action.appointmentID]

			return {
				...state,
				selectedAppointment: appointment,
			}
		}

		case UNSELECT_APPOINTMENT:
			return {
				...state,
				selectedAppointment: {},
			}

		case SELECT_MOST_CURRENT_SOAP: {
			const clientAppointments = state.clientAppointments[action.clientID]

			if (!clientAppointments || !clientAppointments.length) {
				return { ...state }
			}

			const appointmentsForTreatment = clientAppointments
				.map((a: string) => state.appointments[a])
				.filter(
					(a: IAppointment) => a.treatmentNumber === action.treatmentNumber,
				)

			const selectedAppointment =
				appointmentsForTreatment[appointmentsForTreatment.length - 1] || {}

			return {
				...state,
				selectedAppointment,
			}
		}

		case SELECT_BEST_DATE_SOAP: {
			const clientAppointments = state.clientAppointments[action.clientID]

			if (!clientAppointments || !clientAppointments.length) {
				return { ...state }
			}

			try {
				const now = Number(moment().add(1, 'day').format('X'))
				const appointmentsForTreatment = clientAppointments
					.map((a: string) => state.appointments[a])
					.filter((a: IAppointment) => {
						if (!a) {
							captureMessage(
								'Something weird happened with SELECT_BEST_DATE_SOAP - 1',
								{
									extra: {
										clientAppointments,
										now,
									},
									level: Severity.Error,
								},
							)
							return false
						}

						return (
							a.treatmentNumber === action.treatmentNumber &&
							Number(moment(a.start).format('X')) <= now
						)
					})

				const maxAppointment = maxBy(
					appointmentsForTreatment,
					(a: IAppointment) => a.appointmentNumber,
				)

				return {
					...state,
					selectedAppointment: maxAppointment,
				}
			} catch (ex) {
				captureException(ex)

				captureMessage(
					'Something weird happened with SELECT_BEST_DATE_SOAP - 2',
					{
						extra: {
							clientAppointments,
						},
						level: Severity.Error,
					},
				)

				return {
					...state,
				}
			}
		}

		case ON_APPOINTMENT_CHANGED: {
			const appointment: IAppointment = action.data
			const appointmentMyMonthKey = `${moment(appointment.start).get('year')}${
				moment(appointment.start).get('month') + 1
			}`

			const updatedApptByMonth =
				state.appointmentsByMonth[appointmentMyMonthKey] || []
			const updatedIndex = updatedApptByMonth.findIndex(
				(a) => a.id === appointment.id,
			)

			if (updatedIndex === -1) {
				updatedApptByMonth.push(appointment)
			} else {
				updatedApptByMonth[updatedIndex] = appointment
			}

			return {
				...state,
				appointments: {
					...state.appointments,
					[appointment.id]: appointment,
				},
				appointmentsByMonth: {
					...state.appointmentsByMonth,
					[appointmentMyMonthKey]: updatedApptByMonth,
				},
			}
		}

		case ON_ADD_APPOINTMENT: {
			return {
				...state,
				appointments: {
					...state.appointments,
					[emptyGuid]: {
						...action.data,
						id: emptyGuid,
						backgroundColor: colours.NewCalendarEvent,
						borderColor: colours.NewCalendarEventBorder,
						display: 'block',
						displayEventEnd: true,
						droppable: true,
						editable: true,
						eventDurationEditable: true,
						eventStartEditable: true,
						new: true,
						selectable: true,
					},
				},
			}
		}

		default:
			break
	}

	return reducers.reduce((s, r) => r(s, action), state)
}

export const setSelectedAppointment = (appointment: IAppointment) => ({
	type: SET_SELECTED_APPOINTMENT,
	appointment,
})

export const setSelectedAppointmentByID = (appointmentID: string) => ({
	type: SET_SELECTED_APPOINTMENT_BY_ID,
	appointmentID,
})

export const unselectAppointment = () => ({
	type: UNSELECT_APPOINTMENT,
})

export const selectMostCurrentSoap = (
	clientID: string,
	treatmentNumber: number,
) => ({
	type: SELECT_MOST_CURRENT_SOAP,
	clientID,
	treatmentNumber,
})

export const selectBestDateSoap = (
	clientID: string,
	treatmentNumber: number,
) => ({
	type: SELECT_BEST_DATE_SOAP,
	clientID,
	treatmentNumber,
})

export const onAppointmentChange = (appointment: IAppointment) => ({
	type: ON_APPOINTMENT_CHANGED,
	data: appointment,
})

export const onAddAppointment = (newAppointment: IAppointment) => ({
	type: ON_ADD_APPOINTMENT,
	data: newAppointment,
})
