import React from 'react'
import styled from 'styled-components'
import { Radio as SemanticRadio } from 'semantic-ui-react'

import { Font } from 'styles'
import { Text } from './text'
import { Print } from './interfaces'

const RRadio = styled(SemanticRadio)``

const Radio = ({ ...props }) => <RRadio {...props} />

interface IRRadioButton {
  right?: boolean
  left?: boolean
  disabled?: boolean
  selected?: boolean
}
// TODO - no duplicate border on selected
const RRadioButton = styled.div<IRRadioButton>`
  ${Font};
  height: 32px;
  line-height: 30px;
  text-shadow: none;

  background-image: none;

  border-radius: ${(props) =>
    props.left ? '4px 0 0 4px' : props.right ? '0 4px 4px 0' : 0};
  border-left: ${(props) => (props.left ? '1px solid' : '1px solid')};
  border-right: ${(props) => (props.right ? '1px solid' : '1px solid')};

  border-top: 1px solid;
  border-bottom: 1px solid;

  border-color: ${(props) =>
    props.disabled
      ? props.theme.PrimaryDisabled
      : props.selected
      ? props.theme.PrimaryColour
      : props.theme.PrimaryColourBorderColour};

  color: ${(props) =>
    props.disabled
      ? props.selected
        ? props.theme.TextColourLight
        : props.theme.PrimaryDisabled
      : props.selected
      ? props.theme.TextColourLight
      : props.theme.TextColour};

  background-color: ${(props) =>
    props.selected
      ? !props.disabled
        ? props.theme.PrimaryColour
        : props.theme.PrimaryDisabled
      : props.theme.BackgroundColour};

  padding-left: 1em;
  padding-right: 1em;

  :hover {
    background-color: ${(props) =>
      !props.disabled &&
      (props.selected
        ? props.theme.PrimaryColourHover
        : props.theme.BackgroundColourHover)};
  }
`

// TODO - make this circle correct element
const SelectedReadOnly = styled.div``

interface IRadioGroupWrapper {
  readonly?: boolean
  disabled?: boolean
}
const RadioGroupWrapper = styled.div<IRadioGroupWrapper>`
  ${Font};
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  height: auto;
  line-height: 30px;
  transition: all 0.3s ease;

  background-color: ${(props) => props.theme.BackgroundColour};

  :hover {
    cursor: ${(props) =>
      props.readonly ? null : props.disabled ? 'not-allowed' : 'pointer'};
  }
`

const RadioGroup = (props: IRadioGroup) => {
  if (props.children.length < 2) {
    throw Error('Radio Group must have at least 2 elements')
  }

  // TODO - later on, circle correct element?
  if (props?.printParams?.printingView) {
    if (props.value) {
      return <Text style={{ marginRight: '1em' }}>{props.value}</Text>
    }

    return (
      <div style={{ display: 'flex' }}>
        {props.children.map((child: any, i: number) =>
          child.props.value === props.value ? (
            <SelectedReadOnly key={i}>
              <Text key={i} style={{ marginRight: '1em' }}>
                {child.props.value}
              </Text>
            </SelectedReadOnly>
          ) : (
            <Text key={i} style={{ marginRight: '1em' }}>
              {child.props.value}
            </Text>
          ),
        )}
      </div>
    )
  }

  return (
    <RadioGroupWrapper disabled={props.disabled} readonly={props.readonly}>
      {props.children.map((child: any, i: number) => {
        const additionalProps = {
          left: false,
          right: false,
          selected: false,
          disabled: props.disabled,
          onClick: () =>
            !props.disabled &&
            !props.readonly &&
            props.onChange({
              target: { name: props.name, value: child.props.value },
            }),
        }

        if (child.type === RadioButton) {
          if (i === 0) {
            additionalProps.left = true
          } else if (i === props.children.length - 1) {
            additionalProps.right = true
          }
        } else {
          console.warn(child.type)
          // throw Error('Only RadioButton Component can be used in RadioGroup')
        }

        if (!props.value) {
          if (child.props.value === props.defaultValue) {
            additionalProps.selected = true
          }
        } else {
          if (child.props.value === props.value) {
            additionalProps.selected = true
          }
        }

        return (
          <div key={i}>{React.cloneElement(child, { ...additionalProps })}</div>
        )
      })}
    </RadioGroupWrapper>
  )
}

interface IRadioGroup {
  children: any
  defaultValue?: any
  disabled?: boolean
  name: string
  onChange: Function
  printParams?: Print
  readonly?: boolean
  value: any
}

const RadioButton = ({ ...props }: IRadioButton) => <RRadioButton {...props} />

interface IRadioButton {
  children: any
  left?: boolean
  right?: boolean
  selected?: boolean
  value?: any // TODO - does this prop exist?
}

RadioButton.defaultProps = {
  left: false,
  readonly: false,
  right: false,
  selected: false,
}

export { Radio, RadioGroup, RadioButton }
