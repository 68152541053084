import { combineReducers } from 'redux'

import { reducer as account } from 'containers/account'
import { reducer as app } from './ducks'
import { reducer as appointments } from 'containers/appointments/redux/ducks'
import { reducer as auth } from 'containers/auth/redux/ducks'
import { reducer as calendar } from 'containers/calendar'
import { reducer as clients } from 'containers/clients'
import { reducer as clientView } from 'containers/clients/clients-view'
import { reducer as dashboard } from 'containers/dashboard'
import { reducer as draw } from 'containers/draw'
import { reducer as emails } from 'containers/emails/redux/ducks'
import { reducer as features } from 'containers/features'
import { reducer as files } from 'containers/files'
import { reducer as forms } from 'containers/forms'
import { reducer as navbar } from 'components/nav'
import { reducer as notifications } from 'components/notifications/redux'
import { reducer as publicIntake } from 'containers/public-intake/redux/ducks'
import { reducer as receipts } from 'containers/receipts'
import { reducer as services } from 'containers/services'
import { reducer as settings } from 'containers/settings'
import { reducer as soapNotes } from 'containers/soap-notes'
import { reducer as taxes } from 'containers/taxes'
import { reducer as treatments } from 'containers/treatment/redux/ducks'
import { reducer as user } from 'containers/user'

import { defaultState as accountDefaultState } from 'containers/account'
import { defaultState as appDefaultState } from './ducks'
import { defaultState as appointmentState } from 'containers/appointments/redux/ducks'
import { defaultState as authDefaultState } from 'containers/auth/redux/ducks'
import { defaultState as calendarDefaultState } from 'containers/calendar'
import { defaultState as clientsDefaultState } from 'containers/clients'
import { defaultState as clientViewDefaultState } from 'containers/clients/clients-view'
import { defaultState as dashboardDefaultState } from 'containers/dashboard'
import { defaultState as drawDefaultState } from 'containers/draw'
import { defaultState as emailsDefaultState } from 'containers/emails/redux/ducks'
import { defaultState as featuresDefaultState } from 'containers/features/redux/ducks'
import { defaultState as filesDefaultState } from 'containers/files/redux/ducks'
import { defaultState as formsDefaultState } from 'containers/forms'
import { defaultState as navbarDefaultState } from 'components/nav'
import { defaultState as notificationState } from 'components/notifications/redux'
import { defaultState as publicIntakeDefaultState } from 'containers/public-intake/redux/ducks'
import { defaultState as receiptsDefaultState } from 'containers/receipts'
import { defaultState as servicesDefaultState } from 'containers/services'
import { defaultState as settingsDefaultState } from 'containers/settings'
import { defaultState as soapNotesDefaultState } from 'containers/soap-notes'
import { defaultState as taxesDefaultState } from 'containers/taxes'
import { defaultState as treatmentsDefaultState } from 'containers/treatment'
import { defaultState as userDefaultState } from 'containers/user'

// NOTE: Add to utils/test-utils default state

import { IState, IAction } from 'interfaces'

const LOG_OUT = 'client-trackyr/app/LOG_OUT'

const appReducer = combineReducers({
	account,
	app,
	appointments,
	auth,
	calendar,
	clients,
	clientView,
	dashboard,
	draw,
	emails,
	features,
	files,
	forms,
	navbar,
	notifications,
	publicIntake,
	receipts,
	services,
	settings,
	soapNotes,
	taxes,
	treatments,
	user,
})

const rootReducer = (state: IState | any, action: IAction) => {
	if (action.type === LOG_OUT) {

		state = {
			account: accountDefaultState,
			app: appDefaultState,
			appointments: appointmentState,
			auth: authDefaultState,
			calendar: calendarDefaultState,
			clients: clientsDefaultState,
			clientsView: clientViewDefaultState,
			dashboard: dashboardDefaultState,
			draw: drawDefaultState,
			emails: emailsDefaultState,
			features: featuresDefaultState,
			files: filesDefaultState,
			forms: formsDefaultState,
			navbar: navbarDefaultState,
			notifications: notificationState,
			publicIntake: publicIntakeDefaultState,
			receiptsState: receiptsDefaultState,
			services: servicesDefaultState,
			settings: settingsDefaultState,
			soapNotes: soapNotesDefaultState,
			taxes: taxesDefaultState,
			treatments: treatmentsDefaultState,
			user: userDefaultState,
		}
	}

	return appReducer(state, action)
}

export default rootReducer
