import styled, { css } from 'styled-components'
import { TextColour } from 'styles/colours'

export const Font = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  color: ${(props) => props.theme.TextColour};

  @media print {
    font-size: 13px;
  }
`

export const FontHeader = css`
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;

  color: ${(props) => props.theme.TextColour};
`

export const SFont = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
`

export const FontNoDark = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  color: ${TextColour};
`

interface IReadonlyFormFont {
  paddingBottom?: string
}
export const ReadonlyFormFont = styled.p<IReadonlyFormFont>`
  ${Font};
  white-space: pre-wrap;
  font-size: 16px;

  color: ${(props) => props.theme.TextColour};
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '1em'};
`

export const LinkFont = css`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  color: ${(props) => props.theme.LinkColour};

  :hover {
    color: ${(props) => props.theme.LinkColourHighlight};
  }
`
