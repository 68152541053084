// Lambda
// export const serverURL =
//   'https://uczfe2clf2.execute-api.ca-central-1.amazonaws.com/dev'

// ECS
// export const serverURL = 'https://ct-api.client-trackyr-prod-tfxdikrx.net'

// EC2 - cheap
export const serverURL = 'https://ct-api-server.ddns.net'

export const firebaseConfig = {
	apiKey: 'AIzaSyDV-c3e1CKnOqcsc2VF__dlHj_fgjZf1r0',
	authDomain: 'client-trackyr-prod.firebaseapp.com',
	projectId: 'client-trackyr-prod',
	storageBucket: 'client-trackyr-prod.appspot.com',
}

export const ravenEnv: string = 'prod'
export const currentVersion = '0.5.37'
