import React, { useCallback, useEffect, useState } from 'react'

import { Appointments } from 'constants/endpoints'
import { GetAllResponse, IAppointment, IEvent, IResponse } from 'interfaces'
import { authGet } from 'lib/http-request'
import { Dropdown } from 'components/forms'
import moment from 'moment'
import { emptyGuid } from 'lib/guid'

interface Props {
  clientID: string
  onChange: (e: IEvent) => void
  readonly?: boolean
  value: string
}

export const AppointmentDropdown = (props: Props) => {
  const [appointments, setAppointments] = useState([])
  const [fetching, setFetching] = useState(false)

  const { clientID } = props
  const fetchAppointments = useCallback(
    async (from: moment.Moment) => {
      if (!clientID) {
        return
      }

      try {
        const response: IResponse<GetAllResponse<IAppointment>> = await authGet(
          Appointments.GetAll({
            clientID: clientID,
            from: from.format('YYYY/MM/DD'),
          }),
        )

        if (response.status === 200) {
          const items = response.data.items.map((item: IAppointment) => ({
            id: item.id,
            key: item.id,
            value: item.id,
            text: moment(item.start).format('MMM D YYYY h:mm a'),
          }))

          setAppointments(items)
        }

        if (props.value && !appointments.find((a) => a.id === props.value)) {
          const response: IResponse<IAppointment> = await authGet(
            Appointments.Get(props.value),
          )

          if (response.status === 200) {
            const existingAppointments = [...appointments]
            const fetchedAppointment = response.data
            existingAppointments.push({
              id: fetchedAppointment.id,
              key: fetchedAppointment.id,
              value: fetchedAppointment.id,
              text: moment(fetchedAppointment.start).format(
                'MMM D YYYY h:mm a',
              ),
            })
            setAppointments(existingAppointments)
          }
        }

        setFetching(false)
      } catch (ex) {
        setFetching(false)
        console.error(ex)
      }
    },
    [clientID],
  )

  useEffect(() => {
    if (clientID) {
      setFetching(true)
      const now = moment()

      now.subtract(6, 'months')
      fetchAppointments(now)
    }
  }, [clientID, fetchAppointments, props.value])

  if (props.value === emptyGuid && props.readonly) {
    return null
  }

  return (
    <Dropdown
      clearable
      label="Appointment"
      loading={fetching}
      name="appointmentID"
      onChange={props.onChange}
      options={appointments}
      placeholder="Optional Appointment"
      readonly={props.readonly}
      value={props.value}
    />
  )
}
