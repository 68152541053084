import { IState } from 'interfaces'
import { createSelector } from 'reselect'

const firebaseUser = (state: IState) => state.user.user
const ctUser = (state: IState) => state.user.ctUser

export const getFirebaseUser = createSelector(
  [firebaseUser],
  (fbUser) => fbUser,
)

export const getFirebaseUserID$ = createSelector(
  [firebaseUser],
  (firebaseUser) => firebaseUser.uid,
)

export const getCTUserID$ = createSelector([ctUser], (ctUser) => ctUser.id)
export const getCTUser$ = createSelector([ctUser], (ctUser) => ctUser)
