import React from 'react'

import { Segment } from 'components/ui'

import { toCamelCase } from './json-parsing-fix'
import { useSelector } from 'react-redux'
import { getFormTemplates$ } from 'containers/forms'
import { getClientTypeFromID } from 'containers/settings'
import { IClient, IState } from 'interfaces'
import { getFormLabelFromID } from '../utils'
import { LabelText } from '../components/label-text'

interface Props {
  value: string
}

export const ClientValue = ({ value }: Props) => {
  const clientValue: IClient = (value && JSON.parse(value, toCamelCase)) || {}
  const templates = useSelector(getFormTemplates$)
  const clientType = useSelector((state: IState) =>
    getClientTypeFromID(state, clientValue.clientTypeID),
  )
  const getSingleDetailValue = (): any[] =>
    getFormLabelFromID(clientValue, templates, null, ['treatmentNumber'])

  const getFullClientDetailValue = (): any[] => {
    const specialCaseFcn = (key: string) => {
      if (key === 'clientTypeID') {
        return {
          key,
          label: 'Client Type',
          value: clientType?.name,
        }
      }

      return false
    }

    return getFormLabelFromID(clientValue, templates, specialCaseFcn, [
      'treatmentNumber',
    ])
  }

  const detailValues = Object.keys(clientValue).includes('id')
    ? getFullClientDetailValue()
    : getSingleDetailValue()

  if (!clientValue) {
    return null
  }

  return (
    <Segment>
      {detailValues.length === 0 && (
        // TODO - orphaned data?
        <div style={{ display: 'flex' }}>
          <LabelText label="Unknown Label" value={clientValue?.value} />
        </div>
      )}

      {detailValues.map((k: any) => {
        if (!k) {
          return null
        }

        // if (!clientValue[k] && !clientValue['value']) {
        //   value = (
        //     <div key={k} style={{ display: 'flex' }}>
        //       <Icon name="ban" />
        //       <Text>{`<Deleted>`}</Text>
        //     </div>
        //   )
        // }

        return <LabelText key={k.value} label={k.label} value={k.value} />
      })}

      <LabelText label="Treatment Number" value={clientValue.treatmentNumber} />

      <LabelText
        label="New Client Treatment Notes"
        value={clientValue.newClientTreatmentNotes}
      />

      {clientValue.fields && (
        <LabelText label="Fields" value={JSON.stringify(clientValue.fields)} />
      )}

      {typeof clientValue.archived !== 'undefined' && <LabelText label='Archived' value={`${clientValue.archived}`} />}
    </Segment>
  )
}
