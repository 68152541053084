import React from 'react'
import { Circle, Group, Text } from 'react-konva'

import { isDarkColour } from 'containers/draw/components/colour-picker'
import { TextColourDark, TextColour } from 'styles/colours'
import { ShapeProps } from './interface'

// TODO - see if this is good design wise
//      - if so, extract to helper function
const getTextFillColour = (colour: string) => {
  if (isDarkColour(colour)) {
    return TextColourDark
  }

  return TextColour
}

const ColouredCircle = ({
  x,
  y,
  width,
  height,
  colour,
  onClick,
  onTap,
  order,
  onDragEnd,
  onDragStart,
  onMouseLeave,
  onMouseOver,
  readonly,
}: ShapeProps) => (
  <Group>
    <Circle
      draggable={!readonly}
      fill={colour}
      height={height}
      onClick={onClick}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTap={onTap}
      opacity={0.75}
      radius={width / 2}
      width={width}
      x={x}
      y={y}
    />
    <Text
      draggable={!readonly}
      fill={getTextFillColour(colour)}
      fontFamily="Calibri"
      fontSize={24}
      name="text"
      onClick={onClick}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTap={onTap}
      text={`${order}`}
      x={order > 9 ? x - 12 : x - 6}
      y={y - 10}
    />
  </Group>
)

export default ColouredCircle
