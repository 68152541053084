import { IState } from 'interfaces'
import { createSelector } from 'reselect'
import { Service } from '..'

export const getServiceObj = (state: IState) => state.services.services
const getServiceIDs = (state: IState) => state.services.allIDs

export const getServices$ = createSelector(
  [getServiceObj, getServiceIDs],
  (serviceObj, serviceIDs): Service[] => {
    const services = []

    for (const serviceID of serviceIDs) {
      services.push(serviceObj[serviceID])
    }

    services.sort((a: Service, b: Service) => Number(a.cost) - Number(b.cost))

    return services
  },
)

/**
 * Returns true if we have fetched the services had have some
 * @param state
 * @returns
 */
export const hasPotentialServices$ = (state: IState) =>
  state.services.flags.fetched ? state.services.totalServices > 0 : true
