import React from 'react'

import { IPaginationProps } from 'interfaces'
import { Table as SemanticTable } from 'semantic-ui-react'
import styled from 'styled-components'
import { H4 } from './header'
import { Pagination } from './pagination'
import { Spinner } from 'components/loaders'
import { Message } from '.'

const Table = styled(SemanticTable)`
  &&&&& {
    background-color: ${(props) => props.theme.BackgroundColour};
    color: ${(props) => props.theme.TextColour};

    border-color: ${(props) => props.theme.InputBorderColour};
  }
`

interface TableRowProps {
	clickable?: boolean
}

const TableRow = styled(Table.Row)<TableRowProps>`
  ${(props) =>
          props.selected
                  ? `background-color: ${props.theme.ListItemActiveColour}`
                  : null};

  :hover {
    background-color: ${(props) => props.theme.ListItemHoverColour};
    cursor: ${(props) => (props.clickable ? 'pointer' : null)};
  }
`

const TableCell = styled(Table.Cell)`
  &&&& {
    border-bottom: 1px solid ${(props) => props.theme.InputBorderColour} !important;
  }
`

const TableHeaderCell = styled(Table.HeaderCell)`
  &&&&& {
    background-color: ${(props) => props.theme.BackgroundColour};
    color: ${(props) => props.theme.TextColour};

    border-color: ${(props) => props.theme.InputBorderColour};
  }
`

interface Props {
	// TODO - re-enable most of these props
	// activeItem?: any
	headers?: string[]
	items: Array<any>
	itemType: string
	loading?: boolean
	noItemsMessage?: string
	// onClickOverride?: Function
	pagination: IPaginationProps
	renderer: Function
	// search?: ISearch
	// searchTerm?: string
}

const TableRenderer = (props: Props) => {
	return (
		<Spinner spinning={props.loading}>
			<Table>
				<Table.Header>
					<Table.Row>
						{props.headers?.map((header) => (
							<TableHeaderCell key={header}>{header}</TableHeaderCell>
						))}
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{props.items?.map?.((item) => props.renderer(item))}
					{!props.items ||
						(props.items.length <= 0 && (
							<Message content={props.noItemsMessage} info />
						))}
				</Table.Body>
			</Table>

			{props?.pagination?.usePagination &&
				props.pagination?.totalItems > props.pagination?.pageSize && (
					<Pagination
						activePage={props.pagination?.activePage}
						className='stackable'
						firstItem={null}
						lastItem={null}
						onPageChange={props.pagination?.onPageChange}
						pointing
						secondary
						siblingRange={0}
						size='tiny'
						stackable
						style={{ 'margin-top': '0.5em' }}
						totalPages={Math.ceil(
							props.pagination?.totalItems / props.pagination?.pageSize,
						)}
					/>
				)}
			{props.pagination?.totalItems > props.pagination?.pageSize && (
				<H4>{`${props.pagination?.totalItems} ${props.itemType}`}</H4>
			)}
		</Spinner>
	)
}

export { Table, TableRenderer, TableRow, TableCell }
