import { authGet } from 'lib/http-request'
import { Treatments } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { IResponse } from 'interfaces/fetch'
import { Treatment, IAction } from 'interfaces'

const treatmentAction = (action: string) =>
  `client-trackyr/treatments/${action}`
const FETCH_TREATMENTS_FOR_CLIENT = treatmentAction(
  'FETCH_TREATMENTS_FOR_CLIENT',
)
const FETCH_TREATMENTS_SUCCESS = treatmentAction('FETCH_TREATMENTS_SUCCESS')
const FETCH_TREATMENTS_FAILURE = treatmentAction('FETCH_TREATMENTS_FAILURE')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_TREATMENTS_FOR_CLIENT:
      return {
        ...state,
        fetchingTreatments: true,
        [action.clientID]: {
          fetched: false,
          allByID: [],
        },
      }

    case FETCH_TREATMENTS_SUCCESS: {
      const treatments = {}
      const allByID = []

      for (const treatment of action.treatments) {
        // Don't include userID
        const { id, userID, ...rest } = treatment // eslint-disable-line no-unused-vars
        treatments[id] = {
          id,
          ...rest,
        }

        allByID.push(id)
      }

      const mostRecentTreatment = Math.max.apply(
        Math,
        action.treatments.map((t: Treatment) => t.treatmentNumber),
      )

      return {
        ...state,
        fetchingTreatments: false,
        [action.clientID]: {
          ...state[action.clientID],
          ...treatments,
          currentTreatment: action.treatments.find(
            (t: Treatment) => t.treatmentNumber === mostRecentTreatment,
          ).id,
          fetched: true,
          allByID,
        },
      }
    }

    case FETCH_TREATMENTS_FAILURE:
      return {
        ...state,
        fetchingTreatments: false,
      }

    default:
      return state
  }
}

export const fetchTreatmentsForClient = (clientID: string): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_TREATMENTS_FOR_CLIENT, clientID })

  return authGet(Treatments.GetTreatments(clientID))
    .then((response: IResponse<Array<Treatment>>) => {
      dispatch(fetchTreatmentsSuccess(response.data, clientID))
      return response
    })
    .catch((error) => {
      captureMessage(
        `fetchTreatmentsForClient Failed. ${error}`,
        Severity.Error,
      )
      dispatch(fetchTreatmentsFailure(error, clientID))

      return error
    })
}

export const fetchTreatmentsSuccess = (
  treatments: Array<Treatment>,
  clientID: string,
) => ({
  type: FETCH_TREATMENTS_SUCCESS,
  treatments,
  clientID,
})

export const fetchTreatmentsFailure = (error: any, clientID: string) => ({
  type: FETCH_TREATMENTS_FAILURE,
  error,
  clientID,
})
