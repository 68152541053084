import { authPost } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

const filesAction = (action) => `client-trackyr/files/${action}`
const FETCH_PUBLIC_FILE_URL = filesAction('FETCH_PUBLIC_FILE_URL')
const FETCH_PUBLIC_FILE_URL_SUCCESS = filesAction(
  'FETCH_PUBLIC_FILE_URL_SUCCESS',
)
const FETCH_PUBLIC_FILE_URL_FAILURE = filesAction(
  'FETCH_PUBLIC_FILE_URL_FAILURE',
)
const PUBLIC_URL_NOT_FOUND = filesAction('PUBLIC_URL_NOT_FOUND')

export const defaultState = {
  fetchingPublicFileUrl: false,
  publicFileURlNotFound: false,
}

export const reducer = (state: any = defaultState, action) => {
  switch (action.type) {
    case FETCH_PUBLIC_FILE_URL: {
      return {
        ...state,
        fetchingPublicFileUrl: true,
        publicFileURlNotFound: false,
      }
    }

    case FETCH_PUBLIC_FILE_URL_SUCCESS: {
      const file = action.file

      return {
        ...state,
        fetchingPublicFileUrl: false,
        endpoints: {
          ...state.endpoints,
          [action.file.blobStorageFileID]: file.shareID,
        },
      }
    }

    case FETCH_PUBLIC_FILE_URL_FAILURE:
      return {
        ...state,
        fetchingPublicFileUrl: false,
        clientFilesError: action.error,
      }

    case PUBLIC_URL_NOT_FOUND:
      return {
        ...state,
        fetchingPublicFileUrl: false,
      }

    default:
      return state
  }
}

export const fetchPublicFileURL = (
  blobStorageFileID,
  useCache = false,
): any => async (dispatch): Promise<any> => {
  dispatch({ type: FETCH_PUBLIC_FILE_URL, blobStorageFileID })

  return authPost(
    Storage.ShareLink,
    {
      blobStorageFileID,
      duration: null,
      sharedFileType: 1,
    },
    { cache: useCache },
  )
    .then((response) => {
      dispatch(fetchPublicFileURLSuccess(response.data))
      return response
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch(fileNotFound())
      }

      captureMessage(
        `Failed to fetch Public File URL. BlobID: ${blobStorageFileID}. ${error}`,
        Severity.Error,
      )
      dispatch(fetchPublicFileURLFailure(error))
      return error
    })
}

const fetchPublicFileURLSuccess = (file) => ({
  type: FETCH_PUBLIC_FILE_URL_SUCCESS,
  file,
})

const fetchPublicFileURLFailure = (error) => ({
  type: FETCH_PUBLIC_FILE_URL_FAILURE,
  error,
})

const fileNotFound = () => ({
  type: PUBLIC_URL_NOT_FOUND,
})
