import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import {
	Label,
	Text,
	Icon,
	Image,
	PDFView,
	SelectableText,
	Input,
	LinkText,
	Form,
	FormField,
	SubText,
	TextArea,
} from 'components/ui'
import { IEvent, IFile } from 'interfaces'
import { getAmountUsedText } from 'lib'
import { getContentType, isImageContentType, isPDFContentType } from 'lib/file'
import { MobileBreakPoint } from 'styles'
import { getClientFullName, getClients$ } from 'containers/clients'
import { useNavigate } from 'react-router-dom'

const Area = styled.div`
  display: grid;
  grid-template-areas: 'details preview';

  @media (max-width: ${MobileBreakPoint}) {
    grid-template-areas:
      'preview'
      'details';
  }
`

const PreviewContainer = styled.div`
  grid-area: preview;
  padding: 1em;
  max-width: 384px;
  max-height: 384px;
  margin: auto;

  @media (max-width: ${MobileBreakPoint}) {
    max-width: 256px;
    max-height: 256px;
  }
`

const DetailsContainer = styled.div`
  grid-area: details;
  padding: 1em 0 1em 2em;

  @media (max-width: ${MobileBreakPoint}) {
    padding: 1em 0 1em 1em;
  }
`

const NonImagePreview = styled(Icon)`
  margin: auto;
  font-size: 256px !important;

  @media (max-width: ${MobileBreakPoint}) {
    visibility: hidden;
    height: 0;
    font-size: 0 !important;
  }
`

interface Props {
	editing?: boolean
	file: IFile
	fileExtension?: string
	filePathUrl?: string
	onChange: (e: IEvent) => void
	readonly?: boolean
}

const FileDetails = (props: Props) => {
	const navigate = useNavigate()
	const clients = useSelector(getClients$)
	const [editing, setEditing] = useState(props.editing)

	const getClientID = () => {
		const strtok = props.file.storagePath.split('/clients/')
		const clientID = strtok[1].substr(0, strtok[1].lastIndexOf('/'))

		return clientID
	}

	const getClientInfo = () => {
		const clientID = getClientID()
		const client = clients[clientID]
		return getClientFullName(client)
	}

	const storagePath = props.file.storagePath || ''
	const isImage = isImageContentType(props.file)
	const isPDF = isPDFContentType(props.file)
	const contentType = getContentType(props.file)

	const getFileName = (fileName: string): string => {
		const tokens = fileName.split('.')

		if (tokens.length === 1) {
			return fileName
		}

		tokens.pop()

		return tokens.join('.')
	}

	return (
		<>
			<Area>
				<PreviewContainer>
					{isImage && props.filePathUrl ? (
						<Image src={props.filePathUrl} />
					) : isPDF ? (
						<PDFView filePathUrl={props.filePathUrl} firstPage scale={0.6} />
					) : (
						<NonImagePreview name={contentType} />
					)}
				</PreviewContainer>

				<DetailsContainer>
					{editing ? (
						<>
							<Label>File Name</Label>
							<div style={{ display: 'flex' }}>
								<Input
									name='fileName'
									onChange={props.onChange}
									placeholder={getFileName(props.file.fileName)}
									style={{ padding: '1em 0em', width: '90%' }}
									value={getFileName(props.file.fileName)}
								/>
								<Text fontSize={18} style={{ padding: '1em' }}>
									{props.fileExtension}
								</Text>
							</div>
						</>
					) : props.readonly ? (
						<>
							<Label>File Name</Label>
							<Text>{props.file.fileName}</Text>
						</>
					) : (
						<>
							<Label>File Name</Label>
							<SelectableText onClick={() => setEditing(true)}>
								{props.file.fileName}
							</SelectableText>
						</>
					)}

					{storagePath.includes('/clients/') && (
						<>
							<Label>File for Client</Label>
							<LinkText onClick={() => navigate(`/client/${getClientID()}/files`)}>
								{getClientInfo()}
							</LinkText>
						</>
					)}

					<Label>Size</Label>
					<Text>{getAmountUsedText(props.file.fileSize)}</Text>

					<Label>Type</Label>
					<Text>{props.file.contentType}</Text>

					<Label>Date Created</Label>
					<Text>
						{moment(props.file.dateCreated).format('MMMM D YYYY h:mm a')}
					</Text>

					<Label>Date Modified</Label>
					<Text>
						{moment(props.file.dateModified).format('MMMM D YYYY h:mm a')}
					</Text>

					<Form>
						<FormField>
							<Label>Tags</Label>
							<Input
								name='tags'
								onChange={props.onChange}
								placeholder='Tags'
								value={props.file.tags}
							/>
							<SubText>Comma Seperated Tags</SubText>
						</FormField>

						<FormField>
							<Label>Notes</Label>
							<TextArea
								name='notes'
								onChange={props.onChange}
								placeholder='Any notes about the file'
								value={props.file.notes}
							/>
						</FormField>
					</Form>
				</DetailsContainer>
			</Area>
		</>
	)
}

export { FileDetails }
