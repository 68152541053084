import styled from 'styled-components'

const Bar = styled.div`
  position: relative;
  border-radius: 25px;
  height: 1em;
  width: 100%;
  background-color: ${(props) => props.theme.NeutralColourThree};
  padding: 10px;
  padding: 10px;
  box-shadow: inset 0 -1px 1px ${(props) => props.theme.ShadowColour};
`

interface IQuota {
  used: number
  type: string
}

const Quota = styled.span<IQuota>`
  width: ${(props) => `${props.used}%`};
  display: block;
  height: 1em;
  background-color: ${(props) =>
    props.used >= 90 && props.type === 'quota'
      ? props.theme.ProgressIndicatorNearlyFullWarningColour
      : props.theme.ProgressIndicatorColour};

  border-radius: 25px;

  position: relative;
  overflow: hidden;
  top: -0.5em;
  left: -0.5em;
`

export const Progress = ({ percent = 0, type = 'progress' }: IProgress) => {
  /* + 2.5 b/c it's the offset needed, without this 100% doesn't look like 100% */
  const p = percent > 0 ? (percent >= 100 ? 102.5 : percent + 2.5) : 0

  return (
    <Bar>
      <Quota type={type} used={p} />
    </Bar>
  )
}

interface IProgress {
  percent: number
  type?: 'progress' | 'quota'
}
