import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Divider, Form, Label } from 'components/ui'
import { Dropdown, Input } from 'components/forms'
import { IEvent } from 'interfaces'
import { getTabTypes } from '..'
import { FormTypeTabs } from './form-type-tabs'
import { FormTypeColourPicker } from './form-type-colour-picker'
import { FormTypePanel } from './form-type-panel'
import { Forms_T, FormTabs_T, Form_T } from './new-interfaces'
import { FAMILY_SELECTED_TYPE, INDIVIDUAL_SELECTED_TYPE } from 'constants/form'

interface Props {
  formData: Form_T
  disabled?: boolean
  formTypeName: string
  setFormData: (data: Form_T) => void
}

const NameFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const ContLeft = styled.div`
  width: 30%;
  padding-right: 1em;

  @media (max-width: 760px) {
    width: 100%;
    padding-right: 0;
  }
`
const Cont = styled.div`
  width: 70%;
  padding-left: 1em;

  @media (max-width: 760px) {
    padding-left: 0;
    width: 100%;
  }
`

export const NewFormTypeDetails = (props: Props) => {
  const [formData, setFormData] = useState(props.formData)
  const globalTabs = useSelector(getTabTypes)
  // Tabs now hold form data too, so some fo the setTabs stuff is setting the form data
  const [tabs, setTabs] = useState(formData.formTabs || [...globalTabs])

  const setData = ({ t = null, d = {} }) => {
    const data: Form_T = {
      ...formData,
      ...d,
      formTabs: t || tabs,
    }
    props.setFormData(data)
  }

  // TODO - onset form data?
  const onSetTabs = (tabs: any[]) => {
    setTabs([...tabs])
    setData({ t: [...tabs] })
  }

  const onTabDetailsChange = ({ target }: IEvent, tab: any) => {
    const newTabs = [...tabs]
    const newTab = newTabs.find((t) => t.id === tab.id)
    newTab[target.name] = target.value
    onSetTabs(newTabs)
  }

  const onFormDetailsChange = ({ target }: IEvent) => {
    const data = {
      ...formData,
      [target.name]: target.value,
    }

    setFormData(data)
    setData({ d: data })
  }

  const onTabChanged = (tabs: Array<any>) => {
    onSetTabs([...tabs])
    setData({ t: [...tabs] })
  }

  const addForm = (t: FormTabs_T) => {
    const tab = { ...t }

    if (!tab.forms) {
      tab.forms = [{ formID: '', order: 0 }]
    }

    tab.forms.push({ formID: '', order: tab.forms.length - 1 })

    const newTabs = [...tabs]
    const i = newTabs.findIndex((nt) => nt.id === tab.id)
    newTabs[i] = tab
    onSetTabs(newTabs)
  }

  const onFormTypeChange = (
    { target }: IEvent,
    index: number,
    t: FormTabs_T,
  ) => {
    const tab = { ...t }

    if (!tab.forms) {
      tab.forms = [{ formID: '', order: 0 }]
    }

    tab.forms[index] = { formID: target.value, order: index }

    const newTabs = [...tabs]
    const i = newTabs.findIndex((nt) => nt.id === tab.id)
    newTabs[i] = tab
    onSetTabs(newTabs)
  }

  const onFormTypesSort = (items: Forms_T[], t: FormTabs_T) => {
    const tab: FormTabs_T = { ...t }
    tab.forms = items.map((item, index) => ({ ...item, order: index }))
    const newTabs = [...tabs]
    const i = newTabs.findIndex((nt) => nt.id === tab.id)
    newTabs[i] = tab
    onSetTabs(newTabs)
  }

  const removeForm = (index: number, t: FormTabs_T) => {
    const tab = { ...t }

    if (!tab.forms) {
      return
    }

    tab.forms.splice(index, 1)
    const newTabs = [...tabs]
    const i = newTabs.findIndex((nt) => nt.id === tab.id)
    newTabs[i] = tab
    setTabs(newTabs)
  }

  return (
    <>
      <div>
        <NameFormContainer>
          <ContLeft>
            <Form>
              <Input
                disabled={props.disabled || formData.isDefault}
                label={
                  props.formTypeName ? `${props.formTypeName} Type Name` : ''
                }
                name="name"
                onChange={onFormDetailsChange}
                placeholder={`${props.formTypeName} Type Name`}
                validationRules={{ required: true }}
                value={formData.name}
              />

              <Dropdown
                disabled={props.disabled || formData.isDefault}
                label="Type"
                name="selectedType"
                onChange={onFormDetailsChange}
                options={[
                  {
                    key: INDIVIDUAL_SELECTED_TYPE,
                    id: INDIVIDUAL_SELECTED_TYPE,
                    text: 'Individual',
                    value: INDIVIDUAL_SELECTED_TYPE,
                  },
                  {
                    key: FAMILY_SELECTED_TYPE,
                    id: FAMILY_SELECTED_TYPE,
                    text: 'Family',
                    value: FAMILY_SELECTED_TYPE,
                  },
                ]}
                style={{ width: '15em' }}
                value={formData.selectedType || INDIVIDUAL_SELECTED_TYPE}
              />
            </Form>
          </ContLeft>

          <Cont>
            <FormTypeTabs
              disabled={props.disabled || formData.isDefault}
              setTabs={onTabChanged}
              tabs={tabs}
            />
          </Cont>
        </NameFormContainer>
      </div>

      <Divider />

      <div style={{ marginTop: '1em' }}>
        <Label>Forms for Tabs</Label>

        <FormTypePanel
          addForm={addForm}
          data={formData}
          disabled={props.disabled}
          isDefault={formData.isDefault}
          onFormTypeChange={onFormTypeChange}
          onFormTypesSort={onFormTypesSort}
          onTabDetailsChange={onTabDetailsChange}
          removeForm={removeForm}
          tabs={tabs}
        />
      </div>

      <Divider />

      <div style={{ marginTop: '1em' }}>
        <FormTypeColourPicker
          colour={formData.appointmentColour}
          disabled={props.disabled}
          onChange={onFormDetailsChange}
        />
      </div>
    </>
  )
}
