import { Dropdown } from '.'
import { IPhone } from 'components/phone-number/phone-number'

interface Props {
  name: string
  phoneNumbers: IPhone[]
  onChange: any
  value: string
}

export const getDefaultPhoneNumber = (phoneNumbers: IPhone[]): IPhone => {
  const defaultPhoneNumber =
    phoneNumbers?.find((p) => p.type === 'mobile') || phoneNumbers?.[0]
  return defaultPhoneNumber || {}
}

export const PhoneNumberDropdown = (props: Props) => {
  const options = props.phoneNumbers.map((phone: IPhone) => ({
    key: phone.phoneNumber,
    id: phone.phoneNumber,
    text: `(${phone.type}) ${phone.phoneNumber}`,
    value: phone.phoneNumber,
  }))

  const defaultPhoneNumber = getDefaultPhoneNumber(props.phoneNumbers)

  return (
    <Dropdown
      name={props.name}
      onChange={props.onChange}
      options={options}
      value={props.value || defaultPhoneNumber?.phoneNumber}
    />
  )
}
