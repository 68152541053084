import {
  reducer as fetchAllPublicIntakeForms,
  defaultState as fetchAllPublicIntakeFormsDefaultState,
} from './fetch-all-public-intake-forms'
import {
  reducer as createPublicIntakeForm,
  defaultState as createPublicIntakeFormDefaultState,
} from './create-public-intake'
import {
  reducer as closePublicIntake,
  defaultState as closePublicIntakeDefaultState,
} from './close-public-intake'
import {
  reducer as resendPublicIntakeFormEmail,
  defaultState as resendPublicIntakeFormEmailState,
} from './resend-public-intake-email'
import { reducer as fetchPublicIntake } from './fetch-public-intake-by-id'
import { IAction, ErrorState } from 'interfaces'

const CLEAR_SENDING_PUBLIC_INTAKE_EMAIL_ERROR =
  'client-trackyr/public-intake/CLEAR_SENDING_PUBLIC_INTAKE_EMAIL_ERROR'

const defaultState = {
  ...fetchAllPublicIntakeFormsDefaultState,
  ...createPublicIntakeFormDefaultState,
  ...closePublicIntakeDefaultState,
  ...resendPublicIntakeFormEmailState,
  sendingPublicIntakeEmail: true,
  sendingPublicIntakeEmailError: { hasError: false } as ErrorState,
}

const reducers = [
  fetchAllPublicIntakeForms,
  createPublicIntakeForm,
  closePublicIntake,
  resendPublicIntakeFormEmail,
  fetchPublicIntake,
]

const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case CLEAR_SENDING_PUBLIC_INTAKE_EMAIL_ERROR:
      return {
        ...state,
        sendingPublicIntakeEmailError: { hasError: false },
      }
    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

const clearSendingPublicIntakeEmailError = () => ({
  type: CLEAR_SENDING_PUBLIC_INTAKE_EMAIL_ERROR,
})

export { reducer, defaultState, clearSendingPublicIntakeEmailError }
