import React from 'react'

import { authGetImage } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { SImage as Image, Segment } from 'components/ui'
import { Spinner } from 'components/loaders'

interface Props {
	height?: number
	imageID: string
	size?: number
	width?: number
	style?: any
}

export default class CTImage extends React.Component<Props> {
	state = {
		img: '',
		loading: false,
		error: false,
	}

	componentDidMount() {
		this.fetchImage()
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.imageID !== this.props.imageID) {
			this.fetchImage()
		}
	}

	fetchImage = () => {
		if (this.props.imageID) {
			this.setState(() => ({ loading: true }))
			authGetImage(
				Storage.GetFile(this.props.imageID),
				this.props.imageID,
			).then((response) => {
				var uri = URL.createObjectURL(response.data)
				this.setState(() => ({ img: uri, loading: false }))
			})
				.catch(() => this.setState(() => ({ loading: false, error: true })))
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<Segment>
					<Spinner size='large' spinning />
				</Segment>
			)
		}

		// if (this.state.error) {
		//
		// }

		if (this.props.size) {
			return (
				<Image
					centered
					height={this.props.size}
					src={this.state.img}
					style={this.props.style}
					width={this.props.size}
				/>
			)
		} else if (this.props.height && this.props.width) {
			return (
				<Image
					centered
					height={this.props.height}
					src={this.state.img}
					style={this.props.style}
					width={this.props.width}
				/>
			)
		} else {
			return <Image centered src={this.state.img} style={this.props.style} />
		}
	}
}
