import { captureException } from '@sentry/browser'
import { Soaps } from 'constants/endpoints'
import { GetAllResponse, IResponse, Note } from 'interfaces'
import { authGet } from 'lib/http-request'

export const getNotesForSelectedAppointments = async (
  appointmentIDs: string[],
  clientID?: string,
): Promise<GetAllResponse<Note>> => {
  const queryParams = {
    clientID,
    appointmentIDs: JSON.stringify(appointmentIDs),
  }

  try {
    const response: IResponse<GetAllResponse<Note>> = await authGet(
      Soaps.GetAll(queryParams),
    )

    if (response.status === 200) {
      return response.data
    }
  } catch (ex) {
    captureException(ex)
    throw ex
  }
}
