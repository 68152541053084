import { Error, IResponse } from 'interfaces'

export const getErrorMessage = (error: Error): string => {
  if (error?.message === 'Network Error') {
    return 'Unable to connect to the server, check your network settings or try again later'
  }

  const response = error.response

  if (response.status >= 400 || response.status < 600) {
    return response?.data || getStatusText(response)
  }

  return ''
}

export const getErrorHeader = (error: any): string => {
  if (error?.message === 'Network Error') {
    return 'Network Error'
  }

  const response = error.response

  if (response.status >= 400 || response.status < 600) {
    return getStatusText(response)
  }

  return ''
}

const getStatusText = (errorResponse: IResponse<any>): string => {
  return `${errorResponse.status} ${errorResponse.statusText}`
}
