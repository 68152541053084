import { authPost } from 'lib/http-request'
import { Tasks } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import {
  IAction,
  EmailState,
  Dispatch,
  IAppointment,
  Task,
  TaskType,
} from 'interfaces'
import moment from 'moment'

export const defaultState = {
  flags: {
    sendingAppointmentEmailReminder: false,
    sendingAppointmentEmailReminderError: {},
  },
}

const emailsAction = (action: string) => `client-trackyr/emails/${action}`
const SETUP_APPOINTMENT_EMAIL_REMINDER = emailsAction(
  'SETUP_APPOINTMENT_EMAIL_REMINDER',
)
const SETUP_APPOINTMENT_EMAIL_REMINDER_SUCCESS = emailsAction(
  'SETUP_APPOINTMENT_EMAIL_REMINDER_SUCCESS',
)
const SETUP_APPOINTMENT_EMAIL_REMINDER_FAILURE = emailsAction(
  'SETUP_APPOINTMENT_EMAIL_REMINDER_FAILURE',
)

export const reducer = (
  state = defaultState as EmailState,
  action: IAction,
) => {
  switch (action.type) {
    case SETUP_APPOINTMENT_EMAIL_REMINDER:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentEmailReminder: true,
          sendingAppointmentEmailReminderError: {},
        },
      }

    case SETUP_APPOINTMENT_EMAIL_REMINDER_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentEmailReminder: false,
          sendingAppointmentEmailReminderError: {},
        },
      }

    case SETUP_APPOINTMENT_EMAIL_REMINDER_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentEmailReminder: false,
          sendingAppointmentEmailReminderError: { ...action.error },
        },
      }

    default:
      return state
  }
}

export const setupAppointmentEmailReminder = (
  appointment: IAppointment,
  showNotification = false,
): any => async (dispatch: Dispatch): Promise<any> => {
  dispatch({
    type: SETUP_APPOINTMENT_EMAIL_REMINDER,
    appointment,
    showNotification,
  })

  const startTime = moment.utc(appointment.start).subtract(1, 'days')

  const task: Task = {
    entityID: appointment.id,
    time: startTime,
    type: TaskType.EmailReminder,
  }

  return authPost(Tasks.Post, task)
    .then((response) => {
      dispatch(onSendSuccess())
      showNotification && message.success('Reminder Setup')
      return response
    })
    .catch((error) => {
      message.error(
        'Failed to Setup Appointment Reminder Email, please try again later',
      )
      captureMessage(`Failed to Send Email: ${error}`, Severity.Error)
      dispatch(onSendFailure(error))

      return error
    })
}

const onSendSuccess = () => ({ type: SETUP_APPOINTMENT_EMAIL_REMINDER_SUCCESS })

const onSendFailure = (error: any) => ({
  type: SETUP_APPOINTMENT_EMAIL_REMINDER_FAILURE,
  error,
})
