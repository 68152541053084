import { reducer as fetchCurrentTreatment } from './fetch-treatments'
import { reducer as closeTreatment } from './close-treatment'
import { reducer as saveTreatment } from './save-treatment'
import {
  reducer as fetchAllTreatments,
  defaultState as fetchAllTreatmentsDefaultState,
} from './fetch-all-treatments'

import { unsetSelectedSoap } from 'containers/soap-notes/redux/ducks'
import { unselectAppointment } from 'containers/appointments'
import { IAction } from 'interfaces'

const treatmentAction = (action: string) =>
  `client-trackyr/treatments/${action}`
const SELECT_TREATMENT = treatmentAction('SELECT_TREATMENT')

export const defaultState = {
  ...fetchAllTreatmentsDefaultState,
  closingTreatment: false,
  fetchingPreviousTreatments: false,
  fetchingTreatments: false,
  previous: {},
  treatments: {},
  savingTreatment: false,
}

const reducers = [
  fetchCurrentTreatment,
  closeTreatment,
  saveTreatment,
  fetchAllTreatments,
]

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SELECT_TREATMENT:
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          selectedTreatment: action.treatmentID,
        },
      }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const onSelectTreatment = (treatmentID: number, clientID: string) => ({
  type: SELECT_TREATMENT,
  treatmentID,
  clientID,
})

export const selectTreatment = (
  clientID: string,
  selectedTreatment: number,
) => (dispatch: any) => {
  dispatch(unselectAppointment())
  dispatch(unsetSelectedSoap())
  dispatch(onSelectTreatment(Number(selectedTreatment), clientID))
}
