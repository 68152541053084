import { Spinner } from 'components/loaders'
import { H3, H4 } from 'components/ui'
import { getSelectedClientsFullName$ } from 'containers/clients'
import { getFormTemplates$ } from 'containers/forms'
import { getSoapTypesFromSettings$ } from 'containers/settings'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getNotesForSelectedAppointments } from './get-notes-for-selected-appointments'
import { MultiFormPrint, MultiFormPrintItem } from './interfaces'
import { PrePrintModal } from './pre-print-modal'

interface Props {
  appointmentsToPrint: string[]
  clientID?: string
}

export const AppointimentsPrint = (props: Props) => {
  const [fetching, setFetching] = useState(false)
  const [multiForms, setMultiForms] = useState([])

  const soapTypes = useSelector(getSoapTypesFromSettings$)
  const formTemplates = useSelector(getFormTemplates$)
  const clientName = useSelector(getSelectedClientsFullName$)

  const fetch = useCallback(async () => {
    setFetching(true)
    const response = await getNotesForSelectedAppointments(
      props.appointmentsToPrint,
      props.clientID,
    )

    const items = response.items
    const mf = []

    for (const item of items) {
      const multiFormItem = {} as MultiFormPrintItem
      const appointment = item.appointment
      const header = (
        <div>
          <H3 style={{ marginBottom: 0 }}>
            {`#${appointment.appointmentNumber}: ${moment(
              appointment.start,
            ).format('MMM D YYYY h:mm a')} - ${moment(appointment.end).format(
              'h:mm a',
            )}`}
          </H3>
          {/*  */}
        </div>
      )

      const soapType = soapTypes[item.soapTypeID]

      const soapForms = soapType.forms
        ?.map((sf: any) => ({
          ...formTemplates[sf.formID],
          order: sf.order,
        }))
        .sort((a, b) => a.order - b.order)

      multiFormItem.header = header
      multiFormItem.data = item.fields
      multiFormItem.fileJson = soapForms
      mf.push(multiFormItem)
    }

    setMultiForms(mf)
    setFetching(false)
  }, [])

  useEffect(() => {
    if (props.appointmentsToPrint.length > 0) {
      fetch()
    }
  }, [props.appointmentsToPrint])

  // Fetch all Appointments
  // Fetch all Notes

  // Build list, map list to FormToPrint
  // Print

  if (fetching) {
    return <Spinner spinning={true} tip="Loading..." />
  }

  if (props.appointmentsToPrint.length <= 0) {
    return null
  }

  const multiForm: MultiFormPrint = {
    header: (
      <H4
        style={{
          marginTop: 0,
          marginBottom: '0.5em',
          textDecoration: 'underline',
        }}
      >{`Appointments for ${clientName}`}</H4>
    ),
    items: multiForms,
  }

  return (
    <PrePrintModal data={null} fileJson={null} multiForms={multiForm} primary />
  )
}
