import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { FilesUploadButton, fetchClientFiles } from 'containers/files'
import { Spinner } from 'components/loaders'
import {
	DocumentView,
	FlexContainer,
	IconButton,
	List,
	Divider,
	Responsive,
	H3,
	Popup,
	SelectableHeader,
} from 'components/ui'
import { getFileDocumentViewDriverSetting } from 'containers/settings'
import {
	FileDetailsModal,
	FileItem,
	getPublicSharedURLForSelectedFile,
	fetchPublicFileURL,
	fetchFile,
	selectFile,
} from 'containers/files'
import { Dispatch, IFile, IPaginationProps, ISearch, IState } from 'interfaces'
import { getContentType } from 'lib/file'
import { getAccountFormsByKey } from 'containers/account'
import { Storage } from 'constants/endpoints'
import { getClients$ } from 'containers/clients'
import { getClientFullName } from 'containers/clients'
import { IResponse } from 'interfaces/fetch'

interface IFileListPreview {
	appointmentID?: string
	files: Array<IFile>
	hideUpload?: boolean
	listHeader?: string
	onPageChange?: (activePage: string | number) => void
	pagination?: IPaginationProps
	searchTerm?: string
	setSearchTerm: Function
}

const FilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FullContainer = styled.div`
  width: 100%;
  height: 100%;
`

const FileListPreview = (props: IFileListPreview) => {
	const dispatch: Dispatch = useDispatch()

	const clients = useSelector(getClients$)
	const documentDriver = useSelector(getFileDocumentViewDriverSetting)
	const userID = useSelector((state: IState) => state.user.ctUser.id)
	const fileZoom = useSelector((state: IState) => state.files.fileZoom)
	const publicSharedURL = useSelector(getPublicSharedURLForSelectedFile)
	const fetchingClientFiles = useSelector(
		(state: IState) => state.files.fetchingClientFiles,
	)
	const fetchingAllFiles = useSelector(
		(state: IState) => state.files.flags.fetchingAllFiles,
	)
	const formTemplates = useSelector(getAccountFormsByKey)

	const [detailsOpen, setDetailsOpen] = useState(false)
	const [selectedFile, setSeletedFile] = useState({} as IFile)
	const [fileURL, setFileURL] = useState('')
	const [fetchingFile, setFetchingFile] = useState(false)
	const [mimeType, setMimeType] = useState('')
	const [fullscreen, setFullscreen] = useState(false)
	// const [searchTerm, setSearchTerm] = useState('')
	const [blobFile, setBlobFile] = useState(null)

	const { files } = props
	const filteredFiles = files
	const fetching = fetchingAllFiles || fetchingClientFiles || fetchingFile

	useEffect(() => {
		if (props.appointmentID) {
			setFileURL('')
			setSeletedFile({} as IFile)
		}
	}, [props.appointmentID])

	const getPublicURL = (file: IFile) => {
		const contentType = getContentType(file)

		if (
			documentDriver === 'default' ||
			(contentType !== 'file word' && contentType !== 'file excel')
		) {
			return
		}

		setFetchingFile(true)

		dispatch(fetchPublicFileURL(file.id)).then(() => {
			setFetchingFile(false)
		})
	}

	const fetchAndDisplayFile = (file: IFile) => {
		setSeletedFile(file)
		setFetchingFile(true)

		dispatch(selectFile(file.id))
		const contentType = file.contentType
		setMimeType(contentType)
		getPublicURL(file)

		dispatch(fetchFile(file.id))
			.then((response: IResponse<IFile>) => {
				const fileResponse = response.data ? response.data : response

				if (!fileResponse) {
					setFileURL('')
					setFetchingFile(false)
					return
				}

				const fileURL = URL.createObjectURL(fileResponse as any)
				setFileURL(fileURL)
				setBlobFile(fileResponse)
				setFetchingFile(false)
			})
			.catch(() => {
				setFileURL('')
				setFetchingFile(false)
			})
	}

	const downloadFile = (file: IFile) => {
		dispatch(fetchFile(file.id)).then((response: IResponse<IFile>) => {
			if (!response || !response.data) {
				return
			}

			const fileResponse = response.data ? response.data : response
			const fileUrl = URL.createObjectURL(fileResponse as any)

			setBlobFile(fileResponse)

			const link = document.createElement('a')
			link.download = file.fileName
			link.href = fileUrl
			link.click()
		})
	}

	const uploadButton = () =>
		props.hideUpload ? null : (
			<FilesUploadButton header='Upload File' url={Storage.Files} />
		)

	const renderList = () => {
		const searchProps: ISearch = {
			useSearch: true,
			onSearchFilter: ({ target }) => props.setSearchTerm(target.value),
			onSearchClear: () => props.setSearchTerm(''),
			searchTerm: props.searchTerm,
			searchLoading: fetching,
		}

		const paginationProps: IPaginationProps = props.pagination
			? {
				activePage: props.pagination.activePage,
				onPageChange: props.pagination.onPageChange,
				pageSize: props.pagination.pageSize,
				totalItems: props.pagination.totalItems,
				usePagination: props.pagination.usePagination,
			}
			: ({} as IPaginationProps)

		return (
			<List
				activeItem={{ id: selectedFile }}
				emptySelectText='Select File'
				items={filteredFiles}
				itemType='Files'
				listHeader={props.listHeader}
				listHeaderIcon='file alternate'
				noArrows
				noItemsMessage='No Files'
				pagination={paginationProps}
				renderer={(file: IFile) => {
					let clientName = null
					if (file.storagePath.includes('/forms/')) {
						const template = formTemplates[file.fileName]
						if (template) {
							const fileName = template.name
							file.fileName = fileName

							if (template.deleted) {
								file.fileName += ' (deleted form)'
							}
						}
					}

					if (file.storagePath.includes('/clients/')) {
						const strtok = file.storagePath.split('/clients/')
						const clientID = strtok[1].substr(0, strtok[1].lastIndexOf('/'))
						const client = clients[clientID]
						clientName = getClientFullName(client)
					}

					return (
						<FileItem
							clientName={clientName}
							file={file}
							getContentType={getContentType}
							onClick={fetchAndDisplayFile}
							selected={selectedFile}
						/>
					)
				}}
				search={searchProps}
				style={{ overflowY: 'scroll', height: '600px' }}
			/>
		)
	}

	return (
		<Spinner size='large' spinning={fetching}>
			<FileDetailsModal
				afterDeleteCallback={fetchClientFiles}
				file={selectedFile}
				filePathUrl={fileURL}
				modalOpen={detailsOpen}
				onClose={() => setDetailsOpen(false)}
				onDownload={() => downloadFile(selectedFile)}
			/>

			{!fullscreen && (
				<Responsive maxWidth={768}>
					<div>
						{uploadButton()}
						{renderList()}
					</div>
				</Responsive>
			)}
			<FilesContainer>
				{!fullscreen && (
					<Responsive minWidth={768}>
						<div style={{ minWidth: '15em', maxWidth: '30em' }}>
							<H3>{props.listHeader}</H3>
							{uploadButton()}

							{renderList()}
						</div>
					</Responsive>
				)}

				<FullContainer>
					<FlexContainer
						alignItems='center'
						justifyContent='flex-start'
						style={{ width: '100%', padding: '0 1em' }}
					>
						<div>
							{selectedFile.id && (
								<div style={{ display: 'flex' }}>
									<Popup
										content='Expand'
										position='top center'
										trigger={
											<div>
												<IconButton
													name={
														fullscreen ? 'close' : 'expand arrows alternate'
													}
													onClick={() => setFullscreen(!fullscreen)}
													size='large'
													style={{ paddingRight: '1.5em' }}
												/>
											</div>
										}
									/>
									<Popup
										content='Download'
										position='top center'
										trigger={
											<div>
												<IconButton
													name='download'
													onClick={() => downloadFile(selectedFile)}
													size='large'
													style={{ paddingRight: '1.5em' }}
												/>
											</div>
										}
									/>
								</div>
							)}
						</div>
						<div>
							<Popup
								content='File Details'
								trigger={
									<div>
										<SelectableHeader onClick={() => setDetailsOpen(true)}>
											{selectedFile.fileName}
										</SelectableHeader>
									</div>
								}
							/>
						</div>
					</FlexContainer>

					<div style={{ margin: 'auto', width: '100%', padding: '0 1em' }}>
						<Divider />
						<Spinner spinning={fetching}>
							<DocumentView
								documentDriver={documentDriver}
								documentUrl={publicSharedURL}
								expanded={fullscreen}
								file={blobFile}
								filePathUrl={fileURL}
								mimeType={mimeType}
								scale={fileZoom}
								userID={userID}
							/>
						</Spinner>
					</div>
				</FullContainer>
			</FilesContainer>
		</Spinner>
	)
}

export { FileListPreview }
