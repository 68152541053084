import React, { useState } from 'react'
import { Divider, Tab as SemanticTab } from 'semantic-ui-react'
import styled from 'styled-components'

import { Font } from 'styles/font'
import {
  Accordion,
  AccordionTitle,
  AccordionContent,
  MobileHiddenSmall,
  MobileVisibleSmall,
  Icon,
} from '.'
import { ContainerLayout } from './layout'

interface TabPane {
  key?: string
  menuItem: {
    content: string
    icon?: string
    key?: any
  }
  render: () => React.ReactNode
}

const T = styled(SemanticTab)`
  ${Font}

  a {
    background-color: ${(props) => props.theme.BackgroundColour} !important;
    color: ${(props) => props.theme.TextColour} !important;
    border-color: ${(props) => props.theme.TabBorderColour} !important;
  }

  .active.item {
    border-color: ${(props) => props.theme.TabBorderActiveColour} !important;
  }

  .ui.secondary > .active.item {
    background-color: ${(props) =>
      props.highlight && props.theme.TabSecondaryActive} !important;

    :hover {
      background-color: ${(props) =>
        props.highlight && props.theme.TabSecondaryHover} !important;
      cursor: pointer;
    }
  }
`

const AccordianTitleDiv = styled.div`
  padding: 0.5em 0.35em;
`

const Tab = ({ menu, responsive = true, ...props }: ITab) => {
  const [activeIndex, setActiveIndex] = useState(-1)

  const handleClick = (_: any, { index }: AccordianChangeExtra) => {
    const newIndex = activeIndex === index ? -1 : index

    if (newIndex >= 0) {
      props.onTabChange(_, { activeIndex: newIndex })
    }
    setActiveIndex(newIndex)
  }

  if (!responsive) {
    return <T menu={{ ...menu, className: 'wrapped' }} {...props} />
  }

  return (
    <>
      <MobileHiddenSmall>
        <T menu={{ ...menu, className: 'wrapped' }} {...props} />
      </MobileHiddenSmall>

      <MobileVisibleSmall>
        <Accordion fluid>
          {props.panes.map((pane: TabPane, i: number) => {
            if (!pane) {
              return null
            }

            return (
              <AccordianTitleDiv
                key={
                  pane.key || pane.menuItem?.key || pane.menuItem?.content || i
                }
              >
                <AccordionTitle
                  active={activeIndex === i}
                  index={i}
                  onClick={handleClick}
                >
                  <Icon name={pane.menuItem?.icon} />
                  {pane.menuItem?.content}
                  <Icon name="dropdown" />
                </AccordionTitle>

                {activeIndex === i && (
                  <Divider style={{ margin: 0, padding: 0 }} />
                )}

                <AccordionContent active={activeIndex === i}>
                  <ContainerLayout>
                    {activeIndex === i ? pane.render() : null}
                  </ContainerLayout>
                </AccordionContent>

                {activeIndex === i && (
                  <Divider style={{ margin: 0, padding: 0 }} />
                )}
              </AccordianTitleDiv>
            )
          })}
        </Accordion>
      </MobileVisibleSmall>
    </>
  )
}

interface ITab {
  activeIndex?: number
  highlight?: boolean
  menu?: object
  onTabChange?: (_: any, { activeIndex }: TabChangeExtra) => void
  panes: TabPane[] // children?
  responsive?: boolean
  size?: string
}

interface TabChangeExtra {
  activeIndex: number
}

interface AccordianChangeExtra {
  index: number
}

export default Tab
