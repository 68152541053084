import React from 'react'

import { IAppointment } from 'interfaces'
import { Label, Segment, Text } from 'components/ui'
import { AppointmentType } from 'containers/appointments'
import { AppointmentModalClientDetails } from 'containers/calendar/appointment-modal-client-details'
import { AppointmentModalTherapistDetails } from 'containers/calendar/appointment-modal-therapist-details'

import { toCamelCase } from './json-parsing-fix'
import {
  appointmentLocationToText,
  appointmentStatusToText,
} from 'containers/appointments/enums'

interface Props {
  value: string
}

export const AppointmentValue = ({ value }: Props) => {
  const appointment: IAppointment = JSON.parse(value, toCamelCase)

  if (!appointment) {
    return null
  }

  return (
    <>
      <Segment>
        {appointment.appointmentType === AppointmentType.Client && (
          <AppointmentModalClientDetails
            clientID={appointment.clientID}
            eventData={{
              event: appointment,
              clientID: appointment.clientID,
              notes: appointment.notes,
              isDirty: false,
            }}
            isForClient
            locationIndex={appointment.appointmentLocation}
            onChange={() => {}}
            readonly
            setCalendarModalOpen={() => {}}
            setEditEventModalOpen={() => {}}
            setLocationIndex={() => {}}
            setNewClientModalOpen={() => {}}
            validation={null}
          />
        )}

        {appointment.appointmentType === AppointmentType.Therapist && (
          <AppointmentModalTherapistDetails
            eventData={{
              event: appointment,
              clientID: appointment.clientID,
              notes: appointment.notes,
              isDirty: false,
            }}
            readonly
            setCalendarModalOpen={() => {}}
            setEditEventModalOpen={() => {}}
          />
        )}

        <Label>Appointment Status</Label>
        <Text>{appointmentStatusToText(appointment.status)}</Text>

        {/* <Label>Appointment Number</Label>
        <Text>{appointment.appointmentNumber}</Text> */}

        <Label>Appointment Location</Label>
        <Text>
          {appointmentLocationToText(appointment.appointmentLocation)}
        </Text>
      </Segment>
    </>
  )
}
