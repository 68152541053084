import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { H3 } from 'components/ui'

import DatePicker from './date-picker'
import { IEvent } from '../../interfaces'

interface HCProps {
	name: string
	backgroundColour: string
}

const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${(props: HCProps) => props.backgroundColour};

  padding: 1em 0.5em;
  border: solid black;
  border-width: 2px 0px;
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled(H3)`
  padding: 0.25em;
  font-weight: 500;
`

const Column = (props: ColumnProps) => (
	<ColumnContainer>
		{props.rows.map((row) => {
			if (row === 'FORM-DATE') {
				if (!props.date) {
					props.onChange({ target: { name: 'headerDate', value: moment() } })
				}

				return (
					<DatePicker
						key={row.name}
						format={props.dateFormat}
						name='headerDate'
						onChange={props.onChange}
						placeholder='Date'
						value={props.date}
					/>
				)
			}

			return (
				<div key={row}>
					<Row>{row}</Row>
				</div>
			)
		})}
	</ColumnContainer>
)

const FormHeader = (props: FormHeaderProps) => (
	<HeaderContainer backgroundColour={props.backgroundColour} name={props.name}>
		<Column
			date={props.date}
			dateFormat={props.dateFormat}
			onChange={props.onChange}
			rows={props.left}
		/>
		<Column
			date={props.date}
			dateFormat={props.dateFormat}
			onChange={props.onChange}
			rows={props.centre}
		/>
		<Column
			date={props.date}
			dateFormat={props.dateFormat}
			onChange={props.onChange}
			rows={props.right}
		/>
	</HeaderContainer>
)

interface FormHeaderProps {
	backgroundColour: string
	centre: Array<any>
	date: string
	dateFormat: string
	left: Array<any>
	name: string
	onChange: (e: IEvent) => void
	right: Array<any>
	// view: Array<any>
}

FormHeader.defaultProps = {
	backgroundColour: 'grey',
	centre: [],
	dateFormat: 'MMMM D YYYY',
	// date: moment(),
	left: [],
	right: [],
	view: ['none'],
}

interface ColumnProps {
	date: string
	dateFormat: string
	onChange: (e: IEvent) => void
	rows: Array<any>
}

export default FormHeader
