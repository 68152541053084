import React from 'react'

import { Button, Modal, Header, Icon, Text } from 'components/ui'

interface Props {
  content?: string
  message: string
  modalOpen: boolean
  onAccept: Function
  onCancel: Function
}

const DeleteModal = (props: Props) => (
  <Modal open={props.modalOpen} size="small">
    <Header content={props.message} icon="trash alternate outline" />
    {props.content && (
      <Modal.Content>
        <Text>{props.content}</Text>
      </Modal.Content>
    )}
    <Modal.Actions>
      <Button onClick={props.onCancel} secondary>
        No
      </Button>
      <Button negative onClick={props.onAccept}>
        <Icon name="trash alternate" /> Yes
      </Button>
    </Modal.Actions>
  </Modal>
)

DeleteModal.defaultProps = {
  modalOpen: false,
  onCancel: () => {},
}

export default DeleteModal
