import React from 'react'
import styled from 'styled-components'

import { H1, Label, FinePrint } from 'components/ui'
import { Input, Checkbox, TextArea, Radio } from 'components/forms'
import { IEvent } from 'interfaces'

import { DataType, FormItemComponentData } from '../interfaces'
import { MultiTextValues } from './multi-text-values'
import { DropdownDataItem } from './dropdown-data-item'

const ComponentContainer = styled.div`
  margin-bottom: 1em;
`

interface Props {
  onChange: (e: IEvent) => void
  dataItem: FormItemComponentData
  value: any
}

export const DataItem = ({ dataItem, onChange, value }: Props) => {
  const getComponent = (componentType: DataType) => {
    switch (componentType) {
      case DataType.Text:
        return (
          <ComponentContainer>
            <Input
              label={dataItem.label}
              name={dataItem.name}
              onChange={onChange}
              placeholder={dataItem.placeholder || ''}
              size="small"
              validation={{ required: !dataItem.optional }}
              value={value || dataItem.defaultValue || ''}
            />

            <FinePrint style={{ marginTop: '-1em', paddingTop: 0 }}>
              {dataItem.help}
            </FinePrint>
          </ComponentContainer>
        )

      case DataType.TextArea:
        return (
          <ComponentContainer>
            <TextArea
              label={dataItem.label}
              name={dataItem.name}
              onChange={onChange}
              placeholder={dataItem.placeholder || ''}
              size="small"
              validation={{ required: !dataItem.optional }}
              value={value || dataItem.defaultValue || ''}
            />

            <FinePrint style={{ marginTop: '-1em', paddingTop: 0 }}>
              {dataItem.help}
            </FinePrint>
          </ComponentContainer>
        )

      case DataType.Boolean:
        return (
          <ComponentContainer>
            <Label>{dataItem.label}</Label>
            <div style={{ marginTop: '-1em' }}>
              <Checkbox
                defaultChecked={dataItem.defaultValue}
                label=""
                name={dataItem.name}
                onChange={onChange}
                toggle
                validation={{ required: !dataItem.optional }}
                value={value}
              />
            </div>

            <FinePrint style={{ marginTop: '0.5em' }}>
              {dataItem.help}
            </FinePrint>
          </ComponentContainer>
        )

      case DataType.Dropdown:
        return (
          <ComponentContainer>
            <DropdownDataItem
              dataItem={dataItem}
              onChange={onChange}
              options={dataItem.dropdownOptions}
              required={!dataItem.optional}
              value={value || dataItem.defaultValue || ''}
            />
            <FinePrint style={{ marginTop: '0.5em' }}>
              {dataItem.help}
            </FinePrint>
          </ComponentContainer>
        )

      case DataType.MultiText:
        return (
          <ComponentContainer>
            <MultiTextValues
              dataItem={dataItem}
              onChange={onChange}
              required={!dataItem.optional}
              values={value}
            />
            <FinePrint style={{ marginTop: '0.5em' }}>
              {dataItem.help}
            </FinePrint>
          </ComponentContainer>
        )

      case DataType.Radio:
        return (
          <ComponentContainer>
            <Radio
              label={dataItem.label}
              name={dataItem.name}
              onChange={onChange}
              options={dataItem.radioOptions}
              validation={{ required: !dataItem.optional }}
              value={value}
            />
            <FinePrint style={{ marginTop: '0.5em' }}>
              {dataItem.help}
            </FinePrint>
          </ComponentContainer>
        )

      case DataType.Icon:
        return null

      default:
        return <H1>Missing Data Type</H1>
    }
  }
  return <>{getComponent(dataItem.type)}</>
}
