import React from 'react'
import styled from 'styled-components'
import { Font, FontHeader } from 'styles/font'
import { Header as H } from 'semantic-ui-react'
import { Icon, MobileHiddenSmall, MobileVisibleSmall } from '.'
import { MobileBreakPoint } from 'styles'

export const Header = styled(H)`
  &&&&& {
    ${Font};
    background-color: ${(props) => props.theme.BackgroundColour} !important;
  }
`

export const ModalHeader = styled(H)`
  &&&&& {
    ${Font};
    background-color: ${(props) => props.theme.BackgroundColour} !important;

    @media (max-width: ${MobileBreakPoint}) {
      font-size: 1em;
      padding: 0.75em 1em;
    }
  }
`

interface HWIProps {
  name: string
  children: React.ReactNode
}

export const HeaderWithIcon = (props: HWIProps) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
    <Icon name={props.name} size="big" />
    <Header style={{ marginTop: 0 }}>{props.children}</Header>
  </div>
)

export const H1 = styled.h1`
  ${FontHeader};
`

export const H2 = styled.h2`
  ${FontHeader};
`

export const H3 = styled.h3`
  ${FontHeader};
`

export const H4 = styled.h4`
  ${FontHeader};
`

export const H5 = styled.h5`
  ${FontHeader};
`

export const SelectableHeader = styled(Header)`
  &&&&& {
    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.PrimaryColour};
    }
  }
`

export const SelectableH1 = styled(H1)`
  &&&&& {
    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.PrimaryColour};
    }
  }
`

export const SelectableH1Mobile = (props) => {
  return (
    <>
      <MobileHiddenSmall>
        <SelectableH1 {...props} />
      </MobileHiddenSmall>
      <MobileVisibleSmall>
        <SelectableHeader {...props} />
      </MobileVisibleSmall>
    </>
  )
}
