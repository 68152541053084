import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { SList as List, Text, H3, H4 } from 'components/ui'
import { getClientTypeByID } from 'containers/settings'
import { DEFAULT_EVENT_COLOUR } from 'constants/calendar-colours'
import { IState, Treatment } from 'interfaces'
import { Form_T } from 'containers/settings/form-types/new-interfaces'

const ListItem = styled(List.Item)`
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }

  ${(props) =>
    props.selected
      ? `background-color: ${(props: any) => props.theme.ListItemActiveColour}`
      : null};
`

const NotesContainer = styled.div`
  padding-bottom: 1em;
`

const NoPaddingH4 = styled(H4)`
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: 700;
`

class TreatmentItem extends React.Component<ITreatmentItem> {
  render() {
    const { treatment, selectTreatment, selected } = this.props
    const endDate = treatment.end && moment(treatment.end).format('MMM D YYYY')

    return (
      <ListItem
        onClick={() => selectTreatment(treatment.treatmentNumber)}
        selected={selected}
      >
        <List.Content>
          <List.Header>
            <H3>{`Case #${treatment.treatmentNumber}`}</H3>
          </List.Header>

          <List.Description>
            <NoPaddingH4>
              {`Start Date: ${moment(treatment.start).format('MMM D YYYY')}`}
            </NoPaddingH4>

            <NoPaddingH4>
              {treatment.end &&
                endDate !== 'Jan 01 0001' &&
                endDate !== 'Dec 31 0000' &&
                ` End: ${endDate}`}
            </NoPaddingH4>
            <div style={{ display: 'flex' }}>
              <Text>{`Client Type: ${this.props.clientType?.name}`}</Text>{' '}
              <div
                style={{
                  borderRadius: '50%',
                  width: '1em',
                  height: '1em',
                  backgroundColor:
                    this.props.clientType?.appointmentColour ||
                    DEFAULT_EVENT_COLOUR,
                  marginLeft: '1em',
                  marginTop: '0.25em',
                }}
              />
            </div>

            {treatment.treatmentOpenMessage && (
              <NotesContainer>
                <NoPaddingH4>Opening Notes:</NoPaddingH4>
                <Text>{treatment.treatmentOpenMessage}</Text>
              </NotesContainer>
            )}

            {treatment.treatmentCloseMessage && (
              <div>
                <NoPaddingH4>Closing Notes:</NoPaddingH4>
                <Text>{treatment.treatmentCloseMessage}</Text>
              </div>
            )}
          </List.Description>
        </List.Content>
      </ListItem>
    )
  }
}

interface ITreatmentItem {
  clientType?: Form_T
  selectTreatment: Function
  selected?: boolean
  treatment: Treatment
}

const mapStateToProps = (state: IState, props: ITreatmentItem) => ({
  clientType: getClientTypeByID(state, props),
})

export default connect(mapStateToProps)(TreatmentItem)
