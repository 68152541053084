import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useOnclickOutside from 'react-cool-onclickoutside'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import UserCard from 'containers/user/components/user-card'
import { AutoSaveIndicator, SearchBox } from 'components/forms'
import { ClientsListOverlay } from 'components/clients'
import { filterClients, sortClients } from 'lib'
import { getAllClientsList$ } from 'containers/clients'
import { FlexContainerCenter, Icon as I, Text } from 'components/ui'
import { MobileBreakPoint } from 'styles'
import { getClientSortOrder$, getSetting } from 'containers/settings/selectors'
import {
	getClientsForToday,
	getSelectedAppointment$,
} from 'containers/appointments'
import { SHOW_APPOINTMENT_DATE_IN_HEADER, USER_AVATAR } from 'constants/settings'
import { IState, IClient, IEvent } from 'interfaces'

const MAX_ITEMS_TO_SHOW = 5

const Icon = styled(I)`
  font-size: 1.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;

  :hover {
    cursor: pointer;
  }

  background-color: ${(props) => props.theme.BackgroundColour};
`

interface IHeaderText {
	hasAppointment?: boolean
}

const HeaderText = styled(Text)<IHeaderText>`
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin: 0.3em;

  font-weight: 600;
  font-size: 22px;

  :hover {
    cursor: pointer;
  }

  @media (max-width: 1010px) {
    font-size: ${(props) => (props.hasAppointment ? '20px' : '22px')};
    font-weight: 550;
  }

  @media (max-width: 920px) {
    font-size: ${(props) => (props.hasAppointment ? '16px' : '20px')};
    font-weight: 550;
  }

  @media (max-width: 771px) {
    font-size: ${(props) => (props.hasAppointment ? '14px' : '18px')};
    font-weight: 550;
  }

  color: ${(props) => props.theme.TextColour};
`

const ComputerScreenHeader = styled.div`
  display: flex;
  align-self: center;

  @media (max-width: 800px) {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
  }
`

const MobileScreenHeader = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-left: 1em;

  @media (min-width: 800px) {
    visibility: hidden;
    width: 0;
    height: 0;
  }
`

const SmallHeaderText = styled(Text)`
  overflow-x: hidden;
  font-weight: 600;
`

const UserCardRight = styled.div`
  margin-right: 1em;
  margin-left: 1em;

  :hover {
    cursor: pointer;
  }
`

const NavBarMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.BackgroundColour};
  color: ${(props) => props.theme.TextColour};
`

const SearchMenu = styled.div`
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.3em;
  width: 300px;

  @media (max-width: 768px) {
    visibility: hidden;
    width: 0;
  }
`

const MobileMenu = styled.div`
  @media (min-width: ${MobileBreakPoint}) {
    visibility: hidden;
    width: 0;
  }

  background-color: ${(props) => props.theme.BackgroundColour};
`

const ComputerMenu = styled.div`
  @media (max-width: ${MobileBreakPoint}) {
    visibility: hidden;
    width: 0;
  }
`

interface IMobileSearchMenu {
	maxWidth?: boolean
}

const MobileSearchMenu = styled.div<IMobileSearchMenu>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => (props.maxWidth ? 'max-width: 100%' : null)};

  @media (min-width: 768px) {
    visibility: hidden;
    width: 0;
  }
`

const NonMobileUserCard = styled.div<IMobileSearchMenu>`
  ${(props) => (props.maxWidth ? 'width: 100%' : null)};

  @media (max-width: 768px) {
    visibility: hidden;
    width: 0;
  }
`

interface IHeaderBar {
	authenticated: boolean
	collapsed?: boolean
	hideDetailsOnUnverified?: boolean
	onCollapse: Function
	onLogout: any // TODO - Function
	onMobileCollapse: Function
}

const HeaderBar = (props: IHeaderBar) => {
	const ref = useOnclickOutside(() => {
		onSearchShow(false)
		onSearchClear()
	})

	const navigate = useNavigate()

	const text = useSelector((state: IState) => state.navbar.text)
	// const filterPage = useSelector((state: IState) => state.navbar.filterPage)
	const clients: Array<IClient> = useSelector(getAllClientsList$)
	const todaysClients = useSelector(getClientsForToday)
	const clientSortOrder = useSelector(getClientSortOrder$)
	const selectedAppointment = useSelector(getSelectedAppointment$)
	const showAppointmentDateInHeaderSettings = useSelector((state: IState) =>
		getSetting(state, SHOW_APPOINTMENT_DATE_IN_HEADER),
	)
	const user = useSelector((state: IState) => state.user.ctUser)
	const userImageUrl = useSelector((state: IState) => getSetting(state, USER_AVATAR))
	const autoSavingStatus = useSelector(
		(state: IState) => state.forms.autoSavingStatus,
	)

	const [searchTerm, setSearchTerm] = useState('')
	const [showMobileSearch, setShowMobileSearch] = useState(false)
	const [showOverlay, setShowOverlay] = useState(false)
	const [canShowOverlay, setCanShowOverlay] = useState(false)
	// const [page, setPage] = useState(1)

	const onSearchChange = ({ target }: IEvent) => {
		setSearchTerm(target.value)
		setShowOverlay(!!target.value)
	}
	const onSearchClear = () => {
		setSearchTerm('')
		setShowOverlay(false)
	}
	const onSearchShow = (show: boolean) => setShowMobileSearch(show)
	const todaysClientsFiltered = filterClients(searchTerm, todaysClients)

	const clientsFiltered = filterClients(
		searchTerm,
		clients,
		todaysClientsFiltered,
	).sort((a: IClient, b: IClient) => sortClients(a, b, clientSortOrder))

	const Search = (
		<SearchBox
			onBlur={() => setCanShowOverlay(false)}
			onChange={onSearchChange}
			onClear={onSearchClear}
			onFocus={() => setCanShowOverlay(true)}
			value={searchTerm}
		/>
	)

	const appointmentText =
		window.location.href.includes('/notes') &&
		!window.location.href.includes('/notes-settings') &&
		selectedAppointment.id &&
		showAppointmentDateInHeaderSettings === 'yes'
			? `${moment(selectedAppointment.start).format(
				'MMM D YYYY h:mma',
			)} - ${moment(selectedAppointment.end).format('h:mma')}`
			: ''

	return props.authenticated ? (
		<NavBarMenu ref={ref}>
			<FlexContainerCenter>
				{!props.hideDetailsOnUnverified && (
					<ComputerMenu>
						<Icon
							name={props.collapsed ? 'indent' : 'outdent'}
							onClick={props.onCollapse}
							size='large'
						/>
					</ComputerMenu>
				)}

				{!props.hideDetailsOnUnverified && (
					<MobileMenu>
						<Icon name='bars' onClick={props.onMobileCollapse} size='large' />
					</MobileMenu>
				)}
				{!showMobileSearch && (
					<>
						<ComputerScreenHeader>
							<div>
								<HeaderText hasAppointment={!!appointmentText}>
									{text}
								</HeaderText>
							</div>

							<div>
								<HeaderText hasAppointment>{appointmentText}</HeaderText>
							</div>
						</ComputerScreenHeader>

						<MobileScreenHeader>
							{appointmentText ? (
								<>
									<div>
										<SmallHeaderText>{text}</SmallHeaderText>
									</div>
									<div>
										<SmallHeaderText>{appointmentText}</SmallHeaderText>
									</div>
								</>
							) : (
								<HeaderText hasAppointment={false}>{text}</HeaderText>
							)}
						</MobileScreenHeader>
					</>
				)}

				<ClientsListOverlay
					canShowOverlay={canShowOverlay}
					clients={clientsFiltered}
					onClickCallback={() => onSearchClear()}
					onEnter={(selectedClient: IClient) => {
						if (showOverlay && !!selectedClient) {
							navigate(`/client/${selectedClient.id}/notes`)
							onSearchClear()
						}
					}}
					onShowOverlay={() => setShowOverlay(true)}
					pageSize={MAX_ITEMS_TO_SHOW}
					showOverlay={!!searchTerm || showOverlay}
					todaysClients={todaysClientsFiltered}
				/>
			</FlexContainerCenter>

			<FlexContainerCenter>
				<AutoSaveIndicator status={autoSavingStatus} />

				{!props.hideDetailsOnUnverified && <SearchMenu>{Search}</SearchMenu>}

				{!props.hideDetailsOnUnverified && (
					<MobileSearchMenu maxWidth={showMobileSearch}>
						{showMobileSearch && <div>{Search}</div>}
						<Icon
							name={showMobileSearch ? 'close' : 'search'}
							onClick={() => {
								if (showMobileSearch) {
									onSearchClear()
								}

								setShowMobileSearch(!showMobileSearch)
							}}
							size='large'
							style={{ marginRight: '1em' }}
						/>
					</MobileSearchMenu>
				)}

				<NonMobileUserCard>
					<UserCardRight>
						<UserCard
							hideDetailsOnUnverified={props.hideDetailsOnUnverified}
							onLogout={props.onLogout}
							user={user}
							userImageUrl={userImageUrl}
						/>
					</UserCardRight>
				</NonMobileUserCard>
			</FlexContainerCenter>
		</NavBarMenu>
	) : null
}

export default HeaderBar
