import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'

import { authPost } from 'lib/http-request'
import { Forms } from 'constants/endpoints'
import { ErrorState, IForm, IResponse } from 'interfaces'

interface ProgressEvent {
	loaded: number
	total: number
}

export const uploadForm = async (
	file: File,
	accountFormData: IForm,
	onProgress: (progress: number) => void,
	onSuccess: (response: IResponse<any>) => void,
	onFailure: (error: ErrorState) => void,
	onStatus: (uploading: boolean) => void = () => {
	},
): Promise<any> => {
	onStatus && onStatus(true)
	const data = new FormData()
	data.append('file', file, file.name)
	data.append('formJson', JSON.stringify(accountFormData))

	const config = {
		onUploadProgress: (progressEvent: ProgressEvent) => {
			const percentCompleted = Math.round(
				(progressEvent.loaded * 100) / progressEvent.total,
			)

			onProgress(percentCompleted - 1)
		},
		headers: { 'Content-Type': 'multipart/form-data' },
	}

	return authPost(Forms.Upload, data, config)
		.then((response) => {
			onProgress(100)
			onSuccess(response.data)
			onStatus && onStatus(false)
		})
		.catch((error) => {
			onProgress(0)
			onFailure(error)
			onStatus && onStatus(false)
			captureMessage(`Failed to Upload Form ${error}`, Severity.Error)
		})
}
