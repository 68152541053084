import Colours from 'styles/colours'

const colorSchemes = {
  DARK: '(prefers-color-scheme: dark)',
  LIGHT: '(prefers-color-scheme: light)',
}

export const getTheme = (themeSetting: string) => {
  if (themeSetting === 'dark') {
    return darkTheme
  } else if (themeSetting === 'light') {
    return lightTheme
  } else if (themeSetting === 'system') {
    const systemTheme = getSystemTheme()

    if (systemTheme === 'dark') {
      return darkTheme
    } else {
      return lightTheme
    }
  }

  return lightTheme
}

export const getSystemTheme = () => {
  if (!window.matchMedia) {
    return
  }

  const isDark = window.matchMedia(colorSchemes.DARK).matches

  if (isDark) {
    return 'dark'
  } else {
    return 'light'
  }
}

const lightDarkConstants = {
  PrimaryColour: Colours.PrimaryColour,
  PrimaryColourHover: Colours.PrimaryColourHover,
  PrimaryDisabled: Colours.PrimaryDisabled,

  DangerColour: Colours.DangerColour,
  DangerColourHover: Colours.DangerColourHover,

  TextColourLight: Colours.TextColourLight,

  WarningColour: Colours.WarningColour,

  DarkerShadowColour: Colours.DarkerShadowColour,

  LinkColour: Colours.LinkColour,
  LinkColourHighlight: Colours.LinkColourHighlight,
  ErrorTextColour: Colours.ErrorTextColour,

  MutedIconColour: Colours.MutedIconColour,
  SuccessColour: Colours.SuccessColour,
  FailureColour: Colours.FailureColour,

  ProgressIndicatorColour: Colours.ProgressIndicatorColour,
  ProgressIndicatorNearlyFullWarningColour:
    Colours.ProgressIndicatorNearlyFullWarningColour,
  ProgressIndicatorNearlyFullColour: Colours.ProgressIndicatorNearlyFullColour,
}

const lightTheme = {
  ...lightDarkConstants,
  PrimaryColourBorderColour: Colours.PrimaryColourBorderColour,

  NeutralColourOne: Colours.NeutralColourOne,
  NeutralColourOneHover: Colours.NeutralColourOneHover,
  NeutralColourThree: Colours.NeutralColourThree,
  SecondaryButton: Colours.SecondaryButton,
  SecondaryButtonHover: Colours.SecondaryButtonHover,
  NeutralButtonActive: Colours.NeutralButtonActive,

  BackgroundColour: Colours.BackgroundColour,
  BackgroundColourHover: Colours.BackgroundColourHover,
  BackgroundColourOne: Colours.BackgroundColourOne,
  BackgroundSlightOffsetColour: Colours.BackgroundSlightOffsetColour,
  ButtonBackgroundColour: Colours.ButtonBackgroundColour,

  ShadowColour: Colours.ShadowColour,
  MutedBorderColour: Colours.MutedBorderColour,
  ShadowColourSolid: Colours.ShadowColourSolid,

  CardBorderColour: Colours.CardBorderColour,
  CardShadowColour: Colours.CardShadowColour,

  TextColour: Colours.TextColour,
  SecondaryTextColour: Colours.SecondaryTextColour,
  LightTextColour: Colours.LightTextColour,
  DarkIconColour: Colours.DarkIconColour,
  LowContrastTextColour: Colours.LowContrastTextColour,
  TextColourInverse: Colours.TextColourInverse,

  LinkAsTextColour: Colours.LinkAsTextColour,

  InputBorderColour: Colours.InputBorderColour,
  InputBorderColourFocused: Colours.InputBorderColourFocused,

  NeutralButton: Colours.NeutralColourOne,
  NeutralButtonText: Colours.NeutralButtonText,

  ListItemActiveColour: Colours.ListItemActiveColour,
  ListItemHoverColour: Colours.ListItemHoverColour,
  ListItemNewColour: Colours.ListItemNewColour,
  ListItemNewActiveColour: Colours.ListItemNewActiveColour,
  ListItemInvalid: Colours.ListItemInvalid,

  MessageInfoColour: Colours.MessageInfoColour,
  MessageWarningColour: Colours.MessageWarningColour,
  MessageErrorColour: Colours.MessageErrorColour,
  MessageInfoBackgroundColour: Colours.MessageInfoBackgroundColour,
  MessageWarningBackgroundColour: Colours.MessageWarningBackgroundColour,
  MessageErrorBackgroundColour: Colours.MessageErrorBackgroundColour,

  AvatarBackgroundColour: Colours.AvatarBackgroundColour,

  WeekDaySelectedColour: Colours.WeekDaySelectedColour,
  WeekDayWeekendColour: Colours.WeekDayWeekendColour,
  WeekDayDayColour: Colours.WeekDayDayColour,
  WeekDayHoverColour: Colours.WeekDayHoverColour,
  DisabledWeekDayWeekendColour: Colours.DisabledWeekDayWeekendColour,
  DisabledWeekDayColour: Colours.DisabledWeekDayColour,

  SignatureBackground: Colours.SignatureBackground,

  TabBorderColour: Colours.TabBorderColour,
  TabBorderActiveColour: Colours.TabBorderActiveColour,
  TabSecondaryActive: Colours.TabSecondaryActive,
  TabSecondaryHover: Colours.TabSecondaryHover,

  NavActiveColour: Colours.NavActiveColour,

  LineColour: Colours.LineColour,
}

const darkTheme = {
  ...lightDarkConstants,
  PrimaryColourBorderColour: Colours.PrimaryColourBorderColourDark,

  NeutralColourOne: Colours.NeutralColourOneDark,
  NeutralColourOneHover: Colours.NeutralColourOneHoverDark,
  NeutralColourThree: Colours.NeutralColourThreeDark,
  SecondaryButton: Colours.SecondaryButtonDark,
  SecondaryButtonHover: Colours.SecondaryButtonHoverDark,
  NeutralButtonActive: Colours.NeutralButtonActiveDark,

  BackgroundColour: Colours.BackgroundColourDark,
  BackgroundColourHover: Colours.BackgroundColourHoverDark,
  BackgroundColourOne: Colours.BackgroundColourOneDark,
  BackgroundSlightOffsetColour: Colours.BackgroundSlightOffsetColourDark,
  ButtonBackgroundColour: Colours.ButtonBackgroundColourDark,

  ShadowColour: Colours.ShadowColourDark,
  MutedBorderColour: Colours.MutedBorderColourDark,
  ShadowColourSolid: Colours.ShadowColourSolidDark,

  CardBorderColour: Colours.CardBorderColourDark,
  CardShadowColour: Colours.CardShadowColourDark,

  TextColour: Colours.TextColourDark,
  SecondaryTextColour: Colours.SecondaryTextColourDark,
  LightTextColour: Colours.LightTextColour,
  DarkIconColour: Colours.DarkIconColourDark,
  LowContrastTextColour: Colours.LowContrastTextColourDark,
  TextColourInverse: Colours.TextColourInverseDark,

  LinkAsTextColour: Colours.LinkAsTextColourDark,

  InputBorderColour: Colours.InputBorderColourDark,
  InputBorderColourFocused: Colours.InputBorderColourFocusedDark,

  NeutralButton: Colours.NeutralButtonDark,
  NeutralButtonText: Colours.SecondaryTextColourDark,

  ListItemActiveColour: Colours.ListItemActiveColourDark,
  ListItemHoverColour: Colours.ListItemHoverColourDark,
  ListItemNewColour: Colours.ListItemNewColourDark,
  ListItemNewActiveColour: Colours.ListItemNewActiveColourDark,
  ListItemInvalid: Colours.ListItemInvalidDark,

  MessageInfoColour: Colours.MessageInfoColourDark,
  MessageWarningColour: Colours.MessageWarningColourDark,
  MessageErrorColour: Colours.MessageErrorColourDark,
  MessageInfoBackgroundColour: Colours.MessageInfoBackgroundColourDark,
  MessageWarningBackgroundColour: Colours.MessageWarningBackgroundColourDark,
  MessageErrorBackgroundColour: Colours.MessageErrorBackgroundColourDark,

  AvatarBackgroundColour: Colours.AvatarBackgroundColourDark,

  WeekDaySelectedColour: Colours.WeekDaySelectedColourDark,
  WeekDayWeekendColour: Colours.WeekDayWeekendColourDark,
  WeekDayDayColour: Colours.WeekDayDayColourDark,
  WeekDayHoverColour: Colours.WeekDayHoverColourDark,
  DisabledWeekDayWeekendColour: Colours.DisabledWeekDayWeekendColourDark,
  DisabledWeekDayColour: Colours.DisabledWeekDayColourDark,

  SignatureBackground: Colours.SignatureBackgroundDark,

  TabBorderColour: Colours.TabBorderColourDark,
  TabBorderActiveColour: Colours.TabBorderActiveColourDark,
  TabSecondaryActive: Colours.TabSecondaryActiveDark,
  TabSecondaryHover: Colours.TabSecondaryHoverDark,

  NavActiveColour: Colours.NavActiveColourDark,

  LineColour: Colours.LineColourDark,
}
