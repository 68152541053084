import React, { useState } from 'react'
import { List as SemanticList } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { SearchBox } from 'components/forms'
import {
  IconButton as IB,
  FlexContainerCenter,
  Message,
  Header,
  Button,
  Modal,
  H4,
  Pagination,
} from 'components/ui'
import { MobileBreakPointSmall } from 'styles'
import { IPaginationProps, ISearch } from 'interfaces'
import { Spinner } from 'components/loaders'

const MobileListView = styled.div<IListView>`
  @media (min-width: ${MobileBreakPointSmall}) {
    visibility: hidden;
    height: 0;
    max-height: 0;
    padding-bottom: 0;
  }

  padding-bottom: 1em;
`

interface IListView {
  collapseOnMobile: boolean
}

const ListView = styled.div<IListView>`
  @media (max-width: ${MobileBreakPointSmall}) {
    display: ${(props) => props.collapseOnMobile && 'none'};
    visibility: ${(props) => (props.collapseOnMobile ? 'hidden' : 'visible')};
    ${(props) => (props.collapseOnMobile ? 'height: 0' : null)}
    ${(props) => (props.collapseOnMobile ? 'max-height: 0' : null)}
  }
`

const IconButtonLeft = styled(IB)`
  padding-right: 1em;
  padding-left: 0.5em;
`

const IconButtonRight = styled(IB)`
  padding-left: 1em;
  padding-right: 0.5em;
`

const TotalAppointments = styled(H4)`
  margin: 0.5em;
`

const renderer = (item, additionalProps, renderFunction) => {
  if (!item) {
    return
  }

  const renderItem = renderFunction(item)

  if (!renderItem) {
    return
  }

  return React.cloneElement(renderItem, {
    ...additionalProps,
    onClick: (e, t) => {
      additionalProps.onClickOverride && additionalProps.onClickOverride(e, t)
      renderItem.props.onClick(e, t)
    },
  })
}

interface ListProps {
  activeItem: any
  collapseOnMobile: boolean
  emptySelectText: string
  items: Array<any>
  itemType: string
  listHeader?: string
  listHeaderIcon?: string
  loading?: boolean
  noArrows: boolean
  noItemsMessage: string
  onNext?: Function
  pagination: IPaginationProps
  renderer: Function
  scrollable?: {
    scrollable: boolean
    maxHeight: string
  }
  search?: ISearch
  style?: any
}

const List = (props: ListProps) => {
  const [showMobileListOverlay, setMobileListOverlay] = useState(false)

  const activeIndex =
    props.items.findIndex((item) => props.activeItem?.id === item?.id) || 0

  return (
    <>
      <Modal
        onClose={() => setMobileListOverlay(false)}
        open={showMobileListOverlay}
      >
        {props.listHeader && (
          <Header content={props.listHeader} icon={props.listHeaderIcon} />
        )}
        <Modal.Content>
          <ListRender
            {...props}
            loading={props.loading}
            onClickOverride={() => setMobileListOverlay(false)}
          />
          {!props.items.length && (
            <Message content={props.noItemsMessage} info />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setMobileListOverlay(false)}>Close</Button>
        </Modal.Actions>
      </Modal>

      <ListView collapseOnMobile={props.collapseOnMobile}>
        <ListRender {...props} />
        {!props.items.length && <Message content={props.noItemsMessage} info />}
      </ListView>

      {props.collapseOnMobile && (
        <MobileListView collapseOnMobile={props.collapseOnMobile}>
          <FlexContainerCenter>
            {!props.noArrows && (
              <IconButtonLeft
                name="angle left"
                onClick={() => {
                  const nextIndex = activeIndex + 1

                  props.onNext(
                    nextIndex > props.items.length
                      ? props.items.length - 1
                      : nextIndex,
                  )
                }}
                size="big"
              />
            )}

            {props.activeItem && props.activeItem.id ? (
              renderer(
                props.items[activeIndex],
                {
                  active: true,
                  onClickOverride: () => setMobileListOverlay(true),
                  hideIcon: true,
                },
                (renderFunction) => props.renderer(renderFunction, activeIndex),
              )
            ) : (
              <Button
                onClick={() => setMobileListOverlay(true)}
                style={{ marginTop: '0.5em' }}
              >
                {props.emptySelectText}
              </Button>
            )}

            {!props.noArrows && (
              <IconButtonRight
                name="angle right"
                onClick={() => {
                  const nextIndex = activeIndex - 1

                  props.onNext(nextIndex < 0 ? 0 : nextIndex)
                }}
                size="big"
              />
            )}
          </FlexContainerCenter>
        </MobileListView>
      )}
    </>
  )
}

interface ListScrollViewProps {
  scrollable?: boolean
  scrollMaxHeight?: string
}

const ListScrollView = styled.div<ListScrollViewProps>`
  ${({ scrollable, scrollMaxHeight }) =>
    scrollable &&
    css`
      overflow-y: scroll;
      max-height: ${scrollMaxHeight};
    `}
`

const ListRender = (props: IListRenderProps) => {
  const renderItems = props.items.map((item, index) =>
    renderer(
      item,
      {
        active: props.activeItem.id === item?.id,
        onClickOverride: (e, t) =>
          props.onClickOverride && props.onClickOverride(e, t),
        index,
      },
      (renderFunction) => props.renderer(renderFunction, index),
    ),
  )

  // TODO - Handle search term in state here, only call onSearch with term
  //      - remove props: onChange, onClear, value
  return (
    <div>
      {props.search.useSearch && (
        <SearchBox
          autoFocus={false}
          loading={props.search.searchLoading}
          onBlur={props.search.onBlur}
          onChange={props.search.onSearchFilter}
          onClear={props.search.onSearchClear}
          onSearch={props.search.onSearch}
          style={{ margin: '0.5em 0 0.5em 0' }}
          value={props.search.searchTerm}
        />
      )}

      <Spinner spinning={props.loading}>
        <ListScrollView
          scrollable={props.scrollable?.scrollable}
          scrollMaxHeight={props.scrollable?.maxHeight}
        >
          <SemanticList relaxed="very">{renderItems}</SemanticList>
        </ListScrollView>
      </Spinner>

      {props.pagination.usePagination &&
        props.pagination.totalItems > props.pagination.pageSize && (
          <Pagination
            activePage={props.pagination.activePage}
            className="stackable"
            firstItem={null}
            lastItem={null}
            onPageChange={props.pagination.onPageChange}
            pointing
            secondary
            siblingRange={0}
            size="tiny"
            stackable
            style={{ 'margin-top': '0.5em' }}
            totalPages={Math.ceil(
              props.pagination.totalItems / props.pagination.pageSize,
            )}
          />
        )}
      {props.pagination.totalItems > props.pagination.pageSize && (
        <TotalAppointments>{`${props.pagination.totalItems} ${props.itemType}`}</TotalAppointments>
      )}
    </div>
  )
}

List.defaultProps = {
  activeItem: {},
  collapseOnMobile: true,
  emptySelectText: 'Select',
  noArrows: false,
  pagination: { usePagination: false },
  search: { useSearch: false },
}

interface IListRenderProps {
  activeItem: any
  items: Array<any>
  itemType: string
  loading?: boolean
  onClickOverride?: Function
  pagination: IPaginationProps
  renderer: Function
  search?: ISearch
  scrollable?: {
    scrollable: boolean
    maxHeight: string
  }
  searchTerm?: string
}

const ListItem = SemanticList.Item
const ListIcon = SemanticList.Icon

const ListContent = styled(SemanticList.Content)`
  color: ${(props) => props.theme.TextColour} !important;
`
const ListHeader = styled(SemanticList.Header)`
  color: ${(props) => props.theme.TextColour} !important;
`
const ListDescription = styled(SemanticList.Description)`
  color: ${(props) => props.theme.TextColour} !important;
`

interface IActivableListItem {
  active?: boolean
  new?: boolean
}
const ActivableListItem = styled(ListItem)<IActivableListItem>`
  &&&&& {
    padding: 1em;
    border-radius: 7px;
    width: 100%;

    :hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.ListItemHoverColour};
    }

    background-color: ${(props) =>
      props.new && props.active
        ? (props) => props.theme.ListItemNewActiveColour
        : props.new
        ? (props) => props.theme.ListItemNewColour
        : props.active
        ? (props) => props.theme.ListItemActiveColour
        : null};
  }
`

const DataItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }
`

const ListText = styled.div`
  display: flex;
  text-transform: capitalize;
`

export default List
export {
  ListItem,
  ListIcon,
  ListContent,
  ListHeader,
  ListDescription,
  ActivableListItem,
  ListText,
  DataItem,
  SemanticList as SList, // TEMP
}
