import { useRef, useEffect } from 'react'

function useEventListener(
  // eslint-disable-next-line no-undef
  eventName: WindowEventMap | any,
  handler: Function,
  element = window,
) {
  const savedHandler: any = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const isSupported = element && element.addEventListener

      if (!isSupported) {
        return
      }

      const eventListener = (event: Function) => savedHandler.current(event)
      element.addEventListener(eventName, eventListener)

      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },

    [eventName, element], // Re-run if eventName or element changes
  )
}

export { useEventListener }
