import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getSetting } from 'containers/settings/selectors'
import { THEME_SETTING } from 'constants/settings'
import { IState } from 'interfaces'

// Define available themes
export const colorSchemes = {
  DARK: '(prefers-color-scheme: dark)',
  LIGHT: '(prefers-color-scheme: light)',
}

export function useGetColourTheme(defaultScheme = 'light') {
  const [scheme, setScheme] = useState(defaultScheme)
  const settingsTheme = useSelector((state: IState) =>
    getSetting(state, THEME_SETTING),
  )

  useEffect(() => {
    // No support for detection
    if (!window.matchMedia) {
      return
    }

    const listener = (e: any) => {
      // No match = not interesting
      if (!e || !e.matches) {
        return
      }

      // Look for the matching color scheme
      // and update the hook state
      const schemeNames = Object.keys(colorSchemes)
      for (let i = 0; i < schemeNames.length; i++) {
        const schemeName = schemeNames[i]
        if (e.media === colorSchemes[schemeName]) {
          setScheme(schemeName.toLowerCase())
          break
        }
      }
    }

    // Loop through and setup listeners for the
    // media queries we want to monitor
    let activeMatches = []
    Object.keys(colorSchemes).forEach((schemeName) => {
      const mq = window.matchMedia(colorSchemes[schemeName])
      mq.addListener(listener)
      activeMatches.push(mq)
      listener(mq)
    })

    // Remove listeners, no memory leaks
    return () => {
      activeMatches.forEach((mq) => mq.removeListener(listener))
      activeMatches = []
    }
  }, [])

  if (settingsTheme !== 'system') {
    return settingsTheme
  }

  return scheme
}
