import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Label,
  Tab,
  SmallAddress,
  Form,
  LinkText,
  SegmentOnReadOnly,
} from 'components/ui'
import { Dropdown, Input, PhoneNumber } from 'components/forms'
import { getOfficesList$ } from 'containers/account/office'
import {
  Office,
  IAppointment,
  IState,
  IClient,
  PhoneNumber as IPhoneNumber,
  IEvent,
} from 'interfaces'
import { getClient } from 'containers/clients'

interface Props {
  appointment: IAppointment
  clientID: string
  locationIndex: number
  onChange?: (e: IEvent) => void
  readonly?: boolean
  setLocationIndex?: Function
}

export const LOCATION_NONE = 4

export const AppointmentLocation = (props: Props) => {
  const offices = useSelector(getOfficesList$)
  const client: IClient = useSelector((state: IState) =>
    getClient(state, props.clientID),
  )

  const [locationEffectSet, setLocationEffectSet] = useState(false)
  const officeOptions = offices.map((office: Office) => ({
    id: office.id,
    key: office.id,
    value: office.id,
    text: office.name,
  }))
  const { appointment, onChange } = props

  useEffect(() => {
    if (!appointment.clinicID && officeOptions?.[0]?.id) {
      onChange?.({
        target: { name: 'clinicID', value: officeOptions?.[0]?.id },
      })
      setLocationEffectSet(true)
    } else if (
      !appointment.clinicID &&
      !officeOptions?.[0]?.id &&
      props.locationIndex !== LOCATION_NONE &&
      !locationEffectSet
    ) {
      props.setLocationIndex(LOCATION_NONE)
      setLocationEffectSet(true)
    }
  })

  const panes = [
    {
      menuItem: { content: 'Clinic' },
      render: () => {
        const selectedOffice =
          appointment.clinicID &&
          offices.find((o: Office) => o.id === appointment.clinicID)

        return (
          <SegmentOnReadOnly readonly={!props.readonly}>
            {!props.readonly && (
              <Dropdown
                disabled={offices.length <= 0}
                label="Clinic"
                name="clinicID"
                onChange={onChange}
                options={officeOptions}
                placeholder={
                  offices.length === 0 ? 'No clinics' : 'Select a clinic'
                }
                readonly={props.readonly}
                validationRules={{ required: offices.length > 1 }}
                value={appointment.clinicID}
              />
            )}

            {selectedOffice && (
              <SmallAddress
                addressOne={selectedOffice['office-address']}
                addressTwo={selectedOffice['office-addressTwo']}
                city={selectedOffice['office-city']}
                postalCode={selectedOffice['office-postalcode']}
                province={selectedOffice['office-province']}
              />
            )}
          </SegmentOnReadOnly>
        )
      },
    },
    {
      menuItem: { content: 'Online Session' },
      render: () => (
        <SegmentOnReadOnly readonly={!props.readonly}>
          <Form>
            {props.readonly ? (
              <a
                href={
                  appointment?.meetingLink?.includes('http')
                    ? appointment.meetingLink
                    : `https://${appointment.meetingLink}`
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkText>
                  {appointment?.meetingLink?.includes('http')
                    ? appointment.meetingLink
                    : `https://${appointment.meetingLink}`}
                </LinkText>
              </a>
            ) : (
              <Input
                inputLabel={
                  appointment?.meetingLink?.includes('http') ? null : 'https://'
                }
                label="Meeting Web Link"
                name="meetingLink"
                onChange={onChange}
                placeholder="Meeting Web Link - Zoom, Teams, etc. Invite Link"
                readonly={props.readonly}
                validationRules={{ required: true }}
                value={appointment.meetingLink}
              />
            )}
            <Input
              label="Meeting Password"
              name="meetingPassword"
              onChange={onChange}
              placeholder="Password to join the meeting"
              readonly={props.readonly}
              value={appointment.meetingPassword}
            />
          </Form>
        </SegmentOnReadOnly>
      ),
    },
    {
      menuItem: { content: 'Phone Session' },
      render: () => (
        <SegmentOnReadOnly readonly={!props.readonly}>
          {!props.readonly && (
            <PhoneNumber
              label="Client's Phone Numbers"
              name="phoneNumbers"
              onChange={() => {}}
              readonly
              value={client?.phoneNumbers}
            />
          )}
          {client?.phoneNumbers?.length ? (
            <Dropdown
              label="Will Call Client At"
              name="locationNotes"
              onChange={onChange}
              options={client?.phoneNumbers.map((option: IPhoneNumber) => ({
                id: option.number,
                key: option.number,
                value: option.number,
                text: `${option.type} - ${option.number}`,
              }))}
              readonly={props.readonly}
              value={appointment.locationNotes}
            />
          ) : (
            <Input
              label="Will Call Client At"
              name="locationNotes"
              onChange={onChange}
              readonly={props.readonly}
              value={appointment.locationNotes}
            />
          )}
        </SegmentOnReadOnly>
      ),
    },
    {
      menuItem: { content: 'None' },
      render: () => <div />,
    },
  ]

  if (props.readonly) {
    let locationIndex = props.locationIndex - 1

    if (locationIndex < 0) {
      locationIndex = LOCATION_NONE - 1
    }
    return panes[locationIndex].render()
  }

  return (
    <div style={{ paddingBottom: '1em' }}>
      <Label>Location</Label>

      <Tab
        activeIndex={props.locationIndex - 1}
        highlight
        // - 1 and + 1 as 0 is unknown on API
        menu={{ secondary: true }}
        onTabChange={(_: any, { activeIndex }) =>
          !props.readonly && props.setLocationIndex(activeIndex + 1)
        }
        panes={panes}
        responsive={false}
      />
    </div>
  )
}
