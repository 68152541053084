import React from 'react'
import styled from 'styled-components'

import { Button, Icon, Divider, SList as List, H3, Text } from 'components/ui'

import TreatmentItem from './treatment-item'
import EmptyCurrentTreatment from './empty-current-treatment'

const ScrollContainer = styled.div`
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
  }
`

const TreatmentList = (props: TreatmentListProps) => (
  <>
    {props.currentTreatment && !props.currentTreatment.closed ? (
      <div>
        <H3>Current Case</H3>
        <hr />
        <List relaxed size="large">
          <TreatmentItem
            selectTreatment={props.selectTreatment}
            treatment={props.currentTreatment}
          />
        </List>
        <div>
          <Button
            loading={props.closingTreatment}
            negative
            onClick={props.onCloseTreatment}
            size="small"
            style={{ margin: 'auto' }}
          >
            <Icon name="close" /> Close Case
          </Button>
        </div>
      </div>
    ) : (
      <EmptyCurrentTreatment
        saveTreatment={props.saveTreatment}
        savingTreatment={props.savingTreatment}
      />
    )}

    <>
      <H3 style={{ paddingTop: '1em' }}>Previous Cases</H3>
      <hr />
      <ScrollContainer>
        <List loading={props.fetchingPrevious} relaxed size="large">
          {!props.previousTreatments.length && <Text>No Previous Cases</Text>}
          {props.previousTreatments.map((previousTreatments) => (
            <div key={previousTreatments.treatmentNumber}>
              <TreatmentItem
                selected={
                  previousTreatments.treatmentNumber ===
                  props.selectedTreatmentNumber
                }
                selectTreatment={props.selectTreatment}
                treatment={previousTreatments}
              />
              <Divider hidden />
            </div>
          ))}
        </List>
      </ScrollContainer>
    </>
  </>
)

interface TreatmentListProps {
  closingTreatment: boolean
  currentTreatment: any
  fetchingPrevious: boolean
  onCloseTreatment: Function
  previousTreatments: Array<any>
  saveTreatment: Function
  savingTreatment: boolean
  selectTreatment: Function
  selectedTreatmentNumber: number
}

TreatmentList.defaultProps = {
  closingTreatment: false,
  previousTreatments: [],
}

export default TreatmentList
