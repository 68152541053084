import { InvoiceReportItem } from 'interfaces'
import { compare } from 'containers/reports/invoices/invoiceCompare'
import { postTaxHeaders, preTaxHeaders } from 'containers/taxes'

/**
 * Takes in a invoiceReportItem array and returns a csv string
 * @param items
 */
export const exportTaxesToCsv = (items: InvoiceReportItem[]): string => {
  const taxHeaders = items[0].taxes.sort(compare).map((tax) => tax.name)
  const headers = [...preTaxHeaders, ...taxHeaders, ...postTaxHeaders]
  headers[0] = 'Invoice Number'

  let csvStr = headers.join(',') + '\r\n'

  for (const item of items) {
    const taxes = item.taxes.map((tax) => tax.taxAmount)

    const rowArray = [item.receiptNumber, item.date, item.client, item.service, item.subTotal, ...taxes, item.total, item.paid ? 'Yes' : 'No', item.datePaid, item.paymentType]

    const row = rowArray.join(',') + '\r\n'
    csvStr += row
  }

  return csvStr
}

// export const exportToCsv = (items: object[]): string => {
//   let csvStr = ''
//
//   for (let i = 0; i < items.length; i++) {
//     if (i === 0) {
//       // TODO - handle taxes headers
//       const keys = Object.keys(items[0]).join(',') + '\r\n'
//       csvStr += keys
//     }
//   }
//
//   return ''
// }