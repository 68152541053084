import React from 'react'
import styled from 'styled-components'
import { Divider, Segment as SemanticSegment, Grid } from 'semantic-ui-react'

import { MobileBreakPoint } from 'styles'

// TODO - clean up this monster
interface FlexContainerProps {
	justifyContent?: string
	alignItems?: string
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  justify-content: ${(props) =>
          props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : null)};
`

export const FlexContainerCenter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Container = styled.div<ContainerProps>`
  padding: ${(props) => (props.padded ? '1em' : '0.5em')};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  padding-bottom: ${(props) => props.paddingBottom || '0'};
${
        '' /* width: 100%;

  @media (max-width: 2048px) {
    width: 75%;
  }

  @media (max-width: 1299px) {
    width: 85%;
  } */
}
`

export const FormColumnArea = styled.div`
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media print {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
  }
`

export const ComfyContainer = styled.div<ContainerProps>`
  padding-top: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: ${(props) => props.marginBottom || '0'};
  padding-bottom: ${(props) => props.paddingBottom || '0'};

  width: 65%;
  max-width: 65%;

  @media (max-width: 2044px) {
    width: 75%;
  }

  @media (max-width: 1644px) {
    width: 85%;
  }

  @media (max-width: 1244px) {
    width: 90%;
  }

  @media (max-width: 1024px) {
    width: 95%;
  }

  @media (max-width: ${MobileBreakPoint}) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

interface ContainerProps {
	padded?: boolean
	marginBottom?: string
	paddingBottom?: string
}

export const UnComfyContainer = styled.div<ContainerProps>`
  margin-bottom: ${(props) => props.marginBottom || '0'};
  padding-bottom: ${(props) => props.paddingBottom || '0'};

  width: 65%;

  @media (max-width: 2044px) {
    width: 75%;
  }

  @media (max-width: 1644px) {
    width: 85%;
  }

  @media (max-width: 1244px) {
    width: 90%;
  }

  @media (max-width: 1024px) {
    width: 95%;
  }

  @media (max-width: ${MobileBreakPoint}) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

interface CLProps {
	more?: boolean
}

// New shiney layout
export const ContainerLayout = styled.div<CLProps>`
  /* max-width: 50%; */
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  max-width: ${(props) => (props.more ? '125em' : '90em')};

  @media (max-width: 1920px) {
    max-width: ${(props) => (props.more ? '110em' : '80em')};
  }

  @media (max-width: 512px) {
    width: 98%;
  }

  background-color: ${(props) => props.theme.BackgroundColour} !important;
  color: ${(props) => props.theme.TextColour} !important;

  /* @media (max-width: 2100px) {
    max-width: 60%;
  }

  @media (max-width: 1920px) {
    max-width: 65%;
  }

  @media (max-width: 1500px) {
    max-width: 75%;
  }

  @media (max-width: 1200px) {
    max-width: 80%;
  }

  @media (max-width: 1050px) {
    max-width: 90%;
  }

  @media (max-width: 900px) {
    max-width: 95%;
  }

  @media (max-width: 768px) {
    max-width: 98%;
  }

  @media (max-width: 512px) {
    max-width: 98%;
  } */
`

const Segment = styled(SemanticSegment)`
  border-color: ${(props) => props.theme.InputBorderColour} !important;
  background-color: ${(props) => props.theme.BackgroundColour} !important;
  color: ${(props) => props.theme.TextColour} !important;

  @media print {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
`

interface ISegmentOnReadOnly {
	readonly?: boolean
	children: any
}

const SegmentOnReadOnly = (props: ISegmentOnReadOnly) => {
	if (props.readonly) {
		return <Segment>{props.children}</Segment>
	}

	return props.children
}

interface IOptionalSegmentProps {
	children: any
	hideSegment?: boolean
}

const OptionalSegment = ({ hideSegment, children }: IOptionalSegmentProps) => {
	if (hideSegment) {
		return children
	}

	return <Segment>{children}</Segment>
}

interface ResProps {
	maxWidth?: number
	minWidth?: number
	style?: any
}

const MinMaxWidthResponsive = styled.div<ResProps>`
  @media (min-width: ${(props) => `${props.maxWidth}px`}) {
    ${(props) => (props.maxWidth ? 'display: none; visibility: none;' : null)};
  }

  @media (max-width: ${(props) => `${props.minWidth}px`}) {
    ${(props) => (props.minWidth ? 'display: none; visibility: none;' : null)};
  }
`

interface ResponsiveProps {
	minWidth?: number
	maxWidth?: number
	children: React.ReactNode
	style?: any
}

const Responsive = ({ maxWidth, minWidth, children, style }: ResponsiveProps) => (
	<MinMaxWidthResponsive maxWidth={maxWidth} minWidth={minWidth} style={style}>
		{children}
	</MinMaxWidthResponsive>
)

export {
	Divider,
	Segment,
	Grid,
	Responsive,
	SegmentOnReadOnly,
	OptionalSegment,
}
