import React from 'react'

import { Label, Print } from 'components/ui'
import { ErrorBoundary } from 'components/error'

import DrawCanvas from './draw-canvas'
import Tools from './tools'
import DrawNotes from './draw-notes'
import { IState, Shape } from 'interfaces'
import { useSelector } from 'react-redux'

interface Props {
  id?: any
  onChangeCallback: Function
  printParams?: Print
  readonly: boolean
  shapesSummaryOverride: Array<Shape>
}

const Draw = ({
  id,
  onChangeCallback,
  printParams,
  readonly,
  shapesSummaryOverride,
}: Props) => {
  const canvasDataURL = useSelector(
    (state: IState) => state.draw.currentCanvasAsDataURL,
  )

  return (
    <ErrorBoundary>
      <div>
        <Label>Body Diagram</Label>
        {!readonly ? <Tools /> : null}
        {printParams?.printingView ? (
          <img src={canvasDataURL} />
        ) : (
          <DrawCanvas
            id={id}
            onChangeCallback={onChangeCallback}
            printParams={printParams}
            readonly={readonly}
            shapesSummaryOverride={shapesSummaryOverride}
          />
        )}
        <DrawNotes
          onChangeCallback={onChangeCallback}
          printParams={printParams}
          readonly={readonly}
          shapesSummaryOverride={shapesSummaryOverride}
        />
      </div>
    </ErrorBoundary>
  )
}

export default Draw
