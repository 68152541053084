import React from 'react'
import { Rect } from 'react-konva'

import { authGetImage } from 'lib/http-request'
import { Storage } from 'constants/endpoints'

interface Props {
  background: string
  height: number
  onImageLoad: (image: HTMLImageElement) => void
  width: number
}

export default class BackgroundLayer extends React.Component<Props> {
  state = {
    fillPatternImage: null,
    background: '',
  }

  componentDidMount() {
    this.drawImage()
  }

  drawImage = () => {
    if (this.props.background === this.state.background) {
      return
    }

    authGetImage(
      Storage.GetFile(this.props.background),
      this.props.background,
    ).then((response) => {
      var uri = URL.createObjectURL(response.data)

      const image = new window.Image()
      image.src = uri

      image.onload = () => {
        this.setState(() => ({
          fillPatternImage: image,
          background: this.props.background,
        }))
        this.props.onImageLoad(image)
      }
    })
  }

  render() {
    return (
      <Rect
        fillPatternImage={this.state.fillPatternImage}
        height={this.props.height}
        shadowBlur={10}
        width={this.props.width}
        x={0}
        y={0}
      />
    )
  }
}
