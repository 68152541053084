import React from 'react'
import styled from 'styled-components'

import { Grid, H4 } from 'components/ui'
import { DAYS } from 'constants/days'

const WeekDayList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.5em 0;
`

interface IWeek {
  disabled?: boolean
  selected?: boolean
  showWeekendColours?: boolean
}

const WeekDayButton = styled.div<IWeek>`
  border-radius: 5px;
  background-color: ${(props) => props.theme.WeekDayDayColour};

  background-color: ${(props) =>
    props.selected
      ? props.theme.WeekDaySelectedColour
      : props.disabled
      ? props.showWeekendColours
        ? props.theme.DisabledWeekDayWeekendColour
        : props.theme.DisabledWeekDayColour
      : props.showWeekendColours
      ? props.theme.WeekDayWeekendColour
      : props.theme.WeekDayDayColour};

  :hover {
    background-color: ${(props) =>
      !props.disabled && props.theme.WeekDayHoverColour};
    ${(props) => !props.disabled && 'cursor: pointer'};
  }
`

const WeekDay = styled(H4)<IWeek>`
  margin: auto;
  padding: 0.5em 1em;

  ${(props) =>
    props.disabled && !props.selected && 'color: rgba(0, 0, 0, 0.25)'};
  color: ${(props) => props.theme.TextColour};
`

const WeekDayPicker = ({
  pickedDays,
  showWeekendColours,
  onClick,
  disabled,
}: IWeekDayPicker) => (
  <WeekDayList>
    {DAYS.map((day) => (
      <WeekDayButton
        key={day.id}
        disabled={disabled}
        onClick={() => !disabled && onClick(day.id)}
        selected={pickedDays.some((d) => d.dayID === day.id)}
        showWeekendColours={
          showWeekendColours && (day.id === 0 || day.id === 6)
        }
      >
        <Grid>
          <Grid.Row>
            <Grid.Column only="mobile tablet">
              <WeekDay>{day.letter}</WeekDay>
            </Grid.Column>
            <Grid.Column only="computer">
              <WeekDay
                disabled={disabled}
                selected={pickedDays.some((d) => d.dayID === day.id)}
              >
                {day.short}
              </WeekDay>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WeekDayButton>
    ))}
  </WeekDayList>
)

interface IWeekDayPicker {
  disabled?: boolean
  onClick: Function
  pickedDays: Array<any>
  showWeekendColours?: boolean
}

WeekDayPicker.defaultProps = {
  disabled: false,
  pickedDays: [],
  showWeekendColours: true,
}

export default WeekDayPicker
