import { Shape } from 'interfaces'
import colours from 'styles/colours'
import { AuditType, AuditReason, TableName } from './interfaces'

export const getAuditListIcon = (auditType: AuditType) => {
  switch (auditType) {
    case AuditType.Add:
      return 'plus square outline'
    case AuditType.Update:
      return 'edit outline'
    case AuditType.Delete:
      return 'trash alternate outline'
    case AuditType.Accessed:
      return 'eye'
    case AuditType.Completed:
      return 'check circle outline'
    case AuditType.Fetched:
      return 'download'
    case AuditType.Archived:
      return 'archive'
    case AuditType.Sent:
    case AuditType.ReminderSent:
      return 'send'
    case AuditType.Unknown:
    default:
      return 'question circle outline'
  }
}

export const getAuditListIconColour = (auditType: AuditType) => {
  switch (auditType) {
    case AuditType.Add:
    case AuditType.Sent:
    case AuditType.ReminderSent:
      return colours.PrimaryColour
    case AuditType.Update:
      return colours.AppointmentCompletedColour
    case AuditType.Delete:
      return colours.DangerColour
    case AuditType.Accessed:
      return colours.WarningColour
    case AuditType.Completed:
      return colours.PrimaryColour
    case AuditType.Fetched:
      return colours.SecondaryColour
    case AuditType.Archived:
      return colours.SecondaryColour
    case AuditType.Unknown:
    default:
      return 'question circle outline'
  }
}

export const getAuditListName = (auditType: AuditType) => {
  switch (auditType) {
    case AuditType.Add:
      return 'Add or Create'
    case AuditType.Update:
      return 'Update'
    case AuditType.Delete:
      return 'Delete'
    case AuditType.Accessed:
      return 'Accessed'
    case AuditType.Completed:
      return 'Completed'
    case AuditType.Fetched:
      return 'Fetched or Downloaded'
    case AuditType.Archived:
      return 'Archived'
    case AuditType.Sent:
      return 'Sent'
    case AuditType.ReminderSent:
      return 'Reminder Sent'
    case AuditType.Unknown:
    default:
      return 'Unknown'
  }
}

export const getAuditReason = (auditReason: AuditReason) => {
  switch (auditReason) {
    case AuditReason.Email:
      return 'ClientTrackyr accessed to get basic client info for sending an email'
    case AuditReason.PublicIntake:
      return 'ClientTrackyr accessed to get basic client info for sending intake form to client'
    case AuditReason.Appointment:
      return 'ClientTrackyr accessed to create appointment for client'
    case AuditReason.AppointmentEmail:
      return 'ClientTrackyr accessed to send appointment details to client'
    case AuditReason.AccessNotes:
      return 'ClientTrackyr accessed client to get notes for client'
    // case AuditReason.UserAccessed:
    //   return 'User {0} accessed this client directly'
    case AuditReason.None:
      return ''
    default:
      return 'Unknown'
  }
}

export const getAuditTableName = (auditTable: TableName) => {
  switch (auditTable) {
    case TableName.All:
      return 'All'
    case TableName.Clients:
      return 'Client'
    case TableName.Notes:
      return 'Notes'
    case TableName.Appointments:
      return 'Appointments'
    case TableName.Treatments:
      return 'Treatments'
    case TableName.Files:
      return 'Files'
    case TableName.PublicIntake:
      return 'PublicIntake'
    case TableName.Email:
      return 'Email'
    case TableName.SMS:
      return 'SMS'
    default:
      return 'Unknown'
  }
}

export const getAuditTableIcon = (auditTable: TableName) => {
  switch (auditTable) {
    case TableName.Clients:
      return 'users'
    case TableName.Notes:
      return 'edit outline'
    case TableName.Appointments:
      return 'calendar outline'
    case TableName.Treatments:
      return 'clipboard outline'
    case TableName.Files:
      return 'file outline'
    case TableName.PublicIntake:
      return 'file alternate outline'
    case TableName.Email:
      return 'mail'
    case TableName.SMS:
      return 'phone'
    case TableName.All:
    default:
      return ''
  }
}

interface SpecialCaseRet {
  key: string
  label: string
  value: string
}

export const getFormLabelFromID = (
  objProps: { [key: string]: any },
  formTemplateData: any,
  specialCaseFcn?: (
    key: string,
    label: string,
    value: any,
  ) => SpecialCaseRet | false,
  ignoreList?: string[],
) => {
  const keys = Object.keys(objProps)
    .map((c) => {
      if (ignoreList.includes(c)) {
        return null
      }

      return c === 'value'
        ? null
        : c === 'key'
        ? objProps[c].replace('Notes', '').replace('Check', '')
        : c.replace('Notes', '').replace('Check', '')
    })
    .filter((c) => c)

  const templates = JSON.stringify(formTemplateData)

  return keys
    .map((key) => {
      if (ignoreList.includes(key)) {
        return null
      }

      let split = templates.split('{').find((f) => f.includes(key))

      if (!split) {
        const tempkey = key.split(':')
        if (tempkey.length === 2) {
          split = templates.split('{').find((f) => f.includes(tempkey[1]))
        } else {
          split = null
        }
      }

      const labelText =
        split?.substr(split?.indexOf('label":"'), split?.length - 1) || ''
      const label = labelText
        .substring(labelText.indexOf(':') + 2, labelText.indexOf(',') - 1)
        .replace('"', '')
        .replace('}', '')
        .replace('{', '')

      const value = objProps[key] || objProps['value']
      const specialCase = getFormLabelFromIDHandleSpecialCase(
        key,
        label,
        value,
        specialCaseFcn,
      )

      if (specialCase) {
        return specialCase
      }

      return {
        key,
        label,
        value,
      }
    })
    .filter((c) => c)
}

const getFormLabelFromIDHandleSpecialCase = (
  key: string,
  label: string,
  value: any,
  specialCaseFcn?: (
    key: string,
    label: string,
    value: any,
  ) => SpecialCaseRet | false,
) => {
  if (specialCaseFcn != null) {
    const specialCaseFcnResult = specialCaseFcn(key, label, value)
    if (specialCaseFcnResult) {
      return specialCaseFcnResult
    }
  }

  if (key === 'id') {
    return {
      key,
      label: 'ID',
      value,
    }
  } else if (key === 'phoneNumbers') {
    return {
      key,
      label,
      value: value.map((p) => `${p.type}: ${p.number}`),
    }
  } else if (key === 'fields') {
    // TODO - TEMP
    return {
      key,
      label,
      value: null, //JSON.stringify(value),
    }
  } else if (key === 'shapes') {
    const shapes = typeof value === 'string' ? JSON.parse(value) : value
    // TODO - TEMP
    return {
      key,
      label: 'Shapes',
      value: [
        ...shapes.map(
          (shape: Shape) =>
            `${shape.shapeType}, Colour: ${shape.colour}. ${
              shape.notes ? 'Notes: ' + shape.notes : ''
            }`,
        ),
      ].join('. '),
    }
  }

  return false
}
