import { authGet } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import localforage from 'localforage'

import {
  defaultTabs,
  TAB_TYPES,
  parseFromAPI,
  getDefaultSettings,
} from 'constants/settings'
import { CLIENT_TRACKYR_STORE, REQUEST_CACHE } from 'constants/storage'
import { IAction, Setting, Dispatch, SettingsState } from 'interfaces'
import { IResponse } from 'interfaces/fetch'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`
const FETCH_SETTINGS = settingsAction('FETCH_SETTINGS')
const FETCH_SETTINGS_SUCCESS = settingsAction('FETCH_SETTINGS_SUCCESS')
const FETCH_SETTINGS_CACHE_SUCCESS = settingsAction(
  'FETCH_SETTINGS_CACHE_SUCCESS',
)
const FETCH_SETTINGS_FAILURE = settingsAction('FETCH_SETTINGS_FAILURE')

const cache = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: REQUEST_CACHE,
})

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_SETTINGS:
      return {
        ...state,
        fetchingSettings: true,
      }

    case FETCH_SETTINGS_SUCCESS:
    case FETCH_SETTINGS_CACHE_SUCCESS: {
      const settings = action.response
      const newState = {} as SettingsState

      if (settings && settings.length) {
        settings.forEach(
          (setting: Setting) =>
            (newState[setting.setting] = parseFromAPI(setting)),
        )
      }

      return {
        ...state,
        ...newState,
        [TAB_TYPES]: populateTabDefaultsIfMissing(newState),
        fetchingSettings: false,
      }
    }

    case FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        fetchingSettings: false,
      }

    default:
      return state
  }
}

export const fetchSettings = (): any => async (
  dispatch: Dispatch,
): Promise<any> => {
  dispatch({ type: FETCH_SETTINGS })
  const cachedData: IResponse<Array<Setting>> = await cache.getItem(
    FETCH_SETTINGS,
  )

  if (cachedData) {
    dispatch(cacheSuccess(cachedData))
  }

  return authGet(Settings.AllSettings)
    .then(async (response) => {
      dispatch(onFetchSuccess(response.data))
      await cache.setItem(FETCH_SETTINGS, response.data)

      return response
    })
    .catch((error) => {
      captureMessage(`fetchBodyDiagrams Failed. ${error}`, Severity.Error)

      dispatch(onFetchFailure(error))

      return error
    })
}

const onFetchSuccess = (response: IResponse<Array<Setting>>) => ({
  type: FETCH_SETTINGS_SUCCESS,
  response,
})

const cacheSuccess = (response: IResponse<Array<Setting>>) => ({
  type: FETCH_SETTINGS_CACHE_SUCCESS,
  response,
})

const onFetchFailure = (error: any) => ({
  type: FETCH_SETTINGS_FAILURE,
  error,
})

const populateTabDefaultsIfMissing = (state: SettingsState) => {
  const tabSettings = state[TAB_TYPES]

  if (!tabSettings) {
    return getDefaultSettings(TAB_TYPES)
  }

  if (!tabSettings.find((ts: any) => ts.key === defaultTabs[2].key)) {
    tabSettings.push(defaultTabs[2])
  }

  return tabSettings
}
