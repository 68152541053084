import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
	Icon,
	IconButton,
	SImage as I,
	Popup,
	SList as L,
	H4,
	LinkText,
} from 'components/ui'
import { useGetColourTheme } from 'lib/get-colour-theme'
import CTImage from '../../../components/image/ct-image'

const Image = styled(I)`
  border-radius: 50%;
  position: absolute;
  margin: auto;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
`

const List = styled(L)`
  width: 150px;
`

interface IUserImageContainer {
	name?: string
}

const UserImageContainer = styled.div<IUserImageContainer>`
  z-index: 9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`

const ListItem = styled(L.Item)`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`

const UserIcon = styled(Icon)`
  &&&&& {
    line-height: normal;
    font-size: 2.9em;
  }
`

const UserCard = (props: IUserCard) => {
	const [open, setOpen] = useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)
	const theme = useGetColourTheme('dark')

	const { user, userImageUrl, onLogout } = props

	const trigger = (
		<UserImageContainer name='user-card'>
			{userImageUrl ? (
				<CTImage imageID={userImageUrl} style={{ borderRadius: '50%' }} />
			) : (
				<UserIcon
					color={theme === 'dark' ? 'white' : 'black'}
					name='user circle'
					size='big'
				/>
			)}
		</UserImageContainer>
	)

	return (
		<Popup
			content={
				<div>
					<H4>{user.displayName || user.email}</H4>
					<Content
						hideDetailsOnUnverified={props.hideDetailsOnUnverified}
						onClick={handleClose}
						onLogout={onLogout}
					/>
				</div>
			}
			hoverable
			on={['hover']}
			onClose={handleClose}
			onOpen={handleOpen}
			open={open}
			position='bottom right'
			trigger={trigger}
			wide='very'
		/>
	)
}

const Content = ({
									 onLogout,
									 onClick,
									 hideDetailsOnUnverified,
								 }: ContentProps) => (
	<List relaxed>
		{!hideDetailsOnUnverified && (
			<ListItem onClick={onClick}>
				<Link to='/account'>
					<IconButton name='setting' /> Profile
				</Link>
			</ListItem>
		)}
		{/* <List.Item>
      <a
        href="mailto:incoming+ChristopherFunk/client-trackyr@incoming.gitlab.com"
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon type="mail" /> New Issue
      </a>
    </List.Item> */}
		<ListItem onClick={onLogout}>
			<LinkText>
				<IconButton name='sign-out' /> Logout
			</LinkText>
		</ListItem>
	</List>
)

interface IUserCard {
	onLogout: Function | any
	user: any // TODO - IUser
	userImageUrl: string
	hideDetailsOnUnverified?: boolean
}

interface ContentProps {
	onClick: Function
	onLogout: Function
	hideDetailsOnUnverified: boolean
}

export default UserCard
