import {
	reducer as fetchFeatures,
	defaultState as fetchFeaturesDefaultState,
} from './fetch-features'

import {
	reducer as fetchUsers,
	defaultState as fetchUsersDefaultState,
} from '../admin/redux/fetch-all-users'
import {
	reducer as fetcBetaUsers,
	defaultState as fetchBetaUsersDefaultState,
} from '../admin/redux/fetch-beta-users'
import {
	reducer as fetchUserFeatures,
	defaultState as fetchUserFeaturesDefaultState,
} from '../admin/redux/fetch-user-features'
import {
	reducer as setFeature,
	defaultState as setFeatureDefaultState,
} from '../admin/redux/set-feature'
import {
	reducer as setBetaUser,
	defaultState as setBetaUserDefaultState,
} from '../admin/redux/set-beta-user'
import {
	reducer as removeBetaUser,
	defaultState as removeBetaUserDefaultState,
} from '../admin/redux/remove-beta-user'
import { FeaturesState, IAction } from 'interfaces'

const defaultState: FeaturesState = {
	...fetchFeaturesDefaultState,
	...fetchUsersDefaultState,
	...fetchBetaUsersDefaultState,
	...fetchUserFeaturesDefaultState,
	...setFeatureDefaultState,
	...setBetaUserDefaultState,
	...removeBetaUserDefaultState,
}

const reducers = [
	fetchFeatures,
	fetchUsers,
	fetcBetaUsers,
	fetchUserFeatures,
	setFeature,
	setBetaUser,
	removeBetaUser,
]

const reducer = (state = defaultState, action: IAction) => {
	switch (action.type) {
		default:
			break
	}

	return reducers.reduce((s, r) => r(s as any, action), state)
}

export { reducer, defaultState }
