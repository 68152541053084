import React from 'react'
import { Card, Text, H1 } from 'components/ui'

import { ContainerLayout } from 'components/ui'

const NotFound = () => (
  <ContainerLayout>
    <H1>404 - Not Found</H1>
    <Card hideTitle>
      <Text>Sorry, the page you&apos;re looking for doesn&apos;t exist</Text>
    </Card>
  </ContainerLayout>
)

export default NotFound
