import { Reports } from 'constants/endpoints'
import { InvoiceReport, IQueryParams, IResponse } from 'interfaces'
import { authGet } from 'lib/http-request'

export const queryAudits = async (
  queryParams: IQueryParams,
): Promise<IResponse<InvoiceReport>> => {
  try {
    return await authGet(Reports.Invoices(queryParams))
  } catch (err) {
    return err
  }
}
