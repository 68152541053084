import React, { useEffect, useState } from 'react'
import localforage from 'localforage'

import { Card } from 'components/ui'
import { IEvent } from 'interfaces'
import { UserStepOne } from './user-step-one'
import { SetupData } from './interfaces'
import { PracticeTypeStepTwo } from './practice-type-step-two'
import { ClinicStepThree } from './clinic-step-three'
import { ReviewStepFour } from './review-step-four'
import { CLIENT_TRACKYR_STORE, FIRST_TIME_SETUP } from 'constants/storage'

const DATA = 'setupData'
const store = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: FIRST_TIME_SETUP,
})

export const FirstTimeSetup = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [userData, setUserData] = useState({ officeAddress: {} } as SetupData)

  useEffect(() => {
    ;(async () => {
      const data: SetupData = await store.getItem(DATA)
      if (data) {
        setUserData(data)
      }
    })()
  }, [])

  const onChange = ({ target }: IEvent) => {
    store.setItem(DATA, { ...userData, [target.name]: target.value })
    setUserData((prev) => ({ ...prev, [target.name]: target.value }))
  }
  const onPageNavigate = (step: number) =>
    step <= 3 && step >= 0 && setActiveStep(step)
  const clearData = () => store.removeItem(DATA)

  return (
    <div style={{ maxWidth: '40em', margin: 'auto' }}>
      <Card hideTitle>
        {activeStep === 0 && (
          <UserStepOne
            data={userData}
            onChange={onChange}
            onNextStep={onPageNavigate}
          />
        )}

        {activeStep === 1 && (
          <PracticeTypeStepTwo
            data={userData}
            onChange={onChange}
            onNextStep={onPageNavigate}
          />
        )}

        {activeStep === 2 && (
          <ClinicStepThree
            data={userData}
            onChange={onChange}
            onNextStep={onPageNavigate}
          />
        )}

        {activeStep === 3 && (
          <ReviewStepFour
            clearData={clearData}
            data={userData}
            onNextStep={onPageNavigate}
          />
        )}
      </Card>
    </div>
  )
}
