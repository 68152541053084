import axios from 'axios'
import localforage from 'localforage'

import { getServer } from 'constants/endpoints'
import { logout, getAuthToken } from 'lib/auth'
import {
	CLIENT_TRACKYR_STORE,
	IMAGES_STORE_NAME,
	REQUEST_CACHE,
	FILES_STORE_NAME,
} from 'constants/storage'
import { Options } from 'interfaces'
import { IResponse, IQueryParams } from 'interfaces/fetch'
import { ONE_HOUR } from 'constants/cache'

const HTTP_UNAUTHORIZED = 401

const cacheStore = localforage.createInstance({
	name: CLIENT_TRACKYR_STORE,
	storeName: REQUEST_CACHE,
})

/**
 * Get's an Axios instance with the baseURL set
 * @param  {string} token auth token from firebase
 * @return {any}    Axios Instance
 */
const getClientTrackerInstance = (token: string, headers = {}): any => {
	return axios.create({
		baseURL: getServer(),
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
			...headers,
		},
	})
}
/**
 * Get's an Axios instance with the baseURL set
 * @param  {string} token auth token from firebase
 * @return {any}    Axios Instance
 */
const getClientTrackerInstanceNoAuth = (headers = {}): any =>
	axios.create({
		baseURL: getServer(),
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
	})

/**
 * authPost: Authorized Post to Server handing HTTP 401
 * @param  {string} endpoint Subpath of the Endpoint (i.e. /all-users)
 * @param  {object} data Body of POST
 * @param {config:any} config optional config information
 * @return {Promise<any>} Returns Then and Catch Promises
 */
export const authPost = (
	endpoint: string,
	data: object,
	config: any = {},
): Promise<any> =>
	getAuthToken().then((token) =>
		getClientTrackerInstance(token, config?.headers)
			.post(endpoint, data, config)
			.then((response: IResponse<any>) => response)
			.catch((error: any) => {
				if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
					onUnauthorized()
				} else {
					throw error
				}
			}),
	)

/**
 * post: HTTP Post
 * @param  {string}  endpoint Subpath of the Endpoint (i.e. /all-users)
 * @param  {any}     data Body of POST
 * @return {Promise} Returns Then and Catch Promises
 */
export const post = (endpoint: string, data: any, config = {}): Promise<any> =>
	getClientTrackerInstanceNoAuth()
		.post(endpoint, data, config)
		.then((response: IResponse<any>) => response)
		.catch((error: any) => {
			if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
				onUnauthorized()
			} else {
				throw error
			}
		})

/**
 * authGet: Authorized POST to Server handing HTTP 401
 * @param  {string} endpoint Subpath of the Endpoint (i.e. /:userID)
 * @param  {any}    options  Request Options: Cache: explicitly use a cache for the response. True by default
 *                           noCache: explicitly do not cache the response
 * @return {Promise} Returns Then and Catch Promises, handles HTTP401
 */
export const authGet = (
	endpoint: string,
	options: Options = {
		cache: false,
		cacheMaxAge: ONE_HOUR,
		cacheDir: '',
	},
	queryParams: IQueryParams = {},
	headers = {},
): Promise<any> =>
	getAuthToken().then(async (token) => {
		if (options.cache) {
			const fromCacheResponse = await getFromCache(options.cacheDir || endpoint)

			if (
				fromCacheResponse.status === 304 ||
				fromCacheResponse.status === 204
			) {
				return fromCacheResponse
			}
		}

		return getClientTrackerInstance(token, headers)
			.get(endpoint, { params: queryParams })
			.then((response: IResponse<any>) => {
				if (options.cache) {
					saveToCache(
						options.cacheDir || endpoint,
						response.data,
						options.cacheMaxAge,
					)
				}

				return response
			})
			.catch((error: any) => {
				if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
					onUnauthorized()
				} else {
					throw error
				}
			})
	})

/**
 * authPut: Authorized PUT to Server handing HTTP 401
 * @param  {string}  endpoint Subpath of the Endpoint (i.e. /all-users)
 * @param  {any}     data Body of PUT
 * @return {Promise} Returns Then and Catch Promises
 */
export const authPut = (endpoint: string, data: any): Promise<any> =>
	getAuthToken().then((token) =>
		getClientTrackerInstance(token)
			.put(endpoint, data)
			.then((response: IResponse<any>) => response)
			.catch((error: any) => {
				if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
					onUnauthorized()
				} else {
					throw error
				}
			}),
	)

/**
 * authPatch: Authorized Patch to Server handing HTTP 401
 * @param  {string}  endpoint Subpath of the Endpoint (i.e. /all-users)
 * @param  {any}     data Body of PATCH
 * @return {Promise} Returns Then and Catch Promises
 */
export const authPatch = (endpoint: string, data: any): Promise<any> =>
	getAuthToken().then((token) =>
		getClientTrackerInstance(token)
			.patch(endpoint, data)
			.then((response: IResponse<any>) => response)
			.catch((error: any) => {
				if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
					onUnauthorized()
				} else {
					throw error
				}
			}),
	)

/**
 * authPut: Authorized DELETE to Server handing HTTP 401
 * @param  {string}  endpoint Subpath of the Endpoint (i.e. /all-users)
 * @return {Promise} Returns Then and Catch Promises
 */
export const authDelete = (endpoint: string): Promise<any> =>
	getAuthToken().then((token) =>
		getClientTrackerInstance(token)
			.delete(endpoint)
			.then((response: IResponse<any>) => response)
			.catch((error: any) => {
				if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
					onUnauthorized()
				} else {
					throw error
				}
			}),
	)

const onUnauthorized = () => {
	logout()
	// window.location.replace('/auth/login')
}

// TODO - combine with authGetFile
/**
 * authGetImage: Authorized POST to Server handing HTTP 401, caches Base64 Images in IndexDB
 * @param  {string} endpoint Subpath of the Endpoint (i.e. /:userID)
 * @param  {string} id       Image ID
 * @return {Promise} Returns Then and Catch Promises, handles HTTP401
 */
export const authGetImage = async (
	endpoint: string,
	id: string,
): Promise<any> => {
	// if (indexDb.getItem(id))...

	if (!id) {
		console.error('You must use ID for authGetImage')
		return
	}

	const store = localforage.createInstance({
		name: CLIENT_TRACKYR_STORE,
		storeName: IMAGES_STORE_NAME,
	})

	return store.getItem(id).then((response: IResponse<any>) => {
		if (response) {
			return {
				data: response,
				status: 304,
				statusText: 'Not Modified',
			}
		} else {
			return getAuthToken().then((token) =>
				getClientTrackerInstance(token)
					.get(endpoint)
					.then((response: IResponse<any>) => {
						const file = dataURLtoFile(response.data, id)
						store.setItem(id, file)

						return {
							...response,
							data: file,
						}
					})
					.catch((error: any) => {
						if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
							onUnauthorized()
						} else {
							throw error
						}
					}),
			)
		}
	})
}

/**
 * authGetFile: Authorized GET to fetch and save a File handing HTTP 401
 * @param  {string} endpoint Subpath of the Endpoint (i.e. /:userID)
 * @param  {string} fileID File ID Guid
 * @return {Promise} Returns Then and Catch Promises, handles HTTP401
 */
export const authGetFile = async (
	endpoint: string,
	fileID: string,
): Promise<any> => {
	if (!fileID) {
		console.error('You must use fileID for authGetFile')
		return
	}

	const store = localforage.createInstance({
		name: CLIENT_TRACKYR_STORE,
		storeName: FILES_STORE_NAME,
	})

	return store.getItem(fileID).then((response: IResponse<any>) => {
		if (response) {
			return {
				data: response,
				status: 304,
				statusText: 'Not Modified',
			}
		} else {
			return getAuthToken().then((token: string) =>
				getClientTrackerInstance(token)
					.get(endpoint)
					.then((response: IResponse<any>) => {
						const file = dataURLtoFile(response.data, fileID)
						// const file = new File([response.data], fileID, { type: response.contentType })
						store.setItem(fileID, file)

						return {
							...response,
							data: file,
						}
					})
					.catch((error: any) => {
						if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
							onUnauthorized()
						} else {
							throw error
						}
					}),
			)
		}
	})
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
	if (!dataurl) {
		return null
	}
	var arr = dataurl?.split?.(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new File([u8arr], filename, { type: mime })
}

export const b64atob = (b64str: string) => {
	if (!b64str) {
		return b64str
	}

	const token = b64str.split(',')

	if (!token || token.length !== 2) {
		console.error('Invalid b64 String')
		return b64str
	}

	try {
		return atob(token[1])
	} catch (ex) {
		console.error(ex)
	}
}

/**
 * Get's an Axios instance with the baseURL set and no Auth Token
 * @param  {string} token auth token from firebase
 * @return {any}    Axios Instance
 */
const getHttpClientTrackerInstance = (): any =>
	axios.create({
		baseURL: getServer(),
		headers: {
			'Content-Type': 'application/json',
		},
	})

/**
 * get
 * @param  {string} endpoint Subpath of the Endpoint (i.e. /:userID)
 * @return {Promise} Returns Then and Catch Promises
 */
export const get = (endpoint: string): Promise<any> =>
	getHttpClientTrackerInstance()
		.get(endpoint)
		.then((response: IResponse<any>) => response)
		.catch((error: any) => {
			if (error.response && error.response.status === HTTP_UNAUTHORIZED) {
				onUnauthorized()
			} else {
				throw error
			}
		})

const getFromCache = async (requestEndpoint: string) => {
	const response = await cacheStore.getItem(requestEndpoint)
	const expiry = await cacheStore.getItem(`${requestEndpoint}::EXPIRY`)

	if (response != null && expiry != null && Date.now() < expiry) {
		return {
			status: !response ? 204 : 304,
			data: response,
		}
	} else {
		cacheStore.removeItem(requestEndpoint)
		cacheStore.removeItem(`${requestEndpoint}::EXPIRY`)

		return {
			status: 404,
		}
	}
}
const saveToCache = async (
	requestEndpoint: string,
	data: any,
	maxAge = ONE_HOUR,
) => {
	await cacheStore.setItem(requestEndpoint, data)
	await cacheStore.setItem(`${requestEndpoint}::EXPIRY`, Date.now() + maxAge)
}
