import { createSelector } from 'reselect'
import { getSelectedClientID$ } from 'containers/clients/redux/selectors'
import { IState } from 'interfaces'
// import { getSelectedAppointmentID } from 'containers/appointments/selectors'

const getSelectedAppointmentID = (state: IState) =>
	(state.appointments.selectedAppointment &&
		state.appointments.selectedAppointment.id) ||
	''

const notesView = (state: IState) => state.soapNotes.notesView

export const getCurrentNotesView = createSelector(
	[notesView, getSelectedClientID$],
	(notesView, selectedClientID) => !notesView[selectedClientID],
)

const getSoaps = (state: IState) => state.soapNotes.soaps

export const getSoapsForSelectedClient = createSelector(
	[getSelectedClientID$, getSoaps],
	(selectedClientID, soaps) => soaps[selectedClientID] || {},
)

export const getSelectedNote = createSelector(
	[getSelectedClientID$, getSoaps, getSelectedAppointmentID],
	(selectedClientID, soaps, selectedAppointmentID) => {
		const soapsForClientID = soaps[selectedClientID] || {}
		const soapForAppointment = soapsForClientID[selectedAppointmentID]

		return soapForAppointment || { noNote: true }
	},
)
