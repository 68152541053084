import { useState } from 'react'

import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  FinePrint,
  Icon,
  Label,
  Segment,
  Text,
} from 'components/ui'

export const HelperString = () => {
  const [activeAccordian, setActiveAccordian] = useState(-1)

  return (
    <Accordion style={{ width: '100%' }}>
      <AccordionTitle
        active={activeAccordian === 0}
        index={0}
        onClick={(_, { index }) =>
          setActiveAccordian(
            Number(index) === activeAccordian ? -1 : Number(index),
          )
        }
      >
        <Icon name="dropdown" />
        Helper Strings
      </AccordionTitle>
      <AccordionContent active={activeAccordian === 0}>
        <Label>Constants</Label>
        <Text>
          Use these constants in your email to auto populate their values
        </Text>

        <Segment>
          <Text>
            {
              '{CLIENT_NAME} : This is replaced by the Clients First and Last Name in the email'
            }
          </Text>
          <Text>{'{CLIENT_FIRST_NAME} : Client\'s First Name'}</Text>
          <Text>{'{CLIENT_LAST_NAME} : Client\'s First Name'}</Text>
          <Text>
            {
              '{USER_NAME} : This is replaced by your First and Last Name in the email'
            }
          </Text>
          <Text>{'{USER_FIRST_NAME} : You\'re First Name'}</Text>
          <Text>{'{USER_LAST_NAME} : You\'re Last Name'}</Text>
          <Text>{'{ORG} : You\'re Organizations Name from Branding'}</Text>
          <Text>
            {
              '{ORG_ADDRESS} : You\'re Organizations Name and Address from Branding'
            }
          </Text>

          <Text>
            {
              '{APPOINTMENT_START_TIME_FORMATTED_WITH_VERB}: Tomorrow or 6 Days, used with Appointment Emails'
            }
          </Text>

          <Text>{'{EMPTY} : No text, overrides defaults'}</Text>

          <Text>{'{APPOINTMENT_START_TIME}: An Appointments start time'}</Text>
          <Text>{'{APPOINTMENT_END_TIME}: An Appointments end time'}</Text>
          {/* <Text>{'{APPOINTMENT_LOCATION}'}</Text> */}
          <Text>
            {
              '{APPOINTMENT_START_TIME_FORMATTED}: Appointments Start Time Formatted to look like "Tomorrow" or "2 Days"'
            }
          </Text>
          <Text>
            {
              '{APPOINTMENT_START_TIME_FORMATTED_WITH_VERB} Appointments Start Time Formatted to look like "For Tomorrow" or "In 2 Days"'
            }
          </Text>

          <FinePrint>
            {
              'Example: {CLIENT_FIRST_NAME}, For Your Next Appointment with {USER_NAME}, Please Complete Your Intake Forms'
            }
          </FinePrint>
        </Segment>
      </AccordionContent>
    </Accordion>
  )
}
