import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authDelete } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'
import { IAction, PublicIntakeState } from 'interfaces'

const action = (action: string) => `client-trackyr/public-intake/${action}`
const CLOSE_PUBLIC_INTAKE = action('CLOSE_PUBLIC_INTAKE')
const CLOSE_PUBLIC_INTAKE_SUCCESS = action('CLOSE_PUBLIC_INTAKE_SUCCESS')
const CLOSE_PUBLIC_INTAKE_FAILURE = action('CLOSE_PUBLIC_INTAKE_FAILURE')

export const defaultState = {
  closingForm: false,
  closingFormError: { error: false },
} as PublicIntakeState

export const reducer = (
  state: PublicIntakeState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case CLOSE_PUBLIC_INTAKE:
      return {
        ...state,
        closingForm: true,
      }

    case CLOSE_PUBLIC_INTAKE_SUCCESS: {
      const publicIntake = action.response
      const clientIntakes = state[publicIntake.clientID].publicIntakeForms
      const closedIntakeIndex = clientIntakes.findIndex(
        (ci: any) => (ci.id = publicIntake.id),
      )

      clientIntakes[closedIntakeIndex] = publicIntake

      return {
        ...state,
        [publicIntake.clientID]: {
          ...state[publicIntake.clientID],
          publicIntakeForms: [...clientIntakes],
        },
        closingForm: false,
      }
    }

    case CLOSE_PUBLIC_INTAKE_FAILURE:
      return {
        ...state,
        closingForm: false,
        closingFormError: {
          error: true,
          message: 'Failed to close forms',
        },
      }

    default:
      return state
  }
}

const closePublicIntakeForm = (id: string): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: CLOSE_PUBLIC_INTAKE, id })

  return authDelete(PublicIntake.Close(id))
    .then((response) => {
      dispatch(closeSuccess(response.data))
      return response
    })
    .catch((error) => {
      dispatch(closeFailure(error))
      captureMessage(
        `closePublicIntakeForm Get Failed: ${error}`,
        Severity.Error,
      )

      throw error
    })
}

const closeSuccess = (response: any) => ({
  type: CLOSE_PUBLIC_INTAKE_SUCCESS,
  response,
})

const closeFailure = (error: any) => ({
  type: CLOSE_PUBLIC_INTAKE_FAILURE,
  error,
})

export { closePublicIntakeForm }
