import { reducer as deleteOffice } from './delete-office'
import { reducer as saveOffice } from './save-office'
import { reducer as fetchOffices } from './fetch-offices'
import { IAction, AccountState, OfficeTimeSlot } from 'interfaces'

const officeAction = (action: string) => `client-trackyr/offices/${action}`

const ON_EDIT_OFFICE = officeAction('ON_EDIT_OFFICE')
const ON_OFFICE_CHANGE = officeAction('ON_OFFICE_CHANGE')
const ADD_OFFICE = officeAction('ADD_OFFICE')
const ON_ADD_TIME_SLOT = officeAction('ON_ADD_TIME_SLOT')
const ON_REMOVE_TIME_SLOT = officeAction('ON_REMOVE_TIME_SLOT')
const ON_TIME_SLOT_CHANGE = officeAction('ON_TIME_SLOT_CHANGE')

const reducers = [deleteOffice, saveOffice, fetchOffices]

export const defaultState = {} as AccountState

export const reducer = (
  state: AccountState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case ON_EDIT_OFFICE: {
      const offices = { ...state.offices }
      const id = action.id
      offices[id].editing = action.checked

      return {
        ...state,
        offices,
      }
    }

    case ON_OFFICE_CHANGE: {
      const offices = { ...state.offices }
      const id = action.id
      const office = offices[id]

      office[action.name] = action.value
      office.changesMade = true

      return {
        ...state,
        offices,
        changesMade: true,
      }
    }

    case ADD_OFFICE: {
      const newOffice = {
        id: 'new',
        editing: true,
        changesMade: true,
        newOffice: true,
        days: [],
      }
      return {
        ...state,
        offices: {
          ...state.offices,
          new: newOffice,
        },
        officesByID: ['new', ...state.officesByID],
        changesMade: true,
      }
    }

    case ON_ADD_TIME_SLOT: {
      const offices = { ...state.offices }
      const days = offices[action.officeID].days

      days
        .filter((d) => d.dayID === action.day)[0]
        .slots.push({ from: '09:00', to: '17:00' } as OfficeTimeSlot)
      offices[action.officeID].changesMade = true

      return {
        ...state,
        offices,
      }
    }

    case ON_REMOVE_TIME_SLOT: {
      const offices = { ...state.offices }
      const days = offices[action.officeID].days
      const slots = days.filter((d) => d.dayID === action.day)[0].slots

      days.filter((d) => d.dayID === action.day)[0].slots = [
        ...slots.slice(0, action.slotIndex),
        ...slots.slice(action.slotIndex + 1),
      ]
      offices[action.officeID].changesMade = true

      return {
        ...state,
        offices,
        changesMade: true,
      }
    }

    case ON_TIME_SLOT_CHANGE: {
      const offices = { ...state.offices }
      const days = offices[action.officeID].days
      const slots = days.filter((d) => d.dayID === action.day)[0].slots
      offices[action.officeID].changesMade = true

      slots[action.slotIndex][action.name] = action.value

      return {
        ...state,
        offices,
      }
    }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const editOffice = (checked: boolean, id: string) => ({
  type: ON_EDIT_OFFICE,
  id,
  checked,
})

export const onOfficeChange = (name: string, value: string, id: string) => ({
  type: ON_OFFICE_CHANGE,
  name,
  value,
  id,
})

export const addOffice = () => ({ type: ADD_OFFICE })

export const onAddTimeSlot = (day: number, officeID: string) => ({
  type: ON_ADD_TIME_SLOT,
  day,
  officeID,
})

export const onRemoveTimeSlot = (
  day: number,
  slotIndex: number,
  officeID: string,
) => ({
  type: ON_REMOVE_TIME_SLOT,
  day,
  slotIndex,
  officeID,
})

export const onTimeSlotChange = (
  name: string,
  value: string,
  day: number,
  slotIndex: number,
  officeID: string,
) => ({
  type: ON_TIME_SLOT_CHANGE,
  name,
  value,
  day,
  slotIndex,
  officeID,
})
