import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Font, ReadonlyFormFont } from 'styles/font'
import { Input } from 'components/ui'
import { IEvent } from 'interfaces'

const TimePicker = styled(Input)`
  input {
    ${Font};
    -webkit-datetime-edit-minute-field[disabled] {
    }
    color: ${(props) => props.theme.TextColour} !important;
    background-color: ${(props) => props.theme.BackgroundColour} !important;
    border-color: ${(props) => props.theme.InputBorderColour} !important;
  }
`

const TP = ({ defaultValue, value, readonly, ...rest }: ITimePicker) =>
  readonly ? (
    <ReadonlyFormFont>
      {moment(`0001-01-01T${value}:00`).format('h:mm a')}
    </ReadonlyFormFont>
  ) : (
    <TimePicker
      icon="clock"
      type="time"
      value={value || defaultValue}
      {...rest}
    />
  )

interface ITimePicker {
  clearIcon?: string
  clockIcon?: string
  defaultOpenValue?: moment.Moment | Date
  defaultValue: string
  disableClock?: boolean
  disabled?: boolean
  format?: string
  name?: string
  onBlur?: Function
  onChange?: (e: IEvent) => void
  placeholder?: string
  readonly: boolean
  value: string
}

export default TP
