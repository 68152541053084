import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import localforage from 'localforage'

import {
  ErrorText,
  Form,
  FormField,
  Checkbox,
  H2,
  LinkText,
} from 'components/ui'
import { Input } from 'components/forms'
import * as Ducks from 'containers/auth'
import { loginRequest } from 'containers/auth/redux/login-request'
import ErrorAlert from 'components/error'
import { LS_REMEMBER_ME } from 'constants/local-storage'
import { Spinner } from 'components/loaders'
import { CLIENT_TRACKYR_STORE, FLAGS } from 'constants/storage'

import SubmitAuthButton from './submit-auth-button'
import ForgotPasswordModal from './forgot-password-modal'
import {
  resetPasswordRequest,
  resetModal,
} from '../redux/forgot-password-request'
import { IEvent, IState, Dispatch } from 'interfaces'

const store = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: FLAGS,
})

const LoginContainer = styled.div`
  width: 35%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1244px) {
    width: 65%;
  }

  @media (max-width: 1024px) {
    width: 85%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  max-width: 35em;
`

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required('Email Address is required')
    .email('Must be a valid email address'),
  password: Yup.string().required('Password is required'),
})

class Login extends React.Component<ILogin> {
  state: ILoginState = {
    emailAddress: '',
    rememberMe: true,
    visible: false,
  }

  onSubmit = async (
    values: any,
    setSubmitting: Function,
    resetForm: Function,
  ) => {
    this.props.setError(false)
    await store.setItem(LS_REMEMBER_ME, this.state.rememberMe)

    this.props
      .loginRequest(
        values.emailAddress.trim(),
        values.password,
        this.state.rememberMe,
      )
      .then(() => {
        resetForm()
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)
      })
  }

  onEmailChanged = ({ target }) =>
    this.setState(() => ({ emailAddress: target.value }))

  onRememberMeChanged = async ({ target }: IEvent) => {
    await store.setItem(LS_REMEMBER_ME, target.value)
    this.setState(() => ({ rememberMe: target.value }))
  }
  showModal = (show: boolean) => this.setState({ visible: show })

  renderLoginView = () => {
    return (
      <LoginContainer>
        <H2>Login</H2>

        <Formik
          initialValues={{
            emailAddress: '',
            password: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.onSubmit(values, setSubmitting, resetForm)
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                icon="mail"
                label="Email Address"
                name="emailAddress"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Email Address"
                size="large"
                type="email"
                value={values.emailAddress}
              />
              <ErrorText>
                {errors.emailAddress &&
                  touched.emailAddress &&
                  errors.emailAddress}
              </ErrorText>

              <Input
                icon="lock"
                label="Password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Password"
                size="large"
                type="password"
                value={values.password}
              />
              <ErrorText>
                {errors.password && touched.password && errors.password}
              </ErrorText>

              <FormField>
                <Checkbox
                  checked={this.state.rememberMe}
                  label="Remember Me"
                  onChange={this.onRememberMeChanged}
                />
              </FormField>

              <FormField>
                <SubmitAuthButton
                  content="Log In"
                  isSubmitting={isSubmitting}
                />
              </FormField>

              <FormField>
                <LinkText onClick={() => this.showModal(true)}>
                  Forgot your password?
                </LinkText>

                <ForgotPasswordModal
                  email={this.state.emailAddress}
                  errorMsg={this.props.resetError}
                  hasError={!!this.props.resetError}
                  loading={this.props.resetLoading}
                  onCancel={() => {
                    this.showModal(false)
                    this.props.resetModal()
                  }}
                  onEmailChanged={this.onEmailChanged}
                  onSubmit={() =>
                    this.props.resetPasswordRequest(this.state.emailAddress)
                  }
                  success={this.props.resetSuccess}
                  visible={this.state.visible}
                />
              </FormField>
            </Form>
          )}
        </Formik>

        <ErrorAlert
          errorMsg={this.props.errorMsg}
          hasError={this.props.hasError}
        />
      </LoginContainer>
    )
  }

  render() {
    return (
      <Spinner spinning={this.props.loading}>{this.renderLoginView()}</Spinner>
    )
  }
}

interface ILogin {
  errorMsg: string
  hasError: boolean
  loading: boolean
  loginRequest: Function
  resetError: string
  resetLoading: boolean
  resetModal: Function
  resetPasswordRequest: Function
  resetSuccess: boolean
  setError: Function
}

interface ILoginState {
  emailAddress: string
  rememberMe: boolean
  visible: boolean
}

const mapStateToProps = (state: IState) => ({ ...state.auth })

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginRequest: (email: string, password: string, rememberMe: boolean) =>
    dispatch(loginRequest(email, password, rememberMe)),
  setLoading: (isLoading: boolean) => dispatch(Ducks.setLoading(isLoading)),
  setError: (hasError: boolean, errorMsg = '') =>
    dispatch(Ducks.setError(hasError, errorMsg)),
  resetPasswordRequest: (email: string) =>
    dispatch(resetPasswordRequest(email)),
  resetModal: () => dispatch(resetModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
