import React from 'react'

import {
  ActivableListItem,
  Avatar,
  H4,
  ListIcon,
  Icon,
  ListContent,
  ListHeader,
  ListDescription,
} from 'components/ui'
import { Service } from '../interfaces'
import { formatAmount } from 'lib'

interface Props {
  service: Service
  onClick: (service: Service) => void
}

export const ServiceItem = ({ service, onClick }: Props) => {
  const onItemClick = () => {
    onClick(service)
  }

  // TODO - different icon
  return (
    <ActivableListItem key={service.id} onClick={onItemClick}>
      <ListIcon>
        <Avatar size={'40px'}>
          <Icon name="wrench" size="big" style={{ margin: 'auto' }} />
        </Avatar>
      </ListIcon>

      <ListContent>
        <ListHeader>
          <H4>{`${service.name}  - $${formatAmount(service.cost)}`}</H4>
        </ListHeader>

        <ListDescription>{service.description}</ListDescription>
      </ListContent>
    </ActivableListItem>
  )
}
