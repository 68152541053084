import { authPost } from 'lib/http-request'
import { Soaps } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { IFormsData, IResponse, Note, Severity } from 'interfaces'
import { message } from 'components/notifications'

import { stripValidation } from 'containers/forms'
import { onSoapsLoad } from 'containers/draw'
import { invalidateCacheLike, parseShapesToAPI } from 'lib'
import { IAction, SoapNotesState } from 'interfaces'

const soapsAction = (action: string) => `client-trackyr/soap-notes/${action}`
const ON_SAVE_SOAP = soapsAction('ON_SAVE_SOAP_SOAP')
const ON_SAVE_SOAP_SUCCESS = soapsAction('ON_SAVE_SOAP_SUCCESS')
const ON_SAVE_SOAP_FAILURE = soapsAction('ON_SAVE_SOAP_FAILURE')

export const defaultState = {
	soapSaving: false,
} as SoapNotesState

export const reducer = (
	state: SoapNotesState = defaultState,
	action: IAction,
) => {
	switch (action.type) {
		case ON_SAVE_SOAP:
			return {
				...state,
				soapSaving: true,
			}

		case ON_SAVE_SOAP_SUCCESS: {
			const clientID = action.clientID
			const soaps = state.soaps
			const clientSoaps = soaps[clientID] || {}
			const soap = action.data

			if (!soap) {
				return {
					...state,
					soapSaving: false,
					changesMade: false,
				}
			}

			const { fields, ...rest } = soap
			clientSoaps[soap.appointmentID] = {
				...rest,
				id: soap.id,
				appointmentID: soap.appointmentID,
				isDirty: false,
				fetched: true,
				...fields,
			}

			return {
				...state,
				dirtySoap: '',
				soaps: {
					...state.soaps,
					[action.clientID]: {
						...clientSoaps,
					},
				},
				soapSaving: false,
				changesMade: false,
			}
		}

		case ON_SAVE_SOAP_FAILURE: {
			return {
				...state,
				soapSaving: false,
			}
		}

		default:
			return state
	}
}

export const onSaveSoap = (soapData: Note) => async (
	dispatch: any,
): Promise<any> => {
	dispatch({ type: ON_SAVE_SOAP, soapData })

	const {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		appointment, // eslint-disable-line no-unused-vars
		appointmentID,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		isDirty, // eslint-disable-line no-unused-vars
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		isNew, // eslint-disable-line no-unused-vars
		bodyDiagramID,
		id,
		shapes,
		soapTypeID,
		clientID,
		treatmentNumber,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		appointmentDate, // eslint-disable-line no-unused-vars
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetched, // eslint-disable-line no-unused-vars
		userID, // eslint-disable-line no-unused-vars
		...fields
	} = stripValidation(soapData, 'validation')

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { shapesSummaryOverride, ...f } = fields // eslint-disable-line no-unused-vars

	const soap = {
		id,
		clientID,
		bodyDiagramID,
		appointmentID,
		shapes: parseShapesToAPI(shapes),
		fields: f,
		soapTypeID,
		treatmentNumber,
	}

	return authPost(Soaps.SaveSoap, soap)
		.then((response: IResponse<IFormsData>) => {
			message.success('Notes Saved')

			if (response.status === 204) {
				response.data = {}
			}

			dispatch(onSaveSuccess(response.data.data, clientID))
			dispatch(onSoapsLoad([response.data]))
			invalidateCacheLike(`/soaps/client/${clientID}`)

			return response
		})
		.catch((error) => {
			message.error('Failed to Save Notes')
			captureMessage(`Failed to Save Notes ${error}`, Severity.Error)
			dispatch(onSaveFailure(error))

			return error
		})
}

const onSaveSuccess = (data: any, clientID: string) => ({
	type: ON_SAVE_SOAP_SUCCESS,
	data,
	clientID,
})

const onSaveFailure = (error: any) => ({
	type: ON_SAVE_SOAP_FAILURE,
	error,
})
