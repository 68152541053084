export const CLIENT_TRACKYR_STORE = 'client-trackyr'

export const FORMS_STORE_NAME = 'forms'
export const IMAGES_STORE_NAME = 'images'
export const FILES_STORE_NAME = 'files'
export const REQUEST_CACHE = 'request-cache'
export const APPOINTMENTS = 'appointments'
export const FLAGS = 'flags'
export const FORM_BUILDER = 'form-builder'
export const FIRST_TIME_SETUP = 'first-time-setup'

export const STORES = [
  FORMS_STORE_NAME,
  IMAGES_STORE_NAME,
  REQUEST_CACHE,
  FILES_STORE_NAME,
  APPOINTMENTS,
  FLAGS,
  FORM_BUILDER,
  FIRST_TIME_SETUP,
]
