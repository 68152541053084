import React from 'react'
import { connect } from 'react-redux'

import { TreatmentCloseModal } from 'components/modals'
import { Button, Modal, Header, Icon } from 'components/ui'

import {
	getTreatmentsForClient$,
	closeTreatment,
	saveTreatment,
	getSeletectedTreatmentNumber$,
} from 'containers/treatment'

import TreatmentList from './treatment-list'
import { IState } from 'interfaces'
import { NavigateFunction, useNavigate } from 'react-router-dom'

class TreatmentButton extends React.Component<TreatmentButtonProps> {
	state = {
		clearModalOpen: false,
		closeModalOpen: false,
		closeTreatmentMessage: '',
		treatmentsVisible: false,
	}

	showCloseConfirm = () => this.setState(() => ({ clearModalOpen: true }))

	renderTreatmentCloseModal = () => {
		const modalProps = {
			modalOpen: this.state.clearModalOpen,
			message: 'Are you sure you want to close this treatment?',
			content:
				'This action is irreversible. You will have to to create a new treatment to write additonal notes and information',
			onAccept: () => {
				this.setState(() => ({ clearModalOpen: false }))
				this.props.closeTreatment(
					this.props.treatments.find((t) => !t.closed),
					this.state.closeTreatmentMessage,
				)
			},
			onCancel: () => this.setState(() => ({ clearModalOpen: false })),
		}

		return (
			<TreatmentCloseModal
				{...modalProps}
				messageValue={this.state.closeTreatmentMessage}
				onMessageChange={(e) => {
					const value = e.target.value
					this.setState(() => ({ closeTreatmentMessage: value }))
				}}
			/>
		)
	}

	render() {
		const { treatments, fetchingPrevious } = this.props

		const selectedTreatment =
			treatments.find(
				(t) => t.treatmentNumber === this.props.selectedTreatmentNumber,
			) || {}
		const currentTreatment = treatments.find((t) => !t.closed)
		const previousTreatments = treatments.filter((t) => t.closed)

		const buttonText = selectedTreatment.closed
			? `Treatment Case #${
				this.props.selectedTreatmentNumber || ''
			} (Case Closed)`
			: `Treatment Case #${this.props.selectedTreatmentNumber || ''}`

		return (
			<>
				{this.renderTreatmentCloseModal()}
				<Modal
					closeIcon
					onClose={() => this.setState(() => ({ treatmentsVisible: false }))}
					open={this.state.treatmentsVisible}
					trigger={
						<Button
							negative={selectedTreatment.closed}
							onClick={() => this.setState(() => ({ treatmentsVisible: true }))}
						>
							<Icon name='clipboard' />
							{buttonText}
							<Icon name='angle right' />
						</Button>
					}
				>
					<Header content='Treatment Case' icon='clipboard' />
					<Modal.Content>
						<TreatmentList
							closingTreatment={this.props.closingTreatment}
							currentTreatment={currentTreatment}
							fetchingPrevious={fetchingPrevious}
							onCloseTreatment={() => {
								this.setState(() => ({ treatmentsVisible: false }))
								this.showCloseConfirm()
							}}
							previousTreatments={previousTreatments}
							saveTreatment={(clientTypeID, createMessage = '') => {
								this.setState(() => ({ treatmentsVisible: false }))
								this.props.saveTreatment(this.props.clientID, clientTypeID, {
									treatmentOpenMessage: createMessage,
								})
									.then((response) => this.props.navigate(`/client/${this.props.clientID}/client-info/treatment/${response.data.treatmentNumber}`))
							}}
							savingTreatment={this.props.savingTreatment}
							selectedTreatmentNumber={this.props.selectedTreatmentNumber}
							selectTreatment={(treatmentNumber) => {
								let tab = 'notes'
								if (document.location.pathname.includes('client-info')) {
									tab = 'client-info'
								}

								this.setState(() => ({ treatmentsVisible: false }))
								this.props.navigate(`/client/${this.props.clientID}/${tab}/treatment/${treatmentNumber}`)
							}}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() =>
								this.setState(() => ({ treatmentsVisible: false }))
							}
						>
							<Icon name='x' /> Close
						</Button>
					</Modal.Actions>
				</Modal>
			</>
		)
	}
}

interface TreatmentButtonProps {
	clientID: string
	closeTreatment: Function
	closingTreatment: boolean
	fetchingPrevious: boolean
	navigate?: NavigateFunction
	saveTreatment: Function
	savingTreatment: boolean
	selectedTreatmentNumber: number
	treatments: Array<any>
}

const mapStateToProps = (state: IState) => ({
	treatments: getTreatmentsForClient$(state),
	fetchingPrevious: state.treatments.fetchingPreviousTreatments,
	// fetched: getPreviousTreatmentsForClientFetched$(state),
	closingTreatment: state.treatments.closingTreatment,
	savingTreatment: state.treatments.savingTreatment,
	selectedTreatmentNumber: getSeletectedTreatmentNumber$(state),
})

const mapDispatchToProps = (dispatch: any) => ({
	closeTreatment: (currentTreatment: any, closeMessage: string) =>
		dispatch(closeTreatment(currentTreatment, closeMessage)),
	saveTreatment: (clientID: string, clientTypeID: string, treatment = {}) =>
		dispatch(saveTreatment(clientID, clientTypeID, treatment)),
})

const TreatmentButtonWrapper = (props: TreatmentButtonProps) => {
	const navigate = useNavigate()
	return <TreatmentButton {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentButtonWrapper)
