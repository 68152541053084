import { Dropdown } from 'components/forms'
import { Form } from 'components/ui'
import { getAllClientsList$, getClientFullName } from 'containers/clients'
import { ServiceDropdown } from 'containers/receipts/components/service-dropdown'
import { IEvent } from 'interfaces'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
interface Props {
  clientID: string
  onChange: (e: IEvent) => void
  serviceID: string
}

export const AppointmentModalSerivceClientSelect = (props: Props) => {
  const clientList = useSelector(getAllClientsList$)
  const [clientIDStartedUndefined, setClientIDStartedUndefined] =
    useState(false)

  useEffect(() => {
    if (!props.clientID) {
      setClientIDStartedUndefined(true)
    }
  }, [props.clientID])

  return (
    <Form style={{ width: '75%' }}>
      <Dropdown
        autoFocus={!props.clientID}
        clearable
        label="Client"
        name="clientID"
        onChange={props.onChange}
        options={clientList.map((client: any) => ({
          id: client.id,
          key: client.id,
          value: client.id,
          text: getClientFullName(client),
        }))}
        placeholder="Select Client"
        readonly={!clientIDStartedUndefined && !!props.clientID}
        style={{ width: '100%' }}
        validationRules={{ required: true }}
        value={props.clientID}
      />

      <ServiceDropdown
        autoFocus={!!props.clientID}
        onChange={props.onChange}
        value={props.serviceID}
      />
    </Form>
  )
}
