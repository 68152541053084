import React from 'react'
import * as Sentry from '@sentry/browser'
import { Message, Text, LinkText } from 'components/ui'

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    error: null,
    eventId: null,
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.setState({ error })

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.error) {
      return (
        <Message
          content={
            <Text>
              Our team has been notified, but
              <LinkText
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                click here
              </LinkText>
              fill out a report.
            </Text>
          }
          error
          header={<Text>We&apos;re sorry — something&apos;s gone wrong.</Text>}
          icon="warning"
        />
      )
    }

    return this.props.children
  }
}

interface ErrorBoundaryProps {
  children: React.ReactNode
}
