import {
  Checkbox,
  CheckboxGroup,
  CheckboxGroupInput,
  CheckboxInput,
  GroupLabel,
  Input,
  InputArea,
  TextArea,
  PhoneNumber,
  Radio,
  YesNo,
  DatePicker,
  Dropdown,
} from 'components/forms'
import {
  Card,
  Divider,
  FinePrint,
  Form,
  FormColumnArea,
  FormHeader,
  Label,
  Placeholder,
  Segment,
  Text,
  UnderlinedText,
} from 'components/ui'
import { IEvent } from 'interfaces'
import React, { useState } from 'react'
import { ComponentType, FormItemComponent } from '../interfaces'
import { SPLIT_CHAR } from './multi-text-values'

interface Props {
  component: FormItemComponent
  values: any
}

export const ComponentPreview = ({ component, values }: Props) => {
  const [tempValue, setTempValue] = useState(null)
  const onChange = ({ target }: IEvent) => {
    setTempValue(target.value)
  }

  const baseHeaderPlaceHolder = () => (
    <Placeholder>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  )

  const smallPlaceholder = () => (
    <Placeholder>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  )

  const getComponent = () => {
    const type = component.type
    switch (type) {
      case ComponentType.Card:
        return (
          <Card
            collapsible={values.collapsible}
            hideTitle={values.hideTitle}
            title={values.title}
          >
            {baseHeaderPlaceHolder()}
          </Card>
        )

      case ComponentType.Group:
        return (
          <>
            <GroupLabel>{values.title}</GroupLabel>
            {smallPlaceholder()}
            {values.showBreak && <hr />}
          </>
        )

      case ComponentType.Segment:
        return <Segment>{smallPlaceholder()}</Segment>

      // case ComponentType.MultiForm:
      //   return (
      //     <MultiForm
      //       count={1}
      //       name=""
      //       onChange={() => {}}
      //       values={{}}
      //       readonly={false}
      //     >
      //       {smallPlaceholder()}
      //     </MultiForm>
      //   )

      case ComponentType.Columns:
        return (
          <FormColumnArea>
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          </FormColumnArea>
        )

      case ComponentType.Input:
      case ComponentType.Email: {
        return (
          <Form>
            <Input
              icon={values.icon}
              label={values.label}
              name={values.title}
              onChange={onChange}
              placeholder={values.placeholder}
              validation={{ required: values.required }}
              value={tempValue}
            />
          </Form>
        )
      }

      case ComponentType.TextArea:
        return (
          <Form>
            <TextArea
              label={values.label}
              name={values.title}
              onChange={onChange}
              placeholder={values.placeholder}
              validation={{ required: values.required }}
              value={tempValue}
            />
          </Form>
        )

      case ComponentType.TextInput:
        return (
          <Form>
            <InputArea
              label={values.label}
              name={values.title}
              onChange={onChange}
              placeholder={values.placeholder}
              validation={{ required: values.required }}
              value={tempValue}
            />
          </Form>
        )

      case ComponentType.PhoneNumber:
        return (
          <Form>
            <PhoneNumber
              label={values.label}
              name={values.title}
              onChange={onChange}
              placeholder={values.placeholder}
              validation={{ required: values.required }}
              value={tempValue || [{}]}
            />
          </Form>
        )

      case ComponentType.Date:
        return (
          <Form>
            <DatePicker
              format={values.format}
              label={values.label}
              name={values.title}
              onChange={onChange}
              placeholder={values.placeholder}
              showTime={values.showTime}
              validation={{ required: values.required }}
              value={tempValue}
            />
          </Form>
        )

      case ComponentType.Checkbox:
        return (
          <Form>
            <Checkbox
              label={values.label}
              name={values.title}
              onChange={onChange}
              toggle={values.toggle}
              validation={{ required: values.required }}
              value={tempValue}
            />
          </Form>
        )

      case ComponentType.CheckboxInput:
        return (
          <Form>
            <CheckboxInput
              label={values.label}
              name={values.title}
              onChange={onChange}
              toggle={values.toggle}
              validation={{ required: values.required }}
              valueCheck={tempValue}
              valueNotes={tempValue}
            />
          </Form>
        )

      case ComponentType.CheckboxForm:
        return (
          <Form>
            <Checkbox
              label={values.label}
              name={values.title}
              onChange={onChange}
              toggle={values.toggle}
              validation={{ required: values.required }}
              value={tempValue}
            />
            {tempValue && smallPlaceholder()}
          </Form>
        )

      case ComponentType.CheckboxGroup: {
        const options =
          values.options?.split(SPLIT_CHAR)?.map((o) => ({
            id: o,
            label: o,
            key: o,
          })) || []
        return (
          <Form>
            <CheckboxGroup
              label={values.label}
              name={values.title}
              onChange={onChange}
              options={options}
              toggle={values.toggle}
              validation={{ required: values.required }}
              value={tempValue}
            />
          </Form>
        )
      }

      case ComponentType.CheckboxGroupInput: {
        const options =
          values.options?.split(SPLIT_CHAR)?.map((o) => ({
            id: o,
            label: o,
            key: o,
          })) || []
        return (
          <Form>
            <CheckboxGroupInput
              label={values.label}
              name={values.title}
              onChange={onChange}
              options={options}
              toggle={values.toggle}
              validation={{ required: values.required }}
              valueCheck={tempValue}
              valueNotes={tempValue}
            />
          </Form>
        )
      }

      case ComponentType.CheckboxGroupForm: {
        const options =
          values.options?.split(SPLIT_CHAR)?.map((o) => ({
            id: o,
            label: o,
            key: o,
          })) || []
        return (
          <Form>
            <CheckboxGroup
              label={values.label}
              name={values.title}
              onChange={onChange}
              options={options}
              toggle={values.toggle}
              validation={{ required: values.required }}
              value={tempValue}
            />
            {tempValue && smallPlaceholder()}
          </Form>
        )
      }

      case ComponentType.Radio: {
        const options = values.options?.split(SPLIT_CHAR)?.map((o) => o) || []
        if (options.length < 2) {
          return null
        }

        return (
          <Form>
            <Radio
              label={values.label}
              onChange={onChange}
              options={options}
              value={tempValue}
            />
          </Form>
        )
      }

      case ComponentType.Yesno: {
        return (
          <Form>
            <YesNo label={values.label} onChange={onChange} value={tempValue} />
          </Form>
        )
      }

      case ComponentType.Yesnoform: {
        return (
          <Form>
            <YesNo label={values.label} onChange={onChange} value={tempValue} />
            {tempValue === values.trigger && (
              <Segment>
                <div>{smallPlaceholder()}</div>
              </Segment>
            )}
          </Form>
        )
      }

      case ComponentType.Dropdown: {
        const options =
          values.options?.split(SPLIT_CHAR)?.map((o) => ({
            id: o,
            text: o,
            key: o,
            value: o,
          })) || []

        return (
          <Form>
            <Dropdown
              clearable={values.clearable}
              label={values.label}
              multiple={values.multiple}
              name={values.title}
              onChange={onChange}
              options={options}
              placeholder={values.placeholder}
              validation={{ required: values.required }}
              value={tempValue}
            />
          </Form>
        )
      }

      case ComponentType.Label:
        return (
          <div>
            <Label>{values.title}</Label>{' '}
          </div>
        )

      case ComponentType.Text:
        return (
          <div>
            <Text>{values.title}</Text>
          </div>
        )

      case ComponentType.FinePrint:
        return (
          <div>
            <FinePrint>{values.title}</FinePrint>
          </div>
        )

      case ComponentType.FormHeader:
        return (
          <div>
            <FormHeader
              centre={['Centre Text']}
              date="right"
              left={['Left Text']}
              name="header"
              onChange={onChange}
              right={['Right Text']}
            />
          </div>
        )

      case ComponentType.Divider:
        return (
          <div>
            {smallPlaceholder()}
            <Divider hidden={values.hidden} />
            {smallPlaceholder()}
          </div>
        )

      default:
        return null
    }
  }

  const preview = getComponent()

  if (preview === null) {
    return null
  }

  return (
    <div style={{ paddingTop: '0.75em' }}>
      <UnderlinedText>Preview</UnderlinedText>
      {preview}
    </div>
  )
}
