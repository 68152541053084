import { TaxesForReport } from 'interfaces'

export const compare = (a: TaxesForReport, b: TaxesForReport) => {
  if (a.name < b.name) {
    return -1
  }

  if (a.name > b.name) {
    return 1
  }

  return 0
}