import React from 'react'

import { Dropdown } from 'components/forms'
import { IEvent } from 'interfaces'

interface Props {
  onChange: (e: IEvent) => void
  readonly?: boolean
  value: string
}

// cash cheque, eTransfer, credit card, paypal, other
const paymentOptions = [
  {
    id: 'Cash',
    key: 'Cash',
    value: 'Cash',
    text: 'Cash',
  },
  {
    id: 'Cheque',
    key: 'Cheque',
    value: 'Cheque',
    text: 'Cheque',
  },
  {
    id: 'eTransfer',
    key: 'eTransfer',
    value: 'eTransfer',
    text: 'eTransfer',
  },
  {
    id: 'Credit Card',
    key: 'Credit Card',
    value: 'Credit Card',
    text: 'Credit Card',
  },
  {
    id: 'Debit Card',
    key: 'Debit Card',
    value: 'Debit Card',
    text: 'Debit Card',
  },
  {
    id: 'Paypal',
    key: 'Paypal',
    value: 'Paypal',
    text: 'Paypal',
  },
  {
    id: 'Other',
    key: 'Other',
    value: 'Other',
    text: 'Other',
  },
]

export const PaymentTypeDropdown = (props: Props) => {
  return (
    <>
      <Dropdown
        clearable
        label="Paid by"
        name="paymentType"
        onChange={props.onChange}
        options={paymentOptions}
        readonly={props.readonly}
        value={props.value}
      />
    </>
  )
}
