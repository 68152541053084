import React, { useState } from 'react'

import {
	ActivableListItem,
	Avatar,
	ListIcon,
	Icon,
	ListContent,
	ListHeader,
	ListDescription,
	H4,
} from 'components/ui'

import { FormGeneratorListItem } from '../interfaces'
import { IEvent, IFormItem } from 'interfaces'
import { ListItemModal } from './list-item-modal'

interface Props {
	description?: string
	form: any
	header?: string
	icon?: string
	item?: FormGeneratorListItem
	// onChange: (e: IEvent) => void
	renderer?: (formItems: Array<IFormItem>) => any
}

export const DefaultListItem = (props: Props) => {
	const [viewListItem, setViewListItem] = useState({ open: false, item: null })
	const onModalClose = () => setViewListItem({ open: false, item: null })
	const onModalOpen = () => setViewListItem({ open: true, item: props.item })

	return (
		<>
			<ListItemModal
				form={props.form}
				// item={viewListItem.item}
				// onChange={props.onChange}
				onClose={onModalClose}
				open={viewListItem.open}
				renderer={props.renderer}
			/>

			<ActivableListItem key={props.item.id} onClick={onModalOpen}>
				{props.icon && (
					<ListIcon>
						<Avatar size={'40px'}>
							<Icon name='print' size='big' style={{ margin: 'auto' }} />
						</Avatar>
					</ListIcon>
				)}

				<ListContent>
					<ListHeader>
						<H4>{props.header}</H4>
					</ListHeader>

					<ListDescription>{props.description}</ListDescription>
				</ListContent>
			</ActivableListItem>
		</>
	)
}
