import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authPost } from 'lib/http-request'
import { Features } from 'constants/endpoints'
import { FeaturesState, IAction } from 'interfaces'

const action = (action: string) => `client-trackyr/features/${action}`
const SET_FEATURE = action('SET_FEATURE')
const SET_FEATURE_SUCCESS = action('SET_FEATURE_SUCCESS')
const SET_FEATURE_FAILURE = action('SET_FEATURE_FAILURE')

export const defaultState = {
  settingFeature: false,
  settingFeatureError: { error: false },
} as FeaturesState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SET_FEATURE:
      return {
        ...state,
        settingFeature: true,
      }

    case SET_FEATURE_SUCCESS: {
      if (action.response.key.includes('global:')) {
        return {
          ...state,
          settingFeature: false,
          features: {
            ...state.features,
            [action.response.key]: action.response.value,
          },
        }
      } else {
        return {
          ...state,
          settingFeature: false,
          userFeatures: {
            ...state.userFeatures,
            [action.response.userID]: {
              ...state.userFeatures[action.response.userID],
              [action.response.key]: action.response.value,
            },
          },
        }
      }
    }

    case SET_FEATURE_FAILURE:
      return {
        ...state,
        settingFeature: false,
        settingFeatureError: {
          error: true,
          message: 'Failed to set feature',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
// TODO - load fron indexDB, but also fetch
const setFeature = (feature) => async (dispatch) => {
  dispatch({ type: SET_FEATURE, feature })

  return authPost(Features.Set, feature)
    .then((response) => {
      dispatch(fetchSuccess(response.data))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(`setFeature Post Failed: ${error}`, Severity.Error)

      throw error
    })
}

const fetchSuccess = (response) => ({
  type: SET_FEATURE_SUCCESS,
  response,
})

const fetchFailure = (error) => ({
  type: SET_FEATURE_FAILURE,
  error,
})

export { setFeature }
