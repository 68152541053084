import { authDelete } from 'lib/http-request'
import { Taxes } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, Dispatch, IResponse, TaxState } from 'interfaces'
import { Tax } from '../interfaces'

const DELETE_TAX = 'client-trackyr/taxes/DELETE_TAX'
const DELETE_TAX_SUCCESS = 'client-trackyr/taxes/DELETE_TAX_SUCCESS'
const DELETE_TAX_FAILURE = 'client-trackyr/taxes/DELETE_TAX_FAILURE'

export const reducer = (state: TaxState, action: IAction) => {
  switch (action.type) {
    case DELETE_TAX: {
      return {
        ...state,
        flags: {
          ...state.flags,
          deleting: true,
        },
      }
    }

    case DELETE_TAX_SUCCESS: {
      let allIDs = state.allIDs
      const taxes = { ...state.taxes }

      allIDs = allIDs.filter((id) => id !== action.id)
      delete taxes[action.id]

      return {
        ...state,
        allIDs,
        taxes,
        totalTaxes: allIDs.length,
        flags: {
          ...state.flags,
          deleting: false,
        },
      }
    }

    case DELETE_TAX_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          deleting: false,
        },
      }
    }
    default:
      return state
  }
}

export const deleteTax = (id: string): any => async (
  dispatch: Dispatch,
): Promise<IResponse<Tax>> => {
  dispatch({ type: DELETE_TAX, id })

  return authDelete(Taxes.Delete(id))
    .then((response) => {
      message.success('Tax Deleted')
      dispatch(onDeleteSuccess(id))
      return response
    })
    .catch((error) => {
      message.error('Failed to delete tax')
      captureMessage(`deleteReceipt Failed. ${error}`, Severity.Error)
      dispatch(onDeleteFailure(error, id))
      return error
    })
}

const onDeleteSuccess = (id: string) => ({
  type: DELETE_TAX_SUCCESS,
  id,
})
const onDeleteFailure = (error: any, id: string) => ({
  type: DELETE_TAX_FAILURE,
  error,
  id,
})
