import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import localforage from 'localforage'

import { authGet } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { CLIENT_TRACKYR_STORE, FORMS_STORE_NAME } from 'constants/storage'

import { saveForm } from './ducks'
import { THIRTY_DAYS } from 'constants/cache'
import { IAction } from 'interfaces'

const formsAction = (action: string) => `client-trackyr/forms/${action}`
const FETCH_FORMS = formsAction('FETCH_FORMS')
const FETCH_FORM = formsAction('FETCH_FORM')
const RE_FETCHING_FORMS = formsAction('RE_FETCHING_FORMS')
const FETCH_FORMS_SUCCESS = formsAction('FETCH_FORMS_SUCCESS')
const FETCH_FORMS_ERROR = formsAction('FETCH_FORMS_ERROR')

export const defaultState = {
  fetchingForms: true,
  fetchingFormsError: { error: false },
}

const store = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: FORMS_STORE_NAME,
})

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_FORMS:
      return {
        ...state,
        fetchingForms: true,
      }

    case FETCH_FORMS_SUCCESS:
      return {
        ...state,
        fetchingForms: false,
      }

    case FETCH_FORMS_ERROR:
      return {
        ...state,
        // fetchingForms: false,
        fetchingFormsError: {
          error: true,
          message: 'Failed to load all forms',
        },
      }

    default:
      return state
  }
}

export const fetchForms = (forms: Array<any>): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_FORMS, forms })

  const formsLastFetched = await store.getItem('formsLastFetched')
  const formsExpired = Date.now() - Number(formsLastFetched) >= THIRTY_DAYS

  let refetchForms = false
  for (const form of forms) {
    if (!form || !form.id) {
      continue
    }

    const lsForm = await store.getItem(form.id)

    if (!lsForm || formsExpired) {
      refetchForms = true
      break
    }

    dispatch(saveForm(lsForm))
  }

  if (!refetchForms) {
    dispatch(fetchFormsSuccess())
    return
  }

  dispatch({ type: RE_FETCHING_FORMS })

  await store.setItem('formsLastFetched', Date.now())

  const fetchFormsPromises = forms.map((form) => fetchForm(form.id, dispatch))
  Promise.all(fetchFormsPromises).then(() => dispatch(fetchFormsSuccess()))
}

const fetchForm = async (formID: string, dispatch: any): Promise<any> => {
  dispatch({ type: FETCH_FORM, formID })

  return authGet(Storage.Form(formID))
    .then(async (response) => {
      await store.setItem(response.data.id, response.data)
      dispatch(saveForm(response.data))

      return response
    })
    .catch((error) => {
      captureMessage(`fetchForms Get Failed: ${error}`, Severity.Error)

      dispatch(fetchFormsFailure(error))

      return error
    })
}

export const fetchFormsSuccess = () => ({ type: FETCH_FORMS_SUCCESS })
export const fetchFormsFailure = (error: any) => ({
  type: FETCH_FORMS_ERROR,
  error,
})
