import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'

import VerifyEmail from 'containers/auth/components/verify-email'
import { Container, ContainerLayout, Message, Text, H3 } from 'components/ui'
import { setNavBarText } from 'components/nav'
import { PRACTICE_TYPE } from 'constants/settings'

import FirstTimeSetup from './components/first-time-setup'
import OfficeSetup from './components/office-setup'
import { TodaysClients } from './components/todays-clients'
import { IState, Dispatch, IUser, Office } from 'interfaces'

export class Dashboard extends React.Component<IDashboard> {
	componentDidMount() {
		this.props.setNavBarText('Dashboard')
	}

	// https://gist.github.com/James1x0/8443042
	getGreetingTime = (m: moment.Moment) => {
		let g = null

		if (!m || !m.isValid()) {
			return
		}

		const split_afternoon = 12 //24hr time to split the afternoon
		const split_evening = 17 //24hr time to split the evening
		const currentHour = parseFloat(m.format('HH'))

		if (currentHour >= split_afternoon && currentHour <= split_evening) {
			g = 'afternoon'
		} else if (currentHour >= split_evening) {
			g = 'evening'
		} else {
			g = 'morning'
		}

		return g
	}

	onAppointmentClick = (clientID: string, appointmentID: string) => {
		this.props.navigate(`/client/${clientID}/notes/${appointmentID}`)
	}


	render() {
		const { user, ctUser } = this.props

		return (
			<ContainerLayout>
				<Container>
					{ctUser.fetched &&
						!this.props.fetchingAccountData &&
						!ctUser.isSetup && <FirstTimeSetup />}

					{ctUser.fetched &&
						!Object.keys(this.props.offices).length &&
						!this.props.fetchingOffice && <OfficeSetup />}

					{!ctUser.isVerified && <VerifyEmail />}

					{ctUser.fetched &&
						!this.props.fetchingSettings &&
						!this.props.practiceType && (
							<Message
								content={
									<Text>
										You must pick a Practice Type from your{' '}
										<Link to='account/settings'>Account</Link> before you can
										create/edit clients or soaps
									</Text>
								}
								error
								header='You must select a practice type'
								icon='warning'
							/>
						)}

					<H3>
						Good {this.getGreetingTime(moment())}{' '}
						{user.displayName
							? user.displayName
							: `${user.firstName || ''} ${user.lastName || ''}`}
					</H3>

					<div style={{ maxWidth: '32.5em' }}>
						<TodaysClients
							onAppointmentClick={this.onAppointmentClick}
						/>
					</div>
				</Container>
			</ContainerLayout>
		)
	}
}

interface IDashboard {
	ctUser: IUser
	fetchingAccountData: boolean
	fetchingOffice: boolean
	fetchingSettings: boolean
	navigate?: NavigateFunction
	offices: Array<Office>
	practiceType: string
	setNavBarText: Function
	user: IUser
}

const mapStateToProps = (state: IState) => ({
	ctUser: state.user.ctUser,
	day: state.dashboard.day,
	fetchingOffice: state.account.fetchingOffice,
	fetchingSettings: state.settings.fetchingSettings,
	fetchingAccountData: state.account.fetchingAccountData,
	offices: state.account.offices || [],
	practiceType: state.settings[PRACTICE_TYPE],
	user: state.user.ctUser,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setNavBarText: (text: string) => dispatch(setNavBarText(text)),
})

const DashboardWrapper = (props: IDashboard) => {
	const navigate = useNavigate()
	return <Dashboard {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper)
