import React from 'react'

import { Button, Modal, Header } from 'components/ui'

interface Props {
  content: string
  message: string
  modalOpen: boolean
  onAccept: Function
  onCancel: Function
}

export default class ClearModal extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    modalOpen: false,
    onCancel: () => {},
  }

  render() {
    return (
      <Modal
        onClose={this.props.onCancel}
        open={this.props.modalOpen}
        size="small"
      >
        <Header content={this.props.message} icon="warning" />
        {this.props.content && (
          <Modal.Content>{this.props.content}</Modal.Content>
        )}
        <Modal.Actions>
          <Button onClick={this.props.onCancel} secondary>
            No
          </Button>
          <Button onClick={this.props.onAccept} primary>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
