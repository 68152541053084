import { authGet } from 'lib/http-request'
import { Appointments } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { maxBy } from 'lodash'
import { IAppointment, IAction } from 'interfaces'
import { ONE_HOUR } from 'constants/cache'

const appointmentAction = (action: string) =>
	`client-trackyr/appointments/${action}`
const FETCH_APPOINTMENT_FOR_CLIENT = appointmentAction(
	'FETCH_APPOINTMENT_FOR_CLIENT',
)
const FETCH_APPOINTMENT_FOR_CLIENT_SUCCESS = appointmentAction(
	'FETCH_APPOINTMENT_FOR_CLIENT_SUCCESS',
)
const FETCH_APPOINTMENT_FOR_CLIENT_ERROR = appointmentAction(
	'FETCH_APPOINTMENT_FOR_CLIENT_ERROR',
)

export const reducer = (state: any, action: IAction) => {
	switch (action.type) {
		case FETCH_APPOINTMENT_FOR_CLIENT: {
			return {
				...state,
				fetchingAppointmentsForClient: true,
			}
		}

		case FETCH_APPOINTMENT_FOR_CLIENT_SUCCESS: {
			const clientAppointments = [
				...new Set([
					...(state.clientAppointments[action.clientID] || []),
					...action.appointments.map((a: IAppointment) => a.id),
				]),
			]

			const appointments = {}
			action.appointments.forEach((a: IAppointment & { userID: string }) => {
				const { userID, ...appointment } = a // eslint-disable-line no-unused-vars
				appointments[a.id] = appointment
			})

			return {
				...state,
				appointments: {
					...state.appointments,
					...appointments,
				},
				clientAppointments: {
					...state.clientAppointments,
					[action.clientID]: clientAppointments,
				},
				fetchingAppointmentsForClient: false,
				selectedAppointment: maxBy(
					action.appointments,
					(a: IAppointment) => a.appointmentNumber,
				),
				allAppointmentsByID: [
					...new Set([
						...(state.allAppointmentsByID || []),
						...clientAppointments,
					]),
				],
			}
		}

		case FETCH_APPOINTMENT_FOR_CLIENT_ERROR: {
			return {
				...state,
				fetchingAppointmentsForClient: false,
			}
		}

		default:
			return state
	}
}

export const fetchAppointmentsForClient = (
	clientID: string,
	treatmentNumber: number,
	queryParams: any = {},
	useCache: boolean = false,
) => async (dispatch: any) => {
	dispatch({ type: FETCH_APPOINTMENT_FOR_CLIENT, clientID, treatmentNumber, queryParams, useCache })

	return authGet(
		Appointments.GetAllForClient(clientID, treatmentNumber, queryParams),
		{
			cache: useCache,
			cacheMaxAge: ONE_HOUR,
		},
	)
		.then((response) => {
			if (response.status === 200 || response.status === 304) {
				dispatch(fetchAppointmentsForClientSuccess(response.data, clientID))
			}
			return response
		})
		.catch((error) => {
			dispatch(fetchAppointmentsForClientError(error))
			message.error('Failed to get appointments for client')

			captureMessage(
				`fetchAppointmentsForClient for clientID ${clientID} Failed. ${error}`,
				Severity.Error,
			)

			throw error
		})
}

export const fetchAppointmentsForClientSuccess = (
	appointments: Array<IAppointment>,
	clientID: string,
) => ({
	type: FETCH_APPOINTMENT_FOR_CLIENT_SUCCESS,
	appointments,
	clientID,
})

const fetchAppointmentsForClientError = (error: any) => ({
	type: FETCH_APPOINTMENT_FOR_CLIENT_ERROR,
	error,
})
