import { authPost } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'
import localforage from 'localforage'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { LS_BODY_DIAGRAM } from 'constants/local-storage'
import { CLIENT_TRACKYR_STORE, FLAGS } from 'constants/storage'
import { IAction, Dispatch } from 'interfaces'

const flagStore = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: FLAGS,
})

const accountAction = (action: string) => `client-trackyr/bodydiagram/${action}`
const SAVE_BODY_DIAGRAM = accountAction('SAVE_BODY_DIAGRAM')
const ON_SAVE_BODY_DIAGRAM_SUCCESS = accountAction(
  'ON_SAVE_BODY_DIAGRAM_SUCCESS',
)
const ON_SAVE_BODY_DIAGRAM_FAILURE = accountAction(
  'ON_SAVE_BODY_DIAGRAM_FAILURE',
)

export const defaultState = {
  bodyDiagrams: [],
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SAVE_BODY_DIAGRAM:
      return {
        ...state,
        bodyDiagramLoading: true,
      }

    case ON_SAVE_BODY_DIAGRAM_SUCCESS: {
      const bodyDiagrams =
        (state.bodyDiagrams &&
          state.bodyDiagrams.map((bd) => ({
            ...bd,
            isCurrent: false,
          }))) ||
        []

      bodyDiagrams.push(action.data)

      return {
        ...state,
        bodyDiagrams,
        bodyDiagramLoading: false,
        bodyDiagramError: false,
      }
    }

    case ON_SAVE_BODY_DIAGRAM_FAILURE:
      return {
        ...state,
        bodyDiagramLoading: false,
        bodyDiagramError: true,
      }

    default:
      return state
  }
}

export const saveBodyDiagram = (path: string, imageUrl: string) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: SAVE_BODY_DIAGRAM, path, imageUrl })

  return authPost(Settings.SaveBodyDiagram, {
    path: path,
    url: imageUrl,
    isCurrent: true,
  })
    .then((response) => {
      message.success('Body Diagram Updated')
      setBodyDiagram(imageUrl)
      dispatch(onSaveSuccess(response.data))

      return response
    })
    .catch((error) => {
      message.error('Failed to Save Body Diagram, please try again')
      captureMessage(`saveBodyDiagram Failed. ${error}`, Severity.Error)
      dispatch(onSaveFailure(error))

      return error
    })
}

const onSaveSuccess = (data: any) => ({
  type: ON_SAVE_BODY_DIAGRAM_SUCCESS,
  data,
})

const onSaveFailure = (error: any) => ({
  type: ON_SAVE_BODY_DIAGRAM_FAILURE,
  error,
})

const setBodyDiagram = async (imageUrl: string) =>
  await flagStore.setItem(LS_BODY_DIAGRAM, imageUrl)
