import React from 'react'

import { Checkbox as C, SegmentOnReadOnly } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps, CheckboxInputProps } from 'components/forms/interfaces'

import { DatePicker } from './date-picker'
import { InputArea } from './input-area'

const CheckboxInputForm = ({
														 autoSave,
														 label,
														 name,
														 onChange,
														 onValidate,
														 placeholder,
														 printParams,
														 readonly,
														 showDate,
														 toggle,
														 validation,
														 valueCheck,
														 valueNotes,
													 }: InputProps & CheckboxInputProps) => (
	<div
		style={{
			paddingBottom: printParams?.printingView ? '' : '0.5em',
			marginTop: printParams?.printingView ? '-1.5em' : '',
		}}
	>
		<AutoSaveWrapper
			afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
			autosaveDisabled={autoSave.autosaveDisabled}
			autosaveMode='onChange'
			debounceTime={250}
			endpoint={autoSave.patchEndpoint}
			entityID={autoSave.entityID}
			formType={autoSave.patchType}
			patch={autoSave.patch}
		>
			<C
				checked={valueCheck}
				label={label}
				name={`${name}Check`}
				onChange={({ target }) => {
					onChange({ target })
					// onChangeCallback && onChangeCallback(target)
				}}
				printParams={printParams}
				readonly={readonly}
				required
				toggle={toggle}
			/>
		</AutoSaveWrapper>

		{(valueCheck || printParams?.printingView) && (
			<SegmentOnReadOnly readonly={readonly && !printParams?.printingView}>
				<AutoSaveWrapper
					afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
					autosaveDisabled={autoSave.autosaveDisabled}
					endpoint={autoSave.patchEndpoint}
					entityID={autoSave.entityID}
					formType={autoSave.patchType}
					patch={autoSave.patch}
				>
					<InputArea
						label=''
						name={`${name}Notes`}
						onChange={onChange}
						onValidate={
							onValidate
								? (validationRules, _, __, value) =>
									onValidate(validationRules, label, name, value)
								: null
						}
						placeholder={placeholder || label}
						printParams={printParams}
						readonly={readonly}
						validation={validation}
						value={valueNotes}
					/>
				</AutoSaveWrapper>
			</SegmentOnReadOnly>
		)}

		{valueCheck && showDate && (
			<DatePicker
				format='MMMM Do YYYY'
				label='Date'
				name={`${name}Date`}
				onChange={onChange}
				placeholder='Date'
				readonly={readonly}
			/>
		)}
	</div>
)

CheckboxInputForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const CheckboxInput = React.memo(CheckboxInputForm)
