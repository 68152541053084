import React from 'react'

import { Signature, ImportantText, Label } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps } from 'components/forms/interfaces'

import MultiForm from './multi-form'
import { Input } from './input'
import { PhoneNumber } from './phone-number'

type MultiMapType = {
	from: string
	label: string
	values: Array<any>
	count: number
}

interface EmergencyContactProps {
	contacts: Object
	signatures: Object
	signatureType: 'single' | 'multiMap' | 'guardian'
	multiMap: Array<MultiMapType>
	count: number
	text: string
}

export default class EmergencyContact extends React.Component<
	InputProps & EmergencyContactProps
> {
	public static defaultProps: Partial<InputProps & EmergencyContactProps> = {
		autoSave: {
			autosaveDisabled: true,
		},
	}

	renderSingleSignature = () => {
		return (
			<AutoSaveWrapper
				afterAutoSaveCallback={this.props.autoSave.afterAutoSaveCallback}
				autosaveDisabled={this.props.autoSave.autosaveDisabled}
				endpoint={this.props.autoSave.patchEndpoint}
				entityID={this.props.autoSave.entityID}
				formType={this.props.autoSave.patchType}
				patch={this.props.autoSave.patch}
			>
				<Signature
					dateValue={this.props.signatures[`${this.props.name}-signature-date`]}
					name={`${this.props.name}-signature`}
					nameValue={this.props.signatures[`${this.props.name}-signature-name`]}
					onChange={this.props.onChange}
					readonly={this.props.readonly}
					signature={this.props.signatures[`${this.props.name}-signature`]}
				/>
			</AutoSaveWrapper>
		)
	}

	renderMultiFormSignature = () => {
		const elements = []

		for (const value of this.props.multiMap) {
			for (let i = 0; i < value.count; i++) {
				elements.push(
					<div key={`${value.from}-signature-${i}`}>
						<Label>{value.label}</Label>

						<AutoSaveWrapper
							afterAutoSaveCallback={this.props.autoSave.afterAutoSaveCallback}
							autosaveDisabled={this.props.autoSave.autosaveDisabled}
							endpoint={this.props.autoSave.patchEndpoint}
							entityID={this.props.autoSave.entityID}
							formType={this.props.autoSave.patchType}
							patch={this.props.autoSave.patch}
						>
							<Signature
								dateValue={value.values[`${value.from}-signature-${i}-date`]}
								name={`${value.from}-signature-${i}`}
								nameValue={value.values[`${value.from}-signature-${i}-name`]}
								onChange={this.props.onChange}
								readonly={this.props.readonly}
								signature={value.values[`${value.from}-signature-${i}`]}
							/>
						</AutoSaveWrapper>
					</div>,
				)
			}
		}

		return elements
	}

	renderParentGuardianSignature = () => (
		<>
			<Label>Parent/Guardian&apos;s Signature</Label>
			<AutoSaveWrapper
				afterAutoSaveCallback={this.props.autoSave.afterAutoSaveCallback}
				autosaveDisabled={this.props.autoSave.autosaveDisabled}
				endpoint={this.props.autoSave.patchEndpoint}
				entityID={this.props.autoSave.entityID}
				formType={this.props.autoSave.patchType}
				patch={this.props.autoSave.patch}
			>
				<Signature
					dateValue={
						this.props.signatures[`${this.props.name}-signature-guardian-date`]
					}
					name={`${this.props.name}-signature-guardian`}
					nameValue={
						this.props.signatures[`${this.props.name}-signature-guardian-name`]
					}
					onChange={this.props.onChange}
					readonly={this.props.readonly}
					signature={
						this.props.signatures[`${this.props.name}-signature-guardian`]
					}
				/>
			</AutoSaveWrapper>

			<Label>Client&apos;s Signature</Label>

			<AutoSaveWrapper
				afterAutoSaveCallback={this.props.autoSave.afterAutoSaveCallback}
				autosaveDisabled={this.props.autoSave.autosaveDisabled}
				endpoint={this.props.autoSave.patchEndpoint}
				entityID={this.props.autoSave.entityID}
				formType={this.props.autoSave.patchType}
				patch={this.props.autoSave.patch}
			>
				<Signature
					dateValue={
						this.props.signatures[`${this.props.name}-signature-clients-date`]
					}
					name={`${this.props.name}-signature-clients`}
					nameValue={
						this.props.signatures[`${this.props.name}-signature-clients-name`]
					}
					onChange={this.props.onChange}
					readonly={this.props.readonly}
					signature={
						this.props.signatures[`${this.props.name}-signature-clients`]
					}
				/>
			</AutoSaveWrapper>
		</>
	)

	render() {
		return (
			<div>
				<MultiForm
					addText='Add Emergency Contact'
					autoSave={this.props.autoSave}
					count={this.props.count}
					name={this.props.name}
					onChange={this.props.onChange}
					readonly={this.props.readonly}
					removeText='Remove Emergency Contact'
					values={this.props.contacts}
				>
					<Input
						autoSave={{
							entityID: this.props.autoSave.entityID,
							patchEndpoint: this.props.autoSave.patchEndpoint,
						}}
						label='Emergency Contact'
						name={`${this.props.name}-emergency-contact`}
						onChange={this.props.onChange}
						readonly={this.props.readonly}
					/>

					<Input
						autoSave={{
							entityID: this.props.autoSave.entityID,
							patchEndpoint: this.props.autoSave.patchEndpoint,
						}}
						label='Relationship'
						name={`${this.props.name}-relationship`}
						onChange={this.props.onChange}
						readonly={this.props.readonly}
					/>

					<PhoneNumber
						autoSave={{
							entityID: this.props.autoSave.entityID,
							patchEndpoint: this.props.autoSave.patchEndpoint,
						}}
						label='Phone Numbers'
						name={`${this.props.name}-phonenumber`}
						onChange={this.props.onChange}
						readonly={this.props.readonly}
					/>
				</MultiForm>

				<ImportantText>{this.props.text}</ImportantText>

				{this.props.signatureType === 'single' && this.renderSingleSignature()}
				{this.props.signatureType === 'multiMap' &&
					this.renderMultiFormSignature()}
				{this.props.signatureType === 'guardian' &&
					this.renderParentGuardianSignature()}
			</div>
		)
	}
}
