import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { authPost } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'

import {
  Button,
  Card,
  Divider,
  Form,
  FormColumnArea,
  Label,
  Segment,
  SubText,
  Text,
} from 'components/ui'
import { Input, InputArea, TextArea } from 'components/forms'
import {
  getSetting,
  getSettingNoDefault,
  saveSettings as saveS,
} from 'containers/settings'
import {
  APPOINTMENT_REMINDER_EMAIL_MESSAGE,
  APPOINTMENT_REMINDER_EMAIL_FAREWELL,
  APPOINTMENT_REMINDER_EMAIL_SUBJECT,
  APPOINTMENT_REMINDER_EMAIL_COMPANY,
  USER_BANNER_RECTANGLE,
  USER_BANNER_SQUARE,
  ORG_NAME,
  ORG_NAME_WITH_ADDRESS,
  getDefaultSettings,
  APPOINTMENT_REMINDER_ICS_SUBJECT,
  APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT,
} from 'constants/settings'
import CTImage from 'components/image/ct-image'
import { IState, Dispatch, SettingKey } from 'interfaces'

export const AppointmentReminderEmailSettings = () => {
  const dispatch: Dispatch = useDispatch()

  const emailMessageSetting = useSelector((state: IState) =>
    getSettingNoDefault(state, APPOINTMENT_REMINDER_EMAIL_MESSAGE),
  )
  const emailIcsTitle = useSelector((state: IState) =>
    getSettingNoDefault(state, APPOINTMENT_REMINDER_ICS_SUBJECT),
  )
  const emailSubjectSetting = useSelector((state: IState) =>
    getSettingNoDefault(state, APPOINTMENT_REMINDER_EMAIL_SUBJECT),
  )
  const emailReminderSubjectSetting = useSelector((state: IState) =>
    getSettingNoDefault(
      state,
      APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT,
    ),
  )
  const companySetting = useSelector((state: IState) =>
    getSettingNoDefault(state, APPOINTMENT_REMINDER_EMAIL_COMPANY),
  )
  const farewellSetting = useSelector((state: IState) =>
    getSettingNoDefault(state, APPOINTMENT_REMINDER_EMAIL_FAREWELL),
  )
  const rectangleBrandImageURL = useSelector((state: IState) =>
    getSetting(state, USER_BANNER_RECTANGLE),
  )
  const squareBrandImageURL = useSelector((state: IState) =>
    getSetting(state, USER_BANNER_SQUARE),
  )
  const orgName = useSelector((state: IState) => getSetting(state, ORG_NAME))
  const orgNameWithAddress = useSelector((state: IState) =>
    getSetting(state, ORG_NAME_WITH_ADDRESS),
  )
  const user = useSelector((state: IState) => state.user.ctUser)

  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false)
  const [emailState, setEmailState] = useState({
    [APPOINTMENT_REMINDER_EMAIL_MESSAGE]: emailMessageSetting,
    [APPOINTMENT_REMINDER_EMAIL_SUBJECT]: emailSubjectSetting,
    [APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT]: emailReminderSubjectSetting,
    [APPOINTMENT_REMINDER_EMAIL_COMPANY]: companySetting,
    [APPOINTMENT_REMINDER_EMAIL_FAREWELL]: farewellSetting,
    [APPOINTMENT_REMINDER_ICS_SUBJECT]: emailIcsTitle,
  })

  useEffect(() => {
    setEmailState((prev) => ({
      ...prev,
      [APPOINTMENT_REMINDER_EMAIL_MESSAGE]: emailMessageSetting,
      [APPOINTMENT_REMINDER_EMAIL_SUBJECT]: emailSubjectSetting,
      [APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT]: emailReminderSubjectSetting,
      [APPOINTMENT_REMINDER_EMAIL_COMPANY]: companySetting,
      [APPOINTMENT_REMINDER_EMAIL_FAREWELL]: farewellSetting,
      [APPOINTMENT_REMINDER_ICS_SUBJECT]: emailIcsTitle,
    }))
  }, [
    emailMessageSetting,
    emailSubjectSetting,
    emailReminderSubjectSetting,
    companySetting,
    farewellSetting,
    emailIcsTitle,
  ])

  const saveSettings = (setting: SettingKey, value: string) =>
    dispatch(
      saveS({
        setting,
        value,
      }),
    )

  const onChange = ({ target }) =>
    setEmailState((prev) => ({
      ...prev,
      [target.name]: target.value,
    }))

  const sendTestEmail = useCallback(() => {
    setIsSendingTestEmail(true)
    authPost(PublicIntake.TestEmail, { email: user.email })
      .then(() => setIsSendingTestEmail(false))
      .catch(() => setIsSendingTestEmail(false))
  }, [user])

  const formatText = (str: string = '') =>
    str
      .replace('{CLIENT_NAME}', 'John Smith')
      .replace('{CLIENT_FIRST_NAME}', 'John')
      .replace('{CLIENT_LAST_NAME}', 'Smith')
      .replace('{USER_NAME}', `${user.firstName || ''} ${user.lastName || ''}`)
      .replace('{USER_FIRST_NAME}', user.firstName)
      .replace('{USER_LAST_NAME}', user.lastName)
      .replace('{ORG}', orgName)
      .replace('{ORG_ADDRESS}', orgNameWithAddress)
      .replace('{EMPTY}', '')

  return (
    <Card collapsible startCollapsed title="Appointment Reminder Emails">
      <FormColumnArea>
        <Card title="Email Text">
          <Form>
            <Input
              autoSave={{
                autosaveMode: 'onBlurOnly',
                entityID: 'setting',
                patch: () =>
                  saveSettings(
                    APPOINTMENT_REMINDER_ICS_SUBJECT,
                    emailState[APPOINTMENT_REMINDER_ICS_SUBJECT],
                  ),
              }}
              label="Calendar Attachment Event Title"
              name={APPOINTMENT_REMINDER_ICS_SUBJECT}
              onChange={onChange}
              placeholder="Title for Calendar Events sent via email"
              value={emailState[APPOINTMENT_REMINDER_ICS_SUBJECT]}
            />

            <Divider />

            <InputArea
              autoSave={{
                autosaveMode: 'onBlurOnly',
                entityID: 'setting',
                patch: () =>
                  saveSettings(
                    APPOINTMENT_REMINDER_EMAIL_SUBJECT,
                    emailState[APPOINTMENT_REMINDER_EMAIL_SUBJECT],
                  ),
              }}
              label="Subject"
              name={APPOINTMENT_REMINDER_EMAIL_SUBJECT}
              onChange={onChange}
              placeholder={getDefaultSettings(
                APPOINTMENT_REMINDER_EMAIL_SUBJECT,
              )}
              value={emailState[APPOINTMENT_REMINDER_EMAIL_SUBJECT]}
            />

            <InputArea
              autoSave={{
                autosaveMode: 'onBlurOnly',
                entityID: 'setting',
                patch: () =>
                  saveSettings(
                    APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT,
                    emailState[
                      APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT
                    ],
                  ),
              }}
              label="Reminder Subject"
              name={APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT}
              onChange={onChange}
              placeholder={getDefaultSettings(
                APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT,
              )}
              value={
                emailState[
                  APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT
                ]
              }
            />

            <TextArea
              autoSave={{
                autosaveMode: 'onBlurOnly',
                entityID: 'setting',
                patch: () =>
                  saveSettings(
                    APPOINTMENT_REMINDER_EMAIL_MESSAGE,
                    emailState[APPOINTMENT_REMINDER_EMAIL_MESSAGE],
                  ),
              }}
              label="Greeting"
              name={APPOINTMENT_REMINDER_EMAIL_MESSAGE}
              onChange={onChange}
              placeholder={getDefaultSettings(
                APPOINTMENT_REMINDER_EMAIL_MESSAGE,
              )}
              value={emailState[APPOINTMENT_REMINDER_EMAIL_MESSAGE]}
            />

            <TextArea
              autoSave={{
                autosaveMode: 'onBlurOnly',
                entityID: 'setting',
                patch: () =>
                  saveSettings(
                    APPOINTMENT_REMINDER_EMAIL_FAREWELL,
                    emailState[APPOINTMENT_REMINDER_EMAIL_FAREWELL],
                  ),
              }}
              label="Farewell Text"
              name={APPOINTMENT_REMINDER_EMAIL_FAREWELL}
              onChange={onChange}
              placeholder={getDefaultSettings(
                APPOINTMENT_REMINDER_EMAIL_FAREWELL,
              )}
              value={emailState[APPOINTMENT_REMINDER_EMAIL_FAREWELL]}
            />

            <InputArea
              autoSave={{
                autosaveMode: 'onBlurOnly',
                entityID: 'setting',
                patch: () =>
                  saveSettings(
                    APPOINTMENT_REMINDER_EMAIL_COMPANY,
                    emailState[APPOINTMENT_REMINDER_EMAIL_COMPANY],
                  ),
              }}
              label="Footer: Company"
              name={APPOINTMENT_REMINDER_EMAIL_COMPANY}
              onChange={onChange}
              placeholder={getDefaultSettings(
                APPOINTMENT_REMINDER_EMAIL_COMPANY,
              )}
              value={emailState[APPOINTMENT_REMINDER_EMAIL_COMPANY]}
            />
          </Form>
        </Card>

        <Card title="Preview">
          <Label>Calendar Attachment Event Title</Label>
          <Text>
            {formatText(
              emailState[APPOINTMENT_REMINDER_ICS_SUBJECT] ||
                getDefaultSettings(APPOINTMENT_REMINDER_ICS_SUBJECT),
            )}
          </Text>

          <Divider />

          <Label>
            {formatText(
              emailState[APPOINTMENT_REMINDER_EMAIL_SUBJECT] ||
                getDefaultSettings(APPOINTMENT_REMINDER_EMAIL_SUBJECT),
            )}
          </Label>

          <Segment>
            {rectangleBrandImageURL && (
              <CTImage imageID={rectangleBrandImageURL} />
            )}
            <Divider hidden />

            {emailState[APPOINTMENT_REMINDER_EMAIL_MESSAGE]
              ? emailState[APPOINTMENT_REMINDER_EMAIL_MESSAGE].split(
                  '\n',
                ).map((text: string, index: number) => (
                  <Text key={index}>{formatText(text)}</Text>
                ))
              : formatText(
                  getDefaultSettings(APPOINTMENT_REMINDER_EMAIL_MESSAGE),
                )}

            <Text>When:</Text>
            <Text fontSize={16}>
              {`${moment().format(
                'dddd MMMM Do YYYY, h:mm a',
              )} - ${moment().add(1, 'hours').format('h:mm a')}`}
            </Text>

            <Text>Where:</Text>
            <Text fontSize={16}>
              Office Location, Phone Number, or Web Link with Password will be
              here. Depending on location picked.
            </Text>

            <Text>Additional Notes:</Text>
            <Text>
              If there are no additional notes this section won&apos;t appear.
              Otherwise it will be here.
            </Text>

            <Text>
              {emailState[APPOINTMENT_REMINDER_EMAIL_FAREWELL]
                ? emailState[APPOINTMENT_REMINDER_EMAIL_FAREWELL].split(
                    '\n',
                  ).map((text: string, index: number) => (
                    <Text key={index}>{formatText(text)}</Text>
                  ))
                : formatText(
                    getDefaultSettings(APPOINTMENT_REMINDER_EMAIL_FAREWELL),
                  )}
            </Text>
          </Segment>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SubText>
              {formatText(emailState[APPOINTMENT_REMINDER_EMAIL_COMPANY])}
            </SubText>

            {squareBrandImageURL && (
              <CTImage height={128} imageID={squareBrandImageURL} width={128} />
            )}

            <SubText>Don&apos;t like these emails? Unsubscribe. </SubText>

            <Divider hidden />

            <SubText>Powered by HTMLemail. </SubText>
          </div>

          <Divider hidden />
          <Divider />

          <Button
            content="Send Test Email to Yourself"
            loading={isSendingTestEmail}
            onClick={sendTestEmail}
            primary
          />
        </Card>
      </FormColumnArea>
    </Card>
  )
}
