import { authDelete } from 'lib/http-request'
import { Receipts } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, Dispatch, ReceiptState, IResponse } from 'interfaces'
import { Receipt } from '../interfaces'

const DELETE_RECEIPT = 'client-trackyr/receipts/DELETE_RECEIPT'
const DELETE_RECEIPT_SUCCESS = 'client-trackyr/receipts/DELETE_RECEIPT_SUCCESS'
const DELETE_RECEIPT_FAILURE = 'client-trackyr/receipts/DELETE_RECEIPT_FAILURE'

export const reducer = (state: ReceiptState, action: IAction) => {
  switch (action.type) {
    case DELETE_RECEIPT: {
      return {
        ...state,
        flags: {
          ...state.flags,
          deleting: true,
        },
      }
    }

    case DELETE_RECEIPT_SUCCESS: {
      let allIDs = state.allIDs
      const receipts = { ...state.receipts }

      allIDs = allIDs.filter((id) => id !== action.receiptNumber)
      delete receipts[action.receiptNumber]

      return {
        ...state,
        allIDs,
        receipts,
        totalReceipts: state.totalReceipts - 1,
        flags: {
          ...state.flags,
          deleting: false,
        },
      }
    }

    case DELETE_RECEIPT_FAILURE: {
      return {
        ...state,
        deleting: false,
      }
    }
    default:
      return state
  }
}

export const deleteReceipt = (receiptNumber: number): any => async (
  dispatch: Dispatch,
): Promise<IResponse<Receipt>> => {
  dispatch({ type: DELETE_RECEIPT, receiptNumber })

  return authDelete(Receipts.Delete(receiptNumber))
    .then((response) => {
      message.success('Receipt Deleted')
      dispatch(onDeleteSuccess(receiptNumber))
      return response
    })
    .catch((error) => {
      message.error('Failed to delete receipt')
      captureMessage(`deleteReceipt Failed. ${error}`, Severity.Error)
      dispatch(onDeleteFailure(error, receiptNumber))
      return error
    })
}

const onDeleteSuccess = (receiptNumber: number) => ({
  type: DELETE_RECEIPT_SUCCESS,
  receiptNumber,
})
const onDeleteFailure = (error: any, receiptNumber: number) => ({
  type: DELETE_RECEIPT_FAILURE,
  error,
  receiptNumber,
})
