import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Label, Text } from 'components/ui'
import { getClientTypes$ } from 'containers/settings'
import { IState, Treatment } from 'interfaces'
import { getClient, getClientFullName } from 'containers/clients'
import { toCamelCase } from './json-parsing-fix'

interface Props {
  value: string
}

export const TreatmentValue = ({ value }: Props) => {
  const clientTypes = useSelector(getClientTypes$)
  const treatment: Treatment = JSON.parse(value, toCamelCase)
  const client = useSelector((state: IState) =>
    getClient(state, treatment.clientID),
  )

  return (
    <>
      <Label>ID</Label>
      <Text>{treatment.id}</Text>

      <Label>Treatment Number</Label>
      <Text>{treatment.treatmentNumber}</Text>

      <Label>Client</Label>
      <Text>{getClientFullName(client)}</Text>

      <Label>Client Type</Label>
      <Text>
        {clientTypes.find((c) => c.id === treatment.clientTypeID)?.name}
      </Text>

      <Label>Start</Label>
      <Text>{moment(treatment.start).format('MMM D YYYY')}</Text>

      <Label>End</Label>
      <Text>{moment(treatment.end).format('MMM D YYYY')}</Text>

      <Label>Opening Message</Label>
      <Text>{treatment.treatmentOpenMessage}</Text>

      <Label>Closing Message</Label>
      <Text>{treatment.treatmentCloseMessage}</Text>
    </>
  )
}
