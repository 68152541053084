import React from 'react'
import { Message as SemanticMessage } from 'semantic-ui-react'
import styled from 'styled-components'

import { Font } from 'styles/font'

const M = styled(SemanticMessage)`
  &&&&& {
    display: flex;
    ${Font};

    color: ${(props) =>
      props.warning
        ? props.theme.MessageWarningColour
        : props.error
        ? props.theme.MessageErrorColour
        : props.theme.MessageInfoColour};

    background-color: ${(props) =>
      props.warning
        ? props.theme.MessageWarningBackgroundColour
        : props.error
        ? props.theme.MessageErrorBackgroundColour
        : props.theme.MessageInfoBackgroundColour};

    .header {
      color: ${(props) => props.theme.TextColour};
    }
  }
`

interface IMessage {
  content?: any
  error?: boolean
  header?: string | React.ReactNode
  icon?: string
  info?: boolean
  style?: any
  warning?: boolean
}

const Message = (props: IMessage) => <M {...props} />

export default Message
