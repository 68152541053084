import React from 'react'
import styled from 'styled-components'

import { Container, SubText } from 'components/ui'
import { ContactUsFooter } from 'containers/emails'
import { currentVersion } from 'config/config'

interface Props {
  authenticated: boolean
}

const AppFooterContainer = styled.div`
  grid-area: footer;
  background-color: ${(props) => props.theme.BackgroundSlightOffsetColour};
  z-index: 9;
  background-color: ${(props) => props.theme.BackgroundSlightOffsetColour};
  margin-top: 2em;
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 2;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;

  @media print {
    visibility: hidden;
  }
`

export const AppFooter = ({ authenticated }: Props) => {
  return (
    <AppFooterContainer>
      <Container>
        <Footer>
          <div>
            <SubText>{`© Copyright ${new Date().getFullYear()} Client Trackyr`}</SubText>
            <sub>{`v: ${currentVersion}-beta`}</sub>
          </div>

          <div>
            <ContactUsFooter authenticated={authenticated} />
          </div>
        </Footer>
      </Container>
    </AppFooterContainer>
  )
}
