import { authGet } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { SettingKey, IAction } from 'interfaces'
import { parseFromAPI } from 'constants/settings'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`
const FETCH_SETTING = settingsAction('FETCH_SETTING')
const FETCH_SETTING_SUCCESS = settingsAction('FETCH_SETTING_SUCCESS')
const FETCH_SETTING_FAILURE = settingsAction('FETCH_SETTING_FAILURE')

export const defaultState = {
  fetchingSetting: false,
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_SETTING:
      return {
        ...state,
        flags: {
          [`fetchingSetting${action.settingKey}`]: true,
        },
      }

    case FETCH_SETTING_SUCCESS: {
      const setting = action.response

      return {
        ...state,
        [setting.setting]: parseFromAPI(setting),
        flags: {
          [`fetchingSetting${action.settingKey}`]: false,
        },
      }
    }

    case FETCH_SETTING_FAILURE:
      return {
        ...state,
        flags: {
          [`fetchingSetting${action.settingKey}`]: false,
        },
      }

    default:
      return state
  }
}

export const fetchSetting = (settingKey: SettingKey): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_SETTING, settingKey })

  return authGet(Settings.GetSetting(settingKey))
    .then((response) => {
      dispatch(onFetchSuccess(response.data, settingKey))

      return response
    })
    .catch((error) => {
      captureMessage(`fetchBodyDiagrams Failed. ${error}`, Severity.Error)

      dispatch(onFetchFailure(error, settingKey))

      return error
    })
}

const onFetchSuccess = (response: any, settingKey: SettingKey) => ({
  type: FETCH_SETTING_SUCCESS,
  response,
  settingKey,
})

const onFetchFailure = (error: any, settingKey: SettingKey) => ({
  type: FETCH_SETTING_FAILURE,
  error,
  settingKey,
})
