import { authPost } from 'lib/http-request'
import { Taxes } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, Dispatch, IResponse, TaxState } from 'interfaces'
import { Tax } from '../interfaces'

const POST_TAX = 'client-trackyr/taxes/POST_TAX'
const POST_TAX_SUCCESS = 'client-trackyr/taxes/POST_TAX_SUCCESS'
const POST_TAX_FAILURE = 'client-trackyr/taxes/POST_TAX_FAILURE'

export const reducer = (state: TaxState, action: IAction) => {
  switch (action.type) {
    case POST_TAX: {
      return {
        ...state,
        flags: {
          ...state.flags,
          posting: true,
        },
      }
    }

    case POST_TAX_SUCCESS: {
      const data: Tax = action.data
      let allIDs = state.allIDs
      const taxes = state.taxes

      allIDs.push(data.id)
      allIDs = [...new Set(allIDs)]
      taxes[data.id] = data

      return {
        ...state,
        allIDs,
        taxes,
        totalReceipts: allIDs.length,
        flags: {
          ...state.flags,
          posting: false,
        },
      }
    }

    case POST_TAX_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          posting: false,
        },
      }
    }
    default:
      return state
  }
}

export const postTax = (tax: Tax): any => async (
  dispatch: Dispatch,
): Promise<IResponse<Tax>> => {
  dispatch({ type: POST_TAX, tax })

  return authPost(Taxes.Post, tax)
    .then((response) => {
      message.success('Tax Saved')
      dispatch(onPostSuccess(response.data))
      return response
    })
    .catch((error) => {
      message.error('Failed to save tax')
      captureMessage(`postTax Failed. ${error}`, Severity.Error)
      dispatch(onPostFailure(error, tax))
      return error
    })
}

const onPostSuccess = (data: any) => ({
  type: POST_TAX_SUCCESS,
  data,
})
const onPostFailure = (error: any, tax: Tax) => ({
  type: POST_TAX_FAILURE,
  error,
  tax,
})
