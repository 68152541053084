import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Card } from 'components/ui'
import { Forms } from 'containers/forms'
import {
	AppointmentStatus,
	getPreviousAppointmentFromCurrentDate,
} from 'containers/appointments'
import {
	fetchSoapByAppointment,
	getSoapsForSelectedClient,
} from 'containers/soap-notes'
import { getSoapTypesForClientType$ } from 'containers/settings'
import { emptyGuid } from 'lib/guid'
import { Dispatch, IState } from 'interfaces'

interface Props {
	clientID: string
	treatmentNumber: number
}

export const LastAppointmentSummary = (props: Props) => {
	const dispatch: Dispatch = useDispatch()

	const lastAppointment = useSelector(getPreviousAppointmentFromCurrentDate)
	const soaps = useSelector(getSoapsForSelectedClient)
	const soapTypes = useSelector(getSoapTypesForClientType$)
	const templates = useSelector((state: IState) => state.forms.templates)
	const shapesForAppointment = useSelector(
		(state: IState) => state.draw.shapes[lastAppointment.id],
	)

	const soapData = soaps[lastAppointment.id] || {}
	const soapTypeID = soapData.soapTypeID
	let soapForms: any

	if (soapTypeID === emptyGuid) {
		soapForms = soapTypes[0]
	} else {
		soapForms = soapTypes.find((st: any) => st.id === soapTypeID)
	}

	useEffect(() => {
		if (lastAppointment.id) {
			dispatch(
				fetchSoapByAppointment(
					props.clientID,
					lastAppointment.id,
					props.treatmentNumber,
				),
			)
		}
	}, [lastAppointment.id])

	if (!soapForms || !soapForms.forms || !soapForms.forms.length) {
		return null
	}

	if (shapesForAppointment) {
		soapData.shapesSummaryOverride = shapesForAppointment
	}

	const formTemplates = soapForms.forms.map((sf: any) => templates[sf.formID])

	const extra =
		lastAppointment.status === AppointmentStatus.Supervision
			? ' (Supervision)'
			: ''

	return (
		<Card
			title={`Last Appointment: ${moment(lastAppointment.start).format(
				'MMMM D YYYY',
			)} ${extra}`}
		>
			<Forms
				entityID={soapData && soapData.id}
				forms={formTemplates}
				formsDataOverride={soapData}
				onChange={() => {
				}}
				patchEndpoint=''
				readonly
			/>
		</Card>
	)
}
