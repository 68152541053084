import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { Card, Label, Form } from 'components/ui'
import { Input } from 'components/forms'
import { Storage } from 'constants/endpoints'
import { invalidateImageCache } from 'lib'
import { getSetting, saveSettings, onSettingChanged } from 'containers/settings'
import {
	ORG_NAME,
	ORG_NAME_WITH_ADDRESS,
	USER_BANNER_SQUARE,
	USER_BANNER_RECTANGLE,
} from 'constants/settings'
import ImageUpload from 'components/image/image-upload-ct'
import { FeatureToggle } from 'containers/features'
import { FileUpload, BetaUser } from 'constants/features'
import { Dispatch, IState } from 'interfaces'
import { IResponse, Error } from 'interfaces/fetch'

const BrandingContainer = styled(Form)`
	&&&&& {
		max-width: 45em;
	}
`

export const Branding = () => {
	const dispatch: Dispatch = useDispatch()

	const orgName = useSelector((state: IState) => getSetting(state, ORG_NAME))
	const orgNameWithAddress = useSelector((state: IState) =>
		getSetting(state, ORG_NAME_WITH_ADDRESS),
	)
	const squareBrandImageURL = useSelector((state: IState) =>
		getSetting(state, USER_BANNER_SQUARE),
	)
	const rectangleBrandImageURL = useSelector((state: IState) =>
		getSetting(state, USER_BANNER_RECTANGLE),
	)

	const onChange = useCallback(
		({ target }) => dispatch(onSettingChanged(target.value, target.name)),
		[dispatch],
	)

	const onUpload = useCallback(
		(bannerType, data) => {
			const link = data.blobStorageFileID
			invalidateImageCache(link)

			dispatch(onSettingChanged(link, bannerType))
			dispatch(
				saveSettings({
					setting: bannerType,
					value: link,
				}),
			)
		},
		[dispatch],
	)

	const saveSetting = useCallback(
		(setting, value) =>
			dispatch(
				saveSettings({
					setting,
					value,
				}),
			),
		[dispatch],
	)

	// TODO - https://github.com/DominicTobias/react-image-crop
	return (
		<div>
			<Card collapsible title="Branding">
				<BrandingContainer>
					<Input
						autoSave={{
							autosaveMode: 'onBlurOnly',
							entityID: 'branding',
							patch: () => saveSetting(ORG_NAME, orgName),
						}}
						label="Organization Name"
						name={ORG_NAME}
						onChange={onChange}
						value={orgName}
					/>
					<Input
						autoSave={{
							autosaveMode: 'onBlurOnly',
							entityID: 'branding',
							patch: () =>
								saveSetting(ORG_NAME_WITH_ADDRESS, orgNameWithAddress),
						}}
						label="Organization Name with Address"
						name={ORG_NAME_WITH_ADDRESS}
						onChange={onChange}
						placeholder="ex) 100-104 Street Name, City, Provice, Postal Code"
						value={orgNameWithAddress}
					/>

					<FeatureToggle features={[FileUpload, BetaUser]}>
						<Label>Square Brand Image</Label>
						<ImageUpload
							hideDetails
							imageSrc={squareBrandImageURL}
							onComplete={(response: IResponse<any>) =>
								onUpload(USER_BANNER_SQUARE, response.data)
							}
							onError={(err: Error) => console.error(err)}
							url={Storage.Branding('square')}
						/>
						<Label>Rectangle Brand Image</Label>
						<ImageUpload
							hideDetails
							imageSrc={rectangleBrandImageURL}
							onComplete={(response: IResponse<any>) =>
								onUpload(USER_BANNER_RECTANGLE, response.data)
							}
							onError={(err: Error) => console.error(err)}
							url={Storage.Branding('rectangle')}
						/>
					</FeatureToggle>
				</BrandingContainer>
			</Card>
		</div>
	)
}
