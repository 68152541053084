import React from 'react'
import { Card, ContainerLayout, H1 } from 'components/ui'
import { default as VerifyEmailMessage } from 'containers/auth/components/verify-email'

const VerifyEmail = () => (
  <ContainerLayout>
    <H1>Please Verify Your Email address</H1>
    <Card hideTitle style={{ width: 400, textAlign: 'center' }}>
      <VerifyEmailMessage />
    </Card>
  </ContainerLayout>
)

export default VerifyEmail
