import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { ReadonlyFormFont } from 'styles/font'
import { Print } from './interfaces'
import { Input, PrintableInputs } from '.'
import { IEvent } from '../../interfaces'

const DatePicker = ({
  name,
  value,
  disabled,
  format = 'YYYY-MM-DD',
  showTime = false,
  readonly = false,
  ...props
}: DatePickerProps) => {
  const [focused, setFocused] = useState<boolean>(false)
  const parsedValue = value?.split?.('T')?.[0] ?? ''
  const renderParsedValue = moment(parsedValue).isValid()
    ? moment(parsedValue).format('MMMM D YYYY')
    : ''
  const [viewValue, setViewValue] = useState<string>(renderParsedValue)
  const [type, setType] = useState<string>('text')

  const onFocus = (e) => {
    setFocused(true)
    props?.onFocus?.(e)
    setType(showTime ? 'datetime-local' : 'date')
    const val = moment(e.target.value).format('YYYY-MM-DD')
    setViewValue(val)
  }

  const onBlur = (e) => {
    setFocused(false)
    props?.onBlur?.(e)
    setType('text')
    setViewValue(renderParsedValue)

    props.onChange(e)
  }

  const onChange = (e) => {
    const val = moment(e.target.value).format('YYYY-MM-DD')
    setViewValue(val)
  }

  useEffect(() => {
    if (value && renderParsedValue && type === 'text') {
      setViewValue(renderParsedValue)
    }
  }, [value])

  return props?.printParams?.printingView ? (
    <PrintableInputs value={value} />
  ) : readonly ? (
    <ReadonlyFormFont>
      {value ? moment(parsedValue).format(format) : ''}
    </ReadonlyFormFont>
  ) : (
    <Input
      disabled={disabled}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={props.placeholder}
      style={
        focused
          ? {
              minWidth: '10em',
              minHeight: '2.5rem',
              ...props.style,
            }
          : props.style
      }
      type={type}
      value={viewValue}
    />
  )
}

interface DatePickerProps {
  defaultValue?: moment.Moment | Date
  disabled?: boolean
  format?: string
  label?: string
  name?: string
  onBlur?: Function
  onBlurOverride?: Function
  onChange: (e: IEvent) => void
  onFocus?: Function
  placeholder?: string
  printParams?: Print
  readonly?: boolean
  showTime?: any // { format: 'h:mm a', use12Hours: true }
  size?: string
  style?: any
  value?: any
}

export default DatePicker
