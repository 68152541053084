/*
 TODO - REORG

  - Primary, Secondary, Negative, Neutral Colours
    - w/ Disabled, Hover, Border
      - w/ Dark

  - Buttons 
    - Primary, Secondary (Button, diff from Secondary), Negative, Neutral
      - w/ Disabled, Hover, Border
        - w/ Dark

  - Text Colours
    - w/ Dark

  - ...
*/
export const AppointmentCreatedColour = '#619cf6'
export const AppointmentCancelledNoShowColour = 'rgb(232, 140, 140, 0.6)'
export const AppointmentCancelledColour = 'rgb(206, 206, 206, 0.6)'
export const AppointmentCompletedColour = '#6ED579'
export const AppointmentSupervisionColour = '#9261f6'

export const DangerColour = '#db2828' // '#fe6c84'
export const DangerColourHover = '#a71f1f' // '#fe6c84'
export const WarningColour = '#573a08'

export const PrimaryColour = '#2185d0' // DEFAULT - '#1890FF' // '#2185d0' //'#619cf6' // '#84b5ff' // '#9bc3ff'
export const PrimaryColourHover = '#0F61AD'
export const PrimaryColourBorderColour = '#d9d9d9'
export const PrimaryDisabled = '#8492a8' // #9cadc6

export const PrimaryColourBorderColourDark = '#333'

export const SecondaryColour = ''
export const TertiaryColour = ''
export const MutedBorderColour = '#6a6a6a'
export const MutedBorderColourDark = '#5d5d5d'

export const InputBorderColour = 'rgba(34, 36, 38, 0.15)'
export const InputBorderColourFocused = '#85B7D9'
export const InputBorderColourDark = 'rgb(177, 188, 199, 0.35)'
export const InputBorderColourFocusedDark = '#668DA8'

export const NeutralColourOne = '#e0e1e2' //'#c2c2c2'
export const NeutralColourOneHover = '#c2c2c2'
export const NeutralColourOneDark = '#3d3d3d' //'#c2c2c2'
export const NeutralColourOneHoverDark = '#515151'
export const NeutralColourTwo = ''
export const NeutralColourTwoDark = ''
export const NeutralColourThree = '#f2f2f2'
export const NeutralColourThreeDark = '#212121'

export const SecondaryButton = '#262626' // '#4f4f4f'
export const SecondaryButtonHover = '#3f3f3f'
export const SecondaryButtonHoverDark = '#3f3f3f'
export const NeutralButtonDark = '#393939' // 'rgb(39, 39, 39)' //'#c2c2c2'
export const SecondaryButtonDark = '#bebebe' //'#c2c2c2'
export const NeutralButtonActive = '#c0c1c2'
export const NeutralButtonActiveDark = '#3f3e3d'
export const NeutralButtonText = 'rgba(0, 0, 0, 0.6)'

export const BackgroundColour = '#fff'
export const BackgroundColourHover = '#ddd'
export const BackgroundColourOne = '#505050'
export const BackgroundSlightOffsetColour = '#f5f8ff'
export const ButtonBackgroundColour = 'rgba(21, 21, 26, 0.05)'

export const BackgroundColourDark = '#202227' //'#151616' //'#0b0b0b' //'#141414' // #151616
export const BackgroundColourHoverDark = '#444'
export const BackgroundColourOneDark = '#050505'
export const BackgroundSlightOffsetColourDark = '#13161c'
export const ButtonBackgroundColourDark = 'rgba(21, 21, 26, 0.05)'

export const ShadowColour = 'rgba(255, 255, 255, 0.3)'
export const ShadowColourDark = 'rgba(0, 0, 0, 0.3)'
export const DarkerShadowColour = 'hsla(0, 0%, 0%, 0.2)'

export const ShadowColourSolid = '#000'
export const ShadowColourSolidDark = '#fff'

export const CardBorderColour = '#e8e8e8'
export const CardShadowColour = '#ddd'

export const CardBorderColourDark = '#333'
export const CardShadowColourDark = '#444'

export const LinkColour = '#4183c4'
export const LinkAsTextColour = '#222'
export const LinkAsTextColourDark = '#ddd'
export const LinkColourHighlight = '#3972aa'

export const NewCalendarEvent = '#2ebddd'
export const NewCalendarEventBorder = '#2ebddd'
export const CalendarEvent = PrimaryColour //'#3788d8'
export const CalendarEventBorder = '#3788d8'
export const CalendarUneditableEvent = '#7c7e7f'
export const CalendarUneditableEventBorder = '#585859'

export const TextColour = 'rgba(0, 0, 0, 0.75)'
export const SecondaryTextColour = 'rgba(0, 0, 0, 0.6)'
export const SecondaryTextColourDark = 'rgba(200, 200, 200, 0.75)'
export const LowContrastTextColour = 'rgba(25, 25, 25, 0.5)'
export const LowContrastTextColourDark = 'rgba(200, 200, 200, 0.5)'
export const TextColourInverse = '#dedede'
export const TextColourInverseDark = 'rgba(0, 0, 0, 0.85)'

export const LineColour = TextColourInverse
export const LineColourDark = LowContrastTextColourDark

export const LightTextColour = '#f9f9f9'
export const ErrorTextColour = '#de2323'

export const TextColourDark = 'rgba(255, 255, 255, 0.75)'
export const TextColourLight = '#fff'

export const MutedIconColour = '#919191'
export const DarkIconColour = 'rgba(0, 0, 0, 0.65)'
export const DarkIconColourDark = 'rgba(256, 256, 256, 0.85)'

export const ListItemActiveColour = 'rgba(122, 92, 98, 0.15)'
export const ListItemHoverColour = 'rgba(55, 29, 34, 0.15)'
export const ListItemNewColour = 'rgba(92, 215, 26, 0.25)'
export const ListItemNewActiveColour = 'rgba(101, 237, 28, 0.15)'
export const ListItemNewColourDark = 'rgba(92, 215, 26, 0.25)'
export const ListItemNewActiveColourDark = 'rgba(101, 237, 28, 0.15)'

export const ListItemActiveColourDark = 'rgba(133, 163, 157, 0.15)'
export const ListItemHoverColourDark = 'rgba(200, 226, 221, 0.15)'

export const ListItemInvalid = 'rgba(223, 223, 223, 0.25)'
export const ListItemInvalidDark = 'rgba(38, 38, 38, 0.25)'

export const MessageInfoColour = '#276f86'
export const MessageWarningColour = '#573a08'
export const MessageErrorColour = '#9f3a38'
export const MessageInfoColourDark = '#b8e1ee'
export const MessageWarningColourDark = '#e7d3b0'
export const MessageErrorColourDark = '#eab7b7'

export const MessageInfoBackgroundColour = '#f8ffff'
export const MessageWarningBackgroundColour = '#fffaf3'
export const MessageErrorBackgroundColour = '#fff3f3'
export const MessageInfoBackgroundColourDark = '#112e39'
export const MessageWarningBackgroundColourDark = '#301f04'
export const MessageErrorBackgroundColourDark = '#521d1d'

export const SuccessColour = '#1BD02D'
export const FailureColour = '#CB3F27'

export const ProgressIndicatorColour = '#2185d0'
export const ProgressIndicatorNearlyFullWarningColour = '#f73232'
export const ProgressIndicatorNearlyFullColour = '#1BD02D'

export const NavActiveColour = '#e6f7ff'
export const NavActiveColourDark = '#161718'
export const AvatarBackgroundColour = '#ececec'
export const AvatarBackgroundColourDark = '#212121'

export const WeekDaySelectedColour = 'rgba(24, 144, 255, 0.3)'
export const WeekDayWeekendColour = 'rgba(241, 104, 104, 0.75)'
export const WeekDayDayColour = 'rgba(134, 134, 134, 0.25)'
export const WeekDayHoverColour = 'rgba(104, 175, 241, 0.75)'
export const DisabledWeekDayWeekendColour = 'rgba(241, 104, 104, 0.08)'
export const DisabledWeekDayColour = 'rgba(134, 134, 134, 0.05)'

export const WeekDaySelectedColourDark = 'rgba(24, 144, 255, 0.90)'
export const WeekDayWeekendColourDark = 'rgba(241, 104, 104, 0.90)'
export const WeekDayDayColourDark = 'rgba(134, 134, 134, 0.55)'
export const WeekDayHoverColourDark = 'rgba(104, 175, 241, 0.75)'
export const DisabledWeekDayWeekendColourDark = 'rgba(241, 104, 104, 0.25)'
export const DisabledWeekDayColourDark = 'rgba(134, 134, 134, 0.25)'

export const SignatureBackground = '#fff'
export const SignatureBackgroundDark = '#afafaf'

export const TabBorderColour = 'rgba(34,36,38,.15)'
export const TabBorderColourDark = '#323232'
export const TabBorderActiveColour = '#000'
export const TabBorderActiveColourDark = '#fff'
export const TabSecondaryActive = 'rgba(122, 92, 98, 0.15)'
export const TabSecondaryActiveDark = 'rgba(133, 163, 157, 0.15)'
export const TabSecondaryHover = 'rgba(133, 163, 157, 0.15)'
export const TabSecondaryHoverDark = 'rgba(200, 226, 221, 0.15)'

export const ReceiptTableUnpaidRowColour = 'rgba(241, 104, 104, 0.15)'

const colours = {
  PrimaryColour,
  PrimaryColourHover,
  PrimaryColourBorderColour,
  PrimaryDisabled,
  SecondaryColour,
  TertiaryColour,
  MutedBorderColour,
  MutedBorderColourDark,
  WarningColour,
  DangerColour,
  DangerColourHover,
  SecondaryButton,
  SecondaryButtonHover,
  SecondaryButtonHoverDark,
  SecondaryButtonDark,
  NeutralColourOne,
  NeutralColourOneHover,
  NeutralColourThree,
  NeutralColourThreeDark,
  NeutralColourOneDark,
  NeutralColourOneHoverDark,
  NeutralColourTwo,
  NeutralColourTwoDark,
  PrimaryColourBorderColourDark,

  BackgroundColour,
  BackgroundColourHover,
  BackgroundColourOne,
  BackgroundSlightOffsetColour,
  ButtonBackgroundColour,

  BackgroundColourDark,
  BackgroundColourHoverDark,
  BackgroundColourOneDark,
  BackgroundSlightOffsetColourDark,
  ButtonBackgroundColourDark,
  NeutralButtonDark,
  NeutralButtonActive,
  NeutralButtonActiveDark,
  NeutralButtonText,

  InputBorderColour,
  InputBorderColourDark,
  InputBorderColourFocused,
  InputBorderColourFocusedDark,

  LineColour,
  LineColourDark,

  ShadowColour,
  ShadowColourDark,
  DarkerShadowColour,

  ShadowColourSolid,
  ShadowColourSolidDark,

  CardBorderColour,
  CardShadowColour,
  CardBorderColourDark,
  CardShadowColourDark,

  LinkColour,
  LinkColourHighlight,
  LinkAsTextColour,
  LinkAsTextColourDark,

  TextColour,
  SecondaryTextColour,
  SecondaryTextColourDark,
  LowContrastTextColour,
  LowContrastTextColourDark,
  LightTextColour,
  ErrorTextColour,
  TextColourLight,
  TextColourDark,
  TextColourInverse,
  TextColourInverseDark,

  NewCalendarEvent,
  NewCalendarEventBorder,
  CalendarEvent,
  CalendarEventBorder,
  CalendarUneditableEvent,
  CalendarUneditableEventBorder,
  DarkIconColour,
  MutedIconColour,
  DarkIconColourDark,

  MessageInfoColour,
  MessageWarningColour,
  MessageErrorColour,
  MessageInfoColourDark,
  MessageWarningColourDark,
  MessageErrorColourDark,
  MessageInfoBackgroundColour,
  MessageWarningBackgroundColour,
  MessageErrorBackgroundColour,
  MessageInfoBackgroundColourDark,
  MessageWarningBackgroundColourDark,
  MessageErrorBackgroundColourDark,

  SuccessColour,
  FailureColour,

  ProgressIndicatorColour,
  ProgressIndicatorNearlyFullWarningColour,
  ProgressIndicatorNearlyFullColour,

  NavActiveColour,
  NavActiveColourDark,
  AvatarBackgroundColour,
  AvatarBackgroundColourDark,
  WeekDaySelectedColour,
  WeekDayWeekendColour,
  WeekDayDayColour,
  WeekDayHoverColour,
  DisabledWeekDayWeekendColour,
  DisabledWeekDayColour,

  ListItemActiveColour,
  ListItemHoverColour,
  ListItemNewColour,
  ListItemNewColourDark,
  ListItemNewActiveColour,
  ListItemNewActiveColourDark,
  ListItemActiveColourDark,
  ListItemHoverColourDark,
  ListItemInvalid,
  ListItemInvalidDark,

  SignatureBackground,
  SignatureBackgroundDark,

  TabBorderColour,
  TabBorderColourDark,
  TabBorderActiveColour,
  TabBorderActiveColourDark,
  TabSecondaryActive,
  TabSecondaryActiveDark,
  TabSecondaryHover,
  TabSecondaryHoverDark,

  WeekDaySelectedColourDark,
  WeekDayWeekendColourDark,
  WeekDayDayColourDark,
  WeekDayHoverColourDark,
  DisabledWeekDayWeekendColourDark,
  DisabledWeekDayColourDark,

  AppointmentCompletedColour,

  ReceiptTableUnpaidRowColour,
}

export default colours
