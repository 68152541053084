import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authGet } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'
import { PublicIntakeState, IAction } from 'interfaces'

const action = (action: string) => `client-trackyr/public-intake/${action}`
const FETCH_PUBLIC_INTAKE = action('FETCH_PUBLIC_INTAKE')
const FETCH_PUBLIC_INTAKE_SUCCESS = action('FETCH_PUBLIC_INTAKE_SUCCESS')
const FETCH_PUBLIC_INTAKE_FAILURE = action('FETCH_PUBLIC_INTAKE_FAILURE')

export const defaultState = {} as PublicIntakeState

export const reducer = (
  state: PublicIntakeState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case FETCH_PUBLIC_INTAKE:
      return {
        ...state,
        fetchingForms: true,
      }

    case FETCH_PUBLIC_INTAKE_SUCCESS:
      return {
        ...state,
        fetchingForms: false,
        [action.clientID]: {
          ...state[action.clientID],
          // totalCount: action.response.totalCount,
          // publicIntakeForms: action.response.items.map((item) => {
          //   const { userID, ...rest } = item
          //   return rest
          // }),
          // fetched: true,
        },
      }

    case FETCH_PUBLIC_INTAKE_FAILURE:
      return {
        ...state,
        fetchingForms: false,
        fetchingFormsError: {
          error: true,
          message: 'Failed to load all forms',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
const fetchPublicIntake = (id: string): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_PUBLIC_INTAKE, id })

  return authGet(PublicIntake.Get(id))
    .then((response) => {
      dispatch(fetchSuccess(response.data, response.data.clientID))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(`fetchPublicIntake Get Failed: ${error}`, Severity.Error)

      throw error
    })
}

const fetchSuccess = (response: any, clientID: string) => ({
  type: FETCH_PUBLIC_INTAKE_SUCCESS,
  response,
  clientID,
})

const fetchFailure = (error: any) => ({
  type: FETCH_PUBLIC_INTAKE_FAILURE,
  error,
})

export { fetchPublicIntake }
