import React from 'react'

import { Button, Divider, Form, HeaderWithIcon, Text } from 'components/ui'
import { Dropdown } from 'components/forms'
import { IEvent } from 'interfaces'
import { SetupData } from './interfaces'
import { PracticeTypes } from 'constants/practice-types'

interface Props {
  data: SetupData
  onChange: (e: IEvent) => void
  onNextStep: (step: number) => void
}

export const PracticeTypeStepTwo = (props: Props) => {
  const stepDisabled = !props.data.practiceType
  const nextStep = () => props.onNextStep(2)
  const prevStep = () => props.onNextStep(0)

  return (
    <div>
      <HeaderWithIcon name="clipboard">Type of Practice</HeaderWithIcon>

      <Form>
        <Text>
          The type of practice determines your starting forms, and a few
          settings. This cannot be changed once you finish setting up.
        </Text>
        <Dropdown
          label=""
          name="practiceType"
          onChange={props.onChange}
          options={[
            {
              key: PracticeTypes.massage,
              text: 'Massage',
              value: PracticeTypes.massage,
              id: PracticeTypes.massage,
            },
            {
              key: PracticeTypes.psychotherapy,
              text: 'Psychotherapy / Counseling',
              value: PracticeTypes.psychotherapy,
              id: PracticeTypes.psychotherapy,
            },
          ]}
          value={props.data.practiceType}
        />
      </Form>

      <Divider hidden />

      <Button content="Prev" onClick={prevStep} />
      <Button
        content="Next"
        disabled={stepDisabled}
        onClick={nextStep}
        primary
      />
    </div>
  )
}
