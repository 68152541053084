import React, { useState } from 'react'
import { Button as SemanticButton } from 'semantic-ui-react'
import styled from 'styled-components'

import { Font } from 'styles'
import { IButton } from 'components/forms/interfaces'

interface ButtonGroupProps {
  active?: number
  activeTab?: number
  children?: any
  defaultActive?: number
  selectable?: boolean
  setActiveTab?: Function
}

interface IStyleButton {
  styleDisable?: boolean
}

const B = styled(SemanticButton)<IStyleButton>`
  &&&&& {
    ${Font};
    background-color: ${(props) =>
      props.active
        ? props.theme.NeutralButtonActive
        : props.theme.NeutralButton};
    color: ${(props) => props.theme.NeutralButtonText};

    ${(props) =>
      props.primary && `background-color: ${props.theme.PrimaryColour}`};
    ${(props) =>
      props.secondary && `background-color: ${props.theme.SecondaryButton}`};
    ${(props) =>
      props.negative && `background-color: ${props.theme.DangerColour}`};
    ${(props) => props.colour && `background-color: ${props.colour}`};

    ${(props) => props.primary && `color: ${props.theme.LightTextColour}`};
    ${(props) => props.secondary && `color: ${props.theme.TextColourInverse}`};
    ${(props) => props.negative && `color: ${props.theme.LightTextColour}`};
    ${(props) => props.colour && `color: ${props.colour}`};

    border-radius: ${(props) => (props.circular ? '50%' : '0.35em')};

    ${(props) => props.styleDisable && 'opacity: 0.45'};

    :hover {
      background-color: ${(props) =>
        !props.styleDisable && props.theme.NeutralColourOneHover};
      ${(props) =>
        props.primary &&
        !props.styleDisable &&
        `background-color: ${props.theme.PrimaryColourHover}`};
      ${(props) =>
        props.secondary &&
        !props.styleDisable &&
        `background-color: ${props.theme.SecondaryButtonHover}`};
      ${(props) =>
        props.negative &&
        !props.styleDisable &&
        `background-color: ${props.theme.DangerColourHover}`};
    }
  }
`

const Button = ({ disabled, onClick, onClickDisabled, ...props }: IButton) => (
  <B
    {...props}
    onClick={(...e) => (!disabled ? onClick(e) : onClickDisabled(...e))}
    styleDisable={disabled}
  />
)

Button.defaultProps = {
  disabled: false,
  onClick: () => {
    console.warn('ON BUTTON CLICK NOT SET')
  },
  onClickDisabled: () => {},
}

export default Button

const ButtonGroup = ({
  children,
  selectable,
  active,
  defaultActive,
  activeTab,
  setActiveTab,
}: ButtonGroupProps) => {
  const [selected, setSelected] = useState(-1)

  if (!Array.isArray(children) || children.length === 1) {
    return children
  }

  if (selectable) {
    return children.map((child, index) => {
      const onClick = (...e) => {
        !active && setSelected(index)
        setActiveTab && setActiveTab(index)
        child.props.onClick && child.props.onClick(...e)
      }

      const props = {
        key: index,
        onClick,
        active: activeTab
          ? activeTab === index
          : selected === index || (selected === -1 && index === defaultActive),
      }

      if (index === 0) {
        return React.cloneElement(child, {
          className: 'left-button',
          ...props,
        })
      } else if (index === children.length - 1) {
        return React.cloneElement(child, {
          className: 'right-button',
          ...props,
        })
      } else {
        return React.cloneElement(child, {
          className: 'centre-button',
          ...props,
        })
      }
    })
  }

  return children.map((child, index) => {
    if (index === 0) {
      return React.cloneElement(child, { className: 'left-button' })
    } else if (index === children.length - 1) {
      return React.cloneElement(child, { className: 'right-button' })
    } else {
      return React.cloneElement(child, { className: 'centre-button' })
    }
  })
}

ButtonGroup.propTypes = {}

export { ButtonGroup }
