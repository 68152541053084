import React from 'react'
import { useSelector } from 'react-redux'

import { Message, Text, Button } from 'components/ui'
import { IState } from 'interfaces'

interface Props {
  send: (ignoreSubscription?: boolean) => any
}

export const SubscriptionError = ({ send }: Props) => {
  const error = useSelector(
    (state: IState) => state.publicIntake.sendingPublicIntakeEmailError,
  )
  if (!error || !error.hasError) {
    return null
  }

  return (
    <Message
      content={
        <>
          <Text>
            This client has unsubscribed from these types of emails. You can
            still send these emails if you wish though.
          </Text>
          <Button content="Send Anyway" onClick={() => send(true)} />
        </>
      }
      header="This client has unsubscribed to these emails"
      icon="warning"
      warning
    />
  )
}
