import React, { ReactInstance } from 'react'
import { connect } from 'react-redux'

import SaveCancelButtons from 'components/form-footer-buttons/save-cancel-buttons'
import { Forms } from 'containers/forms'
import { FETCHING } from 'components/forms'
import { getSeletectedTreatment$ } from 'containers/treatment'
import { getClientTypes$ } from 'containers/settings'
import { SpinnerInline } from 'components/loaders'
import { Message } from 'components/ui'
import { Client } from 'constants/endpoints'
import { BASIC_FALL_BACK_FORM_ID } from 'constants/form'
import { onChange, onSetEdit } from 'containers/clients/client-info/redux/ducks'

import { validateForm } from '../'
import { IState, Treatment, IClient } from 'interfaces'
import { getSelectedClientID$, getSelectedClient$ } from 'containers/clients'
import { onSaveClient } from '../redux/save-client'
import { Forms_T } from 'containers/settings/form-types/new-interfaces'
import { PrePrintModal } from 'containers/print'

class Info extends React.Component<IInfo> {
	componentRef: ReactInstance

	public static defaultProps: Partial<IInfo> = {
		readonly: false,
		selectedTreatment: {} as Treatment,
	}

	state: any = {
		formTemplates: [],
		cancelModalOpen: false,
	}

	componentDidMount() {
		this.getForms()

		if (this.props.clientDetails.completed) {
			this.props.setEditIfCompleted()
		}
	}

	componentDidUpdate(prevProps: IInfo) {
		if (
			(this.props.templates &&
				prevProps.selectedTreatment.clientTypeID !==
				this.props.selectedTreatment.clientTypeID) ||
			prevProps.selectedTreatment.clientTypeID !==
			this.props.selectedTreatment.clientTypeID ||
			prevProps.clientTypes.length !== this.props.clientTypes.length ||
			prevProps.selectedTreatment.treatmentNumber !==
			this.props.selectedTreatment.treatmentNumber
		) {
			this.getForms()
		}

		if (
			prevProps.clientDetails.completed !==
			this.props.clientDetails.completed &&
			this.props.clientDetails.completed
		) {
			this.props.setEditIfCompleted()
		}
	}

	getForms = () => {
		const formTemplates = this.props.forms
			.map((sf: Forms_T) => ({
				...this.props.templates[sf.formID],
				order: sf.order,
			}))
			.filter((sf: any) => sf && sf.id !== BASIC_FALL_BACK_FORM_ID)

		// TODO - on client type creation... create
		if (this.props.templates) {
			const searchForms = JSON.stringify(formTemplates).toLowerCase()
			if (
				// clientType.selectedType !== 'family' &&
				!searchForms.includes('firstname') ||
				!searchForms.includes('lastname')
			) {
				formTemplates.unshift(this.props.templates[BASIC_FALL_BACK_FORM_ID])
			}
		}

		this.setState(() => ({ formTemplates }))
	}

	onSaveClient = () =>
		this.props.onSaveClient(
			this.props.clientDetails,
			this.props.selectedTreatment.treatmentNumber,
			this.props.selectedTreatment.clientTypeID,
		)

	render() {
		const { clientID, clientDetails, onChange } = this.props

		return (
			<>
				{!this.props.clientDetailsError && (
					<>
						<SpinnerInline
							spinning={
								this.props.fetchingForm || !this.state.formTemplates.length
							}
							tip='Fetching Client Details...'
						>
							<Forms
								entityID={clientID}
								forms={this.state.formTemplates}
								formsDataOverride={clientDetails}
								onChange={onChange}
								patchEndpoint={Client.Patch}
								readonly={this.props.readonly}
								validateFormOverride={this.props.validateFormOverride}
							/>

							<SaveCancelButtons
								extraButtons={
									<PrePrintModal
										data={clientDetails}
										fileJson={this.state.formTemplates}
									/>
								}
								loading={
									this.props.fetchingForm ||
									this.props.autoSavingStatus === FETCHING
								}
								noCancel
								onSave={this.onSaveClient}
								readonly={this.props.readonly}
							/>
						</SpinnerInline>
					</>
				)}
				{this.props.clientDetailsError && (
					<Message content="Couldn't load Client Data" error icon='warning' />
				)}
			</>
		)
	}
}

interface IInfo {
	autoSavingStatus: string
	clientDetails: any
	clientDetailsError: string
	clientID: string
	clientTypes: Array<any>
	fetchingForm: boolean
	forms: Forms_T[]
	onChange: Function
	onSaveClient: (
		client: IClient,
		selectedTreatmentNumber: number,
		clientType: string,
	) => Promise<any>
	readonly: boolean
	selectedTreatment: Treatment
	setEditIfCompleted: Function
	templates: any
	validateFormOverride: Function
}

const mapStateToProps = (state: IState) => ({
	clientDetails: getSelectedClient$(state),
	clientID: getSelectedClientID$(state),
	loading: state.clients.fetchingClient,
	templates: state.forms.templates,
	fetchingForm: state.forms.fetchingForms,
	changesMade: state.clients.changesMade,
	clientDetailsError: state.clients.clientDetailsError,
	readonly:
		!state.clients.flags.clientEditing || getSeletectedTreatment$(state).closed,
	selectedTreatment: getSeletectedTreatment$(state),
	clientTypes: getClientTypes$(state),
	autoSavingStatus: state.forms.autoSavingStatus,
})

const mapDispatchToProps = (dispatch: any) => ({
	validateFormOverride: (formName: string, name: string, validation: any) =>
		dispatch(validateForm(formName, name, validation)),
	setEditIfCompleted: () => dispatch(onSetEdit('client', false)),
	onSaveClient: (
		client: IClient,
		selectedTreatmentNumber: number,
		clientType: string,
	) => dispatch(onSaveClient(client, selectedTreatmentNumber, clientType)),
	onChange: (formID: string, name: string, value: string) =>
		dispatch(onChange(formID, name, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Info)
