import React from 'react'

import { Dropdown as D, Label, FormField } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps } from 'components/forms/interfaces'

interface IDropDown {
	clearable?: boolean
	defaultValue?: string
	loading?: boolean
	multiple?: boolean
	options: Array<any>
	search?: boolean
	selection?: boolean
}

const DropdownForm = ({
												autoFocus,
												autoSave,
												clearable,
												defaultValue,
												disabled,
												label,
												loading,
												multiple,
												name,
												onBlur,
												onChange,
												onValidate,
												options,
												placeholder,
												printParams,
												readonly,
												search = true,
												selection = true,
												style,
												validation,
												validationRules,
												value,
											}: InputProps & IDropDown) => (
	<FormField
		help={validation?.msg}
		required={!readonly && validationRules?.required}
		style={style}
		validateStatus={validation?.type}
		{...validation}
	>
		<Label>{label}</Label>

		<AutoSaveWrapper
			afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
			autosaveDisabled={autoSave.autosaveDisabled}
			debounceTime={500}
			endpoint={autoSave.patchEndpoint}
			entityID={autoSave.entityID}
			formType={autoSave.patchType}
			indicatorOverride={autoSave.indicatorOverride}
			patch={autoSave.patch}
		>
			<D
				autoFocus={autoFocus}
				clearable={clearable}
				defaultValue={defaultValue}
				disabled={disabled}
				loading={loading}
				multiple={multiple}
				name={name}
				onBlur={(e: Event) => {
					onValidate && onValidate(validationRules, label, name, value)
					onBlur && onBlur(e)
				}}
				onChange={onChange}
				options={options}
				placeholder={placeholder || label}
				printParams={printParams}
				readonly={readonly}
				search={search}
				// style={style}
				selection={selection}
				value={value}
			/>
		</AutoSaveWrapper>
	</FormField>
)

DropdownForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
	search: true,
	selection: true,
}

export const Dropdown = React.memo(DropdownForm)
