import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Button, Header, Modal, PDFView } from 'components/ui'
import { IResponse, IState } from 'interfaces'
import { Spinner } from 'components/loaders'
import { authPost } from 'lib/http-request'
import { PDF } from 'constants/endpoints'
import { getSetting } from 'containers/settings'
import { USER_BANNER_SQUARE } from 'constants/settings'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface Props {
  onClose: () => void
  open: boolean
  invoiceTitle?: string
  invoiceHeaders?: string
  invoiceType?: string
  invoiceTherapistLabel?: string
  invoiceFooter?: string
  invoiceFooterTwo?: string
  invoiceColour?: string
}

export const ReceiptPreviewModal = (props: Props) => {
  const [fetchingPDF, setfetchingPDF] = useState(false)
  const [pdf, setPDF] = useState('')

  const squareBrandImageURL = useSelector((state: IState) =>
    getSetting(state, USER_BANNER_SQUARE),
  )

  const { open } = props
  useEffect(() => {
    if (open) {
      fetchReceipt()
    }
  }, [open])

  const fetchReceipt = async () => {
    try {
      setfetchingPDF(true)
      const response: IResponse<any> = await authPost(PDF.GeneratePreview, {
        headerText: props.invoiceTitle,
        invoiceTypeText: props.invoiceType,
        invoiceLabel: props.invoiceTherapistLabel,
        headerImage: squareBrandImageURL,
        headerDetails: props.invoiceHeaders,
        billingName: 'John Smith',
        billingPhone: '555-555-5555',
        billingEmail: 'john.smith@emailaddress.com',
        invoiceNumber: '12345',
        invoiceDate: 'Jan 21 2021',
        paymentDate: 'Jan 25 2021',
        totalAmountDue: '85',
        serviceRows: '1 Hour Treatment:&:Session 13:&:85',
        taxRows: '',
        paymentInfo: 'Payment on Jan 25 2021, via Credit Card',
        notes:
          'These are some example notes you can write when you create a receipt/invoice',
        footerLabel: props.invoiceFooter,
        footer: props.invoiceFooterTwo,
        tableHeaderColour: props.invoiceColour,
        paid: true,
      })

      setPDF(response.data)

      setfetchingPDF(false)
    } catch (ex) {
      setfetchingPDF(false)
    }
  }

  if (!open) {
    return null
  }

  const close = () => {
    setPDF('')
    props.onClose()
  }

  let scale = window.innerWidth / 675

  if (scale > 1.25) {
    scale = 1.25
  }

  return (
    <>
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        onClose={close}
        open={props.open}
      >
        <Header icon="print">Receipt</Header>

        <Modal.Content>
          <Spinner size="large" spinning={fetchingPDF}>
            {pdf && <PDFView filePathUrl={pdf} firstPage scale={scale} />}
          </Spinner>
        </Modal.Content>

        <Modal.Actions>
          <ButtonContainer>
            <Button
              content="Close"
              icon="arrow alternate circle left"
              onClick={close}
              secondary
            />
          </ButtonContainer>
        </Modal.Actions>
      </Modal>
    </>
  )
}
