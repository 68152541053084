import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ContainerLayout, Card } from 'components/ui'
import { fetchAllFiles, getAllFiles, FileListPreview } from 'containers/files'
import { IQueryParams, IResponse, GetAllResponse } from 'interfaces/fetch'
import { setNavBarText } from 'components/nav'
import { IState, IFile, Dispatch } from 'interfaces'
import { useDebounce } from 'lib'

const FILES_PAGE_SIZE = 10

const FilesView = () => {
	const dispatch: Dispatch = useDispatch()
	const allFiles = useSelector(getAllFiles)
	const totalItems = useSelector((state: IState) => state.files.meta.totalFiles)

	const [files, setFiles] = useState(allFiles)
	const [page, setPage] = useState(1)
	const [searchTerm, setSearchTerm] = useState('')

	const onSearch = (st: string) => {
		setSearchTerm(st)
		setPage(1)
		if (st === '') {
			fetchAll('')
		} else {
			onSearchFetch()
		}
	}

	const onSearchFetch = useDebounce(() => fetchAll(searchTerm), 500)

	const fetchAll = useCallback(
		async (searchTerm = '') => {
			const pageData: IQueryParams = {
				take: FILES_PAGE_SIZE,
				skip: (page - 1) * FILES_PAGE_SIZE,
				q: searchTerm,
			}

			try {
				const response: IResponse<GetAllResponse<IFile>> = await dispatch(
					fetchAllFiles(pageData, searchTerm === ''),
				)
				setFiles(response.data.items)
			} catch (ex) {
				console.error(ex)
			}
		},
		[dispatch, page],
	)

	useEffect(() => {
		dispatch(setNavBarText('Files'))
		fetchAll()
	}, [dispatch, fetchAll])

	const onPageChange = (activePage: number) => {
		setPage(activePage)
		fetchAll()
	}

	return (
		<ContainerLayout>
			<Card hideTitle>
				<FileListPreview
					files={files}
					onPageChange={onPageChange}
					pagination={{
						usePagination: true,
						pageSize: FILES_PAGE_SIZE,
						totalItems: totalItems,
						onPageChange: onPageChange,
						activePage: page,
					}}
					searchTerm={searchTerm}
					setSearchTerm={onSearch}
				/>
			</Card>
		</ContainerLayout>
	)
}

export { FilesView }
