import { authPost } from 'lib/http-request'
import { Receipts } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, Dispatch, ReceiptState, IResponse } from 'interfaces'
import { Receipt } from '../interfaces'

const EMAIL_RECEIPT = 'client-trackyr/receipts/EMAIL_RECEIPT'
const EMAIL_RECEIPT_SUCCESS = 'client-trackyr/receipts/EMAIL_RECEIPT_SUCCESS'
const EMAIL_RECEIPT_FAILURE = 'client-trackyr/receipts/EMAIL_RECEIPT_FAILURE'

export const reducer = (state: ReceiptState, action: IAction) => {
  switch (action.type) {
    case EMAIL_RECEIPT: {
      return {
        ...state,
        flags: {
          ...state.flags,
          emailing: true,
        },
      }
    }

    case EMAIL_RECEIPT_SUCCESS: {
      return {
        ...state,
        flags: {
          ...state.flags,
          emailing: false,
        },
      }
    }

    case EMAIL_RECEIPT_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          emailing: false,
        },
      }
    }
    default:
      return state
  }
}

export const emailReceipt = (email: string, receipt: Receipt): any => async (
  dispatch: Dispatch,
): Promise<IResponse<Receipt>> => {
  dispatch({ type: EMAIL_RECEIPT, email, receipt })

  const data = {
    email,
    receiptNumber: receipt.receiptNumber,
  }

  return authPost(Receipts.Email, data)
    .then((response) => {
      message.success('Email Sent!')

      dispatch(onEmailSuccess(response.data))
      return response
    })
    .catch((error) => {
      message.error('Failed to Email Receipt')

      captureMessage(`saveSettings Failed. ${error}`, Severity.Error)
      dispatch(onEmailFailure(error, receipt))
      return error
    })
}

const onEmailSuccess = (data: any) => ({
  type: EMAIL_RECEIPT_SUCCESS,
  data,
})
const onEmailFailure = (error: any, receipt: Receipt) => ({
  type: EMAIL_RECEIPT_FAILURE,
  error,
  receipt,
})
