import moment from 'moment'
import { IAction, CalendarState } from 'interfaces'

const prefix = 'client-trackyr/calendar/'
const ON_DATE_CHANGE = `${prefix}/ON_DATE_CHANGE`
const ON_VIEW_CHANGE = `${prefix}/ON_VIEW_CHANGE`

export const defaultState = {
  view: 'month',
  date: moment(),
} as CalendarState

export const reducer = (
  state: CalendarState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case ON_DATE_CHANGE:
      return {
        ...state,
        date: action.date,
      }

    case ON_VIEW_CHANGE:
      return {
        ...state,
        view: action.view,
      }

    default:
      return { ...state }
  }
}

export const onDateChange = (date: moment.Moment | Date) => ({
  type: ON_DATE_CHANGE,
  date,
})

export const onViewChange = (view: string) => ({
  type: ON_VIEW_CHANGE,
  view,
})
