import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authGet } from 'lib/http-request'
import { User } from 'constants/endpoints'
import { IQueryParams, Options, IResponse } from 'interfaces/fetch'
import { IAction, IUser } from 'interfaces'

const action = (action: string) => `client-trackyr/features/${action}`
const FETCH_ALL_USERS = action('FETCH_ALL_USERS')
const FETCH_ALL_USERS_SUCCESS = action('FETCH_ALL_USERS_SUCCESS')
const FETCH_ALL_USERS_FAILURE = action('FETCH_ALL_USERS_FAILURE')

export const defaultState = {
  fetchingUsers: true,
  fetchingUsersError: { error: false },
  users: [],
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_ALL_USERS:
      return {
        ...state,
        fetchingUsers: true,
      }

    case FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        fetchingUsers: false,
        allUsers: action.response.items,
        totalCount: action.response.totalCount,
      }

    case FETCH_ALL_USERS_FAILURE:
      return {
        ...state,
        fetchingUsers: false,
        fetchingUsersError: {
          error: true,
          message: 'Failed to load all forms',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
// TODO - load fron indexDB, but also fetch
const fetchUsers = (queryParams?: IQueryParams): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_ALL_USERS, queryParams })

  return authGet(User.AdminAll, {} as Options, queryParams)
    .then((response: IResponse<IUser>) => {
      dispatch(fetchSuccess(response.data))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(`fetchUsers Get Failed: ${error}`, Severity.Error)

      throw error
    })
}

const fetchSuccess = (response: any) => ({
  type: FETCH_ALL_USERS_SUCCESS,
  response,
})

const fetchFailure = (error: any) => ({
  type: FETCH_ALL_USERS_FAILURE,
  error,
})

export { fetchUsers }
