import { authGet } from 'lib/http-request'
import { Receipts } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import {
  IAction,
  Dispatch,
  ReceiptState,
  IResponse,
  IQueryParams,
  GetAllResponse,
} from 'interfaces'
import { Receipt } from '../interfaces'

const FETCH_RECEIPTS = 'client-trackyr/receipts/FETCH_RECEIPTS'
const FETCH_RECEIPTS_SUCCESS = 'client-trackyr/receipts/FETCH_RECEIPTS_SUCCESS'
const FETCH_RECEIPTS_FAILURE = 'client-trackyr/receipts/FETCH_RECEIPTS_FAILURE'

export const reducer = (state: ReceiptState, action: IAction) => {
  switch (action.type) {
    case FETCH_RECEIPTS: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetching: true,
        },
      }
    }

    case FETCH_RECEIPTS_SUCCESS: {
      const receipts = {}
      const allIDs = []

      action.data.items?.forEach((item: Receipt) => {
        receipts[item.receiptNumber] = item
        allIDs.push(item.receiptNumber)
      })

      return {
        ...state,
        allIDs,
        receipts,
        totalReceipts: action.data.totalCount,
        flags: {
          ...state.flags,
          fetching: false,
        },
      }
    }

    case FETCH_RECEIPTS_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetching: false,
        },
      }
    }
    default:
      return state
  }
}

export const fetchReceipts = (queryParams?: IQueryParams) => async (
  dispatch: Dispatch,
): Promise<IResponse<GetAllResponse<Receipt>>> => {
  dispatch({ type: FETCH_RECEIPTS, queryParams })

  return authGet(Receipts.Fetch(queryParams))
    .then((response: IResponse<GetAllResponse<Receipt>>) => {
      dispatch(onFetchSuccess(response.data))
      return response
    })
    .catch((error) => {
      message.error('Failed to fetch receipts')

      captureMessage(`fetchReceipts Failed. ${error}`, Severity.Error)
      dispatch(onFetchFailure(error))
      throw error
    })
}

const onFetchSuccess = (data: any) => ({
  type: FETCH_RECEIPTS_SUCCESS,
  data,
})
const onFetchFailure = (error: any) => ({
  type: FETCH_RECEIPTS_FAILURE,
  error,
})
