import { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'

import {
  Button,
  ButtonContainer,
  Form,
  Header,
  Modal,
  Print,
} from 'components/ui'
import { FormToPrint } from 'containers/forms'
import { IEvent } from 'interfaces'
import { Checkbox, InputNumber } from 'components/forms'
import { MultiFormPrint } from './interfaces'

const clampNumber = (num: number, a: number, b: number): number =>
  Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))

interface Props {
  // exitNoPrintReturnSettings?: (printParams: Print) => void
  data: any
  fileJson: any
  header?: any
  multiForms?: MultiFormPrint
  onTogglePrintView?: (isPrinting: boolean) => void
  primary?: boolean
  ref?: any
}

export const PrePrintModal = (props: Props) => {
  const ref = useRef()

  const [modalOpen, setModalOpen] = useState(false)
  const [printParams, setPrintParams] = useState({
    printingView: true,
    margins: '',
    multiLines: true, // This is backwards...
    ignoreCards: true,
    hideEmpty: false,
    inputAreaHeight: 2,
    textAreaHeight: 3,
  } as Print)

  const onResetToDefault = () => {
    setPrintParams({
      printingView: true,
      margins: '',
      multiLines: true, // This is backwards...
      ignoreCards: true,
      hideEmpty: false,
      inputAreaHeight: 2,
      textAreaHeight: 3,
    } as Print)
  }

  const onOpen = () => {
    setModalOpen(true)
    props?.onTogglePrintView?.(true)
  }
  const onClose = () => {
    setModalOpen(false)
    props?.onTogglePrintView?.(false)
    // props?.exitNoPrintReturnSettings?.(printParams)
  }

  const onChange = ({ target }: IEvent) => {
    const newPrintParams = { ...printParams }

    if (target.name === 'inputAreaHeight') {
      const newValue = clampNumber(Number(target.value), 1, 3)
      newPrintParams[target.name] = newValue
    } else if (target.name === 'textAreaHeight') {
      const newValue = clampNumber(Number(target.value), 2, 5)
      newPrintParams[target.name] = newValue
    } else {
      newPrintParams[target.name] = target.value
    }

    setPrintParams(newPrintParams)
  }

  return (
    <>
      <Modal onClose={onClose} open={modalOpen} size="tiny">
        <Header content="Print Config" icon="print" />
        <Modal.Content>
          <FormToPrint
            ref={props.ref ?? ref}
            data={props.data}
            fileJson={props.fileJson}
            header={props.header}
            multiForms={props.multiForms}
            printParams={printParams}
          />
          <Form>
            <Checkbox
              label="Hide empty Fields?"
              name="hideEmpty"
              onChange={onChange}
              toggle
              value={printParams['hideEmpty']}
            />
            <Checkbox
              label="Show Lines Instead of White Space for Empty Fields?"
              name="multiLines"
              onChange={onChange}
              toggle
              value={printParams['multiLines']}
            />

            <InputNumber
              label="Medium Text Area Height"
              name="inputAreaHeight"
              onChange={onChange}
              value={printParams['inputAreaHeight']}
            />

            <InputNumber
              label="Large Area Text Area Height"
              name="textAreaHeight"
              onChange={onChange}
              value={printParams['textAreaHeight']}
            />
          </Form>

          <Button
            content="Reset to Default"
            onClick={onResetToDefault}
            style={{ marginTop: '1em' }}
          />
        </Modal.Content>

        <Modal.Actions>
          <ButtonContainer>
            <Button
              content="Close"
              icon="arrow alternate circle left"
              onClick={onClose}
            />

            <ReactToPrint
              content={() => props.ref ?? ref.current}
              removeAfterPrint
              trigger={() => (
                <Button content="Print Form" icon="print" primary />
              )}
            />
          </ButtonContainer>
        </Modal.Actions>
      </Modal>

      <Button
        content={props.multiForms ? 'Print Forms' : 'Print Form'}
        icon="print"
        onClick={onOpen}
        primary={props.primary}
      />
    </>
  )
}
