import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Divider, Popup } from 'components/ui'
import { ConfirmCancelModal } from 'components/modals'
import { IAppointment, IFormsData } from 'interfaces'
import { Form_T } from 'containers/settings/form-types/new-interfaces'
import { AppointmentsPrintModal } from 'containers/print/appointments-print-modal'
import { CopyPreviousNotesButtonModal } from './copy-previous-notes-modal'

interface Props {
	appointment: IAppointment
	clientID: string
	loading?: boolean
	onChange: Function
	onNewSoap: (soapTypeID?: string) => void
	onPreviousSoapNotesSelected: (noteData: IFormsData) => void
	onSoapTypePick: (soapID: string, soapTypeID: string) => void
	readonly?: boolean
	showNewSoap: boolean
	soapID: string
	soapTypeID?: string
	soapTypes?: Form_T[]
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const NewButton = (props: Props) => {
	const [open, setOpen] = useState(false)
	const [changeSoapTypeModal, setChangeSoapTypeModal] = useState({
		open: false,
		soapTypeID: '',
	})
	const [printAppointmentsOpen, setPrintAppointmentsOpen] = useState(false)
	const [copyPreviousOpen, setCopyPreviousOpen] = useState(false)

	const onPrintAppointmentsOpen = () => {
		setPrintAppointmentsOpen(true)
		closePopup()
	}

	const onCopyPrevOpen = () => {
		setCopyPreviousOpen(true)
		closePopup()
	}

	const onPrintAppointmentsClose = () => {
		setPrintAppointmentsOpen(false)
	}
	const onChangeSoapTypeModalOpen = (id: string) =>
		setChangeSoapTypeModal({ open: open, soapTypeID: id })
	const onChangeSoapTypeModalClose = () =>
		setChangeSoapTypeModal({ open: false, soapTypeID: '' })

	if (props.readonly) {
		return null
	}

	const openPopup = () => setOpen(true)
	const closePopup = () => setOpen(false)
	const onNewSoap = (soapTypeID?: string) => {
		props.onNewSoap(soapTypeID)
		closePopup()
	}

	const newSoapButtons = () => {
		if (props.soapTypes.length === 1) {
			const onNewSoapType = () => onNewSoap(props.soapTypes[0].id)
			return (
				<Button
					content='New Note'
					icon='plus'
					onClick={onNewSoapType}
					primary
					style={{ marginBottom: '1em', width: '100%' }}
				/>
			)
		}

		return props.soapTypes.map((soapType: Form_T) => {
			const onNewSoapType = () => onNewSoap(soapType.id)
			return (
				<Button
					key={soapType.id}
					content={`New ${soapType.name} Note`}
					icon='plus'
					onClick={onNewSoapType}
					primary
					style={{ marginBottom: '1em', width: '100%' }}
				/>
			)
		})
	}

	const changeSoapTypeButtons = () => {
		const differentSoapTypes = props.soapTypes.filter(
			(st) => st.id !== props.soapTypeID,
		)

		return differentSoapTypes.map((soapType: Form_T) => {
			const onClickTypeChange = () => onChangeSoapTypeModalOpen(soapType.id)
			return (
				<Button
					key={soapType.id}
					content={`Change to ${soapType.name} Note`}
					icon='exchange'
					onClick={onClickTypeChange}
					style={{ marginBottom: '1em', width: '100%' }}
				/>
			)
		})
	}

	const renderChangeSoapTypeModal = () => {
		if (!changeSoapTypeModal.open) {
			return null
		}
		const soapType = props.soapTypes.find(
			(st) => st.id === changeSoapTypeModal.soapTypeID,
		)

		const modalProps = {
			modalOpen: changeSoapTypeModal.open,
			message: `Are you sure you want to change this Note Type to ${soapType?.name}?`,
			content:
				'Changing the type of note can cause any information and notes you\'ve already written to be lost.',
			onAccept: () => {
				props.onChange(
					'',
					'soapTypeID',
					props.clientID,
					props.appointment.id,
					changeSoapTypeModal.soapTypeID,
				)

				props.onSoapTypePick(props.soapID, changeSoapTypeModal.soapTypeID)
				onChangeSoapTypeModalClose()
			},
			onCancel: onChangeSoapTypeModalClose,
		}

		return <ConfirmCancelModal {...modalProps} />
	}

	return (
		<>
			{renderChangeSoapTypeModal()}

			<AppointmentsPrintModal
				onClose={onPrintAppointmentsClose}
				open={printAppointmentsOpen}
			/>

			<CopyPreviousNotesButtonModal
				currentAppointmentID={props.appointment.id}
				onClick={props.onPreviousSoapNotesSelected}
				onClose={() => setCopyPreviousOpen(false)}
				open={copyPreviousOpen}
			/>

			<Popup
				content={
					<ButtonContainer>
						{props.showNewSoap && newSoapButtons()}

						<Button
							content='Print'
							icon='print'
							onClick={onPrintAppointmentsOpen}
							style={{ width: '100%', marginTop: '0.5em' }}
						/>

						{props.soapTypeID && (
							<>
								<Divider />
								{changeSoapTypeButtons()}
							</>
						)}

						<Button content='Copy Previous Notes' onClick={onCopyPrevOpen} />
					</ButtonContainer>
				}
				on='click'
				onClose={closePopup}
				open={open}
				position='bottom right'
				trigger={
					<Button
						icon={props.showNewSoap ? 'plus' : 'ellipsis vertical'}
						loading={props.loading}
						onClick={openPopup}
						primary={props.showNewSoap}
					/>
				}
			/>
		</>
	)
}
