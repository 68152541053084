import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Message, FlexContainerCenter, H3 } from 'components/ui'

const OfficeSetup = ({ showButton }: IOfficeSetup) => (
  <Message
    content={
      <FlexContainerCenter>
        <div>
          <H3>Add your Clinic to your Account</H3>
        </div>
        <div>
          {showButton && (
            <Link to="/account/offices">
              <Button>Add Office</Button>
            </Link>
          )}
        </div>
      </FlexContainerCenter>
    }
    icon="info"
    warning
  />
)

interface IOfficeSetup {
  showButton?: boolean
}

OfficeSetup.defaultProps = {
  showButton: true,
}

export default OfficeSetup
