import React from 'react'
import styled from 'styled-components'

import { InlineSpinner } from 'components/loaders'
import { SuccessColour, FailureColour } from 'styles/colours'
import { Icon, Popup } from 'components/ui'

export const NONE = 'NONE'
export const FETCHING = 'FETCHING'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'

const Indicator = styled.div`
  /* position: absolute; */
  /* margin-top: 3.5em; */
  /* left: -2.5em; */
  /* right: 2.5em; */
  flex-grow: 1;
`

interface AutoSaveIndicatorProps {
  status: 'SUCCESS' | 'FAILURE' | 'FETCHING' | 'NONE' | string
}

const AutoSaveIndicator = (props: AutoSaveIndicatorProps) => {
  const getIndicatorStatus = () => {
    switch (props.status) {
      case SUCCESS:
        return (
          <Popup
            content="Successfully Synced"
            position="top center"
            trigger={
              <div>
                <Icon
                  name="check circle outline"
                  style={{
                    fontSize: 22,
                    color: SuccessColour,
                  }}
                />
              </div>
            }
          />
        )

      case FAILURE:
        return (
          <Popup
            content="This field was unable to sync to the server. Check your internet connection or try again later. You can also try manually saving this form."
            position="top center"
            title="Failed to save field"
            trigger={
              <div>
                <Icon
                  name="times circle outline"
                  style={{ fontSize: 22, color: FailureColour }}
                />
              </div>
            }
          />
        )

      case FETCHING:
        return (
          <Popup
            content="Saving"
            position="top center"
            trigger={
              <div>
                <InlineSpinner loading={true} />
              </div>
            }
          />
        )

      case NONE:
      default:
        return null
    }
  }

  return <Indicator>{getIndicatorStatus()}</Indicator>
}

export default AutoSaveIndicator
