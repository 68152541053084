import React from 'react'

import { FlexContainerCenter, Text, Button, Message, H3 } from 'components/ui'
import { Link } from 'react-router-dom'

const FirstTimeSetup = () => (
  <Message
    content={
      <FlexContainerCenter>
        <div>
          <H3>Setup your Account</H3>
          <Text>
            Your account isn&apos;t setup yet, let&apos;s setup your account
            real quick
          </Text>
        </div>
        <div>
          <Link to="/account/basic-info">
            <Button>Setup Account</Button>
          </Link>
        </div>
      </FlexContainerCenter>
    }
    icon="info"
    warning
  />
)

export default FirstTimeSetup
