import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { Button, ButtonContainer, Card, H1, Popup } from 'components/ui'
import { fetchReceipts } from './redux/fetch-receipts'
import { getReceipts$ } from './redux/selectors'
import { Receipt } from './interfaces'
import { Dispatch, IEvent, IQueryParams, IState, parseSearchLocation } from 'interfaces'
import { ReceiptModals } from './receipt-modals'
import { ReceiptQuery } from './components/query'
import { fetchServices } from 'containers/services'
import { ReceiptTable } from './components/receipt-table'

const PAGE_SIZE = 25

export const Receipts = () => {
	const dispatch: Dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const [params, setParams] = useState(parseSearchLocation(location.search))
	const [queryParams, setQueryParams] = useState({} as IQueryParams)
	const [receiptModal, setReceiptModal] = useState({
		open: false,
		receipt: {} as Receipt,
	})
	const [pdfModal, setPDFModal] = useState({ open: false, receipt: null })
	const [page, setPage] = useState(1)
	const onModalClose = () => {
		setReceiptModal({ open: false, receipt: {} as Receipt })
	}
	const [visible, setVisible] = useState(false)
	const onSetVisible = () => {
		setVisible(!visible)
	}

	const onPageChange = (page: number) => setPage(page)

	const receipts = useSelector(getReceipts$)
	const fetching = useSelector((state: IState) => state.receipts.flags.fetching)
	const totalReceipts = useSelector(
		(state: IState) => state.receipts.totalReceipts,
	)

	const onFetchServices = useCallback(() => {
		dispatch(fetchServices())
	}, [dispatch])

	const onItemClick = (receipt: Receipt) => {
		setReceiptModal({ open: true, receipt: receipt })
		// setPDFModal({ open: true, receipt })
	}

	const onPDFClose = () => {
		setPDFModal({ open: false, receipt: null })
	}

	const newReceipt = () => {
		setReceiptModal({ open: true, receipt: {} as Receipt })
	}

	const clearParams = () => {
		setParams({})
		navigate('/reports/invoices')
	}

	const onQueryChange = ({ target }: IEvent) => {
		setQueryParams((prev) => ({ ...prev, [target.name]: target.value }))
	}

	const { appointmentID } = params
	const { clientID, q, to, from, deleted, unpaid } = queryParams

	useEffect(() => {
		if (location) {
			setParams(parseSearchLocation(location.search))
		}

		const queryParams: IQueryParams = {
			skip: PAGE_SIZE * (page - 1),
			take: PAGE_SIZE,
			appointmentID,
			clientID,
			q,
			from,
			to,
			deleted,
		}

		if (unpaid) {
			queryParams.unpaid = true
		}

		dispatch(fetchReceipts(queryParams))
		onFetchServices()
	}, [
		dispatch,
		page,
		q,
		appointmentID,
		clientID,
		location,
		to,
		from,
		deleted,
		unpaid,
	])

	return (
		<>
			<ReceiptModals
				onClose={onModalClose}
				onPDFViewClose={onPDFClose}
				open={receiptModal.open}
				pdfView={pdfModal}
				receipt={receiptModal.receipt}
			/>

			{/* TODO - future card redesign look? */}
			<H1>Invoices</H1>
			<Card hideTitle>
				<ButtonContainer>
					<Button
						content='New Invoice'
						icon='print'
						onClick={newReceipt}
						primary
					/>
					<div>
						{params.appointmentID && (
							<Button content='Clear Search' icon='x' onClick={clearParams} />
						)}
						<Popup
							content={visible ? 'Close' : 'Search Invoices'}
							trigger={
								<Button
									icon={visible ? 'x' : 'search'}
									onClick={onSetVisible}
								/>
							}
						/>
					</div>
				</ButtonContainer>

				{visible && (
					<ReceiptQuery onChange={onQueryChange} query={queryParams} tempHide />
				)}

				<ReceiptTable
					fetching={fetching}
					onReceiptItemClick={onItemClick}
					paganation={{
						onPageChange,
						page,
						totalReceipts,
						pageSize: PAGE_SIZE,
					}}
					receipts={receipts}
				/>
			</Card>
		</>
	)
}
