import { createSelector } from 'reselect'
import { getSelectedClientID$ } from 'containers/clients/redux/selectors'
import { getSelectedAppointment$ } from 'containers/appointments'

const getFiles = (state) => state.files
const selectedFile = (state) => state.files.selectedFile
const publicEndpoints = (state) => state.files.endpoints
const allFiles = (state) => state.files.files
const allFilesByID = (state) => state.files.allFilesByID

export const getFilesForClient = createSelector(
  [getFiles, getSelectedClientID$],
  (files, clientID) => files[clientID] || [],
)

export const getFilesForSelectedAppointment = createSelector(
  [getFilesForClient, getSelectedAppointment$],
  (clientFiles, appointmentID) =>
    clientFiles.filter((cf) => cf.entityID === appointmentID) || [],
)

export const hasFilesForClient = createSelector(
  [getFilesForClient],
  (clientFiles) => clientFiles.length !== 0,
)

export const getPublicSharedURLForSelectedFile = createSelector(
  [selectedFile, publicEndpoints],
  (selectedFile, publicEndpoints) => publicEndpoints[selectedFile],
)

export const getAllFiles = createSelector(
  [allFiles, allFilesByID],
  (allFiles, allFilesByID) => {
    return allFilesByID.map((id) => allFiles[id])
  },
)
