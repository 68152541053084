import React from 'react'

import { Text, Progress } from 'components/ui'
import { getAmountUsedText } from 'lib'

const StorageQuota = (props: IStorageQuota) => {
  const used = isNaN(props.used) ? 0 : (props.used / props.total) * 100
  const total = getAmountUsedText(props.total)

  return (
    <>
      <Text>{`${used.toFixed(1)} % (${getAmountUsedText(
        props.used,
      )}) used of ${total}`}</Text>

      <Progress percent={used} type="quota" />
    </>
  )
}

interface IStorageQuota {
  total: number
  used: number
}

export default StorageQuota
