import moment from 'moment'

export const isPhoneNumberValid = (num: string) =>
  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(num) // eslint-disable-line no-useless-escape

export const isEmailValid = (email: string) =>
  /(.+)@(.+){2,}\.(.+){2,}/.test(email)
export const isNumber = (str: number) => str && !isNaN(str)
export const isAlphabetic = (str: string) => /^[a-zA-Z()]+$/.test(str)
export const isAlphanumeric = (str: string) => /[^a-zA-Z0-9]/.test(str)
export const isValidDate = (date: moment.Moment | Date | string) =>
  moment(date).isValid()
