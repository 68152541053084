import React from 'react'

import { FormField, Label, Grid, Print, SmallAddress } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { Input } from './input'
import {
	ValidationRules,
	Validation,
	AutoSave,
} from 'components/forms/interfaces'
import { IEvent } from 'interfaces'

interface AddressFormProps {
	// validation?: Validation
	address?: string
	addressTwo?: string
	addressValidation?: Validation
	autoSave?: AutoSave
	city?: string
	cityValidation?: Validation
	label?: string
	name?: string
	onChange: (e: IEvent) => void
	onValidate?: Function
	postalcode?: string
	postalcodeValidation?: Validation
	printParams?: Print
	province?: string
	provinceValidation?: Validation
	readonly?: boolean
	size?: string
	validationRules?: ValidationRules
}

const AddressForm = (props: AddressFormProps) => {
	if (props.printParams?.printingView) {
		if (
			props.address ||
			props.addressTwo ||
			props.city ||
			props.province ||
			props.postalcode
		) {
			return (
				<SmallAddress
					addressOne={props.address}
					addressTwo={props.addressTwo}
					city={props.city}
					postalCode={props.postalcode}
					province={props.province}
				/>
			)
		} else {
			return (
				<>
					<Input
						label='Address'
						onChange={props.onChange}
						placeholder='Address'
						printParams={props.printParams}
						readonly={props.readonly}
						size={props.size}
						value={props.address}
					/>
					<Input
						label='Address Line 2'
						name={`${props.name}-addressTwo`}
						onChange={props.onChange}
						placeholder='Address Line 2'
						printParams={props.printParams}
						readonly={props.readonly}
						size={props.size}
						value={props.addressTwo}
					/>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Input
							label='City'
							onChange={props.onChange}
							placeholder='City'
							printParams={props.printParams}
							readonly={props.readonly}
							size={props.size}
							value={props.city}
						/>
						<Input
							label='Province'
							onChange={props.onChange}
							placeholder='Province'
							printParams={props.printParams}
							readonly={props.readonly}
							size={props.size}
							value={props.province}
						/>
					</div>
					<Input
						label='Postal Code'
						onChange={props.onChange}
						placeholder='Postal Code'
						printParams={props.printParams}
						readonly={props.readonly}
						size={props.size}
						value={props.postalcode}
					/>
				</>
			)
		}
	}

	return (
		<FormField>
			<Label>{props.label}</Label>

			<Grid>
				<Grid.Column width={16}>
					<AutoSaveWrapper
						afterAutoSaveCallback={props.autoSave.afterAutoSaveCallback}
						autosaveDisabled={props.autoSave.autosaveDisabled}
						endpoint={props.autoSave.patchEndpoint}
						entityID={props.autoSave.entityID}
						formType={props.autoSave.patchType}
					>
						<Input
							label='Address'
							name={`${props.name}-address`}
							onChange={props.onChange}
							onValidate={
								props.onValidate
									? (validationRules, _, __, value) =>
										props.onValidate(
											validationRules,
											'Address',
											`${props.name}-address`,
											value,
										)
									: null
							}
							placeholder='Address'
							printParams={props.printParams}
							readonly={props.readonly}
							size={props.size}
							validation={props.addressValidation}
							validationRules={props.validationRules}
							value={props.address}
						/>
					</AutoSaveWrapper>
				</Grid.Column>

				<Grid.Column width={16}>
					<AutoSaveWrapper
						afterAutoSaveCallback={props.autoSave.afterAutoSaveCallback}
						autosaveDisabled={props.autoSave.autosaveDisabled}
						endpoint={props.autoSave.patchEndpoint}
						entityID={props.autoSave.entityID}
						formType={props.autoSave.patchType}
					>
						<Input
							label='Address Line 2'
							name={`${props.name}-addressTwo`}
							onChange={props.onChange}
							placeholder='Address Line 2'
							printParams={props.printParams}
							readonly={props.readonly}
							size={props.size}
							value={props.addressTwo}
						/>
					</AutoSaveWrapper>
				</Grid.Column>

				<Grid.Column computer={10} mobile={16} tablet={10}>
					<AutoSaveWrapper
						afterAutoSaveCallback={props.autoSave.afterAutoSaveCallback}
						autosaveDisabled={props.autoSave.autosaveDisabled}
						endpoint={props.autoSave.patchEndpoint}
						entityID={props.autoSave.entityID}
						formType={props.autoSave.patchType}
					>
						<Input
							label='City'
							name={`${props.name}-city`}
							onChange={props.onChange}
							onValidate={
								props.onValidate
									? (validationRules, _, __, value) =>
										props.onValidate(
											validationRules,
											'City',
											`${props.name}-city`,
											value,
										)
									: null
							}
							placeholder='City'
							printParams={props.printParams}
							readonly={props.readonly}
							size={props.size}
							validation={props.cityValidation}
							validationRules={props.validationRules}
							value={props.city}
						/>
					</AutoSaveWrapper>
				</Grid.Column>

				<Grid.Column computer={6} mobile={16} tablet={6}>
					<AutoSaveWrapper
						afterAutoSaveCallback={props.autoSave.afterAutoSaveCallback}
						autosaveDisabled={props.autoSave.autosaveDisabled}
						endpoint={props.autoSave.patchEndpoint}
						entityID={props.autoSave.entityID}
						formType={props.autoSave.patchType}
					>
						<Input
							label='Province'
							name={`${props.name}-province`}
							onChange={props.onChange}
							onValidate={
								props.onValidate
									? (validationRules, _, __, value) =>
										props.onValidate(
											validationRules,
											'Province',
											`${props.name}-province`,
											value,
										)
									: null
							}
							placeholder='Province'
							printParams={props.printParams}
							readonly={props.readonly}
							size={props.size}
							validation={props.provinceValidation}
							validationRules={props.validationRules}
							value={props.province}
						/>
					</AutoSaveWrapper>
				</Grid.Column>

				<Grid.Column width={16}>
					<AutoSaveWrapper
						afterAutoSaveCallback={props.autoSave.afterAutoSaveCallback}
						autosaveDisabled={props.autoSave.autosaveDisabled}
						endpoint={props.autoSave.patchEndpoint}
						entityID={props.autoSave.entityID}
						formType={props.autoSave.patchType}
					>
						<Input
							label='Postal Code'
							name={`${props.name}-postalcode`}
							onChange={props.onChange}
							onValidate={
								props.onValidate
									? (validationRules, _, __, value) =>
										props.onValidate(
											validationRules,
											'Postal Code',
											`${props.name}-postalcode`,
											value,
										)
									: null
							}
							placeholder='Postal Code'
							printParams={props.printParams}
							readonly={props.readonly}
							size={props.size}
							validation={props.postalcodeValidation}
							validationRules={props.validationRules}
							value={props.postalcode}
						/>
					</AutoSaveWrapper>
				</Grid.Column>
			</Grid>
		</FormField>
	)
}

AddressForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const Address = React.memo(AddressForm) // TODO - still necessary?
