import React from 'react'
import styled from 'styled-components'
import { Form as SemanticForm } from 'semantic-ui-react'

import { Font } from 'styles/font'
import { ErrorText } from 'components/ui'

const F = styled(SemanticForm)`
  ${Font};

  @media print {
    margin: 0 0 0 0 !important;
    padding: 0 !important;
  }
`

const FField = styled(SemanticForm.Field)`
  ${Font};

  @media print {
    margin: 0 0 0 0 !important;
    padding: 0 !important;
  }
`

const Form = ({ ...props }) => <F {...props} />

const FormField = ({ ...props }: FormFieldProps) => (
  <FField {...props}>
    {props.children}

    {props.validateStatus && props.validateStatus === 'error' && (
      <ErrorText>{props.help}</ErrorText>
    )}
  </FField>
)

interface FormFieldProps {
  children: any
  help?: string
  required?: boolean
  style?: object
  validateStatus?: string
}

export { Form, FormField }
