import React, { useEffect, useState } from 'react'

import { Button, Card, List } from 'components/ui'
import { TaxModal } from '../components/tax-modal'
import { useDispatch, useSelector } from 'react-redux'
import { Tax, getTaxes$, fetchTaxes } from 'containers/taxes'
import { Dispatch, IState } from 'interfaces'
import { TaxItem } from '../components/tax-item'

export const TaxSettings = () => {
	const dispatch: Dispatch = useDispatch()
	const [modalState, setModalState] = useState({ open: false, tax: null })

	const taxes: Tax[] = useSelector(getTaxes$)
	const fetching: boolean = useSelector(
		(state: IState) => state.services.flags.fetching,
	)

	useEffect(() => {
		dispatch(fetchTaxes())
	}, [dispatch])

	const onClick = (service: Tax) => {
		setModalState({ open: true, tax: service })
	}

	const onModalClose = () => {
		setModalState({ open: false, tax: null })
	}

	const onAddService = () => {
		setModalState({ open: true, tax: {} as Tax })
	}

	return (
		<>
			<TaxModal
				onClose={onModalClose}
				open={modalState.open}
				tax={modalState.tax}
			/>

			<Card title='Tax Settings'>
				<Button content='New Tax' icon='plus' onClick={onAddService} primary />

				<List
					collapseOnMobile={false}
					items={taxes}
					itemType='Forms'
					loading={fetching}
					noItemsMessage='No Taxes, hurray!'
					renderer={(service: Tax) => (
						<TaxItem onClick={onClick} tax={service} />
					)}
				/>
			</Card>
		</>
	)
}
