import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'

import { Font, ReadonlyFormFont } from 'styles/font'
import { PrintableInputs } from '.'
import { TextUIElement } from './interfaces'

const I = styled(TextareaAutosize)`
  ${Font};
  color: ${(props) => props.theme.TextColour} !important;
  background-color: ${(props) => props.theme.BackgroundColour} !important;
  border: 1px solid ${(props) => props.theme.InputBorderColour} !important;

  border-radius: 4px;
  width: 100%;
  padding: 0.67857143em 1em;
  min-height: 3em;

  :focus {
    border-color: ${(props) => props.theme.InputBorderColourFocused} !important;
    box-shadow: 0 0 2px ${(props) => props.theme.InputBorderColourFocused} !important;
  }
`

const InputArea = ({ readonly, ...props }: TextUIElement) =>
  props?.printParams?.printingView ? (
    <PrintableInputs
      height={props?.printParams?.inputAreaHeight ?? 2}
      multiLines={props?.printParams?.multiLines}
      value={props.value}
    />
  ) : readonly ? (
    <ReadonlyFormFont>{props.value || ' '}</ReadonlyFormFont>
  ) : (
    <I rows={1} {...props} />
  )

InputArea.defaultProps = {
  readonly: false,
}

export default InputArea
