import React from 'react'
import { connect } from 'react-redux'

import { Card, Text } from 'components/ui'
import { CLIENT_TYPES } from 'constants/settings'

import {
	getActiveClientTypes,
	getSoapTypes$,
	getDeletedClientTypes,
} from '../selectors'
import {
	onAddForm,
	missingField,
	onSoapTypesForClientChange,
	onSoapTypesForClientAdd,
	onSoapTypesForClientRemove,
} from '../redux/ducks'
import { saveFormsTypes } from '../redux/save-forms-types'
import FormTypeSettings from './form-type-settings'
import { Form_T } from '../form-types/new-interfaces'
import { Dispatch, IState } from 'interfaces'

interface Props {
	clientTypes: Form_T[]
	deletedClientTypes: any[]
	// isDirty: boolean
	missingField: Function
	// onSoapTypesForClientAdd: Function
	// onSoapTypesForClientChange: Function
	// onSoapTypesForClientRemove: Function
	saveClientTypes: Function
	// soapTypes: Form_T[]
}

class ClientSettings extends React.Component<Props> {
	options = []

	saveClientTypes = (
		index: number,
		callback: Function,
		skipVerification = true,
	) => {
		if (!skipVerification) {
			const clientType = this.props.clientTypes[index]

			if (!clientType.name) {
				this.props.missingField('Name')
				return
			}
			// TODO - bring back this validation? Currently happens in save
			// TODO - SELECTEDFORMS FIX
			// else if (
			//   !clientType.selectedForms.length ||
			//   clientType.selectedForms.filter((sf) => sf === '').length
			// ) {
			//   this.props.missingField('Forms')
			//   return
			// }
		}
		// else if (
		//   !clientType.selectedNotes.length ||
		//   clientType.selectedNotes.filter((sf) => sf === '').length
		// ) {
		//   this.props.missingField('Selected Notes')
		//   return
		// }

		this.props.saveClientTypes(this.props.clientTypes).then(() => callback())
	}

	// isDirty={this.props.isDirty}
	// message='You have unsaved Client Types, are you sure you want to leave?'

	render() {
		return (
			<>
				<Card title='Client Types'>
					<Text>
						Here you can add or remove different types of Clients you may have.
						You can associate each client type with different Forms.
					</Text>

					<FormTypeSettings
						deletedFormTypes={this.props.deletedClientTypes}
						formTypeName='Client'
						formTypes={this.props.clientTypes}
						// onSave={this.saveClientTypes}
						// subFormsProps={{
						// 	label: 'Soap Types for Client',
						// 	onSubFormsForClientRemove: this.props.onSoapTypesForClientRemove,
						// 	onSubFormsForClientChange: this.props.onSoapTypesForClientChange,
						// 	onSubFormsForClientAdd: this.props.onSoapTypesForClientAdd,
						// 	subFormField: 'selectedNotes',
						// 	subFormTypes: this.props.soapTypes,
						// }}
						// tab='client-info'
						type={CLIENT_TYPES}
					/>
				</Card>
			</>
		)
	}
}

const mapStateToProps = (state: IState) => ({
	clientTypes: getActiveClientTypes(state),
	deletedClientTypes: getDeletedClientTypes(state),
	soapTypes: getSoapTypes$(state),
	isDirty: state.settings.isDirty,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onAddForm: (index: number) => dispatch(onAddForm(index, CLIENT_TYPES)),
	missingField: (name: string) => dispatch(missingField(name)),
	onSoapTypesForClientChange: (value: any, index: number, formIndex: number) =>
		dispatch(onSoapTypesForClientChange(value, index, formIndex)),
	saveClientTypes: (types: any) =>
		dispatch(saveFormsTypes(types, CLIENT_TYPES)),
	onSoapTypesForClientAdd: (index: number) =>
		dispatch(onSoapTypesForClientAdd(index)),
	onSoapTypesForClientRemove: (index: number, formsIndex: number) =>
		dispatch(onSoapTypesForClientRemove(index, formsIndex)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientSettings)
