import React from 'react'
import { connect } from 'react-redux'

import { Card, Text } from 'components/ui'
import { SOAP_TYPES } from 'constants/settings'

import { getActiveSoapTypes, getDeletedSoapTypes } from '../selectors'
import { missingField } from '../redux/ducks'
import FormTypeSettings from './form-type-settings'
import { Form_T } from '../form-types/new-interfaces'
import { Dispatch, IState } from 'interfaces'

interface Props {
	deletedSoapTypes: any[]
	soapTypes: Form_T[]
}

class SoapSettings extends React.Component<Props> {
	render() {
		return (
			<>
				<Card title='Notes Types'>
					<Text>
						Here you can add or remove different types of Notes you may have.
						You can associate each Note Type with a Form.
					</Text>

					<FormTypeSettings
						deletedFormTypes={this.props.deletedSoapTypes}
						formTypeName='Note'
						formTypes={this.props.soapTypes}
						type={SOAP_TYPES}
					/>
				</Card>
			</>
		)
	}
}

const mapStateToProps = (state: IState) => ({
	soapTypes: getActiveSoapTypes(state),
	deletedSoapTypes: getDeletedSoapTypes(state),
	isDirty: state.settings.isDirty,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	missingField: (name: string) => dispatch(missingField(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SoapSettings)
