import { Icon } from './icon'

interface Props {
  shape: 'Lines' | 'Circle' | 'Square'
  colour: string
}

const getIcon = (shape: string): string => {
  if (shape === 'Lines') {
    return 'pencil'
  } else if (shape === 'Circle') {
    return 'circle'
  } else if (shape === 'Square') {
    return 'square'
  } else {
    return 'square'
  }
}

export const DrawIcon = (props: Props) => {
  const icon = getIcon(props.shape)
  return (<Icon name={icon} size="large" style={{color: props.colour}} />)
}