import React, { useState } from 'react'
import SortableTree, {
  removeNodeAtPath,
  changeNodeAtPath,
} from 'react-sortable-tree'

import { Button, H3 } from 'components/ui'
import { FormItemComponent } from './interfaces'
import styled from 'styled-components'
import { DeleteModal } from 'components/modals'
import { EditCompnentModal } from './components/edit-component-modal'

import './style.css'
import { useGetColourTheme } from 'lib'

const FormSheetContainer = styled.div`
  width: 100%;
  height: 80vh;
`

interface Props {
  components: Array<FormItemComponent>
  setComponents: (components: Array<FormItemComponent>) => void
  onRemoveComponent: (node: any) => void
}

export const FormSheet = (props: Props) => {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    node: null,
    path: null,
  })
  const [editModal, setEditModal] = useState({
    open: false,
    node: null,
    path: null,
  })

  const onSetItems = (items: Array<FormItemComponent>) => {
    props.setComponents(items)
  }

  const theme = useGetColourTheme('dark')

  const canDrop = ({ node, nextParent }) => {
    if (node.isComponent && nextParent?.isSubFormComponent) {
      return true
    }

    if (node.isComponent && nextParent?.isComponent) {
      return false
    }

    if (
      node.isContainer &&
      nextParent?.isComponent &&
      !nextParent?.isSubFormComponent
    ) {
      return false
    }

    return true
  }

  const getNodeKey = ({ treeIndex }) => treeIndex

  const removeComponent = (path: any) => {
    const treeData = removeNodeAtPath({
      treeData: props.components,
      path,
      getNodeKey,
    })
    onSetItems(treeData)
    props.onRemoveComponent(deleteModal.node)
    setDeleteModal({ open: false, path: null, node: null })
  }

  const updateComponent = (updatedNode: any) => {
    const treeData = changeNodeAtPath({
      treeData: props.components,
      path: editModal.path,
      getNodeKey,
      newNode: { ...updatedNode },
    })
    onSetItems(treeData)
    setEditModal({ open: false, node: null, path: null })
  }

  return (
    <>
      <DeleteModal
        content="Warning: Deleting a Component will also remove it's children. If this is an existing form removed items are saved under Orphaned Data."
        message={`Delete Component: ${
          deleteModal?.node?.titleLabel || deleteModal?.node?.title
        }?`}
        modalOpen={deleteModal.open}
        onAccept={() => removeComponent(deleteModal.path)}
        onCancel={() => setDeleteModal({ open: false, path: null, node: null })}
      />

      <EditCompnentModal
        modalOpen={editModal.open}
        node={editModal.node}
        onAccept={(updatedNode: any) => {
          updateComponent(updatedNode)
        }}
        onCancel={() => setEditModal({ open: false, node: null, path: null })}
      />

      <FormSheetContainer
        className={theme === 'dark' ? 'dark-theme' : 'light-theme'}
      >
        <H3>Form Sheet</H3>
        <SortableTree
          canDrop={canDrop}
          generateNodeProps={({ node, path }) => ({
            title: node?.additionalProps?.deprecated ? (
              <s>{node.title}</s>
            ) : (
              node.title
            ),
            buttons: [
              <Button
                key="edit"
                circular
                icon="pencil"
                onClick={() => {
                  setEditModal({ open: true, node, path })
                }}
                size="tiny"
              />,
              <Button
                key="close"
                circular
                icon="x"
                negative
                onClick={() => setDeleteModal({ open: true, node, path })}
                size="tiny"
                style={{ marginLeft: '0.25em' }}
              />,
            ],
            style: {
              color: 'black',
            },
          })}
          // isVirtualized={false} // TEMP - Workaround for https://github.com/frontend-collective/react-sortable-tree/issues/821
          onChange={onSetItems}
          treeData={props.components}
        />
      </FormSheetContainer>
    </>
  )
}
