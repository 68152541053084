import { IState } from 'interfaces'
import { createSelector } from 'reselect'
import moment from 'moment'
import { Receipt } from '..'

const getReceiptObj = (state: IState) => state.receipts.receipts
const getReceiptIDs = (state: IState) => state.receipts.allIDs

export const getReceipts$ = createSelector(
  [getReceiptObj, getReceiptIDs],
  (receiptObj, receiptIDs): Receipt[] => {
    const receipts = []

    for (const receiptID of receiptIDs) {
      receipts.push(receiptObj[receiptID])
    }

    receipts.sort(
      (a: Receipt, b: Receipt) =>
        Number(moment(b.date).format('X')) - Number(moment(a.date).format('X')),
    )

    return receipts
  },
)
