import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Input } from 'components/forms'
import { Card, Form, LinkText } from 'components/ui'
import { getDefaultSettings, INVOICE_EMAIL_SUBJECT } from 'constants/settings'
import { Dispatch, IState, SettingKey } from 'interfaces'
import { getSettingNoDefault, saveSettings as saveS } from '../../settings'

export const InvoiceEmailSettings = () => {
	const dispatch: Dispatch = useDispatch()

	const emailSubjectSetting = useSelector((state: IState) =>
		getSettingNoDefault(state, INVOICE_EMAIL_SUBJECT),
	)

	const [emailState, setEmailState] = useState({
		[INVOICE_EMAIL_SUBJECT]: emailSubjectSetting,
	})

	const onChange = ({ target }) =>
		setEmailState((prev) => ({
			...prev,
			[target.name]: target.value,
		}))

	const saveSettings = (setting: SettingKey, value: string) =>
		dispatch(
			saveS({
				setting,
				value,
			}),
		)

	return (
		<Card collapsible startCollapsed title='Invoice Email'>
			<Form>
				<Input
					autoSave={{
						autosaveMode: 'onBlurOnly',
						entityID: 'setting',
						patch: () =>
							saveSettings(
								INVOICE_EMAIL_SUBJECT,
								emailState[INVOICE_EMAIL_SUBJECT],
							),
					}}
					label='Subject'
					name={INVOICE_EMAIL_SUBJECT}
					onChange={onChange}
					placeholder={getDefaultSettings(INVOICE_EMAIL_SUBJECT)}
					value={emailState[INVOICE_EMAIL_SUBJECT]}
				/>

				<Link to='/account/settings/invoice-settings'>
					<LinkText>
						The rest of the settings for Invoice Emails are Here.
					</LinkText>
				</Link>
			</Form>
		</Card>
	)
}
