import { changePassword } from 'lib/auth'
import { message } from 'components/notifications'
import { ErrorState, Dispatch, IAction } from 'interfaces'

const userAction = (action: string) => `client-trackyr/user/${action}`
const CHANGE_PASSWORD_REQUEST = userAction('CHANGE_PASSWORD_REQUEST')
const CHANGE_PASSWORD_REQUEST_SUCCESS = userAction(
  'CHANGE_PASSWORD_REQUEST_SUCCESS',
)
const CHANGE_PASSWORD_REQUEST_FAILURE = userAction(
  'CHANGE_PASSWORD_REQUEST_FAILURE',
)

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changingPassword: true,
        passwordChangeError: '',
      }

    case CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        passwordChangeError: '',
      }

    case CHANGE_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        changingPassword: false,
        passwordChangeError: action.error.code || action.error,
      }

    default:
      return state
  }
}

export const changePasswordRequest = (
  oldPassword: string,
  newPassword: string,
) => async (dispatch: Dispatch) => {
  dispatch({ type: CHANGE_PASSWORD_REQUEST })

  return changePassword(oldPassword, newPassword)
    .then((response: any) => {
      message.success('Password Successfully Changed')
      dispatch({ type: CHANGE_PASSWORD_REQUEST_SUCCESS })

      return response
    })
    .catch((error) => {
      message.error('Failed to Change Password, please try again later')
      dispatch(changePasswordRequestError(error))

      return error
    })
}

export const changePasswordRequestError = (error: ErrorState) => ({
  type: CHANGE_PASSWORD_REQUEST_FAILURE,
  error,
})
