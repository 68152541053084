import { authGet } from 'lib/http-request'
import { Appointments } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { message } from 'components/notifications'
import { IQueryParams } from 'interfaces/fetch'
import { AppointmentState, IAction } from 'interfaces'
import { ONE_HOUR } from 'constants/cache'

const action = (action: string) => `client-trackyr/appointments/${action}`
const FETCH_APPOINTMENTS = action('FETCH_APPOINTMENTS')
const FETCH_APPOINTMENTS_SUCCESS = action('FETCH_APPOINTMENTS_SUCCESS')
const FETCH_APPOINTMENTS_ERROR = action('FETCH_APPOINTMENTS_ERROR')

export const defaultState = {
  fetchingAppointments: false,
  appointmentsByMonth: {},
} as AppointmentState

export const reducer = (
  state: AppointmentState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case FETCH_APPOINTMENTS: {
      return {
        ...state,
        fetchingAppointments: true,
      }
    }

    case FETCH_APPOINTMENTS_SUCCESS: {
      const appointments = state.appointments
      const allAppointmentsByID = state.allAppointmentsByID
      const clientAppointments = state.clientAppointments

      for (const a of action.data.items) {
        const { userID, ...appointment } = a // eslint-disable-line no-unused-vars
        appointments[appointment.id] = appointment

        if (!allAppointmentsByID.includes(appointment.id)) {
          allAppointmentsByID.push(appointment.id)
        }

        if (clientAppointments[appointment.clientID]) {
          if (
            !clientAppointments[appointment.clientID].includes(appointment.id)
          ) {
            clientAppointments[appointment.clientID].push(appointment.id)
          }
        } else {
          clientAppointments[appointment.clientID] = [appointment.id]
        }
      }

      return {
        ...state,
        fetchingAppointments: false,
        appointments,
        allAppointmentsByID,
        appointmentsByMonth: {
          ...state.appointmentsByMonth,
          [`${action.queryParams.year}${action.queryParams.month}`]:
            action.data.items,
        },
        clientAppointments,
      }
    }

    case FETCH_APPOINTMENTS_ERROR: {
      return {
        ...state,
        fetchingAppointments: false,
      }
    }

    default:
      return state
  }
}

/**
 * fetchAppointments - fetches all appointments on a year/month basis
 * @param {object} queryParams top (100), skip (0), year, month, day (optional), clientID (optional)
 * @param {bool} useCache
 * @return {Promise}
 */
export const fetchAppointments =
  (queryParams: IQueryParams = {}, useCache: boolean = false): any =>
  async (dispatch: any): Promise<any> => {
    dispatch({ type: FETCH_APPOINTMENTS, queryParams, useCache })

    return authGet(Appointments.GetAll(queryParams), {
      cache: useCache,
      cacheMaxAge: ONE_HOUR,
    })
      .then(async (response) => {
        if (response.status === 200 || response.status === 304) {
          dispatch(fetchAppointmentsSuccess(response.data, queryParams))
        }
        return response
      })
      .catch((error) => {
        dispatch(fetchAppointmentsError(error))
        message.error('Failed to fetch appointments')

        captureMessage(`fetchAppointments Failed. ${error}`, Severity.Error)

        throw error
      })
  }

const fetchAppointmentsSuccess = (data: any, queryParams: IQueryParams) => ({
  type: FETCH_APPOINTMENTS_SUCCESS,
  data,
  queryParams,
})

const fetchAppointmentsError = (error: any) => ({
  type: FETCH_APPOINTMENTS_ERROR,
  error,
})
