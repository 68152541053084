import React from 'react'
import { Input as SemanticInput } from 'semantic-ui-react'
import styled from 'styled-components'

import { Font, ReadonlyFormFont } from 'styles/font'
import { Icon } from './icon'
import { TextUIElement } from './interfaces'
import { PrintableInputs } from './printables'

interface IInputStyled {
  minHeight?: number
  maxWidth?: number
  style?: any
  icon?: string
}

const I = styled(SemanticInput)<IInputStyled>`
  input {
    ${Font};
    background-color: ${(props) => props.theme.BackgroundColour} !important;
    color: ${(props) => props.theme.TextColour} !important;
    border-color: ${(props) => props.theme.InputBorderColour} !important;
    
    text-align: left;

    width: 100%;
    ${(props) =>
      props.style?.maxWidth ? `max-width: ${props.style?.maxWidth};` : null};
    ${(props) =>
      props.style?.minHeight ? `min-height: ${props.style?.minHeight};` : null};

    padding: 0em 1em;
    font-size: 1.1em;

    :focus {
      border-color: ${(props) =>
        props.theme.InputBorderColourFocused} !important;
      box-shadow: 0 0 2px ${(props) => props.theme.InputBorderColourFocused} !important;
    }
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }

`

const Input = (props: InputProps & TextUIElement) => {
  return props?.printParams?.printingView ? (
    <PrintableInputs
      height={1}
      multiLines={props?.printParams?.multiLines}
      value={props.value}
    />
  ) : props.readonly ? (
    <ReadonlyFormFont>{props.value || ' '}</ReadonlyFormFont>
  ) : (
    <I
      {...props}
      icon={
        props.icon ? (
          props.icon.name ? (
            { ...props.icon }
          ) : (
            <Icon name={props.icon} />
          )
        ) : null
      }
      style={props.style}
    />
  )
}

interface InputProps {
  action?: any
  autoFocus?: boolean
  icon?: IInputIcon | any
  inputLabel?: any
  loading?: boolean
  onFocus?: Function
  onKeyDown?: Function
  onKeyUp?: Function
  ref?: React.MutableRefObject<any>
  type?: string
}

interface IInputIcon {
  name: string
  onClick?: Function
  color: string
  link: boolean
}

Input.defaultProps = {
  readonly: false,
}

export default Input
