import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import { Card, Text, H4, Popup } from 'components/ui'
import { DEFAULT_EVENT_COLOUR } from 'constants/calendar-colours'
import { getClientFullName } from 'containers/clients'
import { getClientTypesNormalized } from 'containers/settings'
import { getAppointmentsForToday } from 'containers/appointments'
import { getClients$ } from 'containers/clients'
import { IState } from 'interfaces'
import {
  appointmentStatusToText,
  appointmentStatusToColour,
  AppointmentStatus,
} from 'containers/appointments/enums'

interface DayClientProps {
  selected: boolean
}

const DayClient = styled.div<DayClientProps>`
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  align-items: center;

  background-color: ${(props) =>
          props.selected ? 'rgb(33, 133, 208, 0.25)' : null};

  @media (max-width: 768px) {
    grid-template-columns: 8fr 7fr;
  }
`

const Clickable = styled.div`
  padding: 1em 1em 1em 1.5em;
  width: 100%;

  @media (max-width: 768px) {
    padding: 1em 0 1em 0em;
  }

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }
`

const NoClientsMessage = styled.div`
  padding: 1em 1em 1em 1.5em;
`

const TimeLine = styled.hr`
  position: relative;
  top: -18px;
  margin-bottom: -18px;
  border: 1px solid ${(props) => props.theme.PrimaryColour};
`

const TimeLineTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid ${(props) => props.theme.PrimaryColour};
`

const Empty = styled.div`
  width: 28px;
`

const TodaysClients = (props: ITodaysClients) => {
  const now = Number(moment.utc().format('X')) // TO utc?

  const appointments = useSelector(getAppointmentsForToday)
  const clients = useSelector(getClients$)
  const clientTypes = useSelector(getClientTypesNormalized)
  const day = useSelector((state: IState) => state.dashboard.day) as string

  const getListHeading = (day: string) => {
    const date = moment(day)

    if (moment().diff(date, 'days') > 1 || moment().diff(date, 'days') < 0) {
      return `${moment(day).format('MMMM Do')}'s Clients`
    }

    return `${date.calendar().split(' ')[0]}'s Clients`
  }

  return (
    <Card ignorePadding title={getListHeading(day)}>
      {appointments.length === 0 ? (
        <NoClientsMessage>
          <Text>No Clients Today, kick back and relax!</Text>
        </NoClientsMessage>
      ) : (
        appointments.map((appointment, index) => {
          if (appointment.status === AppointmentStatus.Cancelled) {
            return null
          }

          const client = clients[appointment.clientID]

          if (!client) {
            return null
          }

          const clientType = clientTypes[client.clientTypeID] || {}

          const appointmentStart = Number(moment(appointment.start).format('X'))
          const appointmentEnd = Number(moment(appointment.end).format('X'))

          const nextIndex =
            index === appointments.length - 1 ? index : index + 1
          const nextAppointmentStart = Number(
            moment(appointments[nextIndex].start).format('X'),
          )

          const nowWithinAppointment =
            now >= appointmentStart && now <= appointmentEnd
          const nowBeforeAppointment = now < appointmentStart
          const nowAfterAppointment = now > appointmentEnd

          const showLine =
            !nowWithinAppointment &&
            now > appointmentStart &&
            now < nextAppointmentStart

          return (
            <div key={appointment.id}>
              {nowBeforeAppointment && index === 0 && (
                <>
                  <TimeLineTriangle />
                  <TimeLine />
                </>
              )}

              <DayClient selected={nowWithinAppointment}>
                {nowWithinAppointment ? <TimeLineTriangle /> : <Empty />}

                <Clickable
                  onClick={() =>
                    props.onAppointmentClick(client.id, appointment.id)
                  }
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginRight: '1em',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Popup
                      content={appointmentStatusToText(appointment.status)}
                      trigger={
                        <div
                          style={{
                            borderRadius: '50%',
                            width: '1em',
                            height: '1em',
                            backgroundColor: appointmentStatusToColour(
                              appointment.status,
                            ),
                            marginRight: '1em',
                          }}
                        />
                      }
                    />
                    {/* STYLE - no margin on mobile? */}
                    <Popup
                      content='To Appointment'
                      trigger={
                        <div>
                          <Text>{`${moment(appointment.start).format(
                            'h:mma',
                          )} - ${moment(appointment.end).format('h:mma')}`}</Text>
                        </div>
                      }
                    />
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <Popup
                      content={clientType.name}
                      trigger={
                        <div
                          style={{
                            borderRadius: '50%',
                            width: '1em',
                            height: '1em',
                            backgroundColor:
                              clientType.colour || DEFAULT_EVENT_COLOUR,
                            marginRight: '1em',
                          }}
                        />
                      }
                    />

                    <div>
                      <H4>{getClientFullName(client)}</H4>
                    </div>
                  </div>
                </Clickable>
              </DayClient>

              {showLine && (
                <>
                  <TimeLineTriangle />
                  <TimeLine />
                </>
              )}

              {nowAfterAppointment && index === appointments.length - 1 && (
                <>
                  <TimeLineTriangle />
                  <TimeLine />
                </>
              )}
            </div>
          )
        })
      )}
    </Card>
  )
}

interface ITodaysClients {
  onAppointmentClick: (clientID: string, appointmentID: string) => void
}

export { TodaysClients }
