import {
  reducer as fetchAllClients,
  defaultState as fetchAllClientsDefaultState,
} from './fetch-all-clients'
import {
  reducer as archiveClient,
  defaultState as archiveClientDefaultState,
} from './archive-client'
import {
  reducer as unArchiveClient,
  defaultState as unArchiveClientDefaultState,
} from './un-archive-client'
import { reducer as clientInfoDucks } from '../client-info/redux/ducks'
import { ClientsState, IAction } from 'interfaces'

const reducers = [
  clientInfoDucks,
  fetchAllClients,
  archiveClient,
  unArchiveClient,
]

const clientAction = (action) => `client-trackyr/clients/${action}`
const SET_LOADING = clientAction('SET_LOADING')
const SET_CURRENT_CLIENT = clientAction('SET_CURRENT_CLIENT')
const UNSET_CURRENT_CLIENT = clientAction('UNSET_CURRENT_CLIENT')
const UPDATE_CLIENT_TYPE_IDS = clientAction('UPDATE_CLIENT_TYPE_IDS')

export const defaultState = {
  ...fetchAllClientsDefaultState,
  ...archiveClientDefaultState,
  ...unArchiveClientDefaultState,
  allIDs: [],
  clients: {},
  loadingAllClients: false,
  loadingAllClientsError: '',
  loadingTodaysClients: false,
  selectedClient: '',
  todaysClientErrors: '',
  todaysClients: [],
  allClientsFetched: false,
  loading: false,
  clientDetailsError: '',
  changesMade: false,
  clientNotFound: false,
  flags: {
    clientEditing: true,
    soapEditing: true,
    restEditing: true,
    archiving: false,
  },
  fetchingClient: false,
  verifyEmailError: '',
} as ClientsState

export const reducer = (
  state: ClientsState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case SET_CURRENT_CLIENT:
      return {
        ...state,
        selectedClient: action.clientID,
      }

    case UNSET_CURRENT_CLIENT:
      return {
        ...state,
        selectedClient: defaultState.selectedClient,
      }

    case UPDATE_CLIENT_TYPE_IDS: {
      const clients = state.clients

      for (const updateRequest of action.payload) {
        const client = clients[updateRequest.clientID]

        if (client) {
          client.clientTypeID = updateRequest.clientTypeID
        }
      }

      return {
        ...state,
        clients: {
          ...clients,
        },
      }
    }

    default:
      break
  }

  return reducers.reduce((s: any, r) => r && r(s, action), state)
}

export const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  loading,
})

export const setCurrentClient = (clientID: string) => ({
  type: SET_CURRENT_CLIENT,
  clientID,
})

export const unsetCurrentClient = () => ({ type: UNSET_CURRENT_CLIENT })

interface UpdateClientTypeIDParam {
  clientID: string
  clientTypeID: string
}
export const updateClientTypeIDs = (
  clientIDClientTypeID: Array<UpdateClientTypeIDParam>,
) => ({
  type: UPDATE_CLIENT_TYPE_IDS,
  payload: clientIDClientTypeID,
})
