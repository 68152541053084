import React, { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print'

import { Button, Modal, Header, Tab } from 'components/ui'
import { Forms, FormToPrint } from 'containers/forms'

const FormPreviewModal = (props: FormPreviewModalProps) => {
  const componentRef = useRef()
  const [values, setValues] = useState({})
  if (!props.modalOpen) {
    return null
  }

  const readonlyProps = {
    entityID: '',
    formsDataOverride: {},
    onChange: (_: any, name: string, value: any) => {
      setValues((prev) => ({ ...prev, [name]: value }))
    },
    patchEndpoint: '',
    readonly: false,
    validateFormOverride: null,
  }

  return (
    <Modal onClose={props.onCancel} open={props.modalOpen} size="large">
      <Header
        content={`Form Preview: ${props.name || props.fileJson.name}`}
        icon="file"
      />
      <Modal.Content>
        {props.fileJson && (
          <Forms
            {...readonlyProps}
            forms={props.fileJson}
            formsDataOverride={values}
          />
        )}
        {props.formsDirect && (
          <Tab
            panes={[
              {
                key: 'formsDirectName',
                menuItem: { content: props.formsDirectName },
                render: () => (
                  <Forms {...readonlyProps} forms={props.formsDirect} />
                ),
              },
              {
                key: 'formsSubFormsName',
                menuItem: { content: props.formsSubFormsName },
                render: () => (
                  <Forms {...readonlyProps} forms={props.formsSubForms} />
                ),
              },
            ]}
          />
        )}

        <FormToPrint ref={componentRef} fileJson={props.fileJson} />
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {props.readonly && props.canDelete && (
              <Button
                content={props.isDeleted ? 'Restore' : 'Delete'}
                icon="trash alternate"
                loading={props.uploading}
                negative={!props.isDeleted}
                onClick={() =>
                  props.isDeleted ? props.onRestore() : props.onDelete()
                }
              />
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ReactToPrint
              content={() => componentRef.current}
              trigger={() => <Button content="Print" icon="print" primary />}
            />

            {props.onCancel && (
              <Button
                content={props.readonly ? 'Close' : 'Cancel'}
                icon="x"
                onClick={props.onCancel}
                secondary
              />
            )}
            {props.onAccept && !props.readonly && (
              <Button
                content="Upload Form"
                loading={props.uploading}
                onClick={props.onAccept}
                primary
              />
            )}
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

interface FormPreviewModalProps {
  canDelete?: boolean
  fileJson: any
  formsDirect?: Array<any>
  formsDirectName?: string
  formsSubForms?: any
  formsSubFormsName?: string
  isDeleted?: boolean
  modalOpen: boolean
  name: string
  onAccept?: Function
  onCancel?: Function
  onDelete?: Function
  onRestore?: Function
  readonly?: boolean
  uploading?: boolean
}

FormPreviewModal.defaultProps = {
  canDelete: false,
  fileJson: {},
  isDeleted: false,
  modalOpen: false,
  name: undefined,
  onCancel: () => {},
  readonly: false,
}

export default FormPreviewModal
