import { Placeholder as P } from 'semantic-ui-react'
import styled from 'styled-components'

const Placeholder = styled(P)`
  .placeholder,
  .line {
    background-color: ${(props) => props.theme.BackgroundColour} !important;
  }

  .line,
  .paragraph,
  .image.header {
    ::before,
    ::after {
      background-color: ${(props) => props.theme.BackgroundColour} !important;
    }
  }

  background-color: ${(props) => props.theme.NeutralColourOne} !important;
`

export { Placeholder }
