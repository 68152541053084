import React from 'react'

import { Button, Modal, Header } from 'components/ui'

interface Props {
  acceptText: string
  content: string | React.ReactChildren | Element | any
  icon: string
  message: string
  modalOpen: boolean
  onAccept: Function
  onCancel: Function
  secondaryText: string
}

const PositiveModal = (props: Props) => (
  <Modal onClose={props.onCancel} open={props.modalOpen} size="small">
    <Header content={props.message} icon={props.icon} />
    {props.content && <Modal.Content>{props.content}</Modal.Content>}
    <Modal.Actions>
      <Button onClick={props.onCancel} secondary>
        {props.secondaryText}
      </Button>
      <Button onClick={props.onAccept} primary>
        {props.acceptText}
      </Button>
    </Modal.Actions>
  </Modal>
)

PositiveModal.defaultProps = {
  acceptText: 'Submit',
  modalOpen: false,
  onCancel: () => {},
  secondaryText: 'Cancel',
}

export default PositiveModal
