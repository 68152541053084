import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import styled from 'styled-components'
import moment from 'moment'

import { Label } from './label'
import { ErrorText } from './text'
import Button from './button'
import { Input, DatePicker } from 'components/forms'
import { Form } from 'components/ui'
import { IEvent } from 'interfaces'

interface IStyledSignature {
	width?: number
	height?: number
}

const SignatureBorder = styled.div<IStyledSignature>`
  max-height: ${(props) => props.height}px;
  max-width: ${(props) => props.width}px;
  background-color: ${(props) => props.theme.SignatureBackground};

  border: 1px solid ${(props) => props.theme.InputBorderColour};
  border-radius: 0.28571429rem;
`

const SignatureContainer = styled.div`
  padding-bottom: 1em;
`

const SignaturePad = styled.div`
  /* -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 100vh; */
`

const NameDateContainer = styled.div<IStyledSignature>`
  display: flex;
  justify-content: space-between;
  max-width: ${(props) => props.width}px;
`

const ButtonContainer = styled.div<IStyledSignature>`
  display: flex;
  padding-top: 1em;
  justify-content: space-between;

  max-width: ${(props) => props.width}px;
`

export default class Signature extends React.Component<ISignature> {
	public static defaultProps: Partial<ISignature> = {
		dateValue: moment(),
		height: 250,
		label: 'Signature',
		nameValue: '',
		showName: true,
		signature: null,
		width: 950,
	}

	signatureCanvas: any = React.createRef()
	container: any

	state = {
		editing: true,
		errors: '',
		errorType: '',
		width: 0,
		height: 0,
		updated: false,
	}

	componentDidMount() {
		this.setState(() => ({
			width: this.props.width,
			height: this.props.height,
		}))

		// this.resizeCanvas()
		// window.addEventListener('resize', () => {
		//   this.resizeCanvas()
		// })

		if (!this.props.readonly) {
			this.signatureCanvas.on()
		} else {
			this.signatureCanvas.off()
		}
	}

	componentWillUnmount() {
		// window.removeEventListener('resize', () => {
		//   this.resizeCanvas()
		// })
	}

	componentDidUpdate() {
		if (this.props.signature != null && !this.state.updated) {
			this.setCanvas()
			// this.resizeCanvas()
			this.setState(() => ({ updated: true })) // HACK - component not updating
		}
	}

	setCanvas = () => {
		if (this.signatureCanvas && this.signatureCanvas.clear) {
			this.signatureCanvas.clear()
			this.signatureCanvas.fromDataURL(this.props.signature, {
				width: this.state.width,
				height: this.state.height,
			})
			this.setState(() => ({ editing: false }))
		}
	}

	// resizeCanvas = () => {
	//   if (!this.container?.offsetWidth) {
	//     return
	//   }
	//   const offsetWidth =
	//     this.container?.offsetWidth > 256
	//       ? this.container.offsetWidth
	//       : window.innerWidth
	//   const ratio =
	//     this.props.width < offsetWidth
	//       ? 1
	//       : (offsetWidth - 150) / this.props.width
	//   console.log(
	//     '🚀 ~ file: signature.tsx ~ line 122 ~ Signature ~ ratio',
	//     ratio,
	//   )
	//   const width =
	//     offsetWidth < 1000 ? offsetWidth - 50 : this.props.width * ratio
	//   console.log(
	//     '🚀 ~ file: signature.tsx ~ line 128 ~ Signature ~ width',
	//     width,
	//   )
	//   if (this.signatureCanvas) {
	//     this.signatureCanvas.getCanvas().getContext('2d').scale(ratio, ratio)
	//   }
	//   this.setState(() => ({
	//     width,
	//     height: this.props.height * ratio,
	//   }))
	//   this.setCanvas()
	// }

	onClear = () => {
		this.setState(() => ({ editing: true }))
		this.signatureCanvas.on()
		this.signatureCanvas.clear()

		this.props.onChange({
			target: {
				name: this.props.name,
				value: '',
			},
		})
	}

	onSave = () => {
		this.props.onChange({
			target: {
				name: `${this.props.name}-date`,
				value: moment(),
			},
		})

		this.props.onChange({
			target: {
				name: this.props.name,
				value: this.signatureCanvas.getCanvas().toDataURL(),
			},
		})
	}

	isFormValid = () => {
		if (!this.props.nameValue) {
			this.setState(() => ({
				errors: 'Please Type your Name',
				errorType: 'name',
			}))
			return false
		}

		if (this.signatureCanvas && this.signatureCanvas.isEmpty()) {
			this.setState(() => ({
				errors: 'Please Sign your Name',
				errorType: 'sign',
			}))
			return false
		}

		this.setState(() => ({ errors: '' }))
		return true
	}

	onBlurInput = () => {
		if (this.isFormValid()) {
			this.setState(() => ({ editing: false }))
		}
	}

	render() {
		return (
			<SignatureContainer>
				{this.props.showName && (
					<NameDateContainer width={this.state.width}>
						<Form>
							<div style={{ width: '100%', paddingRight: '1em' }}>
								<Input
									label='Print Name'
									name={`${this.props.name}-name`}
									onBlur={this.onBlurInput}
									onChange={this.props.onChange}
									readonly={this.props.readonly}
									validationRules={{ required: true }}
									value={this.props.nameValue}
								/>

								{this.state.errors && this.state.errorType === 'name' && (
									<ErrorText>{this.state.errors}</ErrorText>
								)}
							</div>

							<DatePicker
								disabled
								format='MMMM D YYYY'
								label='Date Signed'
								name={`${this.props.name}-date`}
								onChange={this.props.onChange}
								readonly={this.props.readonly}
								value={this.props.dateValue}
							/>
						</Form>
					</NameDateContainer>
				)}

				<Label required>{this.props.label}</Label>
				<SignaturePad>
					<div
						ref={(node) => {
							this.container = node
						}}
					>
						<SignatureBorder
							height={this.state.height}
							width={this.state.width}
						>
							<SignatureCanvas
								ref={(ref: any) => {
									this.signatureCanvas = ref
								}}
								canvasProps={{
									width: this.state.width,
									height: this.state.height,
								}}
								onEnd={this.onSave}
								penColor='black'
							/>
						</SignatureBorder>
					</div>
				</SignaturePad>

				{this.state.errors && this.state.errorType === 'sign' && (
					<ErrorText>{this.state.errors}</ErrorText>
				)}

				{!this.props.readonly && (
					<ButtonContainer height={this.props.height} width={this.state.width}>
						<Button onClick={this.onClear}>Clear</Button>
					</ButtonContainer>
				)}
			</SignatureContainer>
		)
	}
}

interface ISignature {
	dateValue: any
	height: number
	label: string
	name: string
	nameValue: string
	onChange?: (e: IEvent) => void
	readonly: boolean
	showName: boolean
	signature: string
	width: number
}
