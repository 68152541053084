import { authPost } from 'lib/http-request'
import { Client } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { ClientsState, IAction, IClient } from 'interfaces'
import { invalidateCacheLike } from 'lib'
import { ALL_CLIENTS_CACHE } from 'constants/cache'
import { message } from 'components/notifications'

const clientAction = (action: string) => `client-trackyr/clients/${action}`
const UN_ARCHIVE_CLIENT = clientAction('UN_ARCHIVE_CLIENT')
const UN_ARCHIVE_CLIENT_SUCCESS = clientAction('UN_ARCHIVE_CLIENT_SUCCESS')
const UN_ARCHIVE_CLIENT_ERROR = clientAction('UN_ARCHIVE_CLIENT_ERROR')

export const defaultState = {} as ClientsState

export const reducer = (
  state: ClientsState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case UN_ARCHIVE_CLIENT:
      return {
        ...state,
        flags: {
          ...state.flags,
          archiving: true,
        },
      }

    case UN_ARCHIVE_CLIENT_SUCCESS: {
      const allIDs = state.allIDs

      return {
        ...state,
        allIDs: [...allIDs, action.client.id],
        clients: {
          ...state.clients,
          [action.client.id]: {
            ...state.clients[action.client.id],
            archived: false,
          },
        },
        flags: {
          ...state.flags,
          archiving: false,
        },
      }
    }

    case UN_ARCHIVE_CLIENT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          archiving: false,
        },
      }

    default:
      return state
  }
}

export const unArchiveClient =
  (id: string): any =>
  async (dispatch: any): Promise<any> => {
    dispatch({ type: UN_ARCHIVE_CLIENT, id })

    return authPost(Client.UnArchive(id), {})
      .then((response) => {
        invalidateCacheLike(ALL_CLIENTS_CACHE)
        dispatch(unArchiveClientSuccess(response.data))
        message.success('Client Un-Archived')
        return response
      })
      .catch((error) => {
        captureMessage(`unArchiveClient Failed. ${error}`, Severity.Error)
        dispatch(unArchiveClientFailure(error.message))
        message.error('Failed to Un-Archived Client')
        return error
      })
  }

const unArchiveClientSuccess = (client: IClient) => ({
  type: UN_ARCHIVE_CLIENT_SUCCESS,
  client,
})

const unArchiveClientFailure = (error: any) => ({
  type: UN_ARCHIVE_CLIENT_ERROR,
  error,
})
