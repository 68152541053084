import { authPost } from 'lib/http-request'
import { Treatments } from 'constants/endpoints'
import { message } from 'components/notifications'
import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'

// import { fetchClientSoaps } from 'containers/soap-notes'
import { fetchClientDetails } from 'containers/clients/client-info'
import { IAction } from 'interfaces'

const treatmentAction = (action: string) =>
	`client-trackyr/treatments/${action}`
const SAVE_TREATMENT = treatmentAction('SAVE_TREATMENT')
const SAVE_TREATMENT_SUCCESS = treatmentAction('SAVE_TREATMENT_SUCCESS')
const SAVE_TREATMENT_FAILURE = treatmentAction('SAVE_TREATMENT_FAILURE')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
	switch (action.type) {
		case SAVE_TREATMENT:
			return {
				...state,
				savingTreatment: true,
			}

		case SAVE_TREATMENT_SUCCESS: {
			const treatment = action.treatment
			const allByID =
				(state[action.clientID] && state[action.clientID].allByID) || []
			allByID.push(treatment.id)

			return {
				...state,
				[action.clientID]: {
					...state[action.clientID],
					[treatment.id]: treatment,
					fetched: true,
					currentTreatment: treatment.id,
				},
				savingTreatment: false,
				selectedTreatment: action.treatmentNumber,
				allByID,
			}
		}
		case SAVE_TREATMENT_FAILURE:
			return {
				...state,
				savingTreatment: false,
			}

		default:
			return state
	}
}

export const saveTreatment =
	(clientID: string, clientTypeID: string, treatment = {}): any =>
		async (dispatch: any): Promise<any> => {
			dispatch({ type: SAVE_TREATMENT, clientID })

			return authPost(Treatments.SaveTreatment, {
				clientID,
				clientTypeID,
				...treatment,
			})
				.then((response) => {
					const treatmentNumber = response.data.treatmentNumber
					dispatch(saveTreatmentSuccess(response.data, clientID, treatmentNumber))

					// dispatch(fetchClientSoaps(clientID, treatmentNumber))
					dispatch(fetchClientDetails(clientID, treatmentNumber))

					return response
				})
				.catch((error) => {
					captureMessage(`closeTreatment Failed. ${error}`, Severity.Error)

					dispatch(saveTreatmentFailure(error, clientID))
					message.error('Failed to Save Treatment')

					return error
				})
		}

export const saveTreatmentSuccess = (
	treatment: any,
	clientID: string,
	treatmentNumber: number,
) => ({
	type: SAVE_TREATMENT_SUCCESS,
	treatment,
	clientID,
	treatmentNumber,
})

export const saveTreatmentFailure = (error: any, clientID: string) => ({
	type: SAVE_TREATMENT_FAILURE,
	error,
	clientID,
})
