import { authGet } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { normalizeArray } from 'lib'
import { Dispatch, IAction } from 'interfaces'
import {
  formAPIToFormType,
  parseFormTypeSettingFromAPI,
} from 'lib/fetch-parsers'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`

const FETCH_FORMS_TYPES = settingsAction('FETCH_FORMS_TYPES')
const FETCH_FORMS_TYPES_SUCCESS = settingsAction('FETCH_FORMS_TYPES_SUCCESS')
const FETCH_FORMS_TYPES_FAILURE = settingsAction('FETCH_FORMS_TYPES_FAILURE')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_FORMS_TYPES:
      return {
        ...state,
      }

    case FETCH_FORMS_TYPES_SUCCESS: {
      const forms = []

      for (const f of action.response) {
        // TODO - remove first IF
        if (!f.forms) {
          forms.push({
            ...parseFormTypeSettingFromAPI(f),
          })
        } else {
          forms.push({
            ...formAPIToFormType(f),
          })
        }
      }

      const formTypes = normalizeArray(forms, action.formType)

      return {
        ...state,
        [action.formType]: {
          allIDs: formTypes.allIDs,
          ...formTypes[action.formType],
        },
        isDirty: false,
      }
    }
    case FETCH_FORMS_TYPES_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export const fetchFormsTypes = (formType: string) => async (
  dispatch: Dispatch,
): Promise<any> => {
  dispatch({ type: FETCH_FORMS_TYPES, formType })

  return authGet(Settings.FormType(formType))
    .then((response) => {
      dispatch(fetchFormsTypesSuccess(response.data, formType))
      return response
    })
    .catch((error) => {
      message.error('Failed to Fetch Forms Type Settings')
      captureMessage(`fetchFormsTypes Failed. ${error}`, Severity.Error)
      dispatch(fetchFormsTypesFailure({ ...error }, formType))

      return error
    })
}

export const fetchFormsTypesSuccess = (response, formType) => ({
  type: FETCH_FORMS_TYPES_SUCCESS,
  response,
  formType,
})

export const fetchFormsTypesFailure = (error, formType) => ({
  type: FETCH_FORMS_TYPES_FAILURE,
  error,
  formType,
})
