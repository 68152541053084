import localforage from 'localforage'
import {
  CLIENT_TRACKYR_STORE,
  REQUEST_CACHE,
  IMAGES_STORE_NAME,
  FORMS_STORE_NAME,
} from 'constants/storage'

export const STORAGE_QUOTA_CACHE = '/storage/quota'

const cacheStore = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: REQUEST_CACHE,
})

export const invalidateCache = (cacheKey: string) => {
  cacheStore.removeItem(cacheKey)
  cacheStore.removeItem(`${cacheKey}::EXPIRY`)
}

export const invalidateCacheLike = async (cacheKey: string) => {
  const keys = await cacheStore.keys()
  const keysToRemove = keys.filter((k: string) => k.includes(cacheKey))

  keysToRemove.forEach((cacheKey: string) => {
    cacheStore.removeItem(cacheKey)
    cacheStore.removeItem(`${cacheKey}::EXPIRY`)
  })
}

export const invalidateAppointmentCache = async (
  year: number,
  month: number,
) => {
  const cacheKey = `/appointment?year=${year}&month=${month}`
  await cacheStore.removeItem(cacheKey)
  await cacheStore.removeItem(`${cacheKey}::EXPIRY`)
}

export const invalidateClientAppointmentCache = async (clientID: string) => {
  const cacheKey = `/appointment/client/${clientID}`
  await invalidateCacheLike(cacheKey)
}

export const invalidateImageCache = (cacheKey: string) => {
  const imageCacheStorage = localforage.createInstance({
    name: CLIENT_TRACKYR_STORE,
    storeName: IMAGES_STORE_NAME,
  })

  imageCacheStorage.removeItem(cacheKey)
  imageCacheStorage.removeItem(`${cacheKey}::EXPIRY`)
}

export const invalidateForm = async (formID: string) => {
  const formStorage = localforage.createInstance({
    name: CLIENT_TRACKYR_STORE,
    storeName: FORMS_STORE_NAME,
  })

  await formStorage.removeItem(formID)
}
