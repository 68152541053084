import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Button, Form, Segment } from 'components/ui'
import { getClients$, getClientsForSelect$ } from 'containers/clients'
import {
	Checkbox,
	DatePicker,
	Dropdown,
	InputNumber,
	Input,
	TextArea,
} from 'components/forms'
import { Receipt } from '../interfaces'
import { Dispatch, IAppointment, IEvent, IState } from 'interfaces'
import { formatAmount } from 'lib'
import { AppointmentDropdown } from './appointment-dropdown'
import { ServiceDropdown } from './service-dropdown'
import { getServiceObj } from 'containers/services/redux/selectors'
import { PaymentTypeDropdown } from './payment-dropdown'
import { emptyGuid } from 'lib/guid'
import { emailReceipt } from '../redux/email-receipt'
import { TaxList } from 'containers/taxes'
import { DatePaidButton, DatePaidMode } from './date-paid-button'

interface ReceiptFormProps {
	correspondingAppointment?: IAppointment
	receipt?: Receipt
	onChange: (event: IEvent) => void
}

const ReceiptForm = (props: ReceiptFormProps) => {
	const dispatch: Dispatch = useDispatch()
	const clients = useSelector(getClients$)
	const emailing = useSelector((state: IState) => state.receipts.flags.emailing)

	const [isReadonly, setIsReadonly] = useState(true)
	const [editValue, setEditValue] = useState(false)

	const onEditChange = () => {
		setEditValue(!editValue)
		setIsReadonly(editValue)
	}

	const [disableEditingWhenAppointment, setDisableEditingWhenAppointment] =
		useState(!!props.receipt.appointmentID)
	const [datePaidMode, setDatePaidMode] = useState(DatePaidMode.InvoiceDate)

	const clientID = props.receipt?.clientID
	const client = clientID ? clients[clientID] : null
	const onEmailChanged = ({ target }: IEvent) => setEmail(target.value)
	const [email, setEmail] = useState(clients[clientID]?.email)

	const clientsForDropdown = useSelector(getClientsForSelect$)
	const services = useSelector(getServiceObj)

	const serviceID =
		props.receipt?.serviceID || props.correspondingAppointment?.serviceID

	useEffect(() => {
		if (props.receipt?.receiptNumber) {
			setIsReadonly(!editValue)

			if (props.receipt.appointmentID) {
				setDisableEditingWhenAppointment(true)
			}
		} else {
			setIsReadonly(false)
		}
	}, [props.receipt])

	useEffect(() => {
		if (serviceID && serviceID !== emptyGuid) {
			const service = services[serviceID]
			const value = formatAmount(service?.cost)

			if (value === props.receipt.amount) {
				return
			}

			props.onChange({
				target: {
					name: 'amount',
					value,
				},
			})

			onServiceChange({
				target: {
					name: 'serviceID',
					value: serviceID,
				},
			})
		}
	}, [services, serviceID, isReadonly])

	const onServiceChange = ({ target }: IEvent) => {
		const service = services[target.value]
		props.onChange({ target })
		props.onChange({
			target: {
				name: 'taxIDs',
				value: service?.taxIDs,
			},
		})
	}

	const onAmountBlur = ({ target }: IEvent) => {
		if (!target.value) {
			return
		}

		target.value = formatAmount(target.value)
		props.onChange({ target })
	}

	const sendAsEmail = async () => {
		dispatch(emailReceipt(email || client.email, props.receipt))
	}

	const onDatePaidButtonClick = (date: Date | moment.Moment) => {
		setDatePaidMode(
			datePaidMode === DatePaidMode.InvoiceDate
				? DatePaidMode.CurrentDate
				: DatePaidMode.InvoiceDate,
		)

		props.onChange({
			target: { name: 'datePaid', value: moment(date).format('YYYY-MM-DD') },
		})
	}

	const showRest = !!props.receipt.date || !!props.receipt.appointmentID

	// TODO - when picking appointment, if receipt already exists, navigate to that receipt, or filter results from what we can pick, or remove new invoice flow from receipts all together?

	return (
		<Form>
			{props.receipt.receiptNumber && (
				<Segment>
					<div style={{ display: 'flex' }}>
						<Input
							label='Clients Email Address'
							name='email'
							onChange={onEmailChanged}
							placeholder='Clients Email'
							style={{ width: '50%', minWidth: '18em' }}
							value={email || client?.email}
						/>
						<div>
							<Button
								content='Send Receipt to Email'
								icon='mail'
								loading={emailing}
								onClick={sendAsEmail}
								primary
								style={{
									marginLeft: '1em',
									marginTop: '1.85em',
									width: '15em',
								}}
							/>
						</div>
					</div>
				</Segment>
			)}

			{props.receipt?.receiptNumber && (
				<Checkbox
					label='Edit'
					name='edit'
					onChange={onEditChange}
					toggle
					value={editValue}
				/>
			)}

			<Dropdown
				label='Client'
				name='clientID'
				onChange={props.onChange}
				options={clientsForDropdown}
				readonly={disableEditingWhenAppointment || isReadonly}
				validationRules={{ required: true }}
				value={props.receipt.clientID}
			/>

			{props.receipt.clientID && (
				<>
					{(props.receipt.receiptNumber || !props.receipt.date) &&
						(disableEditingWhenAppointment || isReadonly ? (
							<DatePicker
								format='MMMM D YYYY h:mm a'
								label='Appointment'
								name='date'
								onChange={props.onChange}
								placeholder='Date'
								readonly
								value={props.correspondingAppointment?.start || ''}
							/>
						) : (
							<AppointmentDropdown
								clientID={props.receipt.clientID}
								onChange={props.onChange}
								readonly={disableEditingWhenAppointment || isReadonly}
								value={props.receipt.appointmentID}
							/>
						))}

					{(!props.receipt.appointmentID ||
						props.receipt.appointmentID === emptyGuid) && (
						<DatePicker
							format='MMMM D YYYY'
							label='Date'
							name='date'
							onChange={props.onChange}
							placeholder='Date'
							readonly={disableEditingWhenAppointment || isReadonly}
							value={props.receipt.date}
						/>
					)}

					{showRest && (
						<>
							<ServiceDropdown
								onChange={onServiceChange}
								readonly={isReadonly}
								value={serviceID}
							/>

							<Segment>
								<InputNumber
									inputLabel={{
										basic: true,
										content: '$',
									}}
									label='Amount'
									name='amount'
									onBlur={onAmountBlur}
									onChange={props.onChange}
									placeholder='Amount'
									readonly={isReadonly}
									// readonly={!!props.receipt.serviceID}
									value={props.receipt.amount}
								/>

								<TaxList
									amount={Number(props.receipt.amount)}
									name='taxIDs'
									onChange={props.onChange}
									readonly={isReadonly}
									taxIDs={props.receipt.taxIDs || services[serviceID]?.taxIDs}
								/>
							</Segment>

							<Checkbox
								label='Paid?'
								name='paid'
								onChange={props.onChange}
								readonly={isReadonly}
								value={props.receipt.paid}
							/>

							{props.receipt.paid && (
								<Segment>
									<PaymentTypeDropdown
										onChange={props.onChange}
										readonly={isReadonly}
										value={props.receipt.paymentType}
									/>

									<DatePicker
										format='MMMM D YYYY'
										label='Date Paid'
										name='datePaid'
										onChange={props.onChange}
										placeholder='Date Paid'
										readonly={isReadonly}
										value={
											typeof props.receipt.datePaid !== 'undefined'
												? props.receipt.datePaid
												: props.receipt.date
										}
									/>
									{!isReadonly && (
										<DatePaidButton
											appointmentDate={props.correspondingAppointment?.start}
											datePaidMode={datePaidMode}
											onClick={onDatePaidButtonClick}
										/>
									)}
								</Segment>
							)}

							<TextArea
								label='Details'
								name='details'
								onChange={props.onChange}
								placeholder='Additional Details'
								readonly={isReadonly}
								value={props.receipt.details}
							/>
						</>
					)}
				</>
			)}
		</Form>
	)
}

export { ReceiptForm }
