import React from 'react'
import { Card, ContainerLayout, H1, Text } from 'components/ui'

export const FailedToAuth = () => {
  return (
    <ContainerLayout>
      <H1>500 - Server Error</H1>
      <Card hideTitle>
        <Text>
          Sorry, the service is temporarily unavailable. We have been notified,
          please try again later.
        </Text>
      </Card>
    </ContainerLayout>
  )
}
