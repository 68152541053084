import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getSelectedClient$ } from 'containers/clients'
import { Card, Form, Text, Label, Divider } from 'components/ui'
import { Input, PhoneNumber, TextArea, DatePicker } from 'components/forms'
import { MobileBreakPointSmall } from 'styles'
import { getCurrentTreatmentForClient$ } from 'containers/treatment/selectors'
import { Client as ClientEndpoints } from 'constants/endpoints'
import { DEFAULT_EVENT_COLOUR } from 'constants/calendar-colours'
import { getClientTypeBySelectedClient } from 'containers/settings'
import { IClient } from 'interfaces'
import { Form_T } from 'containers/settings/form-types/new-interfaces'

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: 15em 15em 15em;
  grid-template-rows: auto;
  grid-template-areas: 'one two three';
  grid-column-gap: 1em;
  /* max-width: 43.75em; */

  @media (max-width: ${MobileBreakPointSmall}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'one'
      'two'
      'three';
  }
`

interface Props {
  readonly?: boolean
}

export const BasicClientSummary = ({ readonly }: Props) => {
  const clientData: IClient = useSelector(getSelectedClient$)
  const currentTreatment = useSelector(getCurrentTreatmentForClient$)
  const clientType: Form_T = useSelector(getClientTypeBySelectedClient)
  const [scratchNotes, setScratchNotes] = useState(clientData.scratchNotes)

  return (
    <Card hideTitle>
      <Form>
        <SummaryGrid>
          <PhoneNumber
            label="Phone Numbers"
            name="phoneNumbers"
            onChange={() => {}}
            readonly
            style={{ gridArea: 'one' }}
            value={clientData.phoneNumbers}
          />
          <Input
            label="Email"
            name="email"
            onChange={() => {}}
            readonly
            style={{ gridArea: 'two' }}
            value={clientData.email}
          />
          <DatePicker
            format="MMMM D YYYY"
            label="Date of Birth"
            name="dob"
            onChange={() => {}}
            readonly
            style={{ gridArea: 'three' }}
            value={clientData.dateOfBirth}
          />
        </SummaryGrid>
      </Form>

      <Label>Client Type</Label>
      <div style={{ display: 'flex' }}>
        <Text>{clientType.name}</Text>
        <div
          style={{
            borderRadius: '50%',
            width: '1em',
            height: '1em',
            backgroundColor:
              clientType.appointmentColour || DEFAULT_EVENT_COLOUR,
            marginLeft: '1em',
            marginTop: '0.25em',
          }}
        />
      </div>

      <Divider style={{ margin: 0 }} />

      <div>
        {(currentTreatment.treatmentOpenMessage ||
          currentTreatment.treatmentCloseMessage) && (
          <Label>Current Case</Label>
        )}
        {currentTreatment.treatmentOpenMessage && (
          <Text>{currentTreatment.treatmentOpenMessage}</Text>
        )}
        {currentTreatment.treatmentCloseMessage && (
          <Text>{currentTreatment.treatmentCloseMessage}</Text>
        )}

        <Divider hidden />

        <TextArea
          autoSave={{
            autosaveMode: 'onBlur',
            entityID: clientData.id,
            patchEndpoint: ClientEndpoints.Patch,
          }}
          label="Scratch Pad"
          name="scratchNotes"
          onChange={({ target }) => setScratchNotes(target.value)}
          placeholder="Random Notes"
          readonly={readonly}
          value={scratchNotes}
        />
      </div>
    </Card>
  )
}
