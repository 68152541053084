import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { PRACTICE_TYPE } from 'constants/settings'
import { Grid, Tab } from 'components/ui'

import BasicSettings from './components/basic-settings'
import MassageSettings from './components/massage-settings'
import CalendarSettings from './components/calendar-settings'
import { EmailSettings } from './components/email-settings'
import { Dispatch, IState } from 'interfaces'
import { InvoiceSettings } from 'containers/receipts/settings/invoice-settings'
import { ServiceSettings } from 'containers/services'
import { setNavBarText } from 'components/nav'
import { PhoneSettings } from './components/phone-settings'

const TABS = [
	'basic-settings',
	'service-settings',
	'calendar-settings',
	'email-settings',
	'phone-settings',
	'invoice-settings',
]

const NAV_BAR = [
	'Basic Settings',
	'Service Settings',
	'Calendar Settings',
	'Email Settings',
	'Phone Settings',
	'Invoice Settings',
]

interface Props {
	// practiceType: string
	tab: string
}

const Settings = (props: Props) => {
	const dispatch: Dispatch = useDispatch()
	const practiceType = useSelector(
		(state: IState) => state.settings[PRACTICE_TYPE],
	)
	const navigate = useNavigate()

	useEffect(() => {
		const index = TABS.findIndex((tab) => tab === props.tab)
		dispatch(setNavBarText(NAV_BAR[index]))
	}, [dispatch, props.tab])

	const panes = [
		{
			menuItem: {
				key: 'Basic',
				icon: 'setting',
				content: 'Basic',
			},
			render: () => (
				<Grid>
					<Grid.Row>
						<BasicSettings />
					</Grid.Row>
					<Grid.Row>
						{practiceType === 'massage' ? <MassageSettings /> : null}
					</Grid.Row>
				</Grid>
			),
		},
		{
			menuItem: {
				key: 'Services',
				icon: 'wrench',
				content: 'Services',
			},
			render: () => <ServiceSettings />,
		},
		{
			menuItem: {
				key: 'Calendar',
				icon: 'calendar',
				content: 'Calendar',
			},
			render: () => <CalendarSettings />,
		},
		{
			menuItem: {
				key: 'Email',
				icon: 'mail',
				content: 'Email',
			},
			render: () => <EmailSettings />,
		},
		{
			menuItem: {
				key: 'phone',
				icon: 'phone',
				content: 'SMS',
			},
			render: () => <PhoneSettings />,
		},
		{
			menuItem: {
				key: 'Invoices',
				icon: 'print',
				content: 'Invoices',
			},
			render: () => <InvoiceSettings />,
		},
	]

	return (
		<Tab
			activeIndex={TABS.findIndex((tab) => tab === props.tab)}
			menu={{ secondary: true, pointing: true, attached: false }}
			onTabChange={(_, { activeIndex }) => {
				navigate(`/account/settings/${TABS[activeIndex]}`)
			}}
			panes={panes}
			size='large'
		/>
	)
}

Settings.defaultProps = {
	practiceType: '',
}

export default Settings
