import { TableRenderer } from 'components/ui'
import { Receipt } from '../interfaces'
import { ReceiptItem } from '../redux/receipt-item'

interface Props {
  receipts: Receipt[]
  fetching?: boolean
  onReceiptItemClick: (receipt: Receipt) => void
  paganation?: {
    page: number
    pageSize?: number
    onPageChange: (page: number) => void
    totalReceipts: number
  }
}

const DEFAULT_PAGE_SIZE = 10

export const ReceiptTable = (props: Props) => {
  const paginationProps = props.paganation
    ? {
        activePage: props.paganation.page,
        onPageChange: props.paganation.onPageChange,
        pageSize: props.paganation.pageSize || DEFAULT_PAGE_SIZE,
        totalItems: props.paganation.totalReceipts || props.receipts.length,
        usePagination: true,
      }
    : null

  return (
    <TableRenderer
      headers={['Client', 'Invoice Date', 'Service', 'Paid', 'Amount']}
      items={props.receipts}
      itemType="Invoices"
      loading={props.fetching}
      noItemsMessage="No Invoices"
      pagination={paginationProps}
      renderer={(receipt: Receipt) => (
        <ReceiptItem onClick={props.onReceiptItemClick} receipt={receipt} />
      )}
    />
  )
}
