import React from 'react'
import { Button } from 'components/ui'
import styled from 'styled-components'

const AddButtonContainer = styled.div`
  display: flex;
  padding-left: 0.5em;
`

const BothButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

interface ButtonProps {
  onClick: Function
  disabled: boolean
}

const AddButton = ({ onClick, disabled }: ButtonProps) => (
  <AddButtonContainer>
    <Button
      disabled={disabled}
      icon="plus"
      onClick={onClick}
      primary
      shape="circle"
      size="small"
    />
  </AddButtonContainer>
)

const RemoveButton = ({ onClick, disabled }: ButtonProps) => (
  <AddButtonContainer>
    <Button
      disabled={disabled}
      icon="minus"
      onClick={onClick}
      primary
      size="small"
    />
  </AddButtonContainer>
)

interface AddOrRemoveButtonProps {
  add: Function
  disabled?: boolean
  isFirst: boolean
  isRemove: boolean
  noRemove?: boolean
  remove: Function
}
const AddOrRemoveButton = ({
  add,
  disabled = false,
  isFirst = false,
  isRemove = false,
  noRemove = false,
  remove,
}: AddOrRemoveButtonProps) =>
  isRemove ? (
    <RemoveButton disabled={disabled} onClick={remove} />
  ) : (
    <BothButtonsContainer>
      <AddButton disabled={disabled} onClick={add} />
      {!isFirst && !noRemove && (
        <RemoveButton disabled={disabled} onClick={remove} />
      )}
    </BothButtonsContainer>
  )

export default AddOrRemoveButton
