import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	Button,
	ButtonContainer,
	FinePrint,
	Form,
	Header,
	Modal,
} from 'components/ui'
import { Input, InputArea, InputNumber } from 'components/forms'
import { formatAmount } from 'lib'
import { Dispatch, IEvent, IResponse, IState } from 'interfaces'

import { Service } from '../interfaces'
import { postService } from '../redux/post-service'
import { deleteService } from '../redux/delete-service'
import { ConfirmCancelModal } from 'components/modals'
import { TaxList } from 'containers/taxes'

interface Props {
	open: boolean
	service: Service
	onClose: () => void
}

export const ServiceModal = (props: Props) => {
	const dispatch: Dispatch = useDispatch()
	const [isDirty, setIsDirty] = useState(false)
	const [serviceData, setServiceData] = useState({ duration: '1h' } as Service)
	const [confirmOpen, setConfirmOpen] = useState(false)
	const onCloseConfirm = () => {
		setConfirmOpen(false)
	}
	const onOpenConfirm = () => {
		setConfirmOpen(true)
	}

	const posting = useSelector((state: IState) => state.services.flags.posting)
	const deleting = useSelector((state: IState) => state.services.flags.deleting)

	const { service } = props
	useEffect(() => {
		if (service?.id) {
			setServiceData({ ...service })
		}
	}, [service])

	const { onClose } = props

	const onCloseCallback = useCallback(() => {
		setServiceData({ duration: '1h' } as Service)
		onClose()
	}, [onClose])

	const onSubmit = useCallback(() => {
		dispatch(postService(serviceData)).then((response: IResponse<Service>) => {
			if (response.status === 200) {
				onCloseCallback()
			}
		})
	}, [dispatch, onCloseCallback, serviceData])

	const id = service?.id
	const onDelete = useCallback(() => {
		dispatch(deleteService(id)).then((response: IResponse<any>) => {
			if (response.status === 204) {
				onCloseCallback()
			}
		})
	}, [dispatch, onCloseCallback, id])

	if (!props.open) {
		return null
	}

	const disabled =
		!serviceData.name ||
		!serviceData.cost ||
		!serviceData.description ||
		!serviceData.duration

	const onChange = ({ target }: IEvent) => {
		setIsDirty(true)
		setServiceData((prev) => ({ ...prev, [target.name]: target.value }))
	}

	const onCostBlur = ({ target }: IEvent) => {
		if (!target.value) {
			return
		}

		target.value = formatAmount(target.value)
		onChange({ target })
	}

	return (
		<>
			<ConfirmCancelModal
				message='Delete this Service?'
				modalOpen={confirmOpen}
				onAccept={onDelete}
				onCancel={onCloseConfirm}
			/>

			<Modal open={props.open}>
				<Modal.Header icon='wrench'>
					<Header icon='wrench'>
						{props.service.id ? 'Edit Service' : 'New Service'}
					</Header>
				</Modal.Header>

				<Modal.Content>
					<Form>
						<Input
							label='Service Name'
							name='name'
							onChange={onChange}
							validationRules={{ required: true }}
							value={serviceData.name}
						/>

						<InputNumber
							inputLabel={{
								basic: true,
								content: '$',
							}}
							label='Price'
							name='cost'
							onBlur={onCostBlur}
							onChange={onChange}
							validationRules={{ required: true }}
							value={serviceData.cost}
						/>

						<TaxList
							amount={Number(serviceData.cost)}
							name='taxIDs'
							onChange={onChange}
							taxIDs={serviceData.taxIDs}
						/>

						<InputArea
							label='Description'
							name='description'
							onChange={onChange}
							placeholder='A short description about the service'
							validationRules={{ required: true }}
							value={serviceData.description}
						/>

						<Input
							label='Duration'
							name='duration'
							onChange={onChange}
							validationRules={{ required: true }}
							value={serviceData.duration}
						/>
						<FinePrint>Format example: 1h45m</FinePrint>

						<Input
							label='Client Duration'
							name='clientDuration'
							onChange={onChange}
							placeholder='Optional duration visible to clients'
							value={serviceData.clientDuration}
						/>
					</Form>
				</Modal.Content>

				<Modal.Actions>
					<ButtonContainer>
						{service.id && (
							<Button
								content='Delete'
								icon='trash'
								loading={deleting}
								negative
								onClick={onOpenConfirm}
							/>
						)}

						<div>
							<Button
								content={isDirty ? 'Cancel' : 'Close'}
								icon='arrow alternate circle left'
								onClick={onCloseCallback}
								secondary
							/>
							<Button
								content='Save'
								disabled={disabled}
								icon='checkmark'
								loading={posting}
								onClick={onSubmit}
								primary
							/>
						</div>
					</ButtonContainer>
				</Modal.Actions>
			</Modal>
		</>
	)
}
