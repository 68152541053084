import React from 'react'
import { Dropdown as SemanticDropdown } from 'semantic-ui-react'
import styled from 'styled-components'

import { Font, ReadonlyFormFont } from 'styles/font'
import { Text } from './text'
import { Print } from './interfaces'

const D = styled(SemanticDropdown)`
  &&&&& {
    ${Font};

    width: 100%;
    color: ${(props) => props.theme.TextColour} !important;
    background-color: ${(props) => props.theme.BackgroundColour} !important;
    border: 1px solid ${(props) => props.theme.InputBorderColour} !important;

    .visible.menu.transition {
      background-color: ${(props) => props.theme.BackgroundColour} !important;
      border-color: ${(props) => props.theme.InputBorderColour} !important;

      span {
        color: ${(props) => props.theme.TextColour} !important;
        border-color: ${(props) => props.theme.InputBorderColour} !important;
      }
    }

    .selected.item {
      background: ${(props) => props.theme.ListItemActiveColour};
    }

    .menu > .item {
      :hover {
        background: ${(props) => props.theme.ListItemHoverColour};
      }
    }

    > input.search {
      color: ${(props) => props.theme.TextColour} !important;
      background-color: ${(props) => props.theme.BackgroundColour} !important;

      :focus {
        border-color: ${(props) =>
          props.theme.InputBorderColourFocused} !important;
        box-shadow: 0 0 4px ${(props) => props.theme.InputBorderColourFocused} !important;
      }
    }
  }
`

const getReadonlyText = (options: Array<any>, value: string) => {
  const option = options.find((o) => o.id === value || o.value === value)
  return option ? option.text : ' '
}

const allFilter = (o: any) =>
  o.id === 'All' || o.id === 'all' || o === 'All' || o === 'all'
const allNotFilter = (o: any) =>
  o.id !== 'All' && o.id !== 'all' && o !== 'All' && o !== 'all'

const filterOptionsIfMultiple = (
  options: string[],
  value: string[],
): string[] => {
  if (!value) {
    return options
  }

  if (typeof value === 'string') {
    value = [value]
  }

  if (options.find(allFilter)) {
    const cantBeAll =
      value?.length >= 1 && !(value as unknown as string[])?.find(allFilter)

    if (cantBeAll) {
      options = options.filter(allNotFilter)
    }
  }

  return options
}

const Dropdown = ({ readonly, ...props }: DropdownProps) => {
  let value = props.value
  let options = props.options

  if (props.multiple && typeof value === 'string') {
    try {
      value = JSON.parse(props.value)
    } catch {
      value = props.value
    }
  }

  // Only if All is an option
  if (props.multiple) {
    options = filterOptionsIfMultiple(
      props.options,
      props.value as unknown as string[],
    )
  }

  // TODO - ????? why is " being added here???
  if (value?.includes && value?.includes('"')) {
    value = value?.replaceAll('"', '')
  }

  const onBlur = (_, { value }) => {
    if (props.multiple) {
      if (value.length > 1) {
        const option = props.options.find((o) => o.id === value)

        if (
          option &&
          (option.id === 'all' ||
            option.id === 'All' ||
            option.text === 'all' ||
            option.text === 'All')
        ) {
          value = value.filter(allNotFilter)
        }
      }
    }

    return props.onChange({ target: { name: props.name, value } })
  }

  const onChange = (_, { value }) => {
    props.onChange({ target: { name: props.name, value } })
  }

  const render = () => {
    if (props.printParams?.printingView) {
      if (Array.isArray(value) && value.length > 0) {
        return <Text style={{ marginRight: '1em' }}>{value.join(', ')}</Text>
      } else if (!Array.isArray(value) && value) {
        return <Text style={{ marginRight: '1em' }}>{value}</Text>
      } else {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {options.map((option, i) => (
              <Text key={i} style={{ marginRight: '1em' }}>
                {option.text}
              </Text>
            ))}
          </div>
        )
      }
    }

    if (readonly) {
      return (
        <ReadonlyFormFont>
          {getReadonlyText(options, props.value)}
        </ReadonlyFormFont>
      )
    }

    return (
      <D
        {...props}
        // defaultValue={props.defaultValue}
        name={props.name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        search={props.search}
        searchInput={{ autoFocus: props.autoFocus }}
        selection={props.selection}
        value={value}
      />
    )
  }

  return render()
}

interface DropdownProps {
  autoFocus: boolean
  button?: boolean
  className?: string
  clearable?: boolean
  defaultValue?: string
  disabled?: boolean
  floating?: boolean
  icon?: string
  loading?: boolean
  multiple?: boolean
  name?: string
  onBlur?: Function
  onChange: Function
  options: Array<any>
  placeholder?: string
  printParams?: Print
  readonly: boolean
  search: boolean
  selection: boolean
  style?: any
  value?: any | string[]
}

Dropdown.defaultProps = {
  autoFocus: false,
  readonly: false,
  search: true,
  selection: true,
}

export default Dropdown
