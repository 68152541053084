import { createSelector } from 'reselect'

import { getClientTypes$ } from 'containers/settings/selectors'
import { getSelectedClient$ } from 'containers/clients/redux/selectors'
import { IState } from 'interfaces'
import { Form_T } from 'containers/settings/form-types/new-interfaces'
import { CLIENT_INFO_TAB_ID } from 'constants/form'

const getForms = (state: IState) => state.forms

/**
 * Selector to return all Form Templates as an ID indexed Object
 * @param state
 * @returns An Object consisting of all Form Templates
 */
export const getFormTemplates$ = (state: IState) => state.forms.templates
export const getAccountForms = (state: IState) => state.account.forms || []

export const getFormData$ = (formID: string) =>
	createSelector([getForms], (forms) => forms[formID]) // TODO - this isn't working

export const getFormTemplate$ = (formID: string) =>
	createSelector([getFormTemplates$], (templates) => templates[formID] || {})

export const getCurrentForms = createSelector(
	[getAccountForms],
	(forms) => forms.filter((f) => !f.isDeleted) || [],
)

export const getCurrentFormsForDropdown = createSelector(
	[getAccountForms],
	(forms) =>
		forms.map((f) => ({
			key: f.id,
			id: f.id,
			value: f.id,
			text: f.isDeleted ? `${f.name} (deleted)` : f.name,
			deleted: f.isDeleted,
		})) || [],
)

export const getSoapFormsForDropdown = createSelector(
	[getAccountForms],
	(forms) =>
		forms
			.filter((f) => f.tab === 'notes' || f.tab === 'any')
			.map((f) => ({
				key: f.id,
				id: f.id,
				value: f.id,
				text: f.isDeleted ? `${f.name} (deleted)` : f.name,
				deleted: f.isDeleted,
			})) || [],
)

const getClientTypeForSelectedClient = createSelector(
	[getClientTypes$, getSelectedClient$],
	(clientTypes, client) => {
		const type: Form_T = clientTypes.find(
			(ct: Form_T) => ct.id === client.clientTypeID,
		)

		return type
	},
)

export const getFormsForClient = createSelector(
	[getFormTemplates$, getClientTypeForSelectedClient],
	(templates, clientType) => {
		const forms = []

		const clientInfoForms = clientType?.formTabs?.find(
			(ft) => ft.id === CLIENT_INFO_TAB_ID,
		)?.forms

		if (!clientInfoForms) {
			return forms
		}

		for (const form of clientInfoForms) {
			forms.push(templates[form.formID])
		}

		return forms.sort((a, b) => a.order - b.order)
	},
)
