import { createSelector } from 'reselect'
import { getSelectedClientID$ } from 'containers/clients/redux/selectors'
import moment from 'moment'
import { IState } from 'interfaces'

const getPublicIntakes = (state: IState) => state.publicIntake

export const getPublicIntakesForClient = createSelector(
  [getPublicIntakes, getSelectedClientID$],
  (publicIntake, clientID) => {
    if (publicIntake) {
      const obj = publicIntake[clientID] || {}

      return obj.publicIntakeForms
        ? obj.publicIntakeForms.sort((a: any, b: any) =>
            a.completed
              ? Number(moment(b.expires).format('X')) -
                Number(moment(a.expires).format('X'))
              : -1,
          )
        : []
    }

    return []
  },
)

export const getPublicIntakeObjectForClient = createSelector(
  [getPublicIntakes, getSelectedClientID$],
  (publicIntake, clientID) => {
    if (publicIntake) {
      return publicIntake[clientID] || {}
    }

    return {}
  },
)

export const hasUncompletedPublicForms = createSelector(
  [getPublicIntakesForClient],
  (forms) =>
    !!forms.find(
      (f: any) => f.completed === false && moment(f.expires) > moment(),
    ),
)
