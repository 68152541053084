import moment from 'moment'
import { filter } from 'lodash'
import { message } from 'components/notifications'

import { reducer as fetchSoapNotes } from './fetch-soaps'
import { reducer as fetchSoapByAppointment } from './fetch-soap-by-appointment'
import {
	reducer as saveSoap,
	defaultState as saveSoapDefaultState,
} from './save-soap'
import { IAction, Note, SoapNotesState } from 'interfaces'

const reducers = [fetchSoapNotes, saveSoap, fetchSoapByAppointment]

const soapsAction = (action: string) => `client-trackyr/soap-notes/${action}`
const ON_CHANGED = soapsAction('ON_CHANGED')
const ON_ADD_APPOINTMENT = soapsAction('ON_ADD_APPOINTMENT')
const ON_CANCEL = soapsAction('ON_CANCEL')
const ON_SOAP_PAGE_CHANGE = soapsAction('ON_SOAP_PAGE_CHANGE')
const ON_SOAP_SEARCH = soapsAction('ON_SOAP_SEARCH')
const SET_SOAP_DIRTY = soapsAction('SET_SOAP_DIRTY')
const ON_VALIDATE_FORM = soapsAction('ON_VALIDATE_FORM')
const UNSET_SELECTED_SOAP = soapsAction('UNSET_SELECTED_SOAP')
const UPDATE_TOTAL_SOAP_APPOINTMENTS_COUNT = soapsAction('UPDATE_TOTAL_SOAP_APPOINTMENTS_COUNT')
const COLLAPSE_NOTES_APPOINTMENTS_VIEW = soapsAction('COLLAPSE_NOTES_APPOINTMENTS_VIEW')
const SET_NOTE_DATA = soapsAction('SET_NOTE_DATA')
const SET_FETCHING_NOTE = soapsAction('SET_FETCHING_NOTE')
const SET_FETCHING_PREVIOUS_NOTE = soapsAction('SET_FETCHING_PREVIOUS_NOTE')

export const defaultState = {
	...saveSoapDefaultState,
	changesMade: false,
	flags: {
		fetchingNote: false,
		fetchingPreviousNote: false,
	},
	fetchedPages: {},
	fetching: false,
	fetchSoapsFailure: '',
	loading: false,
	soapFilteringLoading: false,
	soapPage: 1,
	soaps: {},
	soapSearchTerm: '',
	totalSoaps: {},
	notesView: {},
} as SoapNotesState

const newSoap = {
	appointment: {
		scheduledStart: moment().format(),
	},
}

export const reducer = (
	state: SoapNotesState = defaultState,
	action: IAction,
) => {
	switch (action.type) {
		case UNSET_SELECTED_SOAP:
			return {
				...state,
				selectedSoap: '',
			}

		case ON_CHANGED: {
			const selectedSoap = action.selectedSoap
			const selectedClient = action.clientID

			const soapsAtSelectedClient = state.soaps[selectedClient] || {}
			const selectedSoapAtSelectedClient =
				soapsAtSelectedClient[selectedSoap] || {}

			return {
				...state,
				soaps: {
					...state.soaps,
					[selectedClient]: {
						...soapsAtSelectedClient,
						[selectedSoap]: {
							...selectedSoapAtSelectedClient,
							[action.name]: action.value,
							isDirty: true,
							appointmentID: selectedSoap,
						},
					},
				},
				changesMade: true,
				dirtySoap: selectedSoap,
			}
		}

		case ON_ADD_APPOINTMENT: {
			const clientID = action.clientID
			const soaps = state.soaps
			const clientSoaps = soaps[clientID]

			const newSoaps = filter(clientSoaps, (soap: Note) => soap.isNew)

			if (newSoaps.length) {
				// TODO - maybe have multiple new soaps with tempID's? Then map those back to what
				// the server returns
				// - that or on new soap saves immediately, will have to see how this works with
				// offline first
				message.warning('Save your new soap before you create another one')
				return { ...state }
			}

			clientSoaps['0'] = {
				...newSoap,
				id: '0',
				appointment: newSoap.appointment,
				soapTypeID: action.soapTypeID,
				appointmentDate: newSoap.appointment.scheduledStart,
				isDirty: true,
				isNew: true,
				bodyDiagramID: action.currentBodyDiagram.id,
			}

			return {
				...state,
				soaps: { ...soaps },
				changesMade: true,
				dirtySoap: '0',
			}
		}

		case ON_CANCEL:
			return {
				...state,
				// soaps: state.initialSoaps.map((initial) => ({ ...initial })),
			}

		case ON_SOAP_PAGE_CHANGE:
			return {
				...state,
				soapPage: action.page,
			}

		case ON_SOAP_SEARCH:
			return {
				...state,
				soapSearchTerm: action.search,
			}

		case SET_SOAP_DIRTY: {
			const selectedSoap = action.soapID
			const selectedClient = action.clientID

			const soapsAtSelectedClient = state.soaps[selectedClient] || {}
			const selectedSoapAtSelectedClient =
				soapsAtSelectedClient[selectedSoap] || {}

			return {
				...state,
				soaps: {
					...state.soaps,
					[selectedClient]: {
						...soapsAtSelectedClient,
						[selectedSoap]: {
							...selectedSoapAtSelectedClient,
							isDirty: true,
							appointmentID: selectedSoap,
						},
					},
				},
				changesMade: true,
				dirtySoap: selectedSoap,
			}
		}

		case ON_VALIDATE_FORM: {
			const selectedSoap = state.selectedSoap
			const selectedClient = action.clientID
			const clientSoaps = state.soaps[selectedClient]

			if (!clientSoaps) {
				return {
					...state,
				}
			}

			const soap = clientSoaps[selectedSoap] || {}

			soap[`${action.name}-validation`] = action.validation
			soap.isDirty = true

			const soaps = state.soaps
			soaps[selectedClient] = clientSoaps

			return {
				...state,
				selectedSoap,
				soaps: { ...soaps },
				changesMade: true,
			}
		}

		case UPDATE_TOTAL_SOAP_APPOINTMENTS_COUNT:
			return {
				...state,
				totalSoaps: {
					...state.totalSoaps,
					[action.clientID]: state.totalSoaps[action.clientID] + 1,
				},
			}

		case COLLAPSE_NOTES_APPOINTMENTS_VIEW:
			return {
				...state,
				notesView: {
					...state.notesView,
					[action.noteID]: !state.notesView[action.noteID],
				},
			}

		case SET_NOTE_DATA: {
			const clientID = action.data.clientID
			const appointmentID = action.data.appointmentID

			return {
				...state,
				soaps: {
					...state.soaps,
					[clientID]: {
						...state.soaps[clientID],
						[appointmentID]: {
							...action.data,
							isDirty: true,
						},
					},
				},
				changesMade: true,
				dirtySoap: appointmentID,
			}
		}

		case SET_FETCHING_NOTE: {
			return {
				...state,
				flags: {
					...state.flags,
					fetchingNote: action.data,
				},
			}
		}

		case SET_FETCHING_PREVIOUS_NOTE: {
			return {
				...state,
				flags: {
					...state.flags,
					fetchingPreviousNote: action.data,
				},
			}
		}

		default:
			break
	}

	return reducers.reduce((s, r) => r(s, action), state)
}

export const unsetSelectedSoap = () => ({
	type: UNSET_SELECTED_SOAP,
})

export const onChange = (
	formID: string,
	name: string,
	clientID: string,
	selectedSoap: any,
	value: any,
) => ({
	type: ON_CHANGED,
	formID,
	name,
	selectedSoap,
	clientID,
	value,
})

export const onCancel = () => ({ type: ON_CANCEL })

// export const onAddSoap = (clientID, currentBodyDiagram, soapTypeID) => ({
//   type: ON_ADD_APPOINTMENT,
//   clientID,
//   currentBodyDiagram,
//   soapTypeID,
// })

export const onSoapPageChange = (page: number) => ({
	type: ON_SOAP_PAGE_CHANGE,
	page,
})

export const onSoapsSearch = (search: string) => ({
	type: ON_SOAP_SEARCH,
	search,
})

export const setSoapDirty = (soapID: string, clientID: string) => ({
	type: SET_SOAP_DIRTY,
	soapID,
	clientID,
})

export const validateForm = (
	formID: string,
	name: string,
	clientID: string,
	validation: any,
) => ({
	type: ON_VALIDATE_FORM,
	formID,
	name,
	clientID,
	validation,
})

export const updateTotalSoapAppointmentsCount = (clientID: string) => ({
	type: UPDATE_TOTAL_SOAP_APPOINTMENTS_COUNT,
	clientID,
})

export const onCollapseNotesAppointmentView = (noteID: string) => ({
	type: COLLAPSE_NOTES_APPOINTMENTS_VIEW,
	noteID,
})

export const setNoteData = (noteData: Note) => ({
	type: SET_NOTE_DATA,
	data: noteData,
})

export const setFetchingNote = (fetchingState: boolean) => ({
	type: SET_FETCHING_NOTE,
	data: fetchingState,
})

export const setFetchingPreviousNote = (fetchingState: boolean) => ({
	type: SET_FETCHING_PREVIOUS_NOTE,
	data: fetchingState,
})