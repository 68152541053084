import React from 'react'
import { connect } from 'react-redux'

import {
  Card,
  Form,
  FormField,
  Label,
  Dropdown,
  Checkbox,
  RadioGroup,
  RadioButton,
} from 'components/ui'
import {
  onCalendarSlotDurationChange,
  onCalendarDefaultAppointmentLengthChange,
  onSettingChanged,
} from '../redux/ducks'
import { saveSettings } from 'containers/settings'
import {
  CALENDAR_SLOT_DURATION,
  CALENDAR_DEFAULT_APPOINTMENT_DURATION,
  getDefaultSettings,
  CALENDAR_SHOW_WEEKENDS,
  CALENDAR_DEFAULT_VIEW,
  CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW,
} from 'constants/settings'
import Setting from './setting'
import { getFirebaseUserID$, getCTUserID$ } from 'containers/account'
import {
  getCalendarSlotDurationSetting,
  getCalendarDefaultAppointmentDuration,
} from 'containers/settings'
import { Dispatch, IState, Setting as ISetting } from 'interfaces'
import { getSetting } from '../selectors'

const SLOTS = [
  { key: 15, id: 15, text: '15 Minutes', value: '00:15:00' },
  { key: 30, id: 30, text: '30 Minutes', value: '00:30:00' },
]

const APPOINTMENT_LENGTHS = [
  { key: 15, id: 15, text: '15 Minutes', value: '15' },
  { key: 30, id: 30, text: '30 Minutes', value: '30' },
  { key: 45, id: 45, text: '45 Minutes', value: '45' },
  { key: 60, id: 60, text: '1 Hour', value: '60' },
  { key: 90, id: 90, text: '1 Hour 30 Minutes', value: '90' },
  { key: 120, id: 120, text: '2 Hours', value: '120' },
]

interface Props {
  calendarDefaultAppointmentDuration: string
  calendarDefaultView: string
  calendarSlotDurationSetting: string
  calendarDefaultCreateView: string
  onCalendarDefaultAppointmentLengthChange: Function
  onCalendarSlotDurationChange: Function
  onSettingChanged: Function
  saveSettings: Function
  showWeekends: string
}

class CalendarSettings extends React.Component<Props> {
  onSettingChange = ({ target }) =>
    this.props.saveSettings({
      setting: target.name,
      value: target.value,
    })

  onCalendarDefaultAppointmentLengthChange = ({ target }) => {
    this.props.onCalendarDefaultAppointmentLengthChange({ target })

    if (target.value === '15') {
      this.props.onCalendarSlotDurationChange({ target: { value: '00:15:00' } })
      this.onSettingChange({
        target: { name: CALENDAR_SLOT_DURATION, value: '00:15:00' },
      })
    }
  }

  render() {
    const {
      calendarSlotDurationSetting = getDefaultSettings(CALENDAR_SLOT_DURATION),
      calendarDefaultAppointmentDuration = getDefaultSettings(
        CALENDAR_DEFAULT_APPOINTMENT_DURATION,
      ),
    } = this.props

    return (
      <>
        <Card title="Calendar and Appointment Settings">
          <Form>
            <FormField>
              <Label>Calendar Slot Duration</Label>
              <Setting patch={(e) => this.onSettingChange(e)}>
                <Dropdown
                  disabled={calendarDefaultAppointmentDuration === '15'}
                  name={CALENDAR_SLOT_DURATION}
                  onChange={this.props.onCalendarSlotDurationChange}
                  options={SLOTS.map((option) => ({
                    key: option.id,
                    id: option.id,
                    text: option.text,
                    value: option.value,
                  }))}
                  search
                  selection
                  style={{ width: '100%', maxWidth: '25em', height: ' 2.7em' }}
                  value={calendarSlotDurationSetting}
                />
              </Setting>
            </FormField>

            <FormField>
              <Label>Calendar Default Appointment Length</Label>
              <Setting patch={(e) => this.onSettingChange(e)}>
                <Dropdown
                  name={CALENDAR_DEFAULT_APPOINTMENT_DURATION}
                  onChange={this.onCalendarDefaultAppointmentLengthChange}
                  options={APPOINTMENT_LENGTHS.map((option) => ({
                    key: option.id,
                    id: option.id,
                    text: option.text,
                    value: option.value,
                  }))}
                  search
                  selection
                  style={{ width: '100%', maxWidth: '25em', height: ' 2.7em' }}
                  value={calendarDefaultAppointmentDuration}
                />
              </Setting>
            </FormField>

            <FormField>
              <Setting patch={(e) => this.onSettingChange(e)}>
                <Checkbox
                  checked={this.props.showWeekends === 'true'}
                  label="Show Weekends on Calendar"
                  name={CALENDAR_SHOW_WEEKENDS}
                  onChange={this.onSettingChange}
                  toggle
                />
              </Setting>
            </FormField>

            <FormField>
              <Label>Default Calendar View</Label>
              <Setting patch={this.onSettingChange}>
                <RadioGroup
                  defaultValue="Month"
                  name={CALENDAR_DEFAULT_VIEW}
                  onChange={this.props.onSettingChanged}
                  value={this.props.calendarDefaultView}
                >
                  <RadioButton value="dayGridMonth">Month</RadioButton>
                  <RadioButton value="timeGridWeek">Week</RadioButton>
                </RadioGroup>
              </Setting>
            </FormField>

            <FormField>
              <Label>Default Calendar New Appointment View</Label>
              <Setting patch={this.onSettingChange}>
                <RadioGroup
                  defaultValue="Day"
                  name={CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW}
                  onChange={this.props.onSettingChanged}
                  value={this.props.calendarDefaultCreateView}
                >
                  <RadioButton value="timeGridDay">Day</RadioButton>
                  <RadioButton value="timeGridWeek">Week</RadioButton>
                </RadioGroup>
              </Setting>
            </FormField>
          </Form>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  calendarSlotDurationSetting: getCalendarSlotDurationSetting(state),
  calendarDefaultAppointmentDuration:
    getCalendarDefaultAppointmentDuration(state),
  calendarDefaultView: getSetting(state, CALENDAR_DEFAULT_VIEW),
  calendarDefaultCreateView: getSetting(
    state,
    CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW,
  ),
  showWeekends: getSetting(state, CALENDAR_SHOW_WEEKENDS),
  userID: getCTUserID$(state),
  firebaseID: getFirebaseUserID$(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onCalendarSlotDurationChange: ({ target }) =>
    dispatch(onCalendarSlotDurationChange(target.value)),
  onCalendarDefaultAppointmentLengthChange: ({ target }) =>
    dispatch(onCalendarDefaultAppointmentLengthChange(target.value)),
  saveSettings: (setting: ISetting) => dispatch(saveSettings(setting)),
  onSettingChanged: (value: string, setting: string) =>
    dispatch(onSettingChanged(value, setting)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSettings)
