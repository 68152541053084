import React from 'react'

import {
  ActivableListItem,
  Avatar,
  H4,
  ListIcon,
  Icon,
  ListContent,
  ListHeader,
  ListDescription,
} from 'components/ui'
import { Tax } from '../interfaces'
import { formatTaxAmount } from 'lib'

interface Props {
  tax: Tax
  onClick: (tax: Tax) => void
}

export const TaxItem = ({ tax, onClick }: Props) => {
  const onItemClick = () => {
    onClick(tax)
  }

  return (
    <ActivableListItem key={tax.id} onClick={onItemClick}>
      <ListIcon>
        <Avatar size={'40px'}>
          <Icon name="dollar sign" size="big" style={{ margin: 'auto' }} />
        </Avatar>
      </ListIcon>

      <ListContent>
        <ListHeader>
          <H4>{`${tax.name}  - ${formatTaxAmount(tax.percentage)}%`}</H4>
        </ListHeader>

        <ListDescription>{tax.taxNumber}</ListDescription>
      </ListContent>
    </ActivableListItem>
  )
}
