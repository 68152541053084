import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Form, Modal, Header } from 'components/ui'
import { Input } from 'components/forms'
import {
	resendPublicIntakeFormEmail,
	clearSendingPublicIntakeEmailError,
} from 'containers/public-intake'
import { SubscriptionError } from 'containers/emails'
import { Dispatch } from '../../../interfaces'

export const ResendEmailModal = (props: ResendEmailModalProps) => {
	const dispatch: Dispatch = useDispatch()
	const [email, setEmail] = useState(props.publicIntakeForm.email)
	const [fetching, setFetching] = useState(false)
	const publicIntakeForm = props.publicIntakeForm

	const resendEmail = useCallback(
		(ignoreSubscription = false) => {
			setFetching(true)
			dispatch(
				resendPublicIntakeFormEmail(
					{
						...publicIntakeForm,
						email: email || props.email,
					},
					ignoreSubscription,
				),
			)
				.then(() => {
					setFetching(false)
					props.handleClose()
				})
				.catch(() => setFetching(false))
		},
		[dispatch, email, props, publicIntakeForm],
	)

	const handleClose = () => {
		dispatch(clearSendingPublicIntakeEmailError())
		props.handleClose()
	}

	return (
		<Modal onClose={handleClose} open={props.visible}>
			<Header
				content='Send Public Intake Form to Client'
				icon='list alternate outline'
			/>

			<Modal.Content>
				<Header>Resend Email</Header>
				<Form>
					<Input
						autoFocus
						label='Client Email'
						onChange={({ target }) => setEmail(target.value)}
						value={email || props.email}
					/>
				</Form>

				<SubscriptionError send={resendEmail} />
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={handleClose} secondary>
					Cancel
				</Button>
				<Button loading={fetching} onClick={() => resendEmail()} primary>
					Re-send Form
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

interface ResendEmailModalProps {
	email: string
	handleClose: Function
	publicIntakeForm: any
	visible: boolean
}
