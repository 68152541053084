import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { Dispatch, ErrorState, IAction, IResponse, IUser } from 'interfaces'

const accountAction = (action: string) => `client-trackyr/account/${action}`
const ON_SAVING_ACCOUNT = accountAction('ON_SAVE_ACCOUNT')
const ON_SAVING_ACCOUNT_SUCCESS = accountAction('ON_SAVING_ACCOUNT_SUCCESS')
const ON_SAVING_ACCOUNT_FAILURE = accountAction('ON_SAVING_ACCOUNT_FAILURE')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case ON_SAVING_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        changesMade: false,
      }

    case ON_SAVING_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case ON_SAVING_ACCOUNT:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}

const onSaving = () => ({ type: ON_SAVING_ACCOUNT })

const onSaveSuccess = (data: IUser) => ({
  type: ON_SAVING_ACCOUNT_SUCCESS,
  data,
})
const onSaveFailure = (error: ErrorState) => ({
  type: ON_SAVING_ACCOUNT_FAILURE,
  error,
})

export const onSave = (
  user: IUser,
  accountForm: any,
  showNotification: boolean,
) => async (dispatch: Dispatch) => {
  dispatch(onSaving())

  // TODO - reauthentication with user first! Set Email as seperate step
  // if (user.email !== accountForm.email) {
  //   user
  //     .updateEmail(accountForm.email)
  //     .then(function() {
  //       // Update successful.
  //     })
  //     .catch(function(error) {
  //       // An error happened.
  //     })
  // }

  // TODO - maybe useless?
  return user
    .updateProfile({
      photoURL: accountForm.avatar,
    })
    .then((response: IResponse<IUser>) => {
      showNotification && message.success('Account Saved')
      dispatch(onSaveSuccess(response.data))
    })
    .catch((error: ErrorState) => {
      showNotification &&
        message.error('Failed to Save Account, please try again')

      captureMessage(`onSave Account Failed. ${error}`, Severity.Error)

      dispatch(onSaveFailure(error))
    })
}
