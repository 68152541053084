import { authPost } from 'lib/http-request'
import { Account } from 'constants/endpoints'
import { parseOfficeHoursFromAPI } from 'lib/fetch-parsers'
import { captureMessage } from '@sentry/browser'
import { message } from 'components/notifications'
import { invalidateCache } from 'lib'
import { OFFICE_CACHE } from 'constants/cache'
import { IAction, Office, Dispatch, Error } from 'interfaces'
import moment from 'moment'
import { Severity } from 'interfaces'

const officeAction = (action: string) => `client-trackyr/offices/${action}`
const ON_SAVE_OFFICE = officeAction('ON_SAVE_OFFICE')
const ON_SAVE_OFFICE_SUCCESS = officeAction('ON_SAVE_OFFICE_SUCCESS')
const ON_SAVE_OFFICE_FAILURE = officeAction('ON_SAVE_OFFICE_FAILURE')

export const defaultState = {} as IAppointmentState

interface IAppointmentState {
  // TEMP - move to clinics interfaces/redux
  officesByID: Array<any>
  offices: Object
}

export const reducer = (
  state: IAppointmentState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case ON_SAVE_OFFICE:
      return {
        ...state,
        loading: true,
        changesMade: false,
      }

    case ON_SAVE_OFFICE_SUCCESS: {
      const officesByID = [...state.officesByID]
      officesByID.push(action.office.id)

      return {
        ...state,
        offices: {
          ...state.offices,
          [action.office.id]: {
            name: action.office.name,
            id: action.office.id,
            changesMade: false,
            newOffice: false,
            editing: false,
            'office-address-id': action.office.address.id,
            'office-address': action.office.address.addressOne,
            'office-addressTwo': action.office.address.addressTwo,
            'office-city': action.office.address.city,
            'office-postalcode': action.office.address.postalCode,
            'office-province': action.office.address.province,
            days: parseOfficeHoursFromAPI(action.office),
          },
        },
        officesByID: [...new Set(officesByID.filter((o) => o !== 'new'))],
        loading: false,
      }
    }

    case ON_SAVE_OFFICE_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export const onSaveOffice = (office: Office) => async (
  dispatch: Dispatch,
): Promise<any> => {
  dispatch({ type: ON_SAVE_OFFICE, office })

  for (const day of office.officeHours) {
    day.openingTime = setOfficeTimeToDateTimeOffest(day.openingTime)
    day.closingTime = setOfficeTimeToDateTimeOffest(day.closingTime)
  }

  authPost(Account.SaveOffice, office)
    .then((response) => {
      message.success('Clinic Saved')
      dispatch(onSaveOfficeSuccess(response.data))
      invalidateCache(OFFICE_CACHE)

      return response
    })
    .catch((error) => {
      message.error('Failed to Save Offices, please try again later')
      captureMessage(`onSaveOffice Failed. ${error}`, Severity.Error)
      dispatch(onSaveOfficeFailure(error))

      return error
    })
}

const onSaveOfficeSuccess = (office: Office) => ({
  type: ON_SAVE_OFFICE_SUCCESS,
  office,
})

const onSaveOfficeFailure = (error: Error) => ({
  type: ON_SAVE_OFFICE_FAILURE,
  error,
})

const setOfficeTimeToDateTimeOffest = (time: string) => {
  const times = time.split(':')
  const date = moment()
    .set('hour', Number(times[0]))
    .set('minute', Number(times[1]))

  return date
}
