import { reducer as fetchClientTrackerUser } from './fetch-client-tracker-user'
import { reducer as changePassword } from './change-password'
import { reducer as setupUser } from './user-setup'
import {
  reducer as saveUser,
  defaultState as saveUserDefaultState,
} from './save-user'
import { IAction, IUser, UserState } from 'interfaces'

const reducers = [fetchClientTrackerUser, changePassword, setupUser, saveUser]

const action = (action: string) => `client-trackyr/user/${action}`
const SET_USER = action('SET_USER')
const UNSET_USER = action('UNSET_USER')
const ON_USER_CHANGE = action('ON_USER_CHANGE')

export const defaultState: UserState = {
  ...saveUserDefaultState,
  user: {} as IUser,
  ctUser: {
    fetched: false,
    error: { hasError: false },
  } as IUser,
  changingPassword: false,
  passwordChangeError: {},
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      }

    case UNSET_USER:
      return {
        ...state,
        user: {},
        ctUser: {},
      }

    case ON_USER_CHANGE: {
      if (action.name === 'id' || action.name === 'firebaseID') {
        return state
      }

      return {
        ...state,
        ctUser: {
          ...state.ctUser,
          [action.name]: action.value,
        },
      }
    }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const setUser = (user: IUser) => ({
  type: SET_USER,
  user,
})

export const unSetUser = () => ({ type: UNSET_USER })

export const onUserChange = (name: string, value: string) => ({
  type: ON_USER_CHANGE,
  name,
  value,
})
