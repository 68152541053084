import React from 'react'

import { Print } from 'components/ui'
import CreateForm from './create-form'
import { IEvent } from 'interfaces'

const Forms = ({
								 autosave,
								 entityID,
								 fetching,
								 forms = [],
								 formsDataOverride,
								 namePrefix,
								 onChange,
								 // onChangeCallback,
								 patch,
								 patchEndpoint,
								 printParams,
								 readonly,
								 validateFormOverride,
							 }: ICreateForms) => (
	<>
		{forms &&
			forms.sort &&
			forms
				.sort((a, b) => a.order - b.order)
				.map(
					(formTemplate) =>
						formTemplate && (
							<CreateForm
								key={formTemplate.id}
								autosave={autosave}
								entityID={entityID}
								fetching={fetching}
								form={formTemplate}
								formID={formTemplate.id}
								formsDataOverride={formsDataOverride}
								namePrefix={namePrefix}
								// onChangeCallback={onChangeCallback}
								onChangeOverride={onChange}
								patch={patch}
								patchEndpoint={patchEndpoint}
								printParams={printParams}
								readonly={readonly}
								validateFormOverride={validateFormOverride}
							/>
						),
				)}
	</>
)

interface ICreateForms {
	// ref?: any
	autosave?: boolean
	entityID: string
	fetching?: boolean
	forms: Array<any> // TODO - IForm
	formsDataOverride?: any // TODO - w.e. this is
	namePrefix?: string
	onChange: Function | any
	// onChangeCallback?: Function | any
	patch?: (e: IEvent, t?: any) => Promise<any>
	patchEndpoint: string
	printParams?: Print
	readonly?: boolean
	validateFormOverride?: Function | any
}

Forms.defaultProps = {
	autosave: true,
	forms: [],
}

export default Forms
