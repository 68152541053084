import React from 'react'
import { connect } from 'react-redux'

import ClientList from 'components/clients/clients-list'
import { fetchAllClients, getAllClientsList$ } from 'containers/clients'
import { filterClients, paginateLocally } from 'lib'
import { setNavBarText } from 'components/nav'
import PracticeType from 'components/practice-type/practice-type'
import {
	Card,
	Message,
	Container,
	ContainerLayout,
	Pagination,
	Button,
	Popup,
} from 'components/ui'

import { SimpleSpinner } from 'components/loaders'

import { onClientSearch } from './ducks'
import NewClientButton from './new-client-button'
import { IEvent, IClient, IState } from 'interfaces'
import { getSetting } from 'containers/settings'
import { CLIENTS_PER_PAGE } from 'constants/settings'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'

// const CLIENT_PAGE_SIZE_DEFAULT = 15
const CLIENT_PAGE_SIZE_MOBILE_MAX = 10

class ClientsView extends React.Component<ClientsViewProps> {
	state = {
		page: 1,
		defaultPageSize: this.props.clientsPerPage,
	}

	componentDidMount() {
		if (
			!this.props.clientState.clients.length &&
			!this.props.allClientsFetched
		) {
			this.props.fetchAllClients(this.props.user.user.uid)
		}

		this.props.setNavBarText('Clients')

		if (!this.props.pageSize) {
			this.changePageSize()
			window.addEventListener('resize', () => {
				this.changePageSize()
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', () => {
			this.changePageSize()
		})
	}

	changePageSize = () => {
		const newPageSize =
			window.innerWidth > 952
				? this.props.clientsPerPage
				: Math.min(
					CLIENT_PAGE_SIZE_MOBILE_MAX,
					Math.floor(this.props.clientsPerPage / 2),
				)

		if (newPageSize !== this.state.defaultPageSize) {
			this.setState(() => ({ page: 1 }))
		}

		this.setState(() => ({
			defaultPageSize: newPageSize,
		}))
	}

	onSearch = ({ target }: IEvent) => {
		this.setState(() => ({ page: 1 }))
		this.props.onClientSearch(target.value)
	}

	onClientClick = (client: IClient) => {
		this.props.navigate(`/client/${client.id}/notes`)
	}

	render() {
		const { clientState, clientSearch, allClients } = this.props
		const pageSize = this.props.pageSize || this.state.defaultPageSize

		const filteredClients = filterClients(clientSearch, allClients)
		const clients = paginateLocally(filteredClients, this.state.page, pageSize)

		return (
			<ContainerLayout>
				<Container>
					<Card
						extra={
							<div>
								<NewClientButton />
								<Popup
									content='Archived Clients'
									trigger={
										<Link to='/clients/archived'>
											<Button icon='archive' style={{ marginLeft: '0.5em' }} />
										</Link>
									}
								/>
							</div>
						}
						title='Clients'
					>
						{this.props.loadingAllClients && <SimpleSpinner />}
						{!clientState.loadingAllClientsError &&
							!this.props.loadingAllClients && (
								<ClientList
									clients={clients}
									onClear={() =>
										this.onSearch({ target: { name: '', value: '' } })
									}
									onSearch={this.onSearch}
									search
									searchTerm={clientSearch}
								/>
							)}
						{clientState.loadingAllClientsError && (
							<Message content="Couldn't load Client's" error icon='warning' />
						)}
						{this.props.verifyEmailError && (
							<Message
								content='You must verify your email address before you can add clients'
								icon='info'
								info
							/>
						)}

						{filteredClients.length > pageSize && (
							<Pagination
								activePage={this.state.page}
								onPageChange={(activePage) =>
									this.setState(() => ({ page: activePage }))
								}
								style={{ 'margin-top': '0.5em' }}
								totalPages={Math.ceil(filteredClients.length / pageSize)}
								totalText={`Total Clients: ${allClients.length}`}
							/>
						)}
					</Card>
				</Container>
			</ContainerLayout>
		)
	}
}

interface ClientsViewProps {
	allClients: Array<any>
	allClientsFetched: boolean
	clientSearch: string
	clientState: any
	clientsPerPage: number
	fetchAllClients: Function
	loadingAllClients: boolean
	navigate: NavigateFunction
	onClientSearch: Function
	pageSize: number
	setNavBarText: Function
	user: any
	verifyEmailError: string
}

const mapStateToProps = (state: IState) => ({
	clientSearch: state.clientView.clientSearch,
	allClients: getAllClientsList$(state),
	clientState: state.clients,
	clientsPerPage: getSetting(state, CLIENTS_PER_PAGE),
	user: state.user,
	loadingAllClients: state.clients.loadingAllClients,
	allClientsFetched: state.clients.allClientsFetched,
	verifyEmailError: state.clients.verifyEmailError,
})

const mapDispatchToProps = (dispatch: any) => ({
	fetchAllClients: (userID: string) => dispatch(fetchAllClients({ userID })),
	onClientSearch: (searchTerm: string) => dispatch(onClientSearch(searchTerm)),
	setNavBarText: (text: string) => dispatch(setNavBarText(text)),
})

const ClientsViewWrapper = (props: ClientsViewProps) => {
	const navigate = useNavigate()
	return <ClientsView {...props} navigate={navigate} />
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PracticeType(ClientsViewWrapper))
