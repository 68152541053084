import moment from 'moment'
import { IAction } from 'interfaces'

export const defaultState = {
  day: moment(),
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    default:
      return state
  }
}
