import { IAction, ServiceState } from 'interfaces'

import { reducer as postService } from './post-service'
import { reducer as fetchService } from './fetch-service'
import { reducer as deleteService } from './delete-service'

export const defaultState: ServiceState = {
  allIDs: [],
  services: {},
  totalServices: 0,
  flags: {
    fetched: false,
    fetching: false,
    posting: false,
    deleting: false,
  },
}

const reducers = [postService, fetchService, deleteService]

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    default:
      break
  }

  return reducers.reduce((s: any, r) => r(s, action), state)
}
