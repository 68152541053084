import { IAction, NotificationState, NotificationType } from 'interfaces'
import shortid from 'shortid'
import store from 'store'

export const NOTIFICATION = 'client-trackyr/notifications/NOTIFICATION'
export const CLEAR_NOTIFICATION =
  'client-trackyr/notifications/CLEAR_NOTIFICATION'
export const defaultState = {
  notifications: [],
} as NotificationState

export const reducer = (
  state: NotificationState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case NOTIFICATION: {
      const notifications = [...state.notifications]

      notifications.push(action.payload)

      return {
        ...state,
        notifications,
      }
    }

    case CLEAR_NOTIFICATION: {
      const notifications = state.notifications

      return {
        ...state,
        notifications: notifications.filter((n) => n.id !== action.id),
      }
    }

    default:
      return state
  }
}

export const notification = (
  id: string,
  message: string,
  content: string,
  type: NotificationType,
) => ({
  type: NOTIFICATION,
  payload: {
    id,
    message,
    content,
    type,
  },
})

export const clearNotification = (id: string) => ({
  type: CLEAR_NOTIFICATION,
  id,
})

const success = (
  message: string,
  content: string = '',
  timeInSeconds: number = 3,
) =>
  notificationMessage(message, content, NotificationType.success, timeInSeconds)
const info = (
  message: string,
  content: string = '',
  timeInSeconds: number = 5,
) => notificationMessage(message, content, NotificationType.info, timeInSeconds)
const warning = (
  message: string,
  content: string = '',
  timeInSeconds: number = 5,
) =>
  notificationMessage(message, content, NotificationType.warning, timeInSeconds)
const error = (
  message: string,
  content: string = '',
  timeInSeconds: number = 7,
) =>
  notificationMessage(message, content, NotificationType.error, timeInSeconds)

const message = {
  success,
  info,
  warning,
  error,
}

const notificationMessage = (
  message: string,
  content: string,
  type: NotificationType,
  timeInSeconds: number,
) => {
  const id = shortid.generate()
  store.dispatch(notification(id, message, content, type))

  if (timeInSeconds > 0) {
    setTimeout(() => {
      store.dispatch(clearNotification(id))
    }, timeInSeconds * 1000)
  }
}

export { message }
