import { authGet } from 'lib/http-request'
import { Soaps } from 'constants/endpoints'
import { onSoapsLoad } from 'containers/draw'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { IResponse, GetAllResponse } from 'interfaces/fetch'
import { IAction, SoapNotesState } from 'interfaces'
import { ONE_HOUR } from 'constants/cache'

const soapsAction = (action: string) => `client-trackyr/soap-notes/${action}`
export const FETCH_CLIENT_SOAPS = soapsAction('FETCH_CLIENT_SOAPS')
export const FETCH_CLIENT_SOAPS_SUCCESS_NO_CONTENT = soapsAction(
  'FETCH_CLIENT_SOAPS_SUCCESS_NO_CONTENT',
)
export const FETCH_CLIENT_SOAPS_SUCCESS = soapsAction(
  'FETCH_CLIENT_SOAPS_SUCCESS',
)
export const FETCH_CLIENT_SOAPS_FAILURE = soapsAction(
  'FETCH_CLIENT_SOAPS_FAILURE',
)

export const defaultState = {} as SoapNotesState

const NOTES_PAGE_SIZE = 3

export const reducer = (
  state: SoapNotesState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case FETCH_CLIENT_SOAPS_SUCCESS: {
      const response = action.data.items
      const stateSoaps = state.soaps
      const soaps = {}

      if (
        new Set(response.map((r: any) => r.appointmentID)).size !==
        response.length
      ) {
        captureMessage(
          `Duplicate Appointments on fetching soap. ClientID: ${action.clientID}`,
          Severity.Error,
        )
      }

      for (const soap of response) {
        const { id, fields, ...rest } = soap
        soaps[soap.appointmentID] = {
          ...rest,
          id,
          appointmentDate: soap.appointment.scheduledStart,
          ...fields,
          fetched: true,
        }
      }

      stateSoaps[action.clientID] = { ...stateSoaps[action.clientID], ...soaps }

      return {
        ...state,
        soaps: { ...stateSoaps },
        fetchedPages: {
          ...state.fetchedPages,
          [action.page]: true,
        },
        fetching: false,
        soapFilteringLoading: false,
        changesMade: false,
        fetchSoapsFailure: '',
        totalSoaps: {
          ...state.totalSoaps,
          [action.clientID]: action.data.totalCount,
        },
        dirtySoap: '',
      }
    }

    case FETCH_CLIENT_SOAPS_SUCCESS_NO_CONTENT:
      return {
        ...state,
        fetchedPages: {
          ...state.fetchedPages,
          [action.page]: true,
        },
        fetching: false,
        soapFilteringLoading: false,
        changesMade: false,
        fetchSoapsFailure: '',
      }

    case FETCH_CLIENT_SOAPS_FAILURE:
      return {
        ...state,
        fetching: false,
        soapFilteringLoading: false,
        fetchSoapsFailure: action.error,
      }

    case FETCH_CLIENT_SOAPS:
      return {
        ...state,
        fetching: !action.isFiltering,
        soapFilteringLoading: action.isFiltering,
        fetchSoapsFailure: '',
      }

    default:
      return state
  }
}

export const fetchClientSoaps = (
  clientID: string,
  treatmentNumber: number,
  page = 1,
  filter = '',
  useCache = false,
  pageSize = NOTES_PAGE_SIZE,
): any => async (dispatch: any): Promise<any> => {
  dispatch({
    type: FETCH_CLIENT_SOAPS,
    clientID,
    treatmentNumber,
    page,
    filter,
    useCache,
  })

  const pageData = {
    take: pageSize,
    skip: (page - 1) * pageSize,
    filter: filter,
  }

  return authGet(Soaps.GetSoapsForClient(clientID, treatmentNumber, pageData), {
    cache: false, //useCache,
    cacheMaxAge: ONE_HOUR,
  })
    .then((response: IResponse<GetAllResponse<any>>) => {
      if (response.status === 204) {
        dispatch(fetchClientSoapsSuccessNoContent(page))

        return response
      }

      dispatch(fetchClientSoapsSuccess(response.data, clientID, page))
      dispatch(onSoapsLoad(response.data.items))

      return response
    })
    .catch((error) => {
      captureMessage(
        `fetchClientSoaps Failed. ClientID: ${clientID}. ${error}`,
        Severity.Error,
      )

      dispatch(fetchClientSoapsFailure(error))
      return error
    })
}

export const fetchClientSoapsSuccess = (
  data: any,
  clientID: string,
  page: number,
) => ({
  type: FETCH_CLIENT_SOAPS_SUCCESS,
  data,
  clientID,
  page,
})

export const fetchClientSoapsSuccessNoContent = (page: number) => ({
  type: FETCH_CLIENT_SOAPS_SUCCESS_NO_CONTENT,
  page,
})

export const fetchClientSoapsFailure = (error: any) => ({
  type: FETCH_CLIENT_SOAPS_FAILURE,
  error,
})
