import React from 'react'

import WeekDayPicker from 'components/calendar/week-day-picker'
import { Header } from 'components/ui'
import OfficeTimes from './office-times'

const OfficeHours = (props: IOfficeHours) => (
  <>
    <Header key="office-hour-header">Office Hours</Header>
    <WeekDayPicker
      key="office-week-day-picker"
      disabled={!props.editing}
      onClick={props.onClick}
      pickedDays={props.pickedDays}
    />
    <OfficeTimes
      key="office-times"
      addSlot={props.addSlot}
      disabled={props.disabled}
      editing={props.editing}
      onTimeSlotChange={props.onTimeSlotChange}
      pickedDays={props.pickedDays}
      removeSlot={props.removeSlot}
    />
  </>
)

interface IOfficeHours {
  addSlot: Function
  disabled?: boolean
  editing?: boolean
  onClick: Function
  onTimeSlotChange: Function
  pickedDays: Array<any>
  removeSlot: Function
}

OfficeHours.defaultProps = {
  disabled: false,
  editing: false,
}

export default OfficeHours
