import React from 'react'

import { Input as I, Label, FormField } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps, InputSizeProps } from 'components/forms/interfaces'
import { IEvent } from 'interfaces'

export const Input = ({
												action,
												autoSave,
												disabled = false,
												icon,
												inputLabel,
												label,
												loading,
												name,
												onBlur,
												onChange,
												onKeyUp,
												onValidate,
												placeholder,
												printParams,
												readonly,
												size,
												style,
												type,
												validation = {},
												validationRules = {},
												value,
											}: InputProps & InputSizeProps) => {
	const input = (
		<I
			action={action}
			disabled={disabled}
			icon={icon}
			label={inputLabel}
			loading={loading}
			name={name}
			onBlur={(e: IEvent) => {
				onValidate && onValidate(validationRules, label, name, value)
				onBlur && onBlur(e)
			}}
			onChange={onChange}
			onKeyUp={onKeyUp}
			placeholder={placeholder || label}
			printParams={printParams}
			readonly={readonly}
			size={size}
			style={style}
			type={type}
			value={value}
		/>
	)

	return (
		<FormField
			required={!readonly && validationRules && validationRules.required}
			style={{ width: '100%' }}
			{...validation}
		>
			<Label>{label}</Label>

			{autoSave ? (
				<AutoSaveWrapper
					afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
					autosaveDisabled={autoSave.autosaveDisabled}
					autosaveMode={autoSave.autosaveMode}
					endpoint={autoSave.patchEndpoint}
					entityID={autoSave.entityID}
					formType={autoSave.patchType}
					patch={autoSave.patch}
				>
					{input}
				</AutoSaveWrapper>
			) : (
				<>{input}</>
			)}
		</FormField>
	)
}

Input.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
	validation: {},
}
