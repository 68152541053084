import React, { useCallback, useEffect, useState } from 'react'

import { Dispatch, IEvent } from 'interfaces'
import { Dropdown } from 'components/forms'
import { fetchServices, getServices$, Service } from 'containers/services'
import { useDispatch, useSelector } from 'react-redux'

interface Props {
  autoFocus?: boolean
  onChange: (e: IEvent) => void
  readonly?: boolean
  value: string
}

export const ServiceDropdown = (props: Props) => {
  const dispatch: Dispatch = useDispatch()
  const [serviceOptions, setServices] = useState([])
  const [fetching, setFetching] = useState(false)
  const [fetched, setFetched] = useState(false)

  const services = useSelector(getServices$)

  const onFetchServices = useCallback(() => {
    dispatch(fetchServices()).then(() => {
      setFetching(false)
      setFetched(true) // temp ?
    })
  }, [dispatch])

  useEffect(() => {
    if (services.length) {
      setServices(
        services.map((s: Service) => ({
          id: s.id,
          key: s.id,
          value: s.id,
          text: s.name,
        })),
      )
    } else {
      if (!fetched) {
        setFetching(true)
        onFetchServices()
      }
    }
  }, [services, fetched, onFetchServices])

  if (services.length === 0) {
    return null
  }

  return (
    <Dropdown
      autoFocus={props.autoFocus}
      clearable
      label="Service"
      loading={fetching}
      name="serviceID"
      onChange={props.onChange}
      options={serviceOptions}
      readonly={props.readonly}
      value={props.value}
    />
  )
}
