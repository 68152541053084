import { logout } from 'lib/auth'
import { LS_SIDE_BAR_STATE } from 'constants/local-storage'
import localforage from 'localforage'

import { CLIENT_TRACKYR_STORE, STORES, FLAGS } from 'constants/storage'
import { deleteSharedFiles } from 'containers/files'
import { IAction, Dispatch } from 'interfaces'

const appAction = (action: string) => `client-trackyr/app/${action}`
const migrationAction = (str: string) => `client-trackyr/migrations/${str}`

const SET_AUTHENTICATION = appAction('SET_AUTHENTICATION')
const APPLICATION_IS_LOADING = appAction('APPLICATION_IS_LOADING')
const LOG_OUT = appAction('LOG_OUT')
const SIDEBAR_ON_COLLAPSE = appAction('SIDEBAR_ON_COLLAPSE')
const USER_CARD_ON_CHANGE = appAction('USER_CARD_ON_CHANGE')
const ON_SEARCH = appAction('ON_SEARCH')
const FETCH_APP_DATA_SUCCESS = appAction('FETCH_APP_DATA_SUCCESS')

const RUNNING_MIGRATIONS = migrationAction('RUNNING_MIGRATIONS')
const MIGRATIONS_COMPLETE = migrationAction('MIGRATIONS_COMPLETE')
const MIGRATIONS_FAILURE = migrationAction('MIGRATIONS_FAILURE')
const MIGRATIONS_STEP = migrationAction('MIGRATIONS_STEP')

const flagStore = localforage.createInstance({
	name: CLIENT_TRACKYR_STORE,
	storeName: FLAGS,
})

export const defaultState = {
	authenticated: false,
	loading: true,
	navSelected: {},
	sidebarCollapsed: false,
	sidebarMode: 'inline',
	userVerified: false,
	searchTerm: '',
	runningMigrations: false,
	migrationStep: 0,
}

export const reducer = (state = defaultState, action: IAction) => {
	switch (action.type) {
		case SET_AUTHENTICATION:
			return {
				...state,
				authenticated: action.authenticated,
			}

		case APPLICATION_IS_LOADING:
			return {
				...state,
				loading: action.loading,
			}

		case SIDEBAR_ON_COLLAPSE:
			return {
				...state,
				sidebarCollapsed: !state.sidebarCollapsed,
			}

		case USER_CARD_ON_CHANGE:
			return {
				...state,
				userCardVisible: action.visible,
			}

		case ON_SEARCH:
			return {
				...state,
				searchTerm: action.value,
			}

		case FETCH_APP_DATA_SUCCESS: {
			const sidebarCollapsed = action.data.sidebarCollapsed === 'true'

			return {
				...state,
				sidebarCollapsed,
			}
		}

		case 'APP::DEGUB':
			return {
				...state,
			}

		case RUNNING_MIGRATIONS:
			return {
				...state,
				runningMigrations: true,
			}

		case MIGRATIONS_COMPLETE:
		case MIGRATIONS_FAILURE:
			return {
				...state,
				runningMigrations: false,
			}

		case MIGRATIONS_STEP:
			return {
				...state,
				migrationStep: action.step,
			}

		default:
			return state
	}
}

export const setAuthentication = (authenticated: boolean) => ({
	type: SET_AUTHENTICATION,
	authenticated,
})

export const isLoading = (loading: boolean) => ({
	type: APPLICATION_IS_LOADING,
	loading,
})

export const onLogout = () => (dispatch: Dispatch) => {
	dispatch(deleteSharedFiles())
	logout().then(async () => {
		cleanupOnLogout()
		await clearLocalForageStores()
		dispatch(setAuthentication(false))
		dispatch(onLogoutFinish())
		window.location.reload()
	})
}

export const clearLocalForageStores = async () => {
	for (const store of STORES) {
		const localforageStore = localforage.createInstance({
			name: CLIENT_TRACKYR_STORE,
			storeName: store,
		})

		await localforageStore.clear()
	}
}

const onLogoutFinish = () => ({ type: LOG_OUT })

export const cleanupOnLogout = () => {
	window.localStorage.clear()
	window.sessionStorage.clear()
}

export const sidebarOnCollapse = () => ({ type: SIDEBAR_ON_COLLAPSE })

export const onSearch = (name: string, value: string) => ({
	type: ON_SEARCH,
	name,
	value,
})

export const loadAppData = (/*user*/) => {
	// setUser(user)
	// fetchAccountData(user)
	// onAuthentication(true)
	// fetchTodaysClients(1) // TEMP - userID
	// setLoading(false)
	//

	return async (dispatch: Dispatch) => {
		const localStorageData = await getLocalStorageData()
		return dispatch(loadAppDataSuccess(localStorageData))
	}
}

const loadAppDataSuccess = (data: any) => ({
	type: FETCH_APP_DATA_SUCCESS,
	data,
})

const getLocalStorageData = async () => {
	return { sidebarCollapsed: await getSavedSidebarState() }
}

const getSavedSidebarState = async () => {
	const ls = await flagStore.getItem(LS_SIDE_BAR_STATE)
	return ls
}

export const runningMigrations = () => ({
	type: RUNNING_MIGRATIONS,
})

export const migrationFailure = (error: any, migrationsRun: number) => ({
	type: MIGRATIONS_FAILURE,
	error,
	migrationsRun,
})

export const migrationComplete = (migrationsRun: number) => ({
	type: MIGRATIONS_COMPLETE,
	migrationsRun,
})

export const migrationStep = (step: number) => ({
	type: MIGRATIONS_STEP,
	step,
})
