import { Spinner } from 'components/loaders'
import { Button, ButtonContainer, Header, Message, Modal } from 'components/ui'
import { Soaps } from 'constants/endpoints'
import { Forms, getFormTemplates$ } from 'containers/forms'
import { getSoapTypes$ } from 'containers/settings'
import { Form_T } from 'containers/settings/form-types/new-interfaces'
import { IFormsData, IResponse, Note } from 'interfaces'
import { authGet } from 'lib/http-request'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

interface Props {
	onClick: (formData: IFormsData) => void
	currentAppointmentID: string
	open: boolean
	onClose: () => void
}

export const CopyPreviousNotesButtonModal = (props: Props) => {
	const [fetched, setFetched] = useState<boolean>(false)
	const [fetching, setFetching] = useState<boolean>(false)
	const [error, setError] = useState<string>()
	const [noteData, setNoteData] = useState<Note>()
	const templates = useSelector(getFormTemplates$)
	const soapTypes: Form_T[] = useSelector(getSoapTypes$)
	const [formData, setFormData] = useState<IFormsData>()
	const [forms, setForms] = useState<Form_T[]>()

	useEffect(() => {
		if (props.open) {
			fetchPreviousAppointment()
		}
	}, [props.open])

	const onClose = () => {
		setFetched(false)
		setFetching(false)
		setError(undefined)
		setNoteData(undefined)
		props.onClose()
	}

	const onSubmit = () => {
		props.onClick(formData)
		onClose()
	}

	const fetchPreviousAppointment = () => {
		setFetching(true)

		authGet(Soaps.PreviousAppointment(props.currentAppointmentID))
			.then((response: IResponse<Note>) => {
				const noteData = response.data

				setNoteData(noteData)
				setFetching(false)

				if (!noteData) {
					return
				}

				setFormData({
					...noteData.fields,
					shapesSummaryOverride: noteData.shapes,
				})

				const soapType = soapTypes.find((s) => s.id === noteData.soapTypeID)
				const forms = soapType.forms.map((f) => templates[f.formID])
				setForms(forms)
				setFetched(true)
			}).catch(() => {
			setFetching(false)
			setError('Failed to fetch previous Appointment')
		})
			.finally(() => {
				setFetching(false)
			})
	}

	const header = noteData?.appointment?.start ? `Previous Appointment's Notes: ${moment(noteData?.appointment?.start).format('MMMM D YYYY')}` : ''

	return (
		<>
			<Modal closeIcon onClose={onClose} open={props.open}>
				<Header
					content={header}
					icon='edit'
				/>

				<Modal.Content>
					<Spinner spinning={fetching} tip='Fetching previous appointment'>
						{error && <Message content={error} error icon='warning' />}

						{fetched && <Forms
              entityID={noteData?.id}
              forms={forms}
              formsDataOverride={formData}
              onChange={() => {
							}}
              patchEndpoint=''
              readonly
						            />}
					</Spinner>
				</Modal.Content>

				<Modal.Actions>
					<ButtonContainer>
						<Button content='Cancel' onClick={onClose} />
						<Button
							content='Use Notes'
							onClick={onSubmit}
							primary
						/>
					</ButtonContainer>
				</Modal.Actions>
			</Modal>
		</>
	)
}