import { authPut } from 'lib/http-request'
import { User } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { Dispatch, ErrorState, IAction, IResponse } from 'interfaces'

const userAction = (action: string) => `client-trackyr/user/${action}`
const SET_USER_SETUP = userAction('SET_USER_SETUP')
const SET_USER_SETUP_SUCCESS = userAction('SET_USER_SETUP_SUCCESS')
const SET_USER_SETUP_FAILURE = userAction('SET_USER_SETUP_FAILURE')

export const defaultState = {
  ctUser: {},
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SET_USER_SETUP:
      return {
        ...state,
      }

    case SET_USER_SETUP_SUCCESS:
      return {
        ...state,
        ctUser: {
          ...state.ctUser,
          isSetup: action.data,
        },
      }

    case SET_USER_SETUP_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export const setUserSetup = (isSetup: boolean, userID: string) => (
  dispatch: Dispatch,
) => {
  dispatch({ type: SET_USER_SETUP })

  authPut(User.UserSetup, { isSetup, userID })
    .then((response: IResponse<boolean>) =>
      dispatch(setUserSetupSuccess(response.data)),
    )
    .catch((error) => {
      captureMessage(`setUserSetup Failed. ${error}`, Severity.Error)

      dispatch(setUserSetupError(error))
    })
}

const setUserSetupSuccess = (data: boolean) => ({
  type: SET_USER_SETUP_SUCCESS,
  data,
})

const setUserSetupError = (error: ErrorState) => ({
  type: SET_USER_SETUP_FAILURE,
  error,
})
