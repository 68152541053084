import { Segment } from 'components/ui'
import { IFile } from 'interfaces'
import moment from 'moment'
import React from 'react'
import { LabelText } from '../components/label-text'
import { toCamelCase } from './json-parsing-fix'

interface Props {
  value: string
}

export const FilesValue = ({ value }: Props) => {
  const file: IFile = JSON.parse(value, toCamelCase) || {}

  const createdDateValue =
    !!file.dateCreated &&
    moment('0001-01-01T00:00:00+00:00').diff(file.dateCreated) !== 0
  const dateModifiedValue =
    !!file.dateModified &&
    moment('0001-01-01T00:00:00+00:00').diff(file.dateModified) !== 0

  return (
    <Segment>
      <LabelText label="ID" value={file.id} />
      <LabelText label="File Name" value={file.fileName} />
      <LabelText label="File Size (bytes)" value={file.fileSize} />
      <LabelText label="Content Type" value={file.contentType} />
      {createdDateValue && (
        <LabelText
          label="Date Created"
          value={moment(file.dateCreated).format('MMM D YYYY h:mm a')}
        />
      )}
      {dateModifiedValue && (
        <LabelText
          label="Date Modified"
          value={moment(file.dateModified).format('MMM D YYYY h:mm a')}
        />
      )}

      <LabelText label="Tags" value={file.tags} />

      {file.notes && <LabelText label="Notes" value={file.notes} />}
    </Segment>
  )
}
