import { authGet } from 'lib/http-request'
import { Account } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { Dispatch, ErrorState, IAction, IUser } from 'interfaces'
import { Severity } from 'interfaces'

const accountAction = (action: string) => `client-trackyr/account/${action}`
const FETCH_ACCOUNT_DATA = accountAction('FETCH_ACCOUNT_DATA')
const FETCH_ACCOUNT_DATA_SUCCESS = accountAction('FETCH_ACCOUNT_DATA_SUCCESS')
const FETCH_ACCOUT_DATA_FAILURE = accountAction('FETCH_ACCOUT_DATA_FAILURE')

export const defaultState = {
  quota: { used: -1, total: -1 },
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_ACCOUNT_DATA_SUCCESS: {
      const { storageUsedInBytes, ...rest } = action.data // eslint-disable-line no-unused-vars

      return {
        ...state,
        ...rest,
        quota: {
          ...state.quota,
          used: storageUsedInBytes,
        },
        fetchingAccountData: false,
      }
    }

    case FETCH_ACCOUT_DATA_FAILURE:
      return {
        ...state,
        fetchingAccountData: false,
        error: {
          hasError: true,
          msg: action.error,
        },
      }

    case FETCH_ACCOUNT_DATA:
      return {
        ...state,
        fetchingAccountData: true,
        error: { hasError: false, msg: '' },
      }

    default:
      return state
  }
}

export const fetchAccountData = (firebaseUser: IUser) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: FETCH_ACCOUNT_DATA })

  return authGet(Account.Account)
    .then((response) => {
      const data = {
        ...response.data,
      }

      dispatch(fetchAccountDataSuccess(firebaseUser, data))
    })
    .catch((error) => {
      captureMessage(`fetchAccountData Call Failed: ${error}`, Severity.Error)
      dispatch(fetchAccountDataFailure({ ...error }))
    })
}

const fetchAccountDataSuccess = (user: IUser, data: any) => ({
  type: FETCH_ACCOUNT_DATA_SUCCESS,
  user,
  data,
})

const fetchAccountDataFailure = (error: ErrorState) => ({
  type: FETCH_ACCOUT_DATA_FAILURE,
  error,
})
