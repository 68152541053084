import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Container, Text, Message } from 'components/ui'
import { getPracticeType } from 'containers/settings/selectors'
import { IUser, IState } from 'interfaces'

interface Props {
  ctUser: IUser
  practiceType: string
}

const PracticeType = (ComposedComponent: any) => {
  class PracticeWrapper extends React.Component<Props> {
    render() {
      const { practiceType, ctUser } = this.props

      if (!ctUser.fetched) {
        return (
          <Container>
            <Message
              content={
                <Text>
                  Failed to load your account data, please try again later
                </Text>
              }
              error
              icon="warning"
            />
          </Container>
        )
      }

      return practiceType ? (
        <ComposedComponent {...this.props} practiceType={practiceType} />
      ) : (
        <Container>
          <Message
            content={
              <Text>
                You must pick a Practice Type from your{' '}
                <Link to="account/settings">Account</Link> before you can
                create/edit clients or soaps
              </Text>
            }
            icon="warning"
            warning
          />
        </Container>
      )
    }
  }

  const mapStateToProps = (state: IState) => ({
    practiceType: getPracticeType(state),
    ctUser: state.user.ctUser,
  })

  return connect(mapStateToProps)(PracticeWrapper)
}

export default PracticeType
