import React from 'react'

import { Button, Divider, Form, H1, HeaderWithIcon, Text } from 'components/ui'
import { Input } from 'components/forms'
import { IEvent } from 'interfaces'
import { SetupData } from './interfaces'

interface Props {
  data: SetupData
  onChange: (e: IEvent) => void
  onNextStep: (step: number) => void
}

export const UserStepOne = (props: Props) => {
  const stepDisabled = !props.data.firstName || !props.data.lastName
  const nextStep = () => props.onNextStep(1)

  return (
    <div>
      <H1>Welcome to Client Trackyr!</H1>
      <Text>
        Thank you for signing up to try ClientTrackyr, let&apos;s get some
        information setup for you.
      </Text>

      <HeaderWithIcon name="user">User Information</HeaderWithIcon>

      <Form>
        <Input
          label="First Name"
          name="firstName"
          onChange={props.onChange}
          validationRules={{ required: true }}
          value={props.data.firstName}
        />

        <Input
          label="Middle/Other Names"
          name="middleName"
          onChange={props.onChange}
          value={props.data.middleName}
        />

        <Input
          label="Last Name"
          name="lastName"
          onChange={props.onChange}
          validationRules={{ required: true }}
          value={props.data.lastName}
        />
      </Form>

      <Divider hidden />

      <Button
        content="Next"
        disabled={stepDisabled}
        onClick={nextStep}
        primary
      />
    </div>
  )
}
