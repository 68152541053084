import React from 'react'

import { AutoSaveWrapper, CheckboxGroup } from 'components/forms'
import {
	InputProps,
	ICheckboxGroupProps,
	CheckboxProps,
} from 'components/forms/interfaces'

import { DatePicker } from './date-picker'
import { InputArea } from './input-area'

const CheckboxGroupInputForm = ({
																	autoSave,
																	label,
																	name,
																	onChange,
																	onValidate,
																	options,
																	placeholder,
																	printParams,
																	readonly,
																	showDate,
																	toggle,
																	validation,
																	valueCheck,
																	valueNotes,
																}: InputProps & ICheckboxGroupProps & CheckboxProps) => {
	return (
		<div>
			<CheckboxGroup
				autoSave={autoSave}
				label={label}
				name={`${name}Check`}
				onChange={onChange}
				options={options}
				printParams={printParams}
				readonly={readonly}
				toggle={toggle}
				validationRules={{ required: true }}
				value={valueCheck}
			/>

			{(valueCheck || printParams?.printingView) && (
				<AutoSaveWrapper
					afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
					autosaveDisabled={autoSave.autosaveDisabled}
					endpoint={autoSave.patchEndpoint}
					entityID={autoSave.entityID}
					formType={autoSave.patchType}
					patch={autoSave.patch}
				>
					<InputArea
						label=''
						name={`${name}Notes`}
						onChange={onChange}
						onValidate={
							onValidate
								? (validationRules, _, __, value) =>
									onValidate(validationRules, label, name, value)
								: null
						}
						placeholder={placeholder || label}
						printParams={printParams}
						readonly={readonly}
						validation={validation}
						value={valueNotes}
					/>
				</AutoSaveWrapper>
			)}

			{(valueCheck || printParams?.printingView) && showDate && (
				<DatePicker
					format='MMMM Do YYYY'
					label='Date'
					name={`${name}Date`}
					onChange={onChange}
					placeholder='Date'
					readonly={readonly}
				/>
			)}
		</div>
	)
}

CheckboxGroupInputForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const CheckboxGroupInput = React.memo(CheckboxGroupInputForm)
