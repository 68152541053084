import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authGet } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { FeaturesState, IAction } from 'interfaces'

const action = (action: string) => `client-trackyr/features/${action}`
const FETCH_BETA_USERS = action('FETCH_BETA_USERS')
const FETCH_BETA_USERS_SUCCESS = action('FETCH_BETA_USERS_SUCCESS')
const FETCH_BETA_USERS_FAILURE = action('FETCH_BETA_USERS_FAILURE')

export const defaultState = {
  fetchingBetaUsers: true,
  fetchingBetaUsersError: { error: false },
  users: [],
} as FeaturesState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_BETA_USERS:
      return {
        ...state,
        fetchingBetaUsers: true,
      }

    case FETCH_BETA_USERS_SUCCESS:
      return {
        ...state,
        fetchingBetaUsers: false,
        betaUsers: action.response.value
          ? JSON.parse(action.response.value)
          : [],
      }

    case FETCH_BETA_USERS_FAILURE:
      return {
        ...state,
        fetchingBetaUsers: false,
        fetchingBetaUsersError: {
          error: true,
          message: 'Failed to fetch beta users',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
// TODO - load fron indexDB, but also fetch
const fetchBetaUsers = () => async (dispatch) => {
  dispatch({ type: FETCH_BETA_USERS })

  return authGet(Settings.BetaUsers)
    .then((response) => {
      dispatch(fetchSuccess(response.data))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(`fetchBetaUsers Get Failed: ${error}`, Severity.Error)

      throw error
    })
}

const fetchSuccess = (response) => ({
  type: FETCH_BETA_USERS_SUCCESS,
  response,
})

const fetchFailure = (error) => ({
  type: FETCH_BETA_USERS_FAILURE,
  error,
})

export { fetchBetaUsers }
