import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { Button, ButtonGroup, Icon, H2 } from 'components/ui'
import { MobileBreakPointSmall } from 'styles'
import { CalendarRef } from 'interfaces'

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  position: relative;
  top: 3em;
  margin-top: -3em;

  @media (max-width: ${MobileBreakPointSmall}) {
    top: 0;
    margin-top: 0;
    position: inherit;
  }
`

export const HeaderButtons = (props: IHeaderButtons) => {
  return (
    <CalendarHeader>
      <div>
        <ButtonGroup>
          <Button
            onClick={() => {
              const currentDate = props.calendarRef.current.getApi().getDate()
              const date = moment(currentDate).subtract(1, 'months')

              props.fetchAppointments({
                year: date.get('year'),
                month: date.get('month') + 1,
              })

              props.calendarRef.current.getApi().prev()
            }}
            size="small"
          >
            <Icon name="angle left" />
            Prev
          </Button>
          <Button
            onClick={() => {
              const currentDate = props.calendarRef.current.getApi().getDate()
              const date = moment(currentDate).add(1, 'months')

              props.fetchAppointments({
                year: date.get('year'),
                month: date.get('month') + 1,
              })

              props.calendarRef.current.getApi().next()
            }}
            size="small"
          >
            Next
            <Icon name="angle right" />
          </Button>
        </ButtonGroup>

        <Button
          onClick={() => props.calendarRef.current.getApi().today()}
          size="small"
        >
          Today
        </Button>
      </div>

      {props.title && (
        <div>
          <H2>{props.title}</H2>
        </div>
      )}

      <div style={{ paddingBottom: '0.5em' }}>
        <ButtonGroup
          activeTab={props.activeTab}
          defaultActive={0}
          selectable
          setActiveTab={props.setActiveTab}
        >
          <Button
            onClick={() =>
              props.calendarRef.current.getApi().changeView('dayGridMonth')
            }
            size="small"
          >
            Month
          </Button>
          <Button
            onClick={() =>
              props.calendarRef.current.getApi().changeView('timeGridWeek')
            }
            size="small"
          >
            Week
          </Button>
          <Button
            onClick={() =>
              props.calendarRef.current.getApi().changeView('timeGridDay')
            }
            size="small"
          >
            Day
          </Button>
        </ButtonGroup>
      </div>
    </CalendarHeader>
  )
}

interface IHeaderButtons {
  activeTab: number
  calendarRef: CalendarRef
  fetchAppointments: Function
  setActiveTab: Function
  title?: string
}
