import React from 'react'
import { Pagination as P, PaginationProps } from 'semantic-ui-react'
import { useGetColourTheme } from 'lib/get-colour-theme'
import { SubText } from './text'

interface IPagination {
  activePage: number
  className?: string
  firstItem?: any
  lastItem?: any
  onPageChange: (page: string | number) => void
  pointing?: boolean
  secondary?: boolean
  siblingRange?: any
  size?: any
  stackable?: boolean
  style?: any
  totalText?: string
  totalPages: number
}

const Pagination = (props: IPagination) => {
  const theme = useGetColourTheme('dark')
  const onPageChange = (
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps,
  ) => props.onPageChange(activePage)
  return (
    <>
      <P {...props} inverted={theme === 'dark'} onPageChange={onPageChange} />
      {props.totalText && <SubText>{props.totalText}</SubText>}
    </>
  )
}

export { Pagination }
