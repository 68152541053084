import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authPost } from 'lib/http-request'
import { Features } from 'constants/endpoints'
import { Dispatch, ErrorState, IAction, IResponse } from 'interfaces'

const action = (action: string) => `client-trackyr/features/${action}`
const SET_BETA_USER = action('SET_BETA_USER')
const SET_BETA_USER_SUCCESS = action('SET_BETA_USER_SUCCESS')
const SET_BETA_USER_FAILURE = action('SET_BETA_USER_FAILURE')

export const defaultState = {
  settingBetaUser: false,
  settingBetaUserError: { error: false },
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SET_BETA_USER:
      return {
        ...state,
        settingBetaUser: true,
      }

    case SET_BETA_USER_SUCCESS: {
      return {
        ...state,
        settingBetaUser: false,
        betaUsers: JSON.parse(action.response.value),
      }
    }

    case SET_BETA_USER_FAILURE:
      return {
        ...state,
        settingBetaUser: false,
        settingBetaUserError: {
          error: true,
          message: 'Failed to set feature',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
// TODO - load fron indexDB, but also fetch
const setBetaUser = (email: string) => async (
  dispatch: Dispatch,
): Promise<IResponse<boolean>> => {
  dispatch({ type: SET_BETA_USER, email })

  return authPost(Features.SetBetaUser, { email })
    .then((response) => {
      dispatch(fetchSuccess(response.data))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(`setBetaUser Post Failed: ${error}`, Severity.Error)

      throw error
    })
}

const fetchSuccess = (response: any) => ({
  type: SET_BETA_USER_SUCCESS,
  response,
})

const fetchFailure = (error: ErrorState) => ({
  type: SET_BETA_USER_FAILURE,
  error,
})

export { setBetaUser }
