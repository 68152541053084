import React from 'react'
import styled from 'styled-components'
import store from 'store'

import { Icon, Card, Text, H5 } from 'components/ui'
import { clearNotification } from './redux'
import { NotificationType } from 'interfaces'

const NotificationMessage = styled.div`
  margin: 1em;
  min-width: 25em;
  max-width: 40em;

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }
`

const CloseButton = styled(Icon)`
  :hover {
    cursor: pointer;
  }
`

interface Props {
  content?: string
  id: string
  message: string
  type: NotificationType
}

const Notification = (props: Props) => {
  const getIcon = (type: NotificationType) => {
    switch (type) {
      case NotificationType.success:
        return 'check circle'
      case NotificationType.warning:
        return 'exclamation circle'
      case NotificationType.info:
        return 'info circle'
      case NotificationType.error:
        return 'remove circle'
      default:
        return ''
    }
  }

  const getIconColour = (type: NotificationType) => {
    switch (type) {
      case NotificationType.success:
        return 'green'
      case NotificationType.warning:
        return 'yellow'
      case NotificationType.info:
        return 'blue'
      case NotificationType.error:
        return 'red'
      default:
        return ''
    }
  }
  const icon = getIcon(props.type)
  const iconColour = getIconColour(props.type)

  return (
    <NotificationMessage>
      <Card
        compressed
        extra={
          <CloseButton
            name="close"
            onClick={() => store.dispatch(clearNotification(props.id))}
          />
        }
        icon={icon}
        iconColour={iconColour}
        noBorder
        titleElement={<H5>{props.message}</H5>}
      >
        {props.content && <Text>{props.content}</Text>}
      </Card>
    </NotificationMessage>
  )
}

export default Notification
export type { Props }
