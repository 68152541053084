import React from 'react'
import { useSelector } from 'react-redux'

import { getFeatures } from './redux/selectors'
import { Feature } from 'interfaces'

const memo = {}

const FeatureToggle = (props: Props) => {
  const features = useSelector(getFeatures)
  let showFeatures = false

  if (!props.features || !props.features.length) {
    return null
  }

  const argStr = JSON.stringify(props.features)

  if (!memo[argStr]) {
    for (const f of props.features) {
      if (!features[f]) {
        showFeatures = false
        break
      } else {
        showFeatures = true
      }
    }

    memo[argStr] = showFeatures
  } else {
    showFeatures = memo[argStr]
  }

  if (showFeatures) {
    return <>{props.children}</>
  }

  return <>{props.fallbackComponent}</>
}

interface Props {
  children: React.ReactNode
  fallbackComponent?: React.ReactNode
  features: Array<Feature>
}

export default FeatureToggle
