import { authGet } from 'lib/http-request'
import { Client } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'

import { parsePhoneNumbersFromAPI } from 'lib'
import { IAction, ClientsState, IClient } from 'interfaces'
import { ONE_HOUR } from 'constants/cache'

const clientAction = (action: string) => `client-trackyr/client-info/${action}`
const FETCH_CLIENT_DETAILS = clientAction('FETCH_CLIENT_DETAILS')
const FETCH_CLIENT_DETAILS_SUCCESS = clientAction(
	'FETCH_CLIENT_DETAILS_SUCCESS',
)
const FETCH_CLIENT_DETAILS_FAILURE = clientAction(
	'FETCH_CLIENT_DETAILS_FAILURE',
)
const CLIENT_NOT_FOUND = clientAction('CLIENT_NOT_FOUND')

export const defaultState = {
	fetchingClient: false,
} as ClientsState

export const reducer = (
	state: ClientsState = defaultState,
	action: IAction,
) => {
	switch (action.type) {
		case FETCH_CLIENT_DETAILS: {
			return {
				...state,
				fetchingClient: true,
				clientNotFound: false,
			}
		}

		case FETCH_CLIENT_DETAILS_SUCCESS: {
			// eslint-disable-next-line no-unused-vars
			const { fields, userID, ...rest } = parsePhoneNumbersFromAPI(
				action.client,
			)

			const client = {
				...rest,
				...fields,
				archived: action.client.archived,
				fetched: true,
			}

			return {
				...state,
				clients: {
					...state.clients,
					[action.client.id]: { ...client },
				},
				fetchingClient: false,
				clientDetailsError: '',
				changesMade: false,
			}
		}

		case FETCH_CLIENT_DETAILS_FAILURE:
			return {
				...state,
				fetchingClient: false,
				clientDetailsError: action.error,
			}

		case CLIENT_NOT_FOUND:
			return {
				...state,
				fetchingClient: false,
				clientNotFound: true,
			}

		default:
			return state
	}
}

export const fetchClientDetails =
	(clientID: string, treatmentNumber: number, useCache: boolean = false) =>
		async (dispatch: any) => {
			dispatch({
				type: FETCH_CLIENT_DETAILS,
				clientID,
				treatmentNumber,
				useCache,
			})

			return authGet(Client.Details(clientID, treatmentNumber), {
				cache: useCache,
				cacheMaxAge: ONE_HOUR,
			})
				.then((response) => {
					dispatch(fetchClientDetailsSuccess(response.data))

					return response
				})
				.catch((error) => {
					if (error.response && error.response.status === 404) {
						dispatch(clientNotFound())
					}

					captureMessage(
						`Failed to fetch Client Details. ClientID: ${clientID}. ${error}`,
						Severity.Error,
					)
					dispatch(fetchClientDetailsFailure(error))

					return error
				})
		}

export const fetchClientDetailsSuccess = (client: IClient) => ({
	type: FETCH_CLIENT_DETAILS_SUCCESS,
	client,
})

const fetchClientDetailsFailure = (error: string) => ({
	type: FETCH_CLIENT_DETAILS_FAILURE,
	error,
})

const clientNotFound = () => ({
	type: CLIENT_NOT_FOUND,
})
