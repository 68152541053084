import { authDelete } from 'lib/http-request'
import { Services } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, Dispatch, IResponse, ServiceState } from 'interfaces'
import { Service } from '../interfaces'

const DELETE_SERVICE = 'client-trackyr/receipts/DELETE_SERVICE'
const DELETE_SERVICE_SUCCESS = 'client-trackyr/receipts/DELETE_SERVICE_SUCCESS'
const DELETE_SERVICE_FAILURE = 'client-trackyr/receipts/DELETE_SERVICE_FAILURE'

export const reducer = (state: ServiceState, action: IAction) => {
  switch (action.type) {
    case DELETE_SERVICE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          deleting: true,
        },
      }
    }

    case DELETE_SERVICE_SUCCESS: {
      let allIDs = state.allIDs
      const services = { ...state.services }

      allIDs = allIDs.filter((id) => id !== action.id)
      delete services[action.id]

      return {
        ...state,
        allIDs,
        services,
        totalServices: allIDs.length,
        flags: {
          ...state.flags,
          deleting: false,
        },
      }
    }

    case DELETE_SERVICE_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          deleting: false,
        },
      }
    }
    default:
      return state
  }
}

export const deleteService = (id: string): any => async (
  dispatch: Dispatch,
): Promise<IResponse<Service>> => {
  dispatch({ type: DELETE_SERVICE, id })

  return authDelete(Services.Delete(id))
    .then((response) => {
      message.success('Receipt Deleted')
      dispatch(onDeleteSuccess(id))
      return response
    })
    .catch((error) => {
      message.error('Failed to delete service')
      captureMessage(`deleteReceipt Failed. ${error}`, Severity.Error)
      dispatch(onDeleteFailure(error, id))
      return error
    })
}

const onDeleteSuccess = (id: string) => ({
  type: DELETE_SERVICE_SUCCESS,
  id,
})
const onDeleteFailure = (error: any, id: string) => ({
  type: DELETE_SERVICE_FAILURE,
  error,
  id,
})
