import { resetPassword } from 'lib/auth'
import { captureMessage } from '@sentry/browser'
import { Dispatch, ErrorState, IAction } from 'interfaces'
import { Severity } from 'interfaces'

const RESET_PASSWORD_REQUEST = 'client-trackyr/auth/RESET_PASSWORD_REQUEST'
const RESET_PASSWORD_SUCCESS = 'client-trackyr/auth/RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_FAILURE = 'client-trackyr/auth/RESET_PASSWORD_FAILURE'
const RESET_MODAL = 'client-trackyr/auth/RESET_MODAL'

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetSuccess: false,
        resetLoading: true,
      }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetSuccess: true,
        resetLoading: false,
        resetError: '',
      }

    case RESET_PASSWORD_FAILURE: {
      const error = action.error
      let resetError = 'Couldn\'t reset password at this time'

      if (error.code === 'auth/user-not-found') {
        resetError = 'User with that Email wasn\'t found'
      } else if (error.code === 'auth/invalid-email') {
        resetError = 'Invalid Email Address'
      }

      return {
        ...state,
        resetSuccess: false,
        resetLoading: false,
        resetError,
      }
    }

    case RESET_MODAL:
      return {
        ...state,
        resetSuccess: false,
        resetLoading: false,
        resetError: '',
      }

    default:
      return state
  }
}

export const resetPasswordRequest = (email: string) => (dispatch: Dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST })

  resetPassword(email)
    .then(() => dispatch(resetPasswordRequestSuccess()))
    .catch((error) => {
      captureMessage(`resetPasswordRequest Failed. ${error}`, Severity.Error)

      dispatch(resetPasswordRequestFailure(error))
    })
}

const resetPasswordRequestSuccess = () => ({ type: RESET_PASSWORD_SUCCESS })

const resetPasswordRequestFailure = (error: ErrorState) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
})

export const resetModal = () => ({ type: RESET_MODAL })
