import { authGet } from 'lib/http-request'
import { Services } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import {
  IAction,
  Dispatch,
  IResponse,
  IQueryParams,
  GetAllResponse,
  ServiceState,
} from 'interfaces'
import { Service } from '../interfaces'

const FETCH_SERVICES = 'client-trackyr/receipts/FETCH_SERVICES'
const FETCH_SERVICES_SUCCESS = 'client-trackyr/receipts/FETCH_SERVICES_SUCCESS'
const FETCH_SERVICES_FAILURE = 'client-trackyr/receipts/FETCH_SERVICES_FAILURE'

export const reducer = (state: ServiceState, action: IAction) => {
  switch (action.type) {
    case FETCH_SERVICES: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetching: true,
        },
      }
    }

    case FETCH_SERVICES_SUCCESS: {
      const services = {}
      const allIDs = []

      action.data.items?.forEach((item: Service) => {
        services[item.id] = item
        allIDs.push(item.id)
      })

      return {
        ...state,
        allIDs,
        services,
        totalServices: action.data.totalCount,
        flags: {
          ...state.flags,
          fetched: true,
          fetching: false,
        },
      }
    }

    case FETCH_SERVICES_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetching: false,
        },
      }
    }
    default:
      return state
  }
}

export const fetchServices =
  (queryParams?: IQueryParams) =>
  async (dispatch: Dispatch): Promise<IResponse<GetAllResponse<Service>>> => {
    dispatch({ type: FETCH_SERVICES, queryParams })

    return authGet(Services.Fetch(queryParams))
      .then((response: IResponse<GetAllResponse<Service>>) => {
        dispatch(onFetchSuccess(response.data))
        return response
      })
      .catch((error) => {
        message.error('Failed to Fetch Services')
        captureMessage(`fetchServices Failed. ${error}`, Severity.Error)
        dispatch(onFetchFailure(error))
        throw error
      })
  }

const onFetchSuccess = (data: any) => ({
  type: FETCH_SERVICES_SUCCESS,
  data,
})
const onFetchFailure = (error: any) => ({
  type: FETCH_SERVICES_FAILURE,
  error,
})
