import React, { ReactInstance } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import SaveCancelButtons from 'components/form-footer-buttons/save-cancel-buttons'
import { H3, H4, Print } from 'components/ui'
import ConfirmCancelModal from 'components/modals/confirm-cancel-modal'
import { ErrorBoundary } from 'components/error'
import { Forms } from 'containers/forms'
import { FETCHING, autoSave } from 'components/forms'
import { getSeletectedTreatmentNumber$ } from 'containers/treatment'
import { getSoapTypesForClientType$ } from 'containers/settings'
import { getCurrentBodyDiagram$, getShapesForSoap$, onSoapsLoad } from 'containers/draw'
import { AppointmentsFormInline } from 'components/appointments'
import {
	getSelectedAppointment$,
	setSelectedAppointmentByID,
	selectBestDateSoap,
	saveAppointment,
	unselectAppointment,
} from 'containers/appointments'
import { Soaps as SoapEndpoints } from 'constants/endpoints'
import { Spinner } from 'components/loaders'

import {
	onChange,
	onSaveSoap,
	validateForm,
	getSelectedNote,
	setNoteData,
	setFetchingPreviousNote,
} from 'containers/soap-notes'
import {
	IState,
	IAppointment,
	Dispatch,
	Note,
	IEvent,
	IResponse,
	Severity,
	IFormsData,
	Shape,
} from 'interfaces'
import { NewButton } from './new-button'
import { Forms_T, Form_T } from 'containers/settings/form-types/new-interfaces'
import { message } from 'components/notifications'
import { captureException, captureMessage } from '@sentry/browser'
import { PrePrintModal } from 'containers/print'
import { getSelectedClientsFullName$ } from 'containers/clients'
import shortid from 'shortid'

interface ISoapState {
	formTemplates: Array<any> // TODO - IForm
	soapTypeID?: string
	cancelModalOpen: boolean
	printingView?: boolean
	tempPrintParams?: Print // HACK
}

class Soap extends React.Component<ISoap> {
	componentRef: ReactInstance

	state: ISoapState = {
		formTemplates: [],
		soapTypeID: '',
		cancelModalOpen: false,
		printingView: false,
		tempPrintParams: {
			printingView: true,
			margins: '',
			multiLines: true, // This is backwards...
			ignoreCards: true,
			hideEmpty: false,
			inputAreaHeight: 2,
			textAreaHeight: 3,
		}, // HACK - shjould be using the Modal PrePrintModal
	}

	componentDidMount() {
		this.getForms()

		this.props.selectBestDateSoap(
			this.props.clientID,
			this.props.selectedTreatmentNumber,
		)
	}

	// WTFc
	componentDidUpdate(prevProps: ISoap) {
		if (this.props.params) {
			if (
				this.props.params.appointmentID &&
				this.props.selectedAppointment.id !==
				this.props.params.appointmentID
			) {
				this.props.setSelectedAppointmentByID(
					this.props.params.appointmentID,
				)
			}
		}

		const currentSoapID =
			(this.props.selectedAppointment &&
				this.props.selectedNote &&
				this.props.selectedNote.soapTypeID) ||
			''

		const prevSoapID =
			(prevProps.selectedAppointment &&
				prevProps.selectedNote &&
				prevProps.selectedNote.soapTypeID) ||
			''

		if (
			Object.keys(prevProps.templates).length !==
			Object.keys(this.props.templates).length ||
			prevProps.selectedAppointment.id !== this.props.selectedAppointment.id ||
			currentSoapID !== prevSoapID ||
			prevProps.selectedNote.soapTypeID !== this.props.selectedNote.soapTypeID
		) {
			this.getForms()
		}
	}

	onCancel = () => {
		if (!this.props.changesMade) {
			return
		}

		this.setState(() => ({ cancelModalOpen: true }))
	}

	cancel = () => this.setState(() => ({ cancelModalOpen: true }))

	onChange = (formID: string, name: string, clientID: string, appointmentID: string, value: any) => {
		this.props.onChange(formID, name, clientID, appointmentID, value)
	}

	renderCancelModal = () => {
		const modalProps = {
			modalOpen: this.state.cancelModalOpen,
			message: 'Are you sure you want to cancel editing this note?',
			onAccept: () => {
				this.setState(() => ({ cancelModalOpen: false }))
				this.props.onCancel()
			},
			onCancel: () => this.setState(() => ({ cancelModalOpen: false })),
		}

		return <ConfirmCancelModal {...modalProps} />
	}

	onSave = (soapTypeID?: string) => {
		let soapToSave = this.props.selectedNote

		if (!soapToSave || soapToSave.noNote) {
			soapToSave = {
				appointmentID: this.props.selectedAppointment.id,
			} as Note
		}

		if (soapTypeID) {
			soapToSave.soapTypeID = soapTypeID
		}

		soapToSave.clientID = this.props.clientID
		soapToSave.treatmentNumber = this.props.selectedTreatmentNumber

		if (!soapToSave.soapTypeID && this.props.soapTypes.length === 1) {
			soapToSave.soapTypeID = this.props.soapTypes[0].id
		}

		soapToSave.shapes = this.props.shapes

		if (!soapToSave.bodyDiagramID) {
			soapToSave.bodyDiagramID = this.props.currentBodyDiagram.id
		}

		this.props.onSave(soapToSave).then(() => this.getForms())
	}

	onSaveNote = (noteData: Note) => {
		if (!noteData.clientID || !noteData.treatmentNumber || !noteData.soapTypeID || !noteData.appointmentID) {
			console.error('NoteData Invalid')
			return
		}

		return this.props.onSave(noteData).then(() => this.getForms())
	}

	onSoapTypePick = (soapID: string, soapTypeID: string) => {
		autoSave(SoapEndpoints.Patch, {
			entityID: soapID,
			treatmentNumber: this.props.selectedTreatmentNumber,
			name: 'soapTypeID',
			value: soapTypeID,
		}).then((response: IResponse<any>) => {
			if (response.status === 200 || response.status === 204) {
				message.success('Note Type Changed')
			} else {
				message.error('Failed to change Note Type')
				captureMessage('Failed to change Note Type', Severity.Error)
				captureException(response)
			}
		})
	}

	onPatchSoap = ({ target }: IEvent): Promise<any> => {
		if (target.name === 'appointmentNumber') {
			const newAppt: IAppointment = { ...this.props.selectedAppointment }
			newAppt.appointmentNumber = target.value

			return this.props.onSaveAppointment(newAppt)
		} else {
			return autoSave(SoapEndpoints.Patch, {
				entityID: this.props.selectedNote?.id,
				treatmentNumber: this.props.selectedTreatmentNumber,
				name: target.name,
				value: target.value,
			})
		}
	}

	// WTF
	getForms = () => {
		const soapTypeID = this.props.selectedNote.soapTypeID

		if (!soapTypeID && this.props.soapTypes.length > 1) {
			this.setState(() => ({ formTemplates: [], soapTypeID: '' }))
			return
		}

		if (this.props.soapTypes.length === 1) {
			const templates = this.props.soapTypes[0]

			if (!templates || !templates.forms || !templates.forms.length) {
				return
			}

			const formTemplates = templates.forms?.map(
				(sf: any) => this.props.templates[sf.formID],
			)

			this.setState(() => ({ formTemplates, soapTypeID }))
		} else {
			if (soapTypeID && soapTypeID !== this.state.soapTypeID) {
				this.setState(() => ({ soapTypeID }))
			}

			const templates = this.props.soapTypes.find(
				(ct) => ct.id === soapTypeID,
			) || { forms: [] }

			if (!templates) {
				return
			}

			const formTemplates = templates.forms
				?.map((sf: any) => ({
					...this.props.templates[sf.formID],
					order: sf.order,
				}))
				.sort((a, b) => a.order - b.order)

			this.setState(() => ({ formTemplates }))
		}
	}

	onSaveButtonClick = () => this.onSave()

	onTogglePrintView = (printView: boolean) => {
		this.setState(() => ({ printingView: printView }))
	}

	onPreviousSoapNotesSelected = (noteData: IFormsData) => {
		this.props.setFetchingPreviousNote(true)
		const { shapesSummaryOverride, ...rest } = noteData

		const newNoteData: Note = {
			...this.props.selectedNote,
			...rest,
			shapes: shapesSummaryOverride?.map((shape: Shape) => {
				shape.id = shortid.generate()
				return shape
			}),
		}

		this.props.setNoteData(newNoteData)
		this.props.onSoapsLoad(newNoteData)

		this.onSaveNote(newNoteData).then(() => {
			// HACK - obvi
			window.location.reload()
		})
			.finally(() => {
				this.props.setFetchingPreviousNote(false)
			})
	}

	render() {
		const { selectedAppointment, readonly, clientID, validateFormOverride } =
			this.props

		if (!selectedAppointment) {
			return null
		}

		const selectedNote: Note = {
			...this.props.selectedNote,
			appointmentNumber: selectedAppointment.appointmentNumber,
		}

		return (
			<Spinner spinning={this.props.fetching}>
				{this.renderCancelModal()}

				<ErrorBoundary>
					{selectedAppointment.id && (
						<AppointmentsFormInline
							appointment={this.props.selectedAppointment}
							readonly={readonly}
						>
							<NewButton
								appointment={selectedAppointment}
								clientID={selectedAppointment.clientID}
								loading={this.props.soapSaving}
								onChange={this.onChange}
								onNewSoap={this.onSave}
								onPreviousSoapNotesSelected={this.onPreviousSoapNotesSelected}
								onSoapTypePick={this.onSoapTypePick}
								readonly={this.props.readonly}
								showNewSoap={selectedAppointment.id && !selectedNote.id}
								soapID={this.props.selectedNote.id}
								soapTypeID={selectedNote.soapTypeID}
								soapTypes={this.props.soapTypes}
							/>
						</AppointmentsFormInline>
					)}

					{selectedAppointment.id &&
						selectedNote.id &&
						selectedNote.soapTypeID && (
							<Spinner spinning={this.props.soapSaving || this.props.fetchingPreviousNote}>
								{!this.state.printingView && (
									<Forms
										entityID={selectedNote?.id}
										forms={this.state.formTemplates}
										formsDataOverride={selectedNote}
										onChange={(formID: string, name: string, value: any) =>
											this.onChange(
												formID,
												name,
												clientID,
												this.props.selectedAppointment.id,
												value,
											)
										}
										patch={this.onPatchSoap}
										patchEndpoint={SoapEndpoints.Patch}
										readonly={readonly}
										validateFormOverride={(
											formName: string,
											name: string,
											validation: any,
										) =>
											validateFormOverride(formName, name, clientID, validation)
										}
									/>
								)}
							</Spinner>
						)}
					{selectedAppointment.id && selectedNote.id && (
						<SaveCancelButtons
							extraButtons={
								<PrePrintModal
									ref={this.componentRef}
									data={selectedNote}
									fileJson={this.state.formTemplates}
									header={
										<div>
											<H3 style={{ marginBottom: 0 }}>
												{`${moment(selectedAppointment.start).format(
													'MMM D YYYY h:mm a',
												)} - ${moment(selectedAppointment.end).format(
													'h:mm a',
												)}`}
											</H3>
											<H4
												style={{
													marginTop: 0,
													marginBottom: '0.5em',
													textDecoration: 'underline',
												}}
											>{`Appointment for ${this.props.clientName}`}</H4>
										</div>
									}
									onTogglePrintView={this.onTogglePrintView}
								/>
							}
							loading={
								this.props.soapSaving ||
								this.props.autoSavingStatus === FETCHING
							}
							noCancel
							onCancel={this.onCancel}
							onSave={this.onSaveButtonClick}
							readonly={readonly}
						/>
					)}
				</ErrorBoundary>
			</Spinner>
		)
	}
}

interface ISoap {
	appointmentsCollapsed?: boolean
	autoSavingStatus?: string
	changesMade: boolean
	clientID: string
	clientName: string
	currentBodyDiagram?: any // TODO - OBJ,
	fetchSoapsFailure?: string
	fetching?: boolean
	fetchingPreviousNote?: boolean
	forms: Forms_T[]
	onCancel: Function
	onChange: Function
	onCollapseAppointments: Function
	onSave: (soap: Note) => Promise<IResponse<Note>>
	onSaveAppointment: (
		appointment: IAppointment,
	) => Promise<IResponse<IAppointment>>
	onSoapsLoad: (soap: Note) => void
	params?: Params
	readonly?: boolean
	selectBestDateSoap: Function
	selectedAppointment?: IAppointment // TODO - OBJ,
	selectedNote?: Note
	selectedTreatmentNumber?: number
	setFetchingPreviousNote: (fetchingState: boolean) => void
	setNoteData: (noteData: Note) => void,
	setSelectedAppointmentByID: Function
	shapes?: Array<any> // TODO - IShape
	soapSaving?: boolean
	soapTypes: Form_T[]
	soaps: { [key: string]: Note }
	templates: any // TODO - OBJ,
	validateFormOverride: Function
	unselectAppointment: () => void
}

interface Params {
	appointmentID?: string
}

const mapStateToProps = (state: IState) => ({
	changesMade: state.soapNotes.changesMade,
	clientName: getSelectedClientsFullName$(state),
	loading: state.soapNotes.loading,
	fetching: state.soapNotes.flags.fetchingNote,
	fetchingPreviousNote: state.soapNotes.flags.fetchingPreviousNote,
	soapSaving: state.soapNotes.soapSaving,
	shapes: getShapesForSoap$(state),
	soaps: state.soapNotes.soaps,
	selectedAppointment: getSelectedAppointment$(state),
	templates: state.forms.templates,
	soapTypes: getSoapTypesForClientType$(state),
	selectedNote: getSelectedNote(state),
	selectedTreatmentNumber: getSeletectedTreatmentNumber$(state),
	currentBodyDiagram: getCurrentBodyDiagram$(state),
	autoSavingStatus: state.forms.autoSavingStatus,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: (
		formID: string,
		name: string,
		clientID: string,
		selectedSoap: string,
		value: any,
	) => dispatch(onChange(formID, name, clientID, selectedSoap, value)),
	onSave: (soap: Note) => dispatch(onSaveSoap(soap)),
	onSoapsLoad: (soap: Note) => dispatch(onSoapsLoad([soap])),
	setNoteData: (noteData: Note) => dispatch(setNoteData(noteData)),
	onSaveAppointment: (appointment: IAppointment) =>
		dispatch(saveAppointment(appointment)),
	validateFormOverride: (
		formName: string,
		name: string,
		clientID: string,
		validation: any,
	) => dispatch(validateForm(formName, name, clientID, validation)),
	setSelectedAppointmentByID: (appointmentID: string) =>
		dispatch(setSelectedAppointmentByID(appointmentID)),
	unselectAppointment: () => dispatch(unselectAppointment()),
	selectBestDateSoap: (clientID: string, treatmentNumber: number) =>
		dispatch(selectBestDateSoap(clientID, treatmentNumber)),
	setFetchingPreviousNote: (fetchingState: boolean) => dispatch(setFetchingPreviousNote(fetchingState)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Soap)
