import React from 'react'
import { Message } from 'components/ui'

interface Props {
  errorMsg: string
  hasError: boolean
}

const ErrorArert = ({ hasError, errorMsg }: Props) =>
  hasError && <Message content={errorMsg} error icon="warning" />
export default ErrorArert
