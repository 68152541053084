import React from 'react'
import styled from 'styled-components'

import { authGetImage } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { paginateLocally } from 'lib/pagination'
import { Icon, Responsive } from 'components/ui'

import { default as Image } from './ct-image'

const CarouselImage = styled(Image)`
  &&&&& {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    padding-right: 2em;

    :hover {
      cursor: pointer;
    }
  }
`

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  max-height: 15em;
  height: 15em;
`

const DotContainer = styled.div`
  display: flex;
  margin-top: auto;
`

const Dot = styled(Icon)`
  padding: 0.5em 1em;

  :hover {
    cursor: pointer;
  }
`

const SliderButton = styled(Icon)`
  :hover {
    cursor: pointer;
  }
`

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface Props {
  bodyDiagrams: Array<any>
  numDisplayed: number
}

class ImageSlider extends React.Component<Props> {
  state = {
    page: 1,
  }

  render() {
    const { numDisplayed, bodyDiagrams } = this.props

    const totalDots = Math.ceil(bodyDiagrams.length / numDisplayed)

    const dots = []

    for (let i = 0; i < totalDots; i++) {
      dots.push('*')
    }

    const bds = paginateLocally(bodyDiagrams, this.state.page, numDisplayed)

    return (
      <SliderContainer>
        <ImageContainer style={{ width: '100%' }}>
          <div>
            <SliderButton
              name="chevron circle left"
              onClick={() =>
                this.setState(() => ({
                  page: this.state.page === 1 ? totalDots : this.state.page - 1,
                }))
              }
              size="large"
            />
          </div>
          <ImageContainer>
            {bds
              .filter((bd) => !bd.isCurrent)
              .map((bodyDiagram, index) => {
                return (
                  <div key={index} style={{ width: 256, height: 256 }}>
                    <CarouselImage
                      height={256}
                      imageID={bodyDiagram.name}
                      width={256}
                    />
                  </div>
                )
              })}
          </ImageContainer>

          <div>
            <SliderButton
              name="chevron circle right"
              onClick={() =>
                this.setState(() => ({
                  page: this.state.page >= totalDots ? 1 : this.state.page + 1,
                }))
              }
              size="large"
            />
          </div>
        </ImageContainer>
        <DotContainer>
          {dots.map((_, i) => (
            <Dot
              key={i}
              color={this.state.page === i + 1 ? 'blue' : 'grey'}
              name="circle"
              onClick={() => this.setState(() => ({ page: i + 1 }))}
              size="small"
            />
          ))}
        </DotContainer>
      </SliderContainer>
    )
  }
}

interface ResponsiveImageSliderProps {
  bodyDiagrams: Array<any>
}

// HACK - but it works
class ResponsiveImageSlider extends React.Component<ResponsiveImageSliderProps> {
  state = {}

  componentDidMount() {
    this.fetchImages()
  }

  componentDidUpdate(prevProps: ResponsiveImageSliderProps) {
    if (
      Object.keys(prevProps.bodyDiagrams).length !==
      Object.keys(this.props.bodyDiagrams).length
    ) {
      this.fetchImages()
    }
  }

  fetchImages = () => {
    this.props.bodyDiagrams.forEach((bd) => {
      if (bd.url && !bd.blobStorageFileID) {
        this.setState(() => ({ [bd.id]: bd.url }))
        return
      } else if (!bd.blobStorageFileID) {
        return
      }

      authGetImage(
        Storage.GetFile(bd.blobStorageFileID),
        bd.blobStorageFileID,
      ).then((resp) =>
        this.setState(() => ({ [bd.blobStorageFileID]: resp.data })),
      )
    })
  }

  render() {
    const bodyDiagrams = Object.values(this.state)
    return (
      <CarouselContainer>
        <Responsive maxWidth={768} style={{ width: '100%' }}>
          <ImageSlider bodyDiagrams={bodyDiagrams} numDisplayed={1} />
        </Responsive>

        <Responsive maxWidth={960} minWidth={768} style={{ width: '100%' }}>
          <ImageSlider bodyDiagrams={bodyDiagrams} numDisplayed={2} />
        </Responsive>

        <Responsive maxWidth={1024} minWidth={960} style={{ width: '100%' }}>
          <ImageSlider bodyDiagrams={bodyDiagrams} numDisplayed={3} />
        </Responsive>

        <Responsive minWidth={1024} style={{ width: '100%' }}>
          <ImageSlider bodyDiagrams={bodyDiagrams} numDisplayed={4} />
        </Responsive>
      </CarouselContainer>
    )
  }
}

export { ResponsiveImageSlider }
