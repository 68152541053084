import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authGet } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'
import { IAction, PublicIntakeState, IPublicIntake } from 'interfaces'
import { IResponse, IQueryParams, Options } from 'interfaces/fetch'

const action = (action: string) => `client-trackyr/public-intake/${action}`
const FETCH_ALL_PUBLIC_INTAKE_FORMS = action('FETCH_ALL_PUBLIC_INTAKE_FORMS')
const FETCH_ALL_PUBLIC_INTAKE_FORMS_SUCCESS = action(
  'FETCH_ALL_PUBLIC_INTAKE_FORMS_SUCCESS',
)
const FETCH_ALL_PUBLIC_INTAKE_FORMS_FAILURE = action(
  'FETCH_ALL_PUBLIC_INTAKE_FORMS_FAILURE',
)

export const defaultState = {
  fetchingForms: true,
  fetchingFormsError: { error: false },
  totalCount: 0,
  publicIntakeForms: [],
} as PublicIntakeState

export const reducer = (
  state: PublicIntakeState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case FETCH_ALL_PUBLIC_INTAKE_FORMS:
      return {
        ...state,
        fetchingForms: true,
      }

    case FETCH_ALL_PUBLIC_INTAKE_FORMS_SUCCESS:
      return {
        ...state,
        fetchingForms: false,
        [action.clientID]: {
          ...state[action.clientID],
          totalCount: action.response.totalCount,
          publicIntakeForms: action.response.items.map((item: any) => {
            // eslint-disable-next-line no-unused-vars
            const { userID, ...rest } = item
            return rest
          }),
          fetched: true,
        },
      }

    case FETCH_ALL_PUBLIC_INTAKE_FORMS_FAILURE:
      return {
        ...state,
        fetchingForms: false,
        fetchingFormsError: {
          error: true,
          message: 'Failed to load all forms',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
const fetchAllPublicIntakeForms = (queryParams: IQueryParams): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_ALL_PUBLIC_INTAKE_FORMS, queryParams })

  return authGet(PublicIntake.GetAll, {} as Options, queryParams)
    .then((response: IResponse<IPublicIntake>) => {
      dispatch(fetchSuccess(response.data, queryParams.clientID))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(
        `fetchAllPublicIntakeForms Get Failed: ${error}`,
        Severity.Error,
      )

      throw error
    })
}

const fetchSuccess = (response: any, clientID: string) => ({
  type: FETCH_ALL_PUBLIC_INTAKE_FORMS_SUCCESS,
  response,
  clientID,
})

const fetchFailure = (error: any) => ({
  type: FETCH_ALL_PUBLIC_INTAKE_FORMS_FAILURE,
  error,
})

export { fetchAllPublicIntakeForms }
