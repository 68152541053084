import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button as B } from 'components/ui'
import { IButton } from 'components/forms/interfaces'
import { AppointmentModal } from 'containers/calendar'
import { getSelectedClient$ } from 'containers/clients'
import { MobileBreakPointSmall } from 'styles'

const AddAppointmentButton = styled(B)<IButton>`
  &&&&& {
    margin-left: 0.5em;
    /* width: 100%; */
    /* margin-bottom: 1em;
    padding-bottom: 1em; */

    width: 15em;

    @media (max-width: ${MobileBreakPointSmall}) {
      margin-left: 0;
      margin-top: 0.5em;
    }
  }
`

const NewSoapButton = () => {
  const [showAppointmentModal, setShowAppointmentModal] = useState(false)
  const client = useSelector(getSelectedClient$)

  const showModal = () => setShowAppointmentModal(true)
  const hideModal = () => setShowAppointmentModal(false)

  return (
    <>
      <AppointmentModal
        clientID={client.id}
        modalOpen={showAppointmentModal}
        onCancel={hideModal}
        showCalendarAtStart
      />
      <AddAppointmentButton
        content="New Appointment"
        icon="calendar"
        name="new-appointment-btn"
        onClick={showModal}
        primary
        // size="small"
      />
    </>
  )
}

export default NewSoapButton
