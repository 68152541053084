import React, { useState, useEffect } from 'react'
import { Modal as SemanticModal } from 'semantic-ui-react'
import styled from 'styled-components'

import { useGetColourTheme } from 'lib'
import { useSelector } from 'react-redux'
import { IState } from 'interfaces'
import Notification from 'components/notifications/notification'

const M = styled(SemanticModal)`
  background-color: ${(props) => props.theme.BackgroundColour} !important;

  .ui.header {
    border-bottom: 1px solid ${(props) => props.theme.CardBorderColour} !important;
  }
`

const ModalNotifications = styled.div`
  position: fixed;
  z-index: 999;
  right: 0;
  display: flex;
  justify-content: right;
`

const NotificationsContainter = styled.div``

let modalsOpen = 0

interface Props {
	children: any
	closeIcon?: boolean
	closeOnDimmerClick?: boolean
	dimmer?: boolean
	onClose?: Function
	open: boolean
	size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
	style?: any
	trigger?: React.ReactNode
}

export const Modal = ({ children, open, ...rest }: Props) => {
	const theme = useGetColourTheme('dark')

	const [wasOpen, setWasOpen] = useState(false)
	const notifications = useSelector(
		(state: IState) => state.notifications.notifications,
	)

	const blur = () => {
		const el = document.getElementsByClassName('app')
		if (el && el[0]) {
			for (const e of el) {
				e.classList.add('modal-blur')
			}

			if (!wasOpen) {
				setWasOpen(true)
				modalsOpen += 1
			}
		}
	}

	const unBlur = () => {
		modalsOpen -= 1

		if (modalsOpen <= 0) {
			modalsOpen = 0
			const el = document.getElementsByClassName('app')
			if (el && el[0]) {
				for (const e of el) {
					e.classList.remove('modal-blur')
				}
			}
		}
	}

	if (open) {
		blur()
	} else if (!open && wasOpen) {
		setWasOpen(false)
		unBlur()
	}

	useEffect(() => {
		return () => {
			unBlur()
		}
	}, [])

	return (
		<M closeOnDimmerClick={false} closeOnDocumentClick={false} dimmer={theme === 'dark' ? 'inverted' : true}
			 open={open} {...rest}
		>
			<>
				<ModalNotifications>
					<NotificationsContainter>
						{notifications.map((notification) => (
							<Notification
								key={notification.id}
								content={notification.content}
								id={notification.id}
								message={notification.message}
								type={notification.type}
							/>
						))}
					</NotificationsContainter>
				</ModalNotifications>

				{children}
			</>
		</M>
	)
}

Modal.defaultProps = {
	open: false,
}

Modal.Actions = styled(M.Actions)`
  background-color: ${(props) => props.theme.BackgroundColour} !important;
`

Modal.Content = styled(M.Content)`
  background-color: ${(props) => props.theme.BackgroundColour} !important;
`
Modal.Header = styled(M.Header)`
  &&&&& {
    background-color: ${(props) => props.theme.BackgroundColour} !important;
    border-bottom: 1px solid ${(props) => props.theme.CardBorderColour} !important;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface CancelSubmitWithDeleteProps {
	deleteActive?: boolean
}

export const CancelSubmitWithDelete = styled.div<CancelSubmitWithDeleteProps>`
  ${(props) => (props.deleteActive ? null : 'display: flex')};
  ${(props) => (props.deleteActive ? null : 'justify-content: space-between')};
  ${(props) => (props.deleteActive ? null : 'width: 100%')};
`

export const ButtonContainerSaveCancelWhenDelete = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Actions = M.Actions
export const Content = M.Content
