import React from 'react'
import styled from 'styled-components'

import { Checkbox as C, Text } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { AutoSave } from 'components/forms/interfaces'
import { IEvent } from 'interfaces'

const SwitchWithText = styled.div`
  display: inline-flex;
`

interface SwitchFormProps {
	autoSave: AutoSave
	name?: string
	onChange?: (e: IEvent) => void
	value?: boolean
	size?: string
	text: string
	readonly?: boolean
}

const SwitchForm = ({
											autoSave,
											name,
											onChange,
											value,
											text,
											size = 'small',
											readonly,
										}: SwitchFormProps) => (
	<SwitchWithText>
		<AutoSaveWrapper
			afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
			autosaveDisabled={autoSave.autosaveDisabled}
			debounceTime={250}
			endpoint={autoSave.patchEndpoint}
			entityID={autoSave.entityID}
			formType={autoSave.patchType}
			patch={autoSave.patch}
		>
			<C
				checked={value}
				name={name}
				onChange={({ target }) =>
					!readonly && onChange({ target: { name, value: target.value } })
				}
				readonly={readonly}
				size={size}
				toggle
			/>
			<Text>
				&ensp;
				{text}
			</Text>
		</AutoSaveWrapper>
	</SwitchWithText>
)

SwitchForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export default React.memo(SwitchForm)
