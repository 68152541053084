import React from 'react'
import { useSelector } from 'react-redux'

import { Input } from 'components/forms'
import { FeatureToggle } from 'containers/features'
import { FileUpload } from 'constants/features'
import { getTotalStorageLimitSetting } from 'containers/settings'
import { getStorageUsed } from 'containers/account'

import { Card, Label, StorageQuota, Form } from 'components/ui'
import { GroupLabel } from 'components/forms'
import SaveCancelButtons from 'components/form-footer-buttons/save-cancel-buttons'
import { IEvent, IUser } from 'interfaces'

interface Props {
  loading?: boolean
  onAccountCancel: Function
  onAccountSave: Function
  onUserChange: (e: IEvent) => void
  user: IUser
}

const BasicInfo = ({
  onUserChange,
  loading,
  onAccountSave,
  onAccountCancel,
  user,
}: Props) => {
  const storageQuotaTotal = useSelector(getTotalStorageLimitSetting)
  const storageQuotaUsed = useSelector(getStorageUsed)

  return (
    <>
      <Card title="User Information">
        <Form>
          <GroupLabel>Name</GroupLabel>
          <Input
            label="First Name"
            name="firstName"
            onChange={onUserChange}
            validationRules={{ required: !user.firstName }}
            value={user.firstName}
          />

          <Input
            label="Middle Name"
            name="middleName"
            onChange={onUserChange}
            value={user.middleName}
          />

          <Input
            label="Last Name"
            name="lastName"
            onChange={onUserChange}
            validationRules={{ required: !user.lastName }}
            value={user.lastName}
          />

          <Input
            label="User Name/Display Name"
            name="displayName"
            onChange={onUserChange}
            value={user.displayName}
          />

          <GroupLabel>Contact</GroupLabel>

          <Input
            disabled
            icon="mail"
            label="Email"
            name="email"
            onChange={onUserChange}
            value={user.email}
          />

          {/* <PhoneNumber
            label="Phone Number"
            name="phoneNumber"
            noType
            onChange={onUserChange}
            oneNumber
            value={user.phoneNumber}
          /> */}
        </Form>
      </Card>

      <FeatureToggle features={[FileUpload]}>
        <Card title="Storage">
          <div>
            <Label>File Storage</Label>
          </div>

          <div style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
            <StorageQuota total={storageQuotaTotal} used={storageQuotaUsed} />
          </div>
        </Card>
      </FeatureToggle>

      <SaveCancelButtons
        loading={loading}
        onCancel={onAccountCancel}
        onSave={onAccountSave}
      />
    </>
  )
}

export default BasicInfo
