import { FormGeneratorComponentType, IForm, IFormItem } from 'interfaces'
import { generateGUID } from 'lib'
import moment from 'moment'
import shortid from 'shortid'
import { AllDefinitions } from './component-definition'
import { SPLIT_CHAR } from './components/multi-text-values'
import { getComponentType, getTreeTitle } from './helpers'
import {
  DataType,
  FormBuilderComponent,
  FormInfo,
  FormItemComponentData,
} from './interfaces'

export const generateJSON = (
  formInfo: FormInfo,
  formItems: Array<FormBuilderComponent>,
) => {
  const json: IForm = {
    id: formInfo.id || generateGUID(),
    name: formInfo.name,
    screen: 'client',
    form: [],
    order: 0,
    dateCreated: formInfo.dateCreated || moment(),
    dateModified: formInfo.dateModified || moment(),
    type: 'user',
  }

  const [generatedForm] = generateFormJSON(formItems)
  json.form = generatedForm
  return json
}

export const generateFormJSON = (forms: Array<FormBuilderComponent>): any => {
  const generatedForms = []
  let data = []

  for (const form of forms) {
    const props = getFormItemProps(form)
    const formItem: any = {
      ...props,
    }

    if (props.label) {
      if (props.name) {
        data.push(props.name)
      }
    }

    if (form.children) {
      const [generatedForm, retData] = generateFormJSON(form.children)
      data = [...data, ...retData]
      formItem.form = generatedForm
    }

    if (form.isContainer) {
      formItem.data = [...data]
      data = []
    }

    generatedForms.push(formItem)
  }

  return [generatedForms, data]
}

export const getFormItemProps = (form: FormBuilderComponent) => {
  const { isComponent, isContainer } = form

  const props = {
    name: form.name || form.id,
    deprecated: form.additionalProps?.deprecated || false,
    label: form.label, //|| form.title, // TODO - why this?
    type: form.additionalProps?.type,
    key: form.id,
  } as IFormItem

  if (isComponent) {
    props['autosave'] = true

    if (!props['name']) {
      props['name'] = shortid.generate()
    }

    if (
      props.label?.toLowerCase() === 'first name' ||
      props.name === 'firstName-ct-comp'
    ) {
      props['name'] = 'firstName'
    }
    if (
      props.label?.toLowerCase() === 'middle name' ||
      props.label?.toLowerCase() === 'middle / other name' ||
      props.name === 'middleName-ct-comp'
    ) {
      props['name'] = 'middleName'
    }
    if (
      props.label?.toLowerCase() === 'last name' ||
      props.name === 'lastName-ct-comp'
    ) {
      props['name'] = 'lastName'
    }
    if (
      props.label?.toLowerCase() === 'email address' ||
      props.name === 'email-ct-comp'
    ) {
      props['name'] = 'email'
    }
    if (
      props.label?.toLowerCase() === 'phone number' ||
      props.name === 'phoneNumbers-ct-comp'
    ) {
      props['name'] = 'phoneNumbers'
    }
    if (props.name === 'appt-number-ct-comp') {
      props['name'] = 'appointmentNumber'
    }
  }

  if (isContainer && form.type === 'card') {
    props['display'] = 'responsive'
  }

  form.additionalProps?.data?.forEach((d) => {
    if (d.value) {
      if (d.name === 'options') {
        getOptions(props, form, d)
      } else {
        props[d.name] = d.value
      }
    }
  })

  return handleSpecialCases(props, form.additionalProps?.type)
}

const getOptions = (
  props: IFormItem,
  form: FormBuilderComponent,
  d: FormItemComponentData,
): void => {
  if (Array.isArray(d.value)) {
    props[d.name] = d.value
    return
  }

  if (
    form.additionalProps.type === 'dropdown' &&
    d.type === DataType.MultiText
  ) {
    props[d.name] = d.value?.split(SPLIT_CHAR).map((opt: string) => ({
      id: opt,
      key: opt,
      text: opt,
      value: opt,
    }))
  } else if (form.additionalProps.type === 'radio') {
    props[d.name] = d.value?.split(SPLIT_CHAR).map((opt: string) => opt)
  } else if (
    form.additionalProps.type === 'checkboxGroup' ||
    form.additionalProps.type === 'checkboxGroupForm' ||
    form.additionalProps.type === 'checkboxGroupInput'
  ) {
    props[d.name] = d.value?.split(SPLIT_CHAR).map((opt: string) => ({
      label: opt,
      value: opt,
    }))
  }
}

const handleSpecialCases = (
  props: { [key: string]: any },
  type: FormGeneratorComponentType,
) => {
  switch (type) {
    case 'card': {
      if (props.hideTitle) {
        delete props['collapsible']
        delete props['title']
        delete props['label']
      }

      return props
    }

    default:
      return props
  }
}

export const getFromJSON = (json): any => {
  const formItems = getFormJSONChildren(json)
  return formItems
}

export const getFormJSONChildren = (json) => {
  if (!json) {
    return
  }

  const { form, type, ...rest } = json
  const props = getAdditionalProps(rest, type)
  const componentDefinitions = getComponentType(type)
  const titles = getTitle(rest, props.subtitle)

  const component = {
    id: rest.name,
    children: [],
    expanded: !rest.deprecated,
    ...componentDefinitions,
    ...props,
    ...titles,
  }

  if (component.title === component.subtitle) {
    component.subtitle = null
  }

  const children = []

  if (form) {
    for (const f of form) {
      const child = getFormJSONChildren(f)
      children.push(child)
    }
  }

  component.children = children

  return component
}

const getAdditionalProps = (
  props: IFormItem,
  type: FormGeneratorComponentType,
) => {
  let comp = AllDefinitions.find((ad) => ad.type === type)

  if (!comp) {
    return {}
  }

  comp = JSON.parse(JSON.stringify(comp))

  for (const data of comp.data) {
    const name = data.name
    let val = props[name]

    if (name === 'title' && !val) {
      val = props['label']
    }

    if (val) {
      data.value = val
    } else {
      data.value = data.defaultValue
    }
  }

  return {
    subtitle: comp.label,
    type: comp.type,
    additionalProps: {
      ...comp,
      deprecated: props.deprecated,
    },
  }
}

// TODO - rest.options.map is adding a label to certain elements
const getTitle = (rest, subtitle: string) => {
  let title = rest.label || rest.title

  if (!title && rest.options) {
    title = rest.options.map((o) => o.label || o.name || o.text).join(', ')
  }

  const titles = {
    title: getTreeTitle(title) || subtitle,
  }

  return titles
}
