import React from 'react'
import moment from 'moment'

import { TimePicker } from 'components/forms'
import {
  Button,
  Form,
  ErrorText,
  Modal,
  Header,
  DatePicker,
  // SelectableText,
} from 'components/ui'
import { ICalendarEvent, IEvent } from 'interfaces'

class NewAppointmentDayModal extends React.Component<INewAppointmentDayModal> {
  public static defaultProps: Partial<INewAppointmentDayModal> = {
    event: {} as ICalendarEvent,
    loading: false,
    modalOpen: false,
    onCancel: () => {},
  }

  state = {
    editDate: false,
    start: moment().format('H:mm'),
    end: moment()
      .add(this.props.calendarDefaultAppointmentLengthSettings, 'minutes')
      .format('H:mm'),
    errors: {
      start: '',
      end: '',
    },
    date: {
      start: null,
      end: null,
    },
  }

  componentDidMount() {
    if (this.props.event) {
      const start = moment(this.props.event.start).format('H:mm')
      const end = moment(this.props.event.end).format('H:mm')
      this.setState(() => ({
        start,
        end,
        date: {
          start: this.props.event.start,
          end: this.props.event.end,
        },
      }))
    }
  }

  componentDidUpdate(prevProps: INewAppointmentDayModal) {
    if (
      this.props.event.start &&
      moment(this.props.event.start).format('H:mm') !==
        moment(prevProps.event.start).format('H:mm')
    ) {
      const start = moment(this.props.event.start).format('HH:mm')
      this.setState(() => ({ start }))
    }

    if (
      this.props.event.end &&
      moment(this.props.event.end).format('H:mm') !==
        moment(prevProps.event.end).format('H:mm')
    ) {
      const end = moment(this.props.event.end).format('HH:mm')
      this.setState(() => ({ end }))
    }

    // TODO - disgusting
    if (
      this.props.event &&
      this.props.event.start &&
      this.props.event.end &&
      prevProps.event.start &&
      prevProps.event.end &&
      ((this.props.event.start &&
        this.props.event.end &&
        (!this.state.date.start || !this.state.date.end)) ||
        (this.props.event.start.toString() !==
          prevProps.event.start.toString() &&
          this.props.event.end.toString() !== prevProps.event.end.toString()))
    ) {
      this.setState(() => ({
        date: {
          start: this.props.event.start,
          end: this.props.event.end,
        },
      }))
    }
  }

  onSubmit = () => {
    const startHM = this.state.start.split(':')
    const endHM = this.state.end.split(':')

    const startM = moment(this.state.date.start)
    const endM = moment(this.state.date.end)

    startM.set({ h: Number(startHM[0]), m: Number(startHM[1]) })
    endM.set({ h: Number(endHM[0]), m: Number(endHM[1]) })

    this.props.onAccept({
      start: startM,
      end: endM,
    })

    this.resetState()
  }

  onClose = () => {
    this.resetState()
    this.props.onCancel()
  }

  resetState = () => {
    this.setState(() => ({
      editDate: false,
      start: moment().format('H:mm'),
      end: moment()
        .add(this.props.calendarDefaultAppointmentLengthSettings, 'minutes')
        .format('H:mm'),
      errors: {
        start: '',
        end: '',
      },
      date: {
        start: null,
        end: null,
      },
    }))
  }

  validate = () => {
    this.setState(() => ({
      errors: {
        start: '',
        end: '',
      },
    }))

    const startHM = this.state.start.split(':')
    const endHM = this.state.end.split(':')
    const startM = moment(this.state.date.start)
    const endM = moment(this.state.date.end)

    startM.set({ h: Number(startHM[0]), m: Number(startHM[1]) })
    endM.set({ h: Number(endHM[0]), m: Number(endHM[1]) })

    if (moment(startM).format('X') > moment(endM).format('X')) {
      this.setState(() => ({
        errors: {
          ...this.state.errors,
          start: 'Start time can\'t be after End time',
        },
      }))
    }

    if (!this.state.start) {
      this.setState(() => ({
        errors: {
          ...this.state.errors,
          start: 'Start time is required',
        },
      }))
    }

    if (!this.state.end) {
      this.setState(() => ({
        errors: {
          ...this.state.errors,
          end: 'End time is required',
        },
      }))
    }
  }

  onDateChange = ({ target }: IEvent) => {
    const date = {} as ICalendarEvent
    const startHM = this.state.start.split(':')
    const endHM = this.state.end.split(':')

    date.start = moment(target.value).set({
      h: Number(startHM[0]),
      m: Number(startHM[1]),
    })
    date.end = moment(target.value).set({
      h: Number(endHM[0]),
      m: Number(endHM[1]),
    })

    this.setState(() => ({ date }))
  }

  render() {
    return (
      <Modal
        closeOnDimmerClick={false}
        onClose={this.onClose}
        open={this.props.modalOpen}
        size="small"
      >
        <Header
          content={
            this.state.editDate ? (
              <DatePicker
                label="Date"
                onChange={this.onDateChange}
                value={this.state.date.start}
              />
            ) : (
              // <SelectableText>
              <>{moment(this.state.date.start).format('MMMM D YYYY')}</>
              // </SelectableText>
            )
          }
          icon="clock"
          // onClick={() => this.setState(() => ({ editDate: true }))}
        />

        <Modal.Content>
          <Form>
            <TimePicker
              label="Start Time"
              name="start"
              onBlur={() => {}}
              onChange={({ target }) => {
                this.setState(
                  () => ({ start: target.value }),
                  () => this.validate(),
                )
              }}
              value={this.state.start}
            />
            {this.state.errors.start && (
              <ErrorText>{this.state.errors.start}</ErrorText>
            )}

            <TimePicker
              label="End Time"
              name="end"
              onBlur={() => {}}
              onChange={({ target }) => {
                this.setState(
                  () => ({ end: target.value }),
                  () => this.validate(),
                )
              }}
              value={this.state.end}
            />
            {this.state.errors.end && (
              <ErrorText>{this.state.errors.end}</ErrorText>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.props.onCancel()
            }}
            secondary
          >
            Cancel
          </Button>
          <Button
            disabled={!!this.state.errors.start || !!this.state.errors.end}
            loading={this.props.loading}
            onClick={this.onSubmit}
            primary
            type="submit"
          >
            {this.props.acceptText ? this.props.acceptText : 'Save'}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

interface INewAppointmentDayModal {
  acceptText?: string
  calendarDefaultAppointmentLengthSettings: string
  event: ICalendarEvent
  loading?: boolean
  modalOpen: boolean
  onAccept: Function
  onCancel: Function
}

export default NewAppointmentDayModal
