import React from 'react'
import { connect } from 'react-redux'
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom'

import { onUserChange, saveUser, setUser, setUserSetup } from 'containers/user'
import ConfirmCancelModal from 'components/modals/confirm-cancel-modal'
import { ErrorBoundary } from 'components/error'
import { setNavBarText } from 'components/nav'
import { Container, ContainerLayout, Grid, Message, Tab, Text } from 'components/ui'
import { getCurrentUser } from 'lib/auth'
import { fetchAccountData, fetchAccountForms, fetchStorageQuota } from 'containers/account'
import Settings, {
	fetchSetting, getSetting,
	getStorageUsedSetting,
	getTotalStorageLimitSetting,
	saveSettings,
} from 'containers/settings'
import { PRACTICE_TYPE, STORAGE_USED, USER_AVATAR } from 'constants/settings'
import { Spinner } from 'components/loaders'
import { FeatureToggle } from 'containers/features'
import { FileUpload } from 'constants/features'

import * as Ducks from '.'
import BasicInfo from './components/basic-info'
// import AvatarUpload from './components/avatar-upload'
import Offices from './office/offices'
import Security from './components/security'
import { Branding } from './components/branding'
import { fetchOffices } from './office'
import { Dispatch, IFile, IState, IUser, Office, Setting as ISetting, SettingKey } from 'interfaces'
import { FormSettingsTabs } from './forms/form-settings-tabs'
import AvatarUpload from './components/avatar-upload'

const BASIC_TAB = 'basic-info'
const BRANDING_TAB = 'branding'
const SETTINGS_TAB = 'settings'
const SECURITY_TAB = 'security'
const FORMS_TAB = 'forms'
const OFFICES_TAB = 'offices'

const TABS = [
	BASIC_TAB,
	BRANDING_TAB,
	SETTINGS_TAB,
	SECURITY_TAB,
	FORMS_TAB,
	OFFICES_TAB,
]

const NAV_BAR = ['User', 'Branding', 'Settings', 'Security', 'Forms', 'Offices']

class Account extends React.Component<IAccount> {
	state = {
		cancelModalOpen: false,
	}

	componentDidMount() {
		this.props.fetchStorageQuota()
		this.props.fetchSetting(STORAGE_USED)

		!(this.props.offices && this.props.offices.length) &&
		this.props.fetchOffices()
		this.props.setNavBarText('Account')
	}

	onAccountSave = () => {
		const { ctUser } = this.props
		const activeTab = this.props.params.tab || BASIC_TAB

		this.props.setUser(getCurrentUser())

		if (activeTab === BASIC_TAB) {
			this.props.saveUser(ctUser)

			if (!ctUser.isSetup) {
				this.props.setUserSetup(true, ctUser.id)
			}
		}
	}

	onAccountCancel = () => {
		const activeTab = this.props.params.tab || BASIC_TAB

		if (activeTab === BASIC_TAB) {
			if (this.props.changesMade) {
				this.setState(() => ({ cancelModalOpen: true }))
			}
		}
	}

	renderModal = () => {
		const modalProps = {
			modalOpen: this.state.cancelModalOpen,
			message: 'Are you sure you want to cancel editing your account?',
			onAccept: () => {
				this.setState(() => ({ cancelModalOpen: false }))
				this.props.fetchAccountData(this.props.user)
				this.props.fetchAccountForms()
			},
			onCancel: () => this.setState(() => ({ cancelModalOpen: false })),
		}

		return <ConfirmCancelModal {...modalProps} />
	}

	setActiveTab = (tabIndex: number) => {
		const tab = TABS[tabIndex]
		const navTab = NAV_BAR[tabIndex]
		this.props.navigate(`/account/${tab}`)
		this.props.setNavBarText(navTab)
	}

	render() {
		const activeTab = TABS.findIndex(
			(tab) => tab === this.props.params.tab,
		)
		if (this.props.ctUser.error.hasError || this.props.error.hasError) {
			return (
				<Container>
					<Message
						content={
							<Text>
								Failed to load your account data, please try again later
							</Text>
						}
						error
						icon='warning'
					/>
				</Container>
			)
		}

		// console.log('user.photoURL', user.photoURL)

		const panes = [
			{
				menuItem: {
					key: BASIC_TAB,
					icon: 'user',
					content: 'User',
				},
				render: () => (
					<Grid>
						<Grid.Column computer={8} mobile={16} tablet={8}>
							<ErrorBoundary>
								<BasicInfo
									loading={this.props.savingUser}
									onAccountCancel={this.onAccountCancel}
									onAccountSave={this.onAccountSave}
									onUserChange={this.props.onUserChange}
									user={this.props.ctUser}
								/>
							</ErrorBoundary>
						</Grid.Column>
						<Grid.Column computer={8} mobile={16} tablet={8}>
							<ErrorBoundary>
								<FeatureToggle features={[FileUpload]}>
									<AvatarUpload
										avatar={this.props.userAvatar}
										onUpload={(response: IFile) => {
											this.props.saveSettings({ setting: USER_AVATAR, value: response.id })
										}}
									/>
								</FeatureToggle>
							</ErrorBoundary>
						</Grid.Column>
					</Grid>
				),
			},
			{
				menuItem: {
					key: BRANDING_TAB,
					icon: 'images',
					content: 'Branding',
				},
				render: () => (
					<Container>
						<ErrorBoundary>
							<Branding />
						</ErrorBoundary>
					</Container>
				),
			},
			{
				menuItem: {
					key: SETTINGS_TAB,
					icon: 'settings',
					content: 'Settings',
				},
				render: () => (
					<Container>
						<ErrorBoundary>
							<Settings
								tab={this.props.params.subtab || 'basic-settings'}
							/>
						</ErrorBoundary>
					</Container>
				),
			},
			{
				menuItem: {
					key: SECURITY_TAB,
					icon: 'lock',
					content: 'Security',
				},
				render: () => (
					<Container>
						<ErrorBoundary>
							<Security />
						</ErrorBoundary>
					</Container>
				),
			},
			{
				menuItem: {
					key: FORMS_TAB,
					icon: 'file text',
					content: 'Forms',
				},
				render: () => (
					<Container>
						<FormSettingsTabs />
					</Container>
				),
			},
			{
				menuItem: {
					key: OFFICES_TAB,
					icon: 'building',
					content: 'Clinics',
				},
				render: () => (
					<ErrorBoundary>
						<Offices />
					</ErrorBoundary>
				),
			},
		]

		return (
			<ContainerLayout>
				{this.renderModal()}

				{!this.props.practiceType && (
					<Container>
						<Message
							content='Your Type of Practice determines the default forms for Client Info/Intake and Notes'
							header='You must select a practice type'
							icon='warning'
							warning
						/>
					</Container>
				)}

				<Spinner
					spinning={this.props.fetchingAccountData}
					tip='Loading Account...'
				>
					<Tab
						activeIndex={activeTab === -1 ? 0 : activeTab}
						menu={{ secondary: true, pointing: true }}
						onTabChange={(_: any, { activeIndex }) =>
							this.setActiveTab(activeIndex)
						}
						panes={panes}
						size='large'
					/>
				</Spinner>
			</ContainerLayout>
		)
	}
}

interface IError {
	// TODO - move elsewhere, make generic
	hasError?: boolean
}

interface IAccount {
	changesMade: boolean
	ctUser: any // User Object
	error?: IError
	fetchAccountData: Function
	fetchAccountForms: Function
	fetchOffices: Function
	fetchSetting: Function
	fetchStorageQuota: Function
	fetchingAccountData: boolean
	loading: boolean
	navigate?: NavigateFunction
	offices: { [key: string]: Office }
	onChange: Function | any
	onSave: Function
	onUserChange: Function | any
	params?: Params
	practiceType: string
	quotaTotal: number
	quotaUsed: number
	saveSettings: (setting: ISetting) => Promise<any>
	saveUser: Function
	savingUser: boolean
	setNavBarText: Function
	setUser: Function
	setUserSetup: Function
	user: IUser
	userAvatar: string
}

interface Params {
	tab?: string
	subtab?: string
}

const mapStateToProps = (state: IState) => ({
	changesMade: state.account.changesMade,
	ctUser: state.user.ctUser,
	error: state.account.error,
	fetchingAccountData: state.account.fetchingAccountData,
	fetchingAccountForms: state.account.fetchingAccountForms,
	fetchingOffice: state.account.fetchingOffice,
	fetchingStorageQuota: state.account.fetchingStorageQuota,
	forms: state.account.forms,
	loading: state.account.loading,
	offices: state.account.offices,
	officesByID: state.account.officesByID,
	practiceType: state.settings[PRACTICE_TYPE],
	quota: state.account.quota,
	quotaTotal: getTotalStorageLimitSetting(state),
	quotaUsed: getStorageUsedSetting(state),
	savingUser: state.user.savingUser,
	storageQuotaError: state.account.storageQuotaError,
	user: state.user.user,
	userAvatar: getSetting(state, USER_AVATAR),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: ({ target }) => dispatch(Ducks.onChange(target.name, target.value)),
	fetchAccountData: (firebase: any) => dispatch(fetchAccountData(firebase)),
	fetchAccountForms: () => dispatch(fetchAccountForms()),
	onSave: (user: IUser, accountForm: any, showNotification: boolean) =>
		dispatch(Ducks.onSave(user, accountForm, showNotification)),
	onUserChange: ({ target }) =>
		dispatch(onUserChange(target.name, target.value)),
	setUserSetup: (isSetup: boolean, userID: string) =>
		dispatch(setUserSetup(isSetup, userID)),
	setNavBarText: (text: string) => dispatch(setNavBarText(text)),
	saveSettings: (setting: ISetting) => dispatch(saveSettings(setting)),
	fetchOffices: () => dispatch(fetchOffices()),
	setUser: (user: IUser) => dispatch(setUser(user)),
	fetchStorageQuota: () => dispatch(fetchStorageQuota()),
	fetchSetting: (settingKey: SettingKey) => dispatch(fetchSetting(settingKey)),
	saveUser: (user: IUser) => dispatch(saveUser(user)),
})

const AccountWrapper = (props: IAccount) => {
	const params = useParams()
	const navigate = useNavigate()

	return <Account {...props} navigate={navigate} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountWrapper)
