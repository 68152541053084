import styled from 'styled-components'
import { H3 } from 'components/ui'

export const GroupLabel = styled(H3)`
  margin-top: 1em;

  @media print {
    font-size: 1em;
    text-decoration: underline;
  }
`
