import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import { Button, Header, SList as List } from 'components/ui'
import {
	fetchAllPublicIntakeForms,
	closePublicIntakeForm,
} from 'containers/public-intake'
import { getSelectedClientID$ } from 'containers/clients/redux/selectors'
import ConfirmCancelModal from 'components/modals/confirm-cancel-modal'

import { CreatePublicIntakeModal } from './create-public-intake-modal'
import { ResendEmailModal } from './resend-email-modal'
import { PublicIntakeDetailsModal } from './public-intake-details-modal'
import {
	getPublicIntakesForClient,
	hasUncompletedPublicForms,
	getPublicIntakeObjectForClient,
} from './selectors'
import { Dispatch, IState } from 'interfaces'
import { getSeletectedTreatmentNumber$ } from 'containers/treatment'

const ListItem = styled(List.Item)`
  &&&&& {
    :hover {
      background-color: ${(props) => props.theme.ListItemHoverColour};
      cursor: pointer;
    }
  }
`

const ListContent = styled(List.Content)`
  &&&&& {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
`

interface Props {
	readonly?: boolean
}

export const PublicIntakes = ({ readonly }: Props) => {
	const clientID = useSelector(getSelectedClientID$)
	const treatmentNumber = useSelector(getSeletectedTreatmentNumber$)
	const clientPublicIntake = useSelector(getPublicIntakeObjectForClient)
	const intakeForms = useSelector(getPublicIntakesForClient)
	const hasUncompletedForm = useSelector(hasUncompletedPublicForms)
	const closingForm = useSelector(
		(state: IState) => state.publicIntake.closingForm,
	)

	const [closeModalOpen, setCloseModalOpen] = useState(false)
	const [resendEmailModalVisible, setResendEmailModalVisible] = useState(false)
	const [detailsModalVisible, setDetailsModalVisible] = useState(false)
	const [detailsModalID, setDetailsModalID] = useState('')

	const [resendEmailModalEmail, setResendEmailModalEmail] = useState('')
	const [closeModalID, setCloseModaID] = useState('')
	const [resendPublicIntakeFormObj, setResendPublicIntakeFormObj] = useState({})

	const dispatch: Dispatch = useDispatch()
	const [publicIntakeModalVisible, setPublicIntakeModalVisible] = useState(
		false,
	)
	const [fetched, setFetched] = useState(false)

	const fetchPublicIntakes = useCallback(() => {
		if (!clientID || !treatmentNumber) {
			return
		}
		setFetched(true)
		dispatch(fetchAllPublicIntakeForms({ clientID, treatmentNumber }))
	}, [dispatch, clientID, treatmentNumber])

	const closePublicIntake = useCallback(
		(id) => {
			dispatch(closePublicIntakeForm(id)).then(() => {
				setCloseModalOpen(false)
			})
		},
		[dispatch],
	)

	useEffect(() => {
		if (!fetched && !clientPublicIntake.fetched) {
			fetchPublicIntakes()
		}
	}, [clientPublicIntake, fetchPublicIntakes, fetched])

	const onListItemClick = (id) => {
		setDetailsModalID(id)
		setDetailsModalVisible(true)
	}

	const getStatus = (status) => {
		if (status === 'FailedToSendEmail') {
			return 'Intake Form Created, Failed to Send Email'
		}

		return status
	}

	return (
		<div>
			<CreatePublicIntakeModal
				// fetch={fetchPublicIntakes}
				handleClose={() => setPublicIntakeModalVisible(false)}
				visible={publicIntakeModalVisible}
			/>

			<ResendEmailModal
				email={resendEmailModalEmail}
				handleClose={() => setResendEmailModalVisible(false)}
				publicIntakeForm={resendPublicIntakeFormObj}
				visible={resendEmailModalVisible}
			/>

			<PublicIntakeDetailsModal
				handleClose={() => setDetailsModalVisible(false)}
				id={detailsModalID}
				visible={detailsModalVisible}
			/>

			<ConfirmCancelModal
				loading={closingForm}
				message='Close this Intake?'
				modalOpen={closeModalOpen}
				onAccept={() => closePublicIntake(closeModalID)}
				onCancel={() => setCloseModalOpen(false)}
			/>

			{!readonly && !hasUncompletedForm && (
				<Button
					content='Send Intake'
					icon='mail'
					onClick={() => setPublicIntakeModalVisible(true)}
				/>
			)}

			<Header>Intake Forms</Header>

			<List divided>
				{intakeForms.map((intakeForm) => (
					<ListItem key={intakeForm.id}>
						{!readonly &&
							!intakeForm.completed &&
							moment(intakeForm.expires) > moment() && (
								<ListContent floated='right' style={{ margin: 'auto' }}>
									<Button
										onClick={() => {
											setResendEmailModalVisible(true)
											setResendEmailModalEmail(intakeForm.email)
											setResendPublicIntakeFormObj(intakeForm)
										}}
										primary
									>
										Re-send Email
									</Button>
									<Button
										onClick={() => {
											setCloseModaID(intakeForm.id)
											setCloseModalOpen(true)
										}}
									>
										Close
									</Button>
								</ListContent>
							)}

						<List.Icon
							name='file alternate outline'
							onClick={() => onListItemClick(intakeForm.id)}
							size='large'
							verticalAlign='middle'
						/>

						<ListContent onClick={() => onListItemClick(intakeForm.id)}>
							{!intakeForm.status ? (
								<ListContent>{`Completed: ${
									intakeForm.completed ? 'Yes' : 'No'
								}`}</ListContent>
							) : (
								<ListContent>{`Status: ${getStatus(
									intakeForm.status,
								)}`}</ListContent>
							)}

							<ListContent>{`Sent to: ${intakeForm.email}`}</ListContent>

							{!intakeForm.completed && (
								<ListContent>{`${
									moment(intakeForm.expires) < moment() ? 'Expired' : 'Expires'
								}: ${moment(intakeForm.expires).format(
									'MMMM D YYYY',
								)}`}</ListContent>
							)}

							{intakeForm.completed &&
								intakeForm.status !== 'Closed' &&
								intakeForm.dateCompleted && (
									<ListContent>{`Date Completed: ${moment(
										intakeForm.dateCompleted,
									).format('MMMM D YYYY h:mm a')}`}</ListContent>
								)}

							{intakeForm.status === 'Closed' && intakeForm.dateCompleted && (
								<ListContent>{`Date Closed: ${moment(
									intakeForm.dateCompleted,
								).format('MMMM D YYYY h:mm a')}`}</ListContent>
							)}
						</ListContent>
					</ListItem>
				))}
			</List>
		</div>
	)
}
