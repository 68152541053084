import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Forms } from 'containers/forms'
import { FETCHING } from 'components/forms'
import SaveCancelButtons from 'components/form-footer-buttons/save-cancel-buttons'
import { Client } from 'constants/endpoints'
import { IState, Treatment, Dispatch } from 'interfaces'
import { getSelectedClientID$, getSelectedClient$ } from 'containers/clients'
import { getSeletectedTreatment$ } from 'containers/treatment'
import { onChange as oc } from 'containers/clients/client-info/redux/ducks'
import { onSaveClient as sc } from '../redux/save-client'
import {
	Forms_T,
	FormTabs_T,
} from 'containers/settings/form-types/new-interfaces'

interface GenericTabProps {
	tab: FormTabs_T
}

export const GenericTab = (props: GenericTabProps) => {
	const dispatch: Dispatch = useDispatch()

	const forms = props.tab.forms
	const flags = useSelector((state: IState) => state.clients.flags)
	const templates = useSelector((state: IState) => state.forms.templates)
	const clientID = useSelector(getSelectedClientID$)
	const clientData = useSelector(getSelectedClient$)
	const selectedTreatment: Treatment = useSelector(getSeletectedTreatment$)
	const loading = useSelector((state: IState) => state.clients.loading)
	const autoSavingStatus = useSelector(
		(state: IState) => state.forms.autoSavingStatus,
	)

	const onChange = (formID: string, name: string, value: string) =>
		dispatch(oc(formID, name, value))

	const tabForms = useMemo(() => {
		if (!forms) {
			return []
		}

		return forms.map((sf: Forms_T) => ({
			...templates[sf.formID],
			order: sf.order,
		}))
	}, [forms, templates])

	const onSaveClient = () =>
		dispatch(
			sc(
				clientData,
				selectedTreatment.treatmentNumber,
				selectedTreatment.clientTypeID,
			),
		)

	return (
		<>
			<Forms
				entityID={clientID}
				forms={tabForms}
				formsDataOverride={clientData}
				namePrefix={props.tab.id}
				onChange={onChange}
				patchEndpoint={Client.Patch}
				readonly={!flags.restEditing || selectedTreatment.closed}
			/>

			{!flags.restEditing && (
				<SaveCancelButtons
					loading={loading || autoSavingStatus === FETCHING}
					noCancel
					onSave={onSaveClient}
				/>
			)}
		</>
	)
}
