import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { ReadonlyFormFont } from 'styles/font'
import { H3 } from 'components/ui'

const DayTimePickerContainer = styled.div`
  margin-left: 1em;
`

const TimesContainer = styled.div`
  border: 1px ${(props) => props.theme.utedBorderColour} solid;
`

// TODO - this can probably be removed
const DayTimePicker = ({ day, readonly }: DayTimePickerProps) =>
  readonly ? (
    <ReadonlyFormFont>
      <H3>{day ? moment(day).format('MMMM DD YYYY') : ''}</H3>
    </ReadonlyFormFont>
  ) : (
    <DayTimePickerContainer>
      <H3>{day ? moment(day).format('MMMM DD YYYY') : ''}</H3>
      <TimesContainer />
    </DayTimePickerContainer>
  )

interface DayTimePickerProps {
  day: object
  readonly: boolean
}

export default DayTimePicker
