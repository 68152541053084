import { Account, Settings, User } from 'constants/endpoints'
import { PRACTICE_TYPE } from 'constants/settings'
import { IResponse, IUser, Office } from 'interfaces'
import { emptyGuid } from 'lib/guid'
import { authPost, authPut } from 'lib/http-request'
import { SetupData } from './interfaces'

export const saveData = async (
  data: SetupData,
  user: IUser,
): Promise<boolean> => {
  try {
    const practiceTypeResponse = await authPost(Settings.SaveSettings, {
      setting: PRACTICE_TYPE,
      value: data.practiceType,
    })

    const userData: IUser = {
      ...user,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      isSetup: false,
    }
    const userResponse: IResponse<IUser> = await authPost(User.Save, userData)

    let clinicResponse: IResponse<Office> = {} as IResponse<Office>
    if (data.officeName) {
      const clinic: Office = {
        id: emptyGuid,
        name: data.officeName,
        address: {
          id: emptyGuid,
          addressOne: data.officeAddress['officeAddress-address'],
          addressTwo: data.officeAddress['officeAddress-addressTwo'],
          city: data.officeAddress['officeAddress-city'],
          postalCode: data.officeAddress['officeAddress-postalcode'],
          province: data.officeAddress['officeAddress-province'],
          isCurrent: true,
        },
      }

      clinicResponse = await authPost(Account.SaveOffice, clinic)
    } else {
      clinicResponse.status = 200
    }

    if (
      practiceTypeResponse.status === 200 &&
      userResponse.status === 200 &&
      clinicResponse.status === 200
    ) {
      const setupResponse: IResponse<boolean> = await authPut(User.UserSetup, {
        isSetup: true,
        userID: userData.id,
      })

      if (setupResponse.status === 200) {
        return true
      }
    }
    return (
      practiceTypeResponse.status === 200 &&
      userResponse.status === 200 &&
      clinicResponse.status === 200
    )
  } catch {
    return false
  }
}
