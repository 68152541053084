import { parseShapesFromAPI } from 'lib'
import { drawColours } from 'containers/draw/components/colour-picker'
import { Dispatch, DrawState, IAction, Line, Shape } from 'interfaces'
import { FETCHING, SUCCESS, FAILURE, NONE } from 'components/forms'

const drawAction = (action: string) => `client-trackyr/draw/${action}`
const ADD_SHAPE = drawAction('ADD_SHAPE')
const SELECT_DRAW_COLOUR = drawAction('SELECT_DRAW_COLOUR')
const SELECT_DRAW_SHAPE = drawAction('SELECT_DRAW_SHAPE')
const SELECT_DRAW_SIZE = drawAction('SELECT_DRAW_SIZE')
const SELECT_DRAW_ACTION = drawAction('SELECT_DRAW_ACTION')
const DELETE_SHAPE = drawAction('DELETE_SHAPE')
const ON_CLEAR_SHAPES = drawAction('ON_CLEAR_SHAPES')
const ON_IMAGE_LOAD = drawAction('ON_IMAGE_LOAD')
const ON_SHAPES_PARSED = drawAction('ON_SHAPES_PARSED')
const SET_CANVAS_SCALE = drawAction('SET_CANVAS_SCALE')
const ON_SHAPES_NOTES_CHANGED = drawAction('ON_SHAPES_NOTES_CHANGED')
const ON_SHAPES_COLOUR_CHANGED = drawAction('ON_SHAPES_COLOUR_CHANGED')
const ON_CHANGE_SHAPE = drawAction('ON_CHANGE_SHAPE')
const ADD_LINES = drawAction('ADD_LINES')
const AUTO_SAVING_DRAW = drawAction('AUTO_SAVING_DRAW')
const SET_CURRENT_CANVAS_AS_DATA_URL = drawAction(
  'SET_CURRENT_CANVAS_AS_DATA_URL',
)

export const defaultState = {
  width: 1,
  height: 1,
  shapes: {},
  selectedColour: drawColours[0].colour,
  selectedShape: 'Circle',
  selectedSize: 35,
  selectedAction: 'edit',
  scale: 1.0,
  autoSavingDraw: 'NONE',
  currentCanvasAsDataURL: '',
} as DrawState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case ADD_SHAPE: {
      const soapShapes = state.shapes[action.appointmentID] || []
      const scaleWidth = (state.width * state.scale) / state.width
      const scaleHeight = (state.height * state.scale) / state.height

      const actualX = action.shape.x / scaleWidth
      const actualY = action.shape.y / scaleHeight

      const newShapes = soapShapes
      newShapes.push({
        ...action.shape,
        x: actualX,
        y: actualY,
        relX: actualX * state.scale,
        relY: actualY * state.scale,
        order: soapShapes.length + 1,
      })

      return {
        ...state,
        shapes: {
          ...state.shapes,
          [action.appointmentID]: [...newShapes],
        },
      }
    }

    case ADD_LINES: {
      const scaledLines = []
      let index = 0
      let sumX = 0
      let sumY = 0

      for (const line of action.lines) {
        switch (index) {
          case 0:
          case 2: {
            const scaleX = (state.width * state.scale) / state.width
            const actualX = line.act / scaleX

            scaledLines.push({
              actual: line.act,
              relative: line.act / state.scale,
            })

            sumX += actualX
            break
          }

          case 1:
          case 3: {
            const scaleY = (state.height * state.scale) / state.height
            const actualY = line.act / scaleY

            scaledLines.push({
              actual: line.act,
              relative: line.act / state.scale,
            })

            sumY += actualY

            break
          }

          default:
            continue
        }

        index++

        if (index > 3) {
          index = 0
        }
      }

      const x = sumX / (action.lines.length / 2)
      const y = sumY / (action.lines.length / 2)

      const soapShapes = state.shapes[action.appointmentID] || []
      soapShapes.push({
        ...action.shape,
        lines: scaledLines,
        order: soapShapes.length + 1,
        x,
        y,
        relX: x * state.scale,
        relY: y * state.scale,
      })

      return {
        ...state,
        shapes: {
          ...state.shapes,
          [action.appointmentID]: [...soapShapes],
        },
      }
    }

    case SELECT_DRAW_COLOUR:
      return {
        ...state,
        selectedColour: action.colour,
      }

    case SELECT_DRAW_SHAPE:
      return {
        ...state,
        selectedShape: action.shape,
      }

    case SELECT_DRAW_SIZE:
      return {
        ...state,
        selectedSize: action.size,
      }

    case SELECT_DRAW_ACTION:
      return {
        ...state,
        selectedAction: action.action,
      }

    case DELETE_SHAPE: {
      const id = action.id
      const shapes = state.shapes[action.soapID].filter((s) => s.id !== id)

      return {
        ...state,
        shapes: {
          ...state.shapes,
          [action.soapID]: shapes.map((shape, index) => ({
            ...shape,
            order: index + 1,
          })),
        },
      }
    }

    case ON_CLEAR_SHAPES:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          [action.soapID]: [],
        },
      }

    case ON_IMAGE_LOAD:
      return {
        ...state,
        width: action.width,
        height: action.height,
      }

    case ON_SHAPES_PARSED:
      return {
        ...state,
        shapes: {
          ...state.shapes,
          ...action.shapes,
        },
      }

    case SET_CANVAS_SCALE: {
      let shapes = state.shapes[action.soapID] || []
      const scale = action.scale > 1 ? 1 : action.scale

      if (shapes.length) {
        shapes = shapes.map((shape) => ({
          ...shape,
          relX: shape.x * scale,
          relY: shape.y * scale,
        }))
      }

      return {
        ...state,
        scale,
        shapes: {
          ...state.shapes,
          [action.soapID]: [...shapes],
        },
      }
    }

    case ON_SHAPES_NOTES_CHANGED: {
      const shapes = [...state.shapes[action.appointmentID]]
      const index = shapes.findIndex((shape) => shape.id === action.shapeID)
      shapes[index].notes = action.notes

      return {
        ...state,
        shapes: {
          ...state.shapes,
          [action.appointmentID]: shapes,
        },
      }
    }

    case ON_SHAPES_COLOUR_CHANGED: {
      const shapes = state.shapes[action.appointmentID]
      const index = shapes.findIndex((shape) => shape.id === action.shapeID)
      shapes[index].colour = action.colour

      return {
        ...state,
        shapes: {
          ...state.shapes,
          [action.appointmentID]: [...shapes],
        },
      }
    }

    case ON_CHANGE_SHAPE: {
      const shapes = state.shapes[action.appointmentID]
      const index = shapes.findIndex((shape) => shape.id === action.shape.id)

      const scaleWidth = (state.width * state.scale) / state.width
      const scaleHeight = (state.height * state.scale) / state.height

      const actualX = action.newX / scaleWidth
      const actualY = action.newY / scaleHeight

      if (action.shape.shapeType === 'Lines') {
        let i = 0
        const diffX = actualX - action.shape.x
        const diffY = actualY - action.shape.y

        for (const line of shapes[index].lines) {
          switch (i) {
            case 0:
            case 2:
              line.actual = Number((Number(line.actual) + diffX).toFixed(2))
              line.relative = Number((Number(line.relative) + diffX).toFixed(2))
              break

            case 1:
            case 3:
              line.actual = Number((Number(line.actual) + diffY).toFixed(2))
              line.relative = Number((Number(line.relative) + diffY).toFixed(2))
              break

            default:
              continue
          }

          i++

          if (i > 3) {
            i = 0
          }
        }
      }

      shapes[index].x = actualX
      shapes[index].y = actualY
      shapes[index].relX = actualX * state.scale
      shapes[index].relY = actualY * state.scale

      return {
        ...state,
        shapes: {
          ...state.shapes,
          [action.appointmentID]: [...shapes],
        },
      }
    }

    case AUTO_SAVING_DRAW:
      return {
        ...state,
        autoSavingDraw: action.status,
      }

    case SET_CURRENT_CANVAS_AS_DATA_URL:
      return {
        ...state,
        currentCanvasAsDataURL: action.dataURL,
      }

    default:
      return state
  }
}

export const addShape = (shape: Shape, appointmentID: string) => ({
  type: ADD_SHAPE,
  shape,
  appointmentID,
})

export const selectDrawColour = (colour: string) => ({
  type: SELECT_DRAW_COLOUR,
  colour,
})

export const selectDrawShape = (shape: 'Circle' | 'Square' | 'Pencil') => ({
  type: SELECT_DRAW_SHAPE,
  shape,
})

export const selectDrawSize = (size: number) => ({
  type: SELECT_DRAW_SIZE,
  size,
})

export const selectDrawAction = (action: 'edit' | 'delete' | 'Pencil') => ({
  type: SELECT_DRAW_ACTION,
  action,
})

export const deleteShape = (id: string, soapID: string) => ({
  type: DELETE_SHAPE,
  id,
  soapID,
})

export const onClear = (soapID: string) => ({
  type: ON_CLEAR_SHAPES,
  soapID,
})

export const onImageLoad = (image: any) => ({
  type: ON_IMAGE_LOAD,
  width: image.width,
  height: image.height,
})

export const onShapesParsed = (shapes: { [key: string]: Array<Shape> }) => ({
  type: ON_SHAPES_PARSED,
  shapes,
})

export const onSoapsLoad = (soaps: Array<any>) => {
  return (dispatch: Dispatch) => {
    parseShapesData(soaps)
      .then((shapes) => dispatch(onShapesParsed(shapes)))
      .catch(() => {})
  }
}

const parseShapesData = (
  soaps: Array<any>,
): Promise<{ [key: string]: Array<Shape> }> => {
  return new Promise((resolve, reject) => {
    if (soaps.some((s) => s.shapes)) {
      const shapes = {}

      for (const s of soaps) {
        shapes[s.appointmentID] = parseShapesFromAPI(s.shapes)
      }

      resolve(shapes)
    } else {
      // No shapes, reject and do nothing
      reject()
    }
  })
}

export const setCanvasScale = (scale: number, soapID: string) => ({
  type: SET_CANVAS_SCALE,
  scale,
  soapID,
})

export const onShapesNotesChanged = (
  shapeID: string,
  appointmentID: string,
  notes: string,
) => ({
  type: ON_SHAPES_NOTES_CHANGED,
  shapeID,
  appointmentID,
  notes,
})

export const onShapesColourChanged = (
  shapeID: string,
  appointmentID: string,
  colour: string,
) => ({
  type: ON_SHAPES_COLOUR_CHANGED,
  shapeID,
  appointmentID,
  colour,
})

export const changeShape = (
  shape: Shape,
  newX: number,
  newY: number,
  appointmentID: string,
) => ({
  type: ON_CHANGE_SHAPE,
  shape,
  newX,
  newY,
  appointmentID,
})

export const addLines = (
  lines: Array<Line>,
  shape: Shape,
  appointmentID: string,
) => ({
  type: ADD_LINES,
  lines,
  shape,
  appointmentID,
})

export const setDrawAutoSaving = (
  status: typeof SUCCESS | typeof FAILURE | typeof FETCHING | typeof NONE,
) => ({
  type: AUTO_SAVING_DRAW,
  status,
})

export const setCurrentCanvasAsDataURL = (dataURL: string) => ({
  type: SET_CURRENT_CANVAS_AS_DATA_URL,
  dataURL,
})
