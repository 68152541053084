import { authGet } from 'lib/http-request'
import { Taxes } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import {
  IAction,
  Dispatch,
  IResponse,
  IQueryParams,
  GetAllResponse,
  ServiceState,
} from 'interfaces'
import { Tax } from '../interfaces'

const FETCH_TAXES = 'client-trackyr/taxes/FETCH_TAXES'
const FETCH_TAXES_SUCCESS = 'client-trackyr/taxes/FETCH_TAXES_SUCCESS'
const FETCH_TAXES_FAILURE = 'client-trackyr/taxes/FETCH_TAXES_FAILURE'

export const reducer = (state: ServiceState, action: IAction) => {
  switch (action.type) {
    case FETCH_TAXES: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetching: true,
        },
      }
    }

    case FETCH_TAXES_SUCCESS: {
      const data: GetAllResponse<Tax> = action.data
      const taxes = {}
      const allIDs = []

      data.items?.forEach((item: Tax) => {
        taxes[item.id] = item
        allIDs.push(item.id)
      })

      return {
        ...state,
        allIDs,
        taxes,
        totalTaxes: data.totalCount,
        flags: {
          ...state.flags,
          fetching: false,
        },
      }
    }

    case FETCH_TAXES_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetching: false,
        },
      }
    }
    default:
      return state
  }
}

export const fetchTaxes = (queryParams?: IQueryParams) => async (
  dispatch: Dispatch,
): Promise<IResponse<GetAllResponse<Tax>>> => {
  dispatch({ type: FETCH_TAXES, queryParams })

  return authGet(Taxes.Fetch(queryParams))
    .then((response: IResponse<GetAllResponse<Tax>>) => {
      dispatch(onFetchSuccess(response.data))
      return response
    })
    .catch((error) => {
      message.error('Failed to Fetch Taxes')
      captureMessage(`fetchTaxes Failed. ${error}`, Severity.Error)
      dispatch(onFetchFailure(error))
      throw error
    })
}

const onFetchSuccess = (data: any) => ({
  type: FETCH_TAXES_SUCCESS,
  data,
})
const onFetchFailure = (error: any) => ({
  type: FETCH_TAXES_FAILURE,
  error,
})
