import React from 'react'
import { Line, Group, Text, Circle } from 'react-konva'
import { ShapeProps } from './interface'

interface Props {
  lines: Array<any>
  size: number
}

const ColouredLines = ({
  id,
  lines,
  colour,
  onClick,
  onTap,
  order,
  onDragEnd,
  onDragStart,
  onMouseLeave,
  onMouseOver,
  size,
  x,
  y,
  readonly,
}: ShapeProps & Props) => (
  <Group>
    <Circle
      draggable={!readonly}
      fill="#d3d3d3"
      height={size}
      onClick={onClick}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTap={onTap}
      radius={size / 2}
      width={size}
      x={x}
      y={y}
    />
    <Line
      key={id}
      opacity={0.75}
      points={lines && lines.map && lines.map((line) => Number(line.relative))}
      stroke={colour}
      strokeWidth={5}
    />
    <Text
      draggable={!readonly}
      fill={'#000'}
      fontFamily="Calibri"
      fontSize={24}
      name="text"
      onClick={onClick}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTap={onTap}
      text={`${order}`}
      x={order > 9 ? x - 12 : x - 6}
      y={y - 10}
    />
  </Group>
)

export default ColouredLines
