import React from 'react'

import { Button, Divider, Form, HeaderWithIcon, Text } from 'components/ui'
import { Address, Input } from 'components/forms'
import { IEvent } from 'interfaces'
import { SetupData } from './interfaces'

interface Props {
  data: SetupData
  onChange: (e: IEvent) => void
  onNextStep: (step: number) => void
}

export const ClinicStepThree = (props: Props) => {
  const stepDisabled = props.data.officeName
    ? !(
        !!props.data.officeAddress['officeAddress-address'] &&
        !!props.data.officeAddress['officeAddress-city'] &&
        !!props.data.officeAddress['officeAddress-postalcode'] &&
        !!props.data.officeAddress['officeAddress-province']
      )
    : false
  const nextStep = () => props.onNextStep(3)
  const prevStep = () => props.onNextStep(1)
  const onAddressChange = ({ target }: IEvent) => {
    const officeAddress = {
      ...props.data.officeAddress,
      [target.name]: target.value,
    }

    props.onChange({ target: { name: 'officeAddress', value: officeAddress } })
  }

  return (
    <div>
      <HeaderWithIcon name="building">Your Clinic</HeaderWithIcon>

      <Text>
        Add your primary clinic. If you have more than one clinic you can add
        them later in your account settings. This step is optional.
      </Text>
      <Form>
        <Input
          label="Clinic Name"
          name="officeName"
          onChange={props.onChange}
          value={props.data.officeName}
        />

        <Divider />

        <Address
          address={props.data.officeAddress['officeAddress-address']}
          addressTwo={props.data.officeAddress['officeAddress-addressTwo']}
          city={props.data.officeAddress['officeAddress-city']}
          name="officeAddress"
          onChange={onAddressChange}
          postalcode={props.data.officeAddress['officeAddress-postalcode']}
          province={props.data.officeAddress['officeAddress-province']}
          validationRules={{ required: !!props.data.officeName }}
        />
      </Form>

      <Divider hidden />

      <Button content="Prev" onClick={prevStep} />
      <Button
        content="Next"
        disabled={stepDisabled}
        onClick={nextStep}
        primary
      />
    </div>
  )
}
