import styled from 'styled-components'
import { Icon as I } from 'semantic-ui-react'

const Icon = styled(I)`
  color: ${(props) => (props.color ? props.color : props.theme.DarkIconColour)};
`

const IconButton = styled(I)`
  color: ${(props) =>
    props.color
      ? props.color
      : props.noColour
      ? null
      : props.theme.PrimaryColour};

  :hover {
    cursor: pointer;
    color: ${(props) =>
      props.backgroundColor
        ? props.backgroundColorHover || null
        : props.theme.PrimaryColourHover};
  }
`

export { IconButton, Icon }
