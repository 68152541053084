import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Input } from 'components/forms'
import { Button, Form, FormField, ErrorText } from 'components/ui'
import {
  changePasswordRequest,
  changePasswordRequestError,
} from 'containers/user'
import ErrorAlert from 'components/error'
import { Card } from 'components/ui'
import { Dispatch, ErrorState, IState } from 'interfaces'

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string()
    .min(6, 'Password must be minimum 6 characters')
    .required('New Password is required'),
  reenterNewPassword: Yup.string()
    .min(6, 'Password must be minimum 6 characters')
    .required('You must re-enter your new password'),
})

interface Props {
  changePasswordRequest: (
    oldPassword: string,
    newPassword: string,
  ) => Promise<any>
  changePasswordRequestError: (error: ErrorState) => any
  passwordChangeError: ErrorState
}

class Security extends React.Component<Props> {
  state = {
    oldPassword: '',
    newPassword: '',
    reenterNewPassword: '',
  }

  onSubmit = (values: any, setSubmitting: Function, resetForm: Function) => {
    if (values.newPassword === values.reenterNewPassword) {
      this.props
        .changePasswordRequest(values.oldPassword, values.newPassword)
        .then((e) => {
          const hasError = e ? e.error : false
          setSubmitting(false)
          if (hasError) {
            this.props.changePasswordRequestError({
              error: true,
              message: 'Failed to change your password',
            })
          } else {
            resetForm()
          }
        })
        .catch(() => {
          this.props.changePasswordRequestError({
            error: true,
            message: 'Failed to change your password',
          })
          setSubmitting(false)
        })
    } else {
      this.props.changePasswordRequestError({
        error: true,
        message: 'The new passwords did not match',
      })
      setSubmitting(false)
    }
  }

  handleFormChange = (changedFields: any) => {
    this.setState((state) => ({
      ...state,
      ...changedFields,
    }))
  }

  render() {
    let errorMessage = this.props.passwordChangeError.details

    if (errorMessage === 'auth/wrong-password') {
      errorMessage = 'Incorrect Password'
    }

    return (
      <Card title="Password">
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            reenterNewPassword: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.onSubmit(values, setSubmitting, resetForm)
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                icon="lock"
                label="Old Password"
                name="oldPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Old Password"
                type="password"
              />
              <ErrorText>
                {errors.oldPassword &&
                  touched.oldPassword &&
                  errors.oldPassword}
              </ErrorText>

              <Input
                icon="lock"
                label="New Password"
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="New Password"
                type="password"
                validationRules={{ required: true }}
              />
              <ErrorText>
                {errors.newPassword &&
                  touched.newPassword &&
                  errors.newPassword}
              </ErrorText>

              <Input
                icon="lock"
                label="Re-enter New Password"
                name="reenterNewPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="New Password"
                type="password"
              />
              <ErrorText>
                {errors.reenterNewPassword &&
                  touched.reenterNewPassword &&
                  errors.reenterNewPassword}
              </ErrorText>

              <FormField>
                <Button loading={isSubmitting} primary type="submit">
                  Change Password
                </Button>
              </FormField>
            </Form>
          )}
        </Formik>

        <ErrorAlert errorMsg={errorMessage} hasError={!!errorMessage} />
      </Card>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  // changingPassword: state.user.changingPassword,
  passwordChangeError: state.user.passwordChangeError,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changePasswordRequest: (oldPassword: string, newPassword: string) =>
    dispatch(changePasswordRequest(oldPassword, newPassword)),
  changePasswordRequestError: (error: ErrorState) =>
    dispatch(changePasswordRequestError(error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Security)
