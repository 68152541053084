// @ts-ignore-file

import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import localforage from 'localforage'

import { PrivateWrapper, PublicWrapper } from 'components/routes'
import Home from 'components/home/home'
import AuthPage from 'containers/auth'
import Dashboard from 'containers/dashboard/dashboard'
import Account, { fetchOffices, Logout } from 'containers/account'
import ClientInfo from 'containers/clients/client-info'
import ClientsView from 'containers/clients/clients-view/clients-view'
import HeaderBar from 'components/nav/header-bar'
import * as Ducks from 'reducers/ducks'
import { setUser, fetchClientTrackerUser } from 'containers/user'
import { fetchTodaysClients, fetchAllClients } from 'containers/clients'
import { fetchSettings, fetchLSSettings, getSetting } from 'containers/settings'
import { LS_SIDE_BAR_STATE, LS_REMEMBER_ME } from 'constants/local-storage'
import { FailedToAuth, NotFound, VerifyEmail } from 'components/response-status'
import { auth } from 'config/firebase'
import { getAuthToken } from 'lib/auth'
import { Spinner } from 'components/loaders'
import { MobileBreakPoint } from 'styles'
import { PublicIntake } from 'containers/public-intake'
import { fetchFeatures } from 'containers/features'
import { AdminPanel } from 'containers/features/admin/admin-panel'
import { SubscriptionManager } from 'containers/emails'
import SideBar from 'components/nav/side-bar'
import SideBarMobile from 'components/nav/side-bar-mobile'

import {
	fetchAppointments,
	getClientIDsByAppointmentsForToday,
} from 'containers/appointments'
import { Calendar } from 'containers/calendar'
import { THEME_SETTING } from 'constants/settings'
import { fetchAllTreatments } from 'containers/treatment'
import { getTheme } from 'styles/themes'
import { FilesView } from 'containers/files'
import { CLIENT_TRACKYR_STORE, FLAGS } from 'constants/storage'

import Notifications from './components/notifications/notifications'
import { IQueryParams, IResponse } from 'interfaces/fetch'
import { IState, Dispatch, IUser, UserState } from 'interfaces'
import { ReportsView } from 'containers/reports'
import { FirstTimeSetup } from 'containers/new-user'
import { AppFooter, AppLayoutNoSideBar } from 'containers/app'
import { ArchivedClientsView } from 'containers/clients/clients-view/archived-clients-view'

const store = localforage.createInstance({
	name: CLIENT_TRACKYR_STORE,
	storeName: FLAGS,
})

const AppContainer = styled.div`
  background-color: ${(props) => props.theme.BackgroundColour};
`

interface IAppLayout {
	authenticated: boolean
	sidebarCollapsed: boolean
}

const AppLayout = styled.div<IAppLayout>`
  display: grid;
  grid-template-columns: ${(props) =>
		props.authenticated
			? props.sidebarCollapsed
				? '4.0em auto'
				: '10em auto'
			: '0 auto'};
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  grid-template-areas:
		'sidebar header'
		'sidebar content'
		'footer footer';

  @media (max-width: ${MobileBreakPoint}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 0 1fr auto;

    grid-template-areas:
			'header'
			'sidebar'
			'content'
			'footer';
  }

  background-color: ${(props) => props.theme.BackgroundColour};
  color: ${(props) => props.theme.TextColour};
`

const AppHeader = styled.div`
  background: ${(props) => props.theme.BackgroundColour};
  padding: 0;
  height: auto;
  margin: 0;
  line-height: normal;
  grid-area: header;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
`

const AppContent = styled.div`
  grid-area: content;
`

const AppSideBar = styled.div`
  position: fixed;
  top: 0;
  grid-area: sidebar;
  z-index: 8;

  @media (max-width: ${MobileBreakPoint}) {
    visibility: hidden;
    height: 0;
    max-height: 0;
  }
`

const AppSideBarMobile = styled.div`
  background-color: ${(props) => props.theme.BackgroundColour};
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  background-color: ${(props) => props.theme.BackgroundColour};
`

const Sticky = styled.div`
  display: inline;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

class App extends React.Component<IApp> {
	authTokenListener: Function
	verificationListener: any
	contextRef = createRef()
	state = {
		errorLogginIn: false,
		sidebarMobileCollapsed: true,
	}

	componentDidMount() {
		this.props.setLoading(true)

		this.authTokenListener = auth.onIdTokenChanged(async (user) => {
			const authenticated = !!user

			if (user && user.uid !== this.props.user.user.uid) {
				this.props.setLoading(true)

				if (await store.getItem(LS_REMEMBER_ME)) {
					getAuthToken()
				}

				const {
					uid,
					displayName,
					photoURL,
					email,
					emailVerified,
					phoneNumber,
				} = user
				this.props.setUser({
					uid,
					displayName,
					photoURL,
					email,
					emailVerified,
					phoneNumber,
				})

				Sentry.setUser({
					email: email,
					id: uid,
				})

				this.props
					.fetchClientTrackerUser(user)
					.then((response: IResponse<IUser>) => {
						this.props.onAuthentication(authenticated)

						if (response.status === 200) {
							this.setState(() => ({ errorLogginIn: false }))
							this.fetchInitialData()

							if (
								authenticated &&
								!(emailVerified || response.data.isVerified)
							) {
								this.verificationListener = setInterval(
									this.pollVerification,
									7500,
								)
							}
						} else {
							this.setState(() => ({ errorLogginIn: true }))
							this.props.setLoading(false)
						}
					})
			} else {
				this.props.onAuthentication(authenticated)
				this.props.setLoading(false)
			}
		})
	}

	componentWillUnmount() {
		this.authTokenListener && this.authTokenListener()
	}

	pollVerification = () => {
		if (auth.currentUser.emailVerified || this.props.user.ctUser.isVerified) {
			clearInterval(this.verificationListener)
			this.props.fetchClientTrackerUser()
			window.location.reload()
		}

		// firebase
		//   .auth()
		//   .currentUser.getIdToken(true)
		//   .then(() => firebase.auth().currentUser.reload())
	}

	fetchInitialData = () => {
		Promise.all([
			this.props.loadAppData(),
			this.props
				.fetchSettings()
				.then(() => {
					this.props.setLoading(false)
				})
				.catch(() => {
					this.props.setLoading(false)
				}),
			this.props.fetchFeatures(),
			this.fetchAllClients(),
			this.props.fetchLSSettings(),
			this.props.fetchOffices(),
			this.props.fetchAllTreatments(),
		]).then(() => {
			this.props.fetchTodaysClients(this.props.todaysClientIDs)
		})

		// Get 4 months of appointments data
		const now = moment() // moment months 0 - 11
		this.props.fetchAppointments({
			year: now.get('year'),
			month: now.get('month') + 1,
		})

		// Fetch Next 2 Months too
		now.add(1, 'months')
		this.props.fetchAppointments({
			year: now.get('year'),
			month: now.get('month') + 1,
		})
		now.add(1, 'months')
		this.props.fetchAppointments({
			year: now.get('year'),
			month: now.get('month') + 1,
		})

		// Fetch Previous 3 Months
		now.subtract(3, 'months')
		this.props.fetchAppointments({
			year: now.get('year'),
			month: now.get('month') + 1,
		})
		now.subtract(1, 'months')
		this.props.fetchAppointments({
			year: now.get('year'),
			month: now.get('month') + 1,
		})
		now.subtract(1, 'months')
		this.props.fetchAppointments({
			year: now.get('year'),
			month: now.get('month') + 1,
		})
	}

	fetchAllClients = async () => {
		const CLIENT_PAGE_SIZE = 10
		const pageData: IQueryParams = {
			take: CLIENT_PAGE_SIZE,
			skip: 0,
		}

		const response = await this.props.fetchAllClients(pageData)
		const totalCount = response?.data?.totalCount || 0
		const additionalFetches = Math.ceil(totalCount / CLIENT_PAGE_SIZE) - 1

		for (let i = 0; i < additionalFetches; i++) {
			pageData.skip = pageData.skip + CLIENT_PAGE_SIZE
			await this.props.fetchAllClients(pageData)
		}
	}

	sidebarOnCollapse = async () => {
		this.props.sidebarOnCollapse()
		await store.setItem(LS_SIDE_BAR_STATE, `${!this.props.sidebarCollapsed}`)
	}

	sidebarMobileOnCollapse = () => {
		this.setState(() => ({
			sidebarMobileCollapsed: true,
		}))
	}

	render() {
		const { authenticated, sidebarCollapsed, loading, onLogout, user } =
			this.props

		const theme = getTheme(this.props.theme)

		let errorComponent = null

		if (authenticated && this.state.errorLogginIn) {
			errorComponent = <FailedToAuth />
		} else if (
			authenticated &&
			!(user.ctUser.isVerified || user.user.emailVerified)
		) {
			errorComponent = <VerifyEmail />
		} else if (authenticated && !user.ctUser.isSetup) {
			errorComponent = <FirstTimeSetup />
		}

		if (errorComponent !== null) {
			return (
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<AppContainer>
							<AppLayoutNoSideBar
								authenticated={authenticated}
								className='app'
								sidebarCollapsed={sidebarCollapsed}
							>
								<AppHeader>
									<HeaderBar
										authenticated={authenticated}
										collapsed={sidebarCollapsed}
										hideDetailsOnUnverified
										onCollapse={this.sidebarOnCollapse}
										onLogout={onLogout}
										onMobileCollapse={() =>
											this.setState(() => ({
												sidebarMobileCollapsed: false,
											}))
										}
									/>
								</AppHeader>

								<AppContent>{errorComponent}</AppContent>

								<AppFooter authenticated={authenticated} />
							</AppLayoutNoSideBar>
						</AppContainer>
					</ThemeProvider>
				</BrowserRouter>
			)
		}

		const App = (
			<AppLayout
				authenticated={authenticated}
				className='app'
				sidebarCollapsed={sidebarCollapsed}
			>
				<Notifications />

				<AppSideBarMobile>
					<SideBarMobile
						authenticated={authenticated}
						collapsed={this.state.sidebarMobileCollapsed}
						onMobileCollapse={this.sidebarMobileOnCollapse}
						// routerLocation={{} // TODO - fix
						// this.props.router ? this.props.router.location : {}
						user={user.user}
					/>
				</AppSideBarMobile>

				<AppSideBar>
					<Sticky>
						<SideBar
							authenticated={authenticated}
							collapsed={sidebarCollapsed}
							// routerLocation={{} // TODO - fix
							// this.props.router ? this.props.router.location : {}
							user={user.user}
						/>
					</Sticky>
				</AppSideBar>

				<AppHeader>
					<HeaderBar
						authenticated={authenticated}
						collapsed={sidebarCollapsed}
						onCollapse={this.sidebarOnCollapse}
						onLogout={onLogout}
						onMobileCollapse={() =>
							this.setState(() => ({
								sidebarMobileCollapsed: false,
							}))
						}
					/>
				</AppHeader>

				<AppContent>
					<Routes>
						<Route element={<PublicWrapper authenticated={authenticated} />}>
							<Route element={<Home />} path='/' />
						</Route>

						<Route element={<PublicWrapper authenticated={authenticated} />}>
							<Route element={<Home />} path='/home' />
						</Route>

						<Route element={<PublicWrapper authenticated={authenticated} />}>
							<Route element={<AuthPage />} path='/auth/:tab?' />
						</Route>

						<Route element={<PublicWrapper authenticated={authenticated} />}>
							<Route element={<PublicIntake />} path='/intake-forms/:clientID/:link/:p?' />
						</Route>

						<Route element={<PublicWrapper authenticated={authenticated} />}>
							<Route element={<SubscriptionManager />} path='/email-subscriptions/:subID/:email' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<Logout />} path='/logout' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<Dashboard />} path='/dashboard' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<Calendar />} path='/calendar/:clientID?' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<Calendar />} path='/calendar/date/:date?' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<Account />} path='/account/:tab?' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<Account />} path='/account/:tab?/:subtab?' />
						</Route>


						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<Account />} path='/account/:tab?/:subtab?/?:action' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ClientsView />} path='/clients' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ArchivedClientsView />} path='/clients/archived' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ClientInfo />} path='/client/:clientID/:tab' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ClientInfo />} path='/client/:clientID/:tab/:appointmentID' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ClientInfo />} path='/client/:clientID/:tab/treatment/:treatmentNumber?' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ClientInfo />}
								path='/client/:clientID/:tab/:appointmentID/treatment/:treatmentNumber?'
							/>
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ClientInfo />} path='/client/:clientID?' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<FilesView />} path='/files' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<ReportsView />} path='/reports/:tab?' />
						</Route>

						<Route element={<PrivateWrapper authenticated={authenticated} />}>
							<Route element={<AdminPanel />} path='/admin' />
						</Route>

						<Route element={<NotFound />} path='/' />
					</Routes>
				</AppContent>

				<AppFooter authenticated={authenticated} />
			</AppLayout>
		)

		return (
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<AppContainer>
						{loading ? <Spinner size='big' spinning tip='Loading...' /> : App}
					</AppContainer>
				</ThemeProvider>
			</BrowserRouter>
		)
	}
}

interface IApp {
	authenticated: boolean
	fetchAllClients: Function
	fetchAllTreatments: Function
	fetchAppointments: Function
	fetchClientTrackerUser: Function
	fetchFeatures: Function
	fetchLSSettings: Function
	fetchOffices: Function
	fetchSettings: Function
	fetchTodaysClients: Function
	loadAppData: Function
	loading: boolean
	onAuthentication: Function
	onLogout: Function
	setLoading: Function
	setUser: Function
	sidebarCollapsed: boolean
	sidebarOnCollapse: Function
	theme: string
	todaysClientIDs: Array<string>
	user: UserState // TODO - IUser
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onAuthentication: (authenticated: boolean) =>
		dispatch(Ducks.setAuthentication(authenticated)),
	onLogout: () => dispatch(Ducks.onLogout()),
	setLoading: (loading: boolean) => dispatch(Ducks.isLoading(loading)),
	sidebarOnCollapse: () => dispatch(Ducks.sidebarOnCollapse()),
	setUser: (user: IUser) => dispatch(setUser(user)),
	fetchTodaysClients: (todaysClientIDs: Array<string>) =>
		dispatch(fetchTodaysClients(todaysClientIDs)),
	loadAppData: () => dispatch(Ducks.loadAppData()),
	fetchAllClients: (queryParams: IQueryParams) =>
		dispatch(fetchAllClients(queryParams)),
	fetchClientTrackerUser: (user: IUser) =>
		dispatch(fetchClientTrackerUser(user)),
	fetchSettings: () => dispatch(fetchSettings()),
	fetchLSSettings: () => dispatch(fetchLSSettings()),
	fetchOffices: () => dispatch(fetchOffices()),
	fetchFeatures: () => dispatch(fetchFeatures()),
	fetchAppointments: (queryParams: IQueryParams, useCache = true) =>
		dispatch(fetchAppointments(queryParams, useCache)),
	fetchAllTreatments: () => dispatch(fetchAllTreatments()),
})

const mapStateToProps = (state: IState) => ({
	...state.app,
	user: state.user,
	theme: getSetting(state, THEME_SETTING),
	// HACK - rerenders on change here to use the right colour scheme on system change
	themeSystemDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
	themeSystemLight: window.matchMedia('(prefers-color-scheme: light)').matches,
	todaysClientIDs: getClientIDsByAppointmentsForToday(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
