import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Address } from 'components/forms'
import { Modal, Checkbox, Input, Form, Button, Header } from 'components/ui'

import OfficeHours from './office-hours'
import {
	onOfficeChange,
	onTimeSlotChange as onTimeSlotChangeDucks,
	onRemoveTimeSlot as onRemoveTimeSlotDucks,
	onAddTimeSlot as onAddTimeSlotDucks,
} from './redux/ducks'
import { getOffices$ } from './redux/selectors'
import { Dispatch, IState } from 'interfaces'

const OfficeModal = (props: IOfficeModal) => {
	const dispatch: Dispatch = useDispatch()

	const [isDirty, setDirty] = useState(false)
	const [editing, setEditing] = useState(false)

	if (props.edit && !editing) {
		setEditing(true)
	}

	const offices = useSelector(getOffices$)
	const loading = useSelector((state: IState) => state.account.loading)
	const office = offices[props.id] || {}

	const onClose = () => {
		setDirty(false)
		setEditing(false)
	}

	const onChange = ({ target }) => {
		!isDirty && setDirty(true)
		dispatch(onOfficeChange(target.name, target.value, props.id))
	}

	const onTimeSlotChange = (name, value, day, slotIndex) => {
		!isDirty && setDirty(true)
		dispatch(onTimeSlotChangeDucks(name, value, day, slotIndex, props.id))
	}
	const onRemoveTimeSlot = (day, index) => {
		!isDirty && setDirty(true)
		dispatch(onRemoveTimeSlotDucks(day, index, props.id))
	}
	const onAddTimeSlot = (day) => {
		!isDirty && setDirty(true)
		dispatch(onAddTimeSlotDucks(day, props.id))
	}

	return (
		<Modal open={props.open} size="small">
			<Header
				content={
					editing ? (
						<Input
							name="name"
							onChange={onChange}
							placeholder="Office Name"
							value={office.name}
						/>
					) : (
						office.name
					)
				}
				icon="building outline"
			/>

			<Modal.Content>
				{!props.edit && (
					<Checkbox
						checked={editing}
						disabled={editing && isDirty}
						label="Edit"
						onChange={({ target }) => setEditing(target.value || isDirty)}
						toggle
					/>
				)}

				<Form>
					<Address
						address={office['office-address']}
						addressTwo={office['office-addressTwo']}
						city={office['office-city']}
						name="office"
						onChange={onChange}
						postalcode={office['office-postalcode']}
						province={office['office-province']}
						readonly={!editing}
					/>
				</Form>
				<div>
					{(editing || (office.days && !!office.days.length)) && (
						<OfficeHours
							addSlot={(dayID: number) => onAddTimeSlot(dayID)}
							editing={editing}
							onClick={(dayID: number) =>
								onChange({
									target: {
										name: 'days',
										value: office.days.some((d) => d.dayID === dayID)
											? office.days.filter((d) => d.dayID !== dayID)
											: [...office.days, { dayID, slots: [{}] }].sort(
													(a, b) => a.dayID - b.dayID,
											  ),
									},
								})
							}
							onTimeSlotChange={(name, value, dayID, index) =>
								onTimeSlotChange(name, value, dayID, index)
							}
							pickedDays={office.days}
							removeSlot={(dayID, index) => onRemoveTimeSlot(dayID, index)}
						/>
					)}
				</div>
			</Modal.Content>

			<Modal.Actions>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					{props.id && (
						<div>
							<Button
								loading={loading}
								negative
								onClick={() => {
									props.onDelete(props.id)
									onClose()
								}}
							>
								Delete
							</Button>
						</div>
					)}
					<div>
						<Button
							loading={loading}
							onClick={() => {
								editing && isDirty ? props.onCancel() : props.onClose()
								onClose()
							}}
							secondary
							style={{ marginRight: '0.5em' }}
						>
							{editing && isDirty ? 'Cancel' : 'Close'}
						</Button>
						{editing && isDirty && (
							<Button
								disabled={!isDirty}
								loading={loading}
								onClick={() => {
									onClose()
									props.onAccept()
								}}
								primary
							>
								Save
							</Button>
						)}
					</div>
				</div>
			</Modal.Actions>
		</Modal>
	)
}

interface IOfficeModal {
	edit?: boolean
	id: string
	onAccept: Function
	onCancel: Function
	onClose: Function
	onDelete: Function
	open?: boolean
}

OfficeModal.defaultProps = {
	edit: false,
}

export default OfficeModal
