import { SettingKey, Setting, FormScreen } from 'interfaces'
import {
  SUMMARY_TAB_ID,
  CLIENT_INFO_TAB_ID,
  NOTES_TAB_ID,
  FILES_TAB_ID,
} from './form'

export const CLIENT_SORT_ORDER = 'basic-settings:CLIENT_SORT_ORDER'
export const CLIENTS_PER_PAGE = 'basic-settings:CLIENTS_PER_PAGE'
export const PRACTICE_TYPE = 'basic-settings:PRACTICE_TYPE'

export const CLIENT_TYPES = 'client-settings:CLIENT_TYPES'
export const CLIENT_APPOINTMENT_COLOURS =
  'client-settings:CLIENT_APPOINTMENT_COLOURS'

export const SOAP_TYPES = 'soap-settings:SOAP_TYPES'

export const TAB_TYPES = 'tab-settings:TAB_TYPES'

export const CALENDAR_SLOT_DURATION = 'calendar-settings:CALENDAR_SLOT_DURATION'
export const CALENDAR_DEFAULT_APPOINTMENT_DURATION =
  'calendar-settings:CALENDAR_DEFAULT_APPOINTMENT_DURATION'
export const CALENDAR_SHOW_WEEKENDS = 'calendar-settings:CALENDAR_SHOW_WEEKENDS'
export const CALENDAR_DEFAULT_VIEW = 'calendar-settings:CALENDAR_DEFAULT_VIEW'
export const CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW =
  'calendar-settings:CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW'

export const DOCUMENT_VIEW_DRIVER = 'file-settings:DOCUMENT_VIEW_DRIVER'

export const STORAGE_LIMIT = 'storage:STORAGE_LIMIT'
export const STORAGE_USED = 'storage:STORAGE_USED'

export const SHOW_APPOINTMENT_DATE_IN_HEADER =
  'header:SHOW_APPOINTMENT_DATE_IN_HEADER'

export const BETA_USERS = 'users:BETA_USERS'

export const USER_BANNER_SQUARE = 'branding:USER_BANNER_SQUARE'
export const USER_BANNER_RECTANGLE = 'branding:USER_BANNER_RECTANGLE'

export const USER_AVATAR = 'user:USER_AVATAR'

export const ORG_NAME = 'org:ORG_NAME'
export const ORG_NAME_WITH_ADDRESS = 'org:ORG_NAME_WITH_ADDRESS'

export const PUBLIC_INTAKE_EMAIL_GREETING = 'email:PUBLIC_INTAKE_EMAIL_GREETING'
export const PUBLIC_INTAKE_EMAIL_SUBJECT = 'email:PUBLIC_INTAKE_EMAIL_SUBJECT'
export const PUBLIC_INTAKE_COMPANY = 'email:PUBLIC_INTAKE_COMPANY'
export const PUBLIC_INTAKE_FAREWELL = 'email:PUBLIC_INTAKE_FAREWELL'
export const INVOICE_EMAIL_SUBJECT = 'email:INVOICE_EMAIL_SUBJECT'
export const DEFAULT_SEND_APPOINTMENT_EMAIL =
  'email:DEFAULT_SEND_APPOINTMENT_EMAIL'

export const DEFAULT_SEND_APPOINTMENT_SMS = 'sms:DEFAULT_SEND_APPOINTMENT_SMS'
export const SMS_APPOINTMENT_MESSAGE = 'sms:SMS_APPOINTMENT_MESSAGE'
export const SMS_APPOINTMENT_DAY_BEFORE_MESSAGE =
  'sms:SMS_APPOINTMENT_DAY_BEFORE_MESSAGE'

export const APPOINTMENT_REMINDER_EMAIL_SUBJECT =
  'email:APPOINTMENT_REMINDER_EMAIL_SUBJECT'
export const APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT =
  'email:APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT'
export const APPOINTMENT_REMINDER_EMAIL_MESSAGE =
  'email:APPOINTMENT_REMINDER_EMAIL_MESSAGE'
export const APPOINTMENT_REMINDER_EMAIL_FAREWELL =
  'email:APPOINTMENT_REMINDER_EMAIL_FAREWELL'
export const APPOINTMENT_REMINDER_EMAIL_COMPANY =
  'email:APPOINTMENT_REMINDER_EMAIL_COMPANY'
export const APPOINTMENT_REMINDER_ICS_SUBJECT =
  'email:APPOINTMENT_REMINDER_ICS_SUBJECT'

export const THEME_SETTING = 'theme:THEME_SETTING'

export const FILE_TAGS = 'file:FILE_TAGS'

export const MIGRATIONS = 'MIGRATIONS'

export const INVOICE_TITLE = 'invoice:INVOICE_TITLE'
export const INVOICE_HEADERS = 'invoice:INVOICE_HEADERS'
export const INVOICE_TYPE = 'invoice:INVOICE_TYPE'
export const INVOICE_THERAPIST_LABEL = 'invoice:INVOICE_THERAPIST_LABEL'
export const INVOICE_NOTES = 'invoice:INVOICE_NOTES'
export const INVOICE_FOOTER = 'invoice:INVOICE_FOOTER'
export const INVOICE_FOOTER_TWO = 'invoice:INVOICE_FOOTER_TWO'
export const INVOICE_TABLE_COLOUR = 'invoice:INVOICE_TABLE_COLOUR'

export const allSettings = [
  CLIENT_SORT_ORDER,
  PRACTICE_TYPE,
  CLIENT_TYPES,
  CLIENT_APPOINTMENT_COLOURS,
  SOAP_TYPES,
  TAB_TYPES,
  CALENDAR_SLOT_DURATION,
  CALENDAR_DEFAULT_APPOINTMENT_DURATION,
  CALENDAR_DEFAULT_VIEW,
  CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW,
  DOCUMENT_VIEW_DRIVER,
  STORAGE_LIMIT,
  STORAGE_USED,
  SHOW_APPOINTMENT_DATE_IN_HEADER,
  BETA_USERS,
  USER_AVATAR,
  USER_BANNER_SQUARE,
  USER_BANNER_RECTANGLE,
  ORG_NAME,
  ORG_NAME_WITH_ADDRESS,
  PUBLIC_INTAKE_EMAIL_GREETING,
  PUBLIC_INTAKE_EMAIL_SUBJECT,
  PUBLIC_INTAKE_COMPANY,
  PUBLIC_INTAKE_FAREWELL,
  APPOINTMENT_REMINDER_EMAIL_SUBJECT,
  APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT,
  APPOINTMENT_REMINDER_EMAIL_MESSAGE,
  APPOINTMENT_REMINDER_EMAIL_FAREWELL,
  APPOINTMENT_REMINDER_EMAIL_COMPANY,
  APPOINTMENT_REMINDER_ICS_SUBJECT,
  THEME_SETTING,
  FILE_TAGS,
  MIGRATIONS,
  INVOICE_HEADERS,
  INVOICE_TITLE,
  INVOICE_TITLE,
  INVOICE_HEADERS,
  INVOICE_TYPE,
  INVOICE_THERAPIST_LABEL,
  INVOICE_NOTES,
  INVOICE_FOOTER,
  INVOICE_FOOTER_TWO,
  INVOICE_TABLE_COLOUR,
]

export const defaultTabs = [
  {
    id: SUMMARY_TAB_ID,
    order: 0,
    tab: 'Summary',
    default: true,
    key: 'summary',
  },
  {
    id: CLIENT_INFO_TAB_ID,
    order: 1,
    tab: 'Client Info',
    default: true,
    key: 'client-info',
  },
  { id: NOTES_TAB_ID, order: 2, tab: 'Notes', default: true, key: 'notes' },
  {
    id: FILES_TAB_ID,
    order: 3,
    tab: 'Files',
    default: true,
    key: 'files',
  },
]

export const documentViewDrivers = {
  default: { value: 'default', text: 'Default' },
  google: { value: 'google', text: 'Google Docs' },
  office: { value: 'office', text: 'Office 365' },
}

export const getHumanReadableSetting = (setting: SettingKey) => {
  switch (setting) {
    case CLIENT_SORT_ORDER:
      return 'Client Sort Order'

    case CLIENTS_PER_PAGE:
      return 'Client\'s per Page'

    case PRACTICE_TYPE:
      return 'Practice Type'

    case CLIENT_TYPES:
      return 'Client Types'

    case CLIENT_APPOINTMENT_COLOURS:
      return 'Client Calendar Colour'

    case SOAP_TYPES:
      return 'Notes Types'

    case CALENDAR_SLOT_DURATION:
      return 'Calendar Slot Duration'

    case CALENDAR_DEFAULT_APPOINTMENT_DURATION:
      return 'Calendar Default Appointment Duration'

    case CALENDAR_DEFAULT_VIEW:
      return 'Calendar Default View'

    case CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW:
      return 'Calendar Default New Appointment View'

    case TAB_TYPES:
      return 'Tab Types'

    case STORAGE_LIMIT:
      return 'Storage Limit'

    case STORAGE_USED:
      return 'Storage Used'

    case SHOW_APPOINTMENT_DATE_IN_HEADER:
      return 'Show Appointment Date in Header'

    case BETA_USERS:
      return 'Beta Users'

    case USER_AVATAR:
      return 'User Avatar'

    case USER_BANNER_SQUARE:
      return 'Square Banner'

    case USER_BANNER_RECTANGLE:
      return 'Rectangle Banner'

    case PUBLIC_INTAKE_EMAIL_SUBJECT:
    case APPOINTMENT_REMINDER_EMAIL_SUBJECT:
    case INVOICE_EMAIL_SUBJECT:
      return 'Subject'

    case APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT:
      return 'Reminder Email Subject'

    case PUBLIC_INTAKE_EMAIL_GREETING:
      return 'Greeting'

    case PUBLIC_INTAKE_COMPANY:
      return 'Company/Org Name'

    case PUBLIC_INTAKE_FAREWELL:
      return 'Farewell Message'

    case APPOINTMENT_REMINDER_EMAIL_MESSAGE:
      return 'Email Message'

    case APPOINTMENT_REMINDER_EMAIL_FAREWELL:
      return 'Farewell Message'

    case APPOINTMENT_REMINDER_EMAIL_COMPANY:
      return 'Company/Org Name'

    case APPOINTMENT_REMINDER_ICS_SUBJECT:
      return 'Calendar Event Title'

    case INVOICE_TITLE:
      return 'Title'

    case INVOICE_HEADERS:
      return 'Headers'

    case INVOICE_TYPE:
      return 'Type'

    case INVOICE_THERAPIST_LABEL:
      return 'Therapist Label'

    case INVOICE_NOTES:
      return 'Notes'

    case INVOICE_FOOTER:
      return 'Footer Label'

    case INVOICE_FOOTER_TWO:
      return 'Footer'

    case INVOICE_TABLE_COLOUR:
      return 'Table Colour'

    case DEFAULT_SEND_APPOINTMENT_EMAIL:
      return 'Send Appointment Email by Default'

    case DEFAULT_SEND_APPOINTMENT_SMS:
      return 'Send Appointment SMS by Default'

    case SMS_APPOINTMENT_MESSAGE:
      return 'SMS Appointment Message'

    case SMS_APPOINTMENT_DAY_BEFORE_MESSAGE:
      return 'SMS Appointment Day Before Reminder Message'

    case CALENDAR_SHOW_WEEKENDS:
      return 'Show Weekends in Calendar'

    default:
      return ''
  }
}

export const getDefaultSettings = (setting: SettingKey): string | any => {
  switch (setting) {
    case CLIENT_SORT_ORDER:
      return 'firstName'

    case CLIENTS_PER_PAGE:
      return 15

    case CLIENT_APPOINTMENT_COLOURS:
      return {}

    case CALENDAR_SLOT_DURATION:
      return '00:30:00'

    case CALENDAR_DEFAULT_APPOINTMENT_DURATION:
      return '60'

    case CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW:
      return 'timeGridDay'

    case TAB_TYPES:
      return defaultTabs

    case DOCUMENT_VIEW_DRIVER:
      return 'default'

    case STORAGE_LIMIT:
      return 512000000 // 512 MB

    case SHOW_APPOINTMENT_DATE_IN_HEADER:
      return 'yes'

    case PUBLIC_INTAKE_EMAIL_SUBJECT:
      return 'Please Complete Your Intake Form'

    case PUBLIC_INTAKE_EMAIL_GREETING:
      return 'Hello {CLIENT_NAME}\nPlease complete your intake form before your next appointment.'

    case PUBLIC_INTAKE_COMPANY:
      return '{ORG_ADDRESS}'

    case PUBLIC_INTAKE_FAREWELL:
      return 'Thanks, we look forward to seeing you at your next appointment.\n{USER_NAME}'

    case THEME_SETTING:
      return 'system'

    case APPOINTMENT_REMINDER_EMAIL_SUBJECT:
      return 'Appointment Reminder'

    case APPOINTMENT_REMINDER_DAY_BEFORE_REMINDER_EMAIL_SUBJECT:
      return 'Appointment {APPOINTMENT_START_TIME_FORMATTED_WITH_VERB} Reminder'

    case APPOINTMENT_REMINDER_EMAIL_MESSAGE:
      return 'Hello,\nThis is a reminder for your upcoming appointment.'

    case APPOINTMENT_REMINDER_EMAIL_FAREWELL:
      return '{USER_NAME}'

    case APPOINTMENT_REMINDER_EMAIL_COMPANY:
      return '{ORG_ADDRESS}'

    case APPOINTMENT_REMINDER_ICS_SUBJECT:
      return 'Appointment'

    case FILE_TAGS:
      return 'image,doc,excel,pdf'

    case INVOICE_TITLE:
      return 'Receipt for Appointment'

    case INVOICE_FOOTER_TWO:
      return 'Thank you for your patronage'

    case INVOICE_TABLE_COLOUR:
      return '#c3ddf4'

    case INVOICE_EMAIL_SUBJECT:
      return 'Invoice for your appointment'

    case DEFAULT_SEND_APPOINTMENT_EMAIL:
      // TODO - this makes sense, RadioGroup can't handle booleans for w.e. reason. Will fix one day
      return 'false'

    case DEFAULT_SEND_APPOINTMENT_SMS:
      return 'false'

    case SMS_APPOINTMENT_MESSAGE:
      return 'Hello {CLIENT_NAME}, you have an appointment scheduled with {USER_NAME} on {APPOINTMENT_START_TIME}'

    case SMS_APPOINTMENT_DAY_BEFORE_MESSAGE:
      return 'Hello {CLIENT_NAME}, just a reminder, you have an appointment scheduled with {USER_NAME} {APPOINTMENT_START_TIME_FORMATTED_WITH_VERB}'

    case CALENDAR_SHOW_WEEKENDS:
      return 'true'

    case CALENDAR_DEFAULT_VIEW:
      return 'month'

    default:
      return ''
  }
}

export const parseFromAPI = (setting: Setting) => {
  switch (setting.setting) {
    case TAB_TYPES:
    case CLIENT_APPOINTMENT_COLOURS: {
      return JSON.parse(setting.value)
    }

    case CLIENTS_PER_PAGE:
      return Number(setting.value)

    default:
      return setting.value
  }
}

export const getFormScreenFromFormType = (
  type: typeof CLIENT_TYPES | typeof SOAP_TYPES,
) => {
  switch (type) {
    case CLIENT_TYPES:
      return FormScreen.Client
    case SOAP_TYPES:
      return FormScreen.Soap
    default:
      return FormScreen.Unknown
  }
}

export const getFormTypeFromFormScreen = (type: FormScreen) => {
  switch (type) {
    case FormScreen.Client:
      return CLIENT_TYPES
    case FormScreen.Soap:
      return SOAP_TYPES
    default:
      return ''
  }
}

// export const prepareForAPI = (setting: Setting) => {}
