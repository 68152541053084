import React from 'react'

import { FormField, Button, OptionalSegment } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { AutoSave } from 'components/forms/interfaces'

interface MultiFormProps {
	addText: string
	autoSave: AutoSave
	children: any
	count: number
	hideSegment?: boolean
	max?: number
	name: string
	onChange: Function
	readonly?: boolean
	removeText: string
	values: object
}

const MultiForm = (props: MultiFormProps) => {
	const count = props.count <= 0 ? 1 : props.count
	const element = []

	for (let i = 0; i < count; i++) {
		element.push(
			<>
				<OptionalSegment hideSegment={props.hideSegment}>
					{props.children?.map((child) => {
						let additionalProps = {}
						let defaultValue: string | Array<any> = ''

						if (
							child.props.name &&
							child.props.name.toLowerCase().includes('phone')
						) {
							defaultValue = []
						}

						if (!child.props.children) {
							additionalProps = {
								name: `${props.name}:${child.props.name}-${i}`,
								value:
									props.values[`${props.name}:${child.props.name}-${i}`] ||
									defaultValue,
							}
						} else {
							additionalProps = {
								name: `${props.name}:${child.props.children.props.name}-${i}`,
								value:
									props.values[
										`${props.name}:${child.props.children.props.name}-${i}`
										] || defaultValue,
							}
						}

						return (
							<FormField key={`${child.props.name}-${i}`}>
								{React.cloneElement(child, {
									...additionalProps,
								})}
							</FormField>
						)
					})}
				</OptionalSegment>
			</>,
		)
	}

	const isNotMax = props.max ? props.count < props.max : true

	const onAdd = () => {
		const target = {
			name: `${props.name}-count`,
			value: count + 1,
		}
		props.onChange({ target })
		return { target }
	}

	const onRemove = () => {
		const target = { name: `${props.name}-count`, value: count - 1 }
		props.onChange({ target })

		// TODO - remove items as well - as prop?
		return { target }
	}

	return (
		<div style={{ paddingBottom: '0.5em' }}>
			{element}
			{!props.readonly && (
				<div style={{ display: 'flex' }}>
					{isNotMax && (
						<AutoSaveWrapper
							afterAutoSaveCallback={props.autoSave.afterAutoSaveCallback}
							autosaveDisabled={props.autoSave.autosaveDisabled}
							autosaveMode='onClick'
							endpoint={props.autoSave.patchEndpoint}
							entityID={props.autoSave.entityID}
							formType={props.autoSave.patchType}
							patch={props.autoSave.patch}
						>
							<Button
								content={props.addText}
								onClick={onAdd}
								primary
								type='button'
							/>
						</AutoSaveWrapper>
					)}

					{count > 1 && (
						<AutoSaveWrapper
							afterAutoSaveCallback={props.autoSave.afterAutoSaveCallback}
							autosaveDisabled={props.autoSave.autosaveDisabled}
							autosaveMode='onClick'
							endpoint={props.autoSave.patchEndpoint}
							entityID={props.autoSave.entityID}
							formType={props.autoSave.patchType}
							patch={props.autoSave.patch}
						>
							<Button
								content={props.removeText}
								onClick={onRemove}
								secondary
								type='button'
							/>
						</AutoSaveWrapper>
					)}
				</div>
			)}
		</div>
	)
}

MultiForm.defaultProps = {
	addText: 'Add',
	autoSave: {
		autosaveDisabled: true,
	},
	removeText: 'Remove',
}

export default MultiForm
