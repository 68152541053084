/**
 * takes an array and splits it up into managable chunks based on the page
 * @param  {Array} items          Items to Paginate
 * @param  {Number} page          Current Page
 * @param  {Number} itemsPerPage  Number of items to display at one time
 * @return {Array}                Array split based on page and items per page
 */
export const paginateLocally = (
  items: Array<any>,
  page: number,
  itemsPerPage: number,
): Array<any> => items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
