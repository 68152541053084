import { authDelete } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { invalidateCacheLike } from 'lib'
import { Dispatch, IAction, IFile, IResponse } from 'interfaces'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const DELETE_FILE = filesAction('DELETE_FILE')
const DELETE_FILE_SUCCESS = filesAction('DELETE_FILE_SUCCESS')
const DELETE_FILE_FAILURE = filesAction('DELETE_FILE_FAILURE')

export const defaultState = {
  deletingFile: false,
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case DELETE_FILE: {
      return {
        ...state,
        deletingFile: true,
      }
    }

    case DELETE_FILE_SUCCESS: {
      return {
        ...state,
        deletingFile: false,
      }
    }

    case DELETE_FILE_FAILURE:
      return {
        ...state,
        deletingFile: false,
      }

    default:
      return state
  }
}

export const deleteFile = (id: string): any => async (
  dispatch: Dispatch,
): Promise<IResponse<IFile>> => {
  dispatch({ type: DELETE_FILE, id })

  return authDelete(Storage.Delete(id))
    .then((response: IResponse<IFile>) => {
      dispatch(deleteFileSuccess(response.data))
      invalidateCacheLike('/api/files')
      message.success('File Deleted')

      return response
    })
    .catch((error) => {
      captureMessage(
        `Failed to fetch File. FileID: ${id}. ${error}`,
        Severity.Error,
      )
      dispatch(deleteFileFailure(error))
      message.error('Failed to Delete File')

      return error
    })
}

const deleteFileSuccess = (file: IFile) => ({
  type: DELETE_FILE_SUCCESS,
  file,
})

const deleteFileFailure = (error: any) => ({
  type: DELETE_FILE_FAILURE,
  error,
})
