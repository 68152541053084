import { serverURL } from 'config/config'
import { CLIENT_TYPES, SOAP_TYPES } from 'constants/settings'
import { IQueryParams } from 'interfaces/fetch'
import { SettingKey } from 'interfaces'

export const getServer = () => serverURL

const Endpoints = {
	Account: '/account',
	Appointments: '/appointment',
	Audits: '/api/audit',
	Client: '/api/client',
	Email: '/api/email',
	Features: '/api/features',
	Files: '/api/files',
	Forms: '/forms',
	PDF: '/api/pdf',
	Ping: '/ping',
	PublicIntake: '/intake',
	Receipts: '/api/receipts',
	Reports: '/api/reports',
	Services: '/api/user-services',
	Settings: '/settings',
	Soaps: '/soaps',
	Storage: '/storage',
	SMS: '/api/sms',
	Tasks: '/api/tasks',
	Taxes: '/api/taxes',
	Treatments: '/treatments',
	User: '/user',
}

const sharedFileURLServer = (userID: string, fileID: string) =>
	`${getServer()}${Endpoints.Storage}/shared-file-public/${userID}/${fileID}`

export const Appointments = {
	GetAll: (queryParams: IQueryParams) =>
		`${Endpoints.Appointments}${getQueryParams(queryParams)}`,
	Post: `${Endpoints.Appointments}`,
	GetAllForClient: (
		clientID: string,
		treatmentNumber: number,
		queryParams: any,
	) =>
		`${
			Endpoints.Appointments
		}/client/${clientID}/treatment/${treatmentNumber}${getQueryParams(
			queryParams,
		)}`,
	Get: (id: string) => `${Endpoints.Appointments}/${id}`,
	Delete: (id: string) => `${Endpoints.Appointments}/${id}`,
}

export const Audits = {
	GetAll: (queryParams: IQueryParams) =>
		`${Endpoints.Audits}${getQueryParams(queryParams)}`,
}

export const Client = {
	TodaysClients: (clientIDs: string) =>
		`${Endpoints.Client}/todays-clients/${clientIDs}`,
	AllClients: (queryParams: IQueryParams) =>
		`${Endpoints.Client}${getQueryParams(queryParams)}`,
	SaveClient: `${Endpoints.Client}`,
	Details: (clientID: string, treatmentNumber: number) =>
		`${Endpoints.Client}/${clientID}/treatment/${treatmentNumber}`,
	Patch: `${Endpoints.Client}`,
	Archive: (id: string) => `${Endpoints.Client}/${id}`,
	UnArchive: (id: string) => `${Endpoints.Client}/${id}/unarchive`,
}

export const User = {
	Login: `${Endpoints.User}/login`,
	Register: `${Endpoints.User}/register`,
	UserSetup: `${Endpoints.User}/user-setup`,
	AdminAll: `${Endpoints.User}/admin/all`,
	Save: `${Endpoints.User}`,
}

export const Account = {
	Account: `${Endpoints.Account}/`,
	GetOffices: `${Endpoints.Account}/office`,
	DeleteOffice: (id: string) => `${Endpoints.Account}/office/${id}`,
	SaveOffice: `${Endpoints.Account}/office`,
}

export const Reports = {
	Invoices: (queryParams?: IQueryParams) => `${Endpoints.Reports}/invoices${getQueryParams(queryParams)}`,
}

export const Settings = {
	AllSettings: `${Endpoints.Settings}`,
	GetSetting: (settingKey: SettingKey) => `${Endpoints.Settings}/${settingKey}`,
	SaveSettings: `${Endpoints.Settings}`,
	SaveBodyDiagram: `${Endpoints.Settings}/body-diagram`,
	FetchBodyDiagrams: `${Endpoints.Settings}/body-diagrams`,
	FormType: (type: string) =>
		type === CLIENT_TYPES
			? `${Endpoints.Settings}/client-type`
			: type === SOAP_TYPES
				? `${Endpoints.Settings}/soap-type`
				: null,
	FormTypeV2: `${Endpoints.Settings}/form-type`,
	DeleteFormType: (id: string) => `${Endpoints.Settings}/form-type/${id}`,
	GetDefaultFormType: (practiceType: string, type: string) =>
		type === CLIENT_TYPES
			? `${Endpoints.Settings}/client-type/default/${practiceType}`
			: type === SOAP_TYPES
				? `${Endpoints.Settings}/soap-type/default/${practiceType}`
				: null,
	RestoreFormType: (id: string) =>
		`${Endpoints.Settings}/restore-form-type/${id}`,
	BetaUsers: `${Endpoints.Settings}/beta-users`,
}

export const Soaps = {
	GetSoap: (soapID: string) => `${Endpoints.Soaps}/${soapID}`,
	GetAll: (queryParams?: IQueryParams) =>
		`${Endpoints.Soaps}${getQueryParams(queryParams)}`,
	GetSoapByAppointment: (
		clientID: string,
		appointmentID: string,
		treatmentNumber: number,
	) =>
		`${Endpoints.Soaps}/client/${clientID}/appointment/${appointmentID}/treatment/${treatmentNumber}`,
	GetSoapsForClient: (clientID: string, treatmentNumber: number, page: IPage) =>
		page.filter
			? `${Endpoints.Soaps}/client/${clientID}/treatment/${treatmentNumber}?filter=${page.filter}&skip=${page.skip}&take=${page.take}`
			: `${Endpoints.Soaps}/client/${clientID}/treatment/${treatmentNumber}?skip=${page.skip}&take=${page.take}`,
	SaveSoap: `${Endpoints.Soaps}`,
	Patch: `${Endpoints.Soaps}`,
	PreviousAppointment: (appointmentID: string) => `${Endpoints.Soaps}/previousNotesTo/${appointmentID}`,
}

export const Tasks = {
	Post: `${Endpoints.Tasks}`,
	GetQueue: `${Endpoints.Tasks}/queue`,
}

export const Taxes = {
	Calculate: `${Endpoints.Taxes}/calculate`,
	Fetch: (queryParams?: IQueryParams) =>
		`${Endpoints.Taxes}${getQueryParams(queryParams)}`,
	GetByID: (id: string) => `${Endpoints.Taxes}/${id}`,
	Post: `${Endpoints.Taxes}`,
	Delete: (id: string) => `${Endpoints.Taxes}/${id}`,
}

export const Treatments = {
	GetAll: `${Endpoints.Treatments}`,
	SaveTreatment: `${Endpoints.Treatments}`,
	GetTreatments: (clientID: string) => `${Endpoints.Treatments}/${clientID}`,
	GetPreviousTreatments: (clientID: string) =>
		`${Endpoints.Treatments}/${clientID}/previous`,
	DeleteTreatment: (clientID: string) =>
		`${Endpoints.Treatments}/${clientID}/close`,
}

export const PDF = {
	GetReceipt: (receiptNumber: number) =>
		`${Endpoints.PDF}/receipt/${receiptNumber}`,
	GeneratePreview: `${Endpoints.PDF}/receipt/data`,
}

export const Receipts = {
	Fetch: (queryParams?: IQueryParams) =>
		`${Endpoints.Receipts}${getQueryParams(queryParams)}`,
	GetByID: (receiptNumber: number) => `${Endpoints.Receipts}/${receiptNumber}`,
	Post: `${Endpoints.Receipts}`,
	Delete: (receiptNumber: number) => `${Endpoints.Receipts}/${receiptNumber}`,
	Taxes: (amount: number, province: string) =>
		`${Endpoints.Receipts}/taxes/amount/${amount}/province/${province}`,
	Email: `${Endpoints.Receipts}/email`,
}

export const Services = {
	Fetch: (queryParams?: IQueryParams) =>
		`${Endpoints.Services}${getQueryParams(queryParams)}`,
	GetByID: (id: string) => `${Endpoints.Services}/${id}`,
	Post: `${Endpoints.Services}`,
	Delete: (id: string) => `${Endpoints.Services}/${id}`,
}

export const Storage = {
	Avatar: `${Endpoints.Storage}/avatar`,
	BodyDiagram: `${Endpoints.Storage}/body-diagram`,
	GetFile: (id: string) => `${Endpoints.Storage}/${id}`,
	Form: (formID: string) => `${Endpoints.Storage}/form/${formID}`,
	UploadForm: `${Endpoints.Storage}/forms`,
	ClientForm: (clientID: string, entityID?: string) =>
		entityID
			? `${Endpoints.Storage}/client/${clientID}/entity/${entityID}`
			: `${Endpoints.Storage}/client/${clientID}`,
	ClientEntityForm: (clientID: string, entityID: string) =>
		`${Endpoints.Storage}/client/${clientID}/${entityID}`,
	ShareLink: `${Endpoints.Storage}/share-link`,
	DeleteAllSharedFiles: `${Endpoints.Storage}/all-shared-files`,
	GoogleDocsSharedFileURL: (userID: string, fileID: string) =>
		`https://docs.google.com/gview?url=${sharedFileURLServer(
			userID,
			fileID,
		)}&embedded=true`,
	Office365SharedFileURL: (userID: string, fileID: string) =>
		`https://view.officeapps.live.com/op/embed.aspx?src=${sharedFileURLServer(
			userID,
			fileID,
		)}`,
	Quota: `${Endpoints.Storage}/quota`,
	Branding: (key: string) => `${Endpoints.Storage}/branding/${key}`,
	Delete: (id: string) => `${Endpoints.Storage}/${id}`,
	Files: `${Endpoints.Storage}/file`,
}

export const Features = {
	GetAll: `${Endpoints.Features}`,
	GetUserFeatures: (userID: string) => `${Endpoints.Features}/admin/${userID}`,
	UserInBeta: (email: string) => `${Endpoints.Features}/is-beta-user/${email}`,
	BetaUploadForms: `${Endpoints.Features}/beta-upload-forms`,
	Set: `${Endpoints.Features}`,
	SetBetaUser: `${Endpoints.Features}/beta-user`,
	RemoveBetaUser: (email: string) => `${Endpoints.Features}/beta-user/${email}`,
}

export const Forms = {
	Fetch: `${Endpoints.Forms}`,
	Upload: `${Endpoints.Forms}/`,
	Delete: (formID: string) => `${Endpoints.Forms}/${formID}`,
	RestoreForm: (formID: string) => `${Endpoints.Forms}/restore/${formID}`,
}

export const Emails = {
	ContactUs: `${Endpoints.Email}/contact-us`,
	Keys: `${Endpoints.Email}/keys`,
	GetSubscriptions: (id: string, email: string) =>
		`${Endpoints.Email}/subscriptions/${id}/${email}`,
	SaveSubscriptions: `${Endpoints.Email}/subscriptions`,
	GetSubscription: (email: string) =>
		`${Endpoints.Email}/subscriptions/${email}`,
	AppointmentReminder: `${Endpoints.Email}/appointment/reminder`,
}

export const SMS = {
	SendSMS: `${Endpoints.SMS}`,
}

export const PublicIntake = {
	Get: (id: string) => `${Endpoints.PublicIntake}/${id}`,
	GetPublic: (clientID: string, link: string) =>
		`${Endpoints.PublicIntake}/${clientID}/${link}`,
	GetAll: `${Endpoints.PublicIntake}`,
	GetForms: (id: string) => `${Endpoints.PublicIntake}/${id}/forms`,
	Complete: `${Endpoints.PublicIntake}/complete`,
	Create: (ignoreSubscription = false) =>
		ignoreSubscription
			? `${Endpoints.PublicIntake}?ignoreSubscription=${ignoreSubscription}`
			: `${Endpoints.PublicIntake}`,
	Close: (id: string) => `${Endpoints.PublicIntake}/${id}`,
	ResendEmail: (ignoreSubscription = false) =>
		ignoreSubscription
			? `${Endpoints.PublicIntake}/resend?ignoreSubscription=${ignoreSubscription}`
			: `${Endpoints.PublicIntake}/resend`,
	TestEmail: `${Endpoints.PublicIntake}/test`,
}

export const Files = {
	GetAll: (queryParams: IQueryParams) =>
		`${Endpoints.Files}${getQueryParams(queryParams)}`,
	Update: `${Endpoints.Files}`,
}

export const Ping = {
	HealthCheck: `${Endpoints.Ping}/health-check`,
}

export const SOAP_APPOINTMENT_PAGE_SIZE = 10

export const getQueryParams = (queryParams: IQueryParams): string => {
	if (!queryParams) {
		return ''
	}

	var queryString = ''

	Object.keys(queryParams).forEach((queryParam) => {
		if (!!queryParams[queryParam] || queryParams[queryParam] === 0) {
			queryString += `${queryParam}=${queryParams[queryParam]}&`
		}
	})

	if (queryString) {
		queryString = `?${queryString}`.slice(0, -1)
	}

	return queryString
}

interface IPage {
	take?: number
	skip?: number
	filter?: string
}
