import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authPost } from 'lib/http-request'
import { Forms } from 'constants/endpoints'
import { message } from 'components/notifications'
import { Dispatch, ErrorState, IAction } from 'interfaces'

const formsAction = (action: string) => `client-trackyr/forms/${action}`
const RESTORE_FORMS = formsAction('RESTORE_FORMS')
const RESTORE_FORMS_SUCCESS = formsAction('RESTORE_FORMS_SUCCESS')
const RESTORE_FORMS_ERROR = formsAction('RESTORE_FORMS_ERROR')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case RESTORE_FORMS:
      return {
        ...state,
        fetching: true,
      }

    case RESTORE_FORMS_SUCCESS:
      return {
        ...state,
        fetching: false,
      }

    case RESTORE_FORMS_ERROR:
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}

export const restoreForm = (formID: string) => async (dispatch: Dispatch) => {
  dispatch({ type: RESTORE_FORMS })

  return authPost(Forms.RestoreForm(formID), {})
    .then((response) => {
      message.success('Restored Form')
      dispatch(restoreFormSuccess())

      return response
    })
    .catch((error) => {
      message.error('Failed to Restore Form')

      captureMessage(`restoreForm Failed. ${error}`, Severity.Error)
      dispatch(restoreFormError(error))

      return error
    })
}

const restoreFormSuccess = () => ({
  type: RESTORE_FORMS_SUCCESS,
})

const restoreFormError = (error: ErrorState) => ({
  type: RESTORE_FORMS_ERROR,
  error,
})
