import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import Notification from './notification'
import { IState } from 'interfaces'

interface MessageContainerProps {
  numNotifications: number
}
const MessageContainer = styled.div<MessageContainerProps>`
  position: fixed;
  right: 0;
  left: auto;
  z-index: 999;
  margin: 1em 0 1em 1em;
  top: 2em;

  @media (max-width: 768px) {
    margin: 1em;
    padding: 0.75em;
    top: 1em;

    width: 100%;
  }
`

const Notifications = () => {
  const notifications = useSelector(
    (state: IState) => state.notifications.notifications,
  )

  return (
    !!notifications.length && (
      <MessageContainer numNotifications={notifications.length}>
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            content={notification.content}
            id={notification.id}
            message={notification.message}
            type={notification.type}
          />
        ))}
      </MessageContainer>
    )
  )
}

export default Notifications
