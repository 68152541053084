import { authPost } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import {
  IAction,
  Dispatch,
  SettingsState,
  ErrorState,
  FormScreen,
} from 'interfaces'
import { getFormTypeFromFormScreen } from 'constants/settings'
import { Form_T } from '../form-types/new-interfaces'
import { formAPIToFormType, formTypeToFormAPI } from 'lib/fetch-parsers'
import { CLIENT_INFO_TAB_ID, NOTES_TAB_ID } from 'constants/form'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`

const SAVE_FORM_TYPE = settingsAction('SAVE_FORM_TYPE')
const SAVE_FORM_TYPE_SUCCESS = settingsAction('SAVE_FORM_TYPE_SUCCESS')
const SAVE_FORM_TYPE_FAILURE = settingsAction('SAVE_FORM_TYPE_FAILURE')

export const defaultState = {} as SettingsState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SAVE_FORM_TYPE:
      return {
        ...state,
        flags: {
          ...state.flags,
          savingFormType: true,
        },
      }

    case SAVE_FORM_TYPE_SUCCESS: {
      const data: Form_T = action.response
      const key = getFormTypeFromFormScreen(data.screen)
      const allIDs = [...new Set([...state[key].allIDs, data.id])]

      return {
        ...state,
        [key]: {
          ...state[key],
          [data.id]: data,
          allIDs,
        },
        flags: {
          ...state.flags,
          savingFormType: false,
        },
      }
    }

    case SAVE_FORM_TYPE_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          savingFormType: false,
        },
      }

    default:
      return state
  }
}

export const saveFormType = (formType: Form_T) => async (
  dispatch: Dispatch,
): Promise<any> => {
  dispatch({ type: SAVE_FORM_TYPE, formType })
  const request = formTypeToFormAPI(formType)

  if (!formType.name) {
    message.error('Form should have a name')
    return
  }

  if (formType.screen === FormScreen.Client) {
    if (
      !formType.formTabs.find((t) => t.id === CLIENT_INFO_TAB_ID)?.forms?.length
    ) {
      message.error('Client Info tab should have at least 1 form')
      dispatch(
        saveFormTypeFailure({
          message: 'Client Info tab should have at least 1 form',
        }),
      )
      return
    }

    if (!formType.formTabs.find((t) => t.id === NOTES_TAB_ID)?.forms?.length) {
      message.error('Notes tab should have at least 1 form')
      dispatch(
        saveFormTypeFailure({
          message: 'Notes tab tab should have at least 1 form',
        }),
      )
      return
    }
  }

  return authPost(Settings.FormTypeV2, request)
    .then((response) => {
      message.success('Form Types Saved')
      dispatch(saveFormTypeSuccess(formAPIToFormType(response.data)))

      return response
    })
    .catch((error) => {
      captureMessage(`saveFormsTypes Failed. ${error}`, Severity.Error)
      dispatch(saveFormTypeFailure(error))

      return error
    })
}

export const saveFormTypeSuccess = (response: any) => ({
  type: SAVE_FORM_TYPE_SUCCESS,
  response,
})

export const saveFormTypeFailure = (error: ErrorState) => ({
  type: SAVE_FORM_TYPE_FAILURE,
  error,
})
