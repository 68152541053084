import React from 'react'

import { Label, FormField, RadioGroup, RadioButton } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps } from 'components/forms/interfaces'
import { Text } from 'components/ui'

interface YesNoFormProps {
	// size?: string
}

const YesNoForm = ({
										 autoSave,
										 label,
										 name,
										 onChange,
										 printParams,
										 readonly,
										 // size = 'small',
										 validationRules = {},
										 value,
									 }: InputProps & YesNoFormProps) => {
	if (printParams?.printingView) {
		if (value) {
			return (
				<FormField>
					<Label>{label}</Label>
					<Text>{value}</Text>
				</FormField>
			)
		}
		return (
			<FormField>
				<Label>{label}</Label>
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					<Text style={{ marginRight: '1em' }}>Yes</Text>
					<Text>No</Text>
				</div>
			</FormField>
		)
	}

	return (
		<FormField required={!readonly && validationRules.required}>
			<Label>{label}</Label>

			<AutoSaveWrapper
				afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
				autosaveDisabled={autoSave.autosaveDisabled}
				autosaveMode='onChange'
				debounceTime={250}
				endpoint={autoSave.patchEndpoint}
				entityID={autoSave.entityID}
				formType={autoSave.patchType}
				patch={autoSave.patch}
			>
				<RadioGroup
					name={name}
					onChange={onChange}
					readonly={readonly}
					// size={size}
					value={value}
				>
					<RadioButton key='yes' value='Yes'>
						Yes
					</RadioButton>
					<RadioButton key='no' value='No'>
						No
					</RadioButton>
				</RadioGroup>
			</AutoSaveWrapper>
		</FormField>
	)
}

YesNoForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const YesNo = React.memo(YesNoForm)
