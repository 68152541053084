import React from 'react'
import { Input as I, Label, FormField } from 'components/ui'

import { AutoSaveWrapper } from 'components/forms'
import {
	InputProps,
	InputSizeProps,
	BasicEvent,
} from 'components/forms/interfaces'

interface IInputNumber {
	numberType?: string
	inputLabel?: any
}

const InputNumberForm = ({
													 autoSave,
													 inputLabel,
													 label,
													 name,
													 onBlur,
													 onChange,
													 onValidate,
													 placeholder,
													 printParams,
													 readonly,
													 validation,
													 validationRules,
													 value,
													 // numberType,
													 size,
												 }: InputProps & InputSizeProps & IInputNumber) => (
	<FormField
		{...validation}
		required={!readonly && validationRules && validationRules.required}
	>
		<Label>{label}</Label>

		<AutoSaveWrapper
			afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
			autosaveDisabled={autoSave.autosaveDisabled}
			endpoint={autoSave.patchEndpoint}
			entityID={autoSave.entityID}
			formType={autoSave.patchType}
			patch={autoSave.patch}
		>
			<I
				// formatter={
				//   numberType === 'dollar'
				//     ? (value) => `$ ${value}`
				//     : numberType === 'percent'
				//     ? (value) => `${value}%`
				//     : (value) => value
				// }
				label={inputLabel}
				name={name}
				onBlur={(e) => {
					onBlur?.(e)
					onValidate?.(validationRules, label, name, value)
				}}
				onChange={(e: BasicEvent) =>
					onChange({ target: { name, value: e.target.value } })
				}
				// parser={
				//   numberType === 'dollar'
				//     ? (value) => value.replace('$', '')
				//     : numberType === 'percent'
				//     ? (value) => value.replace('%', '')
				//     : (value) => value
				// }
				placeholder={placeholder || label}
				printParams={printParams}
				readonly={readonly}
				size={size}
				style={{ width: 200 }}
				type='number'
				value={value}
			/>
		</AutoSaveWrapper>
	</FormField>
)

InputNumberForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
	placeholder: '',
	readonly: false,
	size: 'small',
	validation: {},
}

export const InputNumber = React.memo(InputNumberForm)
