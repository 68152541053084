import React from 'react'
import { connect } from 'react-redux'
import ErrorAlert from 'components/error'
import styled from 'styled-components'

import { ErrorText, Form, FormField, H2 } from 'components/ui'
import { Input } from 'components/forms'
import * as Ducks from 'containers/auth'
import { registerRequest } from 'containers/auth/redux/register-request'
import SubmitAuthButton from './submit-auth-button'
import { Spinner } from 'components/loaders'
import { Features } from 'constants/endpoints'
import { get } from 'lib/http-request'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { Dispatch, IState } from 'interfaces'

const RegisterContainer = styled.div`
  width: 35%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1244px) {
    width: 65%;
  }

  @media (max-width: 1024px) {
    width: 85%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  max-width: 35em;
`

const validationSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required('Email Address is required')
    .email('Must be a valid email address'),
  password: Yup.string().required('Password is required'),
  passwordConfirm: Yup.string().required('Re-entered password is required'),
})

interface Props {
  errorMsg: string
  hasError: boolean
  loading: boolean
  registerRequest: (email: string, password: string) => Promise<any>
  setError: (hasError: boolean, errorMsg?: string) => void
}

class Register extends React.Component<Props> {
  onSubmit = (values: any, setSubmitting: Function, resetForm: Function) => {
    get(Features.UserInBeta(values.emailAddress))
      .then(() => {
        this.props.setError(false)

        if (values.password !== values.passwordConfirm) {
          this.props.setError(true, 'Passwords do not match')
          setSubmitting(false)
          return
        }

        this.props
          .registerRequest(values.emailAddress, values.password)
          .then(() => {
            resetForm()
            setSubmitting(false)
          })
          .catch(() => {
            setSubmitting(false)
          })
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          this.props.setError(
            true,
            'This Email Address is not currently enrolled in the private Beta, you will not be able to sign up at this time.',
          )
        }

        setSubmitting(false)
      })
  }

  renderRegisterView = () => {
    return (
      <RegisterContainer>
        <H2>Register a New Account</H2>

        <Formik
          initialValues={{
            emailAddress: '',
            password: '',
            passwordConfirm: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.onSubmit(values, setSubmitting, resetForm)
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                icon="mail"
                label="Email Address"
                name="emailAddress"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Email Address"
                size="large"
                type="email"
                value={values.emailAddress}
              />
              <ErrorText>
                {errors.emailAddress &&
                  touched.emailAddress &&
                  errors.emailAddress}
              </ErrorText>

              <Input
                icon="lock"
                label="Password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Password"
                size="large"
                type="password"
                value={values.password}
              />
              <ErrorText>
                {errors.password && touched.password && errors.password}
              </ErrorText>

              <Input
                icon="lock"
                label="Password"
                name="passwordConfirm"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Password"
                size="large"
                type="password"
                value={values.passwordConfirm}
              />
              <ErrorText>
                {errors.passwordConfirm &&
                  touched.passwordConfirm &&
                  errors.passwordConfirm}
              </ErrorText>

              <FormField>
                <SubmitAuthButton
                  content="Register"
                  isSubmitting={isSubmitting}
                />
              </FormField>
            </Form>
          )}
        </Formik>

        <ErrorAlert
          errorMsg={this.props.errorMsg}
          hasError={this.props.hasError}
        />
      </RegisterContainer>
    )
  }

  render() {
    return (
      <Spinner spinning={this.props.loading}>
        {this.renderRegisterView()}
      </Spinner>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  errorMsg: state.auth.errorMsg,
  hasError: state.auth.hasError,
  loading: state.auth.loading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  registerRequest: (email: string, password: string) =>
    dispatch(registerRequest(email, password)),
  setLoading: (isLoading: boolean) => dispatch(Ducks.setLoading(isLoading)),
  setError: (hasError: boolean, errorMsg: string = '') =>
    dispatch(Ducks.setError(hasError, errorMsg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
