import { groupBy, toArray, flatten } from 'lodash'
import moment from 'moment'
import {
	IClient,
	IFormType,
	TypeSelectedForm,
	IFormTypeRequest,
	ITab,
	AdditionalTab,
	FormScreen,
	OfficeTimeSlot,
} from 'interfaces'
import { defaultTabs } from 'constants/settings'
import { DEFAULT_EVENT_COLOUR } from 'constants/calendar-colours'
import { Form_API, Form_T } from 'containers/settings/form-types/new-interfaces'

export const parseClientsPhoneNumbersFromAPI = (clients: Array<IClient>) => {
	for (const client of clients) {
		parsePhoneNumbersFromAPI(client)
	}

	return clients
}

export const parsePhoneNumbersFromAPI = (client: IClient) => {
	const phoneNumbers = client.phoneNumbers

	if (phoneNumbers && phoneNumbers.length) {
		for (const pn of phoneNumbers) {
			pn.phoneNumber = pn.number
		}
	}

	const phoneNumberFields = new Set(
		Object.keys(client.fields || {})
			.filter(
				(k) =>
					k.includes('--PN') ||
					(k.toLowerCase().includes('phonenumber') &&
						k.toLowerCase() !== 'phonenumbers'),
			)
			.sort()
			.map((key) => key.substring(0, key.lastIndexOf('-'))),
	)

	for (const phoneNumberField of phoneNumberFields) {
		const phoneNumberKey = phoneNumberField.substring(
			phoneNumberField.lastIndexOf(':') + 1,
			phoneNumberField.length,
		)

		const phoneType = client.fields[`${phoneNumberField}-phoneType`]
		const phoneNumber = client.fields[`${phoneNumberField}-phoneNumber`]

		if (!client[phoneNumberKey]) {
			client[phoneNumberKey] = [{ type: phoneType, phoneNumber: phoneNumber }]
		} else {
			client[phoneNumberKey].push({ type: phoneType, phoneNumber: phoneNumber })
		}

		delete client.fields[`${phoneNumberField}-phoneType`]
		delete client.fields[`${phoneNumberField}-phoneNumber`]
	}

	return client
}

export const parsePhoneNumbersToAPI = (client: IClient) => {
	const extraPhoneNumbers = {}
	const phoneNumbers = []

	const phoneNumberGroup = toArray(
		groupBy(
			Object.keys(client || {}).filter(
				(k) =>
					!!client[k] &&
					(k.includes('--PN') ||
						(k.toLowerCase().includes('phonenumber') &&
							k.toLowerCase() !== 'phonenumbers')),
			),
		),
	)

	if (client['phoneNumbers']) {
		for (const clientPhoneNumber of client['phoneNumbers']) {
			phoneNumbers.push({
				name: 'phoneNumbers',
				type: clientPhoneNumber.type || 'Mobile',
				number: clientPhoneNumber.phoneNumber,
			})
		}

		delete client['phoneNumbers']
	}

	for (const phoneNumberKey of phoneNumberGroup) {
		// TODO - fix this ugly cast
		const pns = client[phoneNumberKey as unknown as string]

		let i = 0
		for (const pn of pns) {
			extraPhoneNumbers[`${i}:${phoneNumberKey}-phoneType`] = pn.type
			extraPhoneNumbers[`${i}:${phoneNumberKey}-phoneNumber`] = pn.phoneNumber
			i++
		}

		// TODO - fix this ugly cast
		delete client[phoneNumberKey as unknown as string]
	}

	const parsed = {
		...client,
		...extraPhoneNumbers,
	} as IClient

	if (phoneNumbers.length) {
		parsed.phoneNumbers = phoneNumbers
	}

	Object.keys(client || {})
		.filter((k) => Array.isArray(client[k]))
		.forEach((key: string) => {
			parsed[key] = JSON.stringify(client[key])
		})

	return parsed
}

export const parseOfficeHoursToAPI = (office: any): OfficeTimeSlot[] =>
	flatten(
		office.days.map((d: any) =>
			d.slots.map((s: any) => ({
				id: s.id,
				day: d.dayID,
				openingTime: s.from || '09:00',
				closingTime: s.to || '17:00',
			})),
		),
	)

export const parseOfficeHoursFromAPI = (office: any) => {
	const days = []

	for (const day of office.officeHours) {
		var index = days.findIndex((d) => d.dayID === day.day)

		const time = {
			id: day.id,
			from: moment(day.openingTime).format('HH:mm'),
			to: moment(day.closingTime).format('HH:mm'),
		}

		if (index >= 0) {
			days[index].slots.push({ ...time })
		} else {
			days.push({
				dayID: day.day,
				slots: [{ ...time }],
			})
		}
	}

	return days
}

export const parseClientTypesSettingsToAPI = (types: Array<any>) => {
	for (let t of types) {
		t = parseClientTypeSettingToAPI(t)
	}

	return types
}

// TODO - DEPRECATED
export const parseClientTypeSettingToAPI = (formType: IFormType) => {
	const toAPI = {
		id: formType.id,
		name: formType.name,
		colour: formType.colour || DEFAULT_EVENT_COLOUR,
		isDefault: formType.isDefault,
		isDeleted: formType.isDeleted,
		screen: formType.screen,
		selectedType: formType.selectedType,
	} as IFormTypeRequest

	if (formType.selectedNotes) {
		toAPI.selectedForms = [
			...formType.selectedForms.map((sn) => ({ ...sn, selectedFormType: 1 })),
			...formType.selectedNotes.map((sn) => ({ ...sn, selectedFormType: 2 })),
		]
	} else {
		toAPI.selectedForms = [
			...formType.selectedForms.map((sn) => ({ ...sn, selectedFormType: 1 })),
		]
	}

	if (formType.customTabs) {
		toAPI.customTabs = JSON.stringify(formType.customTabs)

		const additionalTabs = formType.customTabs.filter(
			(t: ITab) => !defaultTabs.find((dt) => dt.id === t.id),
		)

		const apiAdditionalTabs = []
		for (const at of additionalTabs) {
			const tabName = at.tab.replace(/ /, '')
			apiAdditionalTabs.push({
				name: tabName,
				enabled: formType[`${tabName}-enabled`],
				selectedForms: formType[tabName],
			})
		}

		toAPI.additionalForms = JSON.stringify(apiAdditionalTabs)
	}

	return toAPI
}

export const formTypeToFormAPI = (formType: Form_T): Form_API => {
	const forms = formType.forms
		? JSON.stringify(formType.forms)
		: JSON.stringify(formType.formTabs)

	const request: Form_API = {
		id: formType.id,
		name: formType.name,
		screen: formType.screen,
		selectedType: formType.selectedType,
		forms: forms,
		isDeleted: formType.isDeleted,
		isDefault: formType.isDefault,
		colour: formType.appointmentColour || DEFAULT_EVENT_COLOUR,
	}

	return request
}

export const formAPIToFormType = (formAPI: Form_API): Form_T => {
	const forms = JSON.parse(formAPI.forms)

	if (formAPI.screen === FormScreen.Soap) {
		const response: Form_T = {
			id: formAPI.id,
			name: formAPI.name,
			screen: formAPI.screen,
			selectedType: formAPI.selectedType,
			formTabs: null,
			forms: forms,
			isDeleted: formAPI.isDeleted,
			isDefault: formAPI.isDefault,
			appointmentColour: formAPI.colour || DEFAULT_EVENT_COLOUR,
		}

		return response
	}
	const response: Form_T = {
		id: formAPI.id,
		name: formAPI.name,
		screen: formAPI.screen,
		selectedType: formAPI.selectedType,
		formTabs: forms,
		isDeleted: formAPI.isDeleted,
		isDefault: formAPI.isDefault,
		appointmentColour: formAPI.colour || DEFAULT_EVENT_COLOUR,
	}

	return response
}

export const parseClientTypeSettingsFromAPI = (results: Array<any>) => {
	for (let formType of results) {
		formType = parseFormTypeSettingFromAPI(formType)
	}

	return results
}

// TODO - DEPRECATED
export const parseFormTypeSettingFromAPI = (
	apiFormType: IFormTypeRequest,
): IFormType => {
	let formType = {
		id: apiFormType.id,
		name: apiFormType.name,
		colour: apiFormType.colour || DEFAULT_EVENT_COLOUR,
		isDefault: apiFormType.isDefault,
		isDeleted: apiFormType.isDeleted,
		screen: apiFormType.screen,
		selectedType: apiFormType.selectedType,
	} as IFormType

	formType.selectedNotes = apiFormType.selectedForms.filter(
		(sf: TypeSelectedForm) => sf.selectedFormType === 2,
	)
	formType.selectedForms = apiFormType.selectedForms.filter(
		(sf: TypeSelectedForm) => sf.selectedFormType === 1,
	)

	if (apiFormType.additionalForms) {
		const additionalForms: Array<AdditionalTab> =
			JSON.parse(apiFormType.additionalForms) || []

		const parsedForms = {}
		for (const af of additionalForms) {
			parsedForms[`${af.name}-enabled`] = af.enabled
			parsedForms[af.name] = af.selectedForms
		}

		formType = {
			...formType,
			...parsedForms,
		}
	}

	if (apiFormType.customTabs) {
		formType.customTabs = JSON.parse(apiFormType.customTabs) || []
	}

	return formType
}

export const parseShapesToAPI = (shapes: Array<any>) =>
	shapes.map((s) => ({
		...s,
		lines:
			s.lines && s.lines.length
				? JSON.stringify(s.lines)
					.replace(/},{"actual":/g, '!')
					.replace(/,"relative":/g, '@')
				: null,
	}))

export const parseShapesFromAPI = (shapes: Array<any>) =>
	shapes.map((s) => ({
		...s,
		lines: s.lines
			? JSON.parse(
				s.lines.replace(/!/g, '},{"actual":').replace(/@/g, ',"relative":'),
			)
			: null,
	}))
