import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import localforage from 'localforage'

import { CLIENT_TRACKYR_STORE, REQUEST_CACHE } from 'constants/storage'
import { authGet } from 'lib/http-request'
import { Features } from 'constants/endpoints'
import { IQueryParams, Options } from 'interfaces/fetch'
import { FeaturesState, IAction } from 'interfaces'

const action = (action: string) => `client-trackyr/features/${action}`
const FETCH_FEATURES = action('FETCH_FEATURES')
const FETCH_FEATURES_SUCCESS = action('FETCH_FEATURES_SUCCESS')
const FETCH_FEATURES_CACHE_SUCCESS = action('FETCH_FEATURES_CACHE_SUCCESS')
const FETCH_FEATURES_FAILURE = action('FETCH_FEATURES_FAILURE')

const cache = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: REQUEST_CACHE,
})

export const defaultState = {
  fetchingFeatures: true,
  fetchingFeaturesError: { error: false },
  features: {},
} as FeaturesState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_FEATURES:
      return {
        ...state,
        fetchingFeatures: true,
      }

    case FETCH_FEATURES_SUCCESS:
    case FETCH_FEATURES_CACHE_SUCCESS: {
      const features = {}

      for (const feature of action.response.items) {
        features[feature.key] = feature.value
      }

      return {
        ...state,
        fetchingFeatures: false,
        features,
      }
    }

    case FETCH_FEATURES_FAILURE:
      return {
        ...state,
        fetchingFeatures: false,
        fetchingFeaturesError: {
          error: true,
          message: 'Failed to load all forms',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
// TODO - load fron indexDB, but also fetch
const fetchFeatures =
  (queryParams: IQueryParams = {}): any =>
  async (dispatch: any): Promise<any> => {
    dispatch({ type: FETCH_FEATURES, queryParams })

    const cachedData = await cache.getItem(FETCH_FEATURES)

    if (cachedData) {
      dispatch(cacheSuccess(cachedData))
    }

    return authGet(Features.GetAll, {} as Options, queryParams)
      .then(async (response) => {
        dispatch(fetchSuccess(response.data))
        await cache.setItem(FETCH_FEATURES, response.data)
        return response
      })
      .catch((error) => {
        dispatch(fetchFailure(error))
        captureMessage(`fetchFeatures Get Failed: ${error}`, Severity.Error)

        throw error
      })
  }

const fetchSuccess = (response: any) => ({
  type: FETCH_FEATURES_SUCCESS,
  response,
})

const cacheSuccess = (response: any) => ({
  type: FETCH_FEATURES_CACHE_SUCCESS,
  response,
})

const fetchFailure = (error: any) => ({
  type: FETCH_FEATURES_FAILURE,
  error,
})

export { fetchFeatures }
