import { authGet } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { Dispatch, IAction } from 'interfaces'
import { CLIENT_TYPES, SOAP_TYPES } from 'constants/settings'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`

const GET_DEFAULT_FORM_TYPE = settingsAction('GET_DEFAULT_FORM_TYPE')
const GET_DEFAULT_FORM_TYPE_SUCCESS = settingsAction(
  'GET_DEFAULT_FORM_TYPE_SUCCESS',
)
const GET_DEFAULT_FORM_TYPE_FAILURE = settingsAction(
  'GET_DEFAULT_FORM_TYPE_FAILURE',
)

export const defaultState = {
  fetchingDefaultFormType: false,
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case GET_DEFAULT_FORM_TYPE:
      return {
        ...state,
        fetchingDefaultFormType: true,
      }

    case GET_DEFAULT_FORM_TYPE_SUCCESS:
      return {
        ...state,
        fetchingDefaultFormType: false,
        [action.formType]: action.response,
      }

    case GET_DEFAULT_FORM_TYPE_FAILURE:
      return {
        ...state,
        fetchingDefaultFormType: false,
      }

    default:
      return state
  }
}

export const getDefaultFormsType = (
  practiceType: string,
  formType: typeof CLIENT_TYPES | typeof SOAP_TYPES,
) => async (dispatch: Dispatch) => {
  dispatch({ type: GET_DEFAULT_FORM_TYPE, practiceType, formType })

  return authGet(Settings.GetDefaultFormType(practiceType, formType))
    .then((response) => {
      message.success('Form Types Saved')
      dispatch(getDefaultFormsTypeSuccess(response.data, formType))

      return response
    })
    .catch((error) => {
      message.error('Failed to save Default Forms Types Settings')
      captureMessage(`getDefaultFormsType Failed. ${error}`, Severity.Error)

      dispatch(getDefaultFormsTypeFailure(error, formType))

      return error
    })
}

export const getDefaultFormsTypeSuccess = (
  response: any,
  formType: string,
) => ({
  type: GET_DEFAULT_FORM_TYPE_SUCCESS,
  response,
  formType,
})

export const getDefaultFormsTypeFailure = (error: any, formType: string) => ({
  type: GET_DEFAULT_FORM_TYPE_FAILURE,
  error,
  formType,
})
