import { initializeApp, getApp } from 'firebase/app'
import { getAuth } from  'firebase/auth'
import 'firebase/storage'

import { firebaseConfig } from './config'

initializeApp(firebaseConfig)

const auth = getAuth(getApp())

export { auth }
