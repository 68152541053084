export const parseServiceTimeToMinutes = (serviceTime: string): number => {
  if (!serviceTime) {
    return 0
  }

  let totalMinutes = 0

  const tokens = serviceTime.split('h')
  const hour = tokens[0].includes('m') ? null : tokens[0]

  if (hour === null) {
    const onlyMinutes = tokens[0].split('m')[0]
    totalMinutes += parseInt(onlyMinutes)
  }

  if (hour !== null) {
    const hourParsed = parseInt(hour)
    const validHour = isNaN(hourParsed) ? 0 : hourParsed
    totalMinutes += validHour * 60

    const minutes = tokens[1]?.split('m')?.[0]

    if (minutes) {
      totalMinutes += parseInt(minutes)
    }
  }

  if (isNaN(totalMinutes)) {
    return 0
  }

  return totalMinutes
}
