import React from 'react'
import styled from 'styled-components'
import { paginateLocally } from 'lib'

import ClientsList from './clients-list'
import { Divider, H4, Message, Pagination } from 'components/ui'
import { IClient } from 'interfaces'

interface IOverlay {
  showOverlay: boolean
}
const Overlay = styled.div<IOverlay>`
  position: absolute;
  ${(props) => (props.showOverlay ? 'display: block' : 'display: none')};
  width: 75%;
  height: auto;
  left: 150px;
  background-color: ${(props) => props.theme.BackgroundColour};
  z-index: 15;
  cursor: pointer;
  box-shadow: 0 4px 6px 0 ${(props) => props.theme.DarkerShadowColour};
  top: 3em;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
    top: 5em;
  }
`

const P = styled(H4)`
  padding-left: 0.5em;
  margin-top: 0;
  padding-top: 0;
`

const KEY_LEFT = 37
const KEY_RIGHT = 39
const KEY_UP = 38
const KEY_DOWN = 40
const KEY_ENTER = 13

export default class ClientsListOverlay extends React.Component<
  IClientsListOverlay
> {
  state: IState = {
    page: 1,
    selected: 0,
  }

  _handleKeyDown = (event: KeyboardEvent) => {
    switch (event.keyCode) {
      case KEY_UP: {
        if (this.props.showOverlay && this.state.selected > 0) {
          this.setState((state: IState) => ({ selected: state.selected - 1 }))
        }

        break
      }

      case KEY_DOWN: {
        const clientLength =
          this.props.clients.length > this.props.pageSize
            ? this.props.pageSize
            : this.props.clients.length
        const length = this.props.todaysClients.length + clientLength

        if (this.props.showOverlay && this.state.selected < length - 1) {
          this.setState((state: IState) => ({ selected: state.selected + 1 }))
        } else if (!this.props.showOverlay && this.props.canShowOverlay) {
          this.props.onShowOverlay()
        }

        break
      }

      case KEY_LEFT: {
        if (this.props.showOverlay && this.state.page > 1) {
          this.setState((state: IState) => ({ page: state.page - 1 }))
        }

        break
      }

      case KEY_RIGHT: {
        if (
          this.props.showOverlay &&
          this.state.page <
            Math.ceil(this.props.clients.length / this.props.pageSize)
        ) {
          this.setState((state: IState) => ({ page: state.page + 1 }))
        }

        break
      }

      case KEY_ENTER: {
        if (this.props.showOverlay) {
          if (this.props.todaysClients.length > 0) {
            if (this.state.selected >= this.props.todaysClients.length) {
              this.props.onEnter(
                paginateLocally(
                  this.props.clients,
                  this.state.page,
                  this.props.pageSize,
                )[this.state.selected - this.props.todaysClients.length],
              )
            } else {
              this.props.onEnter(
                paginateLocally(
                  this.props.todaysClients,
                  this.state.page,
                  this.props.pageSize,
                )[this.state.selected],
              )
            }
          } else {
            this.props.onEnter(
              paginateLocally(
                this.props.clients,
                this.state.page,
                this.props.pageSize,
              )[this.state.selected],
            )
          }
        }

        break
      }

      default:
        this.setState(() => ({ selected: 0, page: 1 }))

        break
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this._handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyDown)
  }

  componentDidUpdate() {
    if (!this.props.showOverlay && this.state.page !== 1) {
      this.setState(() => ({ page: 1 }))
    }
  }

  render() {
    const { showOverlay, todaysClients, onClickCallback } = this.props

    const clients = paginateLocally(
      this.props.clients,
      this.state.page,
      this.props.pageSize,
    )

    return (
      <Overlay showOverlay={showOverlay}>
        {todaysClients.length
          ? [
              <P key="tc">Todays Clients</P>,
              <ClientsList
                key="tc-list"
                clients={todaysClients}
                onClickCallback={onClickCallback}
                pageSize={this.props.pageSize}
                selected={
                  this.state.selected < todaysClients.length
                    ? this.state.selected
                    : null
                }
              />,
            ]
          : null}
        {todaysClients.length && clients.length
          ? [<Divider key="tc-hr" />, <P key="tc-ee">Everyone else</P>]
          : null}
        {clients.length ? (
          <ClientsList
            clients={clients}
            onClickCallback={onClickCallback}
            pageSize={this.props.pageSize}
            selected={
              this.state.selected >= todaysClients.length
                ? this.state.selected - todaysClients.length
                : null
            }
          />
        ) : (
          <Message content="No Clients Found" icon="info" info />
        )}

        {Math.ceil(this.props.clients.length / this.props.pageSize) > 1 && (
          <Pagination
            activePage={this.state.page}
            onPageChange={(activePage: string | number) =>
              this.setState(() => ({ page: activePage }))
            }
            size="tiny"
            style={{ marginTop: '0.5em' }}
            totalPages={Math.ceil(
              this.props.clients.length / this.props.pageSize,
            )}
          />
        )}
      </Overlay>
    )
  }
}

interface IClientsListOverlay {
  canShowOverlay: boolean
  clients: Array<IClient>
  onClickCallback: Function
  onEnter: Function
  onShowOverlay: Function
  pageSize: number
  showOverlay: boolean
  todaysClients: any
}

interface IState {
  page: number
  selected: number
}
