import { authGet } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { ONE_HOUR } from 'constants/cache'
import { IAction } from 'interfaces'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const FETCH_CLIENT_FILES = filesAction('FETCH_CLIENT_FILES')
const FETCH_CLIENT_FILES_SUCCESS = filesAction('FETCH_CLIENT_FILES_SUCCESS')
const FETCH_CLIENT_FILES_FAILURE = filesAction('FETCH_CLIENT_FILES_FAILURE')
const CLIENT_FILES_NOT_FOUND = filesAction('CLIENT_FILES_NOT_FOUND')

export const defaultState = {
  fetchingClientFiles: false,
  clientFilesNotFound: false,
  clientFilesError: '',
  filesByEntityID: [],
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_CLIENT_FILES: {
      return {
        ...state,
        fetchingClientFiles: true,
        clientFilesNotFound: false,
      }
    }

    case FETCH_CLIENT_FILES_SUCCESS: {
      const files = action.files

      const filesForClient = {}

      for (const file of files) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userID, storagePath, ...rest } = file.blobStorageFile // eslint-disable-line no-unused-vars

        const fileObj = {
          ...rest,
          entityID:
            file.entityID !== '00000000-0000-0000-0000-000000000000'
              ? file.entityID
              : null,
        }

        if (filesForClient[file.clientID]) {
          filesForClient[file.clientID].push(fileObj)
        } else {
          filesForClient[file.clientID] = [fileObj]
        }
      }

      return {
        ...state,
        ...filesForClient,
        fetchingClientFiles: false,
      }
    }

    case FETCH_CLIENT_FILES_FAILURE:
      return {
        ...state,
        fetchingClientFiles: false,
        clientFilesError: action.error,
      }

    case CLIENT_FILES_NOT_FOUND:
      return {
        ...state,
        fetchingClientFiles: false,
      }

    default:
      return state
  }
}

export const fetchClientFiles = (
  clientID: string,
  useCache = false,
): any => async (dispatch: any): Promise<any> => {
  dispatch({ type: FETCH_CLIENT_FILES, clientID, useCache })

  return authGet(Storage.ClientForm(clientID), {
    cache: useCache,
    cacheMaxAge: ONE_HOUR,
  })
    .then((response) => {
      dispatch(fetchClientFilesSuccess(response.data))
      return response
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch(clientNotFound())
      }

      captureMessage(
        `Failed to fetch Client Files. ClientID: ${clientID}. ${error}`,
        Severity.Error,
      )
      dispatch(fetchClientFilesFailure(error))
      return error
    })
}

const fetchClientFilesSuccess = (files: Array<any>) => ({
  type: FETCH_CLIENT_FILES_SUCCESS,
  files,
})

const fetchClientFilesFailure = (error: any) => ({
  type: FETCH_CLIENT_FILES_FAILURE,
  error,
})

const clientNotFound = () => ({
  type: CLIENT_FILES_NOT_FOUND,
})
