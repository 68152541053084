import React from 'react'
import { AutoSaveWrapper } from 'components/forms'
import { IEvent } from '../../../interfaces'

const Setting = ({ children, patch, onBeforeChange }: Props) => (
	<AutoSaveWrapper
		autosaveMode='onChange'
		debounceTime={100}
		entityID='setting'
		onBeforeChange={onBeforeChange}
		patch={patch}
	>
		{children}
	</AutoSaveWrapper>
)

interface Props {
	children: React.ReactNode
	onBeforeChange?: Function
	patch: (e: IEvent, t?: any) => Promise<any>
}

Setting.defaultProps = {
	loading: false,
}

export default Setting
