import {
  reducer as fetchClientFiles,
  defaultState as clientFilesDefaultState,
} from './fetch-client-files'

import {
  reducer as fetchCliefetchFilentFiles,
  defaultState as filesDefaultState,
} from './fetch-client-files'

import {
  reducer as fetchPublicFileURL,
  defaultState as publicFileDefaultState,
} from './fetch-public-file-url'

import {
  reducer as deleteAllSharedFiles,
  defaultState as deleteAllSharedFilesDefaultState,
} from './delete-shared-files'

import {
  reducer as deleteFile,
  defaultState as deleteFileDefaultState,
} from './delete-file'

import {
  reducer as fetchFile,
  defaultState as fetchFileDefaultState,
} from './fetch-file'

import {
  reducer as fetchAppointmentFiles,
  defaultState as fetchAppointmentFilesDefaultState,
} from './fetch-appointment-files'

import { reducer as fetchAllFiles } from './fetch-all-files'
import { reducer as updateFileData } from './update-file'
import { FilesState, IAction, IFile } from 'interfaces'

const reducers = [
  fetchClientFiles,
  fetchCliefetchFilentFiles,
  fetchPublicFileURL,
  deleteAllSharedFiles,
  deleteFile,
  fetchAllFiles,
  updateFileData,
  fetchFile,
  fetchAppointmentFiles,
]

const filesAction = (action: string) => `client-trackyr/files/${action}`
const SET_SELECTED_FILE = filesAction('SET_SELECTED_FILE')
const SET_FILE_ZOOM = filesAction('SET_FILE_ZOOM')
const SET_FILE_FULLSCREEN = filesAction('SET_FILE_FULLSCREEN')
const SET_ALL_FILES_FETCHED_STATUS = filesAction('SET_ALL_FILES_FETCHED_STATUS')
const ADD_CLIENT_APPOINTMENT_FILE = filesAction('ADD_CLIENT_APPOINTMENT_FILE')

export const NEW_CLIENT_ID = '0'

export const defaultState: FilesState = {
  ...clientFilesDefaultState,
  ...filesDefaultState,
  ...publicFileDefaultState,
  ...deleteAllSharedFilesDefaultState,
  ...deleteFileDefaultState,
  ...fetchFileDefaultState,
  ...fetchAppointmentFilesDefaultState,
  endpoints: {},
  files: {},
  allFilesByID: [],
  fileZoom: 1,
  flags: {
    fetchingAllFiles: false,
    updatingFile: false,
    allFilesFetched: false,
    fetchingAppointmentFiles: false,
    appointmentFilesError: null,
  },
  fullscreen: false,
  meta: {
    totalFiles: 0,
  },
  selectedFile: '',
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SET_SELECTED_FILE:
      return {
        ...state,
        selectedFile: action.fileID,
      }

    case SET_FILE_ZOOM:
      return {
        ...state,
        fileZoom: action.zoom,
      }

    case SET_FILE_FULLSCREEN:
      return {
        ...state,
        fullscreen: action.fullscreen,
      }

    case SET_ALL_FILES_FETCHED_STATUS:
      return {
        ...state,
        flags: {
          ...state.flags,
          allFilesFetched: action.fetchedStatus,
        },
      }

    case ADD_CLIENT_APPOINTMENT_FILE: {
      return {
        ...state,
        appointmentFiles: {
          ...state.appointmentFiles,
          [action.entityID]: [
            ...state.appointmentFiles[action.entityID],
            action.file,
          ],
        },
      }
    }

    default:
      break
  }

  return reducers.reduce((s: any, r) => r(s, action), state)
}

export const selectFile = (fileID: string) => ({
  type: SET_SELECTED_FILE,
  fileID,
})

export const setFileScale = (zoom: number) => ({
  type: SET_FILE_ZOOM,
  zoom,
})

export const setFileFullscreen = (fullscreen: boolean) => ({
  type: SET_FILE_FULLSCREEN,
  fullscreen,
})

export const setAllFilesFetched = (fetchedStatus: boolean) => ({
  type: SET_ALL_FILES_FETCHED_STATUS,
  fetchedStatus,
})

export const addClientAppointmentFile = (file: IFile, entityID: string) => ({
  type: ADD_CLIENT_APPOINTMENT_FILE,
  file,
  entityID,
})
