import React, { useRef, useCallback } from 'react'
import {
  DndProvider,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord,
} from 'react-dnd'
// import Backend from 'react-dnd-touch-backend' // TODO - we want this one
import { HTML5Backend } from 'react-dnd-html5-backend'

// https://react-dnd.github.io/react-dnd/examples/sortable/simple

const draggingContainerStyle = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

interface DNDListProps {
  accepts?: string
  disabled?: boolean
  items?: Array<any>
  readonly?: boolean
  renderItem: Function
  setItems: Function
  noBackend?: boolean
}

export const DNDList = (props: DNDListProps): any => {
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const items = props.items
      items.splice(dragIndex, 0, items.splice(hoverIndex, 1)[0])
      props.setItems(items)
    },
    [props],
  )

  if (!props.items) {
    return null
  }

  if (props.readonly) {
    return props.items.map((child, index) => props.renderItem(child, index))
  }

  const moveCard = (dragIndex: any, hoverIndex: any) =>
    !props.disabled && moveItem(dragIndex, hoverIndex)

  if (props.disabled) {
    return props.items.map((item) => props.renderItem(item))
  }

  const toRender = () =>
    props.items.map((child, index) => (
      <Card
        key={child.id}
        accepts={props.accepts}
        element={child}
        id={child.id}
        index={index}
        moveCard={moveCard}
        renderItem={props.renderItem}
      />
    ))

  if (props.noBackend) {
    return toRender()
  }

  return (
    <DndProvider backend={HTML5Backend} options={{ enableMouseEvents: true }}>
      {toRender()}
    </DndProvider>
  )
}

const Card = ({
  id,
  accepts,
  element,
  index,
  moveCard,
  renderItem,
  readonly,
}: DnDCardProps) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'DROPPABLE',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current || readonly) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: accepts || 'DROPPABLE',
    item: () => ({ id }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.25 : 1
  drag(drop(ref))

  const style = isDragging ? draggingContainerStyle : {}

  return (
    <div ref={ref} data-handler-id={handlerId} style={{ ...style, opacity }}>
      {renderItem(element, index)}
    </div>
  )
}

interface DnDCardProps {
  accepts: string
  element: string
  id: any
  index: number
  moveCard: Function
  readonly?: boolean
  renderItem: Function
}
