import { InvoiceReportItem, TaxesForReport } from 'interfaces'
import { TableCell, TableRow } from '../../../components/ui'
import React from 'react'
import { formatAmount } from '../../../lib'
import { compare } from './invoiceCompare'

interface Props {
  reportItem: InvoiceReportItem
}

export const ReportItem = ({ reportItem }: Props) => {
  const taxes = reportItem.taxes.sort(compare)

  return (
    <TableRow>
      <TableCell>{reportItem.receiptNumber}</TableCell>
      <TableCell>{reportItem.date}</TableCell>
      <TableCell>{reportItem.client}</TableCell>
      <TableCell>{reportItem.service}</TableCell>
      <TableCell>{`$${formatAmount(reportItem.subTotal)}`}</TableCell>
      {taxes.map((tax: TaxesForReport) => (<TableCell key={tax.name}>{tax.taxAmount}</TableCell>))}
      <TableCell>{`$${formatAmount(reportItem.total)}`}</TableCell>
      <TableCell>{reportItem.paid ? 'Yes' : 'No'}</TableCell>
      <TableCell>{reportItem.datePaid}</TableCell>
      <TableCell>{reportItem.paymentType}</TableCell>
    </TableRow>
  )
}