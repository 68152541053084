import { PracticeTypes } from './practice-types'

export const BASIC_FALL_BACK_FORM_ID = 'f0000000-0000-0000-0000-000000000002'

export const FORM_SCREEN = {
  CLIENT: 'client',
}

export const FORM_TYPE = {
  ALL: 'all',
  MASSAGE: PracticeTypes.massage,
  PSYCHOTHERAPY: PracticeTypes.psychotherapy,
}

export const TABS = {
  CLIENT_INFO: 'client-info',
  NOTES: 'notes',
}

export const SUMMARY_TAB_ID = 's'
export const CLIENT_INFO_TAB_ID = 'ci'
export const NOTES_TAB_ID = 'n'
export const FILES_TAB_ID = 'f'

export const INDIVIDUAL_SELECTED_TYPE = 'indiv'
export const FAMILY_SELECTED_TYPE = 'family'
export const ADULT_CHILD_SELECTED_TYPE = 'adultchild'
