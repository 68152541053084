import React from 'react'
import styled from 'styled-components'

import { IconButton } from 'components/ui'

const ShapeContainer = styled.div`
  display: flex;
`

const ToolButton = styled(IconButton)`
  padding-left: 0.85em;
  padding-right: 0.85em;
  color: ${(props) =>
    props.active ? props.theme.PrimaryColour : props.theme.NeutralColourOne};

  :hover {
    cursor: pointer;
  }
`

interface Props {
  onChange: (val: string) => void
  selectedShape: string
}

const ShapePicker = ({ selectedShape, onChange }: Props) => (
  <ShapeContainer>
    <ToolButton
      active={selectedShape === 'Circle'}
      name="circle"
      onClick={() => onChange('Circle')}
      size="big"
    />
    <ToolButton
      active={selectedShape === 'Square'}
      name="square"
      onClick={() => onChange('Square')}
      size="big"
    />
    <ToolButton
      active={selectedShape === 'Pencil'}
      name="pencil"
      onClick={() => onChange('Pencil')}
      size="big"
    />
  </ShapeContainer>
)

export default ShapePicker
