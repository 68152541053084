import React, { useState } from 'react'

import { Button } from 'components/ui'
import NewClientModal from 'components/modals/new-client-modal'
import { IClient } from 'interfaces'
import { useNavigate } from 'react-router-dom'

const NewClientButton = () => {
	const [showCreateModal, setShowCreateModal] = useState(false)

	const navigate = useNavigate()

	const renderCreateModal = () => {
		const modalProps = {
			modalOpen: showCreateModal,
			onCancel: () => setShowCreateModal(false),
			onSubmit: (client: IClient) => {
				navigate(`/client/${client.id}/client-info`)
			},
		}

		return <NewClientModal {...modalProps} />
	}

	return (
		<>
			{renderCreateModal()}
			<Button
				content='New Client'
				icon='plus'
				onClick={() => setShowCreateModal(true)}
				primary
				size='small'
			/>
		</>
	)
}

export default NewClientButton
