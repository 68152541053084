import { post } from 'lib/http-request'
import { Emails } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { Dispatch, IAction } from 'interfaces'

export const defaultState = {
  sendingContactUsEmail: false,
  contactUsEmailError: {},
}

const emailsAction = (action: string) => `client-trackyr/emails/${action}`
const ON_SEND_CONTACT_US = emailsAction('ON_SEND_CONTACT_US')
const ON_SEND_CONTACT_US_SUCCESS = emailsAction('ON_SEND_CONTACT_US_SUCCESS')
const ON_SEND_CONTACT_US_FAILURE = emailsAction('ON_SEND_CONTACT_US_FAILURE')

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case ON_SEND_CONTACT_US:
      return {
        ...state,
        sendingContactUsEmail: true,
        contactUsEmailError: {},
      }

    case ON_SEND_CONTACT_US_SUCCESS:
      return {
        ...state,
        sendingContactUsEmail: false,
        contactUsEmailError: {},
      }

    case ON_SEND_CONTACT_US_FAILURE:
      return {
        ...state,
        sendingContactUsEmail: false,
        contactUsEmailError: action.error,
      }

    default:
      return state
  }
}

export const sendContactUsEmail = (
  from: string,
  subject: string,
  msg: string,
) => async (dispatch: Dispatch) => {
  dispatch({ type: ON_SEND_CONTACT_US, from, subject, msg })

  return post(Emails.ContactUs, { from, subject, message: msg })
    .then((response) => {
      dispatch(onSendSuccess())
      message.success('Email Sent!')

      return response
    })
    .catch((error) => {
      message.error('Failed to Send Email, please try again later')
      captureMessage(`Failed to Send Email: ${error}`, Severity.Error)
      dispatch(onSendFailure(error))

      return error
    })
}

const onSendSuccess = () => ({ type: ON_SEND_CONTACT_US_SUCCESS })

const onSendFailure = (error: any) => ({
  type: ON_SEND_CONTACT_US_FAILURE,
  error,
})
