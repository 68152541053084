import { createSelector } from 'reselect'
import { getBodyDiagrams$ } from 'containers/settings/selectors'
import { IState } from 'interfaces'

const getShapes$ = (state: IState) => state.draw.shapes
const getSoaps$ = (state: IState) =>
  state.soapNotes.soaps[state.clients.selectedClient] || {}
const getSelectedSoap$ = (state: IState) =>
  (state.appointments.selectedAppointment &&
    state.appointments.selectedAppointment.id) ||
  ''

export const getShapesForSoap$ = createSelector(
  [getShapes$, getSelectedSoap$],
  (shapes, selectedSoap) => {
    const shapesForSoap =
      (shapes[selectedSoap] &&
        shapes[selectedSoap].sort((a, b) => a.order - b.order)) ||
      []

    if (shapesForSoap.filter((shape) => shape.order === 0).length > 0) {
      return shapesForSoap.map((shape, index) => ({
        ...shape,
        order: index + 1,
      }))
    }

    return shapesForSoap
  },
)

export const getCurrentBodyDiagram$ = createSelector(
  [getBodyDiagrams$],
  (bodyDiagrams) => bodyDiagrams.find((bd) => bd.isCurrent),
)

export const getBodyDiagram$ = createSelector(
  [getSoaps$, getSelectedSoap$, getBodyDiagrams$, getCurrentBodyDiagram$],
  (soaps, selectedSoap, bodyDiagrams, currentBodyDiagram) => {
    const bodyDiagramID =
      (soaps[selectedSoap] && soaps[selectedSoap].bodyDiagramID) ||
      currentBodyDiagram.id
    return bodyDiagrams.find((bd) => bd.id === bodyDiagramID)
  },
)
