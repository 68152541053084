import { authPost } from 'lib/http-request'
import { User } from 'constants/endpoints'
import { fetchAccountData, fetchAccountForms } from 'containers/account'
import { fetchBodyDiagrams, fetchFormsTypes } from 'containers/settings'
import { CLIENT_TYPES, SOAP_TYPES } from 'constants/settings'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { Dispatch, ErrorState, IAction, IResponse, IUser } from 'interfaces'

const userAction = (action: string) => `client-trackyr/user/${action}`
const FETCH_CLIENT_TRACKER_USER = userAction('FETCH_CLIENT_TRACKER_USER')
const FETCH_CLIENT_TRACKER_USER_SUCCESS = userAction(
  'FETCH_CLIENT_TRACKER_USER_SUCCESS',
)
const FETCH_CLIENT_TRACKER_USER_FAILURE = userAction(
  'FETCH_CLIENT_TRACKER_USER_FAILURE',
)

export const defaultState = { ctUser: {} }

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_CLIENT_TRACKER_USER_SUCCESS: {
      const fetchedUser = {
        ...action.clientTrackerUser,
        fetched: true,
        error: { hasError: false },
      }

      return {
        ...state,
        ctUser: fetchedUser,
      }
    }

    case FETCH_CLIENT_TRACKER_USER_FAILURE:
      return {
        ...state,
        ctUser: {
          ...state.ctUser,
          error: { hasError: true, message: 'Failed to fetch User Data' },
        },
      }

    default:
      return state
  }
}

export const fetchClientTrackerUser = (firebaseUser?: IUser) => async (
  dispatch: Dispatch,
): Promise<IResponse<IUser>> => {
  dispatch({ type: FETCH_CLIENT_TRACKER_USER })

  const user = {
    displayName: firebaseUser?.displayName,
    photoURL: firebaseUser?.photoURL,
    email: firebaseUser?.email,
    firebaseID: firebaseUser?.uid,
  }

  return authPost(User.Login, user)
    .then((response: IResponse<IUser>) => {
      dispatch(fetchClientTrackerUserSuccess(response.data))
      dispatch(fetchAccountData(firebaseUser))
      dispatch(fetchAccountForms())
      dispatch(fetchBodyDiagrams())
      dispatch(fetchFormsTypes(CLIENT_TYPES))
      dispatch(fetchFormsTypes(SOAP_TYPES))

      return response
    })
    .catch((error) => {
      captureMessage(`fetchClientTrackerUser Failed. ${error}`, Severity.Error)
      dispatch(fetchClientTrackerUserError(error))
      return error
    })
}

const fetchClientTrackerUserSuccess = (clientTrackerUser: IUser) => ({
  type: FETCH_CLIENT_TRACKER_USER_SUCCESS,
  clientTrackerUser,
})

const fetchClientTrackerUserError = (error: ErrorState) => ({
  type: FETCH_CLIENT_TRACKER_USER_FAILURE,
  error,
})
