import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import {
  SelectableH1Mobile,
  Divider,
  Popup,
  Text,
  Label,
  Card,
} from 'components/ui'
import { saveAppointment } from 'containers/appointments'
import { NewAppointmentDayModal } from 'components/modals'
import { IAppointment } from 'interfaces/appointments'
import { AppointmentModal, AppointmentLocation } from 'containers/calendar'
import { AppointmentLocation as AppointmentLocations } from 'containers/appointments/enums'
// import NewAppointmentNotesModal from 'components/modals/new-appointment-notes-modal'
import { getSelectedClient$ } from 'containers/clients'
import {
  appointmentStatusToText,
  appointmentStatusToColour,
} from 'containers/appointments/enums'
import { MobileBreakPoint } from 'styles'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AppointmentLabelStatusContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  @media (max-width: ${MobileBreakPoint}) {
    justify-content: flex-start;
    flex-direction: column;
  }
`

const AppointmentsFormInline = (props: IAppointmentsFormInline) => {
  var dispatch = useDispatch()

  const client = useSelector(getSelectedClient$)

  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [calendarModalOpen, setCalendarModalOpen] = useState(false)

  const onSubmit = (event: IAppointment) => {
    setLoading(true)
    const start = moment(event.start)
    const end = moment(event.end)

    const startHM = start.format('hh:mm').split(':')
    const endHM = end.format('hh:mm').split(':')

    start.set({ h: Number(startHM[0]), m: Number(startHM[1]) })
    end.set({ h: Number(endHM[0]), m: Number(endHM[1]) })

    dispatch(
      saveAppointment({
        ...props.appointment,
        start: start,
        end: end,
      }),
    ).then(() => {
      setLoading(false)
      setEdit(false)
    })
  }

  const locationValid =
    props.appointment.appointmentLocation !== AppointmentLocations.None &&
    props.appointment.appointmentLocation !== AppointmentLocations.Unknown
  const clientNotesValid = !!props.appointment.clientNotes
  const appointmentNotesValid = !!props.appointment.notes

  const validAppointmentNotes =
    locationValid || clientNotesValid || appointmentNotesValid

  return (
    <>
      <NewAppointmentDayModal
        event={props.appointment}
        loading={loading}
        modalOpen={edit}
        onAccept={onSubmit}
        onCancel={() => setEdit(false)}
      />
      <AppointmentModal
        clientID={client.id}
        event={props.appointment}
        isEditMode
        modalOpen={calendarModalOpen}
        onCancel={() => setCalendarModalOpen(false)}
        readonly={props.readonly}
      />

      <HeaderContainer style={{ alignItems: 'center' }}>
        <AppointmentLabelStatusContainer>
          <Popup
            content={
              props.readonly
                ? 'Appointment Details'
                : 'Appointment Details & Edit'
            }
            position="top center"
            trigger={
              <div>
                <SelectableH1Mobile
                  onClick={() => setCalendarModalOpen(true)}
                >{`${moment(props.appointment.start).format(
                  'MMMM D YYYY h:mma',
                )} - ${moment(props.appointment.end).format(
                  'h:mma',
                )}`}</SelectableH1Mobile>
              </div>
            }
          />

          <div style={{ display: 'flex' }}>
            <div
              style={{
                borderRadius: '50%',
                width: '1em',
                height: '1em',
                backgroundColor: appointmentStatusToColour(
                  props.appointment.status,
                ),
                marginLeft: '1em',
                marginTop: '0.25em',
              }}
            />
            <Text>
              &nbsp;({appointmentStatusToText(props.appointment.status)})
            </Text>
          </div>
        </AppointmentLabelStatusContainer>

        {props.children && <div>{props.children}</div>}
      </HeaderContainer>

      <div style={{ marginBottom: '0.5em' }} />
      {validAppointmentNotes && (
        <Card hideTitle>
          {locationValid && <Label>Appointment Location</Label>}
          {locationValid && (
            <AppointmentLocation
              appointment={props.appointment}
              clientID={props.appointment.clientID}
              locationIndex={props.appointment.appointmentLocation}
              readonly
            />
          )}

          {clientNotesValid && (
            <>
              <Divider />
              <Label style={{ marginTop: '1em', paddingTop: '1em' }}>
                Additional Notes for Client
              </Label>
              <Text>{props.appointment.clientNotes}</Text>
            </>
          )}

          {appointmentNotesValid && (
            <>
              <Label>Appointment Notes for Therapist</Label>
              <Text>{props.appointment.notes}</Text>
            </>
          )}
        </Card>
      )}

      <Divider />
    </>
  )
}

interface IAppointmentsFormInline {
  appointment: IAppointment
  children?: React.ReactNode
  readonly?: boolean
}

export default AppointmentsFormInline
