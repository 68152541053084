import React from 'react'
import styled from 'styled-components'

import {
  NeutralColourThree,
  AppointmentCompletedColour,
  AppointmentCancelledNoShowColour,
  AppointmentCancelledColour,
  AppointmentCreatedColour,
  AppointmentSupervisionColour,
} from 'styles/colours'
import { Text } from 'components/ui'

export enum AppointmentStatus {
  Unknown = 0,
  Created = 1,
  Valid = 2,
  Completed = 3,
  NoShow = 4,
  Cancelled = 5,
  CancelledTooLate = 6,
  Deleted = 7,
  Conflict = 8,
  SchedulingConflict = 9,
  ForgottenByTherapist = 10,
  TimeOff = 11,
  Unavailable = 12,
  Sick = 13,
  NonClientHours = 14,
  Supervision = 15,
  ContinuingEducation = 16,
}

export const appointmentStatusToText = (
  appointmentStatus: AppointmentStatus,
) => {
  switch (appointmentStatus) {
    case AppointmentStatus.Created:
      return 'Appointment'
    case AppointmentStatus.Valid:
      return 'Valid'
    case AppointmentStatus.Completed:
      return 'Completed'
    case AppointmentStatus.NoShow:
      return 'No Show'
    case AppointmentStatus.Cancelled:
      return 'Cancelled'
    case AppointmentStatus.CancelledTooLate:
      return 'Cancelled Too Late'
    case AppointmentStatus.TimeOff:
      return 'Time Off'
    case AppointmentStatus.Unavailable:
      return 'Unavailable'
    case AppointmentStatus.Sick:
      return 'Sick'
    case AppointmentStatus.NonClientHours:
      return 'Non-Client Hours Work'
    case AppointmentStatus.Supervision:
      return 'Supervision'
    case AppointmentStatus.ContinuingEducation:
      return 'Continuing Education'
    case AppointmentStatus.Unknown:
    default:
      return 'Unknown'
  }
}

export const appointmentStatusToColour = (
  appointmentStatus: AppointmentStatus,
) => {
  switch (appointmentStatus) {
    case AppointmentStatus.Created:
    case AppointmentStatus.Valid:
    case AppointmentStatus.NonClientHours:
      return AppointmentCreatedColour

    case AppointmentStatus.Supervision:
      return AppointmentSupervisionColour

    case AppointmentStatus.Completed:
      return AppointmentCompletedColour

    case AppointmentStatus.NoShow:
    case AppointmentStatus.CancelledTooLate:
      return AppointmentCancelledNoShowColour

    case AppointmentStatus.Cancelled:
    case AppointmentStatus.TimeOff:
    case AppointmentStatus.Unavailable:
    case AppointmentStatus.Sick:
      return AppointmentCancelledColour

    case AppointmentStatus.Unknown:
    default:
      return NeutralColourThree
  }
}

export const appointmentStatusToColourNullIfValid = (
  appointmentStatus: AppointmentStatus,
) => {
  switch (appointmentStatus) {
    case AppointmentStatus.Created:
    case AppointmentStatus.Valid:
      return null

    // TODO - different colours?
    case AppointmentStatus.NonClientHours:
      return null

    case AppointmentStatus.Supervision:
      return AppointmentSupervisionColour

    case AppointmentStatus.Completed:
      return AppointmentCompletedColour

    case AppointmentStatus.NoShow:
    case AppointmentStatus.CancelledTooLate:
      return AppointmentCancelledNoShowColour

    case AppointmentStatus.Cancelled:
    case AppointmentStatus.TimeOff:
    case AppointmentStatus.Unavailable:
    case AppointmentStatus.Sick:
      return AppointmentCancelledColour

    case AppointmentStatus.Unknown:
    default:
      return NeutralColourThree
  }
}

const OptDiv = styled.div`
  display: flex;
  margin-bottom: 0;
  padding-bottom: 0;
`

interface IDot {
  backgroundColor: string
}
const Dot = styled.div<IDot>`
  border-radius: 50%;
  width: 1em;
  height: 1em;
  background-color: ${(props) => props.backgroundColor};
  margin-left: 1em;
  margin-top: 0.25em;
`

const getOptionText = (status: AppointmentStatus) => (
  <OptDiv>
    <Text style={{ marginBottom: 0 }}>{appointmentStatusToText(status)}</Text>
    <Dot backgroundColor={appointmentStatusToColour(status)} />
  </OptDiv>
)

export const AppointmentOptions = [
  // {
  //   id: AppointmentStatus.Unknown,
  //   text: getOptionText(AppointmentStatus.Unknown),
  //   value: AppointmentStatus.Unknown,
  //   key: 'Unknown',
  // },
  {
    id: AppointmentStatus.Created,
    text: getOptionText(AppointmentStatus.Created),
    value: AppointmentStatus.Created,
    key: 'Created',
  },
  {
    id: AppointmentStatus.Supervision,
    text: getOptionText(AppointmentStatus.Supervision),
    value: AppointmentStatus.Supervision,
    key: 'Supervision',
  },
  {
    id: AppointmentStatus.Cancelled,
    text: getOptionText(AppointmentStatus.Cancelled),
    value: AppointmentStatus.Cancelled,
    key: 'Cancelled',
  },
  {
    id: AppointmentStatus.NoShow,
    text: getOptionText(AppointmentStatus.NoShow),
    value: AppointmentStatus.NoShow,
    key: 'NoShow',
  },
  {
    id: AppointmentStatus.CancelledTooLate,
    text: getOptionText(AppointmentStatus.CancelledTooLate),
    value: AppointmentStatus.CancelledTooLate,
    key: 'CancelledTooLate',
  },
  // UNCHANGEABLE
  // {
  //   id: AppointmentStatus.Deleted,
  //   text: getOptionText(AppointmentStatus.Deleted),
  //   value: AppointmentStatus.Deleted,
  //   key: 'Deleted',
  // },
  // {
  //   id: AppointmentStatus.Valid,
  //   text: getOptionText(AppointmentStatus.Valid),
  //   value: AppointmentStatus.Valid,
  //   key: 'Valid',
  // },
  // UNCHANGEABLE
  // {
  //   id: AppointmentStatus.Completed,
  //   text: getOptionText(AppointmentStatus.Completed),
  //   value: AppointmentStatus.Completed,
  //   key: 'Completed',
  // },
]

export const TherapistOptions = [
  {
    id: AppointmentStatus.Unavailable,
    text: getOptionText(AppointmentStatus.Unavailable),
    value: AppointmentStatus.Unavailable,
    key: 'Unavailable',
  },
  {
    id: AppointmentStatus.TimeOff,
    text: getOptionText(AppointmentStatus.TimeOff),
    value: AppointmentStatus.TimeOff,
    key: 'TimeOff',
  },
  {
    id: AppointmentStatus.NonClientHours,
    text: getOptionText(AppointmentStatus.NonClientHours),
    value: AppointmentStatus.NonClientHours,
    key: 'NonClientHours',
  },
  {
    id: AppointmentStatus.Supervision,
    text: getOptionText(AppointmentStatus.Supervision),
    value: AppointmentStatus.Supervision,
    key: 'Supervision',
  },
  {
    id: AppointmentStatus.ContinuingEducation,
    text: getOptionText(AppointmentStatus.ContinuingEducation),
    value: AppointmentStatus.ContinuingEducation,
    key: 'Continuing Education',
  },
  {
    id: AppointmentStatus.Sick,
    text: getOptionText(AppointmentStatus.Sick),
    value: AppointmentStatus.Sick,
    key: 'Sick',
  },
]

export enum AppointmentType {
  Unknown = 0,
  Client = 1,
  Therapist = 2,
}
export const AppointmentTypeOptions = [
  {
    id: AppointmentType.Client,
    text: 'Client',
    value: AppointmentType.Client,
    key: AppointmentType.Client,
  },
  {
    id: AppointmentType.Therapist,
    text: 'Therapist',
    value: AppointmentType.Therapist,
    key: AppointmentType.Therapist,
  },
]

export enum AppointmentLocation {
  Unknown = 0,
  Clinic = 1,
  Online = 2,
  Phone = 3,
  None = 4,
}

export const appointmentLocationToText = (
  location: AppointmentLocation,
): string => {
  switch (location) {
    case AppointmentLocation.Unknown:
      return 'Unknown'
    case AppointmentLocation.Clinic:
      return 'Clinic'
    case AppointmentLocation.Online:
      return 'Online'
    case AppointmentLocation.Phone:
      return 'Phone'
    case AppointmentLocation.None:
      return 'None'
  }
}
