import React from 'react'
import styled from 'styled-components'

import { Input } from 'components/forms'
import { Button, Label } from 'components/ui'
import { IEvent } from 'interfaces'
import { FormItemComponentData } from '../interfaces'

export const SPLIT_CHAR = '$!'
const MultiTextContainer = styled.div`
  display: flex;
  align-items: center;
`
interface MultiTextProps {
  dataItem: FormItemComponentData
  onChange: (e: IEvent) => void
  required?: boolean
  values: string
}

export const MultiTextValues = ({
  dataItem,
  onChange,
  required,
  values = '',
}: MultiTextProps) => {
  const splitValues = Array.isArray(values)
    ? [...values].map((v) => v.label || v)
    : values?.split(SPLIT_CHAR) || ['']

  if (splitValues.length === 0) {
    splitValues.push('')
  }

  const onValuesChange = ({ target }: IEvent, i: number) => {
    splitValues[i] = target.value
    const newSplitValues = splitValues.map((s) => s?.text || s)

    onChange({
      target: { name: dataItem.name, value: newSplitValues.join(SPLIT_CHAR) },
    })
  }

  const onClick = () => {
    const newValues = [...splitValues].map((s) => s?.text || s)
    onChange({
      target: {
        name: dataItem.name,
        value: `${newValues.join(SPLIT_CHAR)}${SPLIT_CHAR}`,
      },
    })
  }

  const onRemove = (i: number) => {
    const newSplitValues = [
      ...splitValues.slice(0, i),
      ...splitValues.slice(i + 1, splitValues.length),
    ].map((s) => s?.text || s)
    onChange({
      target: { name: dataItem.name, value: newSplitValues.join(SPLIT_CHAR) },
    })
  }

  return (
    <div>
      <Label>{dataItem.label}</Label>

      {splitValues?.map((v, i) => (
        <MultiTextContainer key={`${dataItem.label}-${i}`}>
          <Input
            label={`Option ${i + 1}`}
            name={`${dataItem.label}-${i}`}
            onChange={(e: IEvent) => onValuesChange(e, i)}
            placeholder="Option"
            validation={{ required }}
            value={v?.id || v}
          />

          {splitValues.length > 1 && (
            <div>
              <Button
                circular
                icon="x"
                negative
                onClick={() => onRemove(i)}
                size="tiny"
              />
            </div>
          )}
        </MultiTextContainer>
      ))}

      <Button content="Add Option" onClick={onClick} primary size="small" />
    </div>
  )
}
