import React from 'react'

import { IPublicIntake } from 'interfaces'
import { LinkText, Segment } from 'components/ui'

import { Text, DataItem, Header, SList as List } from 'components/ui'

import { toCamelCase } from './json-parsing-fix'
import moment from 'moment'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getFormTemplates$ } from 'containers/forms'

interface Props {
  value: string
}

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.25em;
`

export const PublicIntakeValue = ({ value }: Props) => {
  const publicIntake: IPublicIntake = JSON.parse(value, toCamelCase)
  const formTemplates = useSelector(getFormTemplates$)

  if (!publicIntake) {
    return null
  }

  const getStatus = (status: string) => {
    if (status === 'FailedToSendEmail') {
      return 'Intake Form Created, Failed to Send Email'
    }

    return status
  }

  const link = `${document.location.origin}/intake-forms/${publicIntake.clientID}/${publicIntake.link}`

  return (
    <>
      <Segment>
        <List>
          <List.Item>
            <List.Content>
              <DataItem>
                <Text>Email</Text>
                <Text>{publicIntake.email}</Text>
              </DataItem>
            </List.Content>

            {!publicIntake.status ? (
              <List.Content>
                <DataItem>
                  <Text>Completed</Text>
                  <Text>{publicIntake.completed ? 'Yes' : 'No'}</Text>
                </DataItem>
              </List.Content>
            ) : (
              <List.Content>
                <DataItem>
                  <Text>Status</Text>
                  <Text>{getStatus(publicIntake.status)}</Text>
                </DataItem>
              </List.Content>
            )}

            {!publicIntake.completed && (
              <List.Content>
                <DataItem>
                  <Text>Expires</Text>
                  <Text>
                    {moment(publicIntake.expires).format('MMMM D YYYY')}
                  </Text>
                </DataItem>
              </List.Content>
            )}

            {publicIntake.completed && publicIntake.dateCompleted && (
              <List.Content>
                <DataItem>
                  <Text>
                    {publicIntake.completed &&
                    publicIntake.status !== 'Closed' &&
                    publicIntake.dateCompleted
                      ? 'Date Completed'
                      : 'Date Closed'}
                  </Text>
                  <Text>
                    {moment(publicIntake.dateCompleted).format(
                      'MMMM D YYYY h:mm a',
                    )}
                  </Text>
                </DataItem>
              </List.Content>
            )}

            <List.Content>
              <DataItem>
                <Text>Link</Text>
                {publicIntake.completed ? (
                  <Text>-</Text>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <LinkText href={link}>{link}</LinkText>
                  </div>
                )}
              </DataItem>
            </List.Content>

            {publicIntake.passphrase && (
              <List.Content>
                <DataItem>
                  <Text>Passphrase</Text>
                  <Text>{publicIntake.passphrase}</Text>
                </DataItem>
              </List.Content>
            )}

            <List.Content>
              <Header>Forms</Header>
              <Segment>
                {publicIntake.forms &&
                  publicIntake.forms.map((form) => {
                    const formTemplate = formTemplates[form.id] || {}

                    return (
                      <FormContainer key={form.id}>
                        <div>
                          <Text>{formTemplate.name}</Text>
                        </div>
                      </FormContainer>
                    )
                  })}
              </Segment>
            </List.Content>
          </List.Item>

          {/* {publicIntake.completed && publicIntake.status === 'Completed' && (
              <List.Item>
                <Header style={{ marginBottom: '1em' }}>Signed Form</Header>
                <Signature
                  readonly
                  dateValue={signatureData.date}
                  nameValue={signatureData.name}
                  signature={signatureData.signature}
                />
              </List.Item>
            )} */}
        </List>
      </Segment>
    </>
  )
}
