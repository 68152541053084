import { authGet } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'
import localforage from 'localforage'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { LS_BODY_DIAGRAM } from 'constants/local-storage'
import { BACKGROUND_ID } from 'constants/temp-image'
import { CLIENT_TRACKYR_STORE, FLAGS } from 'constants/storage'
import { IAction, Dispatch, IResponse, BodyDiagram } from 'interfaces'

const flagStore = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: FLAGS,
})

const bodyDiagramAction = (action: string) =>
  `client-trackyr/bodydiagram/${action}`
const FETCH_BODY_DIAGRAM = bodyDiagramAction('FETCH_BODY_DIAGRAM')
const ON_FETCH_BODY_DIAGRAM_SUCCESS = bodyDiagramAction(
  'ON_FETCH_BODY_DIAGRAM_SUCCESS',
)
const ON_FETCH_BODY_DIAGRAM_FAILURE = bodyDiagramAction(
  'ON_FETCH_BODY_DIAGRAM_FAILURE',
)

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_BODY_DIAGRAM:
      return {
        ...state,
        bodyDiagramLoading: true,
      }

    case ON_FETCH_BODY_DIAGRAM_SUCCESS:
      return {
        ...state,
        bodyDiagrams: action.response,
        bodyDiagramLoading: false,
      }

    case ON_FETCH_BODY_DIAGRAM_FAILURE:
      return {
        ...state,
        bodyDiagramLoading: false,
      }

    default:
      return state
  }
}

export const fetchBodyDiagrams = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_BODY_DIAGRAM })

  return authGet(Settings.FetchBodyDiagrams)
    .then((response: IResponse<any>) => {
      const diagrams: Array<BodyDiagram> = response.data
      diagrams.push({
        blobStorageFileID: BACKGROUND_ID,
        id: BACKGROUND_ID,
        isCurrent: diagrams.length === 0,
      })

      dispatch(onFetchSuccess(diagrams))
      setBodyDiagram(diagrams.find((b: BodyDiagram) => b.isCurrent))
      return response
    })
    .catch((error) => {
      message.error('Failed to Fetch Body Diagrams, please try again')

      captureMessage(`fetchBodyDiagrams Failed. ${error}`, Severity.Error)
      dispatch(onFetchFailure(error))

      return error
    })
}

const onFetchSuccess = (response: any) => ({
  type: ON_FETCH_BODY_DIAGRAM_SUCCESS,
  response,
})
const onFetchFailure = (error: any) => ({
  type: ON_FETCH_BODY_DIAGRAM_FAILURE,
  error,
})

const setBodyDiagram = async (bodyDiagram: BodyDiagram) =>
  await flagStore.setItem(LS_BODY_DIAGRAM, bodyDiagram.id)
