import React from 'react'
import styled from 'styled-components'

import { Input, Dropdown } from 'components/ui'
import { ReadonlyFormFont } from 'styles/font'

import { AddOrRemoveButton } from 'components/forms'
import { IEvent } from 'interfaces'

const PhoneNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`

const PhoneNumber = ({
  name,
  phoneNumbers = [],
  onChange,
  oneNumber,
  noType,
  onBlur,
  size,
  readonly,
}: IPhoneNumber) => {
  if (!phoneNumbers.length || !phoneNumbers.map) {
    phoneNumbers = [{ type: 'Mobile', phoneNumber: '' }]
  }

  if (readonly) {
    return (
      <>
        {phoneNumbers.map((phoneNumber, index) => (
          <ReadonlyFormFont key={index} paddingBottom="0">
            {phoneNumber.phoneNumber &&
              `${phoneNumber.type || 'Mobile'}: ${phoneNumber.phoneNumber}`}
          </ReadonlyFormFont>
        ))}
      </>
    )
  }

  return (
    <PhoneNumbersContainer key={name}>
      {phoneNumbers.map((phoneNumber, index) => (
        <PhoneNumberInput
          key={`${name}-${index}`}
          add={() => {
            onChange({
              target: {
                name,
                value: [...phoneNumbers, { type: 'Mobile', phoneNumber: '' }],
              },
            })
          }}
          index={index}
          isFirst={index === 0}
          isRemove={
            phoneNumbers.length <= 1
              ? false
              : index === phoneNumbers.length - 1
              ? false
              : true
          }
          name={name}
          noType={noType}
          onBlur={() =>
            onBlur({
              target: {
                name,
                value: [...phoneNumbers],
              },
            })
          }
          onChange={(e) => {
            const pns = [...phoneNumbers]
            pns[e.index][e.type] = e.value
            onChange({ target: { name, value: pns } })
          }}
          oneNumber={oneNumber}
          phoneNumber={phoneNumber}
          remove={() => {
            onChange({
              target: {
                name,
                value: [
                  ...phoneNumbers.slice(0, index),
                  ...phoneNumbers.slice(index + 1, phoneNumbers.length),
                ],
              },
            })
          }}
          size={size}
        />
      ))}
    </PhoneNumbersContainer>
  )
}

export const PhoneNumberInput = ({
  add,
  index,
  isFirst,
  isRemove,
  name,
  noType,
  onBlur,
  onChange,
  oneNumber,
  phoneNumber,
  remove,
  size,
}: IPhoneNumberInput) => (
  <Container>
    {!noType && (
      <div style={{ marginRight: '0.5em' }}>
        <PhoneType
          name={name}
          onChange={({ target }) => {
            onChange({
              value: target.value,
              type: 'type',
              index,
            })
          }}
          selected={phoneNumber.type}
        />
      </div>
    )}
    <div>
      <Input
        icon="phone"
        onBlur={onBlur ? (e: IEvent) => onBlur(e) : null}
        onChange={(e: IEvent) =>
          onChange({
            value: e.target.value,
            type: 'phoneNumber',
            index,
          })
        }
        placeholder="Phone Number"
        size={size}
        type="tel"
        value={phoneNumber.phoneNumber}
      />
    </div>
    {!oneNumber && (
      <div>
        <AddOrRemoveButton
          add={add}
          isFirst={isFirst}
          isRemove={isRemove}
          remove={remove}
        />
      </div>
    )}
  </Container>
)

interface IPhoneNumberInput {
  add: Function
  index: number
  isFirst: boolean
  isRemove: boolean
  name: string
  noType?: boolean
  onBlur: Function
  onChange: Function
  oneNumber?: boolean
  phoneNumber: IPhone
  remove: Function
  size: string
}

const PhoneType = ({ onChange, name, selected }: IPhoneType) => (
  <Dropdown
    name={name}
    onChange={onChange}
    options={[
      { key: 'Mobile', text: 'Mobile', value: 'Mobile' },
      { key: 'Home', text: 'Home', value: 'Home' },
      { key: 'Work', text: 'Work', value: 'Work' },
      { key: 'School', text: 'School', value: 'School' },
    ]}
    search
    style={{ minWidth: '6.5em', marginRight: '0.5em' }}
    value={selected}
  />
)

interface IPhoneNumber {
  name: string
  noType?: boolean
  onBlur: Function
  onChange: Function
  oneNumber?: boolean
  phoneNumbers: Array<IPhone>
  readonly: boolean
  size: string
}

interface IPhoneType {
  name: string
  onChange: Function
  selected: string
}

interface IPhone {
  type?: string
  phoneNumber?: string
}

PhoneType.defaultProps = {
  selected: 'Mobile',
}

PhoneNumber.defaultProps = {
  phoneNumbers: [{ type: 'Mobile', phoneNumber: '' }],
  readonly: false,
}

export { PhoneNumber }
export type { IPhone, IPhoneType }
