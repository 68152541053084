import React, { useEffect, useState } from 'react'

import { Button, Card, List } from 'components/ui'
import { ServiceModal } from '../components/service-modal'
import { getServices$ } from '../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { Service } from '../interfaces'
import { Dispatch, IState } from 'interfaces'
import { ServiceItem } from '../components/service-item'
import { fetchServices } from '../redux/fetch-service'
import { TaxSettings } from 'containers/taxes'

export const ServiceSettings = () => {
	const dispatch: Dispatch = useDispatch()
	const [modalState, setModalState] = useState({ open: false, service: null })

	const services: Service[] = useSelector(getServices$)
	const fetching: boolean = useSelector(
		(state: IState) => state.services.flags.fetching,
	)

	useEffect(() => {
		dispatch(fetchServices())
	}, [dispatch])

	const onClick = (service: Service) => {
		setModalState({ open: true, service: service })
	}

	const onModalClose = () => {
		setModalState({ open: false, service: null })
	}

	const onAddService = () => {
		setModalState({ open: true, service: {} as Service })
	}

	return (
		<>
			<ServiceModal
				onClose={onModalClose}
				open={modalState.open}
				service={modalState.service}
			/>

			<Card title='Service Settings'>
				<Button
					content='New Service'
					icon='plus'
					onClick={onAddService}
					primary
				/>

				<List
					collapseOnMobile={false}
					items={services}
					itemType='Forms'
					loading={fetching}
					noItemsMessage='No Services'
					renderer={(service: Service) => (
						<ServiceItem onClick={onClick} service={service} />
					)}
				/>
			</Card>

			<TaxSettings />
		</>
	)
}
