import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Modal, Button, ModalHeader } from 'components/ui'
import { getClientFullName, getClients$ } from 'containers/clients'
import { getOfficesSimpleList } from 'containers/account/office'
import {
	saveAppointment,
	AppointmentStatus,
	deleteAppointment,
	getIsForClient,
} from 'containers/appointments'
import { NewAppointmentDayModal, ConfirmCancelModal } from 'components/modals'
import {
	AppointmentLocation as AppointmentLocations,
	AppointmentType,
} from 'containers/appointments/enums'
import NewAppointmentNotesModal from 'components/modals/new-appointment-notes-modal'
import { IAppointment, AppointmentEventData } from 'interfaces/appointments'
import { appointmentStatusToText } from 'containers/appointments/enums'
import {
	IState,
	ICalendarEvent,
	IClient,
	IResponse,
	Validation, Dispatch,
} from 'interfaces'
import NewClientModal from 'components/modals/new-client-modal'
import {
	sendAppointmentEmail,
	sendAppointmentSMS,
	setupAppointmentEmailReminder,
	setupAppointmentReminderSMS,
} from 'containers/emails'
import { checkFeatures } from 'containers/features'
import {
	CanSendAppointmentReminders,
	GlobalAppointmentReminders,
} from 'constants/features'
import styled from 'styled-components'
import { MobileBreakPoint } from 'styles'
import { getSetting } from 'containers/settings'
import {
	DEFAULT_SEND_APPOINTMENT_EMAIL,
	DEFAULT_SEND_APPOINTMENT_SMS,
} from 'constants/settings'
import { AppointmentSetup } from './components/appointment-modal-setup'
import { AppointmentSendReminders } from './components/appointment-modal-send-reminders'
import { getDefaultPhoneNumber } from 'components/ui/phone-number-dropdown'
import { AppointmentModalSerivceClientSelect } from './redux/appointment-modal-service-client-select'
import { parseServiceTimeToMinutes } from 'containers/services/lib/service-time-to-minutes-parser'
import { hasPotentialServices$ } from 'containers/services'
import { useNavigate } from 'react-router-dom'

const DeleteButton = styled(Button)`
  &&&&& {
    @media (max-width: ${MobileBreakPoint}) {
      margin-top: 1.5em;
    }
  }
`

const CancelButton = styled(Button)`
  &&&&& {
    @media (min-width: ${MobileBreakPoint}) {
      margin-right: 1em;
    }
  }
`

const MobileButtons = styled.div`
  display: flex;

  @media (max-width: ${MobileBreakPoint}) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`

enum AppointmentStep {
	Setup = 0,
	Reminders = 1,
	ClientServiceSelect = 2,
}

interface Props {
	clientID?: string
	event?: IAppointment
	gotoNotes?: boolean
	isDirty?: boolean
	isEditMode?: boolean
	// loading?: boolean
	modalOpen: boolean
	onCancel: Function
	readonly?: boolean
	showCalendarAtStart?: boolean
}

// - this is a horribly large Modal :|
const AppointmentModal = (props: Props) => {
	const isEditMode = props.isEditMode

	const dispatch: Dispatch = useDispatch()
	const navigate = useNavigate()

	const clients = useSelector(getClients$)
	const offices = useSelector(getOfficesSimpleList)
	const saving = useSelector(
		(state: IState) => state.appointments.savingAppointment,
	)
	const deletingAppointment = useSelector(
		(state: IState) => state.appointments.deletingAppointment,
	)
	const appointmentReminderFeature = useSelector((state: IState) =>
		checkFeatures(state, [CanSendAppointmentReminders]),
	)
	const defaultSendAppointmentEmail = useSelector(
		(state: IState) =>
			getSetting(state, DEFAULT_SEND_APPOINTMENT_EMAIL) === 'true',
	)
	const defaultSendAppointmentSMS = useSelector(
		(state: IState) =>
			getSetting(state, DEFAULT_SEND_APPOINTMENT_SMS) === 'true',
	)
	const emailFlagFeature = useSelector((state: IState) =>
		checkFeatures(state, [
			GlobalAppointmentReminders,
			CanSendAppointmentReminders,
		]),
	)
	const servicesFetchedAndExist = useSelector(hasPotentialServices$)

	const [clientID, setClientID] = useState(props.clientID)
	const [client, setClient] = useState(clients[props.clientID] || {})
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [editEventModalOpen, setEditEventModalOpen] = useState(false)
	const [calendarModalOpen, setCalendarModalOpen] = useState(false)
	const [editAppointmentStatus, setEditAppointmentStatus] = useState(false)
	const [eventData, setEventData] = useState({
		clientID: clientID,
		event:
			props.event ||
			({
				start: moment(),
				end: moment(),
				appointmentType: AppointmentType.Client,
			} as IAppointment),
		notes: '',
		clinicID: offices.length === 1 ? offices[0].id : null,
		isDirty: props.isDirty,
		email: client.email,
	} as AppointmentEventData)

	const service = useSelector(
		(state: IState) => state.services.services[eventData.event.serviceID],
	)

	const [sendEmail, setSendEmail] = useState(defaultSendAppointmentEmail)
	const [sendReminder, setSendReminderEmail] = useState(
		defaultSendAppointmentEmail,
	)
	const [sendSMS, setSendSMS] = useState(defaultSendAppointmentSMS)
	const [sendSMSReminder, setSendSMSReminder] = useState(
		defaultSendAppointmentSMS,
	)
	const [phoneNumber, setPhoneNumber] = useState(
		getDefaultPhoneNumber(client.phoneNumbers)?.phoneNumber,
	)

	const [appointmentStep, setAppointmentStep] = useState(
		AppointmentStep.ClientServiceSelect, //
	)
	const [newClientModalOpen, setNewClientModalOpen] = useState(false)
	const [locationIndex, setLocationIndex] = useState(1)
	const [autoShowCalendar, setAutoShowCalendar] = useState(true)
	const [appointmentCalendarIsInline, setAppointmentCalendarIsInline] =
		useState(false)
	// Used for showing the temp event on the calender if you edit the event
	const [newEvent, setNewEvent] = useState({} as IAppointment)
	const [validation, setValidation] = useState({
		clientID: {
			type: 'none',
			msg: '',
		},
	} as Validation)

	const onSetLocationIndex = (index: number) => {
		setLocationIndex(index)
		setEventData((prev) => ({ ...prev, isDirty: true }))
	}

	const appointmentType =
		eventData.event.appointmentType || AppointmentType.Client

	const canSendEmail =
		sendEmail &&
		appointmentReminderFeature &&
		appointmentType === AppointmentType.Client
	const canSendReminderEmail =
		sendReminder &&
		appointmentReminderFeature &&
		appointmentType === AppointmentType.Client
	const canSendSMS =
		sendSMS &&
		appointmentReminderFeature &&
		appointmentType === AppointmentType.Client
	const canSendReminderSMS =
		sendSMSReminder &&
		appointmentReminderFeature &&
		appointmentType === AppointmentType.Client

	const [isForClient, setIsForClient] = useState(
		getIsForClient(eventData.event),
	)
	const { event } = props

	const nextOrSubmitDisabled = false //isForClient ? !eventData.event.clientID : false

	const validateMissingFields = () => {
		if (isForClient) {
			if (!eventData.clientID) {
				setValidation({
					clientID: {
						type: 'error',
						msg: 'Client is Required',
					},
				})
			}
		}
	}

	useEffect(() => {
		const newClientID =
			props.clientID || eventData.clientID || eventData.event.clientID

		if (event) {
			setEventData((prev) => ({ ...prev, event }))
			event.appointmentLocation && setLocationIndex(event.appointmentLocation)

			if (newClientID !== clientID) {
				setClientID(newClientID)
				setClient(clients[newClientID] || {})
			}

			// If we have an ID, this is an existing appointment, we don't need to do the pre-setup
			if (event.id != null) {
				setAppointmentStep(AppointmentStep.Setup)
			}
		}

		if (typeof props.isDirty !== 'undefined') {
			setEventData((prev) => ({
				...prev,
				isDirty: props.isDirty,
				clientID: newClientID,
				email: clients[newClientID]?.email,
			}))
		}
	}, [event, props.isDirty, props.modalOpen])

	useEffect(() => {
		setIsForClient(getIsForClient(eventData.event))
	}, [eventData.event.status, appointmentType])

	// If we have fetched services and they don't exist, we don't want to show the service
	// select step.
	useEffect(() => {
		if (
			!servicesFetchedAndExist &&
			appointmentStep === AppointmentStep.ClientServiceSelect
		) {
			setAppointmentStep(AppointmentStep.Setup)
		}
	}, [servicesFetchedAndExist])

	useEffect(() => {
		if (!service || !eventData.event.serviceID) {
			return
		}

		const serviceTime = parseServiceTimeToMinutes(service.duration)
		const newEnd = moment(eventData.event.start).add(serviceTime, 'minutes')

		setEventData((prev) => ({
			...prev,
			event: {
				...prev.event,
				end: newEnd,
			},
			isDirty: true,
		}))
	}, [service, eventData.event.serviceID])

	const { modalOpen, showCalendarAtStart } = props

	useEffect(() => {
		if (
			modalOpen &&
			autoShowCalendar &&
			appointmentStep === AppointmentStep.Setup
		) {
			if (showCalendarAtStart && !calendarModalOpen) {
				setCalendarModalOpen(true)
				setAutoShowCalendar(false)
			}
		}
	}, [modalOpen, showCalendarAtStart, calendarModalOpen, appointmentStep])

	// TODO - yikes
	const onChange = ({ target }) => {
		if (target.name === 'clientID') {
			setClientID(target.value)
			setClient(clients[target.value] || {})
			setEventData((prev) => ({
				...prev,
				clientID: target.value,
				email: clients[target.value]?.email,
			}))
			setValidation({ clientID: null })

			return
		}

		if (target.name === 'email') {
			setEventData((prev) => ({
				...prev,
				[target.name]: target.value,
				isDirty: true,
			}))

			return
		}

		if (target.name === 'phoneNumber') {
			setPhoneNumber(target.value)
			return
		}

		if (target.name === 'status') {
			if (target.value === AppointmentStatus.Supervision) {
				setEventData((prev) => ({
					...prev,
					event: {
						...prev.event,
						sendEmail: false,
						[target.name]: target.value,
					},
					isDirty: true,
				}))

				setSendEmail(false)
				return
			}
		}

		setEventData((prev) => ({
			...prev,
			event: {
				...prev.event,
				[target.name]: target.value,
			},
			isDirty: true,
		}))
	}

	const onEditAppointmentTime = (event: ICalendarEvent) => {
		setEventData((prev) => {
			return {
				...prev,
				event: {
					...prev.event,
					start: event.start.format ? event.start.format() : event.start,
					end: event.end.format ? event.end.format() : event.end,
				},
				isDirty: true,
			}
		})
		setEditEventModalOpen(false)
	}

	const onEditAppointmentTimeCancel = () => {
		setEditEventModalOpen(false)
	}

	const onDeleteAppointment = () => {
		dispatch(deleteAppointment(eventData.event, clientID))
			.then(() => {
				onCancel()
				setDeleteModalOpen(false)
			})
			.catch(() => {
			})
	}

	const createNewClient = (client: IClient) => {
		onChange({ target: { name: 'clientID', value: client.id } })
	}

	const cancelNewClient = () => {
		setNewClientModalOpen(false)
	}

	const resetData = () => {
		setNewEvent({} as IAppointment)
		setCalendarModalOpen(false)
		setEditAppointmentStatus(false)
		setLocationIndex(1)
		setAppointmentStep(AppointmentStep.ClientServiceSelect)
		setEventData({
			clientID: '',
			event:
				props.event ||
				({
					start: moment(),
					end: moment(),
					appointmentType: AppointmentType.Client,
				} as IAppointment),
			notes: '',
			clinicID: null,
			isDirty: false,
			email: '',
		})
		setSendEmail(defaultSendAppointmentEmail)
		setSendReminderEmail(defaultSendAppointmentEmail)
		setSendSMS(defaultSendAppointmentSMS)
		setSendSMSReminder(defaultSendAppointmentSMS)
		setAutoShowCalendar(true)
		setAppointmentCalendarIsInline(false)
		setClient({})
		setClientID('')
	}

	const onCancel = useCallback(() => {
		if (props.clientID && window.location.href.includes('/calendar/')) {
			navigate('/calendar')
		}

		resetData()
		props.onCancel()
	}, [props, dispatch])

	const getLocationData = () => {
		switch (locationIndex) {
			case AppointmentLocations.Clinic:
				return {
					clinicID: eventData.event.clinicID,
				}
			case AppointmentLocations.Online:
				return {
					meetingLink: eventData.event.meetingLink,
					meetingPassword: eventData.event.meetingPassword,
				}
			case AppointmentLocations.Phone:
				return {
					locationNotes: eventData.event.locationNotes,
				}
			default:
				return {}
		}
	}

	const onSubmit = () => {
		const client = clients[clientID]
		const locationData = getLocationData()
		let apptData = {} as any

		if (!eventData.isDirty) {
			handleEmails(eventData.event)
			handleSMS(eventData.event)
			onCancel()
			return
		}

		if (appointmentType === AppointmentType.Client) {
			apptData = {
				...locationData,
			}
			apptData.clientID = clientID
			apptData.clientNotes = eventData.event.clientNotes

			if (eventData.event.status === AppointmentStatus.Supervision) {
				apptData.title = `${appointmentStatusToText(
					eventData.event.status,
				)} - ${getClientFullName(client)}`
			} else {
				apptData.title = getClientFullName(client)
			}
			apptData.appointmentLocation = locationIndex
		} else {
			apptData.title = appointmentStatusToText(eventData.event.status)
		}

		const appointment: IAppointment = {
			...apptData,
			id: eventData.event.id,
			appointmentType: appointmentType,
			end: eventData.event.end,
			notes: eventData.event.notes,
			serviceID: eventData.event.serviceID,
			start: eventData.event.start,
			status: eventData.event.status || AppointmentStatus.Created,
		}

		if (eventData.event.appointmentNumber) {
			appointment.appointmentNumber = eventData.event.appointmentNumber
		}

		dispatch(saveAppointment(appointment))
			.then((response: IResponse<IAppointment>) => {
				const appointment: IAppointment = response.data
				handleEmails(appointment)
				handleSMS(appointment)
				onCancel()
			})
			.catch(() => {
			})
	}

	const handleEmails = (appointment: IAppointment) => {
		if (canSendEmail && appointmentReminderFeature) {
			// TODO - pass email here!
			dispatch(sendAppointmentEmail(appointment.id, true))
		}
		if (canSendReminderEmail && appointmentReminderFeature) {
			// TODO - pass email here!
			dispatch(setupAppointmentEmailReminder(appointment, true))
		}
	}

	const handleSMS = (appointment: IAppointment) => {
		let phoneNumberToMessage = phoneNumber

		if (!phoneNumberToMessage) {
			phoneNumberToMessage = getDefaultPhoneNumber(
				client.phoneNumbers,
			)?.phoneNumber
		}

		if (canSendSMS) {
			dispatch(sendAppointmentSMS(appointment.id, phoneNumberToMessage, true))
		}
		if (canSendReminderSMS) {
			dispatch(
				setupAppointmentReminderSMS(appointment, phoneNumberToMessage, true),
			)
		}
	}

	const appointmentSetupSetCalendarModalOpen = () => {
		setCalendarModalOpen(true)
		setAppointmentCalendarIsInline(true)
		setNewEvent(eventData.event)
	}

	const onAppointmentCalendarCancel = () => {
		setCalendarModalOpen(false)

		if (!appointmentCalendarIsInline) {
			onCancel()
		}
		setAppointmentCalendarIsInline(false)
	}

	const onAppointmentCalendarSubmit = (appointment: ICalendarEvent) => {
		onEditAppointmentTime(appointment)
		setCalendarModalOpen(false)
		setAppointmentCalendarIsInline(false)
	}

	const onDeleteClick = () => {
		setDeleteModalOpen(true)
	}

	const onDeleteCancel = () => {
		setDeleteModalOpen(false)
	}

	const getNextOrSubmitButtonText = (): string => {
		if (appointmentStep === AppointmentStep.Reminders) {
			if (isEditMode) {
				if (eventData.isDirty) {
					return 'Save'
				} else {
					if (sendEmail || sendReminder || sendSMS || sendSMSReminder) {
						return 'Send'
					}
					return 'Close'
				}
			}
			return 'Create'
		} else {
			if (emailFlagFeature) {
				return 'Next'
			} else {
				return 'Create'
			}
		}
	}

	const getBackOrCloseText = (): string => {
		if (appointmentStep === AppointmentStep.Reminders) {
			return 'Back'
		} else {
			return props.readonly || !eventData.isDirty ? 'Close' : 'Cancel'
		}
	}

	const getButtonIcon = () => {
		if (
			(emailFlagFeature && appointmentStep === AppointmentStep.Setup) ||
			appointmentStep === AppointmentStep.ClientServiceSelect
		) {
			return null
		} else if (
			appointmentReminderFeature &&
			(canSendReminderEmail || canSendEmail || canSendSMS || canSendReminderSMS)
		) {
			return 'send'
		} else {
			return 'checkmark'
		}
	}

	const onNextOrSubmit = () => {
		if (appointmentStep === AppointmentStep.ClientServiceSelect) {
			setAppointmentStep(AppointmentStep.Setup)
			return
		}

		if (!emailFlagFeature || !isForClient) {
			onSubmit()
		} else {
			if (appointmentStep === AppointmentStep.Reminders) {
				onSubmit()
			} else {
				setAppointmentStep(AppointmentStep.Reminders)
			}
		}
	}

	const onBackOrCancel = () => {
		if (appointmentStep === AppointmentStep.Reminders) {
			setAppointmentStep(AppointmentStep.Setup)
		} else {
			onCancel()
		}
	}

	const getHeader = () => {
		if (appointmentStep === AppointmentStep.Reminders) {
			return 'Appointment Reminders'
		} else {
			const clientFullName = getClientFullName(client)
			return appointmentType === AppointmentType.Therapist
				? appointmentStatusToText(eventData.event.status)
				: props.readonly
					? 'Appointment'
					: isEditMode
						? clientFullName
						: clientFullName
							? `New Appointment for ${clientFullName}`
							: 'New Appointment'
		}
	}

	const getModalSize = () => {
		if (appointmentStep === AppointmentStep.Reminders) {
			return 'tiny'
		}

		return 'large'
	}

	const modalSize = getModalSize()
	const modalHeader = getHeader()
	const backOrCloseText = getBackOrCloseText()
	const nextOrSubmitButtonText = getNextOrSubmitButtonText()
	const buttonIcon = getButtonIcon()

	return (
		<>
			<NewAppointmentDayModal
				acceptText='Ok'
				event={editEventModalOpen ? eventData.event : ({} as IAppointment)}
				modalOpen={editEventModalOpen}
				onAccept={onEditAppointmentTime}
				onCancel={onEditAppointmentTimeCancel}
			/>
			<NewAppointmentNotesModal
				client={client}
				defaultDate={eventData.event ? eventData.event.start : null}
				existingEvent={newEvent}
				isEditMode={isEditMode}
				modalOpen={calendarModalOpen}
				onCancel={onAppointmentCalendarCancel}
				onSubmit={onAppointmentCalendarSubmit}
				serviceID={eventData.event.serviceID}
				treatmentNumber={client.treatmentNumber}
			/>
			<ConfirmCancelModal
				message='Delete this appointment?'
				modalOpen={deleteModalOpen}
				onAccept={onDeleteAppointment}
				onCancel={onDeleteCancel}
			/>
			<NewClientModal
				modalOpen={newClientModalOpen}
				onCancel={cancelNewClient}
				onSubmit={createNewClient}
			/>

			<Modal
				closeOnDimmerClick={false}
				onClose={onCancel}
				open={props.modalOpen}
				size={modalSize}
				style={{ margin: 0 }}
			>
				<ModalHeader content={modalHeader} icon='calendar' />

				<Modal.Content
					style={{
						maxHeight:
							appointmentStep === AppointmentStep.Setup ? '80vh' : null,
						overflowY:
							appointmentStep === AppointmentStep.Setup ? 'scroll' : null,
						scrollbarWidth: 'none',
					}}
				>
					{appointmentStep === AppointmentStep.ClientServiceSelect && (
						<AppointmentModalSerivceClientSelect
							clientID={clientID}
							onChange={onChange}
							serviceID={eventData.event.serviceID}
						/>
					)}

					{appointmentStep === AppointmentStep.Setup && (
						<AppointmentSetup
							appointmentType={appointmentType}
							clientID={clientID}
							editAppointmentStatus={editAppointmentStatus}
							eventData={eventData}
							gotoNotes={props.gotoNotes}
							isEditMode={isEditMode}
							isForClient={isForClient}
							locationIndex={locationIndex}
							onChange={onChange}
							onSetLocationIndex={onSetLocationIndex}
							onSubmit={onSubmit}
							readonly={props.readonly}
							saving={saving}
							setCalendarModalOpen={appointmentSetupSetCalendarModalOpen}
							setEditAppointmentStatus={setEditAppointmentStatus}
							setEditEventModalOpen={setEditEventModalOpen}
							setNewClientModalOpen={setNewClientModalOpen}
							validation={validation}
						/>
					)}

					{appointmentStep === AppointmentStep.Reminders && (
						<AppointmentSendReminders
							clientID={clientID}
							email={eventData.email || client.email}
							onChange={onChange}
							phoneNumber={phoneNumber}
							sendEmail={sendEmail}
							sendReminder={sendReminder}
							sendSMS={sendSMS}
							sendSMSReminder={sendSMSReminder}
							setSendEmail={setSendEmail}
							setSendReminder={setSendReminderEmail}
							setSendSMS={setSendSMS}
							setSendSMSReminder={setSendSMSReminder}
						/>
					)}
				</Modal.Content>

				<Modal.Actions>
					<ActionsContainer>
						{!props.readonly && (
							<div>
								{eventData.event.id && (
									<DeleteButton
										loading={deletingAppointment}
										negative
										onClick={onDeleteClick}
									>
										Delete
									</DeleteButton>
								)}
							</div>
						)}
						<MobileButtons>
							<div>
								<CancelButton
									content={backOrCloseText}
									onClick={onBackOrCancel}
									secondary
								/>
							</div>

							{!props.readonly && (
								<div>
									<Button
										content={nextOrSubmitButtonText}
										disabled={nextOrSubmitDisabled}
										icon={buttonIcon}
										loading={saving}
										onClick={onNextOrSubmit}
										onClickDisabled={validateMissingFields}
										primary
										type='submit'
									/>
								</div>
							)}
						</MobileButtons>
					</ActionsContainer>
				</Modal.Actions>
			</Modal>
		</>
	)
}

AppointmentModal.defaultProps = {
	event: {},
}

export { AppointmentModal }
