import React, { useEffect, useState } from 'react'

import {
	Card,
	FormField,
	Label,
	Text,
	RadioButton,
	RadioGroup,
	Form,
} from 'components/ui'
import { FeatureToggle } from 'containers/features'
import { CanSendSMSAppointmentReminders } from 'constants/features'

import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, IEvent, IState, SettingKey } from 'interfaces'
import { saveSettings as saveS } from '..'
import {
	DEFAULT_SEND_APPOINTMENT_SMS,
	SMS_APPOINTMENT_DAY_BEFORE_MESSAGE,
	SMS_APPOINTMENT_MESSAGE,
} from 'constants/settings'
import { getSetting } from '../selectors'
import Setting from './setting'
import { TextArea } from 'components/forms'
import { HelperString } from './helper-strings'

export const PhoneSettings = () => {
	const dispatch: Dispatch = useDispatch()

	const defaultSendAppointmentSMS = useSelector((state: IState) =>
		getSetting(state, DEFAULT_SEND_APPOINTMENT_SMS),
	)
	const smsMessage = useSelector((state: IState) =>
		getSetting(state, SMS_APPOINTMENT_MESSAGE),
	)
	const smsMessageDayBefore = useSelector((state: IState) =>
		getSetting(state, SMS_APPOINTMENT_DAY_BEFORE_MESSAGE),
	)

	const [settingsState, setSettingsState] = useState({
		[SMS_APPOINTMENT_MESSAGE]: smsMessage,
		[SMS_APPOINTMENT_DAY_BEFORE_MESSAGE]: smsMessageDayBefore,
	})

	useEffect(() => {
		setSettingsState((prev) => ({
			...prev,
			[SMS_APPOINTMENT_MESSAGE]: smsMessage,
			[SMS_APPOINTMENT_DAY_BEFORE_MESSAGE]: smsMessageDayBefore,
		}))
	}, [smsMessage, smsMessageDayBefore])

	const onChange = ({ target }: IEvent) => {
		setSettingsState((prev) => ({
			...prev,
			[target.name]: target.value,
		}))
	}

	const onSettingChange = (event: IEvent): Promise<any> => {
		return saveSettings(event.target.name as SettingKey, event.target.value)
	}

	const saveSettings = (setting: SettingKey, value: string) =>
		dispatch(
			saveS({
				setting,
				value,
			}),
		)

	return (
		<FeatureToggle features={[CanSendSMSAppointmentReminders]}>
			<Card hideTitle>
				<FormField>
					<Label>Default Send Appointment SMS</Label>
					<Text>
						When creating appointments, default the initial appointment email
						and the reminder SMS to be sent or not
					</Text>
					<Setting patch={onSettingChange}>
						<RadioGroup
							defaultValue={true}
							name={DEFAULT_SEND_APPOINTMENT_SMS}
							onChange={({ target }) =>
								onSettingChange({
									target: {
										value: target.value,
										name: DEFAULT_SEND_APPOINTMENT_SMS,
									},
								})
							}
							value={defaultSendAppointmentSMS}
						>
							<RadioButton value='false'>No</RadioButton>
							<RadioButton value='true'>Yes</RadioButton>
						</RadioGroup>
					</Setting>
				</FormField>
			</Card>

			<HelperString />

			<Card title='SMS Settings'>
				<Form>
					<FormField>
						<TextArea
							autoSave={{
								autosaveMode: 'onBlurOnly',
								entityID: 'setting',
								patch: (): any =>
									saveSettings(
										SMS_APPOINTMENT_MESSAGE,
										settingsState[SMS_APPOINTMENT_MESSAGE],
									),
							}}
							label='Receipt Title'
							name={SMS_APPOINTMENT_MESSAGE}
							onChange={onChange}
							style={{ marginBottom: '1em' }}
							value={settingsState[SMS_APPOINTMENT_MESSAGE]}
						/>

						<TextArea
							autoSave={{
								autosaveMode: 'onBlurOnly',
								entityID: 'setting',
								patch: (): any =>
									saveSettings(
										SMS_APPOINTMENT_DAY_BEFORE_MESSAGE,
										settingsState[SMS_APPOINTMENT_DAY_BEFORE_MESSAGE],
									),
							}}
							label='Receipt Title'
							name={SMS_APPOINTMENT_DAY_BEFORE_MESSAGE}
							onChange={onChange}
							style={{ marginBottom: '1em' }}
							value={settingsState[SMS_APPOINTMENT_DAY_BEFORE_MESSAGE]}
						/>
					</FormField>
				</Form>
			</Card>
		</FeatureToggle>
	)
}
