export const normalizeArray = (data: any, schema = 'entities') =>
  !data || !data.length
    ? []
    : data.reduce((accumulator: any, current: any) => {
        if (!accumulator[schema]) {
          accumulator[schema] = {}
          accumulator['allIDs'] = []
        }

        accumulator[schema][current.id] = current
        accumulator['allIDs'].push(current.id)
        return accumulator
      }, {})
