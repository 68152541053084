import React from 'react'
import { Accordion as A } from 'semantic-ui-react'

import { useGetColourTheme } from 'lib/get-colour-theme'

const Accordion = (props) => {
  const theme = useGetColourTheme('dark')
  return <A {...props} inverted={theme === 'dark'} />
}

export { Accordion }
export const AccordionTitle = A.Title
export const AccordionContent = A.Content
