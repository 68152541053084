import { authPost } from 'lib/http-request'
import { Receipts } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, Dispatch, ReceiptState, IResponse } from 'interfaces'
import { Receipt } from '../interfaces'

const POST_RECEIPT = 'client-trackyr/receipts/POST_RECEIPT'
const POST_RECEIPT_SUCCESS = 'client-trackyr/receipts/POST_RECEIPT_SUCCESS'
const POST_RECEIPT_FAILURE = 'client-trackyr/receipts/POST_RECEIPT_FAILURE'

export const reducer = (state: ReceiptState, action: IAction) => {
  switch (action.type) {
    case POST_RECEIPT: {
      return {
        ...state,
        flags: {
          ...state.flags,
          posting: true,
        },
      }
    }

    case POST_RECEIPT_SUCCESS: {
      const data: Receipt = action.data
      let allIDs = state.allIDs
      const receipts = state.receipts

      allIDs.push(data.receiptNumber)
      allIDs = [...new Set(allIDs)]
      receipts[data.receiptNumber] = data

      return {
        ...state,
        allIDs,
        receipts,
        totalReceipts: allIDs.length,
        flags: {
          ...state.flags,
          posting: false,
        },
      }
    }

    case POST_RECEIPT_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          posting: false,
        },
      }
    }
    default:
      return state
  }
}

export const postReceipt = (receipt: Receipt): any => async (
  dispatch: Dispatch,
): Promise<IResponse<Receipt>> => {
  dispatch({ type: POST_RECEIPT, receipt })

  if (!receipt.appointmentID) {
    delete receipt.appointmentID
  }

  return authPost(Receipts.Post, receipt)
    .then((response) => {
      message.success('Receipt Saved')
      dispatch(onPostSuccess(response.data))
      return response
    })
    .catch((error) => {
      message.error('Failed to save receipt')
      captureMessage(`postReceipt Failed. ${error}`, Severity.Error)
      dispatch(onPostFailure(error, receipt))
      return error
    })
}

const onPostSuccess = (data: any) => ({
  type: POST_RECEIPT_SUCCESS,
  data,
})
const onPostFailure = (error: any, receipt: Receipt) => ({
  type: POST_RECEIPT_FAILURE,
  error,
  receipt,
})
