import { ClientsViewState, IAction } from 'interfaces'

const clientAction = (action: string) => `client-trackyr/client-view/${action}`
const ON_PAGE_CHANGE = clientAction('ON_PAGE_CHANGE')
const ON_CLIENT_LIST_SEARCH = clientAction('ON_CLIENT_LIST_SEARCH')

export const defaultState: ClientsViewState = {
  page: 1,
  itemsPerPage: 15,
  clientSearch: '',
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case ON_PAGE_CHANGE:
      return {
        ...state,
        page: action.page,
      }

    case ON_CLIENT_LIST_SEARCH:
      return {
        ...state,
        page: 1,
        clientSearch: action.value,
      }

    default:
      return state
  }
}

export const onPageChange = (page: number) => ({
  type: ON_PAGE_CHANGE,
  page,
})

export const onClientSearch = (value: string) => ({
  type: ON_CLIENT_LIST_SEARCH,
  value,
})
