import React from 'react'
import { Checkbox, FormField as FF, Label } from 'components/ui'
import styled from 'styled-components'

import { AutoSaveWrapper } from 'components/forms'
import { InputProps, CheckboxProps } from 'components/forms/interfaces'

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
`

const OptionsSpacer = styled.div`
  margin-right: 1em;
`

const FormField = styled(FF)`
  &&&&& {
    margin: 0;
  }
`

const CheckboxGroupForm = ({
														 autoSave,
														 label,
														 name,
														 onChange,
														 onValidate,
														 options,
														 printParams,
														 readonly,
														 toggle,
														 validation,
														 validationRules,
														 value,
													 }: InputProps & CheckboxProps) => {
	let values = (value && value.split(',')) || []

	return (
		<FormField
			{...validation}
			required={!readonly && validationRules && validationRules.required}
		>
			{label ? <Label>{label}</Label> : null}

			<OptionsContainer>
				{options.map((option) => (
					<OptionsSpacer key={`${option.label}`}>
						<AutoSaveWrapper
							afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
							autosaveDisabled={autoSave.autosaveDisabled}
							autosaveMode='onChange'
							debounceTime={250}
							endpoint={autoSave.patchEndpoint}
							entityID={autoSave.entityID}
							formType={autoSave.patchType}
							patch={autoSave.patch}
						>
							<Checkbox
								checked={values.includes(option.label)}
								label={option.label}
								name={name}
								onBlur={
									onValidate
										? () =>
											onValidate(
												validationRules,
												label,
												name,
												value && value.length,
											)
										: null
								}
								onChange={(e, onChangeCallback) => {
									if (e.target.value) {
										if (!values.includes(option.label)) {
											values.push(option.label)
										}
									} else {
										values = values.filter((v) => v !== option.label)
									}

									const target = {
										target: { name: name, value: values.join(',') },
									}

									onChange(target)
									onValidate &&
									onValidate(
										validationRules,
										label,
										name,
										target.target.value,
									)

									onChangeCallback && onChangeCallback(target)

									return target
								}}
								printParams={printParams}
								readonly={readonly}
								toggle={toggle}
							/>
						</AutoSaveWrapper>
					</OptionsSpacer>
				))}
			</OptionsContainer>
		</FormField>
	)
}

CheckboxGroupForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const CheckboxGroup = React.memo(CheckboxGroupForm)
