import styled from 'styled-components'
import { Font } from 'styles'
import { Text } from 'components/ui'

interface IAvatar {
	asButton?: boolean
	backgroundColour?: string
	size?: string
}

export const Avatar = styled.div<IAvatar>`
  ${Font};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) =>
          props.backgroundColour
                  ? props.backgroundColour
                  : props.theme.AvatarBackgroundColour};
  width: ${(props) => props.size || '32px'};
  height: ${(props) => props.size || '32px'};
  margin-left: 1em;
  margin-right: 1em;

  ${(props) => props.asButton && ':hover { cursor: pointer; }'};
`

export const AvatarText = styled(Text)`
  ${Font};
  font-size: 14px;
  color: ${(props) => props.theme.LowContrastTextColour};
`
