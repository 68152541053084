import { stripValidation } from 'containers/forms'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import moment from 'moment'

import {
  parsePhoneNumbersToAPI,
  parsePhoneNumbersFromAPI,
  invalidateCacheLike,
} from 'lib'
import { authPost } from 'lib/http-request'
import { Client } from 'constants/endpoints'
import { message } from 'components/notifications'
import { ALL_CLIENTS_CACHE } from 'constants/cache'
import { ClientsState, IAction, IClient } from 'interfaces'

const clientAction = (action: string) => `client-trackyr/client-info/${action}`
const ON_SAVE_CLIENT = clientAction('ON_SAVE_CLIENT')
const ON_SAVE_CLIENT_SUCCESS = clientAction('ON_SAVE_CLIENT_SUCCESS')
const ON_SAVE_CLIENT_FAILURE = clientAction('ON_SAVE_CLIENT_FAILURE')

export const defaultState = {} as ClientsState

export const reducer = (
  state: ClientsState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case ON_SAVE_CLIENT_SUCCESS: {
      const { id } = action.client
      const { fields, ...rest } = parsePhoneNumbersFromAPI(action.client)
      const existingClient: IClient = state.clients[id]
      const client = {
        ...rest,
        ...fields,
        isDirty: false,
        fetched: state.clients[id] ? state.clients[id].fetched : false,
      }

      if (existingClient) {
        client.clientTypeID = existingClient.clientTypeID
      }

      const clients = {
        ...state.clients,
        [id]: client,
      }

      if (clients['0']) {
        delete clients['0']
      }

      return {
        ...state,
        clients,
        allIDs: [...new Set([...state.allIDs, id])],
        loading: false,
        changesMade: false,
      }
    }

    case ON_SAVE_CLIENT_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case ON_SAVE_CLIENT:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}

export const onSaveClient = (
  clientRequest: IClient,
  treatmentNum: number,
  clientType: string,
): any => async (dispatch: any): Promise<any> => {
  dispatch({ type: ON_SAVE_CLIENT, clientRequest, treatmentNum, clientType })
  if (!isClientObjectValid(clientRequest, dispatch)) {
    return
  }

  const client = getAPIClientRequestObject(
    clientRequest,
    treatmentNum,
    clientType,
  )

  return authPost(Client.SaveClient, stripValidation(client, 'validation'))
    .then((response) => {
      message.success('Client Saved')
      dispatch(onSaveSuccess(response.data))

      if (!client.id) {
        invalidateCacheLike(ALL_CLIENTS_CACHE)
      }

      return response
    })
    .catch((error) => {
      message.error('Failed to Save Client')

      captureMessage(`Failed to Save Client ${error}`, Severity.Error)

      dispatch(onSaveClientError(error))

      return error
    })
}

const onSaveSuccess = (client: IClient) => ({
  type: ON_SAVE_CLIENT_SUCCESS,
  client,
})

const onSaveClientError = (error: any) => ({
  type: ON_SAVE_CLIENT_FAILURE,
  error,
})

const isClientObjectValid = (client: IClient, dispatch: any) => {
  if (!client.firstName || client.firstName === '') {
    message.error('Client First Name is required', '', 7)
    dispatch(onSaveClientError('Client First Name is required'))

    if (!client.lastName || client.lastName === '') {
      message.error('Client Last Name is required', '', 7)
    }

    return false
  }

  if (!client.lastName || client.lastName === '') {
    if (!client.firstName || client.firstName === '') {
      message.error('Client First Name is required', '', 7)
    }

    message.error('Client Last Name is required', '', 7)
    dispatch(onSaveClientError('Client Last Name is required'))
    return false
  }

  if (client.dateOfBirth && !moment(client.dateOfBirth).isValid()) {
    message.error('Client Date of Birth is invalid', '', 7)
    dispatch(onSaveClientError('Client Date of Birth is invalid'))

    return false
  }

  return true
}

export const getAPIClientRequestObject = (
  clientRequest: IClient,
  treatmentNum: number,
  clientType: any,
) => {
  const {
    dateOfBirth,
    email,
    firstName,
    gender,
    id,
    lastName,
    middleName,
    phoneNumbers,
    newClientTreatmentNotes,
    // We want to exclude useless info from the object we POST
    userID, // eslint-disable-line no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isDirty, // eslint-disable-line no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetched, // eslint-disable-line no-unused-vars
    clientTypeID, // eslint-disable-line no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    treatmentNumber, // eslint-disable-line no-unused-vars
    ...fields
  } = parsePhoneNumbersToAPI(stripValidation(clientRequest, 'validation'))

  const client = {
    dateOfBirth,
    email,
    firstName,
    gender,
    id,
    lastName,
    middleName,
    phoneNumbers,
    treatmentNumber: Number(treatmentNum) || 1,
    clientTypeID: clientType,
    fields,
    newClientTreatmentNotes,
  }

  return client
}
