import styled from 'styled-components'

interface IAppLayout {
  authenticated: boolean
  sidebarCollapsed: boolean
}

export const AppLayoutNoSideBar = styled.div<IAppLayout>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  grid-template-areas:
    'header'
    'content'
    'footer';

  background-color: ${(props) => props.theme.BackgroundColour};
  color: ${(props) => props.theme.TextColour};
`
