import fuzzysearch from 'fuzzysearch'
import moment from 'moment'
import { IFile, IClient, Office } from 'interfaces'
import { FormItemComponent } from 'containers/form-builder'

export const filterOffices = (
  searchTerm: string,
  offices: Array<Office> = [],
): Array<Office> => {
  if (searchTerm === '') {
    return offices
  }

  const filteredOffices = []

  for (const office of offices) {
    if (
      Object.values(office).find((o) =>
        typeof o === 'string'
          ? fuzzysearch(searchTerm.toLowerCase(), o.toLowerCase())
          : false,
      )
    ) {
      filteredOffices.push(office)
    }
  }

  return filteredOffices
}

/**
 * Fuzzy Filter Clients
 * @param  {string} searchTerm
 * @param  {Array<IClient>} clients
 * @return {Array<IClient>}             Filtered List of Clients by SearchTerm
 */
export const filterClients = (
  searchTerm: string,
  clients: Array<IClient>,
  exclusionList?: Array<IClient>,
): Array<IClient> => {
  const hasExclusion = !!exclusionList && exclusionList.length > 0
  const filterIfExclude = () =>
    clients.filter(
      (c: IClient) => !exclusionList.find((e: IClient) => c?.id === e?.id),
    )

  if (!searchTerm) {
    if (hasExclusion) {
      return filterIfExclude()
    }
    return clients
  }

  const terms = searchTerm.split(' ')

  if (terms[0] === '') {
    if (hasExclusion) {
      return filterIfExclude()
    }
    return clients
  }

  const filteredClients = []

  for (const client of clients) {
    for (const t of terms) {
      if (!t.length) {
        continue
      }

      const term = t.toLowerCase()

      if (
        fuzzysearch(term, client?.firstName?.toLowerCase()) ||
        fuzzysearch(term, client?.middleName?.toLowerCase()) ||
        fuzzysearch(term, client?.lastName?.toLowerCase()) ||
        (client?.phoneNumbers?.length > 0 &&
          client.phoneNumbers.filter((ph) => fuzzysearch(term, ph.phoneNumber))
            .length > 0) ||
        fuzzysearch(term, client?.email)
      ) {
        if (hasExclusion) {
          if (exclusionList.find((e: IClient) => e.id === client.id)) {
            break
          }
        }
        filteredClients.push(client)
        break
      }
    }
  }

  return filteredClients
}

export const filterFiles = (
  searchTerm: string,
  files: Array<IFile>,
): Array<IFile> => {
  if (!searchTerm) {
    return files
  }

  const terms = searchTerm.split(' ')

  if (terms[0] === '') {
    return files
  }

  const filteredFiles = []

  for (const file of files) {
    for (const term of terms) {
      if (!term.length) {
        continue
      }

      const lowercaseTerm = term.toLowerCase()

      if (
        (file.fileName &&
          fuzzysearch(lowercaseTerm, file.fileName.toLowerCase())) ||
        fuzzysearch(
          lowercaseTerm,
          moment(file.dateCreated).format('MMMM D YYYY').toLowerCase(),
        ) ||
        fuzzysearch(
          lowercaseTerm,
          moment(file.dateModified).format('MMMM D YYYY').toLowerCase(),
        ) ||
        fuzzysearch(lowercaseTerm, file.contentType.toLowerCase()) ||
        fuzzysearch(lowercaseTerm, file.tags.toLowerCase())
      ) {
        filteredFiles.push(file)
        break
      }
    }
  }

  return filteredFiles
}

export const filterFormComponents = (
  searchTerm: string,
  formComponents: Array<FormItemComponent>,
): Array<FormItemComponent> => {
  if (!searchTerm) {
    return formComponents
  }

  const terms = searchTerm.split(' ')

  if (terms[0] === '') {
    return formComponents
  }

  const filteredComponents = []

  for (const formComponent of formComponents) {
    for (const term of terms) {
      if (!term.length) {
        continue
      }

      const lowercaseTerm = term.toLowerCase()

      if (
        fuzzysearch(lowercaseTerm, formComponent.name.toLowerCase()) ||
        fuzzysearch(lowercaseTerm, formComponent.label.toLowerCase())
      ) {
        filteredComponents.push(formComponent)
        break
      }
    }
  }

  return filteredComponents
}
