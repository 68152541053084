import { reducer as loginRequest } from './login-request'
import { reducer as registerRequest } from './register-request'
import { reducer as forgotPasswordRequest } from './forgot-password-request'
import { IAction } from 'interfaces'

const reducers = [loginRequest, registerRequest, forgotPasswordRequest]

const SET_LOADING = 'client-trackyr/auth/SET_LOADING'
const SET_ERROR = 'client-trackyr/auth/SET_ERROR'
const ON_TAB_CHANGED = 'client-trackcer/auth/ON_TAB_CHANGED'

export const defaultState = {
  loading: false,
  hasError: false,
  errorMsg: '',
  tab: 'login',
  resetSuccess: false,
  resetLoading: false,
  resetError: '',
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.isLoading,
      }

    case SET_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
      }

    case ON_TAB_CHANGED:
      return {
        ...state,
        tab: action.tab,
      }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const setLoading = (isLoading: boolean) => ({
  type: SET_LOADING,
  isLoading,
})

export const setError = (hasError: boolean, errorMsg: string) => ({
  type: SET_ERROR,
  hasError,
  errorMsg,
})

export const onTabChanged = (tab: string) => ({
  type: ON_TAB_CHANGED,
  tab,
})
