import { useEffect, useState } from 'react'

import { Button, ButtonContainer, Card, Text } from 'components/ui'
import { PDF, Receipts } from 'constants/endpoints'
import { postReceipt, Receipt, ReceiptTable } from 'containers/receipts'
import { Dispatch, GetAllResponse, IAppointment, IEvent, IResponse } from 'interfaces'
import { authGet, dataURLtoFile } from 'lib/http-request'
import { Spinner } from 'components/loaders'
import { ReceiptForm } from 'containers/receipts/components/receipt-form'
import { useDispatch } from 'react-redux'
import { ReceiptPDF } from 'containers/receipts/components/receipt-pdf'
import { ReceiptModal } from 'containers/receipts/components/receipt-modal'
import { fetchAppointment } from 'containers/appointments/redux/fetch-appointment'
import SaveCancelButtons from 'components/form-footer-buttons/save-cancel-buttons'

interface Props {
	appointment: IAppointment
	clientID: string
}

export const SoapReceipt = (props: Props) => {
	const dispatch: Dispatch = useDispatch()

	const [fetching, setFetching] = useState(true)
	const [fetchingAndPrinting, setFetchingAndPrinting] = useState(false)
	const [receipt, setReceipt] = useState({
		appointmentID: props.appointment.id,
		clientID: props.clientID,
		serviceID: props.appointment.serviceID,
	} as Receipt)

	const [receiptsDuplicates, setReceiptDuplicates] = useState(null as Receipt[])
	const [pdfViewModal, setPdfViewModal] = useState({
		open: false,
		receipt: null as Receipt,
	})
	const [editReceiptModal, setEditReceiptModal] = useState({
		open: false,
		receipt: null as Receipt,
	})

	const canViewReceipt = !!receipt.receiptNumber

	useEffect(() => {
		setReceipt({
			appointmentID: props.appointment.id,
			clientID: props.clientID,
			serviceID: props.appointment.serviceID,
			taxIDs: 'false,',
		} as Receipt)
		const fetchAppointmentFromReceipt = async () => {
			dispatch(fetchAppointment(props.appointment.id))
		}

		const fetchAppointmentReceipt = async () => {
			const existingReceiptResponse: IResponse<GetAllResponse<Receipt>> =
				await authGet(Receipts.Fetch({ appointmentID: props.appointment.id }))

			setFetching(false)

			const receipts = existingReceiptResponse.data

			if (receipts.totalCount > 1) {
				setReceiptDuplicates(receipts.items)
				return
			}

			setReceiptDuplicates(null)
			setReceipt((prev) =>
				receipts?.items?.[0] ||
				({
					...prev,
					appointmentID: props.appointment.id,
					clientID: props.clientID,
					serviceID: props.appointment.serviceID,
				} as Receipt),
			)
		}

		fetchAppointmentReceipt()
		fetchAppointmentFromReceipt()
	}, [props.appointment.id])

	const onChange = ({ target }: IEvent) => {
		setReceipt((prev: Receipt) => ({
			...prev,
			[target.name]: target.value,
		}))
	}

	const onSaveReceipt = async () => {
		setFetching(true)

		dispatch(postReceipt(receipt))
			.then((response: IResponse<Receipt>) => {
				if (response.status === 200) {
					setReceipt(response.data)
				}
			})
			.finally(() => {
				setFetching(false)
			})
	}

	const onViewReceiptClick = () => onViewReceipt()

	const onViewReceipt = (duplicateReceipt?: Receipt) => {
		setPdfViewModal({ open: true, receipt: duplicateReceipt || receipt })
	}

	const onPdfViewModalClose = () => {
		setPdfViewModal({ open: false, receipt: null })
	}

	const onEditReceipt = (duplicateReceipt: Receipt) => {
		setEditReceiptModal({ open: true, receipt: duplicateReceipt })
	}

	const onEditReceiptModalClose = () => {
		setEditReceiptModal({ open: false, receipt: null })
	}

	const fetchAndPrintReceipt = async () => {
		setFetchingAndPrinting(true)
		try {
			const response: IResponse<any> = await authGet(
				PDF.GetReceipt(receipt.receiptNumber),
			)

			const pdfFile = dataURLtoFile(response.data, 'receipt.pdf')
			const url = URL.createObjectURL(pdfFile)
			window.open(url)
		} catch (ex) {
			console.error('fetchAndPrintReceipt', ex)
		} finally {
			setFetchingAndPrinting(false)
		}
	}

	return (
		<>
			<ReceiptPDF
				onClose={onPdfViewModalClose}
				open={pdfViewModal.open}
				receipt={pdfViewModal.receipt}
			/>

			<Card
				extra={
					canViewReceipt && (
						<ButtonContainer>

							<Button
								content='View'
								onClick={onViewReceiptClick}
							/>

							<Button
								content='Print'
								icon='print'
								loading={fetchingAndPrinting}
								onClick={fetchAndPrintReceipt}
								primary
							/>
						</ButtonContainer>
					)
				}
				icon='print'
				title='Appointment Invoice'
			>
				<Spinner spinning={fetching}>
					{!receiptsDuplicates?.length && (
						<>
							<ReceiptForm
								correspondingAppointment={props.appointment}
								onChange={onChange}
								receipt={receipt}
							/>
							<SaveCancelButtons loading={fetching} noCancel onSave={onSaveReceipt} saveText='Save Receipt' />
						</>
					)}
					{receiptsDuplicates?.length && (
						<>
							<ReceiptModal
								onClose={onEditReceiptModalClose}
								onSuccessCreated={onViewReceipt}
								open={editReceiptModal.open}
								receipt={editReceiptModal.receipt}
							/>

							<Text>This appointment has multiple receipts</Text>
							<ReceiptTable
								onReceiptItemClick={onEditReceipt}
								receipts={receiptsDuplicates}
							/>
						</>
					)}
				</Spinner>
			</Card>
		</>
	)
}
