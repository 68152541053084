import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
	FilesUploadButton,
	fetchClientFiles,
	getFilesForClient,
} from 'containers/files'
import {
	getSelectedClientFullName$,
	getSelectedClientID$,
} from 'containers/clients'
import { Spinner, InlineSpinner } from 'components/loaders'
import {
	DocumentView,
	FlexContainer,
	IconButton,
	List,
	Divider,
	Responsive,
	H3,
	Popup,
	SelectableHeader,
	Card,
} from 'components/ui'
import { filterFiles, invalidateCache, STORAGE_QUOTA_CACHE } from 'lib'
import { getFileDocumentViewDriverSetting } from 'containers/settings'
import { Storage } from 'constants/endpoints'

import { fetchFile } from './redux/fetch-file'
import { fetchPublicFileURL } from './redux/fetch-public-file-url'
import { selectFile, setFileScale, setFileFullscreen } from './redux/ducks'
import { getPublicSharedURLForSelectedFile } from './selectors'
import { FileItem } from './components/file-item'
import { FileDetailsModal } from './components/file-details-modal'
import { IFile, IState, Treatment } from 'interfaces'
import { getContentType } from 'lib/file'
import { IResponse } from 'interfaces/fetch'
import { getSeletectedTreatment$ } from 'containers/treatment'

const FilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FullContainer = styled.div`
  width: 100%;
  height: 100%;
`

class ClientFiles extends React.Component<IClientFiles> {
	state = {
		fetchingPublicURL: false,
		file: null,
		fileDetailsModalOpen: false,
		fileName: '',
		fileSelected: false,
		fileType: '',
		fileUrl: '',
		loadingFile: false,
		searchTerm: '',
		selected: {} as IFile,
	}

	componentDidMount() {
		if (this.props.selectedFile) {
			const file = this.props.clientFiles.find(
				(f) => f.id === this.props.selectedFile,
			)

			if (file) {
				this.fetchAndDisplayFile(file)
			}
		}

		if (this.props.clientID) {
			this.fetchClientFiles()
		}
	}

	componentDidUpdate(prevProps: IClientFiles) {
		if (prevProps.clientID !== this.props.clientID) {
			this.fetchClientFiles()
		}
	}

	fetchAndDisplayFile = (file: IFile) => {
		this.setState(() => ({
			fileUrl: '',
			fileffileType: '',
			loadingFile: true,
			selected: file,
		}))

		this.props.selectFile(file.id)
		const contentType = file.contentType
		this.getPublicURL(file)

		this.props.fetchFile(file.id).then((response: IResponse<IFile>) => {
			const fileResponse = response.data ? response.data : response

			if (!fileResponse) {
				this.setState(() => ({
					fileUrl: '',
					fileType: '',
					loadingFile: false,
					fileName: '',
				}))
				return
			}

			const fileUrl = URL.createObjectURL(fileResponse as any)

			this.setState(() => ({
				fileUrl,
				fileType: contentType,
				file: fileResponse,
				loadingFile: false,
				fileName: file.fileName,
			}))
		})
	}

	downloadFile = (file: IFile) => {
		this.props.fetchFile(file.id).then((response: IResponse<IFile>) => {
			if (!response || !response.data) {
				return
			}

			const fileDownload = response.data
			const fileUrl = URL.createObjectURL(fileDownload as any)

			const link = document.createElement('a')
			link.download = file.fileName
			link.href = fileUrl
			link.click()
		})
	}

	fetchClientFiles = () => {
		this.props.fetchClientFiles(this.props.clientID)
		invalidateCache(STORAGE_QUOTA_CACHE)
	}

	getPublicURL = (file: IFile) => {
		const contentType = getContentType(file)

		if (
			this.props.documentDriver === 'default' ||
			(contentType !== 'file word' && contentType !== 'file excel')
		) {
			return
		}

		this.setState(() => ({ fetchingPublicURL: true }))

		this.props
			.fetchPublicFileURL(file.id)
			.then(() => this.setState(() => ({ fetchingPublicURL: false })))
	}

	onSearchFilter = ({ target }) =>
		this.setState(() => ({ searchTerm: target.value }))

	onSearchClear = () => this.setState(() => ({ searchTerm: '' }))

	uploadButton = () =>
		!this.props.selectedTreatment.closed && (
			<FilesUploadButton
				header={`Upload Files for ${this.props.clientName}`}
				onComplete={this.fetchClientFiles}
				url={Storage.ClientForm(this.props.clientID)}
			/>
		)

	onScaleFile = (scale: number) => {
		const newScale = this.props.fileZoom + scale

		if (newScale < 1) {
			return
		}

		this.props.setFileScale(newScale)
	}

	renderList = (clientFiles: Array<IFile>) => {
		const searchProps = {
			useSearch: true,
			onSearchFilter: this.onSearchFilter,
			onSearchClear: this.onSearchClear,
			searchTerm: this.state.searchTerm,
		}

		return (
			<List
				activeItem={{ id: this.props.selectedFile }}
				emptySelectText='Select File'
				items={clientFiles}
				itemType='Files'
				listHeader='Files'
				listHeaderIcon='file alternate'
				noArrows
				noItemsMessage='No Files'
				renderer={(file: IFile) => (
					<FileItem
						file={file}
						getContentType={getContentType}
						onClick={this.fetchAndDisplayFile}
						selected={this.state.selected}
					/>
				)}
				search={searchProps}
				style={{ overflowY: 'scroll', height: '600px' }}
			/>
		)
	}

	render() {
		const clientFiles = filterFiles(
			this.state.searchTerm,
			this.props.clientFiles,
		)

		return (
			<Card hideTitle>
				<Spinner size='large' spinning={this.props.fetchingClientFiles}>
					<FileDetailsModal
						afterDeleteCallback={this.fetchClientFiles}
						file={this.state.selected}
						filePathUrl={this.state.fileUrl}
						modalOpen={this.state.fileDetailsModalOpen}
						onClose={() =>
							this.setState(() => ({ fileDetailsModalOpen: false }))
						}
						onDownload={() => this.downloadFile(this.state.selected)}
					/>
					{!this.props.fullscreen && (
						<Responsive maxWidth={768}>
							<div>
								{this.uploadButton()}
								{this.renderList(clientFiles)}
							</div>
						</Responsive>
					)}
					<FilesContainer>
						{!this.props.fullscreen && (
							<Responsive minWidth={768}>
								<div style={{ minWidth: '15em', maxWidth: '30em' }}>
									<H3>Files</H3>
									{this.uploadButton()}

									{this.renderList(clientFiles)}
								</div>
							</Responsive>
						)}

						<FullContainer>
							<FlexContainer
								alignItems='center'
								justifyContent='flex-start'
								style={{ width: '100%', padding: '0 1em' }}
							>
								<div>
									{!!this.state.file && (
										<div style={{ display: 'flex' }}>
											<Popup
												content='Expand'
												position='top center'
												trigger={
													<div>
														<IconButton
															name={
																this.props.fullscreen
																	? 'close'
																	: 'expand arrows alternate'
															}
															onClick={() =>
																this.props.setFileFullscreen(
																	!this.props.fullscreen,
																)
															}
															size='large'
															style={{ paddingRight: '1.5em' }}
														/>
													</div>
												}
											/>
											<Popup
												content='Download'
												position='top center'
												trigger={
													<div>
														<IconButton
															name='download'
															onClick={() =>
																this.downloadFile(this.state.selected)
															}
															size='large'
															style={{ paddingRight: '1.5em' }}
														/>
													</div>
												}
											/>
										</div>
									)}
								</div>
								<div>
									<Popup
										content='File Details'
										trigger={
											<div>
												<SelectableHeader
													onClick={() =>
														this.setState(() => ({
															fileDetailsModalOpen: true,
														}))
													}
												>
													{this.state.fileName}
												</SelectableHeader>
											</div>
										}
									/>
								</div>
								{/* <div>
                <IconButton
                  name="minus"
                  onClick={() => this.onScaleFile(-0.25)}
                  size="large"
                />
                <IconButton
                  name="plus"
                  onClick={() => this.onScaleFile(0.25)}
                  size="large"
                />
              </div> */}
							</FlexContainer>

							<div style={{ margin: 'auto', width: '100%', padding: '0 1em' }}>
								<Divider />

								{this.state.loadingFile || this.state.fetchingPublicURL ? (
									<InlineSpinner loading size='large' />
								) : (
									<DocumentView
										documentDriver={this.props.documentDriver}
										documentUrl={this.props.publicSharedURL}
										expanded={this.props.fullscreen}
										file={this.state.file}
										filePathUrl={this.state.fileUrl}
										mimeType={this.state.fileType}
										scale={this.props.fileZoom}
										userID={this.props.userID}
									/>
								)}
							</div>
						</FullContainer>
					</FilesContainer>
				</Spinner>
			</Card>
		)
	}
}

interface IClientFiles {
	clientFiles: Array<any>
	clientID: string
	clientName: string
	documentDriver: string
	fetchClientFiles: Function
	fetchFile: Function
	fetchingClientFiles: boolean
	fetchPublicFileURL: Function
	fileZoom: number
	fullscreen: boolean
	publicSharedURL: string
	selectedFile: string
	selectedTreatment: Treatment
	selectFile: Function
	setFileFullscreen: Function
	setFileScale: Function
	userID: string
}

const mapStateToProps = (state: IState) => ({
	clientID: getSelectedClientID$(state),
	clientFiles: getFilesForClient(state),
	clientName: getSelectedClientFullName$(state),
	documentDriver: getFileDocumentViewDriverSetting(state),
	fetchingClientFiles: state.files.fetchingClientFiles,
	fetchingFile: state.files.fetchingFile,
	fileZoom: state.files.fileZoom,
	fullscreen: state.files.fullscreen,
	publicSharedURL: getPublicSharedURLForSelectedFile(state),
	selectedFile: state.files.selectedFile,
	selectedTreatment: getSeletectedTreatment$(state),
	userID: state.user.ctUser.id,
})

const mapDispatchToProps = (dispatch: any) => ({
	fetchClientFiles: (clientID: string) => dispatch(fetchClientFiles(clientID)),
	fetchFile: (fileID: string) => dispatch(fetchFile(fileID)),
	selectFile: (fileID: string) => dispatch(selectFile(fileID)),
	setFileScale: (zoom: number) => dispatch(setFileScale(zoom)),
	setFileFullscreen: (fullscreen: boolean) =>
		dispatch(setFileFullscreen(fullscreen)),
	fetchPublicFileURL: (blobID: string) => dispatch(fetchPublicFileURL(blobID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientFiles)
