import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	Button,
	ButtonContainer,
	CancelSubmitWithDelete,
	Header,
	Modal,
} from 'components/ui'
import { Receipt } from '../interfaces'
import { Dispatch, IEvent, IResponse, IState } from 'interfaces'
import { deleteReceipt, postReceipt } from '..'
import { formatAmount } from 'lib'
import { ConfirmCancelModal } from 'components/modals'
import { ReceiptForm } from './receipt-form'

interface Props {
	open: boolean
	onClose: () => void
	onSuccessCreated: (receipt: Receipt) => void
	receipt?: Receipt
}

export const ReceiptModal = (props: Props) => {
	const dispatch: Dispatch = useDispatch()

	const [confirmOpen, setConfirmOpen] = useState(false)
	const [isDirty, setIsDirty] = useState(false)
	const [receiptData, setReceiptData] = useState(
		props.receipt ||
		({
			paid: true,
		} as Receipt),
	)

	const posting = useSelector((state: IState) => state.receipts.flags.posting)

	const { receipt } = props

	const onChange = ({ target }: IEvent) => {
		setIsDirty(true)
		setReceiptData((prev) => ({
			...prev,
			[target.name]: target.value,
		}))
	}

	useEffect(() => {
		if (receipt?.receiptNumber) {
			setReceiptData({
				...receipt,
				amount: formatAmount(receipt.amount as number) as any,
			})
		}
	}, [receipt])

	const { onSuccessCreated } = props
	const saveReceipt = useCallback(
		(receiptData: Receipt) => {
			dispatch(postReceipt(receiptData)).then(
				(response: IResponse<Receipt>) => {
					if (response.status === 200) {
						setReceiptData(response.data)
						setIsDirty(false)
					}
				},
			)
		},
		[dispatch],
	)

	const onClose = () => {
		setReceiptData({} as Receipt)
		setIsDirty(false)
		props.onClose()
	}

	const onDeleteReceipt = useCallback(
		(receiptNumber: number) => {
			dispatch(deleteReceipt(receiptNumber)).then(
				(response: IResponse<Receipt>) => {
					if (response.status === 204) {
						onCloseConfirm()
						onClose()
					}
				},
			)
		},
		[dispatch, onClose],
	)

	if (!props.open) {
		return null
	}

	const disabled =
		!receiptData.clientID ||
		!receiptData.amount ||
		(!receiptData.date && !receiptData.appointmentID)

	const onSubmit = () => {
		if (isDirty) {
			saveReceipt(receiptData)
		} else {
			onSuccessCreated({ ...receiptData })
		}
	}

	const onOpenConfirm = () => {
		setConfirmOpen(true)
	}

	const onDelete = () => {
		onDeleteReceipt(receiptData.receiptNumber)
	}

	const onCloseConfirm = () => {
		setConfirmOpen(false)
	}

	const invoiceReceiptType = receiptData.paid ? 'Receipt' : 'Invoice'

	return (
		<>
			<ConfirmCancelModal
				message={`Delete this ${invoiceReceiptType}?`}
				modalOpen={confirmOpen}
				onAccept={onDelete}
				onCancel={onCloseConfirm}
			/>

			<Modal
				closeIcon
				closeOnDimmerClick={false}
				onClose={onClose}
				open={props.open}
			>
				<Header content={invoiceReceiptType} icon='print' />

				<Modal.Content>
					<ReceiptForm onChange={onChange} receipt={receiptData} />
				</Modal.Content>

				<Modal.Actions>
					<ButtonContainer>
						{receiptData?.receiptNumber && receiptData?.receiptNumber !== 0 && (
							<Button
								content='Delete'
								icon='trash'
								negative
								onClick={onOpenConfirm}
							/>
						)}
						<CancelSubmitWithDelete deleteActive={!!receiptData?.receiptNumber}>
							<Button
								content={isDirty ? 'Cancel' : 'Close'}
								icon='arrow alternate circle left'
								onClick={onClose}
								secondary
							/>
							<Button
								content={isDirty ? 'Save' : 'View'}
								disabled={disabled}
								icon='checkmark'
								loading={posting}
								onClick={onSubmit}
								primary
							/>
						</CancelSubmitWithDelete>
					</ButtonContainer>
				</Modal.Actions>
			</Modal>
		</>
	)
}
