import React from 'react'
import { Checkbox as CheckboxInput } from 'semantic-ui-react'
import styled from 'styled-components'

import { Font } from 'styles/font'
import { CheckboxLabel, Icon } from 'components/ui'
import { IEvent } from 'interfaces'
import { Print } from './interfaces'

interface CheckboxProps {
  checked: boolean
  defaultChecked?: boolean
  disabled?: boolean
  label?: string
  name?: string
  onBlur?: Function
  // onChange: Function
  onChange: (e: IEvent, fcn?: Function) => void
  printParams?: Print
  readonly?: boolean
  required?: boolean
  size?: string
  style?: any
  toggle?: boolean
}

const C = styled(CheckboxInput)`
  color: ${(props) => props.theme.TextColour} !important;

  &&&&& {
    ${Font};
    color: ${(props) => props.theme.TextColour} !important;

    label {
      color: ${(props) => props.theme.TextColour} !important;

      ::before {
        ${(props) =>
          props.toggle
            ? `background-color: ${props.theme.NeutralColourOne}`
            : null};
      }

      :hover {
        color: ${(props) => props.theme.TextColour} !important;
      }
    }
  }
`

const ReadonlyDiv = styled.div`
  display: flex;
`

const Checkbox = ({ readonly, ...props }: CheckboxProps) => {
  // TEMP - waiting to fix print on icon/checkbox
  if (props.printParams?.printingView) {
    return (
      <C
        {...props}
        onChange={(_, { checked }) =>
          props.onChange({ target: { name: props.name, value: checked } })
        }
        readonly={false}
        style={{ marginTop: 0, paddingTop: 0 }}
      />
    )
  }

  return (readonly || props.printParams?.printingView) && !props.toggle ? (
    props.checked ? (
      <ReadonlyDiv>
        <Icon name="checkmark" />
        <CheckboxLabel>{props.label}</CheckboxLabel>
      </ReadonlyDiv>
    ) : (
      <ReadonlyDiv>
        <Icon name="square outline" />
        <CheckboxLabel>{props.label}</CheckboxLabel>
      </ReadonlyDiv>
    )
  ) : (
    <C
      {...props}
      onChange={(_, { checked }) =>
        props.onChange({ target: { name: props.name, value: checked } })
      }
    />
  )
}

export default Checkbox
