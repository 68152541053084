import React from 'react'
import { Card } from 'components/ui'
import ImageUpload from '../../../components/image/image-upload-ct'
import { Error, IResponse } from '../../../interfaces'
import { Storage } from '../../../constants/endpoints'

const AvatarUpload = ({ onUpload, avatar }: Props) => (
	<Card title='Profile Picture'>
		<ImageUpload
			hideDetails
			imageSrc={avatar}
			onComplete={(response: IResponse<any>) =>
				onUpload(response.data)
			}
			onError={(err: Error) => console.error(err)}
			url={Storage.Avatar}
		/>
	</Card>
)

interface Props {
	avatar: string
	onUpload: Function
}

export default AvatarUpload
