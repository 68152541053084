import React, { useState } from 'react'
import styled from 'styled-components'

import { FileUploadModal } from 'components/modals'
import { Button as B, Message } from 'components/ui'
import { FeatureToggle } from 'containers/features'
import { FileUpload } from 'constants/features'

const Button = styled(B)`
  &&&&& {
    width: 100%;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
`

const FilesUploadButton = (props: IFilesUploadButton) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  return (
    <FeatureToggle features={[FileUpload]}>
      <Button
        content="Upload"
        icon="cloud upload"
        onClick={() => setModalOpen(true)}
        primary
        size="small"
      />

      <FileUploadModal
        header={props.header}
        loading={loading}
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onComplete={() => {
          setLoading(false)
          setModalOpen(false)
          props.onComplete && props.onComplete()
        }}
        onError={(error: any) => {
          setLoading(false)
          setError(error)
        }}
        onStart={() => setLoading(true)}
        url={props.url}
      />
      {error && <Message content="Failed to Upload File" error />}
    </FeatureToggle>
  )
}

interface IFilesUploadButton {
  header: string
  onComplete?: Function
  url: string
}

export { FilesUploadButton }
