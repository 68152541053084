import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { post } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'

const fetchPublicIntake = async (
  clientID: string,
  link: string,
  p: string,
): Promise<any> => {
  return post(PublicIntake.GetPublic(clientID, link), { passphrase: p })
    .then((response) => {
      return response
    })
    .catch((error) => {
      captureMessage(`fetchPublicIntake Get Failed: ${error}`, Severity.Error)

      throw error
    })
}

export { fetchPublicIntake }
