import React from 'react'
import { Dropdown } from 'components/forms'
import { IEvent, IAppointment } from 'interfaces'
import {
  TherapistOptions,
  AppointmentOptions,
  AppointmentType,
} from 'containers/appointments'
import {
  appointmentStatusToText,
  appointmentStatusToColour,
  AppointmentStatus,
} from 'containers/appointments/enums'
import { Label, SelectableText, LinkText } from 'components/ui'

interface Props {
  readonly: boolean
  onChange: (e: IEvent) => void
  event: IAppointment
  setEditAppointmentStatus: (edit: boolean) => void
}

export const AppointmentStatusDropdown = (props: Props) => {
  return !props.readonly ? (
    <Dropdown
      label="Appointment Type"
      name="status"
      onChange={props.onChange}
      options={
        props.event.appointmentType === AppointmentType.Therapist
          ? TherapistOptions
          : AppointmentOptions
      }
      value={props.event.status || AppointmentStatus.Created}
    />
  ) : (
    <>
      <Label>Status:&nbsp;</Label>
      <div style={{ display: 'flex' }}>
        <SelectableText onClick={() => props.setEditAppointmentStatus(true)}>
          <LinkText fontSize={16}>
            {appointmentStatusToText(props.event.status)}
          </LinkText>
        </SelectableText>
        <div
          style={{
            borderRadius: '50%',
            width: '1em',
            height: '1em',
            backgroundColor: appointmentStatusToColour(props.event.status),
            marginLeft: '1em',
            marginTop: '0.25em',
          }}
        />
      </div>
    </>
  )
}
