import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  InputArea,
  Dropdown as D,
  Icon,
  Segment,
  Text,
  Print,
  DrawIcon,
} from 'components/ui'
import { getSelectedAppointmentID$ } from 'containers/appointments'
import { getSelectedClientID$ } from 'containers/clients'
import { setSoapDirty } from 'containers/soap-notes'
import { debounce } from 'lib/debounce'
import { parseShapesToAPI } from 'lib/fetch-parsers'
import { FETCHING, SUCCESS, FAILURE, NONE } from 'components/forms'
import { ClearModal } from 'components/modals'

import { drawColours } from './components/colour-picker'
import { getShapesForSoap$ } from './selectors'
import {
  onShapesNotesChanged,
  onShapesColourChanged,
  deleteShape,
  setDrawAutoSaving,
} from './ducks'
import { Dispatch, IEvent, IResponse, IState, Shape } from 'interfaces'

const ShapeOrder = styled(Text)`
  font-size: 32;
  font-weight: 600;
`

interface NCProps {
  readonly?: boolean
}

const NoteContainer = styled.div<NCProps>`
  display: flex;
  flex-wrap: wrap;
  ${(props) => (props.readonly ? null : 'align-items: center;')};
  ${(props) =>
    props.readonly ? 'padding-bottom: 0.5em;' : 'padding-bottom: 1em;'};

  flex-basis: 1;
`

const DeleteIcon = styled(Icon)`
  &&&&& {
    :hover {
      cursor: pointer;
    }
  }
`

const Dropdown = styled(D)`
  &&&&& {
    min-width: 10em;
  }
`

const NotesContainer = styled(Segment)`
  &&&&& {
    padding-top: 1em;
    padding-bottom: 1em;

    max-height: 25em;
    overflow-y: scroll;
    min-height: ${(props) => (props.hasItems ? '15em' : '1em')};
  }
`

interface Props {
  appointmentID: string
  clientID: string
  deleteShape: (id: string, appointmentID: string) => any
  onChangeCallback: Function
  onShapesColourChanged: (
    shapeID: string,
    appointmentID: string,
    colour: string,
  ) => any
  onShapesNotesChanged: (
    shapeID: string,
    appointmentID: string,
    note: string,
  ) => any
  printParams?: Print
  readonly: boolean
  setDrawAutoSaving: (
    status: typeof SUCCESS | typeof FAILURE | typeof FETCHING | typeof NONE,
  ) => any
  setSoapDirty: (appointmentID: string, clientID: string) => any
  shapes: Shape[]
  shapesSummaryOverride: Shape[]
}

class DrawNotes extends React.Component<Props> {
  state = {
    deleteModalOpen: false,
    shapeID: '',
  }

  renderDeleteModal = () => {
    const modalProps = {
      modalOpen: this.state.deleteModalOpen,
      message: 'Are you sure you want to delete this shape?',
      onAccept: () => {
        this.props.deleteShape(this.state.shapeID, this.props.appointmentID)
        this.props.setSoapDirty(this.props.appointmentID, this.props.clientID)
        this.props.onChangeCallback && this.debounceOnAfterSave()
        this.setState(() => ({ deleteModalOpen: false }))
      },
      onCancel: () => this.setState(() => ({ deleteModalOpen: false })),
    }

    return <ClearModal {...modalProps} />
  }

  debounceOnAfterSave = debounce(() => {
    this.props.setDrawAutoSaving(FETCHING)

    this.props.onChangeCallback &&
      this.props
        .onChangeCallback({
          target: {
            name: 'shapes',
            value: JSON.stringify(parseShapesToAPI(this.props.shapes)),
          },
        })
        .then((response: IResponse<any>) =>
          this.props.setDrawAutoSaving(
            response.status === 204 ? SUCCESS : FAILURE,
          ),
        )
        .catch(() => this.props.setDrawAutoSaving(FAILURE))
  }, 1500)

  deleteButton = (shape: Shape) =>
    this.props.readonly ? null : (
      <div
        style={{
          marginLeft: '1em',
          marginRight: '1em',
        }}
      >
        <DeleteIcon
          color="red"
          name="trash alternate outline"
          onClick={() =>
            this.setState(() => ({ deleteModalOpen: true, shapeID: shape.id }))
          }
          size="large"
        />
      </div>
    )

  render() {
    const shapes: Shape[] =
      this.props.shapesSummaryOverride && this.props.readonly
        ? this.props.shapesSummaryOverride
        : this.props.shapes

    return (
      <NotesContainer hasItems={this.props.shapes.length > 0}>
        {this.renderDeleteModal()}
        {shapes.map((shape: Shape) => (
          <NoteContainer key={shape.id} readonly={this.props.readonly}>
            <div>
              <ShapeOrder>{shape.order}) </ShapeOrder>
            </div>
            {!this.props.readonly ? (
              <Dropdown
                disabled={this.props.readonly}
                onChange={({ target }) => {
                  this.props.onShapesColourChanged(
                    shape.id,
                    this.props.appointmentID,
                    target.value,
                  )

                  this.props.setSoapDirty(
                    this.props.appointmentID,
                    this.props.clientID,
                  )

                  this.props.onChangeCallback && this.debounceOnAfterSave()
                }}
                options={drawColours.map((option) => ({
                  key: option.colour,
                  id: option.colour,
                  text: option.name,
                  value: option.colour,
                }))}
                printParams={this.props.printParams}
                search
                selection
                style={{
                  height: '2.8em',
                  marginLeft: '1em',
                  marginRight: '1em',
                  maxWidth: '10em',
                }}
                value={shape.colour}
              />
            ) : (
              <DrawIcon
                colour={shape.colour}
                shape={shape.shapeType}
              />
            )}

            {this.deleteButton(shape)}

            <InputArea
              onChange={(e: IEvent) => {
                this.props.onShapesNotesChanged(
                  shape.id,
                  this.props.appointmentID,
                  e.target.value,
                )

                this.props.setSoapDirty(
                  this.props.appointmentID,
                  this.props.clientID,
                )

                this.props.onChangeCallback && this.debounceOnAfterSave()
              }}
              placeholder={`Notes for ${shape.shapeType} #${shape.order}`}
              printParams={this.props.printParams}
              readonly={this.props.readonly}
              style={{ marginLeft: '1.9em', marginTop: '0.5em' }}
              value={shape.notes}
            />
          </NoteContainer>
        ))}
      </NotesContainer>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  appointmentID: getSelectedAppointmentID$(state),
  clientID: getSelectedClientID$(state),
  shapes: getShapesForSoap$(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onShapesNotesChanged: (
    shapeID: string,
    appointmentID: string,
    note: string,
  ) => dispatch(onShapesNotesChanged(shapeID, appointmentID, note)),
  onShapesColourChanged: (
    shapeID: string,
    appointmentID: string,
    colour: string,
  ) => dispatch(onShapesColourChanged(shapeID, appointmentID, colour)),
  setSoapDirty: (appointmentID: string, clientID: string) =>
    dispatch(setSoapDirty(appointmentID, clientID)),
  deleteShape: (id: string, appointmentID: string) =>
    dispatch(deleteShape(id, appointmentID)),
  setDrawAutoSaving: (
    status: typeof SUCCESS | typeof FAILURE | typeof FETCHING | typeof NONE,
  ) => dispatch(setDrawAutoSaving(status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawNotes)
