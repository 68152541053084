import { authDelete } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { IAction } from 'interfaces'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const DELETE_SHARED_FILES = filesAction('DELETE_SHARED_FILES')
const DELETE_SHARED_FILES_SUCCESS = filesAction('DELETE_SHARED_FILES_SUCCESS')
const DELETE_SHARED_FILES_FAILURE = filesAction('DELETE_SHARED_FILES_FAILURE')

export const defaultState = {
  deleting: false,
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case DELETE_SHARED_FILES: {
      return {
        ...state,
        deleting: true,
      }
    }

    case DELETE_SHARED_FILES_SUCCESS: {
      return {
        ...state,
        deleting: false,
      }
    }

    case DELETE_SHARED_FILES_FAILURE:
      return {
        ...state,
        deleting: false,
      }

    default:
      return state
  }
}

export const deleteSharedFiles = () => async (dispatch: any): Promise<any> => {
  dispatch({ type: DELETE_SHARED_FILES })

  return authDelete(Storage.DeleteAllSharedFiles)
    .then((response) => {
      dispatch(deleteSharedFilesSuccess(response.data))

      return response
    })
    .catch((error) => {
      captureMessage(`Failed to Delete Client Files. ${error}`, Severity.Error)
      dispatch(deleteSharedFilesFailure(error))

      return error
    })
}

const deleteSharedFilesSuccess = (files: Array<any>) => ({
  type: DELETE_SHARED_FILES_SUCCESS,
  files,
})

const deleteSharedFilesFailure = (error: any) => ({
  type: DELETE_SHARED_FILES_FAILURE,
  error,
})
