import { createSelector } from 'reselect'
import { IState, Feature } from 'interfaces'

const features = (state: IState) => state.features.features

export const getFeatures = createSelector(
  [features],
  (features) => features || {},
)

export const checkFeatures = (state: IState, features: Array<Feature>) => {
  let valid = true

  for (const feature of features) {
    valid = valid && checkFeature(state, feature)

    if (!valid) {
      break
    }
  }

  return valid
}
export const checkFeature = (state: IState, feature: Feature) =>
  features(state)[feature]
