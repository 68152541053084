import { authGet } from 'lib/http-request'
import { Account } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'

import { parseOfficeHoursFromAPI } from 'lib/fetch-parsers'
import { THIRTY_DAYS, OFFICE_CACHE } from 'constants/cache'
import { IResponse } from 'interfaces/fetch'
import { IAction, Office } from 'interfaces'

const officeAction = (action: string) => `client-trackyr/offices/${action}`
const FETCH_OFFICES = officeAction('FETCH_OFFICES')
const FETCH_OFFICES_SUCCESS = officeAction('FETCH_OFFICES_SUCCESS')
const FETCH_OFFICES_FAILURE = officeAction('FETCH_OFFICES_FAILURE')

export const defaultState = {
  fetchingOffice: false,
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_OFFICES:
      return {
        ...state,
        fetchingOffice: true,
      }

    case FETCH_OFFICES_SUCCESS: {
      const response = action.offices.map((office: any) => {
        return {
          name: office.name,
          id: office.id,
          'office-address-id': office.address.id,
          'office-address': office.address.addressOne,
          'office-addressTwo': office.address.addressTwo,
          'office-city': office.address.city,
          'office-postalcode': office.address.postalCode,
          'office-province': office.address.province,
          days: parseOfficeHoursFromAPI(office),
        }
      })

      const offices = {}
      const officesByID = []

      for (const office of response) {
        offices[office.id] = office
        officesByID.push(office.id)
      }

      return {
        ...state,
        fetchingOffice: false,
        offices,
        officesByID,
      }
    }

    case FETCH_OFFICES_FAILURE:
      return {
        ...state,
        fetchingOffice: false,
      }

    default:
      return state
  }
}

export const fetchOffices = ({ useCache } = { useCache: true }): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_OFFICES, useCache })

  return authGet(Account.GetOffices, {
    cache: useCache,
    cacheMaxAge: THIRTY_DAYS,
    cacheDir: OFFICE_CACHE,
  })
    .then((response: IResponse<Array<Office>>) =>
      dispatch(fetchOfficesSuccess(response.data)),
    )
    .catch((error) => {
      captureMessage(`fetchOffices Failed. ${error}`, Severity.Error)

      dispatch(fetchOfficesFailure(error))
    })
}

export const fetchOfficesSuccess = (offices: Array<Office>) => ({
  type: FETCH_OFFICES_SUCCESS,
  offices,
})

export const fetchOfficesFailure = (error: any) => ({
  type: FETCH_OFFICES_FAILURE,
  error,
})
