import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	INVOICE_TITLE,
	INVOICE_HEADERS,
	INVOICE_TYPE,
	INVOICE_THERAPIST_LABEL,
	INVOICE_FOOTER,
	INVOICE_FOOTER_TWO,
	INVOICE_TABLE_COLOUR,
} from 'constants/settings'
import { Button, Card, Divider, Form, Label, SubText } from 'components/ui'
import { getSetting, saveSettings as saveS } from '../../settings'
import { Dispatch, IEvent, IState, SettingKey } from 'interfaces'
import { Input } from 'components/forms'
import { ReceiptPreviewModal } from '../components/receipt-preview-modal'

const SPLIT_TOKEN = '-$!-'

export const InvoiceSettings = () => {
	const dispatch: Dispatch = useDispatch()

	const invoiceTitle = useSelector((state: IState) =>
		getSetting(state, INVOICE_TITLE),
	)
	const invoiceHeaders = useSelector((state: IState) =>
		getSetting(state, INVOICE_HEADERS),
	)
	const invoiceType = useSelector((state: IState) =>
		getSetting(state, INVOICE_TYPE),
	)
	const invoiceTherapistLabel = useSelector((state: IState) =>
		getSetting(state, INVOICE_THERAPIST_LABEL),
	)
	const invoiceFooter = useSelector((state: IState) =>
		getSetting(state, INVOICE_FOOTER),
	)
	const invoiceFooterTwo = useSelector((state: IState) =>
		getSetting(state, INVOICE_FOOTER_TWO),
	)
	const invoiceColour = useSelector((state: IState) =>
		getSetting(state, INVOICE_TABLE_COLOUR),
	)
	const savingHeaders = useSelector(
		(state: IState) => state.settings.flags[INVOICE_HEADERS],
	)
	const savingSettings = useSelector(
		(state: IState) => state.settings.flags?.saving,
	)

	const tempInvoiceHeadersParse =
		(!!invoiceHeaders && typeof invoiceHeaders !== 'string'
			? JSON.parse(invoiceHeaders)
			: invoiceHeaders) || ''

	const [invoiceState, setInvoiceState] = useState({
		[INVOICE_TITLE]: invoiceTitle,
		[INVOICE_HEADERS]: tempInvoiceHeadersParse,
		[INVOICE_TYPE]: invoiceType,
		[INVOICE_THERAPIST_LABEL]: invoiceTherapistLabel,
		[INVOICE_FOOTER]: invoiceFooter,
		[INVOICE_FOOTER_TWO]: invoiceFooterTwo,
		[INVOICE_TABLE_COLOUR]: invoiceColour,
	})
	const [previewOpen, setPreviewOpen] = useState(false)
	const onPreviewOpen = () => setPreviewOpen(true)
	const onPreviewClose = () => setPreviewOpen(false)

	useEffect(() => {
		const headers = tempInvoiceHeadersParse

		setInvoiceState((prev) => ({
			...prev,
			[INVOICE_TITLE]: invoiceTitle,
			[INVOICE_HEADERS]: headers,
			[INVOICE_TYPE]: invoiceType,
			[INVOICE_THERAPIST_LABEL]: invoiceTherapistLabel,
			[INVOICE_FOOTER]: invoiceFooter,
			[INVOICE_FOOTER_TWO]: invoiceFooterTwo,
			[INVOICE_TABLE_COLOUR]: invoiceColour,
		}))
	}, [
		invoiceTitle,
		invoiceHeaders,
		invoiceType,
		invoiceTherapistLabel,
		invoiceFooter,
		invoiceFooterTwo,
		invoiceColour,
	])

	const invoiceHeadersList =
		typeof invoiceState[INVOICE_HEADERS] === 'string'
			? invoiceState[INVOICE_HEADERS]?.split(SPLIT_TOKEN)
			: invoiceState[INVOICE_HEADERS]

	const onChange = ({ target }: IEvent) => {
		setInvoiceState((prev) => ({
			...prev,
			[target.name]: target.value,
		}))
	}

	const saveSettings = (setting: SettingKey, value: string) =>
		dispatch(
			saveS({
				setting,
				value,
			}),
		)

	const onHeaderChange = ({ target }: IEvent, index: number) => {
		const headers = invoiceState[INVOICE_HEADERS].split(SPLIT_TOKEN)
		headers[index] = target.value
		const newHeaders = headers.join(SPLIT_TOKEN)

		onChange({
			target: {
				name: INVOICE_HEADERS,
				value: newHeaders,
			},
		})
	}

	const onAddHeader = () => {
		const headers: string[] = invoiceState[INVOICE_HEADERS].split(SPLIT_TOKEN)
		headers.push('')
		const newHeaders = headers.join(SPLIT_TOKEN)
		onChange({
			target: {
				name: INVOICE_HEADERS,
				value: newHeaders,
			},
		})

		saveSettings(INVOICE_HEADERS, newHeaders)
	}

	const onRemoveHeader = () => {
		const headers: string[] = invoiceState[INVOICE_HEADERS].split(SPLIT_TOKEN)
		const newHeaders = headers.slice(0, headers.length - 1).join(SPLIT_TOKEN)
		onChange({
			target: {
				name: INVOICE_HEADERS,
				value: newHeaders,
			},
		})
		saveSettings(INVOICE_HEADERS, newHeaders)
	}

	return (
		<>
			<ReceiptPreviewModal
				invoiceColour={invoiceColour}
				invoiceFooter={invoiceFooter}
				invoiceFooterTwo={invoiceFooterTwo}
				invoiceHeaders={invoiceHeaders}
				invoiceTherapistLabel={invoiceTherapistLabel}
				invoiceTitle={invoiceTitle}
				invoiceType={invoiceType}
				onClose={onPreviewClose}
				open={previewOpen}
			/>
			<Card title='Invoice Settings'>
				<Form>
					<Label>Headers</Label>
					<SubText>
						Headers appear at the top of your receipt, in small text.
					</SubText>

					<Input
						autoSave={{
							autosaveMode: 'onBlurOnly',
							entityID: 'setting',
							patch: (): any =>
								saveSettings(INVOICE_TITLE, invoiceState[INVOICE_TITLE]),
						}}
						label='Receipt Title'
						name={INVOICE_TITLE}
						onChange={onChange}
						style={{ marginBottom: '1em' }}
						value={invoiceState[INVOICE_TITLE]}
					/>

					<Input
						autoSave={{
							autosaveMode: 'onBlurOnly',
							entityID: 'setting',
							patch: (): any =>
								saveSettings(INVOICE_TYPE, invoiceState[INVOICE_TYPE]),
						}}
						label='Invoice Type'
						name={INVOICE_TYPE}
						onChange={onChange}
						style={{ marginBottom: '1em' }}
						value={invoiceState[INVOICE_TYPE]}
					/>

					<Input
						autoSave={{
							autosaveMode: 'onBlurOnly',
							entityID: 'setting',
							patch: (): any =>
								saveSettings(
									INVOICE_THERAPIST_LABEL,
									invoiceState[INVOICE_THERAPIST_LABEL],
								),
						}}
						label='Therapist Label'
						name={INVOICE_THERAPIST_LABEL}
						onChange={onChange}
						style={{ marginBottom: '1em' }}
						value={invoiceState[INVOICE_THERAPIST_LABEL]}
					/>

					<Divider />

					<Label>Headers</Label>
					{invoiceHeadersList?.map((header: string, i: number) => (
						<Input
							key={i}
							autoSave={{
								autosaveMode: 'onBlurOnly',
								entityID: 'setting',
								patch: (): any => {
									const headers = invoiceState[INVOICE_HEADERS]
									return saveSettings(INVOICE_HEADERS, headers)
								},
							}}
							disabled={savingHeaders}
							label=''
							name={INVOICE_HEADERS}
							onChange={(e: IEvent) => onHeaderChange(e, i)}
							placeholder='Header Line'
							style={{ marginBottom: '1em' }}
							value={header}
						/>
					))}
					{invoiceState[INVOICE_HEADERS].split(SPLIT_TOKEN).length < 7 && (
						<Button
							content='Add Header'
							disabled={savingHeaders}
							icon='plus'
							onClick={onAddHeader}
							primary
						/>
					)}
					{invoiceState[INVOICE_HEADERS].length > 1 && (
						<Button
							content='Remove Header'
							disabled={savingHeaders}
							icon='minus'
							onClick={onRemoveHeader}
						/>
					)}

					<Divider />

					<Input
						autoSave={{
							autosaveMode: 'onBlurOnly',
							entityID: 'setting',
							patch: (): any =>
								saveSettings(INVOICE_FOOTER, invoiceState[INVOICE_FOOTER]),
						}}
						label='Footer Label'
						name={INVOICE_FOOTER}
						onChange={onChange}
						style={{ marginBottom: '1em' }}
						value={invoiceState[INVOICE_FOOTER]}
					/>

					<Input
						autoSave={{
							autosaveMode: 'onBlurOnly',
							entityID: 'setting',
							patch: (): any =>
								saveSettings(
									INVOICE_FOOTER_TWO,
									invoiceState[INVOICE_FOOTER_TWO],
								),
						}}
						label='Footer Text'
						name={INVOICE_FOOTER_TWO}
						onChange={onChange}
						style={{ marginBottom: '1em' }}
						value={invoiceState[INVOICE_FOOTER_TWO]}
					/>

					<Input
						autoSave={{
							entityID: 'setting',
							patch: (): any =>
								saveSettings(
									INVOICE_TABLE_COLOUR,
									invoiceState[INVOICE_TABLE_COLOUR],
								),
						}}
						label='Table Colour'
						name={INVOICE_TABLE_COLOUR}
						onChange={onChange}
						style={{ marginBottom: '1em', minHeight: '3em', maxWidth: '8.5em' }}
						type='color'
						value={invoiceState[INVOICE_TABLE_COLOUR]}
					/>
				</Form>

				<Button
					content='Preview Invoice/Receipt'
					disabled={savingSettings}
					onClick={onPreviewOpen}
					primary
				/>
			</Card>
		</>
	)
}
