import React from 'react'

import { FormField, Label, TimePicker as TP } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps } from 'components/forms/interfaces'

const TimePickerForm = ({
													autoSave,
													label,
													name,
													onChange,
													onValidate,
													placeholder,
													readonly,
													validation,
													validationRules,
													value,
												}: InputProps) => (
	<FormField
		{...validation}
		required={!readonly && validationRules && validationRules.required}
	>
		<Label>{label}</Label>

		<AutoSaveWrapper
			afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
			autosaveDisabled={autoSave.autosaveDisabled}
			endpoint={autoSave.patchEndpoint}
			entityID={autoSave.entityID}
			formType={autoSave.patchType}
			patch={autoSave.patch}
		>
			<TP
				defaultValue=''
				name={name}
				onBlur={
					onValidate
						? () => onValidate(validationRules, label, name, value)
						: null
				}
				onChange={onChange}
				placeholder={placeholder || label}
				readonly={readonly}
				value={value}
			/>
		</AutoSaveWrapper>
	</FormField>
)

TimePickerForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const TimePicker = React.memo(TimePickerForm)
