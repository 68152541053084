import { reducer as fetchBodyDiagrams } from './fetch-body-diagrams'
import { reducer as saveBodyDiagrams } from './save-body-diagram'
import { reducer as fetchSettings } from './fetch-settings'
import { reducer as saveSettings } from './save-settings'
import { reducer as fetchLSSettings } from './fetch-ls-settings'
import { reducer as saveFormsTypes } from './save-forms-types'
import { reducer as fetchFormsTypes } from './fetch-forms-types'
import { reducer as saveFormType } from './save-form-type'
import {
  reducer as restoreFormType,
  defaultState as restoreFormTypeState,
} from './restore-form-type'
import {
  reducer as deleteFormTypes,
  defaultState as deleteFormTypesState,
} from './delete-form-types'
import {
  reducer as getDefaultFormsType,
  defaultState as getDefaultFormsTypeDefaultState,
} from './get-default-forms-type'
import {
  reducer as getSetting,
  defaultState as fetchSettingDefaultState,
} from './fetch-setting'

import {
  CLIENT_SORT_ORDER,
  PRACTICE_TYPE,
  CLIENT_TYPES,
  CALENDAR_SLOT_DURATION,
  CALENDAR_DEFAULT_APPOINTMENT_DURATION,
  DOCUMENT_VIEW_DRIVER,
  getDefaultSettings,
} from 'constants/settings'
import { IAction, SettingsState } from 'interfaces'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`
const ON_CLIENT_SORT_ORDER_CHANGE = settingsAction(
  'ON_CLIENT_SORT_ORDER_CHANGE',
)
const ON_PRACTICE_CHANGE = settingsAction('ON_PRACTICE_CHANGE')
const ON_ADD_FORM_TYPE = settingsAction('ON_ADD_FORM_TYPE')
const ON_FORM_TYPE_CHANGE = settingsAction('ON_FORM_TYPE_CHANGE')
const ON_REMOVE_FORM = settingsAction('ON_REMOVE_FORM')
const ON_ADD_FORM = settingsAction('ON_ADD_FORM')
const ON_DELETE_FORM_TYPE = settingsAction('ON_DELETE_FORM_TYPE')
const ON_FORM_TYPE_ORDER_CHANGE = settingsAction('ON_FORM_TYPE_ORDER_CHANGE')
const ON_MISSING_FIELD = settingsAction('ON_MISSING_FIELD')
const ON_CANCEL = settingsAction('ON_CANCEL')
const ON_SOAP_TYPES_FOR_CLIENT_CHANGE = settingsAction(
  'ON_SOAP_TYPES_FOR_CLIENT_CHANGE',
)
const ON_SOAP_TYPES_FOR_CLIENT_REMOVE = settingsAction(
  'ON_SOAP_TYPES_FOR_CLIENT_REMOVE',
)
const ON_SOAP_TYPES_FOR_CLIENT_ADD = settingsAction(
  'ON_SOAP_TYPES_FOR_CLIENT_ADD',
)
const ON_CALENDAR_SLOT_DURATION_CHANGE = settingsAction(
  'ON_CALENDAR_SLOT_DURATION_CHANGE',
)
const ON_CALENDAR_DEFAULT_APPOINTMENT_LENGTH_CHANGE = settingsAction(
  'ON_CALENDAR_DEFAULT_APPOINTMENT_LENGTH_CHANGE',
)
const ON_CHANGE_FILE_DRIVER = settingsAction('ON_CHANGE_FILE_DRIVER')
const ON_FORM_TYPE_RESTORE = settingsAction('ON_FORM_TYPE_RESTORE')
const ON_SETTING_CHANGES = settingsAction('ON_SETTING_CHANGES')

const reducers = [
  fetchBodyDiagrams,
  saveBodyDiagrams,
  fetchSettings,
  saveSettings,
  fetchLSSettings,
  saveFormsTypes,
  deleteFormTypes,
  fetchFormsTypes,
  getDefaultFormsType,
  getSetting,
  restoreFormType,
  saveFormType,
]

export const defaultState: SettingsState = {
  ...fetchSettingDefaultState,
  ...deleteFormTypesState,
  ...restoreFormTypeState,
  ...getDefaultFormsTypeDefaultState,
  bodyDiagramLoading: false,
  bodyDiagramError: false,
  isDirty: false,
  formSettingsErrorMessage: '',
  [PRACTICE_TYPE]: '',
  [CLIENT_SORT_ORDER]: getDefaultSettings(CLIENT_SORT_ORDER),
  [CALENDAR_SLOT_DURATION]: getDefaultSettings(CALENDAR_SLOT_DURATION),
  [CALENDAR_DEFAULT_APPOINTMENT_DURATION]: getDefaultSettings(
    CALENDAR_DEFAULT_APPOINTMENT_DURATION,
  ),
  [DOCUMENT_VIEW_DRIVER]: getDefaultSettings(DOCUMENT_VIEW_DRIVER),
  flags: {
    savingSetting: false,
    savingFormType: false,
    restoringForm: true,
    deletingForm: false,
    saving: false,
  },
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case ON_CLIENT_SORT_ORDER_CHANGE:
      return {
        ...state,
        [CLIENT_SORT_ORDER]: action.order,
      }

    case ON_PRACTICE_CHANGE:
      return {
        ...state,
        [PRACTICE_TYPE]: action.value,
      }

    case ON_ADD_FORM_TYPE: {
      const types = state[action.formType] || []

      if (action.defaultOptions) {
        const defaultOption = {
          name: 'Default',
          isDefault: 'true',
          selectedForms: action.defaultOptions.map((o: any, index: number) => ({
            formID: o.id,
            order: index,
          })),
          selectedNotes: [],
        }

        if (action.formType === CLIENT_TYPES) {
          defaultOption.selectedNotes = [
            { formID: 'all', name: 'All Notes', order: 0 },
          ]
        }

        types.unshift(defaultOption)
      } else {
        const newType = {
          selectedForms: [''],
          selectedNotes: [],
        }

        if (action.formType === CLIENT_TYPES) {
          newType.selectedNotes = [
            { formID: 'all', name: 'All Notes', order: 0 },
          ]
        }

        types.allIDs.push(newType)
      }

      return {
        ...state,
        [action.formType]: { ...types },
        isDirty: true,
      }
    }

    case ON_FORM_TYPE_CHANGE: {
      const types = state[action.formType]

      if (typeof action.formsIndex === 'undefined') {
        types[action.index] = {
          ...types[action.index],
          [action.name]: action.value,
        }
      } else {
        const selectedForms = types[action.index].selectedForms

        selectedForms[action.formsIndex] = {
          formID: action.value,
          order: action.formsIndex,
        }
      }

      return {
        ...state,
        [action.formType]: [...types],
        isDirty: true,
      }
    }

    case ON_REMOVE_FORM: {
      const clientType = state[action.formType]
      clientType[action.index].selectedForms = [
        ...clientType[action.index].selectedForms.slice(0, action.formIndex),
        ...clientType[action.index].selectedForms.slice(action.formIndex + 1),
      ]

      return {
        ...state,
        [action.formType]: [...clientType],
        isDirty: true,
      }
    }

    case ON_ADD_FORM: {
      const clientType = state[action.formType]
      clientType[action.index].selectedForms.push('')

      return {
        ...state,
        [action.formType]: [...clientType],
        isDirty: true,
      }
    }

    case ON_DELETE_FORM_TYPE:
      return {
        ...state,
        [action.formType]: [
          ...state[action.formType].slice(0, action.index),
          ...state[action.formType].slice(action.index + 1),
        ],
        isDirty: true,
      }

    case ON_FORM_TYPE_ORDER_CHANGE: {
      const clientType = state[action.formType]
      clientType[action.index].selectedForms = action.items.map(
        (item, index) => ({
          ...item,
          order: index,
        }),
      )

      return {
        ...state,
        [action.formType]: [...clientType],
        isDirty: true,
      }
    }

    case ON_MISSING_FIELD:
      return {
        ...state,
        formSettingsErrorMessage: `${action.name} is Required`,
      }

    case ON_CANCEL:
      return {
        ...state,
        isDirty: false,
      }

    case ON_SOAP_TYPES_FOR_CLIENT_CHANGE: {
      const clientType = state[CLIENT_TYPES]
      const selectedNotes = clientType[action.index].selectedNotes
      selectedNotes[action.formsIndex] = {
        formID: action.value,
        order: action.formsIndex,
      }

      return {
        ...state,
        [CLIENT_TYPES]: [...clientType],
        isDirty: true,
      }
    }

    case ON_SOAP_TYPES_FOR_CLIENT_REMOVE: {
      const clientType = state[CLIENT_TYPES]
      clientType[action.index].selectedNotes = [
        ...clientType[action.index].selectedNotes.slice(0, action.formsIndex),
        ...clientType[action.index].selectedNotes.slice(action.formsIndex + 1),
      ]

      return {
        ...state,
        [CLIENT_TYPES]: [...clientType],
        isDirty: true,
      }
    }
    case ON_SOAP_TYPES_FOR_CLIENT_ADD: {
      const clientType = state[CLIENT_TYPES]
      const selectedNotes = clientType[action.index].selectedNotes
      selectedNotes.push('')

      return {
        ...state,
        [CLIENT_TYPES]: [...clientType],
        isDirty: true,
      }
    }

    case ON_CALENDAR_SLOT_DURATION_CHANGE:
      return {
        ...state,
        [CALENDAR_SLOT_DURATION]: action.slotDuration,
      }

    case ON_CALENDAR_DEFAULT_APPOINTMENT_LENGTH_CHANGE:
      return {
        ...state,
        [CALENDAR_DEFAULT_APPOINTMENT_DURATION]: action.appointmentLength,
      }

    case ON_CHANGE_FILE_DRIVER:
      return {
        ...state,
        [DOCUMENT_VIEW_DRIVER]: action.driver,
      }

    case ON_FORM_TYPE_RESTORE: {
      const types = state[action.formType] || []

      const toRestore = types.find((t) => t.id === action.id)
      toRestore.isDeleted = false

      return {
        ...state,
        [action.formType]: [
          ...types.sort(
            (a, b) => Number(!!a.isDeleted) - Number(!!b.isDeleted),
          ),
        ],
      }
    }

    case ON_SETTING_CHANGES:
      return {
        ...state,
        [action.settingsKey]: action.value,
      }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const onClientSortOrderChange = (order) => ({
  type: ON_CLIENT_SORT_ORDER_CHANGE,
  order,
})

export const onAddFormType = (defaultOptions, formType) => ({
  type: ON_ADD_FORM_TYPE,
  defaultOptions,
  formType,
})

export const onFormTypeChange = (name, value, index, formsIndex, formType) => ({
  type: ON_FORM_TYPE_CHANGE,
  name,
  value,
  index,
  formsIndex,
  formType,
})

export const onRemoveForm = (index, formIndex, formType) => ({
  type: ON_REMOVE_FORM,
  index,
  formIndex,
  formType,
})

export const onAddForm = (index, formType) => ({
  type: ON_ADD_FORM,
  index,
  formType,
})

export const onDeleteFormType = (index, formType) => ({
  type: ON_DELETE_FORM_TYPE,
  index,
  formType,
})

export const onFormTypeOrderChange = (items, index, formType) => ({
  type: ON_FORM_TYPE_ORDER_CHANGE,
  items,
  index,
  formType,
})

export const missingField = (name) => ({
  type: ON_MISSING_FIELD,
  name,
})

export const onCancel = () => ({
  type: ON_CANCEL,
})

export const onSoapTypesForClientChange = (value, index, formsIndex) => ({
  type: ON_SOAP_TYPES_FOR_CLIENT_CHANGE,
  value,
  index,
  formsIndex,
})

export const onSoapTypesForClientRemove = (index, formsIndex) => ({
  type: ON_SOAP_TYPES_FOR_CLIENT_REMOVE,
  index,
  formsIndex,
})

export const onSoapTypesForClientAdd = (index: number) => ({
  type: ON_SOAP_TYPES_FOR_CLIENT_ADD,
  index,
})

export const onCalendarSlotDurationChange = (slotDuration) => ({
  type: ON_CALENDAR_SLOT_DURATION_CHANGE,
  slotDuration,
})

export const onCalendarDefaultAppointmentLengthChange = (
  appointmentLength: number,
) => ({
  type: ON_CALENDAR_DEFAULT_APPOINTMENT_LENGTH_CHANGE,
  appointmentLength,
})

export const changeFileDriver = (driver: string) => ({
  type: ON_CHANGE_FILE_DRIVER,
  driver,
})

export const onRestoreFormType = (id: string, formType: string) => ({
  type: ON_FORM_TYPE_RESTORE,
  id,
  formType,
})

/**
 * Generic action to change settings
 * @param {string} value
 * @param {string} setting
 */
export const onSettingChanged = (value: string, settingsKey: string) => ({
  type: ON_SETTING_CHANGES,
  value,
  settingsKey,
})
