import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Form, Label } from 'components/ui'
import { Input } from 'components/forms'
import { IEvent } from 'interfaces'

import { FormTypesList } from './form-types-list'
import { getSoapFormsForDropdown } from 'containers/forms'
import { Form_T } from './new-interfaces'

const NameFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

// TODO - RENAME
const ContLeft = styled.div`
  width: 30%;
  padding-right: 1em;

  @media (max-width: 760px) {
    width: 100%;
    padding-right: 0;
  }
`
const Cont = styled.div`
  width: 70%;
  padding-left: 1em;

  @media (max-width: 760px) {
    padding-left: 0;
    width: 100%;
  }
`

interface Props {
  formData: any // Form_T // TODO - fix
  disabled?: boolean
  formTypeName: string
  setFormData: Function
}

export const FormTypeNotes = (props: Props) => {
  const [data, setData] = useState({} as Form_T)
  const noteTypes = useSelector(getSoapFormsForDropdown)

  const { formData } = props
  useEffect(() => {
    setData({
      ...formData,
    })
  }, [formData])

  const onChange = ({ target }: IEvent) => {
    const newData = {
      ...data,
      [target.name]: target.value,
    }
    setData((prevData) => ({
      ...prevData,
      ...newData,
    }))
    props.setFormData(newData)
  }

  const onFormTypeChange = ({ target }: IEvent, index: number) => {
    const selectedForms = data['forms']?.length
      ? data['forms']
      : [{ order: 0, formID: '' }]
    selectedForms[index].formID = target.value

    const newData = {
      ...data,
      forms: selectedForms,
    }

    setData((prevData) => ({
      ...prevData,
      ...newData,
    }))
    props.setFormData(newData)
  }

  const onFormTypesSort = (items: any) => {
    const newData = {
      ...data,
      forms: items.map((i: any, index: number) => ({ ...i, order: index })),
    }
    setData((prevData) => ({
      ...prevData,
      ...newData,
    }))
    props.setFormData(newData)
  }

  const addForm = () => {
    const prev = data['forms'] || []
    prev.push({ order: prev.length, formID: '' })
    const newData = {
      ...data,
      forms: prev,
    }
    setData((prevData) => ({
      ...prevData,
      ...newData,
    }))
    props.setFormData(newData)
  }

  const removeForm = (name: string, index: number) => {
    const prev = data['forms'] || []
    prev.splice(index, 1)
    const newData = {
      ...data,
      forms: prev,
    }
    setData((prevData) => ({
      ...prevData,
      ...newData,
    }))
    props.setFormData(newData)
  }

  return (
    <div>
      <NameFormContainer>
        <ContLeft>
          <Form>
            <Input
              disabled={props.disabled || data.isDefault}
              label="Note Type"
              name="name"
              onChange={onChange}
              placeholder={`${props.formTypeName} Type Name`}
              validationRules={{ required: true }}
              value={data.name}
            />
          </Form>
        </ContLeft>

        <Cont>
          <Label>Forms</Label>
          <FormTypesList
            addForm={addForm}
            disabled={props.disabled}
            isDefault={data.isDefault}
            name="selectedForms"
            onFormTypeChange={onFormTypeChange}
            onFormTypesSort={onFormTypesSort}
            options={noteTypes}
            removeForm={removeForm}
            selectedForms={data.forms}
            sortable
          />
        </Cont>
      </NameFormContainer>
    </div>
  )
}
