// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript

export const generateGUID =
  typeof window.crypto !== 'undefined' &&
  typeof window.crypto.getRandomValues !== 'undefined'
    ? function () {
        // If we have a cryptographically secure PRNG, use that
        // https://stackoverflow.com/questions/6906916/collisions-when-generating-uuids-in-javascript
        var buf = new Uint16Array(8)
        window.crypto.getRandomValues(buf)

        const S4 = function (num) {
          var ret = num.toString(16)
          while (ret.length < 4) {
            ret = '0' + ret
          }
          return ret
        }
        return (
          S4(buf[0]) +
          S4(buf[1]) +
          '-' +
          S4(buf[2]) +
          '-' +
          S4(buf[3]) +
          '-' +
          S4(buf[4]) +
          '-' +
          S4(buf[5]) +
          S4(buf[6]) +
          S4(buf[7])
        )
      }
    : function () {
        // Otherwise, just use Math.random
        // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/2117523#2117523
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0,
              v = c === 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
          },
        )
      }

export const isValidGuid = (guid) =>
  guid &&
  guid !== '00000000-0000-0000-0000-000000000000' &&
  /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/.test(guid)

export const tryParseGuid = (guid) => {
  if (isValidGuid(guid)) {
    return guid
  }

  return undefined
}

export const emptyGuid = '00000000-0000-0000-0000-000000000000'

export class Guid {
  constructor(public guid: string) {
    this._guid = guid
  }

  private _guid: string

  public ToString(): string {
    return this.guid
  }

  // Static member
  static MakeNew(): Guid {
    var result: string
    var i: string
    var j: number

    result = ''
    for (j = 0; j < 32; j++) {
      if (j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + '-'
      }
      i = Math.floor(Math.random() * 16)
        .toString(16)
        .toLowerCase()
      result = result + i
    }

    return new Guid(result)
  }
}
