import React from 'react'
import { Label, Text } from 'components/ui'

interface Props {
  label: string
  value?: any
}

export const LabelText = ({ label, value }: Props) => {
  if (!value) {
    return null
  }

  return (
    <>
      <Label>{label}</Label>
      <Text>{value}</Text>
    </>
  )
}
