import React from 'react'
import { connect } from 'react-redux'
import { useNavigate, NavigateFunction, useParams } from 'react-router-dom'

import { Tab, Container } from 'components/ui'
import Login from './components/login'
import Register from './components/register'
import * as Ducks from '.'
import { Dispatch } from 'interfaces'

const panes = [
	{
		key: 'login',
		menuItem: { content: 'Login' },
		render: () => (
			<Container>
				<Login />
			</Container>
		),
	},
	{
		key: 'register',
		menuItem: { content: 'Register' },
		render: () => (
			<Container>
				<Register />
			</Container>
		),
	},
]

const TABS = ['login', 'register']

interface Props {
	params?: Params
	navigate?: NavigateFunction
	onTabChanged: (tab: string) => void
	setError: (hasError: boolean, errorMsg?: string) => void
}

interface Params {
	tab?: string
}

class AuthPage extends React.Component<Props> {
	componentDidMount() {
		const tab = this.props.params.tab || 'login'
		this.props.onTabChanged(tab)
	}

	onTabChanged = (tabIndex: number) => {
		const tab = TABS[tabIndex]

		this.props.navigate(`/auth/${tab}`)
		this.props.setError(false)
		this.props.onTabChanged(tab)
	}

	render() {
		const activeTab = TABS.findIndex(
			(tab) => tab === this.props.params.tab,
		)

		return (
			<Tab
				activeIndex={activeTab === -1 ? 0 : activeTab}
				menu={{ secondary: true, pointing: true }}
				onTabChange={(_: any, { activeIndex }) =>
					this.onTabChanged(activeIndex)
				}
				panes={panes}
				responsive={false}
				size='large'
			/>
		)
	}
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onTabChanged: (tab: string) => dispatch(Ducks.onTabChanged(tab)),
	setError: (hasError: boolean, errorMsg = '') =>
		dispatch(Ducks.setError(hasError, errorMsg)),
})

const AuthPageWrapper = (props: Props) => {
	const navigate = useNavigate()
	const params: Params = useParams()
	return <AuthPage {...props} navigate={navigate} params={params} />
}

export default connect(null, mapDispatchToProps)(AuthPageWrapper)
