import { IAppointment } from 'interfaces'
import { AppointmentStatus, AppointmentType } from '..'

export const getIsForClient = (appoinment: IAppointment) => {
  const appointmentType = appoinment.appointmentType || AppointmentType.Client
  return (
    appoinment.status !== AppointmentStatus.Supervision &&
    appoinment.status !== AppointmentStatus.NoShow &&
    appoinment.status !== AppointmentStatus.Cancelled &&
    appoinment.status !== AppointmentStatus.CancelledTooLate &&
    appointmentType === AppointmentType.Client
  )
}
