import React from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import { getActiveClientTypes } from 'containers/settings'
import { Input, DatePicker, PhoneNumber, TextArea } from 'components/forms'
import {
	Button,
	Form,
	ErrorText,
	FormField,
	Message,
	Dropdown,
	Modal,
	Header,
} from 'components/ui'
import { onSaveClient } from 'containers/clients/client-info/redux/save-client'
import { IState, IClient } from 'interfaces'
import { IResponse } from 'interfaces/fetch'
import { FAMILY_SELECTED_TYPE, INDIVIDUAL_SELECTED_TYPE } from 'constants/form'

const validationSchema = Yup.object().shape({
	// clientType: Yup.string().required('Client Type is Required'),
	firstName: Yup.string().required('First Name is Required'),
	lastName: Yup.string().required('Last Name is Required'),
	dateOfBirth: Yup.date().nullable().notRequired(),
	// phoneNumbers: Yup.array()
	//   .required('At least one Phone Number is Required')
	//   .min(1, 'At least one Phone Number is Required'),
	// .of(
	//   Yup.object().shape({
	//     type: Yup.string(),
	//     phoneNumber: Yup.string().required(),
	//   }),
	// ),
	email: Yup.string().email('Invalid Email Address'),
})

const familyValidationSchema = Yup.object().shape({
	firstName: Yup.string().required('Family Name is Required'),
	email: Yup.string().email('Invalid Email Address'),
})

interface NewClientModalProps {
	clientTypes: Array<any>
	modalOpen: boolean
	onCancel: Function
	onSaveClient: Function
	onSubmit: Function
}

class NewClientModal extends React.Component<NewClientModalProps> {
	state = {
		formData: {},
		indiv: true,
		dateOfBirthError: '',
	}

	onSubmit = (values, setSubmitting, resetForm) => {
		let { clientType, ...client } = values

		const ct = this.props.clientTypes.find((ct) => ct.id === clientType)

		if (ct && ct.selectedType === 'family') {
			client.firstName = client.firstName.replace(/Family/, '').trim()
			client.firstName = client.firstName.replace(/family/, '').trim()

			client.lastName = 'Family'
		}

		if (this.props.clientTypes.length > 1 && !clientType) {
			setSubmitting(false)
		}

		if (this.props.clientTypes.length === 1 && !clientType) {
			clientType = this.props.clientTypes[0].id
		}

		if (clientType.key) {
			clientType = clientType.key
		}

		if (client.dateOfBirth && !moment(client.dateOfBirth).isValid()) {
			this.setState(() => ({ dateOfBirthError: 'Date is Invalid' }))
			setSubmitting(false)
			return
		}

		this.props
			.onSaveClient(client, 1, clientType)
			.then((response: IResponse<IClient>) => {
				if (response.status === 200) {
					this.props.onCancel()
					resetForm()
					this.props.onSubmit(response.data)
				}

				setSubmitting(false)
				// if (response.status !== 200) {
				//   // TODO - set failure message here
				// }
			})
			.catch(() => setSubmitting(false))
	}

	individualClient = (values, handleChange, handleBlur, errors, touched) => (
		<>
			<Header>Client Info</Header>

			<Input
				label='First Name'
				name='firstName'
				onBlur={handleBlur}
				onChange={handleChange}
				placeholder='First Name'
				validationRules={{ required: true }}
				value={values.firstName}
			/>

			<ErrorText>
				{errors.firstName && touched.firstName && errors.firstName}
			</ErrorText>

			<Input
				label='Middle Name'
				name='middleName'
				onBlur={handleBlur}
				onChange={handleChange}
				placeholder='Middle Name'
				value={values.middleName}
			/>

			<Input
				label='Last Name'
				name='lastName'
				onBlur={handleBlur}
				onChange={handleChange}
				placeholder='Last Name'
				validationRules={{ required: true }}
				value={values.lastName}
			/>

			<ErrorText>
				{errors.lastName && touched.lastName && errors.lastName}
			</ErrorText>

			<DatePicker
				label='Date of Birth'
				name='dateOfBirth'
				onBlurOverride={handleBlur}
				onChange={handleChange}
				placeholder='Date of Birth (Month/Day/Year)'
				value={values.dateOfBirth}
			/>

			<ErrorText>
				{this.state.dateOfBirthError && this.state.dateOfBirthError}
			</ErrorText>
		</>
	)

	familyClient = (values, handleChange, handleBlur, errors, touched) => (
		<>
			<Header>Family Info</Header>

			<Input
				label='Family Name'
				name='firstName'
				onBlur={handleBlur}
				onChange={handleChange}
				placeholder='Family Name'
				validationRules={{ required: true }}
				value={values.firstName}
			/>

			<ErrorText>
				{errors.firstName && touched.firstName && errors.firstName}
			</ErrorText>
		</>
	)

	render() {
		if (!this.props.clientTypes.length) {
			return (
				<Modal onClose={this.props.onCancel} open={this.props.modalOpen}>
					<Header content='New Client' />
					<Modal.Content>
						<Message
							content='You have no Forms for creating Clients, either create a new form or restore a deleted form'
							icon='warning'
							warning
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={() => this.props.onCancel()} secondary>
							Cancel
						</Button>
					</Modal.Actions>
				</Modal>
			)
		}

		return (
			<Formik
				initialValues={{
					clientType: '',
					firstName: '',
					middleName: '',
					lastName: '',
					dateOfBirth: null,
					email: '',
					phoneNumbers: [],
					newClientTreatmentNotes: '',
				}}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					this.onSubmit(values, setSubmitting, resetForm)
				}}
				validationSchema={
					this.state.indiv ? validationSchema : familyValidationSchema
				}
			>
				{({
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						values,
						resetForm,
					}) => {
					const showForm =
						this.props.clientTypes.length > 1 ? !!values.clientType : true
					const selectedClientType = this.props.clientTypes.find(
						(ct) => ct.id === values.clientType,
					)
					const selectedType =
						(selectedClientType && selectedClientType.selectedType) ||
						INDIVIDUAL_SELECTED_TYPE

					if (selectedClientType) {
						if (
							selectedClientType.selectedType !== INDIVIDUAL_SELECTED_TYPE &&
							this.state.indiv
						) {
							this.setState(() => ({ indiv: false }))
						} else if (
							selectedClientType.selectedType === INDIVIDUAL_SELECTED_TYPE &&
							!this.state.indiv
						) {
							this.setState(() => ({ indiv: true }))
						}
					}

					const isValidForm =
						selectedType === INDIVIDUAL_SELECTED_TYPE
							? validationSchema.isValidSync(values)
							: familyValidationSchema.isValidSync(values)

					return (
						<Modal
							closeOnDimmerClick={false}
							onClose={this.props.onCancel}
							open={this.props.modalOpen}
						>
							<Header content='New Client' />
							<Modal.Content>
								<Form>
									{this.props.clientTypes.length > 1 && (
										<>
											<Header>Client Type</Header>

											<FormField required>
												<Dropdown
													autoFocus
													name='clientType'
													onBlur={handleBlur}
													onChange={({ target }) =>
														handleChange({
															target: {
																name: 'clientType',
																value: target.value,
															},
														})
													}
													options={this.props.clientTypes.map((ct) => ({
														text: ct.name,
														key: ct.id,
														value: ct.id,
													}))}
													selection
													value={values.clientType}
												/>

												<ErrorText>
													{errors.clientType &&
														touched.clientType &&
														errors.clientType}
												</ErrorText>
											</FormField>
										</>
									)}

									{showForm && (
										<>
											{selectedType === INDIVIDUAL_SELECTED_TYPE &&
												this.individualClient(
													values,
													handleChange,
													handleBlur,
													errors,
													touched,
												)}

											{selectedType === FAMILY_SELECTED_TYPE &&
												this.familyClient(
													values,
													handleChange,
													handleBlur,
													errors,
													touched,
												)}

											<Header>Contact Info</Header>

											<PhoneNumber
												label='Phone Number'
												name='phoneNumbers'
												onBlur={handleBlur}
												onChange={handleChange}
												placeholder='Phone Number'
												value={values.phoneNumbers}
											/>

											<ErrorText>
												{(errors.phoneNumbers &&
													touched.phoneNumbers &&
													errors.phoneNumbers) as any}
											</ErrorText>

											<Input
												icon='mail'
												label='Email Address'
												name='email'
												onBlur={handleBlur}
												onChange={handleChange}
												placeholder='Email address'
												value={values.email}
											/>

											<ErrorText>
												{errors.email && touched.email && errors.email}
											</ErrorText>

											<TextArea
												label='New Case Notes'
												name='newClientTreatmentNotes'
												onBlur={handleBlur}
												onChange={handleChange}
												placeholder='New Case Notes'
												value={values.newClientTreatmentNotes}
											/>
										</>
									)}
								</Form>
							</Modal.Content>
							<Modal.Actions>
								<Button
									onClick={() => {
										resetForm()
										this.props.onCancel()
									}}
									secondary
								>
									Cancel
								</Button>
								<Button
									disabled={!isValidForm}
									loading={isSubmitting}
									onClick={handleSubmit}
									onClickDisabled={handleSubmit}
									primary
									type='submit'
								>
									Create
								</Button>
							</Modal.Actions>
						</Modal>
					)
				}}
			</Formik>
		)
	}
}

const mapStateToProps = (state: IState) => ({
	clientTypes: getActiveClientTypes(state),
})

const mapDispatchToProps = (dispatch: any) => ({
	onSaveClient: (
		client: IClient,
		treatmentNumber: number,
		clientTypeID: string,
	) => dispatch(onSaveClient(client, treatmentNumber, clientTypeID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewClientModal)
