import React, { useState } from 'react'
import moment from 'moment/moment'
import styled from 'styled-components'

import { Button, ButtonContainer, ContainerLayout, Form, H1, Message } from 'components/ui'
import { DatePicker } from 'components/forms'
import { IEvent, InvoiceReport, InvoiceReportItem, IQueryParams } from 'interfaces'
import { MobileBreakPointTiny } from 'styles'
import { queryAudits } from './invoiceQuery'
import { InvoiceReportsDataView } from './invoice-reports-data-view'
import { message } from '../../../components/notifications'
import { exportTaxesToCsv } from '../../csv'

const PaddedContainerItem = styled.div`
  padding-left: 1em;

  @media (max-width: ${MobileBreakPointTiny}) {
    padding-left: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const InvoiceReports = () => {
  const [fetched, setFetched] = useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<Date | moment.Moment>()
  const [dateTo, setDateTo] = useState<Date | moment.Moment>()
  const [details, setDetails] = useState<InvoiceReport>({} as InvoiceReport)
  const [items, setItems] = useState<InvoiceReportItem[]>([])

  const onDateFromChange = ({ target }: IEvent) => {
    const from = target.value === null ? null : target.value
    setDateFrom(from)
  }
  const onDateToChange = ({ target }: IEvent) => {
    const to = target.value === null ? null : target.value
    setDateTo(to)
  }

  const onGenerateInvoiceReport = async () => {
    try {
      setFetching(true)
      const queryParams: IQueryParams = {
        from: dateFrom,
        to: dateTo,
      }

      const results = await queryAudits(queryParams)

      const data = results.data
      const { items, ...rest } = data

      setFetched(true)
      setItems(items)
      setDetails(rest as InvoiceReport)
    } catch (ex) {
      message.error('Failed to fetch report')
      console.error(ex)
    } finally {
      setFetching(false)
    }
  }

  const onDownloadCsv = () => {
    const csv = exportTaxesToCsv(items)

    const blob = new Blob([csv], {
      type: 'text/plain',
    })

    let reportName = 'invoice-report'

    if (dateFrom!= null) {
      reportName += `-${dateFrom}`
    }

    if (dateTo != null) {
      reportName += `-${dateTo}`
    }

    reportName = `${reportName}.csv`

    const file = new File([blob], reportName)
    const fileUrl = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.download = reportName
    link.href = fileUrl
    link.click()
  }

  return (
    <ContainerLayout>
      <Form>
        <H1>Invoices</H1>
        <Container>
          <DatePicker
            label='From'
            name='from'
            onChange={onDateFromChange}
            placeholder='Optional From Date'
            value={dateFrom}
          />
          <PaddedContainerItem>
            <DatePicker
              label='To'
              name='to'
              onChange={onDateToChange}
              placeholder='Optional To Date'
              value={dateTo}
            />
          </PaddedContainerItem>
        </Container>

        <ButtonContainer>
          <div>
            <Button content='Generate Invoice Report' loading={fetching} onClick={onGenerateInvoiceReport} primary />
          </div>
          {items.length > 0 && (
            <div>
              <Button
                content='Download Report as CSV'
                icon='download'
                onClick={onDownloadCsv}
              />
            </div>
          )}
        </ButtonContainer>

        {items.length > 0 && <InvoiceReportsDataView details={details} items={items} />}
        {fetched && items.length <= 0 && <Message content='No items in report' info />}
      </Form>
    </ContainerLayout>
  )
}