import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
	Button,
	Divider,
	Form,
	Text,
	Label,
	HeaderWithIcon,
} from 'components/ui'
import { Address } from 'components/forms'
import { PracticeTypes } from 'constants/practice-types'
import { fetchOffices, getCTUser$ } from 'containers/account'
import { Dispatch, IUser } from 'interfaces'

import { saveData } from './save-data'
import { SetupData } from './interfaces'
import { fetchClientTrackerUser } from 'containers/user'
import { fetchSettings, getDefaultFormsType } from 'containers/settings'
import { CLIENT_TYPES, SOAP_TYPES } from 'constants/settings'

interface Props {
	data: SetupData
	onNextStep: (step: number) => void
	clearData: () => void
}

export const ReviewStepFour = ({ data, onNextStep, clearData }: Props) => {
	const dispatch: Dispatch = useDispatch()
	const navigate = useNavigate()

	const user: IUser = useSelector(getCTUser$)
	const prevStep = () => onNextStep(2)
	const [loading, setLoading] = useState(false)

	const getPracticeType = () => {
		if (data.practiceType === PracticeTypes.massage) {
			return 'Massage'
		} else if (data.practiceType === PracticeTypes.psychotherapy) {
			return 'Psychotherapy / Counseling'
		}
	}

	const onSubmit = useCallback(async () => {
		setLoading(true)
		const response = await saveData(data, user)

		if (response === true) {
			dispatch(fetchClientTrackerUser(user))
			dispatch(fetchSettings())
			dispatch(fetchOffices({ useCache: false }))
			dispatch(getDefaultFormsType(data.practiceType, CLIENT_TYPES))
			dispatch(getDefaultFormsType(data.practiceType, SOAP_TYPES))
			clearData()

			navigate('/dashboard')
		}

		setLoading(false)
	}, [dispatch, data, user, clearData])

	return (
		<div>
			<Text>Take a moment to review your information</Text>
			<Form>
				<HeaderWithIcon name='user'>User Information</HeaderWithIcon>
				<Label>First Name</Label>
				<Text>{data.firstName}</Text>

				{data.middleName && <Label>Middle / Other Name</Label>}
				{data.middleName && <Text>{data.middleName}</Text>}

				<Label>Last Name</Label>
				<Text>{data.lastName}</Text>

				<Divider />

				<HeaderWithIcon name='clipboard'>Practice Type</HeaderWithIcon>
				<Text>{getPracticeType()}</Text>

				<Divider />

				{data.officeName && (
					<>
						<HeaderWithIcon name='building'>Clinic</HeaderWithIcon>
						<Text>{data.officeName}</Text>
						<Address
							address={data.officeAddress['officeAddress-address']}
							addressTwo={data.officeAddress['officeAddress-addressTwo']}
							city={data.officeAddress['officeAddress-city']}
							onChange={() => {
							}}
							postalcode={data.officeAddress['officeAddress-postalcode']}
							province={data.officeAddress['officeAddress-province']}
							readonly
						/>
					</>
				)}
			</Form>

			<Divider hidden />

			<Button content='Prev' onClick={prevStep} />
			<Button
				content='Submit'
				icon='checkmark'
				loading={loading}
				onClick={onSubmit}
				primary
			/>
		</div>
	)
}
