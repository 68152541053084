import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { get, post } from 'lib/http-request'
import {
	Card,
	Form,
	Button,
	Message,
	LinkText,
	Icon,
	Divider,
} from 'components/ui'
import { Checkbox } from 'components/forms'
import { Emails } from 'constants/endpoints'
import { Spinner } from 'components/loaders'
import { IEvent } from 'interfaces'

const Container = styled.div`
  max-width: 45em;
  margin: auto;
`

interface Params {
	subID?: string
	email?: string
}

export const SubscriptionManager = () => {
	const [keys, setKeys] = useState({})
	const [subscriptions, setSubscriptions] = useState({})
	const [loading, setLoading] = useState(false)
	const [saving, setSaving] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState({ err: false, msg: '' })

	const params: Params = useParams()

	const fetchSubscriptionKeys = useCallback(
		() =>
			get(Emails.Keys)
				.then((response) => {
					setKeys(response.data)
					return response
				})
				.catch(() =>
					setError({
						err: true,
						msg: 'Failed to fetch your preferences, please try again later',
					}),
				),
		[],
	)

	const fetchSubscriptions = useCallback(
		() =>
			get(
				Emails.GetSubscriptions(
					params.subID,
					params.email,
				),
			)
				.then((response) => {
					setSubscriptions(response.data.subscriptionData)
					return response
				})
				.catch(() =>
					setError({
						err: true,
						msg: 'Failed to fetch your preferences, please try again later',
					}),
				),
		[],
	)

	const postSubscriptions = useCallback(() => {
		setSaving(true)
		post(Emails.SaveSubscriptions, {
			email: params.email,
			id: params.subID,
			subscriptionData: subscriptions,
		})
			.then(() => {
				setSuccess(true)
				setSaving(false)
			})
			.catch(() => {
				setSaving(false)
				setError({
					err: true,
					msg: 'Failed to save your preferences, please try again later',
				})
			})
	}, [subscriptions])

	const unsubAll = () => {
		const subs = {}
		for (const key of Object.keys(subscriptions)) {
			subs[key] = false
		}

		setSubscriptions({ ...subs })
	}

	useEffect(() => {
		setLoading(true)
		;(async () => {
			await fetchSubscriptionKeys()
			await fetchSubscriptions()
			setLoading(false)
		})()
	}, [fetchSubscriptionKeys, fetchSubscriptions])

	return (
		<Container>
			<Card title='Email Subscriptions'>
				{error.err && <Message content={error.msg} error icon='warning' />}

				{!error.err && (
					<Spinner spinning={loading} tip='loading email preferences...'>
						<LinkText onClick={unsubAll}>Unsubscribe from All Emails</LinkText>

						<Form>
							{Object.keys(subscriptions).map((entry) => (
								<Checkbox
									key={keys[entry]}
									label={keys[entry]}
									name={entry}
									onChange={(e: IEvent) => {
										setSubscriptions((prevState) => ({
											...prevState,
											[e.target.name]: e.target.value,
										}))
									}}
									toggle
									value={subscriptions[entry]}
								/>
							))}
						</Form>

						<Divider hidden />

						{success && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									paddingBottom: '0.5em',
								}}
							>
								<Icon color='green' name='check' size='large' />
								Preferences Saved
							</div>
						)}
						<Button
							content='Save Preferences'
							loading={saving}
							onClick={postSubscriptions}
							primary
						/>
					</Spinner>
				)}
			</Card>
		</Container>
	)
}
