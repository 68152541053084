import { authPost } from 'lib/http-request'
import { SMS } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, EmailState, Dispatch } from 'interfaces'

export const defaultState = {
  flags: {
    sendingAppointmentSMS: false,
    sendingAppointmentSMSError: {},
  },
}

const smsAction = (action: string) => `client-trackyr/sms/${action}`
const SEND_APPOINTMENT_SMS = smsAction('SEND_APPOINTMENT_SMS')
const SEND_APPOINTMENT_SMS_SUCCESS = smsAction('SEND_APPOINTMENT_SMS_SUCCESS')
const SEND_APPOINTMENT_SMS_FAILURE = smsAction('SEND_APPOINTMENT_SMS_FAILURE')

export const reducer = (
  state = defaultState as EmailState,
  action: IAction,
) => {
  switch (action.type) {
    case SEND_APPOINTMENT_SMS:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentSMS: true,
          sendingAppointmentSMSError: {},
        },
      }

    case SEND_APPOINTMENT_SMS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentSMS: false,
          sendingAppointmentSMSError: {},
        },
      }

    case SEND_APPOINTMENT_SMS_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          sendingAppointmentSMS: false,
          sendingAppointmentSMSError: { ...action.error },
        },
      }

    default:
      return state
  }
}

export const sendAppointmentSMS = (
  appointmentID: string,
  phoneNumber: string,
  showNotification = false,
): any => async (dispatch: Dispatch): Promise<any> => {
  dispatch({
    type: SEND_APPOINTMENT_SMS,
    appointmentID,
    phoneNumber,
    showNotification,
  })

  return authPost(SMS.SendSMS, { appointmentID, to: phoneNumber })
    .then((response) => {
      dispatch(onSendSuccess())
      showNotification && message.success('SMS Sent!')
      return response
    })
    .catch((error) => {
      showNotification &&
        message.error('Failed to Send Appointment SMS, please try again later')
      captureMessage(`Failed to Send SMS: ${error}`, Severity.Error)
      dispatch(onSendFailure(error))

      return error
    })
}

const onSendSuccess = () => ({ type: SEND_APPOINTMENT_SMS_SUCCESS })

const onSendFailure = (error: any) => ({
  type: SEND_APPOINTMENT_SMS_FAILURE,
  error,
})
