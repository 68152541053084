import { authPost } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import {
  normalizeArray,
  parseClientTypeSettingsFromAPI,
  parseClientTypesSettingsToAPI,
} from 'lib'
import { Dispatch, IAction, IFormType } from 'interfaces'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`

const SAVE_FORMS_TYPES = settingsAction('SAVE_FORMS_TYPES')
const SAVE_FORMS_TYPES_SUCCESS = settingsAction('SAVE_FORMS_TYPES_SUCCESS')
const SAVE_FORMS_TYPES_FAILURE = settingsAction('SAVE_FORMS_TYPES_FAILURE')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SAVE_FORMS_TYPES:
      return {
        ...state,
        loading: true,
      }

    case SAVE_FORMS_TYPES_SUCCESS: {
      return {
        ...state,
        [action.formType]: action.response.sort((a: IFormType) =>
          a.isDeleted ? 1 : -1,
        ),
        [`${action.formType}-normalized`]: normalizeArray(
          action.response,
          action.formType,
        ),
        loading: false,
        isDirty: false,
        formSettingsErrorMessage: '',
      }
    }

    case SAVE_FORMS_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export const saveFormsTypes = (
  types: Array<IFormType>,
  formType: string,
  options = { showMessage: true },
) => async (dispatch: Dispatch) => {
  dispatch({ type: SAVE_FORMS_TYPES, types, formType })

  return authPost(
    Settings.FormType(formType),
    parseClientTypesSettingsToAPI(types),
  )
    .then((response) => {
      options.showMessage && message.success('Form Types Saved')
      dispatch(
        saveFormsTypesSuccess(
          parseClientTypeSettingsFromAPI(response.data),
          formType,
        ),
      )

      return response
    })
    .catch((error) => {
      if (error.response && error.response.data.validationErrors) {
        const duplicates = Object.keys(error.response.data.validationErrors)
        options.showMessage &&
          message.error(`Duplicate Form Names: ${duplicates.join(',')}`)
      } else {
        options.showMessage &&
          message.error(`Failed to save Forms Type Settings: ${error}`)
      }

      captureMessage(`saveFormsTypes Failed. ${error}`, Severity.Error)

      dispatch(saveFormsTypesFailure(error, formType))

      return error
    })
}

export const saveFormsTypesSuccess = (response: any, formType: string) => ({
  type: SAVE_FORMS_TYPES_SUCCESS,
  response,
  formType,
})

export const saveFormsTypesFailure = (error: any, formType: string) => ({
  type: SAVE_FORMS_TYPES_FAILURE,
  error,
  formType,
})
