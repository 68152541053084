import React from 'react'
import styled from 'styled-components'

import { Button } from 'components/ui'

const ButtonBackground = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 1em;

  :hover {
    background-color: ${(props) => props.theme.ButtonBackgroundColour};
  }
`

const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 9;
`

const SaveCancelButtons = (props: ISaveCancelButtons) => {
  return (
    <Sticky>
      <ButtonBackground>
        {!props.readonly && (
          <div>
            <Button
              content={props.saveText}
              disabled={props.disableSave}
              loading={props.loading}
              onClick={props.onSave}
              primary
            />
          </div>
        )}
        <div>
          {!props.noCancel && (
            <Button content={props.cancelText} onClick={props.onCancel} secondary/>
          )}
        </div>
        {props.extraButtons && <div>{props.extraButtons}</div>}
      </ButtonBackground>
    </Sticky>
  )
}

interface ISaveCancelButtons {
  cancelText?: string
  disableSave: boolean
  extraButtons?: React.ReactNode
  loading: boolean
  noCancel?: boolean
  onCancel?: Function
  onSave: Function
  readonly?: boolean
  saveText?: string
}

SaveCancelButtons.defaultProps = {
  cancelText: 'Cancel',
  disableSave: false,
  saveText: 'Save',
}

export default SaveCancelButtons
