import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { Dropdown, DatePicker } from 'components/forms'
import { Button, Form, Segment } from 'components/ui'
import { IEvent, IQueryParams, parseSearchLocation } from 'interfaces'

import { AUDIT_PAGE_SIZE } from '.'
import { Audits } from './audits'
import { Audit, AuditType, TableName } from './interfaces'
import { queryAudits } from './query'
import moment from 'moment'
import { MobileBreakPointSmall, MobileBreakPointTiny } from 'styles'
import { ErrorBoundary } from '@sentry/react'

interface Props {
  entityID: string
  onParamChange?: (newParam: { [key: string]: string }) => void
  onQueryClear?: () => void
  pickTable?: boolean
  queryTable?: TableName | null
}

const auditTypeOptions = [
  {
    id: 0,
    key: 0,
    text: 'All',
    value: 0,
  },
  {
    id: 1,
    key: 1,
    text: 'Add',
    value: 1,
  },
  {
    id: 2,
    key: 2,
    text: 'Update',
    value: 2,
  },
  {
    id: 3,
    key: 3,
    text: 'Delete',
    value: 3,
  },
  {
    id: 4,
    key: 4,
    text: 'Accessed',
    value: 4,
  },
  {
    id: 5,
    key: 5,
    text: 'Completed',
    value: 5,
  },
  {
    id: 6,
    key: 6,
    text: 'Fetched',
    value: 6,
  },
  {
    id: 7,
    key: 7,
    text: 'Archived',
    value: 7,
  },
  {
    id: 8,
    key: 8,
    text: 'Sent',
    value: 8,
  },
  {
    id: 9,
    key: 9,
    text: 'Reminder Sent',
    value: 9,
  },
]

const auditLocationOptions = [
  {
    id: TableName.All,
    key: TableName.All,
    text: TableName.All,
    value: TableName.All,
  },
  {
    id: TableName.Clients,
    key: TableName.Clients,
    text: TableName.Clients,
    value: TableName.Clients,
  },
  {
    id: TableName.Notes,
    key: TableName.Notes,
    text: TableName.Notes,
    value: TableName.Notes,
  },
  {
    id: TableName.Appointments,
    key: TableName.Appointments,
    text: TableName.Appointments,
    value: TableName.Appointments,
  },
  {
    id: TableName.Treatments,
    key: TableName.Treatments,
    text: TableName.Treatments,
    value: TableName.Treatments,
  },
  {
    id: TableName.Files,
    key: TableName.Files,
    text: TableName.Files,
    value: TableName.Files,
  },
  {
    id: TableName.PublicIntake,
    key: TableName.PublicIntake,
    text: TableName.PublicIntake,
    value: TableName.PublicIntake,
  },
  {
    id: TableName.Email,
    key: TableName.Email,
    text: TableName.Email,
    value: TableName.Email,
  },
  {
    id: TableName.SMS,
    key: TableName.SMS,
    text: TableName.SMS,
    value: TableName.SMS,
  },
]

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PaddedContainerItem = styled.div`
  padding-left: 1em;

  @media (max-width: ${MobileBreakPointTiny}) {
    padding-left: 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${MobileBreakPointSmall}) {
    margin-top: 1em;
  }
`

export const AuditContainer = (props: Props) => {
  const location = useLocation()
  const params = parseSearchLocation(location.search)

  const [audits, setAudits] = useState([] as Audit[])
  const [totalCount, setTotalCount] = useState(0)
  const [auditType, setAuditType] = useState(
    params.auditType || [AuditType.Unknown],
  )
  const [auditLocation, setAuditLocation] = useState(
    props.queryTable || params.location || [TableName.All],
  )
  const onAuditTypeChange = ({ target }: IEvent) => {
    setAuditType(target.value)
    props?.onParamChange?.({ auditType: target.value })
  }
  const onAuditLocationChange = ({ target }: IEvent) => {
    setAuditLocation(target.value)
    props?.onParamChange?.({ location: target.value })
  }

  const fromParse = moment(params.from, 'DD-MM-YYYY')
  const toParse = moment(params.to, 'DD-MM-YYYY')
  const [dateFrom, setDateFrom] = useState(
    fromParse.isValid() ? fromParse : null,
  )
  const [dateTo, setDateTo] = useState(toParse.isValid() ? fromParse : null)
  const onDateFromChange = ({ target }: IEvent) => {
    const from = target.value === null ? null : target.value
    setDateFrom(from)
    props?.onParamChange?.({ from: from })
  }
  const onDateToChange = ({ target }: IEvent) => {
    const to = target.value === null ? null : target.value
    setDateTo(to)
    props?.onParamChange?.({ to: to })
  }
  const [page, setPage] = useState(1)
  const onPageChange = (activePage: number) => {
    setPage(activePage)
    onQuery(activePage)
  }
  const [fetching, setFetching] = useState(false)

  const onQuery = useCallback(
    async (page) => {
      setFetching(true)
      const queryParams: IQueryParams = {
        take: AUDIT_PAGE_SIZE,
        skip: (page - 1) * AUDIT_PAGE_SIZE,
        location: auditLocation,
        entityID: props.entityID,
        auditType: auditType === 0 ? null : auditType,
        from: dateFrom,
        to: dateTo,
      }
      const results = await queryAudits(queryParams)

      if (results.status === 200) {
        setAudits(results.data.items)
        setTotalCount(results.data.totalCount)
      }
      setFetching(false)
    },
    [props.entityID, auditType, dateFrom, dateTo, auditLocation],
  )

  const onClearQuery = () => {
    setDateFrom(null)
    setDateTo(null)
    setAuditType(0)
    setAuditLocation(TableName.All)
    props.onQueryClear && props.onQueryClear()
  }

  return (
    <ErrorBoundary>
      <div>
        <Form>
          <Container>
            <Dropdown
              clearable
              label="Audit Type"
              multiple
              name="auditType"
              onChange={onAuditTypeChange}
              options={auditTypeOptions}
              style={{ maxWidth: '20em' }}
              value={auditType}
            />

            {props.pickTable && (
              <PaddedContainerItem>
                <Dropdown
                  clearable
                  label="Client Information Type"
                  multiple
                  name="table"
                  onChange={onAuditLocationChange}
                  options={auditLocationOptions}
                  style={{
                    maxWidth: '20em',
                    marginLeft: '1em',
                  }}
                  value={auditLocation}
                />
              </PaddedContainerItem>
            )}
          </Container>

          <Container>
            <DatePicker
              label="From"
              name="from"
              onChange={onDateFromChange}
              placeholder="Optional From Date"
              value={dateFrom}
            />
            <PaddedContainerItem>
              <DatePicker
                format="MMM DD YYYY"
                label="To"
                name="to"
                onChange={onDateToChange}
                placeholder="Optional To Date"
                value={dateTo}
              />
            </PaddedContainerItem>
          </Container>

          <ButtonContainer>
            <Button
              content="Query Audits"
              icon="database"
              loading={fetching}
              onClick={onQuery}
              primary
            />

            <Button
              content="Clear Query"
              icon="remove"
              onClick={onClearQuery}
            />
          </ButtonContainer>

          <Segment>
            <Audits
              audits={audits}
              onPageChange={onPageChange}
              page={page}
              totalCount={totalCount}
            />
          </Segment>
        </Form>
      </div>
    </ErrorBoundary>
  )
}
