import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'

import { Font, ReadonlyFormFont } from 'styles/font'
import { PrintableInputs } from '.'
import { Print } from './interfaces'

const TA = styled(TextareaAutosize)`
  ${Font};

  color: ${(props) => props.theme.TextColour} !important;
  background-color: ${(props) => props.theme.BackgroundColour} !important;
  border: 1px solid ${(props) => props.theme.InputBorderColour} !important;

  border-radius: 4px;
  width: 100%;
  padding: 0.67857143em 1em;
  max-height: none;

  :focus {
    border-color: ${(props) => props.theme.InputBorderColourFocused} !important;
    box-shadow: 0 0 2px ${(props) => props.theme.InputBorderColourFocused} !important;
  }
`

const TextArea = (props: ITextArea) =>
  props?.printParams?.printingView ? (
    <PrintableInputs
      height={props?.printParams?.textAreaHeight ?? 3}
      multiLines={props?.printParams?.multiLines}
      value={props.value}
    />
  ) : props.readonly ? (
    <ReadonlyFormFont>{props.value || ' '}</ReadonlyFormFont>
  ) : (
    <TA
      autoFocus={props.autoFocus}
      maxRows={120}
      minRows={4}
      name={props.name}
      onBlur={props.onBlur}
      onChange={props.onChange}
      placeholder={props.placeholder}
      readOnly={props.readonly}
      value={props.value}
    />
  )

interface ITextArea {
  autoFocus?: boolean
  // autosaveMode?: string
  name?: string
  onBlur?: Function | any //ChangeEvent<HTMLTextAreaElement>
  onChange?: Function | any //ChangeEvent<HTMLTextAreaElement>
  placeholder?: string
  printParams?: Print
  readonly?: boolean
  // size?: string
  value?: string
}

TextArea.defaultProps = {
  readonly: false,
}

export default TextArea
