import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import {
	Button,
	Form,
	Text,
	Message,
	Card,
	Modal,
	ContainerLayout,
	Header,
	Icon,
	Divider,
	Image,
	H1,
} from 'components/ui'
import { Input } from 'components/forms'
import { Forms } from 'containers/forms'
import { b64atob } from 'lib/http-request'
import { Spinner } from 'components/loaders'
import { parsePhoneNumbersFromAPI } from 'lib'

import { fetchPublicIntake } from './redux/fetch-public-intake'
import { fetchPublicIntakeForms } from './redux/fetch-public-intake-forms'
import { CompleteModal } from './complete-modal'
import { IPublicIntake } from 'interfaces'

const Container = styled.div``

const SuccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonContainer = styled.div`
  text-align: right;
  margin-bottom: 1em;
`

export const PublicIntake = () => {
	const [loading, setLoading] = useState(false)
	const [fetched, setFetched] = useState(false)
	const [error, setError] = useState({ hasError: false, title: '', msg: '' })
	const [success, setSuccess] = useState(false)
	const [formData, setFormData] = useState({})
	const [formUrls, setFormUrls] = useState([])
	const [passphrase, setPassphrase] = useState('')
	const [showPassphraseModal, setShowPassphraseModal] = useState(false)
	const [showSubmitModal, setShowSubmitModal] = useState(false)
	const [publicIntake, setPublicIntake] = useState({} as IPublicIntake)

	const params: Params = useParams()

	const needsPassphrase = !!params.p
	const clientID = params.clientID
	const link = params.link

	const fetchForms = useCallback((data) => {
		fetchPublicIntakeForms(data.id)
			.then((res) => {
				const forms = res.data.forms
				const formUrls = []

				for (const form of forms) {
					const dataUrl = b64atob(form)
					formUrls.push(JSON.parse(dataUrl))
				}

				setFormUrls(formUrls)
			})
			.catch(() => {
				setError({
					hasError: true,
					title: 'Failed to get Form',
					msg: 'Error fetching Form, please try again later',
				})
			})
			.finally(() => setLoading(false))
	}, [])

	const fetch = useCallback(
		(clientID, link, passphrase) => {
			setLoading(true)
			setFetched(true)

			fetchPublicIntake(clientID, link, passphrase)
				.then((r) => {
					setPublicIntake(r.data)
					fetchForms(r.data)

					const apiData = parsePhoneNumbersFromAPI(r.data.client)

					const sessionKeys = Object.keys(sessionStorage)
					const data = {}

					for (const key of sessionKeys) {
						try {
							const value = JSON.parse(sessionStorage.getItem(key))

							if (value && value.value) {
								data[key] = value.value
							}
						} catch {
							/* noop */
						}
					}

					setFormData({ ...apiData, ...data })
				})
				.catch((e) => {
					if (
						e.response &&
						e.response.status === 400 &&
						e.response.data === 'Form Completed'
					) {
						setError({
							hasError: true,
							title: 'Form Complete',
							msg: 'This form has already been completed',
						})
					} else if (
						e.response &&
						e.response.status === 400 &&
						e.response.data === 'Form Expired'
					) {
						setError({
							hasError: true,
							title: 'Form Expired',
							msg: 'This form has expired',
						})
					} else if (e.response && e.response.status === 404) {
						setError({
							hasError: true,
							title: '404 Form Not Found',
							msg: 'We\'re sorry, we were unable to find this form',
						})
					} else {
						setError({
							hasError: true,
							title: 'Error',
							msg: 'Error with Intake Form, please try again later',
						})
					}

					setLoading(false)
				})
		},
		[fetchForms],
	)

	const onChange = (_: any, name: string, value: string) => {
		const newFormData = { ...formData, [name]: value }
		sessionStorage.setItem(name, JSON.stringify({ value }))
		setFormData(newFormData)
	}

	if (needsPassphrase && !passphrase && !showPassphraseModal) {
		setShowPassphraseModal(true)
	} else if (!needsPassphrase && !loading && !fetched) {
		fetch(clientID, link, passphrase)
	}

	if (loading) {
		return <Spinner size='big' spinning tip='Loading Client Intake' />
	}

	// TODO - use success template from settings to populate this, comes from intake?
	if (success) {
		return (
			<ContainerLayout>
				<div className='center-screen'>
					<Card hideTitle>
						<SuccessContainer>
							<Icon color='green' name='checkmark' size='huge' spin />
							<Text fontSize={32} fontWeight={600}>
								Successfully Submitted Intake Form
							</Text>
						</SuccessContainer>
						<SuccessContainer>
							<Text fontSize={18}>
								Thank you for filling out your intake information.
							</Text>
						</SuccessContainer>
					</Card>
				</div>
			</ContainerLayout>
		)
	}

	if (error.hasError && error.title === 'Form Complete') {
		return (
			<ContainerLayout>
				<div className='center-screen'>
					<Card hideTitle>
						<SuccessContainer>
							<Icon color='green' name='checkmark' size='huge' spin />
							<Text fontSize={32} fontWeight={600}>
								This intake form has already been completed
							</Text>
						</SuccessContainer>
						<SuccessContainer>
							<Text fontSize={18}>
								Thank you for filling out your intake information.
							</Text>
						</SuccessContainer>
					</Card>
				</div>
			</ContainerLayout>
		)
	}

	return (
		<ContainerLayout>
			{error.hasError && (
				<Message
					content={error.msg}
					error
					header={error.title}
					icon='warning'
					style={{ marginTop: '1em' }}
				/>
			)}

			<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				<Image maxHeight='25em' src={publicIntake.brandingRectURL} />
			</div>

			<H1>
				{publicIntake.brandingName
					? `${publicIntake.brandingName} New Client Intake Forms`
					: 'New Client Intake Forms'}
			</H1>

			<Container>
				<Modal open={showPassphraseModal} size='small'>
					<Header
						content='Enter Passphrase for Intake'
						icon='clipboard outline'
					/>
					<Modal.Content>
						<Text>Enter the passphrase you recieved in your email</Text>
						<Form>
							<Input
								label='Passphrase'
								onChange={(e) => setPassphrase(e.target.value)}
								value={passphrase}
							/>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={() => {
								fetch(clientID, link, passphrase)
								setShowPassphraseModal(false)
							}}
							primary
						>
							<Icon name='checkmark' /> Submit
						</Button>
					</Modal.Actions>
				</Modal>

				<CompleteModal
					formData={formData}
					onChange={onChange}
					publicIntake={publicIntake}
					setError={setError}
					setLoading={setLoading}
					setShowSubmitModal={setShowSubmitModal}
					setSuccess={setSuccess}
					showSubmitModal={showSubmitModal}
				/>

				{/* TODO - text and pictures from settings later */}
				{!error.hasError && (
					<>
						<Text fontSize={16}>
							{`Please complete these forms before your first appointment. This form expires on ${moment(
								publicIntake.expires,
							).format('MMMM D YYYY')}`}
						</Text>

						<Text fontSize={16}>
							Once you complete this form, it will no longer be available.
							Please make sure it&apos;s correct before you submit it
						</Text>
					</>
				)}

				<Divider />

				<Forms
					autosave={false}
					entityID={params.clientID}
					forms={formUrls}
					formsDataOverride={formData}
					onChange={onChange}
					patchEndpoint=''
					// validateFormOverride={validateForm}
				/>
			</Container>

			{!error.hasError && (
				<ButtonContainer>
					<Button
						content='Complete Intake Form'
						onClick={() => setShowSubmitModal(true)}
						primary
					/>
				</ButtonContainer>
			)}
		</ContainerLayout>
	)
}

interface Params {
	clientID?: string
	link?: string
	p?: string
}
