import { NavBarState, IAction } from 'interfaces'

const navAction = (action: string) => `client-trackyr/navigation/${action}`
const SET_NAV_BAR_TEXT = navAction('SET_NAV_BAR_TEXT')

export const defaultState = {
  text: '',
  searchValue: '',
  searchLoading: false,
} as NavBarState

export const reducer = (state: NavBarState = defaultState, action: IAction) => {
  switch (action.type) {
    case SET_NAV_BAR_TEXT:
      return {
        ...state,
        text: action.text,
      }

    default:
      return state
  }
}

export const setNavBarText = (text: string) => ({
  type: SET_NAV_BAR_TEXT,
  text,
})
