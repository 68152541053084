import { FormGeneratorComponentType } from 'interfaces'
import { FormType } from 'interfaces/forms'
import moment from 'moment'

enum DataType {
  Text = 'text',
  TextArea = 'textArea',
  Boolean = 'boolean',
  Dropdown = 'dropdown',
  MultiText = 'multitext',
  Icon = 'icon',
  Radio = 'radio',
}

enum ComponentType {
  Fragment = 'fragment',
  Card = 'card',
  Group = 'group',
  Columns = 'columns',
  PrintColumns = 'printColumns',
  Email = 'email',
  Input = 'input',
  InputNumber = 'inputNumber',
  Date = 'date',
  Checkbox = 'checkbox',
  CheckboxInput = 'checkboxInput',
  CheckboxForm = 'checkboxForm',
  CheckboxGroup = 'checkboxGroup',
  CheckboxGroupInput = 'checkboxGroupInput',
  CheckboxGroupForm = 'checkboxGroupForm',
  TextInput = 'textInput',
  TextArea = 'textArea',
  PhoneNumber = 'phoneNumber',
  Radio = 'radio',
  Yesno = 'yesno',
  Yesnoform = 'yesnoform',
  Dropdown = 'dropdown',
  Address = 'address',
  Label = 'label',
  Text = 'text',
  FinePrint = 'finePrint',
  BodyDiagram = 'bodyDiagram',
  MultiForm = 'multiForm',
  Segment = 'segment',
  Signature = 'signature',
  EmergencyContact = 'emergencyContact',
  FormHeader = 'formHeader',
  Divider = 'divider',
  AppointmentNumber = 'appointmentNumber',
}

interface FormItemComponent {
  id?: string
  data: Array<FormItemComponentData>
  deprecated?: boolean
  description: string
  hidden?: boolean
  label: string
  name: string
  title?: string
  subtitle?: string
  type: FormGeneratorComponentType
}

interface FormItemComponentData {
  defaultValue?: any
  dropdownOptions?: Array<any>
  radioOptions?: Array<any>
  help?: string
  hidden?: boolean
  label: string
  name: string
  optional?: boolean
  placeholder?: string
  type: DataType
  value?: any
}

// TODO - Might be same as FormItemComponent?
interface FormBuilderComponent {
  id: string
  additionalProps: FormItemComponent
  children?: Array<FormBuilderComponent>
  deprecated?: boolean
  expanded?: boolean
  isComponent: boolean
  isContainer: boolean
  isSubFormComponent?: boolean
  label: string
  name?: string
  subtitle: string
  title: string
  titleLabel: string
  type: FormGeneratorComponentType
}

interface FormInfo {
  id?: string
  dateCreated?: Date | moment.Moment | any
  dateModified?: Date | moment.Moment | any
  name: string
  screen: 'client'
  tab: FormType
  type: string
}

export type {
  FormItemComponent,
  FormItemComponentData,
  FormBuilderComponent,
  FormInfo,
}
export { DataType, ComponentType }
