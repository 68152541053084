import React from 'react'
import { useSelector } from 'react-redux'

import { getSoapTypes$ } from 'containers/settings'

import { toCamelCase } from './json-parsing-fix'
import { IState, Note } from 'interfaces'
import { getClient, getClientFullName } from 'containers/clients'
import { getFormTemplates$ } from 'containers/forms/selectors'
import { LabelText } from '../components/label-text'
import { getFormLabelFromID } from '../utils'
import { Audit } from '../interfaces'

interface Props {
  audit: Audit
  value: string
}

export const NotesValue = ({ audit, value }: Props) => {
  const note: Note | any = (value && JSON.parse(value, toCamelCase)) || {} // TEMP - Note
  const soapTypes = useSelector(getSoapTypes$)
  const client = useSelector((state: IState) =>
    getClient(state, note?.clientID || audit.subEntityID),
  )
  const templates = useSelector(getFormTemplates$)

  const getSingleDetailValue = (): any[] =>
    getFormLabelFromID(note, templates, null, [
      'id',
      'treatmentNumber',
      'appointmentID',
      'soapTypeID',
      'clientID',
      'bodyDiagramID',
    ])

  const detailValues = getSingleDetailValue()

  return (
    <>
      <LabelText label="ID" value={note.id} />

      <LabelText
        label="Note Type"
        value={soapTypes.find((st) => st.id === note.soapTypeID)?.name}
      />

      <LabelText label="Client" value={getClientFullName(client)} />

      <LabelText label="Body Diagram ID" value={note.bodyDiagramID} />

      {/* <LabelText label={note.key} value={note.value} /> */}

      <LabelText label="Treatment Number" value={note.treatmentNumber} />

      {detailValues.map((k: any) => {
        if (!k) {
          return null
        }

        return <LabelText key={k.label} label={k.label} value={k.value} />
      })}
    </>
  )
}
