import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { captureMessage } from '@sentry/browser'
import { Dispatch, Severity } from 'interfaces'

import { getFormsForClient } from 'containers/forms/selectors'
import {
	Button,
	Checkbox,
	Form,
	Modal,
	Header,
	IconButton,
	Divider,
	ErrorText,
} from 'components/ui'
import { Input } from 'components/forms'
import {
	getSelectedClient$,
	getSelectedClientFullName$,
} from 'containers/clients'
import { FormPreviewModal } from 'components/modals'
import { isEmailValid } from 'lib/validation'

import { getClientTypeByClientTypeID$ } from 'containers/settings'
import { createPublicIntakeForm } from 'containers/public-intake/redux/create-public-intake'
import { getAccountFormsByFormID } from 'containers/account'
import { IEvent, IState } from 'interfaces'
import { SubscriptionError, ToEmailAddress } from 'containers/emails'
import { clearSendingPublicIntakeEmailError } from 'containers/public-intake'
import { Form_T } from 'containers/settings/form-types/new-interfaces'
import { CLIENT_INFO_TAB_ID } from 'constants/form'

const FormCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }
`
export const CreatePublicIntakeModal = (props: ICreatePublicIntakeModal) => {
	const dispatch: Dispatch = useDispatch()

	const accountForms = useSelector(getAccountFormsByFormID)
	const forms = useSelector(getFormsForClient)
	const client = useSelector(getSelectedClient$)
	const clientName = useSelector(getSelectedClientFullName$)
	const clientType: Form_T = useSelector((state: IState) =>
		getClientTypeByClientTypeID$(state, client.clientTypeID),
	)

	const [fetching, setFetching] = useState(false)
	const [error, setError] = useState({ form: '', email: '' } as IError)
	const [selectedForms, setSelectedForms] = useState({})
	const [email, setEmail] = useState(client.email)
	const [passphrase, setPassphrase] = useState('')
	const [formPreviewModalState, setFormPreviewModalState] = useState({
		open: false,
		json: [],
		name: '',
	})

	const validate = useCallback(
		(forms) => {
			if (forms.length === 0) {
				setFetching(false)
				setError({ form: 'Must have at least 1 Form Selected' })
				return false
			}

			if (!email) {
				setFetching(false)
				setError({ email: 'You must send a public intake to an email address' })
				return false
			}

			if (!isEmailValid(email)) {
				setFetching(false)
				setError({ email: 'Invalid Email Address' })
				return false
			}

			return true
		},
		[email],
	)

	const onSubmit = useCallback(
		(ignoreSubscription = false) => {
			setError({ form: '', email: '' })
			setFetching(true)

			const forms = []

			const infoForms = clientType.formTabs.find(
				(ft) => ft.id === CLIENT_INFO_TAB_ID,
			)?.forms

			for (const sf of infoForms) {
				if (selectedForms[sf.formID] === true) {
					const accountForm = accountForms[sf.formID]

					if (accountForm == null) {
						captureMessage(
							`Undefined accountForm for CreatePublicIntakeModal.onSubmit: ${sf.formID}`,
							Severity.Error,
						)
						continue
					}

					forms.push({ id: accountForm.id })
				}
			}

			if (!validate(forms)) {
				return
			}

			const publicIntakeObject = {
				clientID: client.id,
				email,
				expires: null,
				forms,
				passphrase,
			}

			dispatch(createPublicIntakeForm(publicIntakeObject, ignoreSubscription))
				.then(() => {
					setFetching(false)
					props.handleClose()
				})
				.catch(() => setFetching(false))
		},
		[
			dispatch,
			client,
			selectedForms,
			email,
			clientType,
			props,
			accountForms,
			passphrase,
			validate,
		],
	)

	const onFormPreview = (json, name) => {
		setFormPreviewModalState({
			...formPreviewModalState,
			open: true,
			json: json,
			name: name,
		})
	}

	const handleClose = () => {
		dispatch(clearSendingPublicIntakeEmailError())
		props.handleClose()
	}

	return (
		<Modal onClose={handleClose} open={props.visible}>
			<FormPreviewModal
				canDelete={false}
				fileJson={formPreviewModalState.json}
				modalOpen={formPreviewModalState.open}
				name={formPreviewModalState.name}
				onCancel={() =>
					setFormPreviewModalState({
						...formPreviewModalState,
						open: false,
					})
				}
				readonly
			/>

			<Header
				content={`Send Public intake to ${clientName}`}
				icon='list alternate outline'
			/>

			<Modal.Content>
				<Form>
					<ToEmailAddress
						email={email}
						error={error}
						onChange={({ target }: IEvent) => setEmail(target.value)}
					/>

					<Header>Select Forms for Public Intake</Header>

					{forms.map((form) => {
						if (!form) {
							return null
						}

						if (typeof selectedForms[form.id] === 'undefined') {
							setSelectedForms({
								...selectedForms,
								[form.id]: true,
							})
						}

						return (
							<FormCheckboxContainer key={form.id}>
								<div>
									<Checkbox
										checked={selectedForms[form.id]}
										label={form.name}
										onChange={({ target }) =>
											setSelectedForms({
												...selectedForms,
												[form.id]: target.value,
											})
										}
									/>
								</div>
								<IconButton
									name='eye'
									noColour
									onClick={() => onFormPreview(form.form, form.name)}
									size='large'
								/>
							</FormCheckboxContainer>
						)
					})}
					{error.form && <ErrorText>{error.form}</ErrorText>}

					<Divider hidden />

					<Input
						autoFocus
						label='Passphrase'
						onChange={({ target }) => setPassphrase(target.value)}
						placeholder='Optional Passphrase you can set on the intake form'
						value={passphrase}
					/>
				</Form>

				<SubscriptionError send={onSubmit} />
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={handleClose} secondary>
					Cancel
				</Button>
				<Button
					disabled={!email}
					loading={fetching}
					onClick={onSubmit}
					onClickDisabled={validate}
					primary
				>
					Send Form
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

interface ICreatePublicIntakeModal {
	handleClose: Function
	visible?: boolean
}

interface IError {
	form?: string
	email?: string
}
