import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  H4,
  Icon,
  IconButton,
  Segment,
} from 'components/ui'
import React, { useState } from 'react'
import styled from 'styled-components'

const OrphanData = styled.div`
  display: flex;
  justify-content: space-between;

  :hover {
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }
`

const ButtonsContainer = styled.div`
  display: flex;
`

interface Props {
  onAddComponentBack: (node: any) => void
  orphanedData: any[]
}

export const OrphanedData = ({ onAddComponentBack, orphanedData }: Props) => {
  const [accordianIndex, setAccordianIndex] = useState(-1)

  const onAccordianClick = (_: any, { index }) => {
    const newIndex = accordianIndex === index ? -1 : index
    setAccordianIndex(newIndex)
  }

  if (
    !orphanedData ||
    orphanedData.length === 0 ||
    // HACK - Should never happen, but some old test data is crashing this view
    !Array.isArray(orphanedData)
  ) {
    return null
  }

  return (
    <div style={{ marginTop: '2.75em' }}>
      <Accordion style={{ width: '100%' }}>
        <AccordionTitle
          active={accordianIndex === 0}
          index={0}
          onClick={onAccordianClick}
        >
          <Icon name="dropdown" />
          Orphaned Data
        </AccordionTitle>

        <AccordionContent active={accordianIndex === 0}>
          <Segment>
            {orphanedData?.map((node) => (
              <OrphanData key={node.title}>
                <H4>{node.title}</H4>
                <ButtonsContainer>
                  {/* TODO - enable preview <IconButton name="eye" size="big" /> */}

                  <IconButton
                    name="plus circle"
                    onClick={() => onAddComponentBack(node)}
                    size="big"
                    style={{ marginLeft: '0.5em' }}
                  />
                </ButtonsContainer>
              </OrphanData>
            ))}
          </Segment>
        </AccordionContent>
      </Accordion>
    </div>
  )
}
