import { authDelete } from 'lib/http-request'
import { Client } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { ClientsState, IAction } from 'interfaces'
import { invalidateCacheLike } from 'lib'
import { ALL_CLIENTS_CACHE } from 'constants/cache'
import { message } from 'components/notifications'

const clientAction = (action: string) => `client-trackyr/clients/${action}`
const ARCHIVE_CLIENT = clientAction('ARCHIVE_CLIENT')
const ARCHIVE_CLIENT_SUCCESS = clientAction('ARCHIVE_CLIENT_SUCCESS')
const ARCHIVE_CLIENT_ERROR = clientAction('ARCHIVE_CLIENT_ERROR')

export const defaultState = {} as ClientsState

export const reducer = (
  state: ClientsState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case ARCHIVE_CLIENT:
      return {
        ...state,
        flags: {
          ...state.flags,
          archiving: true,
        },
      }

    case ARCHIVE_CLIENT_SUCCESS: {
      const allIDs = state.allIDs

      return {
        ...state,
        allIDs: allIDs.filter((id) => id !== action.id),
        clients: {
          ...state.clients,
          [action.id]: {
            ...state.clients[action.id],
            archived: true,
          },
        },
        flags: {
          ...state.flags,
          archiving: false,
        },
      }
    }

    case ARCHIVE_CLIENT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          archiving: false,
        },
      }

    default:
      return state
  }
}

export const archiveClient =
  (id: string): any =>
  async (dispatch: any): Promise<any> => {
    dispatch({ type: ARCHIVE_CLIENT, id })

    return authDelete(Client.Archive(id))
      .then((response) => {
        invalidateCacheLike(ALL_CLIENTS_CACHE)
        dispatch(archiveClientSuccess(id))
        message.success('Client Archived')
        return response
      })
      .catch((error) => {
        captureMessage(`archiveClient Failed. ${error}`, Severity.Error)
        dispatch(archiveClientFailure(error.message))
        message.error('Failed to Archive Client')
        return error
      })
  }

const archiveClientSuccess = (id: string) => ({
  type: ARCHIVE_CLIENT_SUCCESS,
  id,
})

const archiveClientFailure = (error: any) => ({
  type: ARCHIVE_CLIENT_ERROR,
  error,
})
