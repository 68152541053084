import { useEffect, useRef } from 'react'

export const debounce = (
  func?: Function,
  wait?: number,
  immediate?: boolean,
) => {
  let timeout: any

  return function () {
    const context = this,
      args = arguments

    const later = function () {
      timeout = null

      if (!immediate) {
        func.apply(context, args)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(context, args)
    }
  }
}

export const useDebounce = (callback: any, delay: number) => {
  const latestCallback: any = useRef()
  const latestTimeout: any = useRef()

  useEffect(() => {
    latestCallback.current = callback
  }, [callback])

  return () => {
    if (latestTimeout.current) {
      clearTimeout(latestTimeout.current)
    }

    latestTimeout.current = setTimeout(() => {
      latestCallback.current()
    }, delay)
  }
}
