import React from 'react'
import { Label, FormField, ErrorText } from 'components/ui'

import { PhoneNumber as PN } from 'components/phone-number/phone-number'
import { AutoSaveWrapper } from 'components/forms'
import {
	AutoSave,
	Validation,
	ValidationRules,
} from 'components/forms/interfaces'

interface PhoneNumberProps {
	autoFocus?: boolean
	autoSave?: AutoSave
	disabled?: boolean
	label: string
	name: string
	noType?: boolean
	onBlur?: Function
	onChange: any
	oneNumber?: boolean
	onValidate?: Function
	placeholder?: string
	readonly?: boolean
	size?: string
	style?: any
	validation?: Validation
	validationRules?: ValidationRules
	value?: Array<any>
}

const PhoneNumberForm = ({
													 autoSave,
													 label,
													 name,
													 noType,
													 onBlur,
													 onChange,
													 oneNumber,
													 onValidate,
													 readonly,
													 size,
													 style,
													 validation,
													 validationRules,
													 value,
												 }: PhoneNumberProps) => {
	let phoneNumbers

	try {
		phoneNumbers =
			typeof value === 'string' && value ? JSON.parse(value) : value
	} catch {
		phoneNumbers = []
	}

	return (
		<FormField
			required={!readonly && validationRules && validationRules.required}
			style={style}
			{...validation}
		>
			<Label>{label}</Label>

			<AutoSaveWrapper
				afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
				autosaveDisabled={autoSave.autosaveDisabled}
				endpoint={autoSave.patchEndpoint}
				entityID={autoSave.entityID}
				formType={autoSave.patchType}
				overWriteBlur={false}
				patch={autoSave.patch}
			>
				<PN
					name={name}
					noType={noType}
					onBlur={() => {
						onValidate && onValidate(validationRules, label, name, value)
						onBlur && onBlur({ target: { name, value } })
					}}
					onChange={onChange}
					oneNumber={oneNumber}
					phoneNumbers={phoneNumbers}
					readonly={readonly}
					size={size}
				/>
			</AutoSaveWrapper>

			{validation && validation.validateStatus === 'error' && (
				<ErrorText>{validation.help}</ErrorText>
			)}
		</FormField>
	)
}

PhoneNumberForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const PhoneNumber = React.memo(PhoneNumberForm)
