import styled from 'styled-components'
import { Font } from 'styles/font'

export const Label = styled.label<ILabel>`
  &&&&& {
    ${Font};
    font-size: 1.1em;
    font-weight: 700;

    @media print {
      font-size: 12px;
    }
  }

  label {
    ${Font};
    font-size: 1.1em;
    font-weight: 700;

    @media print {
      font-size: 12px;
    }
  }
`

export const CheckboxLabel = styled.label<ILabel>`
  &&&&& {
    ${Font};
    font-size: 1.15em;
    font-weight: 500;

    @media print {
      font-size: 12px;
      margin: 0;
    }
  }

  label {
    ${Font};
    font-size: 1.15em;
    font-weight: 500;

    @media print {
      font-size: 12px;
      margin: 0;
    }
  }
`

interface ILabel {
  required?: boolean
}
