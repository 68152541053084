import { authPost } from 'lib/http-request'
import {
  invalidateAppointmentCache,
  invalidateClientAppointmentCache,
} from 'lib/cache'
import { Appointments } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { updateTotalSoapAppointmentsCount } from 'containers/soap-notes'
import moment from 'moment'
import { IAppointment } from 'interfaces/appointments'
import { IAction, Dispatch, Error, IResponse } from 'interfaces'

const appointmentAction = (action: string) =>
  `client-trackyr/appointments/${action}`
const SAVE_APPOINTMENT = appointmentAction('SAVE_APPOINTMENT')
const SAVE_APPOINTMENT_SUCCESS = appointmentAction('SAVE_APPOINTMENT_SUCCESS')
const SAVE_APPOINTMENT_ERROR = appointmentAction('SAVE_APPOINTMENT_ERROR')

export const defaultState: IAppointmentState = {
  savingAppointment: false,
}

interface IAppointmentState {
  // TEMP - move to appointments interfaces/redux
  savingAppointment?: boolean // This stays here when the rest moves
  clientAppointments?: Object
  appointments?: Object
  allAppointmentsByID?: Array<any>
  appointmentsByMonth?: any
}

export const reducer = (
  state: IAppointmentState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case SAVE_APPOINTMENT: {
      return {
        ...state,
        savingAppointment: true,
      }
    }

    case SAVE_APPOINTMENT_SUCCESS: {
      const clientID = action.appointment.clientID
      const appointmentID = action.appointment.id
      const clientAppointments = new Set(
        state.clientAppointments[clientID] || [],
      )
      clientAppointments.add(appointmentID)

      const appointmentStart = moment(action.appointment.start)
      const appointmentsByMonthKey = `${appointmentStart.get('year')}${
        appointmentStart.get('month') + 1
      }`
      const appointmentsByMonth =
        state.appointmentsByMonth[appointmentsByMonthKey] || []
      appointmentsByMonth.push(action.appointment)

      return {
        ...state,
        appointments: {
          ...state.appointments,
          [appointmentID]: action.appointment,
        },
        clientAppointments: {
          ...state.clientAppointments,
          [clientID]: [...clientAppointments],
        },
        savingAppointment: false,
        selectedAppointment: action.appointment,
        allAppointmentsByID: [
          ...new Set([
            ...(state.allAppointmentsByID || []),
            ...clientAppointments,
          ]),
        ],
        appointmentsByMonth: {
          ...state.appointmentsByMonth,
          [appointmentsByMonthKey]: appointmentsByMonth,
        },
      }
    }

    case SAVE_APPOINTMENT_ERROR: {
      return {
        ...state,
        savingAppointment: false,
      }
    }

    default:
      return state
  }
}

export const saveAppointment =
  (appointment: IAppointment): any =>
  async (dispatch: Dispatch): Promise<IResponse<IAppointment>> => {
    dispatch({ type: SAVE_APPOINTMENT, appointment })

    return authPost(Appointments.Post, appointment)
      .then((response) => {
        const data = response.data
        dispatch(saveAppointmentSuccess(data))
        dispatch(updateTotalSoapAppointmentsCount(data.clientID))

        const startDate = moment(data.start)
        invalidateAppointmentCache(
          startDate.get('year'),
          startDate.get('month') + 1,
        )
        invalidateClientAppointmentCache(appointment.clientID)
        message.success('Appointment Saved')

        return response
      })
      .catch((error) => {
        dispatch(saveAppointmentError(error))
        message.error('Error saving appointment')

        captureMessage(`saveAppointment Failed. ${error}`, Severity.Error)

        throw error
      })
  }

const saveAppointmentSuccess = (appointment: IAppointment) => ({
  type: SAVE_APPOINTMENT_SUCCESS,
  appointment,
})

const saveAppointmentError = (error: Error) => ({
  type: SAVE_APPOINTMENT_ERROR,
  error,
})
