import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authPost } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'
import { message } from 'components/notifications'
import { IResponse, Error } from 'interfaces/fetch'
import { IAction, PublicIntakeState } from 'interfaces'

const action = (action: string) => `client-trackyr/public-intake/${action}`
const RESEND_PUBLIC_INTAKE_EMAIL = action('RESEND_PUBLIC_INTAKE_EMAIL')
const RESEND_PUBLIC_INTAKE_EMAIL_SUCCESS = action(
  'RESEND_PUBLIC_INTAKE_EMAIL_SUCCESS',
)
const RESEND_PUBLIC_INTAKE_EMAIL_FAILURE = action(
  'RESEND_PUBLIC_INTAKE_EMAIL_FAILURE',
)

export const defaultState = {} as PublicIntakeState

export const reducer = (
  state: PublicIntakeState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case RESEND_PUBLIC_INTAKE_EMAIL:
      return {
        ...state,
        sendingPublicIntakeEmail: true,
        sendingPublicIntakeEmailError: { hasError: false },
      }

    case RESEND_PUBLIC_INTAKE_EMAIL_SUCCESS:
      return {
        ...state,
        sendingPublicIntakeEmail: false,
      }

    case RESEND_PUBLIC_INTAKE_EMAIL_FAILURE:
      return {
        ...state,
        sendingPublicIntakeEmail: false,
        sendingPublicIntakeEmailError: {
          hasError: true,
          message: 'Failed to load all forms',
          details: action.error,
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
const resendPublicIntakeFormEmail = (
  publicIntakeForm: any,
  ignoreSubscription = false,
): any => async (dispatch: any): Promise<any> => {
  dispatch({
    type: RESEND_PUBLIC_INTAKE_EMAIL,
    publicIntakeForm,
    ignoreSubscription,
  })

  return authPost(
    PublicIntake.ResendEmail(ignoreSubscription),
    publicIntakeForm,
  )
    .then((response: IResponse<any>) => {
      message.success('Email Sent!')
      dispatch(resendSuccess(response.data))
      return response
    })
    .catch((error: Error) => {
      if (error.response.status === 403) {
        dispatch(resendFailure(error))
        throw error
      }

      message.error('Email failed to send')
      captureMessage(
        `resendPublicIntakeFormEmail Get Failed: ${error}`,
        Severity.Error,
      )

      throw error
    })
}

const resendSuccess = (data: any) => ({
  type: RESEND_PUBLIC_INTAKE_EMAIL_SUCCESS,
  data,
})

const resendFailure = (error: any) => ({
  type: RESEND_PUBLIC_INTAKE_EMAIL_FAILURE,
  error,
})

export { resendPublicIntakeFormEmail }
