import React from 'react'

import {
	Button,
	ButtonContainer,
	CancelSubmitWithDelete,
	Header,
	Modal,
	Text,
} from 'components/ui'
import { IEvent, IFormItem } from 'interfaces'

// import { FormGeneratorListItem } from '../interfaces'

interface Props {
	description?: string
	form: any
	header?: string
	// item: FormGeneratorListItem
	// onChange?: (e: IEvent) => void
	onClose: () => void
	open: boolean
	renderer?: (formItems: Array<IFormItem>) => any
}

export const ListItemModal = (props: Props) => {
	// const [isDirty, setDirty] = useState(false)

	const onSave = () => {
		// props.onChange()
	}

	// const onChange = ({ target }: IEvent) => {
	//   setDirty(true)
	//   props.onChange({ target })
	// }

	const onClose = () => {
		props.onClose
	}

	return (
		<Modal
			closeIcon
			closeOnDimmerClick={false}
			onClose={props.onClose}
			open={props.open}
		>
			<Header content={props.header} />

			<Modal.Content>
				<Text>{props.description}</Text>
				{props.renderer(props.form)}
			</Modal.Content>

			<Modal.Actions>
				<ButtonContainer>
					<Button content='Delete' icon='trash' negative onClick={() => {
					}}
					/>
					<CancelSubmitWithDelete deleteActive={true}>
						<Button
							content={'Close'}
							icon='arrow alternate circle left'
							onClick={onClose}
							secondary
						/>
						<Button content='Save' icon='checkmark' onClick={onSave} primary />
					</CancelSubmitWithDelete>
				</ButtonContainer>
			</Modal.Actions>
		</Modal>
	)
}
