import React from 'react'
import { TextArea as TA, Label, FormField } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'

import { InputProps, InputSizeProps } from 'components/forms/interfaces'

export const TextArea = ({
													 autoFocus = false,
													 autoSave,
													 label,
													 name,
													 onChange,
													 onValidate,
													 placeholder,
													 printParams,
													 readonly = false,
													 // size = 'small',
													 validation = {},
													 validationRules = {},
													 value,
												 }: InputProps & InputSizeProps) =>
	// TODO - do this to all form components
	readonly && !value ? null : (
		<FormField
			required={!readonly && validationRules && validationRules.required}
			{...validation}
		>
			<Label>{label}</Label>

			<AutoSaveWrapper
				afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
				autosaveDisabled={autoSave.autosaveDisabled}
				autosaveMode={autoSave.autosaveMode}
				endpoint={autoSave.patchEndpoint}
				entityID={autoSave.entityID}
				formType={autoSave.patchType}
				patch={autoSave.patch}
			>
				<TA
					autoFocus={autoFocus}
					name={name}
					onBlur={
						onValidate
							? () => onValidate(validationRules, label, name, value)
							: null
					}
					onChange={onChange}
					placeholder={placeholder || label}
					printParams={printParams}
					readonly={readonly}
					// size={size}
					value={value}
				/>
			</AutoSaveWrapper>
		</FormField>
	)

TextArea.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
	validation: {},
}
