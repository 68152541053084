import { IClient } from 'interfaces'

export const sortClients = (
  a: IClient,
  b: IClient,
  sortOrder: 'firstName' | 'lastName',
): number => {
  const nameA =
    sortOrder === 'firstName'
      ? `${a.firstName} ${a.middleName} ${a.lastName}`
      : `${a.lastName} ${a.middleName} ${a.firstName}`

  const nameB =
    sortOrder === 'firstName'
      ? `${b.firstName} ${b.middleName} ${b.lastName}`
      : `${b.lastName} ${b.middleName} ${b.firstName}`

  if (nameA < nameB) {
    return -1
  }

  if (nameA > nameB) {
    return 1
  }

  return 0
}
