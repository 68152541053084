import React from 'react'
import { connect } from 'react-redux'

import { TextArea, Dropdown } from 'components/forms'
import { Form, Button, Modal, Header, Icon, Divider, DrawIcon } from 'components/ui'
import { getSelectedAppointmentID$ } from 'containers/appointments'
import { getSelectedClientID$ } from 'containers/clients'
import { setSoapDirty } from 'containers/soap-notes'
import { debounce } from 'lib/debounce'
import { parseShapesToAPI } from 'lib/fetch-parsers'
import { FETCHING, SUCCESS, FAILURE } from 'components/forms'
import { ClearModal } from 'components/modals'

import {
  onShapesNotesChanged,
  onShapesColourChanged,
  deleteShape,
  getShapesForSoap$,
  drawColours,
  setDrawAutoSaving,
} from 'containers/draw'
import { IState, Dispatch, IResponse, IEvent } from 'interfaces'

interface Shape {
  colour: string
  id: string
  notes: string
  order: number
  shapeType: 'Lines' | 'Circle' | 'Square'
}

interface Props {
  appointmentID: string
  clientID: string
  deleteShape: Function
  modalOpen: boolean
  onCancel: Function
  onChangeCallback: Function
  onShapesColourChanged: Function
  onShapesNotesChanged: Function
  readonly: boolean
  setDrawAutoSaving: Function
  setSoapDirty: Function
  shape: Shape
  shapes: Array<Shape>
}

class DrawNoteModal extends React.Component<Props> {
  public static defaultProps: Partial<Props> = {
    modalOpen: false,
    onCancel: () => {},
  }

  state = {
    deleteModalOpen: false,
  }

  renderDeleteModal = () => {
    const modalProps = {
      modalOpen: this.state.deleteModalOpen,
      message: 'Are you sure you want to delete this shape?',
      onAccept: () => {
        this.props.deleteShape(this.props.shape.id, this.props.appointmentID)
        this.props.setSoapDirty(this.props.appointmentID, this.props.clientID)
        this.props.onCancel()
        this.props.onChangeCallback && this.debounceOnAfterSave()
        this.setState(() => ({ deleteModalOpen: false }))
      },
      onCancel: () => this.setState(() => ({ deleteModalOpen: false })),
    }

    return <ClearModal {...modalProps} />
  }

  debounceOnAfterSave = debounce(() => {
    this.props.setDrawAutoSaving(FETCHING)

    this.props.onChangeCallback &&
      this.props
        .onChangeCallback({
          target: {
            name: 'shapes',
            value: JSON.stringify(parseShapesToAPI(this.props.shapes)),
          },
        })
        .then((response: IResponse<any>) =>
          this.props.setDrawAutoSaving(
            response.status === 204 ? SUCCESS : FAILURE,
          ),
        )
        .catch(() => this.props.setDrawAutoSaving(FAILURE))
  }, 1500)

  render() {
    return (
      <>
        {this.renderDeleteModal()}

        <Modal
          closeOnDimmerClick
          onClose={this.props.onCancel}
          open={this.props.modalOpen}
          size="small"
        >
          <Header
            content={`${this.props.shape.shapeType} #${this.props.shape.order}`}
            icon="edit"
          />
          <Modal.Content>
            <Form>
              {!this.props.readonly ?(
                <Dropdown
                  disabled={this.props.readonly}
                  label='Colour'
                  onChange={({ target }) => {
                    this.props.onShapesColourChanged(
                      this.props.shape.id,
                      this.props.appointmentID,
                      target.value,
                    )
                    this.props.setSoapDirty(
                      this.props.appointmentID,
                      this.props.clientID,
                    )
                    this.props.onChangeCallback && this.debounceOnAfterSave()
                  }}
                  options={drawColours.map((option) => ({
                    key: option.colour,
                    id: option.colour,
                    text: option.name,
                    value: option.colour,
                  }))}
                  search
                  selection
                  style={{
                    height: '2.8em',
                  }}
                  value={this.props.shape.colour}
                />) : (
                  <DrawIcon colour={this.props.shape.colour} shape={this.props.shape.shapeType} />
                )}

              <Divider hidden />

              <TextArea
                autoFocus
                label="Notes"
                onChange={(e: IEvent) => {
                  this.props.onShapesNotesChanged(
                    this.props.shape.id,
                    this.props.appointmentID,
                    e.target.value,
                  )
                  this.props.setSoapDirty(
                    this.props.appointmentID,
                    this.props.clientID,
                  )
                  this.props.onChangeCallback && this.debounceOnAfterSave()
                }}
                placeholder={`Notes for ${this.props.shape.shapeType} #${this.props.shape.order}`}
                readonly={this.props.readonly}
                style={{ marginLeft: '1.9em', marginTop: '0.5em' }}
                value={this.props.shape.notes}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                disabled={this.props.readonly}
                negative
                onClick={() => this.setState(() => ({ deleteModalOpen: true }))}
              >
                <Icon name="trash alternate outline" /> Remove
              </Button>

              <Button onClick={this.props.onCancel} primary>
                <Icon name="checkmark" /> Done
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  appointmentID: getSelectedAppointmentID$(state),
  clientID: getSelectedClientID$(state),
  shapes: getShapesForSoap$(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onShapesNotesChanged: (
    shapeID: string,
    appointmentID: string,
    note: string,
  ) => dispatch(onShapesNotesChanged(shapeID, appointmentID, note)),
  onShapesColourChanged: (
    shapeID: string,
    appointmentID: string,
    colour: string,
  ) => dispatch(onShapesColourChanged(shapeID, appointmentID, colour)),
  setSoapDirty: (appointmentID: string, clientID: string) =>
    dispatch(setSoapDirty(appointmentID, clientID)),
  deleteShape: (id: string, appointmentID: string) =>
    dispatch(deleteShape(id, appointmentID)),
  setDrawAutoSaving: (status: 'SUCCESS' | 'FAILURE' | 'FETCHING' | 'NONE') =>
    dispatch(setDrawAutoSaving(status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawNoteModal)
