import React from 'react'
import { Text } from './text'
import styled from 'styled-components'

const AddressText = styled(Text)`
  padding-bottom: 0;
  margin-bottom: 0;
`

const SingleLineAddress = styled.div`
  display: flex;
`

interface Props {
  addressOne?: string
  addressTwo?: string
  city?: string
  province?: string
  postalCode?: string
  country?: string
}

export const SmallAddress = (props: Props) => {
  const provincePostalCountry = []
  props.province && provincePostalCountry.push(props.province)
  props.postalCode && provincePostalCountry.push(props.postalCode)
  props.country && provincePostalCountry.push(props.country)

  const provincePostalCountryLine =
    provincePostalCountry.length > 1
      ? provincePostalCountry.join(', ')
      : provincePostalCountry[0]

  return (
    <>
      <SingleLineAddress>
        {props.addressTwo && (
          <AddressText>{props.addressTwo}&nbsp;-&nbsp;</AddressText>
        )}
        <AddressText>{props.addressOne}</AddressText>
      </SingleLineAddress>

      <AddressText>{props.city}</AddressText>

      <SingleLineAddress>
        <AddressText>{provincePostalCountryLine}</AddressText>
      </SingleLineAddress>
    </>
  )
}
