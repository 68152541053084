import React from 'react'

import { Button, Modal, Header, Icon, Text } from 'components/ui'

const ConfirmCancelModal = (props: IConfirmCancelModal) => {
  return (
    <Modal onClose={props.onCancel} open={props.modalOpen} size="small">
      <Header content={props.message} icon="warning circle" />
      {props.content && (
        <Modal.Content>
          <Text>{props.content}</Text>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button onClick={props.onCancel} secondary>
          <Icon name="arrow alternate circle left" /> No
        </Button>
        <Button
          loading={props.loading}
          negative={!props.gentle}
          onClick={props.onAccept}
          primary={props.gentle}
        >
          <Icon name={props.gentle ? 'checkmark' : 'ban'} /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

interface IConfirmCancelModal {
  content?: string
  loading?: boolean
  message: string
  modalOpen: boolean
  onAccept: Function
  onCancel: Function
  gentle?: boolean
}

ConfirmCancelModal.defaultProps = {
  modalOpen: false,
  onCancel: () => {},
}

export default ConfirmCancelModal
