import { authGet } from 'lib/http-request'
import { Client, getQueryParams } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { normalizeArray, parseClientsPhoneNumbersFromAPI } from 'lib'
import { ALL_CLIENTS_CACHE, ONE_HOUR } from 'constants/cache'
import { IQueryParams } from 'interfaces/fetch'
import { IClient, ClientsState, IAction } from 'interfaces'

const clientAction = (action: string) => `client-trackyr/clients/${action}`
const FETCH_ALL_CLIENTS = clientAction('FETCH_ALL_CLIENTS')
const FETCH_ALL_CLIENTS_SUCCESS = clientAction('FETCH_ALL_CLIENTS_SUCCESS')
const FETCH_ALL_CLIENTS_ERROR = clientAction('FETCH_ALL_CLIENTS_ERROR')
const FETCH_ALL_CLIENTS_ERROR_FORBIDDEN = clientAction(
  'FETCH_ALL_CLIENTS_ERROR_FORBIDDEN',
)

export const defaultState = {
  totalClients: 0,
} as ClientsState

export const reducer = (
  state: ClientsState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case FETCH_ALL_CLIENTS_SUCCESS: {
      const normalizedClients = normalizeArray(
        parseClientsPhoneNumbersFromAPI(action.data.items),
        'clients',
      )

      if (!normalizedClients.clients || !normalizedClients.allIDs) {
        return {
          ...state,
          loadingAllClients: false,
        }
      }

      return {
        ...state,
        clients: {
          ...state.clients,
          ...normalizedClients.clients,
        },
        allIDs: [...new Set([...state.allIDs, ...normalizedClients.allIDs])],
        totalClients: action.data.totalCount,
        loadingAllClients: false,
        allClientsFetched: true,
        verifyEmailError: '',
      }
    }

    case FETCH_ALL_CLIENTS:
      return {
        ...state,
        loadingAllClients: true,
        verifyEmailError: '',
      }

    case FETCH_ALL_CLIENTS_ERROR:
      return {
        ...state,
        loadingAllClientsError: action.error,
        loadingAllClients: false,
        verifyEmailError: '',
      }

    case FETCH_ALL_CLIENTS_ERROR_FORBIDDEN:
      return {
        ...state,
        loadingAllClients: false,
        verifyEmailError: action.errorMsg,
      }

    default:
      return state
  }
}

export const fetchAllClients = (queryParams: IQueryParams = {}): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_ALL_CLIENTS, queryParams })

  return authGet(Client.AllClients(queryParams), {
    cache: true,
    cacheDir: `${ALL_CLIENTS_CACHE}${getQueryParams(queryParams)}`,
    cacheMaxAge: ONE_HOUR,
  })
    .then((response) => {
      dispatch(fetchAllClientsSuccess(response.data))
      return response
    })
    .catch((error) => {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data === 'Email Address Not Verified'
      ) {
        dispatch(fetchAllClientsFailureForbidden(error.response.data))
        return
      }

      captureMessage(`fetchAllClients Failed. ${error}`, Severity.Error)

      dispatch(fetchAllClientsFailure(error.message))
      return error
    })
}

const fetchAllClientsSuccess = (data: Array<IClient>) => ({
  type: FETCH_ALL_CLIENTS_SUCCESS,
  data,
})

const fetchAllClientsFailure = (error: any) => ({
  type: FETCH_ALL_CLIENTS_ERROR,
  error,
})

const fetchAllClientsFailureForbidden = (errorMsg: string) => ({
  type: FETCH_ALL_CLIENTS_ERROR_FORBIDDEN,
  errorMsg,
})
