import React from 'react'

import { Label, FormField, RadioGroup, RadioButton } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps } from 'components/forms/interfaces'

interface RadioProps {
	options: Array<any>
	// size?: string
}

const RadioForm = ({
										 autoSave,
										 disabled,
										 label,
										 name,
										 onChange,
										 // onValidate,
										 options,
										 printParams,
										 readonly,
										 // size = 'small',
										 validation,
										 validationRules,
										 value,
									 }: InputProps & RadioProps) => (
	<FormField
		{...validation}
		required={!readonly && validationRules && validationRules.required}
	>
		<Label>{label}</Label>

		<AutoSaveWrapper
			afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
			autosaveDisabled={autoSave.autosaveDisabled}
			debounceTime={250}
			endpoint={autoSave.patchEndpoint}
			entityID={autoSave.entityID}
			formType={autoSave.patchType}
			patch={autoSave.patch}
		>
			<RadioGroup
				disabled={disabled}
				name={name}
				onChange={onChange}
				printParams={printParams}
				readonly={readonly}
				// size={size}
				value={value}
			>
				{options.map((radioItem, index) => (
					<RadioButton key={index} value={radioItem}>
						{radioItem}
					</RadioButton>
				))}
			</RadioGroup>
		</AutoSaveWrapper>
	</FormField>
)

RadioForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const Radio = React.memo(RadioForm)
