import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { Receipts } from 'containers/receipts'
import { Dispatch } from 'interfaces'
import { AuditReports } from './audit-reports'
import { ContainerLayout, Tab } from 'components/ui'
import { setNavBarText } from 'components/nav'
import { InvoiceReports } from './invoices/invoice-reports'

interface Params {
	tab?: string
}

const TABS = ['invoices', 'reports', 'audits']
const NAV_BAR = ['Invoices', 'Reports', 'Audits']

export const ReportsView = () => {
	const dispatch: Dispatch = useDispatch()
	const params: Params = useParams()
	const navigate = useNavigate()

	let activeIndex = TABS.findIndex((tab) => tab === params.tab)

	useEffect(() => {
		dispatch(setNavBarText(NAV_BAR[activeIndex]))
	}, [dispatch, activeIndex])

	if (activeIndex === -1) {
		activeIndex = 0
	}

	const panes = [
		{
			menuItem: {
				key: 'Invoices',
				icon: 'print',
				content: 'Invoices',
			},
			render: () => <Receipts />,
		},
		{
			menuItem: {
				key: 'Reports',
				icon: 'chart bar',
				content: 'Reports',
			},
			render: () => <InvoiceReports />,
		},
		{
			menuItem: {
				key: 'Audits',
				icon: 'clipboard list',
				content: 'Audits',
			},
			render: () => <AuditReports />,
		},
	]

	return (
		<ContainerLayout>
			<Tab
				activeIndex={activeIndex}
				// highlight
				menu={{ secondary: true, pointing: true, attached: false }}
				onTabChange={(_, { activeIndex }) => {
					navigate(`/reports/${TABS[activeIndex]}`)
				}}
				panes={panes}
				responsive={false}
			/>
		</ContainerLayout>
	)
}
