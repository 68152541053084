import { authGet } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { ONE_HOUR } from 'constants/cache'
import { IAction } from 'interfaces'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const FETCH_APPOINTMENT_FILES = filesAction('FETCH_APPOINTMENT_FILES')
const FETCH_APPOINTMENT_FILES_SUCCESS = filesAction(
  'FETCH_APPOINTMENT_FILES_SUCCESS',
)
const FETCH_APPOINTMENT_FILES_FAILURE = filesAction(
  'FETCH_APPOINTMENT_FILES_FAILURE',
)

export const defaultState = {
  appointmentFiles: {},
  flags: {
    fetchingAppointmentFiles: false,
    appointmentFilesError: '',
  },
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_APPOINTMENT_FILES: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetchingAppointmentFiles: true,
          appointmentFilesError: '',
        },
      }
    }

    case FETCH_APPOINTMENT_FILES_SUCCESS: {
      const files = action.files

      const filesForAppointment = []

      for (const file of files) {
        const { userID, ...rest } = file.blobStorageFile // eslint-disable-line no-unused-vars
        filesForAppointment.push(rest)
      }

      return {
        ...state,
        appointmentFiles: {
          ...state.appointmentFiles,
          [action.entityID]: filesForAppointment,
        },
        flags: {
          ...state.flags,
          fetchingAppointmentFiles: false,
          appointmentFilesError: '',
        },
      }
    }

    case FETCH_APPOINTMENT_FILES_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          fetchingAppointmentFiles: false,
          appointmentFilesError: action.error,
        },
      }

    default:
      return state
  }
}

export const fetchAppointmentFiles = (
  clientID: string,
  entityID: string,
  useCache = false,
): any => async (dispatch: any): Promise<any> => {
  dispatch({ type: FETCH_APPOINTMENT_FILES, clientID, entityID, useCache })

  return authGet(Storage.ClientForm(clientID, entityID), {
    cache: useCache,
    cacheMaxAge: ONE_HOUR,
  })
    .then((response) => {
      dispatch(fetchAppointmentFilesSuccess(response.data, clientID, entityID))
      return response
    })
    .catch((error) => {
      captureMessage(
        `Failed to fetch Client Files. ClientID: ${clientID}. ${error}`,
        Severity.Error,
      )
      dispatch(fetchAppointmentFilesFailure(error))
      return error
    })
}

const fetchAppointmentFilesSuccess = (
  files: Array<any>,
  clientID: string,
  entityID: string,
) => ({
  type: FETCH_APPOINTMENT_FILES_SUCCESS,
  files,
  clientID,
  entityID,
})

const fetchAppointmentFilesFailure = (error: any) => ({
  type: FETCH_APPOINTMENT_FILES_FAILURE,
  error,
})
