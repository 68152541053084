import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authGet } from 'lib/http-request'
import { Features } from 'constants/endpoints'
import { Options, IAction } from 'interfaces'
import { IQueryParams } from 'interfaces/fetch'

const action = (action: string) => `client-trackyr/features/${action}`
const FETCH_USER_FEATURES = action('FETCH_USER_FEATURES')
const FETCH_USER_FEATURES_SUCCESS = action('FETCH_USER_FEATURES_SUCCESS')
const FETCH_USER_FEATURES_FAILURE = action('FETCH_USER_FEATURES_FAILURE')

export const defaultState = {
  userFeatures: {},
  fetchingUserFeatures: true,
  fetchingUserFeaturesError: { error: false },
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_USER_FEATURES:
      return {
        ...state,
        fetchingUserFeatures: true,
      }

    case FETCH_USER_FEATURES_SUCCESS: {
      const userFeatures = {}

      action.response.items.map((i: any) => (userFeatures[i.key] = i.value))

      return {
        ...state,
        fetchingUserFeatures: false,
        userFeatures: {
          ...state.userFeatures,
          [action.id]: userFeatures,
        },
      }
    }

    case FETCH_USER_FEATURES_FAILURE:
      return {
        ...state,
        fetchingUserFeatures: false,
        fetchingUserFeaturesError: {
          error: true,
          message: 'Failed to fetch user features',
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
// TODO - load fron indexDB, but also fetch
const fetchUserFeatures = (
  id: string,
  queryParams?: IQueryParams,
): any => async (dispatch: any): Promise<any> => {
  dispatch({ type: FETCH_USER_FEATURES, id, queryParams })

  return authGet(Features.GetUserFeatures(id), {} as Options, queryParams)
    .then((response) => {
      dispatch(fetchSuccess(response.data, id))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(`fetchUserFeatures Get Failed: ${error}`, Severity.Error)

      throw error
    })
}

const fetchSuccess = (response: any, id: string) => ({
  type: FETCH_USER_FEATURES_SUCCESS,
  response,
  id,
})

const fetchFailure = (error: any) => ({
  type: FETCH_USER_FEATURES_FAILURE,
  error,
})

export { fetchUserFeatures }
