export const CALENDAR_COLOURS = [
  {
    colour: '#825ff4',
    borderColour: '#825ff4',
  },
  {
    colour: '#a25ff4',
    borderColour: '#a25ff4',
  },
  {
    colour: '#cc5ff4',
    borderColour: '#cc5ff4',
  },
  {
    colour: '#619cf6',
    borderColour: '#619cf6',
  },
  {
    colour: '#5fb6f4',
    borderColour: '#5fb6f4',
  },
  {
    colour: '#5ff4ef',
    borderColour: '#5ff4ef',
  },
  {
    colour: '#5ff473',
    borderColour: '#5ff473',
  },
  {
    colour: '#ccf45f',
    borderColour: '#ccf45f',
  },
  {
    colour: '#f4d95f',
    borderColour: '#f4d95f',
  },
  {
    colour: '#f49b5f',
    borderColour: '#f49b5f',
  },
  {
    colour: '#f4665f',
    borderColour: '#f4665f',
  },
]

export const DEFAULT_EVENT_COLOUR = '#5fb6f4'
