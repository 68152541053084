import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Checkbox } from 'components/forms'
import { Dispatch, IEvent, IState } from 'interfaces'
import { SUMMARY_TAB, FILES_TAB } from 'constants/tabs'
import { onSetEdit } from 'containers/clients/client-info/redux/ducks'

export const EditSwitch = () => {
	const params: Params = useParams()

	const dispatch: Dispatch = useDispatch()
	const editingTab =
		params.tab === 'client-info'
			? 'client'
			: params.tab === 'notes'
				? 'soap'
				: 'rest'

	const onChange = ({ target }: IEvent) =>
		dispatch(onSetEdit(editingTab, target.value))
	const flags = useSelector((state: IState) => state.clients.flags)

	if (params.tab === SUMMARY_TAB || params.tab === FILES_TAB) {
		return null
	}

	return (
		<Checkbox
			label='Edit'
			name='edit'
			onChange={onChange}
			toggle
			value={flags[`${editingTab}Editing`]}
		/>
	)
}

interface Params {
	tab?: string
}
