import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'

import {
  Avatar,
  ListItem,
  ListIcon,
  ListContent,
  ListHeader,
  ListDescription,
  Icon,
} from 'components/ui'
import { IFile, IState } from 'interfaces'

const ListItemButton = styled(ListItem)`
  &&&&& {
    margin: auto;
    background-color: ${(props) =>
      props.selected ? (props) => props.theme.ListItemActiveColour : null};
    padding: 0.5em 0;
    border-radius: '5px';

    :hover {
      cursor: pointer;
    }
  }
`
const FileItem = (props: IFileItem) => {
  const fetchingFile = useSelector((state: IState) => state.files.fetchingFile)

  return (
    <ListItemButton
      key={props.file.id}
      onClick={() => props.onClick(props.file)}
      selected={props.selected.id === props.file.id}
    >
      {!props.hideIcon && (
        <ListIcon>
          {fetchingFile === props.file.id ? (
            <Avatar size={'40px'}>
              <Icon loading name="spinner" size="big" />
            </Avatar>
          ) : (
            <Avatar size={'40px'}>
              <Icon
                name={props.getContentType(props.file)}
                size="big"
                style={{ margin: 'auto' }}
              />
            </Avatar>
          )}
        </ListIcon>
      )}

      <ListContent>
        <ListHeader as="a">{props.file.fileName}</ListHeader>
        <ListDescription>{`${moment(props.file.dateCreated).format(
          'MMMM D YYYY hh:mm:a',
        )}`}</ListDescription>
        {props.clientName && (
          <ListDescription>{`Client File: ${props.clientName}`}</ListDescription>
        )}
      </ListContent>
    </ListItemButton>
  )
}

interface IFileItem {
  clientName?: string
  file: IFile
  getContentType: Function
  hideIcon?: boolean
  onClick: Function
  selected: any
}

export { FileItem }
