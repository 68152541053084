import React from 'react'
import ErrorAlert from 'components/error'
import styled from 'styled-components'

import { Button, Input, Modal, Header, Text } from 'components/ui'
import { IEvent } from 'interfaces'
import { isEmailValid } from 'lib'

const ErrorAlertDiv = styled.div`
  padding-top: 1em;
`

interface Props {
  email: string
  errorMsg: string
  hasError: boolean
  loading: boolean
  onCancel: () => void
  onEmailChanged: (e: IEvent) => void
  onSubmit: () => void
  success: boolean
  visible: boolean
}

const ForgotPasswordModal = ({
  email,
  onEmailChanged,
  visible,
  loading,
  onCancel,
  onSubmit,
  success,
  hasError,
  errorMsg,
}: Props) => (
  <Modal onClose={onCancel} open={visible} size="small">
    <Header content="Reset Password" icon="question" />
    <Modal.Content>
      {success ? (
        <Text>Success! Check your email for the reset link</Text>
      ) : (
        <div>
          <Text>
            Enter your email address and we&apos;ll send you a password reset
            email
          </Text>
          <Input
            icon="mail"
            onChange={onEmailChanged}
            placeholder="Email Address"
            style={{ width: '100%' }}
            value={email}
          />
          <ErrorAlertDiv>
            <ErrorAlert errorMsg={errorMsg} hasError={hasError} />
          </ErrorAlertDiv>
        </div>
      )}
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onCancel} secondary>
        Cancel
      </Button>
      <Button
        disabled={!email || !isEmailValid(email)}
        loading={loading}
        onClick={success ? onCancel : onSubmit}
        primary
      >
        {success ? 'Close' : 'Submit'}
      </Button>
    </Modal.Actions>
  </Modal>
)

export default ForgotPasswordModal
