export const formatAmount = (amount: number): string => {
  if (!amount) {
    return '0.00'
  }

  const amt = `${amount}`

  const valueLength = amt.split('.').length > 1 && amt.split('.')[1]?.length

  if (!amt.includes('.')) {
    return `${amt}.00`
  } else if (valueLength === 1) {
    return `${amt}0`
  } else if (valueLength > 2) {
    return amt.substring(0, amt.length - (valueLength - 2))
  }

  return amt
}

export const formatTaxAmount = (amount: number): string => {
  const strAmount = `${amount}`

  if (strAmount.includes('.')) {
    const wholeNumberAmount = strAmount.toString().split('.')[0]
    const decimalAmount = strAmount.toString().split('.')[1]

    if (wholeNumberAmount !== '0') {
      return strAmount
    }

    return `${Number(decimalAmount)}`
  }

  return strAmount
}
