import React, { useState } from 'react'

import { IEvent, IState } from 'interfaces'
import { ErrorText, LinkText, Text } from 'components/ui'
import { isEmailValid } from 'lib'
import { Input, Checkbox } from 'components/forms'
import { getSetting } from 'containers/settings'
import { DEFAULT_SEND_APPOINTMENT_EMAIL } from 'constants/settings'
import { useSelector } from 'react-redux'

interface Props {
  email: string
  error?: {
    email?: string
  }
  name?: string
  onChange: (e: IEvent) => void
  onToggleChange?: (e: IEvent) => void
  readonly?: boolean
  toggleText?: string
  toggleValue?: boolean
  withToggle?: boolean
  hideExtraLabel?: boolean
}

export const ToEmailAddress = (props: Props) => {
  const [error, setError] = useState('')
  const [editing, setEditing] = useState(false)

  const toggleStartingValue = useSelector(
    (state: IState) =>
      getSetting(state, DEFAULT_SEND_APPOINTMENT_EMAIL) === 'true',
  )

  const onBlurValidateEmail = ({ target }: IEvent) => {
    if (!isEmailValid(target.value)) {
      setError('Invalid Email Address')
    } else {
      setError('')
    }
  }

  const onEditClick = () => setEditing(true)

  const errorState = props.error?.email || error
  const toggleValue = props.toggleValue || toggleStartingValue
  const displayEmailRender = props.withToggle
    ? toggleStartingValue || toggleValue
    : true

  return (
    <>
      {props.withToggle && (
        <Checkbox
          label={props.toggleText}
          name={props.name}
          onChange={props.onToggleChange}
          toggle
          value={toggleValue}
        />
      )}

      {displayEmailRender && (
        <>
          {editing ? (
            <Input
              autoFocus
              label="Client Email"
              name="email"
              onBlur={onBlurValidateEmail}
              onChange={props.onChange}
              type="email"
              value={props.email}
            />
          ) : props.hideExtraLabel ? (
            <Text>{`${props.email}`}</Text>
          ) : (
            <Text>{`Sending to Email Address: ${props.email}`}</Text>
          )}

          {errorState && <ErrorText>{errorState}</ErrorText>}

          {!props.readonly && !editing && (
            <LinkText onClick={onEditClick}>Change Email</LinkText>
          )}
        </>
      )}
    </>
  )
}
