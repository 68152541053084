import React, { useState } from 'react'
import styled from 'styled-components'
import { Font, MobileBreakPoint, Colours } from 'styles'
import { H2 } from 'components/ui'
import { Icon } from 'components/ui/icon'
import { Print } from './interfaces'

const C = styled.div`
  border: 1px solid ${(props) => props.theme.CardBorderColour};
  font-feature-settings: 'tnum';
  font-variant: tabular-nums;
  line-height: 1.5;
  color: ${(props) => props.theme.DarkIconColour};
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: ${(props) => props.theme.BackgroundColour};
  border-radius: 2px;
  position: relative;
  transition: all 0.3s;

  margin-top: 1em;
  margin-bottom: 1em;

  box-shadow: 0 0 5px ${(props) => props.theme.CardShadowColour};

  width: 100%;

  @media print {
    border: 1px solid ${(props) => props.theme.LineColour};
    box-shadow: none;
  }
`

interface CH {
	collapsible?: boolean
	noBorder?: boolean
}

const CardHead = styled.div<CH>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: transparent;
    /* border-bottom: ${(props) =>
          props.noBorder ? null : `1px solid ${props.theme.CardBorderColour}`}; */
  border-radius: 2px 2px 0 0;
  min-height: 48px;
  font-size: 16px;
  color: ${(props) => props.theme.TextColour};
  font-weight: 500;
  width: 100%;

  :hover {
    ${(props) => props.collapsible && 'cursor: pointer'};
  }

  @media print {
    border-bottom: ${(props) =>
            props.noBorder ? null : `1px solid ${props.theme.LineColour}`};
    margin-bottom: 1em;
    text-decoration: underline;
  }
`

interface CHT {
	hasExtra?: boolean
}

const CardHeadTitle = styled.div<CHT>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em;
  margin-top: ${(props) => (props.hasExtra ? '0.75em' : 'auto')};
  padding-left: 1.2em;
  text-overflow: ellipsis;

  width: 100%;

  background-color: ${(props) => props.theme.BackgroundColourDark};
  color: ${(props) => props.theme.TextColourDark};
`

const CardExtra = styled.div`
  margin: auto;
  margin-right: 1em;
`

interface CB {
	collapsed?: boolean
	ignorePadding?: boolean
	compressed?: boolean
}

const CardBody = styled.div`
  padding: ${(props: CB) =>
          props.compressed
                  ? '0 1.5em 1em 1.5em'
                  : props.ignorePadding
                          ? 0
                          : props.collapsed
                                  ? '0 1em'
                                  : '1.5em 2em'};
  font-size: 14px;
  font-feature-settings: 'tnum';
  font-variant: tabular-nums;
  line-height: 1.5;
  color: ${(props) => props.theme.DarkIconColour};
  list-style: none;

  ${Font};

  @media (max-width: ${MobileBreakPoint}) {
    padding: ${(props: any) =>
            props.collapsed
                    ? '0 1em'
                    : props.compressed
                            ? '0 1em 0.5em 1em'
                            : '0.5em 1em'};
    color: ${(props) => props.theme.DarkIconColourDark};
  }

  background-color: ${(props) => props.theme.BackgroundColour};
`

const CollapseIcon = styled(Icon)`
  padding-right: 1.2em;
  color: ${Colours.MutedIconColour};

  :hover {
    cursor: pointer;
  }

  @media print {
    display: none;
    visibility: hidden;
  }
`

const CardIcon = styled.div`
  margin-top: -2px;
`

interface ICard {
	children?: any
	collapsible?: boolean
	compressed?: boolean
	extra?: any
	hideTitle?: boolean
	icon?: string
	iconColour?: string
	ignorePadding?: boolean
	noBorder?: boolean
	printParams?: Print
	startCollapsed?: boolean
	style?: any
	title?: string | any
	titleElement?: any
}

const Card = (props: ICard) => {
	const [collapsed, setCollapsed] = useState(props.startCollapsed || false)
	const onClick = () => setCollapsed(!collapsed)

	if (props.printParams?.printingView) {
		return (
			<>
				<div>
					{props.titleElement ? (
						props.titleElement
					) : (
						<u>
							<H2 style={{ fontSize: '1.25em', marginTop: '0.5em' }}>
								{props.title}
							</H2>
						</u>
					)}
				</div>
				{props.children}
			</>
		)
	}

	return (
		<C style={props.style}>
			{!props.hideTitle && (
				<CardHead
					collapsible={props.collapsible}
					noBorder={props.noBorder}
					onClick={() => props.collapsible && onClick()}
				>
					{(props.title || props.titleElement) && (
						<CardHeadTitle hasExtra={!!props.extra}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<CardIcon>
									{props.icon && (
										<Icon
											color={props.iconColour}
											name={props.icon}
											size='large'
										/>
									)}
								</CardIcon>
								<div>
									{props.titleElement ? (
										props.titleElement
									) : (
										<H2>{props.title}</H2>
									)}
								</div>
							</div>
							<div>{props.extra && <CardExtra>{props.extra}</CardExtra>}</div>
						</CardHeadTitle>
					)}

					{props.collapsible && (
						<div>
							<CollapseIcon
								name={collapsed ? 'angle up' : 'angle down'}
								onClick={onClick}
								size='large'
							/>
						</div>
					)}
				</CardHead>
			)}

			{props.children && (
				<CardBody
					collapsed={collapsed}
					compressed={props.compressed}
					ignorePadding={props.ignorePadding}
				>
					{!collapsed ? props.children : null}
				</CardBody>
			)}
		</C>
	)
}

Card.defaultProps = {
	collabsable: false,
	hideTitle: false,
	icon: '',
	iconColour: '',
	noBorder: false,
	titleElement: null,
}

export default Card
