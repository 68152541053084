import { createSelector } from 'reselect'
import { getClientSortOrder$ } from 'containers/settings/selectors'
import { sortClients } from 'lib'
import { IState, IClient } from 'interfaces'

export const getSelectedClientID$ = (state: IState) =>
	state.clients.selectedClient
export const getClients$ = (state: IState) => state.clients.clients
export const getClient = (state: IState, clientID: string) =>
	state.clients.clients[clientID] || {}
const getAllClientsIDList$ = (state: IState) => state.clients.allIDs

export const getSelectedClient$ = createSelector(
	getSelectedClientID$,
	getClients$,
	(selectedClientID, clients) => {
		return clients[selectedClientID] || {}
	},
)

export const getSelectedClientFullName$ = createSelector(
	getSelectedClient$,
	(client) => getClientFullName(client),
)

export const getAllClientsList$ = createSelector(
	[getClients$, getAllClientsIDList$, getClientSortOrder$],
	(
		clients: Array<IClient>,
		idList: Array<string>,
		sortOrder: 'firstName' | 'lastName',
	) => {
		const allClientsForList =
			idList
				.map((id) => {
					const {
						firstName,
						lastName,
						middleName,
						email,
						phoneNumbers,
					} = clients[id]
					return {
						id,
						firstName: firstName,
						lastName: lastName,
						middleName: middleName,
						email: email,
						phoneNumbers: phoneNumbers,
					} as IClient
				})
				.sort((a: IClient, b: IClient) => sortClients(a, b, sortOrder)) || []

		return allClientsForList
	},
)

/**
 *
 * @param client Client Object
 * @returns Client Full Name with middle initial
 */
export const getClientFullName = (client: IClient | any) => {
	if (!client) {
		return ''
	}

	const firstName = client.firstName
	const lastName = client.lastName
	const middleInitials =
		client.middleName && client.middleName
			? client.middleName
				.split(' ')
				.map((names) => (names[0] ? `${names[0]}.` : ''))
				.join(' ')
			: ''

	if (!firstName && !middleInitials && !lastName) {
		return ''
	}

	return `${firstName || ''} ${middleInitials || ''} ${lastName || ''}`
}

/**
 * Uses the current context's selected client to get the full client name
 * @returns The Selected Clients Full Name with Middle Initial
 */
export const getSelectedClientsFullName$ = createSelector(
	getSelectedClientID$,
	getClients$,
	(selectedClientID, clients) =>
		(clients[selectedClientID] &&
			getClientFullName(clients[selectedClientID])) ||
		'',
)

export const getClientsForSelect$ = createSelector(
	[getAllClientsList$],
	(clients) => {
		return clients.map((client: any) => ({
			id: client.id,
			key: client.id,
			value: client.id,
			text: getClientFullName(client),
		}))
	},
)
