import React from 'react'
import { connect } from 'react-redux'

import { onLogout } from 'reducers/ducks'
import { Dispatch } from 'interfaces'

interface Props {
  logout: () => void
}

class Logout extends React.Component<Props> {
  componentDidMount() {
    this.props.logout()
  }

  render() {
    return <div />
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(onLogout()),
})

export default connect(null, mapDispatchToProps)(Logout)
