import { authGet } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { Dispatch, ErrorState, IAction, Severity } from 'interfaces'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const FETCH_STORAGE_QUOTA = filesAction('FETCH_STORAGE_QUOTA')
const FETCH_STORAGE_QUOTA_SUCCESS = filesAction('FETCH_STORAGE_QUOTA_SUCCESS')
const FETCH_STORAGE_QUOTA_FAILURE = filesAction('FETCH_STORAGE_QUOTA_FAILURE')

export const defaultState = {
  quota: {
    used: 0,
    total: 0,
  },
  fetchingStorageQuota: false,
  storageQuotaError: {},
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_STORAGE_QUOTA: {
      return {
        ...state,
        fetchingStorageQuota: true,
      }
    }

    case FETCH_STORAGE_QUOTA_SUCCESS: {
      const quota = action.quota

      return {
        ...state,
        fetchingStorageQuota: false,
        quota: {
          used: quota.usedInBytes,
          total: quota.quotaInBytes,
        },
      }
    }

    case FETCH_STORAGE_QUOTA_FAILURE:
      return {
        ...state,
        fetchingStorageQuota: false,
        clientFilesError: action.error,
      }

    default:
      return state
  }
}

export const fetchStorageQuota = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_STORAGE_QUOTA })

  return authGet(Storage.Quota)
    .then((response) => {
      dispatch(fetchStorageQuotaSuccess(response.data))
      return response
    })
    .catch((error) => {
      captureMessage(`Failed to fetch Storage Quota. ${error}`, Severity.Error)
      dispatch(fetchStorageQuotaFailure(error))
      return error
    })
}

const fetchStorageQuotaSuccess = (quota: number) => ({
  type: FETCH_STORAGE_QUOTA_SUCCESS,
  quota,
})

const fetchStorageQuotaFailure = (error: ErrorState) => ({
  type: FETCH_STORAGE_QUOTA_FAILURE,
  error,
})
