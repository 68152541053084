import React from 'react'

import { Button, Modal, Header } from 'components/ui'
import { FileUpload } from 'containers/files'

const FileUploadModal = (props: FileUploadModalProps) => (
  <Modal
    closeOnDimmerClick={true}
    onClose={props.onClose}
    open={props.modalOpen}
  >
    <Header content={props.header} icon="browser" />
    <Modal.Content>
      <FileUpload
        onComplete={props.onComplete}
        onError={props.onError}
        onStart={props.onStart}
        url={props.url}
      />
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={props.onClose} secondary>
        Cancel
      </Button>
      {/* <Button primary loading={props.loading} onClick={props.onClose}>
        Upload
      </Button> */}
      {props.onFinish && (
        <Button loading={props.loading} onClick={props.onFinish} primary>
          Finish
        </Button>
      )}
    </Modal.Actions>
  </Modal>
)

interface FileUploadModalProps {
  header: string
  loading: boolean
  modalOpen: boolean
  onClose: Function
  onComplete?: Function
  onError?: Function
  onFinish?: Function
  onStart?: Function
  url: string
}

FileUploadModal.defaultProps = {
  modalOpen: false,
  onCancel: () => {},
}

export default FileUploadModal
