import React from 'react'
import styled from 'styled-components'

import { Label, FormField, Form, Icon } from 'components/ui'

interface IColour {
  colour: string
  borderColour?: string
}

interface IColourPicker {
  onChange: any //React.ChangeEvent<HTMLInputElement>,
  value?: string
  defaultColours: Array<IColour>
  defaultColour?: string
}

const ColourGrid = styled.div`
  display: flex;
  max-width: 30em;
`

const ColourPickerInputContainer = styled.div`
  display: flex;
  align-items: center;
`

interface IColourBlock {
  colour: string
  selected?: boolean
  size?: string
  circle?: boolean
}

export const ColourBlock = styled.div<IColourBlock>`
  width: ${(props) => (props.size ? props.size : '2em')};
  height: ${(props) => (props.size ? props.size : '2em')};
  ${(props) => `background-color: ${props.colour}`};
  ${(props) =>
    props.selected
      ? props.colour === '#000000'
        ? 'border: 3px solid #6e6e6e'
        : 'border: 3px solid #000000'
      : null};

  @media (max-width: 768px) {
    width: 2.25rem;
    height: 2.25rem;
  }
  
  ${(props) => props.circle && 'border-radius: 50%'};

  :hover {
    cursor: pointer;
  }

  @media (prefers-color-scheme: dark) {
    ${(props) =>
      props.selected
        ? props.colour === '#ffeb3b'
          ? 'border: 3px solid ##6e6e6e'
          : 'border: 3px solid #fff'
        : null};
  }
`

const ColourPickerInput = styled.input`
  width: 15em;
  height: 2.5em;
  border-radius: 5px;
  padding: 0;
  border: 1px solid ${(props) => props.theme.InputBorderColour};

  :hover {
    cursor: pointer;
  }
`

const ColourPicker = (props: IColourPicker) => {
  return (
    <Form>
      <FormField>
        <Label>Pick Colour</Label>

        {props.defaultColours.length && (
          <ColourGrid>
            {props.defaultColours.map((colour) => (
              <ColourBlock
                key={colour.colour}
                colour={colour.colour}
                onClick={() =>
                  props.onChange({ target: { value: colour.colour } })
                }
                selected={
                  props.value
                    ? colour.colour === props.value
                    : colour.colour === props.defaultColour
                }
              />
            ))}
          </ColourGrid>
        )}
      </FormField>

      <FormField>
        <ColourPickerInputContainer>
          <Icon name="paint brush" size="large" />
          <ColourPickerInput
            onChange={props.onChange}
            type="color"
            value={props.value || props.defaultColour}
          />
        </ColourPickerInputContainer>
      </FormField>
    </Form>
  )
}

export { ColourPicker }
