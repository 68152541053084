import { createSelector } from 'reselect'

import {
  CLIENT_SORT_ORDER,
  CLIENT_TYPES,
  SOAP_TYPES,
  CALENDAR_SLOT_DURATION,
  CALENDAR_DEFAULT_APPOINTMENT_DURATION,
  TAB_TYPES,
  DOCUMENT_VIEW_DRIVER,
  STORAGE_USED,
  STORAGE_LIMIT,
  PRACTICE_TYPE,
  getDefaultSettings,
  CALENDAR_DEFAULT_VIEW,
} from 'constants/settings'

import { getCurrentTreatmentForClient$ } from 'containers/treatment/selectors'
import { IState, SettingKey } from 'interfaces'
import { Form_T } from './form-types/new-interfaces'
import { FILES_TAB_ID, NOTES_TAB_ID, SUMMARY_TAB_ID } from 'constants/form'

export const getBodyDiagrams$ = (state: IState) =>
  state.settings.bodyDiagrams || []

export const getCurrentBodyDiagram$ = createSelector(
  getBodyDiagrams$,
  (bodyDiagrams) => {
    const bd = bodyDiagrams && bodyDiagrams.find((b) => b.isCurrent)
    return bd || {}
  },
)

export const getClientSortOrder$ = (state: IState) =>
  state.settings[CLIENT_SORT_ORDER]

const getClientTypesFromSettings$ = (state: IState) =>
  state.settings[CLIENT_TYPES] || null

const getTabTypesFromSettings = (state: IState) => state.settings[TAB_TYPES]

const getDefaultTabs = () => getDefaultSettings(TAB_TYPES)

export const getTabTypes = createSelector(
  [getTabTypesFromSettings, getDefaultTabs],
  (tabTypes, defaultTabs: Array<any>) => {
    const tabSettings =
      typeof tabTypes === 'string' ? JSON.parse(tabTypes) : tabTypes

    if (!tabSettings) {
      return defaultTabs
    }

    for (const defaultTab of defaultTabs) {
      if (!tabSettings.find((t) => t.id === defaultTab.id)) {
        tabSettings.push(defaultTab)
      }
    }

    const tabs = tabSettings.sort((a, b) => a.order - b.order) || []

    let prevOrder = -1
    for (const tab of tabs) {
      if (tab.order === prevOrder) {
        tab.order++
      }

      prevOrder++
    }

    return tabs
  },
)

export const getTabsForFormTypes = createSelector([getTabTypes], (tabTypes) =>
  tabTypes.filter(
    (tab) => tab.id !== SUMMARY_TAB_ID && tab.id !== FILES_TAB_ID,
  ),
)

export const getClientTypes$ = createSelector(
  [getClientTypesFromSettings$],
  (clientTypes) => {
    if (!clientTypes) {
      return []
    }
    return clientTypes?.allIDs?.map((id: string) => clientTypes[id]) || []
  },
)

const practiceType = (state: IState) => state.settings[PRACTICE_TYPE] || ''
export const getPracticeType = createSelector(
  [practiceType],
  (practiceType) => practiceType,
)

export const getActiveClientTypes = createSelector(
  [getClientTypesFromSettings$],
  (clientTypes) => {
    if (!clientTypes) {
      return []
    }
    const ct = clientTypes?.allIDs?.map((id: string) => clientTypes[id])
    return (ct || []).filter((ct: any) => !ct.isDeleted)
  },
)

export const getDeletedClientTypes = createSelector(
  [getClientTypesFromSettings$],
  (clientTypes) => {
    if (!clientTypes) {
      return []
    }
    const ct = clientTypes?.allIDs?.map((id: string) => clientTypes[id])
    return (ct || []).filter((ct: any) => ct.isDeleted)
  },
)

/**
 * Selector to return all Soap Types as ID indexed Object
 * @param state
 * @returns An Object consisting of all Soap Types
 */
export const getSoapTypesFromSettings$ = (state: IState) =>
  state.settings[SOAP_TYPES] || null

/**
 * @returns List of Soap Types
 */
export const getSoapTypes$ = createSelector(
  [getSoapTypesFromSettings$],
  (soapTypes) => {
    if (!soapTypes) {
      return []
    }

    return soapTypes?.allIDs?.map((id: string) => soapTypes[id]) || []
  },
)

export const getSoapTypesForDropdown$ = createSelector(
  [getSoapTypesFromSettings$],
  (soapTypes) => {
    const types =
      soapTypes?.allIDs?.map((id: string) => {
        const f = soapTypes[id]

        return {
          key: f.id,
          id: f.id,
          value: f.id,
          text: f.isDeleted ? `${f.name} (deleted)` : f.name,
          deleted: f.isDeleted,
        }
      }) || []

    types.unshift({
      id: 'all',
      key: 'all',
      text: 'All Notes',
      value: 'all',
    })

    return types
  },
)

export const getActiveSoapTypes = createSelector(
  [getSoapTypesFromSettings$],
  (soapTypes) => {
    if (!soapTypes) {
      return []
    }
    const st = soapTypes?.allIDs?.map((id: string) => soapTypes[id])
    return (st || []).filter((ct: any) => !ct.isDeleted)
  },
)

export const getDeletedSoapTypes = createSelector(
  [getSoapTypesFromSettings$],
  (soapTypes) => {
    if (!soapTypes) {
      return []
    }
    const st = soapTypes?.allIDs?.map((id: string) => soapTypes[id])
    return (st || []).filter((ct: any) => ct.isDeleted)
  },
)

export const getClientTypeFromID = (state: IState, clientTypeID: string) =>
  getClientTypes$(state).find((ct: Form_T) => ct.id === clientTypeID)

export const getClientTypeByID = (state: IState, { treatment }) =>
  getClientTypes$(state).find((ct: Form_T) => ct.id === treatment.clientTypeID)

const getClientTypeByClientTypeID = (state: IState, clientTypeID: string) =>
  getClientTypes$(state).find((ct: Form_T) => ct.id === clientTypeID)

export const getClientTypeByID$ = createSelector(
  [getClientTypeByID],
  (clientType) => clientType,
)

// TODO - temp, circular dependency as client selectors import from here
const getSelectedClientID$ = (state: IState) => state.clients.selectedClient
const getClients$ = (state: IState) => state.clients.clients
const getSelectedClient$ = createSelector(
  getSelectedClientID$,
  getClients$,
  (selectedClientID, clients) => clients[selectedClientID] || {},
)
export const getClientTypeBySelectedClient = createSelector(
  [getClientTypes$, getSelectedClient$],
  (clientTypes, selectedClient) => {
    return (
      clientTypes.find((ct: Form_T) => ct.id === selectedClient.clientTypeID) ||
      {}
    )
  },
)

export const getClientTypeTabsBySelectedClient$ = createSelector(
  [getClientTypes$, getSelectedClient$],
  (clientTypes, selectedClient) => {
    const client: Form_T = clientTypes.find(
      (ct: Form_T) => ct.id === selectedClient.clientTypeID,
    )

    const tabs = client?.formTabs?.filter(
      (t) => t.enabled === true || typeof t.enabled === 'undefined',
    )
    return tabs
  },
)

export const getClientTypeByClientTypeID$ = createSelector(
  [getClientTypeByClientTypeID],
  (clientType) => clientType,
)

export const getDefaultClientType$ = createSelector(
  [getClientTypes$],
  (clientTypes) =>
    clientTypes.find((ct: Form_T) => !ct.isDeleted && ct.name === 'Default') ||
    clientTypes.find((ct: Form_T) => !ct.isDeleted),
)

export const getSoapTypesForClientType$ = createSelector(
  [getCurrentTreatmentForClient$, getClientTypes$, getSoapTypes$],
  (treatment, clientTypes, soapTypes) => {
    if (!treatment || !clientTypes) {
      return []
    }

    const clientType: Form_T =
      clientTypes.find((ct: Form_T) => ct.id === treatment.clientTypeID) || {}

    if (!clientType) {
      return []
    }
    const selectedNotes =
      clientType.formTabs?.find((t) => t.id === NOTES_TAB_ID).forms || []

    const soapTypesForClient = []

    if (selectedNotes.length === 0) {
      const soapType = soapTypes.find((st: Form_T) => st.isDefault)
      return [{ ...soapType }]
    }

    if (selectedNotes.length === 1 && selectedNotes[0].formID === 'all') {
      return soapTypes
    }

    for (const sn of selectedNotes) {
      const soapType = soapTypes.find((st: Form_T) => st.id === sn.formID)
      soapTypesForClient.push(soapType)
    }

    return soapTypesForClient
  },
)

const getCalendarSlotDuration = (state: IState) =>
  state.settings[CALENDAR_SLOT_DURATION]

export const getCalendarSlotDurationSetting = createSelector(
  [getCalendarSlotDuration],
  (duration) => duration || getDefaultSettings(CALENDAR_SLOT_DURATION),
)

const getDefaultAppointmentDuration = (state: IState) =>
  state.settings[CALENDAR_DEFAULT_APPOINTMENT_DURATION]

export const getCalendarDefaultAppointmentDuration = createSelector(
  [getDefaultAppointmentDuration],
  (duration) =>
    duration || getDefaultSettings(CALENDAR_DEFAULT_APPOINTMENT_DURATION),
)

const getDefaultCalendarView = (state: IState) =>
  state.settings[CALENDAR_DEFAULT_VIEW]
export const getCalendarDefaultView = createSelector(
  [getDefaultCalendarView],
  (view) => {
    if (view !== 'dayGridMonth' && view !== 'timeGridWeek') {
      return 'dayGridMonth'
    }

    return view || getDefaultSettings(CALENDAR_DEFAULT_VIEW)
  },
)

const getFileDocumentDriver = (state: IState) =>
  state.settings[DOCUMENT_VIEW_DRIVER]
export const getFileDocumentViewDriverSetting = createSelector(
  [getFileDocumentDriver],
  (driverSetting) => driverSetting || getDefaultSettings(DOCUMENT_VIEW_DRIVER),
)

const getStorageUsed = (state: IState) => state.settings[STORAGE_USED]
export const getStorageUsedSetting = createSelector(
  [getStorageUsed],
  (storageUsed) => Number(storageUsed) || 0,
)

const getTotalStorageLimit = (state: IState) => state.settings[STORAGE_LIMIT]
export const getTotalStorageLimitSetting = createSelector(
  [getTotalStorageLimit],
  (totalStorageLimit) =>
    Number(totalStorageLimit) || getDefaultSettings(STORAGE_LIMIT),
)

export const getSetting = (state: IState, settingKey: SettingKey) =>
  state.settings[settingKey] || getDefaultSettings(settingKey)

export const getSettingNoDefault = (state: IState, settingKey: SettingKey) =>
  state.settings[settingKey] || ''

export const getClientTypesNormalized = (state: IState) =>
  state.settings[CLIENT_TYPES] || {}
