import React from 'react'
import styled from 'styled-components'

import { FileUpload } from 'containers/files'

import CTImage from './ct-image'

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ImageUpload = ({ imageSrc, ...rest }: Props) => (
	<>
		<ImageContainer>
			<CTImage imageID={imageSrc} />
		</ImageContainer>
		<FileUpload accept={{ 'image/*': ['.png', '.jpg', '.jpeg'] }} {...rest} />
	</>
)

interface Props {
	hideDetails?: boolean
	imageSrc: string
	multiple?: boolean
	onComplete: Function
	onDropOverride?: Function
	onError?: Function
	onStart?: Function
	status?: string
	uploadText?: string
	url: string
}

export default ImageUpload
