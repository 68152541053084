import React from 'react'
import { connect } from 'react-redux'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import styled from 'styled-components'
import { message } from 'components/notifications'

import ImageUpload from 'components/image/image-upload-ct'
import {
  saveBodyDiagram,
  fetchBodyDiagrams,
  getCurrentBodyDiagram$,
  getBodyDiagrams$,
} from 'containers/settings'
import { Spinner } from 'components/loaders'
import { Card, Message, H3, H5 } from 'components/ui'
import { saveSettings } from 'containers/settings'
import { ResponsiveImageSlider } from 'components/image/image-slider'
import { Storage } from 'constants/endpoints'
import { Dispatch, IState } from 'interfaces'

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SliderContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
`

interface Props {
  bodyDiagramLoading: boolean
  bodyDiagrams: any[]
  currentBodyDiagram: any
  emailVerified: boolean
  fetchBodyDiagrams: Function
}

class MassageSettings extends React.Component<Props> {
  state = {
    uploadProgress: 0,
  }

  componentDidMount() {
    if (!this.props.bodyDiagrams?.length) {
      this.props.fetchBodyDiagrams()
    }
  }

  render() {
    if (!this.props.emailVerified) {
      return (
        <Message
          content="You must verify your email address before you can change more settings"
          icon="info"
          info
        />
      )
    }

    return (
      <Spinner spinning={this.props.bodyDiagramLoading}>
        <Card title="Body Diagram Image">
          <ImageContainer>
            <H5>
              Note: Changing the Body Diagram Image will only apply to future
              appointments
            </H5>

            <H3>Current Image</H3>

            <ImageUpload
              // accept="image/*"
              imageSrc={this.props.currentBodyDiagram.blobStorageFileID}
              onComplete={(response) => {
                this.props.fetchBodyDiagrams()
                message.success(
                  `${response.data.fileName} file uploaded successfully.`,
                )
              }}
              onError={(error) => {
                if (
                  error.response.status === 403 &&
                  error.response.data === 'Email Address Not Verified'
                ) {
                  return
                }

                captureMessage(
                  `Body Diagram Image Upload Failed. ${error}`,
                  Severity.Error,
                )

                message.error('Failed to upload file')
              }}
              url={Storage.BodyDiagram}
            />

            {this.props.bodyDiagrams.length > 1 && (
              <div>
                <H3>Previous Images</H3>
                <SliderContainer>
                  <ResponsiveImageSlider
                    bodyDiagrams={this.props.bodyDiagrams.filter(
                      (bd) => bd.id !== this.props.currentBodyDiagram.id,
                    )}
                  />
                </SliderContainer>
              </div>
            )}
          </ImageContainer>
        </Card>
      </Spinner>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  bodyDiagramError: state.settings.bodyDiagramError,
  bodyDiagrams: getBodyDiagrams$(state),
  bodyDiagramLoading: state.settings.bodyDiagramLoading,
  currentBodyDiagram: getCurrentBodyDiagram$(state),
  emailVerified: state.user.user.emailVerified,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveBodyDiagram: (path, imageUrl) =>
    dispatch(saveBodyDiagram(path, imageUrl)),
  fetchBodyDiagrams: () => dispatch(fetchBodyDiagrams()),
  saveSettings: (setting) => dispatch(saveSettings(setting)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MassageSettings)
