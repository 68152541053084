import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { List, Message, H3 } from 'components/ui'
import {
	IAppointment,
	ClientInfoURLParams,
	IEvent,
} from 'interfaces'

import Appointment from './appointment'

const Appointments = (props: AppointmentsProps) => {
	const params: ClientInfoURLParams = useParams()
	const navigate = useNavigate()

	const clientName = props.displayClient ? props.clientName : null
	const appointments = props.appointments

	const searchProps = {
		useSearch: true,
		onSearch: props.onSearch,
		onSearchClear: props.onSearchClear,
		onSearchFilter: props.onFilter,
		searchLoading: props.loading,
		searchTerm: props.searchTerm,
	}

	const onClick = (e: any) => {
		props.onClick(e)
		params.treatmentNumber
			? navigate(`/client/${props.clientID}/notes/${e.id}/treatment/${params.treatmentNumber}`)
			: navigate(`/client/${props.clientID}/notes/${e.id}`)
	}

	return (
		<div style={{ minWidth: '18em' }}>
			<H3>Appointments</H3>
			{!props.appointmentError && (
				<>
					<List
						activeItem={props.selectedAppointment}
						items={appointments}
						itemType='Appointments'
						listHeader='Appointments'
						listHeaderIcon='calendar'
						noArrows
						noItemsMessage={
							clientName
								? `No Appointments Found For ${clientName}`
								: 'No Appointments'
						}
						onNext={(nextIndex: number) =>
							props.onClick(appointments[nextIndex])
						}
						renderer={(appointment: IAppointment & IAppointmentList) => (
							<Appointment
								key={appointment.id}
								active={appointment.active}
								appointment={appointment}
								appointmentNumber={appointment.appointmentNumber}
								end={appointment.end}
								isDirty={props.isDirty === appointment.id}
								onClick={onClick}
								start={appointment.start}
							/>
						)}
						scrollable={{ scrollable: true, maxHeight: '70vh' }}
						search={searchProps}
					/>
				</>
			)}
			{props.appointmentError && (
				<Message content="Couldn't load Appointments" error icon='warning' />
			)}
		</div>
	)
}

interface AppointmentsProps {
	appointmentError: string
	appointments: Array<IAppointment>
	clientID: string
	clientName?: string
	// disabled?: boolean
	displayClient?: boolean
	isDirty?: string
	loading?: boolean
	onClick: Function
	onFilter: (e: IEvent) => void
	onSearch: Function
	onSearchClear: Function
	// reverseEmphasis?: boolean
	searchTerm: string
	selectedAppointment: IAppointment
}

export interface IAppointmentList {
	active?: boolean
}

Appointments.defaultProps = {
	appointmentError: '',
	disabled: false,
	displayClient: false,
	loading: false,
	reverseEmphasis: false,
	selectedAppointment: {},
}

export default Appointments
export type { AppointmentsProps }
