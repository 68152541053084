import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import FullCalendar from '@fullcalendar/react'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import { useEventListener, useGetColourTheme } from 'lib'
import { getCalendarSlotDurationSetting, getSetting } from 'containers/settings'

import './calendar.css'
import { MobileBreakPointSmallNumber } from 'styles'
import { IState } from 'interfaces'
import { CALENDAR_SHOW_WEEKENDS } from 'constants/settings'

const CALENDAR_OFFSET = 70
const CALENDAR_MODAL_OFFSET = 220
const MOBILE_CALENDAR_OFFSET = 10

const getOffset = (isInModal: boolean = false) =>
  window.innerWidth <= MobileBreakPointSmallNumber
    ? MOBILE_CALENDAR_OFFSET
    : isInModal
    ? CALENDAR_MODAL_OFFSET
    : CALENDAR_OFFSET

const Calendar = (props: ICalendar) => {
  const [calHeight, setCalHeight] = useState(
    window.innerHeight - getOffset(props.isInModal),
  )
  const calendarSlotDurationSetting = useSelector(
    getCalendarSlotDurationSetting,
  )
  const showWeekends = useSelector((state: IState) =>
    getSetting(state, CALENDAR_SHOW_WEEKENDS),
  )
  const theme = useGetColourTheme('dark')

  const setCalendarHeight = (height: number) => {
    if (height < 512) {
      setCalHeight(512)
    } else {
      setCalHeight(height)
    }
  }

  useEffect(() => {
    setCalendarHeight(window.innerHeight - getOffset(props.isInModal))
  })

  useEventListener('resize', () =>
    setCalendarHeight(window.innerHeight - getOffset(props.isInModal)),
  )

  const { events } = props
  // TODO - re-enable?
  // const calendarEvents = useMemo(
  //   () =>
  //     events.map((e) => ({
  //       ...e,
  //       start: new Date(moment(e.start).format()),
  //       end: new Date(moment(e.end).format()),
  //     })),
  //   [events],
  // )

  return (
    <div
      className={
        theme === 'dark' ? 'calendar-dark-theme' : 'calendar-light-theme'
      }
    >
      <FullCalendar
        key={props.key}
        ref={props.calendarRef}
        dateClick={props.dateClick}
        dayMaxEvents={5}
        defaultTimedEventDuration={props.defaultTimedEventDuration}
        displayEventEnd
        droppable
        editable={props.editable}
        eventChange={props.eventChange}
        eventClick={props.eventClick}
        eventMouseEnter={props.eventMouseEnter}
        eventMouseLeave={props.eventMouseLeave}
        events={events}
        eventStartEditable={props.editable}
        forceEventDuration
        headerToolbar={props.header}
        height={calHeight}
        navLinkDayClick={props.navLinkDayClick}
        nowIndicator
        plugins={[
          momentPlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ]}
        select={props.select}
        selectable={props.selectable}
        selectLongPressDelay={500}
        selectMirror={props.selectMirror}
        slotDuration={calendarSlotDurationSetting}
        weekends={showWeekends === 'true'}
      />
    </div>
  )
}

interface ICalendar {
  // activeTab?: number // 1,2,3 -> Month, Week, Day
  // eventDrop?: Function | any // Function
  // eventRender?: Function | any
  // eventResize?: Function | any //  Function
  // plugins: Array<any>
  calendarRef?: any
  dateClick?: any //Function
  defaultTimedEventDuration?: string
  editable?: boolean
  eventChange?: Function | any // TEMP - Migrate to this
  eventClick?: Function | any // Function
  eventMouseEnter?: Function | any // Function
  eventMouseLeave?: Function | any //  Function
  events: Array<any> // TODO - IAppointment
  header: any
  isInModal?: boolean
  key: string
  navLinkDayClick?: any // Function
  select?: any //  Function
  selectable?: boolean
  selectMirror?: boolean
}

Calendar.defaultProps = {
  defaultDate: null,
  defaultTimedEventDuration: '01:00',
  defaultView: 'dayGridMonth',
  editable: false,
  events: [],
  header: {
    left: '',
    center: 'title',
    right: '',
  },
  selectMirror: false,
  selectable: false,
}

export { Calendar }
