import React from 'react'

import { Grid, H4 } from 'components/ui'
import { DAYS } from 'constants/days'
import { TimeSlot } from 'components/calendar'

const OfficeTimes = ({
  pickedDays,
  addSlot,
  removeSlot,
  onTimeSlotChange,
  disabled,
  editing,
}: IOfficeTimes) => (
  <div>
    {pickedDays
      .sort((a, b) => a.dayID - b.dayID)
      .map((day) => (
        <Grid key={day.dayID}>
          <Grid.Row columns={2}>
            <Grid.Column computer={2} mobile={16} tablet={2}>
              <H4>{DAYS[day.dayID].long}</H4>
            </Grid.Column>
            <Grid.Column computer={14} mobile={16} tablet={14}>
              <TimeSlot
                addSlot={() => addSlot(day.dayID)}
                disabled={disabled}
                editing={editing}
                onTimeSlotChange={(name, value, index) =>
                  onTimeSlotChange(name, value, day.dayID, index)
                }
                removeSlot={(index) => removeSlot(day.dayID, index)}
                slots={day.slots}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))}
  </div>
)

interface IOfficeTimes {
  addSlot: Function
  disabled: boolean
  editing: boolean
  onTimeSlotChange: Function
  pickedDays: Array<any>
  removeSlot: Function
}

OfficeTimes.defaultProps = {
  disabled: false,
  editing: true,
  pickedDays: [],
}

export default OfficeTimes
