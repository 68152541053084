import React, { useEffect, useState } from 'react'

import {
  Header,
  Modal,
  Button,
  Icon,
  Form,
  Text,
  Checkbox,
  FinePrint,
  Divider,
} from 'components/ui'
import { DataItem } from './data-item'
import { IEvent } from 'interfaces'
import { mapToTree } from '../helpers'
import { FormBuilderComponent, FormItemComponent } from '../interfaces'

interface Props {
  modalOpen: boolean
  node: FormBuilderComponent
  onAccept: (node: any) => void
  onCancel: () => void
}

export const EditCompnentModal = (props: Props) => {
  const { node } = props
  const [components, setComponent] = useState([])
  const [deprecated, setDeprecated] = useState(
    node?.additionalProps?.deprecated,
  )

  const onChange = ({ target }: IEvent, index: number) => {
    const updateComponents = [...components]
    updateComponents[index].value = target.value
    setComponent(updateComponents)
  }

  const onDeprecated = ({ target }: IEvent) => setDeprecated(target.value)

  const onAccept = () => {
    const newNode = JSON.parse(JSON.stringify(node))
    newNode.additionalProps.data = components

    const component: FormItemComponent = {
      id: newNode.id,
      deprecated,
      data: newNode.additionalProps.data,
      description: newNode.description,
      label: newNode.label,
      name: newNode.name,
      title: newNode.title,
      type: newNode.additionalProps.type,
    }

    const updatedNode: any = mapToTree(component)
    updatedNode.subtitle = newNode.subtitle
    updatedNode.children = newNode.children
    updatedNode.expanded = newNode.expanded
    props.onAccept(updatedNode)
    setDeprecated(false)
  }

  const onClose = () => {
    setDeprecated(false)
    props.onCancel()
  }

  useEffect(() => {
    if (node) {
      const copyNode: FormBuilderComponent = JSON.parse(JSON.stringify(node))
      setComponent(copyNode.additionalProps.data)
      setDeprecated(copyNode.additionalProps.deprecated)
    }
  }, [node])

  const header = node?.title
    ? `${node?.subtitle}: ${node?.title}`
    : node?.subtitle

  return (
    <Modal onClose={onClose} open={props.modalOpen} size="small">
      <Header content={header} icon="pencil" />
      <Modal.Content>
        <Text>{node?.additionalProps?.description}</Text>
        <Form>
          {components?.map((c, i) => {
            return (
              <DataItem
                key={i}
                dataItem={c}
                onChange={(e: IEvent) => onChange(e, i)}
                value={c.value}
              />
            )
          })}

          <Divider />

          <Checkbox
            checked={deprecated}
            label="Deprecated"
            name="deprecated"
            onChange={onDeprecated}
            toggle
          />
          <FinePrint>
            Deprecating a component hides the component from a form unless it
            has data. If the component has data it will appear.
          </FinePrint>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onAccept} primary>
          <Icon name="checkmark" /> Save
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
