import React from 'react'
import styled from 'styled-components'
import useOnclickOutside from 'react-cool-onclickoutside'

import { Icon } from 'components/ui'

import { SideBarRoutes } from './side-bar'

interface ISideBarMobileContainer {
	collapsed?: boolean
}

const SideBarMobileContainer = styled.div<ISideBarMobileContainer>`
  display: block;
  position: fixed;
  z-index: 999;
  min-height: 100vh;
  background-color: ${(props) => props.theme.BackgroundColour};
  visibility: ${(props) => (props.collapsed ? 'hidden' : 'visible')};
`

const CloseIcon = styled(Icon)`
  :hover {
    cursor: pointer;
  }
`

const IconBackdrop = styled.div`
  display: block;
  position: fixed;
  padding: 1em;
  left: 10em;
  background-color: ${(props) => props.theme.BackgroundColour};
`

const SideBarMobile = (props: ISideBarMobile) => {
	const ref = useOnclickOutside(() => {
		props.onMobileCollapse(true)
	})

	const { collapsed, authenticated, onMobileCollapse } = props

	return (
		authenticated && (
			<SideBarMobileContainer ref={ref} collapsed={collapsed}>
				{/* <Sidebar.Pushable as={Segment}>
        <Sidebar
          animation="overlay"
          icon="labeled"
          inverted
          vertical
          visible={true}
          width="thin"
        > */}
				<IconBackdrop>
					<CloseIcon
						name='bars'
						onClick={() => onMobileCollapse(!collapsed)}
						size='large'
					/>
				</IconBackdrop>
				<SideBarRoutes
					isMobile
					onItemPress={onMobileCollapse}
					// routerLocation={routerLocation}
					user={props.user}
				/>

				{/* </Sidebar>
      </Sidebar.Pushable> */}
			</SideBarMobileContainer>
		)
	)
}

export default SideBarMobile

interface ISideBarMobile {
	authenticated?: boolean
	collapsed?: boolean
	onMobileCollapse: (collapsed: boolean) => void
	// routerLocation: any
	user: any // TODO - IUser
}
