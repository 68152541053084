import {
  reducer as sendContactUsEmail,
  defaultState as sendContactUsEmailState,
} from './send-contact-us-email'
import {
  reducer as sendAppointmentEmail,
  defaultState as sendAppointmentEmailState,
} from './send-appointment-email'
import {
  reducer as setupAppointmentEmailReminder,
  defaultState as setupAppointmentEmailReminderState,
} from './setup-appointment-email-reminder'
import {
  reducer as sendAppointmentSMS,
  defaultState as sendAppointmentSMSState,
} from './send-appointment-sms'
import {
  reducer as setupAppointmentReminderSMS,
  defaultState as setupAppointmentReminderSMSState,
} from './setup-appointment-sms-reminder'
import { IAction, EmailState } from 'interfaces'

const reducers = [
  sendContactUsEmail,
  sendAppointmentEmail,
  setupAppointmentEmailReminder,
  sendAppointmentSMS,
  setupAppointmentReminderSMS,
]

export const defaultState: EmailState = {
  ...sendContactUsEmailState,
  ...sendAppointmentEmailState,
  ...setupAppointmentEmailReminderState,
  ...sendAppointmentSMSState,
  ...setupAppointmentReminderSMSState,
  flags: {
    ...sendAppointmentEmailState.flags,
    ...setupAppointmentEmailReminderState.flags,
    ...sendAppointmentSMSState.flags,
    ...setupAppointmentReminderSMSState.flags,
  },
}

export const reducer = (
  state = defaultState as EmailState,
  action: IAction,
) => {
  switch (action.type) {
    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}
