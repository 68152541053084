import { IAction, ReceiptState } from 'interfaces'

import { reducer as postReceipt } from './post-receipt'
import { reducer as fetchReceipts } from './fetch-receipts'
import { reducer as emailReceipt } from './email-receipt'
import { reducer as deleteReceipt } from './delete-receipt'

export const defaultState: ReceiptState = {
  allIDs: [],
  receipts: {},
  totalReceipts: 0,
  flags: {
    fetching: false,
    posting: false,
    emailing: false,
    deleting: false,
  },
}

const reducers = [fetchReceipts, postReceipt, emailReceipt, deleteReceipt]

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    default:
      break
  }

  return reducers.reduce((s: any, r) => r(s, action), state)
}
