import React from 'react'

import { Button, Form, Modal, Header, Text } from 'components/ui'
import { TextArea } from 'components/forms'
import { IEvent } from 'interfaces'

interface Props {
  content: string
  message: string
  messageValue: string
  modalOpen: boolean
  onAccept: Function
  onCancel: Function
  onMessageChange: (e: IEvent) => void
}

const TreatmentCloseModal = (props: Props) => (
  <Modal onClose={props.onCancel} open={props.modalOpen} size="small">
    <Header content={props.message} icon="question circle" />
    <Modal.Content>
      <Text>{props.content}</Text>
      <Form>
        <TextArea
          label="Closing Notes"
          name="treatmentCloseMessage"
          onChange={props.onMessageChange}
          placeholder="Any notes on why the treatment was closed"
          value={props.messageValue}
        />
      </Form>
    </Modal.Content>

    <Modal.Actions>
      <Button onClick={props.onCancel} secondary>
        Cancel
      </Button>
      <Button negative onClick={props.onAccept}>
        Close
      </Button>
    </Modal.Actions>
  </Modal>
)

TreatmentCloseModal.defaultProps = {
  modalOpen: false,
  onCancel: () => {},
}

export default TreatmentCloseModal
