import { reducer as saveClient } from './save-client'
import {
  reducer as fetchClient,
  defaultState as fetchClientDefaultState,
} from './fetch-client'
import { ClientsState, IAction } from 'interfaces'

const reducers = [saveClient, fetchClient]

const clientAction = (action: string) => `client-trackyr/client-info/${action}`
const ON_CHANGE = clientAction('ON_CHANGE')
const ON_SET_EDIT = clientAction('ON_SET_EDIT')
const UNSET_CHANGES_MADE = clientAction('UNSET_CHANGES_MADE')
const ON_VALIDATE_FORM = clientAction('ON_VALIDATE_FORM')

export const NEW_CLIENT_ID = '0'

export const defaultState = {
  ...fetchClientDefaultState,
} as ClientsState

export const reducer = (
  state: ClientsState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case ON_CHANGE: {
      return {
        ...state,
        clients: {
          ...state.clients,
          [state.selectedClient]: {
            ...state.clients[state.selectedClient],
            [action.name]: action.value,
          },
        },
        changesMade: true,
      }
    }

    case ON_VALIDATE_FORM: {
      return {
        ...state,
        clients: {
          ...state.clients,
          [state.selectedClient]: {
            ...state.clients[state.selectedClient],
            [`${action.name}-validation`]: action.validation,
          },
        },
      }
    }

    case UNSET_CHANGES_MADE:
      return {
        ...state,
        changesMade: false,
      }

    case ON_SET_EDIT:
      return {
        ...state,
        flags: {
          ...state.flags,
          [`${action.tab}Editing`]: action.editing,
        },
      }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const onChange = (fieldID: string, name: string, value: string) => ({
  type: ON_CHANGE,
  fieldID,
  name,
  value,
})

export const validateForm = (
  formID: string,
  name: string,
  validation: any,
) => ({
  type: ON_VALIDATE_FORM,
  formID,
  name,
  validation,
})

export const onSetEdit = (
  tab: 'client' | 'soap' | 'rest',
  editing: boolean,
) => ({
  type: ON_SET_EDIT,
  tab,
  editing,
})

export const unsetChangesMade = () => ({ type: UNSET_CHANGES_MADE })
