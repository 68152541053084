import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFView = (props: IPDFView) => {
  const [numPages, setNumPages] = useState(null)
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

  return (
    <Document
      file={props.filePathUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      renderMode="svg"
    >
      {props.firstPage ? (
        <Page key={'page_1'} pageNumber={1} scale={props.scale} />
      ) : (
        Array.from(new Array(numPages), (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={props.scale}
          />
        ))
      )}
    </Document>
  )
}

interface IPDFView {
  firstPage?: boolean
  filePathUrl: string
  scale: number
}

export { PDFView }
