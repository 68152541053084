import { Form, Header, Label, PhoneNumberDropdown } from 'components/ui'
import {
  GlobalAppointmentReminders,
  CanSendAppointmentReminders,
  CanSendSMSAppointmentReminders,
} from 'constants/features'
import { FeatureToggle } from 'containers/features'
import { IClient, IEvent, IState, PhoneNumber } from 'interfaces'
import { getClient } from 'containers/clients'
import { useSelector } from 'react-redux'
import { Checkbox } from 'components/forms'
import { ToEmailAddress } from 'containers/emails'

interface Props {
  clientID: string
  email: string
  phoneNumber: any
  onChange: (e: IEvent) => void
  sendEmail: boolean
  sendReminder: boolean
  sendSMS: boolean
  sendSMSReminder: boolean
  setSendEmail: (sendEmail: boolean) => void
  setSendReminder: (sendReminder: boolean) => void
  setSendSMS: (sendSMS: boolean) => void
  setSendSMSReminder: (sendSMSReminder: boolean) => void
}

export const AppointmentSendReminders = (props: Props) => {
  const client: IClient = useSelector((state: IState) =>
    getClient(state, props.clientID),
  )

  const onSendEmailChange = () => props.setSendEmail(!props.sendEmail)
  const onSendEmailReminderChange = () =>
    props.setSendReminder(!props.sendReminder)
  const onSendSMSChange = () => props.setSendSMS(!props.sendSMS)
  const onSendSMSReminderChange = () =>
    props.setSendSMSReminder(!props.sendSMSReminder)

  const hasPhoneNumbers: boolean =
    client.phoneNumbers?.length > 0 &&
    client.phoneNumbers?.find((p: PhoneNumber) => p.phoneNumber) !== undefined

  return (
    <FeatureToggle features={[GlobalAppointmentReminders]}>
      <FeatureToggle features={[CanSendAppointmentReminders]}>
        <Header content="Email Reminders" icon="mail" />

        <Form>
          <Label>Email Address</Label>
          <ToEmailAddress
            email={props.email}
            hideExtraLabel
            name="email"
            onChange={props.onChange} // TEMP
            readonly
          />

          <Checkbox
            label="Send Appointment Email to Client"
            name="sendEmail"
            onChange={onSendEmailChange}
            toggle
            value={props.sendEmail}
          />

          <Checkbox
            label="Send Appointment Reminder Day Before to Client"
            name="sendReminder"
            onChange={onSendEmailReminderChange}
            toggle
            value={props.sendReminder}
          />
        </Form>
      </FeatureToggle>

      {hasPhoneNumbers && (
        <FeatureToggle features={[CanSendSMSAppointmentReminders]}>
          <Header content="Text Message SMS Reminders" icon="phone" />
          <Form>
            <PhoneNumberDropdown
              name="phoneNumber"
              onChange={props.onChange}
              phoneNumbers={client.phoneNumbers}
              value={props.phoneNumber}
            />

            <Checkbox
              label="Send Appointment Text Message"
              name="sendSMS"
              onChange={onSendSMSChange}
              toggle
              value={props.sendSMS}
            />

            <Checkbox
              label="Send Appointment Text Message Reminder Day Before to Client"
              name="sendSMSReminder"
              onChange={onSendSMSReminderChange}
              toggle
              value={props.sendSMSReminder}
            />
          </Form>
        </FeatureToggle>
      )}
    </FeatureToggle>
  )
}
