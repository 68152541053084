import { Print } from 'components/ui'
import { MultiFormPrint } from 'containers/print/interfaces'
import * as React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { getTheme } from 'styles/themes'

import Forms from './forms'

const Container = styled.div`
  display: none;
  visibility: hidden;

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    display: inherit;
    visibility: visible;

    html,
    body {
      height: initial;
      overflow: initial;
      -webkit-print-color-adjust: exact;
    }

    div {
      break-inside: avoid;
    }

    .page-break {
      margin-top: 4em;
      display: block;
      page-break-before: auto;
      /* page-break-before: always; */
    }

    @page {
      size: landscape;
    }
  }

  @page {
    size: auto;
    margin: 10mm 15mm 10mm 15mm;
  }
`

interface Props {
  data?: any
  fileJson: any
  header?: any
  multiForms?: MultiFormPrint
  printParams?: Print
  ref: any
}

export class FormToPrint extends React.PureComponent<Props> {
  public render() {
    const printParams = this.props.printParams ?? {
      printingView: true,
      margins: '',
      multiLines: true,
      ignoreCards: true,
      hideEmpty: false,
    }

    if (this.props.multiForms) {
      return (
        <Container ref={this.props.ref}>
          <ThemeProvider theme={getTheme('light')}>
            {this.props.multiForms.header ? this.props.multiForms.header : null}
            {this.props.multiForms.items.map((form) => {
              return (
                <>
                  {form.header && form.header}
                  <Forms
                    entityID=""
                    forms={form.fileJson}
                    formsDataOverride={form.data || {}}
                    onChange={() => {}}
                    patchEndpoint=""
                    printParams={printParams}
                    readonly={true}
                    validateFormOverride={null}
                  />
                  <br />
                </>
              )
            })}
          </ThemeProvider>
        </Container>
      )
    }

    return (
      <Container ref={this.props.ref}>
        <ThemeProvider theme={getTheme('light')}>
          {this.props.header && this.props.header}
          <Forms
            entityID=""
            forms={this.props.fileJson}
            formsDataOverride={this.props.data || {}}
            onChange={() => {}}
            patchEndpoint=""
            printParams={printParams}
            readonly={true}
            validateFormOverride={null}
          />
        </ThemeProvider>
      </Container>
    )
  }
}
