import {
  isPhoneNumberValid,
  isEmailValid,
  isNumber,
  isAlphabetic,
  isAlphanumeric,
  isValidDate,
} from 'lib'
import { IFormItem, IValidation } from 'interfaces/forms'
import { omitBy } from 'lodash'

export const getValidation = (formItem: IFormItem) => {
  const validation: IValidation = {}

  if (!formItem.validation) {
    return validation
  }

  if (formItem.validation.includes('required')) {
    validation.required = true
  }

  if (formItem.validation.includes('email')) {
    validation.email = true
  }

  if (formItem.validation.includes('phonenumber')) {
    validation.phonenumber = true
  }

  if (formItem.validation.includes('number')) {
    validation.number = true
  }

  if (formItem.validation.includes('alphabetical')) {
    validation.alphabetical = true
  }

  if (formItem.validation.includes('alphanumeric')) {
    validation.alphanumeric = true
  }

  if (formItem.validation.includes('date')) {
    validation.date = true
  }

  return validation
}

export const onValidate = (
  validation = {} as IValidation,
  label: string,
  name: string,
  value: any,
  validateFormCallback: Function,
) => {
  const validationObj = {
    hasFeedback: false,
    validateStatus: '',
    help: '',
  }

  if (validation.required) {
    if (!value) {
      validationObj.hasFeedback = true
      validationObj.validateStatus = 'error'
      validationObj.help = `${label} is required`

      validateFormCallback(name, validationObj)
      return
    }
  }

  if (validation.email) {
    if (!isEmailValid(value)) {
      validationObj.hasFeedback = true
      validationObj.validateStatus = 'error'
      validationObj.help = `${label} is not a valid email`

      validateFormCallback(name, validationObj)
      return
    }
  }

  if (validation.phonenumber) {
    for (const phoneNumber of value) {
      if (!isPhoneNumberValid(phoneNumber.phoneNumber)) {
        validationObj.hasFeedback = true
        validationObj.validateStatus = 'error'
        validationObj.help = `${label} is not a valid phone number`

        validateFormCallback(name, validationObj)
        return
      }
    }
  }

  if (validation.number) {
    if (!isNumber(value)) {
      validationObj.hasFeedback = true
      validationObj.validateStatus = 'error'
      validationObj.help = `${label} is not a valid number`

      validateFormCallback(name, validationObj)
      return
    }
  }

  if (validation.alphabetical) {
    if (!isAlphabetic(value)) {
      validationObj.hasFeedback = true
      validationObj.validateStatus = 'error'
      validationObj.help = `${label} can only contain letters`

      validateFormCallback(name, validationObj)
      return
    }
  }

  if (validation.alphanumeric) {
    if (!isAlphanumeric(value)) {
      validationObj.hasFeedback = true
      validationObj.validateStatus = 'error'
      validationObj.help = `${label} can only contain letters and numbers`

      validateFormCallback(name, validationObj)
      return
    }
  }

  if (validation.date) {
    if (!isValidDate(value)) {
      validationObj.hasFeedback = true
      validationObj.validateStatus = 'error'
      validationObj.help = `${label} must be a valid date`

      validateFormCallback(name, validationObj)
      return
    }
  }

  validateFormCallback(name, {})
}

export const stripValidation = (obj: any, match: string): any =>
  omitBy(obj, (_, k) => k.includes(match))
