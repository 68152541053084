import React from 'react'
import { connect } from 'react-redux'
import { Layer, Stage, Line } from 'react-konva'
import shortid from 'shortid'

import { setSoapDirty } from 'containers/soap-notes'
import ErrorAlert from 'components/error'
import { getSelectedClientID$ } from 'containers/clients'
import { getSelectedAppointmentID$ } from 'containers/appointments'
import { DrawNoteModal } from 'components/modals'
import { debounce } from 'lib/debounce'
import { parseShapesToAPI } from 'lib/fetch-parsers'
import { FETCHING, SUCCESS, FAILURE, NONE } from 'components/forms'

import {
  addShape,
  addLines,
  deleteShape,
  onImageLoad,
  setCanvasScale,
  changeShape,
  setDrawAutoSaving,
  setCurrentCanvasAsDataURL,
} from './ducks'
import ColoredShape from './components/coloured-shape'
import BackgroundLayer from './components/background-layer'
import { getShapesForSoap$, getBodyDiagram$ } from './selectors'
import { Dispatch, IResponse, IState, Line as ILine, Shape } from 'interfaces'
import { KonvaEventObject } from 'konva/lib/Node'
import { Print } from 'components/ui'

const EDIT_ACTION = 'edit'
const DELETE_ACTION = 'delete'
const PENCIL_SHAPE = 'Pencil'

interface Props {
  addLines: (lines: Array<ILine>, shape: Shape, appointmentID: string) => any
  addShape: (shape: Shape, appointmentID: string) => any
  appointmentID: string
  canvasBackground: any
  changeShape: (
    shape: Shape,
    newX: number,
    newY: number,
    appointmentID: string,
  ) => any
  clientID: string
  currentCanvasAsDataURL?: string
  deleteShape: (id: string, appointmentID: string) => any
  height: number
  id?: any
  onChangeCallback: Function
  onImageLoad: (image: any) => any
  printParams?: Print
  readonly: boolean
  scale: number
  selectedAction: string
  selectedColour: string
  selectedShape: string
  selectedSize: number
  setCanvasScale: (scale: number, appointmentID: string) => any
  setCurrentCanvasAsDataURL: (dataURL: string) => void
  setDrawAutoSaving: (
    status: typeof SUCCESS | typeof FAILURE | typeof FETCHING | typeof NONE,
  ) => void
  setSoapDirty: (appointmentID: string, clientID: string) => void
  shapes: Array<Shape>
  shapesSummaryOverride: Array<any>
  width: number
}

class DrawCanvas extends React.Component<Props> {
  boundingBox = {} as any
  bodyDiagramRef: any
  stageRef: any

  state = {
    drawNoteModalOpen: false,
    modalShape: {} as Shape,
    drawing: false,
    tempLines: [] as Array<ILine>,
    size: 35,
  }

  constructor(props: Props) {
    super(props)
    this.bodyDiagramRef = React.createRef()
    this.stageRef = React.createRef()
  }

  componentDidMount() {
    this.scaleCanvas()

    window.addEventListener('resize', () => {
      this.scaleCanvas()
    })
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.appointmentID !== prevProps.appointmentID) {
      this.scaleCanvas()
    }

    const dataURL = this.stageRef.toDataURL({
      pixelRatio: 1, // or other value you need
    })

    if (dataURL !== this.props.currentCanvasAsDataURL) {
      this.props.setCurrentCanvasAsDataURL(dataURL)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      this.scaleCanvas()
    })
  }

  scaleCanvas = () => {
    if (!this.bodyDiagramRef) {
      return
    }

    this.boundingBox = this.bodyDiagramRef.getBoundingClientRect()

    const width =
      window.innerWidth < this.boundingBox.width
        ? window.innerWidth - 45
        : this.boundingBox.width
    this.props.setCanvasScale(
      width / this.props.width,
      this.props.appointmentID,
    )

    const size = window.innerWidth > 768 ? 35 : 55
    this.setState(() => ({ size }))
  }

  onImageLoad = (image) => {
    this.props.onImageLoad(image)
    this.scaleCanvas()
  }

  checkIfOverlap = (x: number, y: number) => {
    const overlapAmount = (this.props.selectedSize - 12) * this.props.scale

    return this.props.shapes.find(
      (s) =>
        x < s.x + overlapAmount &&
        x > s.x - overlapAmount &&
        y < s.y + overlapAmount &&
        y > s.y - overlapAmount,
    )
  }

  handleClick = (e: KonvaEventObject<any>) => {
    const pointerPosition = this.stageRef.getPointerPosition()
    const x = e.evt.layerX || pointerPosition.x
    const y = e.evt.layerY || pointerPosition.y

    if (!x || !y) {
      return
    }

    const overlapping = this.checkIfOverlap(x, y)

    if (
      this.props.selectedShape === PENCIL_SHAPE ||
      this.props.selectedAction !== EDIT_ACTION ||
      this.props.readonly ||
      !!overlapping
    ) {
      return e
    }

    const shape = {
      id: shortid.generate(),
      x: x,
      y: y,
      width: this.props.selectedSize,
      height: this.props.selectedSize,
      shapeType: this.props.selectedShape,
      colour: this.props.selectedColour,
    } as Shape

    this.props.addShape(shape, this.props.appointmentID)
    this.props.setSoapDirty(this.props.appointmentID, this.props.clientID)

    this.props.onChangeCallback && this.debounceOnAfterSave()
  }

  onMouseDownPencil = () => {
    if (this.props.selectedShape === PENCIL_SHAPE && !this.props.readonly) {
      this.setState(() => ({
        drawing: true,
        tempLines: [],
      }))
    }
  }

  onMouseUpPencil = () => {
    if (!this.state.drawing) {
      return
    }

    if (this.state.tempLines.length > 15) {
      const shape = {
        id: shortid.generate(),
        shapeType: 'Lines',
        colour: this.props.selectedColour,
      } as Shape

      this.props.addLines(this.state.tempLines, shape, this.props.appointmentID)
      this.props.setSoapDirty(this.props.appointmentID, this.props.clientID)
    }

    this.setState(() => ({
      drawing: false,
      tempLines: [],
    }))

    this.props.onChangeCallback && this.debounceOnAfterSave()
  }

  onMouseMovePencil = (e: KonvaEventObject<any>) => {
    if (!this.state.drawing) {
      return
    }

    const pointerPosition = this.stageRef.getPointerPosition()

    const x = e.evt.layerX || pointerPosition.x
    const y = e.evt.layerY || pointerPosition.y
    let { tempLines } = this.state

    tempLines = tempLines.concat([
      { rel: x / this.props.scale, act: x } as ILine,
      { rel: y / this.props.scale, act: y } as ILine,
    ])

    this.setState(() => ({
      tempLines,
    }))
  }

  onSelectShape = (e: KonvaEventObject<any>, shape: Shape) => {
    var nativeEvent = e.evt
    nativeEvent.preventDefault()

    if (this.props.selectedAction === DELETE_ACTION && !this.props.readonly) {
      this.props.deleteShape(shape.id, this.props.appointmentID)
      this.props.setSoapDirty(this.props.appointmentID, this.props.clientID)
    } else {
      this.setState(() => ({
        selected: shape.id,
        drawNoteModalOpen: true,
        modalShape: shape,
      }))
    }
  }

  onDragEnd = (e: KonvaEventObject<any>, shape: Shape) => {
    if (this.props.readonly) {
      return
    }

    const pointerPosition = this.stageRef.getPointerPosition()
    const x = e.evt.layerX || pointerPosition.x
    const y = e.evt.layerY || pointerPosition.y

    this.props.changeShape(shape, x, y, this.props.appointmentID)
    this.props.setSoapDirty(this.props.appointmentID, this.props.clientID)

    this.props.onChangeCallback && this.debounceOnAfterSave()
  }

  debounceOnAfterSave = debounce(() => {
    this.props.setDrawAutoSaving(FETCHING)

    this.props.onChangeCallback &&
      this.props
        .onChangeCallback({
          target: {
            name: 'shapes',
            value: JSON.stringify(parseShapesToAPI(this.props.shapes)),
          },
        })
        .then((response: IResponse<any>) => {
          this.props.setDrawAutoSaving(
            response.status === 204 ? SUCCESS : FAILURE,
          )

          if (response.status === 204) {
            setTimeout(() => this.props.setDrawAutoSaving(NONE), 3500)
          }
        })
        .catch(() => this.props.setDrawAutoSaving(FAILURE))
  }, 1500)

  render() {
    // HACK
    // TODO - Fix Canvas error: CanvasRenderingContext2D.drawImage: Passed-in canvas is empty
    // Likely from canvas existing in a modal
    if (this.props.printParams?.printingView) {
      this.bodyDiagramRef = null
      return null
    }
    if (
      !this.props.canvasBackground ||
      !this.props.canvasBackground.blobStorageFileID
    ) {
      this.bodyDiagramRef = null
      return (
        <ErrorAlert
          errorMsg="Failed to Load Body Diagram Image, please try again"
          hasError
        />
      )
    }

    const shapes =
      this.props.shapesSummaryOverride && this.props.readonly
        ? this.props.shapesSummaryOverride
        : this.props.shapes

    return (
      <div key={this.props.id} ref={(ref) => (this.bodyDiagramRef = ref)}>
        <DrawNoteModal
          modalOpen={this.state.drawNoteModalOpen}
          onCancel={() => this.setState(() => ({ drawNoteModalOpen: false }))}
          onChangeCallback={this.props.onChangeCallback}
          readonly={this.props.readonly}
          shape={this.state.modalShape}
        />
        <Stage
          key={this.props.id}
          ref={(ref) => (this.stageRef = ref)}
          height={this.props.height * this.props.scale}
          id={this.props.id}
          onClick={this.handleClick}
          onMouseDown={this.onMouseDownPencil}
          onMouseMove={this.onMouseMovePencil}
          onMouseUp={this.onMouseUpPencil}
          onTap={this.handleClick}
          onTouchEnd={this.onMouseUpPencil}
          onTouchMove={this.onMouseMovePencil}
          onTouchstart={this.onMouseDownPencil}
          scaleX={this.props.scale}
          scaleY={this.props.scale}
          width={this.props.width * this.props.scale}
        >
          <Layer>
            <BackgroundLayer
              background={this.props.canvasBackground.blobStorageFileID}
              height={this.props.height}
              onImageLoad={this.onImageLoad}
              width={this.props.width}
            />
          </Layer>
          <Layer>
            {shapes.map((shape) => (
              <ColoredShape
                key={shape.id}
                isDrawingMode={this.props.selectedAction === EDIT_ACTION}
                onClick={(e) => this.onSelectShape(e, shape)}
                onDragEnd={(e) => this.onDragEnd(e, shape)}
                onTap={(e) => this.onSelectShape(e, shape)}
                readonly={this.props.readonly}
                scale={this.props.scale}
                shape={shape}
                size={this.state.size}
              />
            ))}

            <Line
              key="temp-lines"
              points={this.state.tempLines.map((l) => l.rel)}
              stroke={this.props.selectedColour}
              strokeWidth={5}
            />
          </Layer>
        </Stage>
      </div>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  shapes: getShapesForSoap$(state),
  canvasBackground: getBodyDiagram$(state),
  clientID: getSelectedClientID$(state),
  appointmentID: getSelectedAppointmentID$(state),
  currentCanvasAsDataURL: state.draw.currentCanvasAsDataURL,
  selectedColour: state.draw.selectedColour,
  selectedShape: state.draw.selectedShape,
  selectedSize: state.draw.selectedSize,
  selectedAction: state.draw.selectedAction,
  width: state.draw.width,
  height: state.draw.height,
  scale: state.draw.scale,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addShape: (shape: Shape, appointmentID: string) =>
    dispatch(addShape(shape, appointmentID)),
  addLines: (lines: Array<ILine>, shape: Shape, appointmentID: string) =>
    dispatch(addLines(lines, shape, appointmentID)),
  deleteShape: (id: string, appointmentID: string) =>
    dispatch(deleteShape(id, appointmentID)),
  onImageLoad: (image: any) => dispatch(onImageLoad(image)),
  setSoapDirty: (appointmentID: string, clientID: string) =>
    dispatch(setSoapDirty(appointmentID, clientID)),
  setCanvasScale: (scale: number, appointmentID: string) =>
    dispatch(setCanvasScale(scale, appointmentID)),
  changeShape: (
    shape: Shape,
    newX: number,
    newY: number,
    appointmentID: string,
  ) => dispatch(changeShape(shape, newX, newY, appointmentID)),
  setDrawAutoSaving: (
    status: typeof SUCCESS | typeof FAILURE | typeof FETCHING | typeof NONE,
  ) => dispatch(setDrawAutoSaving(status)),
  setCurrentCanvasAsDataURL: (dataURL: string) =>
    dispatch(setCurrentCanvasAsDataURL(dataURL)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawCanvas)
