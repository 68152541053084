import React from 'react'

import { Checkbox as C, FormField, Print } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import {
	AutoSave,
	Validation,
	ValidationRules,
} from 'components/forms/interfaces'

interface CheckboxProps {
	autoSave?: AutoSave
	defaultChecked?: boolean
	disabled?: boolean
	label?: string
	name?: string
	onChange: Function
	printParams?: Print
	readonly?: boolean
	style?: any
	toggle?: boolean
	validation?: Validation
	validationRules?: ValidationRules
	value: boolean
}

const CheckboxForm = ({
												autoSave = {} as AutoSave,
												defaultChecked,
												disabled,
												label,
												name,
												onChange,
												printParams,
												readonly,
												style,
												toggle,
												validation = {},
												validationRules = {},
												value,
											}: CheckboxProps) => {
	return (
		<FormField
			required={!readonly && validationRules && validationRules.required}
			{...validation}
		>
			<AutoSaveWrapper
				afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
				autosaveDisabled={autoSave.autosaveDisabled}
				autosaveMode='onChange'
				debounceTime={250}
				endpoint={autoSave.patchEndpoint}
				entityID={autoSave.entityID}
				formType={autoSave.patchType}
			>
				<C
					checked={value}
					defaultChecked={defaultChecked}
					disabled={disabled}
					label={label}
					name={name}
					onChange={(event, onChangeCallback) => {
						onChange(event)

						onChangeCallback && onChangeCallback(event)
					}}
					printParams={printParams}
					readonly={readonly}
					style={style}
					toggle={toggle}
				/>
			</AutoSaveWrapper>
		</FormField>
	)
}

CheckboxForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const Checkbox = React.memo(CheckboxForm)
