import React, { useEffect, useState } from 'react'

import * as Form from 'components/forms'
import {
	Divider,
	FormColumnArea,
	FormHeader,
	H1,
	List,
	Print,
} from 'components/ui'
import Draw from 'containers/draw'
import {
	Card,
	Form as F,
	Label,
	Text,
	FinePrint,
	Signature,
	Placeholder,
	Segment,
} from 'components/ui'
import { authPatch } from 'lib/http-request'

import { getValidation, onValidate as onFormValidate } from './form-validator'
import {
	FormGeneratorComponentType,
	IForm,
	IFormItem,
	IFormsData,
	IValidation,
} from 'interfaces/forms'
import { IEvent } from 'interfaces'
import { debounce } from 'lib'
import { AutoSave } from 'components/forms/interfaces'
import { FormGeneratorListItem } from './interfaces'
import { DefaultListItem } from './components/default-list-item'

const defaultStringValue = ''
const defaultCheckedValue = false

interface Props {
	autosave?: boolean
	entityID: string
	fetching: boolean
	formID: string
	forms: IForm
	formsData: IFormsData
	id?: string
	namePrefix?: string
	onChange: (e: IEvent) => void
	onSave?: Function
	patch?: (e: IEvent, t?: any) => Promise<any>
	patchEndpoint: string
	printParams?: Print
	readonly?: boolean
	validateForm: Function
}

// TODO - use entityID and some type to get data directly?

const filteredKeys = (obj: any, filter: string) => {
	const values = {}

	for (const key in obj) {
		if (key.includes(filter)) {
			values[key] = obj[key]
		}
	}

	return values
}

export const FormGenerator = (props: Props) => {
	const [data, setData] = useState(props.formsData)

	const { formsData } = props

	useEffect(() => {
		if (formsData.id !== data.id) {
			setData(formsData)
		}
	}, [formsData])

	const onChange = ({ target }: IEvent) => {
		setData((prevData) => ({
			...prevData,
			[target.name]: target.value,
		}))
		!props.autosave && onChangeSynctoRedux({ target })
	}

	// Less performant than using autosave callback, but if no autosave
	// you have to use this method.
	const onChangeSynctoRedux: ({ target }: IEvent) => void = debounce(
		({ target }: IEvent) => props.onChange({ target }),
		250,
	)

	const afterAutoSaveCallback = ({ target }: IEvent) => {
		props.onChange({ target })
	}

	const initForm = () => {
		const formItems: Array<IFormItem> = props.forms.form

		if (formItems) {
			const form = constructForm(formItems)
			return <F>{form}</F>
		} else {
			return null
		}
	}

	const constructForm = (formItems: Array<IFormItem>) => {
		return (
			formItems &&
			formItems.map(
				(formItem: IFormItem) => formItem && buildForm(formItem, null),
			)
		)
	}

	const onValidate = (
		validation: IValidation,
		label: string,
		name: string,
		value: any,
	) =>
		onFormValidate(
			validation,
			label,
			name,
			value,
			(name: string, validationObj: IValidation) =>
				props.validateForm(name, validationObj),
		)

	const buildForm = (formItem: IFormItem, renderFormItem: React.ReactNode) => {
		if (props.fetching && formItem.type !== 'card') {
			return (
				<Placeholder key={formItem.id}>
					<Placeholder.Paragraph>
						<Placeholder.Line />
						<Placeholder.Line />
					</Placeholder.Paragraph>
				</Placeholder>
			)
		}

		const validationRules = getValidation(formItem)
		const fn = formItem.name || formItem.id
		const formItemName = props.namePrefix ? `${props.namePrefix}:${fn}` : fn

		const autoSaveData: AutoSave = {
			afterAutoSaveCallback: afterAutoSaveCallback,
			autosaveDisabled: !props.autosave,
			entityID: props.entityID,
			patch: props.patch,
			patchEndpoint: props.patchEndpoint,
			patchType: props.forms.tab,
		}

		const basicFormsData = {
			autoSave: autoSaveData,
			key: formItem.id || formItem.name,
			label: formItem.label,
			name: formItemName,
			onChange: onChange,
			onValidate: onValidate,
			placeholder: formItem.placeholder,
			printParams: props.printParams,
			readonly: props.readonly,
			size: formItem.size,
			style: formItem.style,
			validation: props.formsData[`${formItemName}-validation`],
			validationRules,
		}

		const formType: FormGeneratorComponentType = formItem.type

		if (props.readonly) {
			if (
				formType !== 'card' &&
				formType !== 'fragment' &&
				formType !== 'group' &&
				formType !== 'columns' &&
				formType !== 'printColumns' &&
				!data[formItemName] &&
				!data[`${formItemName}Notes`] &&
				!data[`${formItemName}Check`] &&
				!data[`${formItemName}-count`] &&
				!data[`${formItemName}-address`] &&
				!data[`${formItemName}-addressTwo`] &&
				!data[`${formItemName}-city`] &&
				!data[`${formItemName}-province`] &&
				!data[`${formItemName}-postalcode`] &&
				(formType === 'bodyDiagram' ? !data['shapes'] : true)
			) {
				return null
			}
		}

		if (formItem.deprecated && !data[formItemName]) {
			return null
		}

		switch (formType) {
			case 'fragment': {
				const subform = constructForm(formItem.form)

				if (
					props.readonly &&
					subform.filter((s: any) => s != null).length === 0
				) {
					return null
				}

				renderFormItem = <F>{subform}</F>

				break
			}

			case 'card': {
				const subform = constructForm(formItem.form)

				if (
					props.readonly &&
					subform.filter((s: any) => s != null).length === 0
				) {
					return null
				}

				renderFormItem = (
					<Card
						key={formItem.id}
						collapsible={formItem.collapsible}
						hideTitle={formItem.hideTitle}
						printParams={props.printParams}
						title={formItem.title}
					>
						<F>{subform}</F>
					</Card>
				)

				break
			}

			case 'group': {
				const subform = constructForm(formItem.form)

				if (
					props.readonly &&
					subform.filter((s: any) => s != null).length === 0
				) {
					return null
				}

				renderFormItem = (
					<>
						<Form.GroupLabel key={formItem.id}>
							{formItem.label}
						</Form.GroupLabel>
						{subform}
						{!props.printParams?.printingView && formItem.showBreak && <hr />}
					</>
				)

				break
			}

			case 'columns': {
				const subform = constructForm(formItem.form)

				if (
					props.readonly &&
					subform.filter((s: any) => s != null).length === 0
				) {
					return null
				}

				renderFormItem = (
					<FormColumnArea key={formItem.id}>{subform}</FormColumnArea>
				)

				break
			}

			case 'printColumns': {
				renderFormItem = constructForm(formItem.form)
				break
			}

			case 'email': {
				renderFormItem = (
					<Form.Input
						{...basicFormsData}
						icon={formItem.icon}
						type='email'
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'input': {
				renderFormItem = (
					<Form.Input
						{...basicFormsData}
						icon={formItem.icon}
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'inputNumber': {
				renderFormItem = (
					<Form.InputNumber
						{...basicFormsData}
						numberType={formItem.numberType}
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'checkbox': {
				renderFormItem = (
					<Form.Checkbox
						{...basicFormsData}
						toggle={formItem.toggle}
						value={JSON.parse(data[formItemName] || defaultCheckedValue)}
					/>
				)

				break
			}

			case 'checkboxInput': {
				renderFormItem = (
					<Form.CheckboxInput
						{...basicFormsData}
						placeholder={formItem.placeholder}
						toggle={formItem.toggle}
						valueCheck={JSON.parse(
							data[`${formItemName}Check`] || defaultCheckedValue,
						)}
						valueNotes={data[`${formItemName}Notes`] || defaultStringValue}
					/>
				)

				break
			}

			case 'checkboxForm': {
				const subform = constructForm(formItem.form)

				renderFormItem = (
					<div
						style={{
							paddingBottom: '0.5em',
						}}
					>
						<Form.Checkbox
							{...basicFormsData}
							toggle={formItem.toggle}
							value={JSON.parse(data[formItemName] || defaultCheckedValue)}
						/>
						{(basicFormsData?.printParams?.printingView ||
							data[formItemName]) && <div>{subform}</div>}
					</div>
				)

				break
			}

			case 'checkboxGroup': {
				renderFormItem = (
					<Form.CheckboxGroup
						{...basicFormsData}
						options={formItem.options}
						toggle={formItem.toggle}
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'checkboxGroupInput': {
				renderFormItem = (
					<Form.CheckboxGroupInput
						{...basicFormsData}
						options={formItem.options}
						placeholder={formItem.placeholder}
						toggle={formItem.toggle}
						valueCheck={data[`${formItemName}Check`] || defaultCheckedValue}
						valueNotes={data[`${formItemName}Notes`] || defaultStringValue}
					/>
				)

				break
			}

			case 'checkboxGroupForm': {
				const subform = constructForm(formItem.form)

				renderFormItem = (
					<div
						style={{
							paddingBottom: '0.5em',
						}}
					>
						<Form.CheckboxGroup
							{...basicFormsData}
							options={formItem.options}
							toggle={formItem.toggle}
							value={data[formItemName] || defaultStringValue}
						/>
						{(basicFormsData?.printParams?.printingView ||
							data[formItemName]) && <div>{subform}</div>}
					</div>
				)

				break
			}

			case 'textInput': {
				renderFormItem = (
					<Form.InputArea
						{...basicFormsData}
						placeholder={formItem.placeholder}
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'textArea': {
				renderFormItem = (
					<Form.TextArea
						{...basicFormsData}
						placeholder={formItem.placeholder}
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'phoneNumber': {
				renderFormItem = (
					<Form.PhoneNumber
						{...basicFormsData}
						placeholder={formItem.placeholder}
						value={data[formItemName] || [{}]}
					/>
				)

				break
			}

			case 'radio': {
				renderFormItem = (
					<Form.Radio
						{...basicFormsData}
						options={formItem.options}
						value={data[formItemName]}
					/>
				)

				break
			}

			case 'yesno': {
				renderFormItem = (
					<Form.YesNo
						{...basicFormsData}
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'yesnoform': {
				const subform = constructForm(formItem.form)

				renderFormItem = (
					<>
						<Form.YesNo
							{...basicFormsData}
							value={data[formItemName] || defaultStringValue}
						/>
						{(basicFormsData?.printParams?.printingView ||
							data[formItemName] === formItem.trigger) && (
							<Segment>
								<div>{subform}</div>
							</Segment>
						)}
					</>
				)

				break
			}

			case 'date': {
				renderFormItem = (
					<Form.DatePicker
						{...basicFormsData}
						format={formItem.format}
						placeholder={formItem.placeholder}
						// showTime={formItem.showTime}
						// startMode={formItem.startMode}
						value={data[formItemName] || defaultStringValue}
					/>
				)

				break
			}

			case 'dropdown': {
				renderFormItem = (
					<Form.Dropdown
						{...basicFormsData}
						clearable={formItem.clearable}
						multiple={formItem.multiple}
						options={formItem.options}
						placeholder={formItem.placeholder}
						value={data[formItemName]}
					/>
				)

				break
			}

			case 'address': {
				renderFormItem = (
					<Form.Address
						{...basicFormsData}
						address={data[`${formItemName}-address`] || defaultStringValue}
						addressTwo={
							data[`${formItemName}-addressTwo`] || defaultStringValue
						}
						addressValidation={data[`${formItemName}-address-validation`]}
						city={data[`${formItemName}-city`] || defaultStringValue}
						cityValidation={data[`${formItemName}-city-validation`]}
						postalcode={
							data[`${formItemName}-postalcode`] || defaultStringValue
						}
						postalcodeValidation={data[`${formItemName}-postalcode-validation`]}
						province={data[`${formItemName}-province`] || defaultStringValue}
						provinceValidation={data[`${formItemName}-province-validation`]}
					/>
				)

				break
			}

			case 'label': {
				renderFormItem = <Label key={formItem.id}>{formItem.label}</Label>

				break
			}

			case 'text': {
				renderFormItem = <Text key={formItem.id}>{formItem.text}</Text>

				break
			}

			case 'finePrint': {
				renderFormItem = (
					<FinePrint key={formItem.id}>{formItem.text}</FinePrint>
				)

				break
			}

			case 'bodyDiagram': {
				renderFormItem = (
					<Draw
						key={formItem.id}
						id={formItem.id}
						onChangeCallback={onDrawChangeAutoSave}
						readonly={props.readonly}
						shapesSummaryOverride={props.formsData.shapesSummaryOverride}
					/>
				)

				break
			}

			case 'multiForm': {
				const multiValues = filteredKeys(data, formItemName)
				const subform = constructForm(formItem.form)

				renderFormItem = (
					<Form.MultiForm
						{...basicFormsData}
						addText={formItem.addText}
						count={Number(data[`${formItemName}-count`]) || 1}
						removeText={formItem.removeText}
						values={multiValues}
					>
						{subform}
					</Form.MultiForm>
				)

				break
			}

			case 'list': {
				const items: any[] = JSON.parse(data[formItemName])
				const renderer = (items: Array<IFormItem>) => constructForm(items)

				renderFormItem = (
					<List
						items={items}
						itemType=''
						noItemsMessage={formItem.noItemText}
						renderer={(item: FormGeneratorListItem) => (
							<DefaultListItem
								description={item.description}
								form={formItem.form}
								header={item.header}
								item={item}
								renderer={renderer}
							/>
						)}
					/>
				)

				break
			}

			case 'segment': {
				const subform = constructForm(formItem.form)
				renderFormItem = <Segment>{subform}</Segment>

				break
			}

			case 'signature': {
				renderFormItem = (
					<Signature
						{...basicFormsData}
						dateValue={data[`${formItemName}-date`]}
						nameValue={data[`${formItemName}-name`]}
						signature={data[formItemName]}
					/>
				)

				break
			}

			case 'emergencyContact': {
				const multiValues = filteredKeys(data, formItemName)
				const signatures = filteredKeys(data, `${formItemName}-signature`)
				const multiMapFields =
					formItem.signatureType === 'multiMap'
						? formItem.multiMap.map((mm) => ({
							...mm,
							count: Number(data[`${mm.from}-count`] || 1),
							values: filteredKeys(data, `${mm.from}-signature`),
						}))
						: []

				renderFormItem = (
					<Form.EmergencyContact
						{...basicFormsData}
						contacts={multiValues}
						count={Number(data[`${formItemName}-count`]) || 1}
						multiMap={multiMapFields}
						signatures={signatures}
						signatureType={formItem.signatureType}
						text={formItem.text}
					/>
				)

				break
			}

			case 'formHeader': {
				renderFormItem = (
					<FormHeader
						backgroundColour={formItem.backgroundColour}
						centre={formItem.centre}
						date={data['headerDate']}
						dateFormat={formItem.dateFormat}
						left={formItem.left}
						name={formItemName}
						onChange={props.onChange}
						right={formItem.right}
						view={formItem.view}
					/>
				)

				break
			}

			case 'divider': {
				renderFormItem = <Divider hidden={formItem.hidden} />
				break
			}

			default: {
				renderFormItem = (
					<H1>{`MISSING COMPONENT: ${formItemName} - ${formItem.type}`}</H1>
				)
				console.error(`MISSING COMPONENT: ${formItemName} - ${formItem.type}`)
				break
			}
		}

		return renderFormItem
	}

	const onDrawChangeAutoSave = ({ target }: IEvent) => {
		const data = {
			entityID: props.entityID,
			type: props.forms.tab,
			name: target.name,
			value: target.value,
		}

		return authPatch(props.patchEndpoint, data)
	}

	return initForm()
}
