import { IState } from 'interfaces'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { MobileBreakPoint } from 'styles'
import SideBarMobile from 'components/nav/side-bar-mobile'
import { SideBar as SB } from 'components/nav'

const AppSideBar = styled.div`
  position: fixed;
  top: 0;
  grid-area: sidebar;
  z-index: 8;

  @media (max-width: ${MobileBreakPoint}) {
    visibility: hidden;
    height: 0;
    max-height: 0;
  }
`

const AppSideBarMobile = styled.div`
  background-color: ${(props) => props.theme.BackgroundColour};
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  background-color: ${(props) => props.theme.BackgroundColour};
`

const Sticky = styled.div`
  display: inline;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

interface Props {
	authenticated: boolean
}

export const SideBar = ({ authenticated }: Props) => {
	const user = useSelector((state: IState) => state.user)
	const sidebarCollapsed: boolean = useSelector(
		(state: IState) => state.app.sidebarCollapsed,
	)

	const [sidebarMobileCollapsed, setSidebarMobileOnCollapse] = useState(true)

	const sidebarMobileOnCollapse = (collapsed: boolean) =>
		setSidebarMobileOnCollapse(collapsed)

	return (
		<>
			<AppSideBarMobile>
				<SideBarMobile
					authenticated={authenticated}
					collapsed={sidebarMobileCollapsed}
					onMobileCollapse={sidebarMobileOnCollapse}
					// routerLocation={{}}
					// routerLocation={router ? router.location : {}}
					user={user.user}
				/>
			</AppSideBarMobile>

			<AppSideBar>
				<Sticky>
					<SB
						authenticated={authenticated}
						collapsed={sidebarCollapsed}
						// routerLocation={router ? router.location : {}}
						// routerLocation={{}}
						user={user.user}
					/>
				</Sticky>
			</AppSideBar>
		</>
	)
}
