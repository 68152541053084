import { authPost } from 'lib/http-request'
import { User } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { Dispatch, ErrorState, IAction, IResponse, IUser } from 'interfaces'

const userAction = (action: string) => `client-trackyr/user/${action}`
const SAVE_USER = userAction('SAVE_USER')
const SAVE_USER_SUCCESS = userAction('SAVE_USER_SUCCESS')
const SAVE_USER_FAILURE = userAction('SAVE_USER_FAILURE')

export const defaultState = {
  savingUser: false,
  savingUserError: { hasError: false, msg: '' },
  ctUser: {},
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case SAVE_USER:
      return {
        ...state,
        savingUser: true,
        savingUserError: { hasError: false, msg: '' },
      }

    case SAVE_USER_SUCCESS:
      return {
        ...state,
        savingUser: false,
        ctUser: {
          ...state.ctUser,
          ...action.data,
        },
      }

    case SAVE_USER_FAILURE:
      return {
        ...state,
        savingUser: false,
        error: { hasError: true, message: 'Failed to save user' },
      }

    default:
      return state
  }
}

export const saveUser =
  (user: IUser) =>
  async (dispatch: Dispatch): Promise<IResponse<IUser>> => {
    dispatch({ type: SAVE_USER, user })

    return authPost(User.Save, user)
      .then((response: IResponse<IUser>) => {
        message.success('Account Saved')
        dispatch(saveUserSuccess(response.data))
        return response.data
      })
      .catch((error) => {
        message.error('Failed to Save Account, please try again')
        captureMessage(`saveUser Failed. ${error}`, Severity.Error)

        dispatch(saveUserError(error))

        return error
      })
  }

const saveUserSuccess = (data: IUser) => ({
  type: SAVE_USER_SUCCESS,
  data,
})

const saveUserError = (error: ErrorState) => ({
  type: SAVE_USER_FAILURE,
  error,
})
