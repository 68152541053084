import { authPost } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { getHumanReadableSetting, parseFromAPI } from 'constants/settings'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import {
  IAction,
  Dispatch,
  Setting,
  SettingsState,
  IResponse,
} from 'interfaces'
import { invalidateCache } from 'lib'

// TODO - implementation
const settingsAction = (action: string) => `client-trackyr/settings/${action}`
const FETCH_SETTINGS = settingsAction('FETCH_SETTINGS')
const SAVE_SETTINGS = settingsAction('SAVE_SETTINGS')
const SAVE_SETTINGS_SUCCESS = settingsAction('SAVE_SETTINGS_SUCCESS')
const SAVE_SETTINGS_FAILURE = settingsAction('SAVE_SETTINGS_FAILURE')

export const reducer = (state: SettingsState, action: IAction) => {
  switch (action.type) {
    case SAVE_SETTINGS: {
      return {
        ...state,
        flags: {
          ...state.flags,
          saving: true,
          [action.settings.setting]: true,
        },
      }
    }

    case SAVE_SETTINGS_SUCCESS: {
      return {
        ...state,
        [action.data.setting]: parseFromAPI(action.data),
        flags: {
          ...state.flags,
          saving: false,
          [action.data.setting]: false,
        },
      }
    }

    case SAVE_SETTINGS_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          saving: false,
          [action.settings.setting]: false,
        },
      }
    }
    default:
      return state
  }
}

/**
 * Save Settings
 * @param  {Setting} settings
 */
export const saveSettings = (
  settings: Setting,
  options = { showMessage: true },
) => async (dispatch: Dispatch): Promise<IResponse<Setting>> => {
  dispatch({ type: SAVE_SETTINGS, settings, options })
  invalidateCache(FETCH_SETTINGS)

  return authPost(Settings.SaveSettings, {
    ...settings,
  })
    .then((response) => {
      options.showMessage &&
        message.success(
          `${getHumanReadableSetting(settings.setting)} Setting Updated`,
        )

      dispatch(onSaveSuccess(response.data, settings))
      return response
    })
    .catch((error) => {
      if (options.showMessage) {
        message.error(
          `Failed to update ${getHumanReadableSetting(
            settings.setting,
          )} Setting`,
        )
      }

      captureMessage(`saveSettings Failed. ${error}`, Severity.Error)

      dispatch(onSaveFailure(error, settings))
      throw error
    })
}

const onSaveSuccess = (data: any, settings: Setting) => ({
  type: SAVE_SETTINGS_SUCCESS,
  data,
  settings,
})
const onSaveFailure = (error: any, settings: Setting) => ({
  type: SAVE_SETTINGS_FAILURE,
  error,
  settings,
})
