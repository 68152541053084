import { captureMessage } from '@sentry/browser'
import { auth } from 'config/firebase'
import {
	createUserWithEmailAndPassword,
	setPersistence,
	signInWithEmailAndPassword,
	browserSessionPersistence,
	browserLocalPersistence,
	sendPasswordResetEmail,
	reauthenticateWithCredential,
	updatePassword,
	EmailAuthProvider,
	sendEmailVerification as sendFirebaseEmailVerification,
	getIdToken,
} from 'firebase/auth'
import { Severity } from 'interfaces'

export const register = (email: string, password: string) =>
	createUserWithEmailAndPassword(auth, email, password)
		.then(() => sendEmailVerification())

export const logout = () => auth.signOut()

export const login = (email: string, password: string, rememberMe: boolean) =>
	setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence)
		.then(() => signInWithEmailAndPassword(auth, email, password))

export const resetPassword = (email: string) =>
	sendPasswordResetEmail(auth, email)

export const changePassword = async (
	oldPassword: string,
	newPassword: string,
) => {
	const user = auth.currentUser
	const credentials = EmailAuthProvider.credential(
		user.email,
		oldPassword,
	)

	await reauthenticateWithCredential(user, credentials)
	return await updatePassword(user, newPassword)
}

export const sendEmailVerification = () =>
	sendFirebaseEmailVerification(auth.currentUser)

export const getCurrentUser = () => auth.currentUser

const authToken = (refresh?: boolean) =>
	getIdToken(auth.currentUser, refresh)
		.then((token) => token)
		.catch((error) =>
			captureMessage(`getIdToken error ${error}`, Severity.Error),
		)

export const getAuthToken = async () => {
	const token = await authToken()
	return token
}
