import { CLIENT_SORT_ORDER, PRACTICE_TYPE } from 'constants/settings'
import localforage from 'localforage'
import { CLIENT_TRACKYR_STORE, FLAGS } from 'constants/storage'
import { IAction, Dispatch } from 'interfaces'

const flagStore = localforage.createInstance({
  name: CLIENT_TRACKYR_STORE,
  storeName: FLAGS,
})

const settingsAction = (action: string) => `client-trackyr/settings/${action}`
const FETCH_LS_SETTINGS_SUCCESS = settingsAction('FETCH_LS_SETTINGS_SUCCESS')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_LS_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.settings,
      }
    default:
      return state
  }
}

export const fetchLSSettings = () => async (dispatch: Dispatch) => {
  const settings = {
    [PRACTICE_TYPE]: await flagStore.getItem(PRACTICE_TYPE),
    [CLIENT_SORT_ORDER]: await flagStore.getItem(CLIENT_SORT_ORDER),
  }

  dispatch(fetchLSSettingsSuccess(settings))
}

const fetchLSSettingsSuccess = (settings: any) => ({
  type: FETCH_LS_SETTINGS_SUCCESS,
  settings,
})
