import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAppointmentFiles, FileListPreview } from 'containers/files'
import { getSelectedClientID$ } from 'containers/clients'
import { getSelectedAppointmentID$ } from 'containers/appointments'
import { Dispatch, IFile, IResponse, IState } from 'interfaces'
import { Button, Card } from 'components/ui'
import { paginateLocally, filterFiles } from 'lib'
import { FeatureToggle } from 'containers/features'
import { FileUpload } from 'constants/features'
import { FileUploadModal } from 'components/modals'
import { addClientAppointmentFile } from 'containers/files/redux/ducks'
import { Storage } from 'constants/endpoints'

const PAGE_SIZE = 5

interface Props {
	appointmentID: string
	clientID: string
}

export const SoapFiles = (props: Props) => {
	const dispatch: Dispatch = useDispatch()
	const clientID = useSelector(getSelectedClientID$)
	const appointmentID = useSelector(getSelectedAppointmentID$)
	const files = useSelector(
		(state: IState) => state.files.appointmentFiles[appointmentID] || [],
	)

	const [page, setPage] = useState(1)
	const [searchTerm, setSearchTerm] = useState('')
	const [uploadModalOpen, setUploadModalOpen] = useState(false)
	const [uploading, setUploading] = useState(false)

	const fetchFiles = useCallback(() => {
		dispatch(fetchAppointmentFiles(clientID, appointmentID, true))
	}, [dispatch, appointmentID, clientID])

	const onSearch = (term: string) => {
		setSearchTerm(term)
		setPage(1)
	}

	const onPageChange = (activePage: number) => setPage(activePage)

	useEffect(() => {
		if (appointmentID && clientID) {
			fetchFiles()
			setPage(1)
			setSearchTerm('')
		}
	}, [appointmentID, clientID, fetchFiles])

	const filteredFiles = filterFiles(searchTerm, files)
	const pagedFiles = paginateLocally(filteredFiles, page, PAGE_SIZE)

	const onUploadFile = () => {
		setUploadModalOpen(true)
	}

	return (
		<>
			<FileUploadModal
				header='Upload File'
				loading={uploading}
				modalOpen={uploadModalOpen}
				onClose={() => setUploadModalOpen(false)}
				onComplete={(response: IResponse<IFile>) => {
					dispatch(addClientAppointmentFile(response.data, props.appointmentID))
					setUploading(false)
					setUploadModalOpen(false)
				}}
				onError={() => {
					setUploading(false)
				}}
				onStart={() => setUploading(true)}
				url={Storage.ClientEntityForm(props.clientID, props.appointmentID)}
			/>

			<Card
				extra={
					<FeatureToggle features={[FileUpload]}>
						<Button
							content='Upload File'
							icon='cloud upload'
							onClick={onUploadFile}
							primary
							style={{
								width: '100%',
							}}
						/>
					</FeatureToggle>
				}
				icon='folder'
				title='Appointment Files'
			>
				<FileListPreview
					appointmentID={props.appointmentID}
					files={pagedFiles}
					hideUpload
					onPageChange={onPageChange}
					pagination={{
						usePagination: true,
						pageSize: PAGE_SIZE,
						totalItems: filteredFiles.length,
						onPageChange: onPageChange,
						activePage: page,
					}}
					searchTerm={searchTerm}
					setSearchTerm={onSearch}
				/>
			</Card>
		</>
	)
}
