import React from 'react'
import { connect } from 'react-redux'
import { filterOffices } from 'lib/filters'

import { OfficeSetup } from 'containers/dashboard'
import { SearchBox } from 'components/forms'
import { SimpleSpinner, Spinner } from 'components/loaders'
import { parseOfficeHoursToAPI } from 'lib/fetch-parsers'
import ConfirmCancelModal from 'components/modals/confirm-cancel-modal'
import DeleteModal from 'components/modals/delete-modal'
import { Card, Button, FlexContainer } from 'components/ui'
import {
  Form,
  FormField,
  List,
  ListHeader,
  ListDescription,
  ListIcon,
  ListContent,
  ActivableListItem,
  Text,
} from 'components/ui'
import { message } from 'components/notifications'

import {
  addOffice,
  onSaveOffice,
  onDeleteOffice,
  fetchOffices,
  getOfficesList$,
} from '.'

import OfficeModal from './office-modal'
import { Office, Dispatch, IState, OfficeDay } from 'interfaces'

interface Props {
  addOffice: () => void
  fetchingOffice?: boolean
  fetchOfficesIfMissing: () => void
  loading?: boolean
  offices: Array<any>
  onCancelOffices: () => void
  onDeleteOffice: (officeID: string) => void
  onSaveOffice: (office: Office) => void
}

class Offices extends React.Component<Props> {
  state: OfficeState = {
    cancelModalOpen: false,
    officeSearchTerm: '',
    deleteModalData: {
      open: false,
      id: '',
      officeName: '',
    },
    officeModal: {
      open: false,
      id: '',
      edit: false,
    },
  }

  componentDidMount() {
    if (this.props.offices.length === 0) {
      this.props.fetchOfficesIfMissing()
    }
  }

  getDay = (dayID: number) => {
    switch (dayID) {
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thu'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'
      default:
        return ''
    }
  }

  getAddress = (office: Office) => {
    const addressTwo = office['office-addressTwo']
      ? `${office['office-addressTwo']}-`
      : ''
    const addressStr = `${addressTwo}${office['office-address'] || ''}${
      (office['office-address'] && ', ') || ''
    } ${office['office-city'] || ''}${
      (office['office-city'] && ', ') || '' || ''
    } ${office['office-province'] || ''}${
      (office['office-province'] && ', ') || ''
    } ${office['office-postalcode'] || ''}`.trim()

    if (addressStr.slice(-1) === ',') {
      return addressStr.substring(0, addressStr.length - 1)
    }

    return addressStr
  }

  onDeleteOffice = (officeID: string) =>
    this.setState(() => ({ deleteModalData: { id: officeID, open: true } }))

  onSearchChange = ({ target }) =>
    this.setState(() => ({ officeSearchTerm: target.value }))
  onSearchClear = () => this.setState(() => ({ officeSearchTerm: '' }))

  onSaveOffices = () => {
    for (const office of this.props.offices) {
      if (!office.name || office.name === '') {
        message.error('Clinic must have a name', '', 5)
        return
      }
    }

    const o = this.props.offices.find((o) => o.changesMade)
    const id = o.id === 'new' ? undefined : o.id

    const office: Office = {
      id: id,
      name: o.name,
      officeHours: parseOfficeHoursToAPI(o),
      address: {
        id: o['office-address-id'],
        addressOne: o['office-address'],
        addressTwo: o['office-addressTwo'],
        city: o['office-city'],
        postalCode: o['office-postalcode'],
        province: o['office-province'],
        isCurrent: true,
      },
    }

    this.props.onSaveOffice(office)
    this.setState(() => ({
      cancelModalOpen: false,
      officeModal: { open: false, id: '', edit: false },
    }))
  }

  onCancelOffices = () => {
    if (this.props.offices.find((o) => o.changesMade)) {
      this.setState(() => ({ cancelModalOpen: true }))
    }
  }

  renderModal = () => {
    const modalProps = {
      modalOpen: this.state.cancelModalOpen,
      message: 'Are you sure you want to cancel editing offices?',
      onAccept: () => {
        this.setState(() => ({
          cancelModalOpen: false,
          officeModal: { open: false, id: '', edit: false },
        }))
        this.props.onCancelOffices()
      },
      onCancel: () => this.setState(() => ({ cancelModalOpen: false })),
    }

    return <ConfirmCancelModal {...modalProps} />
  }

  renderDeleteModal = () => {
    const modalProps = {
      modalOpen: this.state.deleteModalData.open,
      message: this.state.deleteModalData.officeName
        ? `Do you want to delete Office: ${this.state.deleteModalData.officeName}?`
        : 'Do you want to delete this office?',
      onAccept: () => {
        this.props.onDeleteOffice(this.state.deleteModalData.id)
        this.setState(() => ({
          deleteModalData: {
            open: false,
            id: '',
          },
          officeModal: { open: false, id: '', edit: false },
        }))
      },
      onCancel: () =>
        this.setState(() => ({
          deleteModalData: {
            open: false,
            id: '',
          },
        })),
    }

    return <DeleteModal {...modalProps} />
  }

  render() {
    const { offices, addOffice } = this.props
    const filteredOffices = filterOffices(this.state.officeSearchTerm, offices)

    return (
      <>
        {!offices.length && <OfficeSetup showButton={false} />}
        {this.renderModal()}
        {this.renderDeleteModal()}
        <OfficeModal
          edit={this.state.officeModal.edit}
          id={this.state.officeModal.id}
          onAccept={this.onSaveOffices}
          onCancel={this.onCancelOffices}
          onClose={() =>
            this.setState(() => ({
              officeModal: { open: false, id: '', edit: false },
            }))
          }
          onDelete={this.onDeleteOffice}
          open={this.state.officeModal.open}
        />

        <Card
          extra={
            <div>
              <Form>
                <FormField>
                  <SearchBox
                    onChange={this.onSearchChange}
                    onClear={this.onSearchClear}
                    placeholder="Search Clinics"
                    value={this.state.officeSearchTerm}
                  />
                </FormField>
              </Form>
            </div>
          }
          titleElement={
            <FlexContainer>
              <div>
                <Form>
                  <FormField>
                    <Button
                      content="New Clinic"
                      icon="plus"
                      onClick={() => {
                        addOffice()
                        this.setState(() => ({
                          officeModal: { id: 'new', open: true, edit: true },
                        }))
                      }}
                      primary
                      size="small"
                    />
                  </FormField>
                </Form>
              </div>
            </FlexContainer>
          }
        >
          <Spinner spinning={this.props.loading}>
            <List
              collapseOnMobile={false}
              items={filteredOffices}
              itemType="Offices"
              noItemsMessage="No offices found"
              renderer={(office: Office) => (
                <ActivableListItem
                  key={office.id}
                  onClick={() =>
                    this.setState(() => ({
                      officeModal: { id: office.id, open: true },
                    }))
                  }
                >
                  <ListIcon
                    name="building"
                    size="large"
                    verticalAlign="middle"
                  />
                  <ListContent>
                    <ListHeader>{office.name}</ListHeader>
                    <ListDescription>{this.getAddress(office)}</ListDescription>
                    <ListDescription>
                      <div style={{ display: 'flex' }}>
                        <Text>Open: &nbsp;</Text>
                        {office.days
                          .sort(
                            (a: OfficeDay, b: OfficeDay) => a.dayID - b.dayID,
                          )
                          .map((day, index) => (
                            <Text key={day.dayID}>
                              {`${this.getDay(day.dayID)}${
                                index === office.days.length - 1 ? '' : ','
                              }`}
                              &nbsp;
                            </Text>
                          ))}
                      </div>
                    </ListDescription>
                  </ListContent>
                </ActivableListItem>
              )}
            />
          </Spinner>

          {this.props.fetchingOffice && <SimpleSpinner />}
        </Card>
      </>
    )
  }
}

interface OfficeState {
  cancelModalOpen: boolean
  officeSearchTerm: string
  deleteModalData: {
    open?: boolean
    id: string
    officeName: string
  }
  officeModal: {
    open: boolean
    id: string
    edit: boolean
  }
}

const mapStateToProps = (state: IState) => ({
  ctUser: state.user.ctUser,
  offices: getOfficesList$(state),
  loading: state.account.loading,
  fetchingOffice: state.account.fetchingOffice,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addOffice: () => dispatch(addOffice()),
  onSaveOffice: (office: Office) => dispatch(onSaveOffice(office)),
  onDeleteOffice: (officeID: string) => dispatch(onDeleteOffice(officeID)),
  onCancelOffices: () => dispatch(fetchOffices()),
  fetchOfficesIfMissing: () => dispatch(fetchOffices({ useCache: false })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Offices)
