import React from 'react'
import { Divider, Pagination } from 'components/ui'
import { useSelector } from 'react-redux'

import { Text } from 'components/ui'
import { AUDIT_PAGE_SIZE } from '.'
import { Audit as IAudit } from './interfaces'
import { Audit } from './audit'
import { getClients$ } from 'containers/clients'

interface Props {
  audits: IAudit[]
  totalCount: number
  page: number
  onPageChange: (page: number) => void
}

export const Audits = ({
  audits = [],
  totalCount = 0,
  page = 1,
  onPageChange,
}: Props) => {
  const clients = useSelector(getClients$)
  const totalPages = Math.ceil(totalCount / AUDIT_PAGE_SIZE)

  const getClient = (audit: IAudit) => {
    const client = clients[audit.subEntityID] || clients[audit.entityID]

    // TODO - Archived clients aren't being fetched, so we don't have access to them
    //      - Either fetch all clients on app startup, or fetch all archived clients and store in redux
    //        on archived client view or on audit container start
    if (client == null && audit.value) {
      const auditValue = JSON.parse(audit.value)
      const clientIDFromAuditValue =
        auditValue?.ClientID || auditValue?.clientID

      return clients[clientIDFromAuditValue]
    }

    return client
  }

  return (
    <>
      {totalCount > 0 && <Text>{`Total: ${totalCount}`}</Text>}
      {audits.map((audit: IAudit, index: number) => (
        <div key={`${index}${audit.entityID}${audit.dateTime}`}>
          <Audit audit={audit} client={getClient(audit)} />
          {index + 1 < audits.length && <Divider />}
        </div>
      ))}

      {totalPages > 1 && (
        <Pagination
          activePage={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </>
  )
}
