import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authPost } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'
import { message } from 'components/notifications'
import { PublicIntakeState, IAction, Error } from 'interfaces'

const action = (action: string) => `client-trackyr/public-intake/${action}`
const CREATE_PUBLIC_INTAKE_FORM = action('CREATE_PUBLIC_INTAKE_FORM')
const CREATE_PUBLIC_INTAKE_FORM_SUCCESS = action(
  'CREATE_PUBLIC_INTAKE_FORM_SUCCESS',
)
const CREATE_PUBLIC_INTAKE_FORM_FAILURE = action(
  'CREATE_PUBLIC_INTAKE_FORM_FAILURE',
)

export const defaultState = {
  creatingForm: true,
} as PublicIntakeState

export const reducer = (
  state: PublicIntakeState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case CREATE_PUBLIC_INTAKE_FORM:
      return {
        ...state,
        creatingForm: true,
        sendingPublicIntakeEmailError: { hasError: false },
      }

    case CREATE_PUBLIC_INTAKE_FORM_SUCCESS: {
      const publicIntake = action.response

      const clientIntakes = state[publicIntake.clientID].publicIntakeForms
      clientIntakes.push(publicIntake)

      return {
        ...state,
        [publicIntake.clientID]: {
          ...state[publicIntake.clientID],
          publicIntakeForms: [...clientIntakes],
          totalCount: state[publicIntake.clientID].totalCount + 1,
        },
        creatingForm: false,
      }
    }

    case CREATE_PUBLIC_INTAKE_FORM_FAILURE:
      return {
        ...state,
        creatingForm: false,
        sendingPublicIntakeEmailError: {
          hasError: true,
          message: 'Failed to load all forms',
          details: action.error,
        },
      }

    default:
      return state
  }
}

/**
 * Fetches all forms
 * @param {Object} queryParams queryParams Object
 */
const createPublicIntakeForm = (
  publicIntakeForm: any,
  ignoreSubscription = false,
): any => async (dispatch: any): Promise<any> => {
  dispatch({
    type: CREATE_PUBLIC_INTAKE_FORM,
    publicIntakeForm,
    ignoreSubscription,
  })

  return authPost(PublicIntake.Create(ignoreSubscription), publicIntakeForm)
    .then((response) => {
      message.success('Email Sent!')
      dispatch(createSuccess(response.data))
      return response
    })
    .catch((error: Error) => {
      if (error.response.status === 403) {
        dispatch(createFailure(error))
        throw error
      }

      dispatch(createFailure(error))
      message.error('Email failed to send')
      captureMessage(
        `createPublicIntakeForm Get Failed: ${error}`,
        Severity.Error,
      )

      throw error
    })
}

const createSuccess = (response: any) => ({
  type: CREATE_PUBLIC_INTAKE_FORM_SUCCESS,
  response,
})

const createFailure = (error: any) => ({
  type: CREATE_PUBLIC_INTAKE_FORM_FAILURE,
  error,
})

export { createPublicIntakeForm }
