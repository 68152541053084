import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import { GlobalSummaryTab, SummaryTab } from 'constants/features'
import { SearchBox } from 'components/forms'
import { getClientFullName } from 'containers/clients'
import { message } from 'components/notifications'
import {
	Avatar,
	AvatarText,
	IconButton,
	Responsive,
	Table,
	Text,
	H4,
	Popup,
	SelectableText,
	TableRow,
	TableCell,
} from 'components/ui'
import { IClient, IEvent, IState } from 'interfaces'
import { ErrorBoundary } from 'components/error'

const Additional = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`

const NameCell = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  color: ${(props) => props.theme.LinkColour};
`

const HoverIcon = styled(IconButton)`
  font-size: 1.3em;
  margin-left: 0.75em !important;
`

const renderTable = (
	clientName: string,
	firstName: string,
	lastName: string,
	phoneNumbers: any,
	email: string,
	id: string,
	onClickCallback: Function,
	i: number,
	selected: boolean,
	showSummary: boolean,
	navigate: NavigateFunction,
) => {
	return (
		<TableRow key={`f${id}-${i}`} selected={selected}>
			<TableCell>
				<NameCell
					key={`name-${id}-${i}`}
					onClick={() => {
						onClickCallback()
						navigate(showSummary ? `/client/${id}/summary` : `/client/${id}/notes`)
					}}
				>
					<ClientAvatar
						firstName={firstName || undefined}
						lastName={lastName || undefined}
					/>
					{clientName}
				</NameCell>
			</TableCell>
			<TableCell>
				<Popup
					content='Copy Phone Number to Clipboard'
					position='top center'
					trigger={
						<ErrorBoundary>
							<SelectableText
								key={`p${id}-${i}`}
								onClick={() => {
									copy(phoneNumbers?.[0]?.phoneNumber)
									message.info('Phone Number Copied')
								}}
							>
								{phoneNumbers?.[0]?.phoneNumber || '-'}
							</SelectableText>
						</ErrorBoundary>
					}
				/>
			</TableCell>
			<TableCell>
				<Popup
					content='Copy Email Address to Clipboard'
					position='top center'
					trigger={
						<SelectableText
							key={`e${id}-${i}`}
							onClick={() => {
								copy(email)
								message.info('Email Copied')
							}}
						>
							{email || '-'}
						</SelectableText>
					}
				/>
			</TableCell>
			{/*<TableCell textAlign="right">*/}
			{/*  <Options*/}
			{/*    key={`${id}-${i}-options`}*/}
			{/*    clientID={id}*/}
			{/*    onClickCallback={onClickCallback}*/}
			{/*    showSummary={showSummary}*/}
			{/*  />*/}
			{/*</TableCell>*/}
		</TableRow>
	)
}

const renderTableMobile = (
	clientName: string,
	phoneNumbers: any,
	email: string,
	id: string,
	onClickCallback: Function,
	i: number,
	navigate: NavigateFunction,
) => {
	return (
		<Table.Row key={`m${id}-${i}`}>
			<TableCell>
				{/* <div key={`card-${id}-${i}`} style={{ marginBottom: '1em' }}> */}
				<NameCell
					onClick={() => {
						onClickCallback()
						navigate(`/client/${id}/notes`)
					}}
				>
					<H4>{clientName}</H4>
				</NameCell>

				{!!phoneNumbers && (
					<ErrorBoundary>
						<Text
							key={`p${id}-${i}`}
							style={{ paddingTop: '0.25em', marginBottom: '0' }}
						>
							{phoneNumbers?.[0]?.phoneNumber}
						</Text>
					</ErrorBoundary>
				)}

				{!!email && (
					<Text key={`e${id}-${i}`} style={{ paddingTop: '0' }}>
						{email}
					</Text>
				)}
				{/* </div> */}
				{/* </Card> */}
			</TableCell>
		</Table.Row>
	)
}

const ClientsList = (props: IClientsList) => {
	const navigate = useNavigate()

	return (
		<>
			{props.search && (
				<SearchBox
					onChange={props.onSearch}
					onClear={props.onClear}
					onSearch={props.onSearch}
					value={props.searchTerm}
				/>
			)}
			<Responsive maxWidth={768}>
				<Table>
					<Table.Body>
						{props.clients.map((client, i) => {
							if (!client) {
								return null
							}

							return renderTableMobile(
								getClientFullName(client),
								client?.phoneNumbers,
								client?.email,
								client?.id,
								props.onClickCallback,
								i,
								navigate,
							)
						})}
					</Table.Body>
				</Table>
			</Responsive>
			<Responsive minWidth={768}>
				<Table>
					<Table.Body>
						{props.clients.map((client, i) => {
							if (!client) {
								return null
							}
							return renderTable(
								getClientFullName(client),
								client.firstName,
								client.lastName,
								client.phoneNumbers,
								client.email,
								client.id,
								props.onClickCallback,
								i,
								props.selected === i,
								props.showSummary,
								navigate,
							)
						})}
					</Table.Body>
				</Table>
			</Responsive>
		</>
	)
}

const ClientAction = ({ type, popoverText, onClick }: IClientAction) => (
	<Popup
		content={popoverText}
		position='top center'
		trigger={
			<div>
				<HoverIcon name={type} onClick={onClick} size='large' />
			</div>
		}
	/>
)

// TODO - Avatar to it's own component
const ClientAvatar = ({ firstName = '', lastName = '' }: IClientAvatar) => (
	<Avatar style={{ verticalAlign: 'middle' }}>
		<div>
			<AvatarText>{`${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`}</AvatarText>
		</div>
	</Avatar>
)

const Options = ({ clientID, onClickCallback, showSummary }: any) => {
	const navigate = useNavigate()

	return (
		<Additional>
			<ClientAction
				key={`appointment-${clientID}`}
				onClick={() => navigate(`/calendar/${clientID}`)}
				popoverText='Schedule Appointment'
				type='calendar'
			/>
			{showSummary && (
				<ClientAction
					key={`summary-${clientID}`}
					onClick={() => {
						onClickCallback()
						navigate(`/client/${clientID}/summary`)
					}}
					popoverText='Summary'
					type='address card'
				/>
			)}
			<ClientAction
				key={`info-${clientID}`}
				onClick={() => {
					onClickCallback()
					navigate(`/client/${clientID}/client-info`)
				}}
				popoverText='Client Info'
				type='user'
			/>
			<ClientAction
				key={`notes-${clientID}`}
				onClick={() => {
					onClickCallback()
					navigate(`/client/${clientID}/notes`)
				}}
				popoverText='Notes'
				type='edit'
			/>
		</Additional>
	)
}

interface IClientsList {
	clients: Array<IClient>
	// loading: boolean
	onClear?: Function
	onClickCallback?: Function
	onSearch?: (e: IEvent) => void
	// pageSize: number
	search: boolean
	searchTerm?: string
	selected?: number
	showSummary?: boolean
	// size?: string
}

interface IClientAction {
	onClick: Function
	popoverText: string
	type: string
}

interface IClientAvatar {
	firstName: string
	lastName: string
}

ClientAvatar.defaultProps = {
	firstName: ' ',
	lastName: ' ',
}

ClientsList.defaultProps = {
	loading: false,
	onClickCallback: () => {
	},
	pageSize: 10,
	pagination: false,
	search: false,
	searchTerm: '',
	size: 'small',
}

Options.defaultProps = {
	onClickCallback: () => {
	},
}

const mapStateToProps = (state: IState) => ({
	showSummary:
		state.features.features[GlobalSummaryTab] &&
		state.features.features[SummaryTab],
})

export default connect(mapStateToProps)(ClientsList)
