import React from 'react'
import { onChange, validateForm } from './redux/ducks'
import { connect } from 'react-redux'

import { IForm, IFormsData } from 'interfaces/forms'
import { IState, Dispatch, IEvent } from 'interfaces'
import { Print } from 'components/ui'
import { FormGenerator } from './form-generator'
import { FormGeneratorPrinterView } from './form-generator-printer-view'

// import { getFormData$ } from './selectors'

interface State {
	forms: IForm
	formsData: IFormsData
	fetching: boolean
}

class CreateForm extends React.Component<Props> {
	public static defaultProps: Partial<Props> = {
		autosave: true,
	}

	state: State = {
		forms: {} as IForm,
		formsData: {} as IFormsData,
		fetching: true,
	}

	componentDidMount() {
		if (!this.props.form) {
			// this.fetchForm(/*this.props.userID*/)
		} else {
			this.setState(() => ({ forms: this.props.form, fetching: false }))
		}

		// this.fetchFormData(/*this.props.formID*/)
	}

	fetchForm = (/*id*/) => {
		// this.setState(() => ({ forms: {} })) // TODO - fetch form
	}

	fetchFormData = () => {
		// setTimeout(() => this.setState(() => ({ fetching: false })), 1500)
	}

	createFormData = () => {
	}

	render() {
		const formName = `${this.props.formID}:${this.props.entityID}`

		// Dumb, but works for now
		if (this.props.printParams?.printingView) {
			return (
				<FormGeneratorPrinterView
					key={this.props.formID}
					entityID={this.props.entityID}
					fetching={this.state.fetching}
					formID={this.props.formID}
					forms={this.state.forms}
					formsData={
						this.props.formsDataOverride
							? this.props.formsDataOverride
							: this.props.formsData
					}
					namePrefix={this.props.namePrefix}
					printParams={this.props.printParams}
				/>
			)
		}

		return (
			<FormGenerator
				key={this.props.formID}
				autosave={this.props.autosave}
				entityID={this.props.entityID}
				fetching={this.state.fetching || this.props.fetching}
				formID={this.props.formID}
				forms={this.state.forms}
				formsData={
					this.props.formsDataOverride
						? this.props.formsDataOverride
						: this.props.formsData
				}
				namePrefix={this.props.namePrefix}
				onChange={
					this.props.onChangeOverride
						? (e: IEvent) =>
							this.props.onChangeOverride(
								formName,
								e.target.name,
								e.target.value,
							)
						: (e: IEvent) =>
							this.props.onChange(formName, e.target.name, e.target.value)
				}
				patch={this.props.patch}
				patchEndpoint={this.props.patchEndpoint}
				// onChangeCallback={this.props.onChangeCallback}
				printParams={this.props.printParams}
				readonly={this.props.readonly}
				validateForm={
					this.props.validateFormOverride
						? (name: string, validation: any) =>
							this.props.validateFormOverride(formName, name, validation)
						: (name: string, validation: any) =>
							this.props.validateForm(formName, name, validation)
				}
			/>
		)
	}
}

interface Props {
	autosave: boolean
	entityID: string
	fetching?: boolean
	form: Object
	formID: string
	formsData?: Object
	formsDataOverride?: Object
	namePrefix?: string
	onChange?: Function
	// onChangeCallback?: Function
	onChangeOverride?: Function
	patch?: (e: IEvent, t?: any) => Promise<any>
	patchEndpoint: string
	printParams?: Print
	readonly?: boolean
	// userID?: string
	validateForm?: Function
	validateFormOverride?: Function
}

const mapStateToProps = (state: IState, props: Props) => ({
	formsData: state.forms[`${props.formID}:${props.entityID}`], // TODO - make selector
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: (formID: string, name: string, value: any) =>
		dispatch(onChange(formID, name, value)),
	validateForm: (formID: string, name: string, validation: any) =>
		dispatch(validateForm(formID, name, validation)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm)
