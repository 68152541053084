import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authGet } from 'lib/http-request'
import { Treatments } from 'constants/endpoints'
import { updateClientTypeIDs } from 'containers/clients'

const treatmentAction = (action) => `client-trackyr/treatments/${action}`
const FETCH_ALL_TREATMENTS = treatmentAction('FETCH_ALL_TREATMENTS')
const FETCH_ALL_TREATMENTS_SUCCESS = treatmentAction(
  'FETCH_ALL_TREATMENTS_SUCCESS',
)
const FETCH_ALL_TREATMENTS_FAILURE = treatmentAction(
  'FETCH_ALL_TREATMENTS_FAILURE',
)

export const defaultState = {
  fetchingAllTreatments: false,
}

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ALL_TREATMENTS:
      return {
        ...state,
        fetchingAllTreatments: true,
      }

    case FETCH_ALL_TREATMENTS_SUCCESS: {
      const treatments = action.payload
      const stateTreatments = {}

      for (const treatment of treatments) {
        const { id, userID, ...rest } = treatment // eslint-disable-line no-unused-vars
        treatments[id] = {
          id,
          ...rest,
        }

        const allByID = [id]

        stateTreatments[treatment.clientID] = {
          ...state[action.clientID],
          [id]: {
            ...treatment,
          },
          currentTreatment: id,
          fetched: false, // Not all treatments have been fetched
          allByID,
        }
      }

      return {
        ...state,
        ...stateTreatments,
        fetchingAllTreatments: false,
      }
    }

    case FETCH_ALL_TREATMENTS_FAILURE:
      return {
        ...state,
        fetchingAllTreatments: false,
      }

    default:
      return state
  }
}

export const fetchAllTreatments = (): any => async (dispatch): Promise<any> => {
  dispatch({ type: FETCH_ALL_TREATMENTS })

  return authGet(Treatments.GetAll)
    .then((response) => {
      dispatch(fetchAllTreatmentsSuccess(response.data))
      dispatch(
        updateClientTypeIDs(
          response.data.map((t) => ({
            clientID: t.clientID,
            clientTypeID: t.clientTypeID,
          })),
        ),
      )
      return response
    })
    .catch((error) => {
      captureMessage(`fetchAllTreatments Failed. ${error}`, Severity.Error)
      dispatch(fetchAllTreatmentsFailure(error))

      return error
    })
}

export const fetchAllTreatmentsSuccess = (treatments) => ({
  type: FETCH_ALL_TREATMENTS_SUCCESS,
  payload: treatments,
})

export const fetchAllTreatmentsFailure = (error) => ({
  type: FETCH_ALL_TREATMENTS_FAILURE,
  error,
})
