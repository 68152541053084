import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const routersMiddleware = routerMiddleware(history)

const middlewares = [thunkMiddleware]

const configureStore = () => {
	const store = createStore(
		rootReducer,
		{},
		composeEnhancers(applyMiddleware(...middlewares)),
	)

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('./reducers', () => {
			store.replaceReducer(rootReducer)
		})
	}

	return store
}

const store = configureStore()
export default store
