import React from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom'

import { Container, Tab, ContainerLayout } from 'components/ui'
import SoapNotes from 'containers/soap-notes'
import {
	fetchAppointmentsForClient,
	unselectAppointment,
} from 'containers/appointments'
import {
	setCurrentClient,
	getSelectedClientFullName$,
	getSelectedClientID$,
	getSelectedClient$,
} from 'containers/clients'
import { ErrorBoundary } from 'components/error'
import { setNavBarText } from 'components/nav'
import {
	TreatmentButton,
	fetchTreatmentsForClient,
	selectTreatment,
} from 'containers/treatment'
import {
	getSeletectedTreatment$,
	getSeletectedTreatmentNumber$,
} from 'containers/treatment/selectors'
import {
	getTabTypes,
	getClientTypeBySelectedClient,
	getClientTypeTabsBySelectedClient$,
} from 'containers/settings'
import PracticeType from 'components/practice-type/practice-type'
import { Spinner } from 'components/loaders'
import { ClientFiles } from 'containers/files'
import { defaultTabs } from 'constants/settings'

import { ClientSummary } from 'containers/clients/summary/client-summary'
import { FeatureToggle } from 'containers/features'
import * as Features from 'constants/features'

import * as Ducks from '.'
import Info from './components/info'
import { NotFound } from 'components/response-status'
import {
	IState,
	IClient,
	Treatment,
	ClientInfoURLParams,
} from 'interfaces'

import {
	SUMMARY_TAB,
	CLIENT_INFO_TAB,
	NOTES_TAB,
	FILES_TAB,
} from 'constants/tabs'
import { EditSwitch } from './components/edit-switch'
import NewSoapButton from 'components/appointments/new-soap-button'
import { GenericTab } from './components/generic-tab'
import { onSetEdit } from './redux/ducks'
import {
	FormTabs_T,
} from 'containers/settings/form-types/new-interfaces'
import SoapAppointments from 'containers/soap-notes/components/soap-appointments'
import styled from 'styled-components'
import { MobileBreakPoint } from 'styles'

// TODO - this file is too big and complicated - clean it up

const AppointmentsAndInfoContainer = styled.div`
  display: flex;

  @media (max-width: ${MobileBreakPoint}) {
    flex-direction: column;
  }
`

export class ClientInfo extends React.Component<IClientInfo> {
	componentDidMount() {
		this.props.unselectAppointment()
		this.props.onMountSetEditToDefault()
		this.fetchClientInfo()
	}

	componentDidUpdate(prevProps: IClientInfo) {
		if (
			prevProps.params.clientID !== this.props.params.clientID ||
			prevProps.params.treatmentNumber !==
			this.props.params.treatmentNumber
		) {
			this.props.onMountSetEditToDefault()
			this.fetchClientInfo()
		}

		if (
			prevProps.clientName !== this.props.clientName ||
			this.props.navText !== this.props.clientName
		) {
			this.props.setNavBarText(this.props.clientName)
		}
	}

	fetchClientInfo = () => {
		const clientID = this.props.params.clientID
		this.props.setCurrentClient(clientID)
		const treatmentNumberParam = Number(this.props.params.treatmentNumber)

		this.props.fetchTreatmentsForClient(clientID).then(() => {
			const clientTreatments = this.props.treatments[clientID] || {}
			const currentTreatment =
				clientTreatments[clientTreatments.currentTreatment] || {}

			const treatmentNumber =
				treatmentNumberParam || currentTreatment.treatmentNumber || 0
			this.props.selectTreatment(clientID, treatmentNumber)
			this.props.fetchAppointmentsForClient(clientID, treatmentNumber, true)

			if (
				this.props.clientID &&
				this.props.selectedTreatment &&
				!this.props.client.fetched
			) {
				this.props.fetchClientDetails(
					this.props.clientID,
					this.props.selectedTreatment.treatmentNumber,
				)
			}
		})
	}

	setActiveTab = (
		clientID: string,
		tabIndex: number,
		treatmentNumber: number,
		tabs: Array<any>,
	) => {
		treatmentNumber
			? this.props.navigate(`/client/${clientID}/${tabs[tabIndex].key}/treatment/${treatmentNumber}`)
			: this.props.navigate(`/client/${clientID}/${tabs[tabIndex].key}`)
	}

	tabPanes = (tabs: Array<FormTabs_T>) => {
		if (!tabs) {
			return []
		}

		const panes = []
		for (const tab of tabs) {
			if (tab.enabled === false) {
				continue
			}

			if (tab.id === defaultTabs[0].id) {
				if (this.props.showSummaryTab) {
					panes.push({
						menuItem: {
							key: SUMMARY_TAB,
							icon: 'address card',
							content: 'Summary',
						},
						render: () => (
							<ErrorBoundary>
								<FeatureToggle
									features={[Features.GlobalSummaryTab, Features.SummaryTab]}
								>
									<ClientSummary />
								</FeatureToggle>
							</ErrorBoundary>
						),
					})
				} else {
					panes.push(null)
				}
			} else if (tab.id === defaultTabs[1].id) {
				panes.push({
					menuItem: {
						key: CLIENT_INFO_TAB,
						icon: 'user',
						content: 'Client Info',
					},
					render: () => (
						<ErrorBoundary>
							<Info forms={tab.forms} />
						</ErrorBoundary>
					),
				})
			} else if (tab.id === defaultTabs[2].id) {
				panes.push({
					menuItem: {
						key: NOTES_TAB,
						icon: 'edit',
						content: 'Notes',
					},
					render: () => (
						<ErrorBoundary>
							<SoapNotes forms={tab.forms} />
						</ErrorBoundary>
					),
				})
			} else if (tab.id === defaultTabs[3].id) {
				if (this.props.showFilesTab) {
					panes.push({
						menuItem: {
							key: FILES_TAB,
							icon: 'folder',
							content: 'Files',
						},
						render: () => (
							<ErrorBoundary>
								<FeatureToggle features={[Features.FileUpload]}>
									<ClientFiles />
								</FeatureToggle>
							</ErrorBoundary>
						),
					})
				} else {
					panes.push(null)
				}
			} else {
				panes.push({
					menuItem: {
						key: tab.key,
						icon: tab.icon || 'clipboard',
						content: tab.tab,
					},
					render: () => (
						<ErrorBoundary>
							<GenericTab tab={tab} />
						</ErrorBoundary>
					),
				})
			}
		}

		return panes
	}

	render() {
		if (this.props.clientNotFound) {
			return <NotFound />
		}

		const { clientID } = this.props
		const tabs = this.props.clientTabs
		const activeTab = tabs?.findIndex(
			(tab) => tab.key === this.props.params.tab,
		)
		const panes = this.tabPanes(tabs)

		if (
			this.props.fetchingForms ||
			this.props.fetchingClient ||
			this.props.fetchingTreatments
		) {
			return <Spinner size='large' spinning />
		}

		return (
			<ContainerLayout more>
				<Container>
					{clientID && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginBottom: '0.5em',
							}}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
								}}
							>
								<TreatmentButton clientID={clientID} />
								{!this.props.selectedTreatment.closed && <NewSoapButton />}
							</div>
							{!this.props.selectedTreatment.closed && (
								<EditSwitch />
							)}
						</div>
					)}

					<AppointmentsAndInfoContainer>
						<div
							style={{
								marginRight: '1em',
								marginTop: '4.5em',
							}}
						>
							<SoapAppointments />
						</div>

						<div style={{ width: '100%' }}>
							<Tab
								activeIndex={activeTab === -1 ? 0 : activeTab}
								menu={{ secondary: true, pointing: true }}
								onTabChange={(_: any, { activeIndex }) =>
									this.setActiveTab(
										clientID,
										activeIndex,
										this.props.params.treatmentNumber,
										tabs,
									)
								}
								panes={panes}
								size='large'
							/>
						</div>
					</AppointmentsAndInfoContainer>
				</Container>
			</ContainerLayout>
		)
	}
}

interface IClientInfo {
	client: IClient
	clientID: string
	clientName?: string
	clientNotFound?: boolean
	clientTabs: FormTabs_T[]
	fetchAppointmentsForClient: Function
	fetchClientDetails: Function
	fetchTreatmentsForClient: Function
	fetchingClient?: boolean
	fetchingForms?: boolean
	fetchingTreatments?: boolean
	navText?: string
	navigate?: NavigateFunction
	onMountSetEditToDefault: Function
	params?: ClientInfoURLParams
	selectTreatment: Function
	selectedTreatment?: Treatment
	setCurrentClient: Function
	setNavBarText: Function
	showFilesTab?: boolean
	showSummaryTab?: boolean
	treatments?: Object
	unselectAppointment: Function
}

const mapStateToProps = (state: IState) => ({
	client: getSelectedClient$(state),
	clientID: getSelectedClientID$(state),
	clientName: getSelectedClientFullName$(state),
	clientTabs: getClientTypeTabsBySelectedClient$(state),
	clientType: getClientTypeBySelectedClient(state),
	clientNotFound: state.clients.clientNotFound,
	clients: state.clients.clients,
	fetchingClient: state.clients.fetchingClient,
	fetchingForms: state.forms.fetchingForms,
	fetchingTreatments: state.treatments.fetchingTreatments,
	navText: state.navbar.text,
	selectedTreatment: getSeletectedTreatment$(state),
	selectedTreatmentNumber: getSeletectedTreatmentNumber$(state),
	showSummaryTab:
		state.features.features[Features.GlobalSummaryTab] &&
		state.features.features[Features.SummaryTab],
	showFilesTab: state.features.features[Features.FileUpload],
	todaysClientErrors: state.clients.todaysClientErrors,
	tabSettings: getTabTypes(state),
	treatments: state.treatments,
})

const mapDispatchToProps = (dispatch: any) => ({
	fetchClientDetails: (clientID: string, selectedTreatmentNumber: number) =>
		dispatch(Ducks.fetchClientDetails(clientID, selectedTreatmentNumber)),
	setCurrentClient: (clientID: string) => dispatch(setCurrentClient(clientID)),
	setNavBarText: (text: string) => dispatch(setNavBarText(text)),
	fetchTreatmentsForClient: (clientID: string) =>
		dispatch(fetchTreatmentsForClient(clientID)),
	selectTreatment: (clientID: string, treatmentNumber: number) =>
		dispatch(selectTreatment(clientID, treatmentNumber)),
	fetchAppointmentsForClient: (
		clientID: string,
		treatmentNumber: number,
		useCache: boolean,
	) =>
		dispatch(
			fetchAppointmentsForClient(clientID, treatmentNumber, {}, useCache),
		),
	unselectAppointment: () => dispatch(unselectAppointment()),
	onMountSetEditToDefault: () => {
		dispatch(onSetEdit('client', true))
		dispatch(onSetEdit('soap', true))
		dispatch(onSetEdit('rest', true))
	},
})

const ClientInfoWrapper = (props: IClientInfo) => {
	const params = useParams()
	const navigate = useNavigate()

	return <ClientInfo {...props} navigate={navigate} params={params} />
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PracticeType(ClientInfoWrapper))
