import React from 'react'
import styled from 'styled-components'

const HR = styled.hr`
  color: ${(props) => props.theme.LineColour};
`

interface Props {
  style?: any
}

export const Line = ({ style }: Props) => {
  return <HR style={style} />
}
