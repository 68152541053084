import React from 'react'
import { FormField, SelectableText, LinkText, IconButton } from 'components/ui'
import { emptyGuid } from 'lib/guid'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  margin-bottom: 1em;
`

interface Props {
	clientID: string
	gotoNotes: boolean
	appointmentID: string
}

export const AppointmentModalClientNav = (props: Props) => {
	const navigate = useNavigate()

	return (
		(props.gotoNotes && props.clientID && props.clientID !== emptyGuid && (
			<Container>
				{
					<FormField style={{ paddingRight: '1em' }}>
						<SelectableText>
							<LinkText fontSize={18}>
								<IconButton name='address card' /> Client
							</LinkText>
						</SelectableText>
					</FormField>
				}

				<FormField>
					<SelectableText
						onClick={() => {
							navigate(`/client/${props.clientID}/notes/${props.appointmentID}`)
						}}
					>
						<LinkText fontSize={18}>
							<IconButton name='edit' /> Notes for Appointment
						</LinkText>
					</SelectableText>
				</FormField>
			</Container>
		)) ||
		null
	)
}
