import React, { useState } from 'react'

import { Button, Modal } from 'components/ui'
import { FormBuilder } from './form-builder'
import { invalidateForm } from 'lib/cache'
import { useDispatch } from 'react-redux'
import { fetchForms } from 'containers/forms'
import { Dispatch, IForm } from 'interfaces'
import { fetchAccountForms } from 'containers/account'
import { message } from 'components/notifications'
import { ConfirmCancelModal } from 'components/modals'

interface Props {
	formID?: string
	formJson?: IForm
	// onAccept: () => void
	onClose: () => void
	open: boolean
}

export const FormBuilderModal = (props: Props) => {
	const dispatch: Dispatch = useDispatch()
	const [changesMade, scm] = useState(false)
	const [cancelOpen, setCancelOpen] = useState(false)

	const setChangesMade = () => scm(true)
	const disabledButtonClick = () => message.info('You have unsaved changes')

	const onSuccess = (formID: string, newID: string) => {
		invalidateForm(formID)

		if (!formID) {
			dispatch(fetchAccountForms())
			dispatch(fetchForms([{ id: newID }]))
			props.onClose()
		}
		scm(false)
	}

	const onClose = () => {
		dispatch(fetchForms([{ id: props.formID }]))
		onCancelCancel()
		scm(false)
		props.onClose()
	}

	// const onDone = () => {

	//   onClose()
	// }

	const onCancel = () => (changesMade ? setCancelOpen(true) : onClose())
	const onCancelCancel = () => setCancelOpen(false)

	return (
		<>
			<ConfirmCancelModal
				message='Cancel Changes?'
				modalOpen={cancelOpen}
				onAccept={onClose}
				onCancel={onCancelCancel}
			/>

			<Modal
				closeOnDimmerClick={false}
				onClose={props.onClose}
				open={props.open}
				size='fullscreen'
			>
				<Modal.Content>
					<FormBuilder
						formID={props.formID}
						formJson={props.formJson}
						onUploadSuccess={onSuccess}
						setChangesMade={setChangesMade}
					/>
				</Modal.Content>

				<Modal.Actions style={{ marginTop: '-2.5em' }}>
					<Button
						content={changesMade ? 'Cancel' : 'Close'}
						icon='arrow alternate circle left'
						onClick={onCancel}
						secondary
					/>

					<Button
						content='Done'
						disabled={changesMade}
						icon='checkmark'
						onClick={onClose}
						onClickDisabled={disabledButtonClick}
						primary
					/>
				</Modal.Actions>
			</Modal>
		</>
	)
}
