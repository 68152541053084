import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { currentVersion } from 'config/config'
import { TextArea, Dropdown, Input } from 'components/forms'
import {
	Form,
	Button,
	Modal,
	Header,
	Icon,
	Text,
	H5,
	Divider,
} from 'components/ui'
import { sendContactUsEmail } from 'containers/emails'
import { Dispatch, IResponse, IState } from 'interfaces'
import { Validation } from 'components/forms/interfaces'
import { useEffect } from 'react'
import { authGet } from 'lib/http-request'
import { Ping } from 'constants/endpoints'
import { isEmailValid } from 'lib'

const ContactUsText = styled(H5)`
  color: ${(props) => props.theme.LinkAsTextColour};
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.LinkColourHighlight};
  }
`

const subjectOptions = [
	{
		key: 'bug',
		id: 'bug',
		text: 'Report a Bug or Error',
		value: 'bug',
	},
	{
		key: 'techHelp',
		id: 'techHelp',
		text: 'Technical Support',
		value: 'techHelp',
	},
	{
		key: 'feature',
		id: 'feature',
		text: 'Feature Request',
		value: 'feature',
	},
	{
		key: 'general',
		id: 'general',
		text: 'General Question or Other',
		value: 'general',
	},
]

interface Props {
	authenticated: boolean
}

const ContactUsFooter = (props: Props) => {
	const defaultEmail = useSelector((state: IState) => state.user.ctUser.email)

	const [modalOpen, setModalOpen] = useState(false)
	const [subject, setSubject] = useState('')
	const [email, setEmail] = useState(defaultEmail)
	const [userSubject, setUserSubject] = useState('')
	const [message, setMessage] = useState('')
	const [validation, setValidationState] = useState({} as Validation)
	const [canSend, setCanSend] = useState(true)
	const sendingMessage = useSelector(
		(state: IState) => state.emails.sendingContactUsEmail,
	)
	const [hasConnection, setHasConnection] = useState(false)

	const checkConnection = async () => {
		try {
			const response: IResponse<any> = await authGet(Ping.HealthCheck)

			if (response?.status === 204) {
				setHasConnection(true)
			}
		} catch {
			setHasConnection(false)
		}
	}

	useEffect(() => {
		if (!hasConnection) {
			checkConnection()
		}
	}, [checkConnection, hasConnection])

	const dispatch: Dispatch = useDispatch()

	const onSendClick = async () => {
		try {
			const response: IResponse<any> = await authGet(Ping.HealthCheck)

			if (response?.status === 204) {
				setModalOpen(true)
				setHasConnection(true)
			} else {
				setHasConnection(false)
			}
		} catch {
			setHasConnection(false)
		}
	}

	const sendEmail: (email: string, subject: string, message: string) => any =
		useCallback(
			(email, subject, message) =>
				dispatch(sendContactUsEmail(email, subject, message)),
			[dispatch],
		)

	const onSubmit = () => {
		let isValid = true
		let val = {}

		if (!subject) {
			isValid = false
			val = {
				...val,
				subjectValidation: {
					validateStatus: 'error',
					help: 'Subject is required',
				},
			}
		}

		if (!email) {
			isValid = false
			val = {
				...val,
				emailValidation: {
					validateStatus: 'error',
					help: 'Email is required',
				},
			}
		} else if (!isEmailValid(email)) {
			isValid = false
			val = {
				...val,
				emailValidation: {
					validateStatus: 'error',
					help: 'Invalid Email Address',
				},
			}
		}

		if (!message) {
			isValid = false
			val = {
				...val,
				messageValidation: {
					validateStatus: 'error',
					help: 'You must provide a message',
				},
			}
		}

		if (!isValid) {
			setValidationState(val)
			return
		} else {
			setValidationState({})
		}

		sendEmail(
			email,
			`${userSubject} - ${
				subjectOptions.find((s) => s.value === subject).text
			} (v${currentVersion})`,
			message,
		).then((response: IResponse<any>) => {
			if (response && response.status > 199 && response.status < 300) {
				setMessage('')
				setSubject('')
				setUserSubject('')
				setEmail(defaultEmail)
				setCanSend(props.authenticated)
			}

			setModalOpen(false)
		})
	}

	return (
		<>
			<Modal
				closeOnDimmerClick
				onClose={() => setModalOpen(false)}
				open={modalOpen}
				size='small'
			>
				<Header content='Contact Us' icon='mail' />

				<Modal.Content>
					<Text>Send us a quick email</Text>
					<Form>
						<Dropdown
							label='Issue Type'
							onChange={({ target }) => setSubject(target.value)}
							options={subjectOptions}
							search
							selection
							style={{
								height: '2.8em',
							}}
							validation={validation.subjectValidation}
							validationRules={{ required: true }}
							value={subject}
						/>

						<Divider hidden />

						<Input
							label='Email'
							onChange={({ target }) => setEmail(target.value)}
							placeholder='Your email address'
							type='email'
							validation={validation.emailValidation}
							validationRules={{ required: true }}
							value={email}
						/>

						<Input
							label='Subject'
							onChange={({ target }) => setUserSubject(target.value)}
							placeholder='Email subject'
							value={userSubject}
						/>

						<TextArea
							label='Message'
							onChange={({ target }) => setMessage(target.value)}
							placeholder='Type your message here'
							validation={validation.messageValidation}
							validationRules={{ required: true }}
							value={message}
						/>
					</Form>
				</Modal.Content>

				<Modal.Actions>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button onClick={() => setModalOpen(false)} secondary>
							<Icon name='x' /> Cancel
						</Button>

						<Button
							disabled={!canSend}
							loading={sendingMessage}
							onClick={() => onSubmit()}
							primary
						>
							<Icon name='checkmark' /> {canSend ? 'Submit' : 'Email Sent'}
						</Button>
					</div>
				</Modal.Actions>
			</Modal>

			{hasConnection ? (
				<ContactUsText onClick={onSendClick}>Contact Us</ContactUsText>
			) : (
				<ContactUsText>
					<a
						href='mailto:contact-project+puter-cloud-solutions-client-trackyr-client-tracker-4005578-issue-@incoming.gitlab.com'
						rel='noopener noreferrer'
						target='_blank'
					>
						Contact Us
					</a>
				</ContactUsText>
			)}
		</>
	)
}

export default ContactUsFooter
