import { authGet } from 'lib/http-request'
import { Soaps } from 'constants/endpoints'
import { onSoapsLoad } from 'containers/draw'
import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'
import { IAction, SoapNotesState } from 'interfaces'

const soapsAction = (action: string) => `client-trackyr/soap-notes/${action}`
export const FETCH_CLIENT_SOAP = soapsAction('FETCH_CLIENT_SOAP')
export const FETCH_CLIENT_SOAP_NO_CONTENT = soapsAction(
	'FETCH_CLIENT_SOAP_NO_CONTENT',
)
export const FETCH_CLIENT_SOAP_SUCCESS = soapsAction(
	'FETCH_CLIENT_SOAP_SUCCESS',
)
export const FETCH_CLIENT_SOAP_FAILURE = soapsAction(
	'FETCH_CLIENT_SOAP_FAILURE',
)

export const defaultState = {} as SoapNotesState

export const reducer = (
	state: SoapNotesState = defaultState,
	action: IAction,
) => {
	switch (action.type) {
		case FETCH_CLIENT_SOAP_SUCCESS: {
			const response = action.data

			if (action.status === 204) {
				return
			}

			const { id, fields, ...rest } = response
			const soap = {
				...rest,
				id,
				appointmentDate: response.appointment.scheduledStart,
				...fields,
				fetched: true,
			}

			return {
				...state,
				soaps: {
					...state.soaps,
					[action.clientID]: {
						...state.soaps[action.clientID],
						[response.appointmentID]: soap,
					},
				},
				flags: {
					...state.flags,
					fetchingNote: false,
				},
				soapFilteringLoading: false,
				changesMade: false,
				fetchSoapsFailure: '',
				dirtySoap: '',
			}
		}

		case FETCH_CLIENT_SOAP_NO_CONTENT:
			return {
				...state,
				soaps: {
					...state.soaps,
					[action.clientID]: {
						...state.soaps[action.clientID],
						[action.appointmentID]: {
							appointmentID: action.appointmentID,
							fetched: true,
						},
					},
				},
				flags: {
					...state.flags,
					fetchingNote: false,
				},
				fetching: false,
				soapFilteringLoading: false,
				changesMade: false,
				fetchSoapsFailure: '',
			}

		case FETCH_CLIENT_SOAP_FAILURE:
			return {
				...state,
				fetching: false,
				soapFilteringLoading: false,
				fetchSoapsFailure: action.error,
			}

		case FETCH_CLIENT_SOAP:
			return {
				...state,
				flags: {
					...state.flags,
					fetchingNote: true,//!action.isFiltering,
				},
				soapFilteringLoading: action.isFiltering,
				fetchSoapsFailure: '',
			}

		default:
			return state
	}
}

export const fetchSoapByAppointment =
	(clientID: string, appointmentID: string, treatmentNumber: number) =>
		async (dispatch: any): Promise<any> => {
			dispatch({
				type: FETCH_CLIENT_SOAP,
				clientID,
				appointmentID,
				treatmentNumber,
			})

			return authGet(
				Soaps.GetSoapByAppointment(clientID, appointmentID, treatmentNumber),
			)
				.then((response) => {
					if (response.status === 204) {
						dispatch(fetchClientSoapsSuccessNoContent(clientID, appointmentID))
						return response
					}

					dispatch(fetchClientSoapsSuccess(response.data, clientID))
					dispatch(onSoapsLoad([response.data]))
				})
				.catch((error) => {
					captureMessage(
						`fetchClientSoap Failed. ClientID: ${clientID}. ${error}`,
						Severity.Error,
					)

					dispatch(fetchClientSoapsFailure(error))
				})
		}

export const fetchClientSoapsSuccess = (data: any, clientID: string) => ({
	type: FETCH_CLIENT_SOAP_SUCCESS,
	data,
	clientID,
})

export const fetchClientSoapsFailure = (error: any) => ({
	type: FETCH_CLIENT_SOAP_FAILURE,
	error,
})

const fetchClientSoapsSuccessNoContent = (
	clientID: string,
	appointmentID: string,
) => ({
	type: FETCH_CLIENT_SOAP_NO_CONTENT,
	clientID,
	appointmentID,
})
