import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ContainerLayout, H3, H4, Card } from 'components/ui'

const HomeBackground = styled.div`
  height: 100vh;
`

const AuthActions = styled.div`
  display: flex;
  justify-content: center;
`

const Home = () => (
  <HomeBackground>
    <ContainerLayout>
      <Card title="Welcome to Client Trackyr">
        <H4>
          Client Trackyr is an all in one Client Management System for the
          health services industry, focusing on massage therapy and
          psychotherapy. Client Trackyr manages your scheduling, notes, billing,
          and more.
        </H4>
        <H4>
          Client Trackyr is currently in a Private Beta and will be available at
          a later date. If you have an account you can login below. If you have
          been invited to join you can register with your email address that the
          invite was sent too.
        </H4>
        <AuthActions>
          <H3>
            <Link to="/auth/login"> Login </Link>
            <strong>&#8226;</strong>
            <Link to="/auth/register"> Register </Link>
          </H3>
        </AuthActions>
      </Card>
    </ContainerLayout>
  </HomeBackground>
)

export default Home
