import { Audits } from 'constants/endpoints'
import { GetAllResponse, IQueryParams, IResponse } from 'interfaces'
import { authGet } from 'lib/http-request'
import { Audit } from './interfaces'

export const queryAudits = async (
  queryParams: IQueryParams,
): Promise<IResponse<GetAllResponse<Audit>>> => {
  try {
    const result = await authGet(Audits.GetAll(queryParams))
    return result
  } catch (err) {
    return err
  }
}
