import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authDelete } from 'lib/http-request'
import { Forms } from 'constants/endpoints'
import { message } from 'components/notifications'
import { Dispatch, ErrorState, IAction } from 'interfaces'

const formsAction = (action: string) => `client-trackyr/forms/${action}`
const DELETE_FORMS = formsAction('DELETE_FORMS')
const DELETE_FORMS_SUCCESS = formsAction('DELETE_FORMS_SUCCESS')
const DELETE_FORMS_ERROR = formsAction('DELETE_FORMS_ERROR')

export const defaultState = {
  deleting: false,
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case DELETE_FORMS:
      return {
        ...state,
        deleting: true,
      }

    case DELETE_FORMS_SUCCESS:
      return {
        ...state,
        deleting: false,
      }

    case DELETE_FORMS_ERROR:
      return {
        ...state,
        deleting: false,
      }

    default:
      return state
  }
}

export const deleteForm = (formID: string) => async (dispatch: Dispatch) => {
  dispatch({ type: DELETE_FORMS })

  return authDelete(Forms.Delete(formID))
    .then((response) => {
      message.success('Deleted Form')
      dispatch(deleteFormSuccess())

      return response
    })
    .catch((error) => {
      message.error('Failed to Delete Form')

      captureMessage(`deleteForm Failed. ${error}`, Severity.Error)
      dispatch(deleteFormError(error))

      return error
    })
}

const deleteFormSuccess = () => ({
  type: DELETE_FORMS_SUCCESS,
})

const deleteFormError = (error: ErrorState) => ({
  type: DELETE_FORMS_ERROR,
  error,
})
