import { register } from 'lib/auth'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { Dispatch, ErrorState, IAction } from 'interfaces'

const REGISTER_REQUEST = 'client-trackyr/auth/REGISTER_REQUEST'
const REGISTER_SUCCESS = 'client-trackyr/auth/REGISTER_SUCCESS'
const REGISTER_FAILURE = 'client-trackyr/auth/REGISTER_FAILURE'

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        hasError: false,
        errorMsg: '',
        loading: true,
      }

    case REGISTER_SUCCESS:
      return {
        ...state,
        hasError: false,
        errorMsg: '',
        loading: false,
      }

    case REGISTER_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMsg: action.error.message,
        loading: false,
      }

    default:
      return state
  }
}

export const registerRequest = (email: string, password: string) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: REGISTER_REQUEST })

  return register(email, password)
    .then((resp) => dispatch(registerRequestSuccess(resp)))
    .catch((error) => {
      captureMessage(`registerRequest Failed. ${error}`, Severity.Error)

      dispatch(registerRequestFailure(error))
      throw error
    })
}

const registerRequestSuccess = (response: any) => ({
  type: REGISTER_SUCCESS,
  response,
})

const registerRequestFailure = (error: ErrorState) => ({
  type: REGISTER_FAILURE,
  error,
})
