import { authGet } from 'lib/http-request'
import { Forms } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'

import { fetchForms } from 'containers/forms'
import { Dispatch, ErrorState, IAction, Severity } from 'interfaces'

const accountAction = (action: string) => `client-trackyr/account/${action}`
const FETCH_ACCOUNT_FORMS = accountAction('FETCH_ACCOUNT_FORMS')
const FETCH_ACCOUNT_FORMS_SUCCESS = accountAction('FETCH_ACCOUNT_FORMS_SUCCESS')
const FETCH_ACCOUNT_FORMS_FAILURE = accountAction('FETCH_ACCOUNT_FORMS_FAILURE')

export const defaultState = {
  fetchingAccountForms: false,
  forms: [],
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_ACCOUNT_FORMS_SUCCESS: {
      const accountForms = action.data.map((f) => {
        const { userID, ...form } = f // eslint-disable-line no-unused-vars
        return form
      })

      return {
        ...state,
        forms: accountForms,
        fetchingAccountForms: false,
      }
    }

    case FETCH_ACCOUNT_FORMS_FAILURE:
      return {
        ...state,
        fetchingAccountForms: false,
        error: {
          hasError: true,
          msg: action.error,
        },
      }

    case FETCH_ACCOUNT_FORMS:
      return {
        ...state,
        fetchingAccountForms: true,
        error: { hasError: false, msg: '' },
      }

    default:
      return state
  }
}

export const fetchAccountForms = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_ACCOUNT_FORMS })

  return authGet(Forms.Fetch)
    .then((response) => {
      dispatch(fetchAccountFormsSuccess(response.data))
      dispatch(fetchForms(response.data))

      return response
    })
    .catch((error) => {
      captureMessage(`fetchAccountForms Call Failed: ${error}`, Severity.Error)
      dispatch(fetchAccountFormsFailure(error))

      return error
    })
}

const fetchAccountFormsSuccess = (data: any) => ({
  type: FETCH_ACCOUNT_FORMS_SUCCESS,
  data,
})

const fetchAccountFormsFailure = (error: ErrorState) => ({
  type: FETCH_ACCOUNT_FORMS_FAILURE,
  error,
})
