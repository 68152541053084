import React from 'react'

import { Button, Form, Message, Divider } from 'components/ui'
import { Dropdown, TextArea } from 'components/forms'
import {
  AppointmentTypeOptions,
  AppointmentType,
} from 'containers/appointments/enums'
import { IEvent, Validation } from 'interfaces'

import { emptyGuid } from 'lib/guid'
import { AppointmentModalClientNav } from './appointment-modal-client-nav'
import { AppointmentStatusDropdown } from './appointment-status-dropdown'
import { AppointmentModalClientDetails } from '../appointment-modal-client-details'
import { AppointmentModalTherapistDetails } from '../appointment-modal-therapist-details'

interface Props {
  appointmentType: AppointmentType
  clientID: string
  editAppointmentStatus: boolean
  eventData: any
  gotoNotes: any
  isEditMode: boolean
  isForClient: boolean
  locationIndex: number
  onChange: (e: IEvent) => void
  onSetLocationIndex: (index: number) => void
  onSubmit: Function
  readonly: boolean
  saving: boolean
  setCalendarModalOpen: (open: boolean) => void
  setEditAppointmentStatus: (edit: boolean) => void
  setEditEventModalOpen: (open: boolean) => void
  setNewClientModalOpen: (open: boolean) => void
  validation: Validation
}

export const AppointmentSetup = (props: Props) => {
  return (
    <>
      {props.eventData.event.treatmentNumber === 0 &&
        props.eventData.event.clientID !== emptyGuid && (
          <Message
            content={
              <div>
                <Button
                  content="Fix to Latest Treatment"
                  icon="wrench"
                  loading={props.saving}
                  onClick={props.onSubmit}
                />
              </div>
            }
            error
            header="This appointment is assigned to an invalied treatment"
          />
        )}

      <AppointmentModalClientNav
        appointmentID={props.eventData.event.id}
        clientID={props.clientID}
        gotoNotes={props.gotoNotes}
      />

      <Form>
        {!props.readonly && !props.eventData.event.id && !props.clientID && (
          <>
            <Dropdown
              label="Event Type"
              name="appointmentType"
              onChange={props.onChange}
              options={AppointmentTypeOptions}
              value={props.appointmentType || AppointmentType.Client}
            />
            <Divider />
          </>
        )}

        {/* {!props.readonly && isEditMode && ( */}
        <AppointmentStatusDropdown
          event={props.eventData.event}
          onChange={(e: IEvent) => {
            props.onChange(e)
            props.setEditAppointmentStatus(false)
          }}
          readonly={props.editAppointmentStatus}
          setEditAppointmentStatus={props.setEditAppointmentStatus}
        />
        {/* )} */}

        {/* props.clientID here is correct, we use this prop to determine if we can change 
      the client or not */}
        {props.appointmentType === AppointmentType.Client && (
          <AppointmentModalClientDetails
            clientID={props.clientID}
            eventData={props.eventData}
            isEditMode={props.isEditMode}
            isForClient={props.isForClient}
            locationIndex={props.locationIndex}
            onChange={props.onChange}
            readonly={props.readonly}
            setCalendarModalOpen={props.setCalendarModalOpen}
            setEditEventModalOpen={props.setEditEventModalOpen}
            setLocationIndex={props.onSetLocationIndex}
            setNewClientModalOpen={props.setNewClientModalOpen}
            validation={props.validation}
          />
        )}

        {props.appointmentType === AppointmentType.Therapist && (
          <AppointmentModalTherapistDetails
            eventData={props.eventData}
            readonly={props.readonly}
            setCalendarModalOpen={props.setCalendarModalOpen}
            setEditEventModalOpen={props.setEditEventModalOpen}
          />
        )}

        <Divider />

        <TextArea
          label="Appointment Notes for Therapist"
          name="notes"
          onChange={props.onChange}
          placeholder="Any additional notes for this appointment. (These will not be sent to the client)."
          readonly={props.readonly}
          value={props.eventData.event.notes}
        />
      </Form>
    </>
  )
}
