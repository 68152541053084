import { authGetFile } from 'lib/http-request'
import { Storage } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { IResponse, Error } from 'interfaces/fetch'
import { IAction, IFile } from 'interfaces'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const FETCH_FILE = filesAction('FETCH_FILE')
const FETCH_FILE_SUCCESS = filesAction('FETCH_FILE_SUCCESS')
const FETCH_FILE_FAILURE = filesAction('FETCH_FILE_FAILURE')
const FILE_NOT_FOUND = filesAction('FILE_NOT_FOUND')

export const defaultState = {
  fetchingFile: '',
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_FILE: {
      return {
        ...state,
        fetchingFile: action.fileID,
      }
    }

    case FETCH_FILE_SUCCESS: {
      return {
        ...state,
        fetchingFile: '',
      }
    }

    case FETCH_FILE_FAILURE:
      return {
        ...state,
        fetchingFile: '',
      }

    case FILE_NOT_FOUND:
      return {
        ...state,
        fetchingFile: '',
      }

    default:
      return state
  }
}

export const fetchFile = (fileID: string): any => async (
  dispatch: any,
): Promise<any> => {
  dispatch({ type: FETCH_FILE, fileID })

  return authGetFile(
    Storage.GetFile(fileID),
    fileID,
    // TODO - reenable this cache!
    // {
    //   cache: true,
    //   expiry: 30 * 24 * 60 * 60,
    //   cacheMaxAge: 60 * 60 /* 1 hour */,
    // }
  )
    .then((response: IResponse<IFile>) => {
      dispatch(fetchFileSuccess(response.data))

      return response
    })
    .catch((error: Error) => {
      if (error?.response?.status === 404) {
        dispatch(fileNotFound())
      }

      captureMessage(
        `Failed to fetch File. FileID: ${fileID}. ${error}`,
        Severity.Error,
      )
      dispatch(fetchFileFailure(error))

      return error
    })
}

const fetchFileSuccess = (files: any) => ({
  type: FETCH_FILE_SUCCESS,
  files,
})

const fetchFileFailure = (error: Error) => ({
  type: FETCH_FILE_FAILURE,
  error,
})

const fileNotFound = () => ({
  type: FILE_NOT_FOUND,
})
