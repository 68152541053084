import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { FeatureToggle } from 'containers/features'
import {
  AdminSetFeatures,
  AllFeatures,
  GlobalFeatures,
} from 'constants/features'
import {
  ActivableListItem,
  ListContent,
  ListIcon,
  Header,
  SubText,
  Card,
  Text,
  Input,
  Label,
  Button,
  Container,
  IconButton,
  Icon,
  Segment,
  SList as List,
  H4,
  H1,
} from 'components/ui'
import { Spinner } from 'components/loaders'

import { fetchUsers } from './redux/fetch-all-users'
import {
  getAllUsers,
  getBetaUsers,
  getUserFeatures,
  getFeatures,
} from './redux/selectors'
import { fetchBetaUsers } from './redux/fetch-beta-users'
import { fetchUserFeatures } from './redux/fetch-user-features'
import { FeatureToggleCheckbox } from './feature-toggle-checkbox'
import { setBetaUser } from './redux/set-beta-user'
import { removeBetaUser } from './redux/remove-beta-user'
import { Dispatch, Feature, IState, IUser } from 'interfaces'
import { authGet } from 'lib/http-request'
import { Tasks } from 'constants/endpoints'

const BetaUserContainer = styled.div`
  display: flex;
  justify-content: space-between;

  :hover {
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }

  max-width: 45em;
`

export const AdminPanel = () => {
  const dispatch: Dispatch = useDispatch()

  const allUsers = useSelector(getAllUsers)
  const betaUsers = useSelector(getBetaUsers)
  const userFeatures = useSelector(getUserFeatures)
  const features = useSelector(getFeatures)
  const fetchingUserFeatures = useSelector(
    (state: IState) => state.features.fetchingUserFeatures,
  )
  const fetchingUsers = useSelector(
    (state: IState) => state.features.fetchingUsers,
  )
  const fetchingBetaUsers = useSelector(
    (state: IState) => state.features.fetchingBetaUsers,
  )
  const fetchingFeatures = useSelector(
    (state: IState) => state.features.fetchingFeatures,
  )
  const settingBetaUser = useSelector(
    (state: IState) => state.features.settingBetaUser,
  )
  const removingBetaUser = useSelector(
    (state: IState) => state.features.removingBetaUser,
  )

  const [activeFeatureUser, setActiveFeatureUser] = useState('')
  const [betaUserEmail, setBetaUserEmail] = useState('')
  const [queueMetrics, setQueueMetrics] = useState({
    fetching: false,
    running: 0,
    waiting: 0,
  })

  const fetchUsersCallback = useCallback(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  const fetchBetaUsersCallback = useCallback(() => {
    dispatch(fetchBetaUsers())
  }, [dispatch])

  const fetchUserFeaturesCallback = useCallback(
    (id) => {
      dispatch(fetchUserFeatures(id))
    },
    [dispatch],
  )

  const setBetaUserCallback = useCallback(
    (email) => dispatch(setBetaUser(email)).then(() => setBetaUserEmail('')),
    [dispatch],
  )

  const removeBetaUserCallback = useCallback(
    (email) => dispatch(removeBetaUser(email)),
    [dispatch],
  )

  const onFetchMetrics = useCallback(() => {
    setQueueMetrics({
      ...queueMetrics,
      fetching: true,
    })
    authGet(Tasks.GetQueue).then((response) => {
      setQueueMetrics({ ...response.data, fetching: false })
    })
  }, [])

  useEffect(() => {
    fetchUsersCallback()
    fetchBetaUsersCallback()
    onFetchMetrics()
  }, [fetchUsersCallback, fetchBetaUsersCallback, onFetchMetrics])

  return (
    <Container>
      <FeatureToggle
        fallbackComponent={
          <div>
            <H4>403 Forbidden</H4>
            <Text>You do not have permission to access this page</Text>
          </div>
        }
        features={[AdminSetFeatures]}
      >
        <H1> Admin Panel </H1>

        <Card collapsible title="Global Features">
          <Spinner spinning={fetchingFeatures} tip="loading...">
            {GlobalFeatures.map((key: Feature) => {
              return (
                <FeatureToggleCheckbox
                  key={key}
                  label={key}
                  userID="00000000-0000-0000-0000-000000000001"
                  value={features[key]}
                />
              )
            })}
          </Spinner>
        </Card>

        <Card collapsible title="Metrics">
          <Text>{`Running: ${queueMetrics.running}`}</Text>
          <Text>{`Waiting: ${queueMetrics.waiting}`}</Text>
          <Button
            content="Refresh"
            icon="sync"
            loading={queueMetrics.fetching}
            onClick={onFetchMetrics}
          />
        </Card>

        <Card
          collapsible
          extra={
            <IconButton
              loading={fetchingBetaUsers}
              name="sync"
              onClick={fetchBetaUsersCallback}
            />
          }
          title="Beta Users"
        >
          <Text>Note: This is for signup purposes only</Text>
          <Spinner spinning={fetchingBetaUsers} tip="loading...">
            {betaUsers.map((betaUser: string) => (
              <BetaUserContainer key={betaUser}>
                <Text>{betaUser}</Text>
                {removingBetaUser ? (
                  <Icon loading name="sync" />
                ) : (
                  <IconButton
                    color="red"
                    name="trash"
                    onClick={() => removeBetaUserCallback(betaUser)}
                    size="large"
                  />
                )}
              </BetaUserContainer>
            ))}

            <Label>Add New Beta User</Label>
            <div style={{ display: 'flex', maxWidth: '45em' }}>
              <Input
                onChange={({ target }) => setBetaUserEmail(target.value)}
                placeholder="Beta User Email"
                value={betaUserEmail}
              />
              <Button
                content="Add"
                loading={settingBetaUser}
                onClick={() => setBetaUserCallback(betaUserEmail)}
                primary
              />
            </div>
          </Spinner>
        </Card>

        <Card
          collapsible
          extra={
            <IconButton
              loading={fetchingUsers}
              name="sync"
              onClick={fetchUsersCallback}
            />
          }
          title="Users"
        >
          <Spinner spinning={fetchingUsers} tip="loading...">
            <List>
              {allUsers.map((user: IUser) => {
                const name = `${user.firstName || ''} ${
                  user.middleName || ''
                } ${user.lastName || ''}`.trim()

                return (
                  <ActivableListItem
                    key={user.id}
                    onClick={() => {
                      if (activeFeatureUser !== user.id) {
                        fetchUserFeaturesCallback(user.id)
                        setActiveFeatureUser(user.id)
                      }
                      /* 
                      if (activeFeatureUser === user.id) {
                        setActiveFeatureUser('')
                      } else {
                        setActiveFeatureUser(user.id)
                      } */
                    }}
                  >
                    <ListIcon name="user" size="large" verticalAlign="middle" />
                    <ListContent>
                      <Header>{name || user.displayName || user.email}</Header>

                      {name && user.displayName && (
                        <Text>{`Display Name: ${user.displayName}`}</Text>
                      )}
                      <Text>{`Email: ${user.email}`}</Text>
                      <SubText>{user.id}</SubText>

                      {activeFeatureUser === user.id && (
                        <Segment>
                          <Spinner
                            spinning={fetchingUserFeatures}
                            tip="loading..."
                          >
                            <Header>Features</Header>

                            {userFeatures[user.id] &&
                              AllFeatures.map((key: Feature) => {
                                const feature = userFeatures[user.id]

                                return (
                                  <FeatureToggleCheckbox
                                    key={key}
                                    label={key}
                                    userID={user.id}
                                    value={feature[key]}
                                  />
                                )
                              })}
                          </Spinner>
                        </Segment>
                      )}
                    </ListContent>
                  </ActivableListItem>
                )
              })}
            </List>
          </Spinner>
        </Card>
      </FeatureToggle>
    </Container>
  )
}
