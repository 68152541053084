import React from 'react'
import { authPatch } from 'lib/http-request'
import { captureMessage } from '@sentry/browser'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { debounce } from 'lib/debounce'
import { getSeletectedTreatmentNumber$ } from 'containers/treatment/selectors'
import { BasicEvent } from 'components/forms/interfaces'

import AutoSaveIndicator, {
	NONE,
	FETCHING,
	SUCCESS,
	FAILURE,
} from './auto-save-indicator'
import { IState, IEvent, Severity } from 'interfaces'
import { getErrorHeader, getErrorMessage } from 'lib/error-message'

const Children = styled.div`
  flex-grow: 999;
`

const WrapperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
`

const ICON_TIMEOUT = 350
const AUTO_HIDE_TIMEOUT = 5000

export const autoSave = (endpoint: string, data: any) => {
	if (!endpoint) {
		console.error('Endpoint not set for Autosave')
		throw new Error('Endpoint not set for Autosave')
	}

	if (!data.name || !data.entityID || !data.treatmentNumber) {
		console.error('Data not set correctly for Autosave')
		throw new Error('Data not set correctly for Autosave')
	}

	return authPatch(endpoint, data)
}

type Status = 'NONE' | 'FETCHING' | 'FAILURE' | 'SUCCESS'

interface AutoSaveWrapperState {
	autosaving: boolean
	changed: boolean
	status: Status
}

interface AutoSaveWrapperProps {
	afterAutoSaveCallback?: Function
	autoHideSuccess?: boolean
	autosaveDisabled?: boolean
	autosaveMode?: 'onBlur' | 'onChange' | 'onClick' | 'onBlurOnly'
	children?: any
	debounceTime?: number
	endpoint?: string
	entityID?: string
	formType?: string
	indicatorOverride?: string
	name?: string
	onBeforeChange?: any
	// onBlur?: Function
	overWriteBlur?: boolean
	patch?: (e: IEvent, t?: any) => Promise<any>
	setAutoSavingStatus?: Function
	sendErrorMessage?: (error: any) => void
	treatmentNumber?: number
	value?: string
}

/**
 * AutoSaveWrapper Wraps a Form.Item input
 * @type {Object}
 */
class AutoSaveWrapper extends React.Component<AutoSaveWrapperProps> {
	public static defaultProps: Partial<AutoSaveWrapperProps> = {
		autoHideSuccess: true,
		autosaveDisabled: false,
		autosaveMode: 'onBlur',
		debounceTime: 2000,
		// onBlur: () => {},
		overWriteBlur: true,
	}

	state: AutoSaveWrapperState = {
		status: NONE, // FETCHING, FAILURE, SUCCESS
		changed: false,
		autosaving: false,
	}

	/**
	 * patch - autosave patch
	 * @param {IEvent} e JS Event
	 * @param {any} t Unknown
	 * @param {any} retEvt a modified event that overrides e
	 */
	patch = async (e: IEvent | any, t: any, retEvt: any = {}) => {
		if (
			typeof e === 'undefined' ||
			!e.target ||
			(!e.target.name && !t && !t.name)
		) {
			return Promise.resolve()
		}

		if (e.target.type === 'date' && e.target.value === 'Invalid date') {
			return Promise.reject()
		}

		// Is there a good reason for this?? Some JS fuckery?
		const event = e

		if (retEvt.target) {
			event.target.name = retEvt.target.name
			event.target.value = retEvt.target.value
		}

		if (!this.props.patch) {
			const data = {
				entityID: this.props.entityID,
				type: this.props.formType,
				treatmentNumber: this.props.treatmentNumber,
				name: event.target.name,
				value:
					typeof event.target.value !== 'string'
						? JSON.stringify(event.target.value)
						: event.target.value,
			}

			if (this.props.endpoint) {
				return authPatch(this.props.endpoint, data).then(() => {
					this.props.afterAutoSaveCallback &&
					this.props.afterAutoSaveCallback(e)
				})
			}

			captureMessage('auto-save-wrapper endpoint not set', Severity.Error)

			throw Error('AutoSaveWrapper Endpoint not set')
		} else {
			return this.props.patch(e, t).then(() => {
				this.props.afterAutoSaveCallback && this.props.afterAutoSaveCallback(e)
			})
		}
	}

	onChangeSync = (e: any, t: any) => {
		if (!this.props.entityID) {
			return
		}

		this.setStatus(FETCHING)

		const retEvt =
			this.props.children.props.onChange &&
			this.props.children.props.onChange(e, t)

		this.patch(e, t, retEvt)
			.then((...resp: any) => {
				this.setState(() => ({ changed: false }))
				setTimeout(() => this.setStatus(SUCCESS), ICON_TIMEOUT)

				if (this.props.autoHideSuccess) {
					setTimeout(() => this.setStatus(NONE), AUTO_HIDE_TIMEOUT)
				}

				return { ...resp }
			})
			.catch((error: any) => {
				captureMessage(`auto-save-wrapper error. ${error}`, Severity.Error)
				this.props.sendErrorMessage(error)
				setTimeout(() => this.setStatus(FAILURE), ICON_TIMEOUT)
				return { error }
			})
	}

	setStatus = (status: Status) => {
		this.setState(() => ({ status }))
		this.props.setAutoSavingStatus(status)
	}

	debounceOnChangeSync: (e: any, a: any) => void = debounce(
		(e: any, args: any) => {
			this.state.changed &&
			this.state.status !== FETCHING &&
			this.onChangeSync(e, args)
		},
		this.props.debounceTime,
	)

	onChange = (e: BasicEvent, t: any) => {
		if (!this.props.entityID) {
			this.props.children.props.onChange &&
			this.props.children.props.onChange(e, t)
			return
		}

		this.setState((prevState: AutoSaveWrapperState) =>
			prevState.changed === true ? null : { changed: true },
		)

		if (this.props.autosaveMode === 'onChange' && this.state.changed) {
			this.onChangeSync(e, t)
		} else if (this.props.autosaveMode !== 'onBlurOnly') {
			e && e.persist && e.persist()
			this.debounceOnChangeSync(e, t)

			this.props.children.props.onChange &&
			this.props.children.props.onChange(e, t, (et: any) =>
				this.debounceOnChangeSync(et, t),
			)
		} else {
			this.props.children.props.onChange &&
			this.props.children.props.onChange(e, t)
			this.setState(() => ({ changed: true }))
		}
	}

	onBlur = (e: BasicEvent, t: any) => {
		if (
			(this.props.autosaveMode === 'onBlur' ||
				this.props.autosaveMode === 'onBlurOnly') &&
			this.state.changed &&
			!!this.props.entityID
		) {
			this.setStatus(FETCHING)

			const retEvt = this.props.children.props.onChange(
				{
					target: {
						name: this.props.children.props.name,
						value: this.props.children.props.value,
					},
				},
				t,
			)

			this.patch(e, t, retEvt)
				.then((...resp: any) => {
					this.setState(() => ({ changed: false }))
					setTimeout(() => this.setStatus(SUCCESS), ICON_TIMEOUT)

					if (this.props.autoHideSuccess) {
						setTimeout(() => this.setStatus(NONE), AUTO_HIDE_TIMEOUT)
					}

					return { ...resp }
				})
				.catch((error: any) => {
					captureMessage(`auto-save-wrapper error. ${error}`, Severity.Error)
					this.props.sendErrorMessage(error)
					setTimeout(() => this.setStatus(FAILURE), ICON_TIMEOUT)
					return { ...error }
				})
		}

		this.props.children.props.onBlurOverride &&
		this.props.children.props.onBlurOverride(e, t)
	}

	onClick = (e: BasicEvent, ...args: any[]) => {
		e && e.persist && e.persist()
		if (this.props.autosaveMode === 'onClick') {
			const target = args[args.length - 1]
			this.onChangeSync({ ...target }, args)
		}

		this.props.children.props.onClick(e, ...args)
	}

	renderChildren = () => {
		const nameValueOverrides = this.props.name
			? {
				name: this.props.name,
				value: this.props.value,
			}
			: {}

		const newProps: any = {
			onChange: this.props.onBeforeChange
				? (e: BasicEvent, t: any) =>
					this.props.onBeforeChange(() => this.onChange(e, t))
				: this.onChange,
			onClick: (e: any, ...args: any) => {
				const onClickResult =
					this.props.children.props.onClick &&
					this.props.children.props.onClick(e, args)
				this.props.children.props.onClick &&
				this.onClick(e, args, onClickResult)
			},
			...nameValueOverrides,
		}

		if (this.props.overWriteBlur) {
			newProps.onBlur = this.onBlur
		}

		return React.cloneElement(this.props.children, newProps)
	}

	renderWithIndicator = () => {
		return (
			<WrapperContainer>
				<Children>{this.renderChildren()}</Children>
				<AutoSaveIndicator
					status={
						this.props.indicatorOverride
							? this.props.indicatorOverride
							: this.state.status
					}
				/>
			</WrapperContainer>
		)
	}

	renderWithoutIndicator = () => <>{this.renderChildren()}</>

	render() {
		return this.props.autosaveDisabled
			? this.props.children
			: this.renderWithoutIndicator()
	}
}

const mapStateToProps = (state: IState) => ({
	treatmentNumber: getSeletectedTreatmentNumber$(state),
})

const mapDispatchToProps = (dispatch: any) => ({
	setAutoSavingStatus: (status: Status) =>
		dispatch({
			type: 'client-trackyr/forms/SET_FORM_AUTO_SAVING_STATUS',
			status,
		}),
	sendErrorMessage: (error: any) => {
		dispatch({
			type: 'client-trackyr/notifications/NOTIFICATION',
			payload: {
				type: 'error',
				id: error?.response?.status || error?.message || '1',
				content: getErrorMessage(error),
				message: `Failed to Save Form Field: ${getErrorHeader(error)}`,
			},
		})
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoSaveWrapper)
