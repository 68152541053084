import React, { useState } from 'react'
import styled from 'styled-components'
// import mammoth from 'mammoth'
// import { captureMessage } from '@sentry/browser'
// import { Severity } from 'interfaces'

import { Storage } from 'constants/endpoints'
import { Text, PDFView, H3 } from 'components/ui'

import { Image, ImageContainer, Message } from './'

const PlainText = styled.div`
  white-space: pre-line;
`

const DocumentView = (props: DocumentViewProps) => {
	const [text, setText] = useState('')
	// const [htmlDoc, setHtmlDoc] = useState('')

	const isImage = props.mimeType.includes('image')
	const isPDF = props.mimeType.includes('pdf')
	const isPlainText = props.mimeType.includes('plain')
	const isDocument =
		props.mimeType.includes(
			'vnd.openxmlformats-officedocument.wordprocessingml.document',
		) ||
		(props.documentDriver !== 'default' &&
			props.mimeType.includes(
				'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			))
	const isForm =
		props.file &&
		props.file.storagePath &&
		props.file.storagePath.includes('/forms/')

	if (isImage) {
		return (
			<ImageContainer>
				<Image src={props.filePathUrl} />
			</ImageContainer>
		)
	} else if (isPDF) {
		return (
			<div
				style={{
					width: '100%',
					overflow: 'scroll',
					overflowX: 'scroll',
					height: '100vh',
				}}
			>
				<PDFView
					filePathUrl={props.filePathUrl}
					scale={props.expanded ? props.scale * 1.5 : props.scale}
				/>
			</div>
		)
	} else if (isPlainText) {
		const fr = new FileReader()
		fr.onload = function () {
			setText(this.result.toString())
		}
		fr.readAsText(props.file)

		return <PlainText>{text}</PlainText>
	} else if (isDocument) {
		switch (props.documentDriver) {
			case 'google': {
				const url = Storage.GoogleDocsSharedFileURL(
					props.userID,
					props.documentUrl,
				)

				return (
					<div style={{ width: '100%', height: '100vh' }}>
						<iframe
							src={url}
							style={{ width: '100%', height: '100vh' }}
							title='document-view'
						></iframe>
					</div>
				)
			}

			case 'office': {
				const url = Storage.Office365SharedFileURL(
					props.userID,
					props.documentUrl,
				)

				return (
					<div style={{ width: '100%', height: '100vh' }}>
						<iframe
							src={url}
							style={{ width: '100%', height: '100vh' }}
							title='document-view'
						></iframe>
					</div>
				)
			}

			case 'default':
			default: {
				// const fr = new FileReader()
				// fr.onload = function () {
				// 	try {
				// 		mammoth
				// 			.convertToHtml({ arrayBuffer: (this.result as ArrayBuffer) })
				// 			.then((result) => {
				// 				const html = result.value
				// 				setHtmlDoc(html)
				// 			})
				// 		// .done()
				// 	} catch (error) {
				// 		captureMessage(
				// 			`failed to render document, <default>. ${error}`,
				// 			Severity.Error,
				// 		)
				// 	}
				// }
				// try {
				// 	fr.readAsArrayBuffer(props.file)
				// } catch (error) {
				// 	captureMessage(
				// 		`failed to render document, <default>. ${error}`,
				// 		Severity.Error,
				// 	)
				// }
				//
				// return (
				// 	<div
				// 		dangerouslySetInnerHTML={{ __html: htmlDoc }}
				// 		style={{ overflow: 'scroll', height: '100vh' }}
				// 	/>
				// )
				return null
			}
		}
	} else if (isForm) {
		let content = 'These are files that support custom forms.'
		if (props.file.fileName.includes('(deleted form)')) {
			content += '\nThis form has been deleted.'
		}

		return (
			<div
				style={{
					width: '100%',
					overflow: 'scroll',
					overflowX: 'scroll',
					height: '100vh',
				}}
			>
				<H3>Form File Definition</H3>

				<div>
					<Message
						content={content}
						header='File Type not supported for Preview'
						icon='info'
						info
					/>
				</div>
			</div>
		)
	}

	if (props.mimeType) {
		return (
			<div>
				<Message
					content='File Type not supported for Preview'
					icon='info'
					info
				/>
			</div>
		)
	} else {
		return (
			<div
				style={{
					margin: 'auto',
					width: '100%',
					height: '35vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Text>No File Selected</Text>
			</div>
		)
	}
}

interface DocumentViewProps {
	documentDriver: string
	documentUrl: string
	expanded: boolean
	file: any
	filePathUrl: string
	mimeType: string
	scale: number
	userID: string
}

export default DocumentView
