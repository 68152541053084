import React from 'react'

import { FormField, Label, DatePicker as D } from 'components/ui'
import { AutoSaveWrapper } from 'components/forms'
import { InputProps } from 'components/forms/interfaces'
import { IEvent } from 'interfaces'

interface DatePickerProps {
	format?: string
	onBlurOverride?: Function | any
	showTime?: any // { format: 'h:mm a', use12Hours: true }
}

const DatePickerForm = ({
													autoSave,
													disabled,
													format = 'YYYY-MM-DD',
													label,
													name,
													onBlur,
													onBlurOverride,
													onChange,
													onValidate,
													placeholder,
													printParams,
													readonly,
													showTime,
													style,
													validation,
													validationRules,
													value,
												}: InputProps & DatePickerProps) => {
	return (
		<FormField
			required={!readonly && validationRules && validationRules.required}
			{...validation}
		>
			<Label style={style}>{label}</Label>

			<AutoSaveWrapper
				afterAutoSaveCallback={autoSave.afterAutoSaveCallback}
				autosaveDisabled={autoSave.autosaveDisabled}
				endpoint={autoSave.patchEndpoint}
				entityID={autoSave.entityID}
				formType={autoSave.patchType}
				overWriteBlur={false}
				patch={autoSave.patch}
			>
				<D
					disabled={disabled}
					format={format}
					label={label}
					name={name}
					onBlur={(e: IEvent) => {
						onBlur && onBlur(e)
						onBlurOverride && onBlurOverride(e)
						onValidate && onValidate(validationRules, label, name, value)
					}}
					onChange={onChange}
					placeholder={placeholder}
					printParams={printParams}
					readonly={readonly}
					showTime={showTime}
					style={style}
					value={value}
				/>
			</AutoSaveWrapper>
		</FormField>
	)
}

DatePickerForm.defaultProps = {
	autoSave: {
		autosaveDisabled: true,
	},
}

export const DatePicker = React.memo(DatePickerForm)
