import { authGet } from 'lib/http-request'
import { Files } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'

import { GetAllResponse, IAction, Severity } from 'interfaces'
import { IQueryParams } from 'interfaces/fetch'
import { ONE_HOUR } from 'constants/cache'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const FETCH_ALL_FILES = filesAction('FETCH_ALL_FILES')
const FETCH_ALL_FILES_SUCCESS = filesAction('FETCH_ALL_FILES_SUCCESS')
const FETCH_ALL_FILES_FAILURE = filesAction('FETCH_ALL_FILES_FAILURE')

export const defaultState = {
  fetchingFiles: false,
}

export const reducer = (state: any = defaultState, action: IAction) => {
  switch (action.type) {
    case FETCH_ALL_FILES: {
      return {
        ...state,
        flags: {
          ...state.flags,
          fetchingFiles: true,
        },
      }
    }

    case FETCH_ALL_FILES_SUCCESS: {
      const files = { ...state.files }
      let allFilesByID = [...state.allFilesByID]

      for (const file of action.response.items) {
        allFilesByID.push(file.id)
        files[file.id] = file
      }

      allFilesByID = [...new Set(allFilesByID)]

      return {
        ...state,
        files,
        allFilesByID,
        flags: {
          ...state.flags,
          fetchingFiles: false,
        },
        meta: {
          ...state.meta,
          totalFiles: action.response.totalCount,
        },
      }
    }

    case FETCH_ALL_FILES_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          fetchingFiles: false,
        },
      }

    default:
      return state
  }
}

export const fetchAllFiles = (
  queryParams = {} as IQueryParams,
  useCache = false,
): any => async (dispatch: any): Promise<any> => {
  dispatch({ type: FETCH_ALL_FILES, queryParams, useCache })

  return authGet(Files.GetAll(queryParams), {
    cache: useCache,
    cacheMaxAge: ONE_HOUR,
  })
    .then((response) => {
      dispatch(fetchAllFilesSuccess(response.data))
      return response
    })
    .catch((error) => {
      captureMessage(`Failed to fetch all files. ${error}`, Severity.Error)
      dispatch(fetchAllFilesFailure(error))
      return error
    })
}

const fetchAllFilesSuccess = (response: GetAllResponse<any>) => ({
  type: FETCH_ALL_FILES_SUCCESS,
  response,
})

const fetchAllFilesFailure = (error: any) => ({
  type: FETCH_ALL_FILES_FAILURE,
  error,
})
