import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface Props {
	authenticated: boolean
}

export const PrivateWrapper = ({ authenticated }: Props) => {
	return authenticated ? <Outlet /> : <Navigate to='/auth/login' />
}

export const PublicWrapper = ({ authenticated }: Props) => {
	return !authenticated ? <Outlet /> : <Navigate to='/dashboard' />
}
