import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { ClearModal } from 'components/modals'
import { getSelectedAppointmentID$ } from 'containers/appointments'
import { setSoapDirty } from 'containers/soap-notes'
import { getSelectedClientID$ } from 'containers/clients'

import * as Ducks from './ducks'
import ColourPicker from './components/colour-picker'
import ShapePicker from './components/shape-picker'
import { AutoSaveIndicator } from 'components/forms'
import { Dispatch, IState } from 'interfaces'

const ToolsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Tool = styled.div`
  min-width: 10em;
  min-height: 3.5em;
  margin-left: 1em;
  margin-right: 1em;
`

const IndicatorContainer = styled.div`
  margin: auto;
`

interface Props {
  appointmentID: string
  autoSavingStatus?: string
  clientID: string
  onClear: (appointmentID: string) => any
  selectDrawAction: (action: 'edit' | 'delete' | 'Pencil') => any
  selectDrawColour: (colour: string) => any
  selectDrawShape: (shape: 'Circle' | 'Square' | 'Pencil') => any
  selectDrawSize: (size: number) => any
  selectedAction: string
  selectedColour: string
  selectedShape: string
  selectedSize: number
  setSoapDirty: (appointmentID: string, clientID: string) => any
}

class Tools extends React.Component<Props> {
  state = {
    clearModalOpen: false,
  }
  confirmClearShapesModal = () =>
    this.setState(() => ({ clearModalOpen: true }))

  renderClearModal = () => {
    const modalProps = {
      modalOpen: this.state.clearModalOpen,
      message: 'Are you sure you want to clear all drawings?',
      onAccept: () => {
        this.setState(() => ({ clearModalOpen: false }))
        this.props.onClear(this.props.appointmentID)
        this.props.setSoapDirty(this.props.appointmentID, this.props.clientID)
      },
      onCancel: () => this.setState(() => ({ clearModalOpen: false })),
    }

    return <ClearModal {...modalProps} />
  }

  render() {
    return (
      <ToolsContainer>
        <div>{this.renderClearModal()}</div>
        <Tool>
          <ColourPicker
            onClick={this.props.selectDrawColour}
            selectedColour={this.props.selectedColour}
          />
        </Tool>

        <Tool>
          <ShapePicker
            onChange={this.props.selectDrawShape}
            selectedShape={this.props.selectedShape}
          />
        </Tool>

        {/* <Tool>
          <SizePicker
            onChange={this.props.selectDrawSize}
            selectedSize={this.props.selectedSize}
          />
        </Tool> */}

        <IndicatorContainer>
          <AutoSaveIndicator status={this.props.autoSavingStatus} />
        </IndicatorContainer>
      </ToolsContainer>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  appointmentID: getSelectedAppointmentID$(state),
  autoSavingStatus: state.draw.autoSavingDraw,
  clientID: getSelectedClientID$(state),
  selectedAction: state.draw.selectedAction,
  selectedColour: state.draw.selectedColour,
  selectedShape: state.draw.selectedShape,
  selectedSize: state.draw.selectedSize,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  selectDrawColour: (colour: string) =>
    dispatch(Ducks.selectDrawColour(colour)),
  selectDrawShape: (shape: 'Circle' | 'Square' | 'Pencil') =>
    dispatch(Ducks.selectDrawShape(shape)),
  selectDrawSize: (size: number) => dispatch(Ducks.selectDrawSize(size)),
  selectDrawAction: (action: 'edit' | 'delete' | 'Pencil') =>
    dispatch(Ducks.selectDrawAction(action)),
  onClear: (appointmentID: string) => dispatch(Ducks.onClear(appointmentID)),
  setSoapDirty: (appointmentID: string, clientID: string) =>
    dispatch(setSoapDirty(appointmentID, clientID)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tools)
