import React from 'react'

import { Dropdown } from 'components/forms'
import { IEvent } from 'interfaces'
import { FormItemComponentData } from '../interfaces'

interface Props {
  dataItem: FormItemComponentData
  onChange: (e: IEvent) => void
  options: Array<string>
  required?: boolean
  value: string
}

export const DropdownDataItem = ({
  dataItem,
  options,
  onChange,
  required,
  value,
}: Props) => {
  const dropdownOptions = options.map((opt) => ({
    id: opt,
    key: opt,
    text: opt,
    value: opt,
  }))
  return (
    <>
      <Dropdown
        label={dataItem.label}
        name={dataItem.name}
        onChange={onChange}
        options={dropdownOptions}
        placeholder=""
        validation={{ required }}
        value={value}
      />
    </>
  )
}
