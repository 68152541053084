import React, { useState } from 'react'

import { Icon, Segment, Text } from 'components/ui'
import { Audit } from '../interfaces'
import styled from 'styled-components'

const ShowRawToggle = styled.div`
  display: flex;

  :hover {
    cursor: pointer;
  }
`

interface Props {
  audit: Audit
}

export const RawAudit = ({ audit }: Props) => {
  const [showRaw, setShowRaw] = useState(false)
  const onClick = () => setShowRaw(!showRaw)

  if (!showRaw) {
    return (
      <ShowRawToggle onClick={onClick}>
        <Icon name="setting" />
        <Text>Show Raw Data</Text>
      </ShowRawToggle>
    )
  }

  let entityStr = `EntityID: ${audit.entityID}`

  if (audit.subEntityID) {
    entityStr += ` > ${audit.subEntityID}`
  }

  return (
    <>
      <ShowRawToggle onClick={onClick}>
        <Icon name="x" />
        <Text>Hide Raw Data</Text>
      </ShowRawToggle>

      <Segment>
        <div>
          <Text>{entityStr}</Text>
          {audit.value && (
            <Text>{`Value: ${audit.value.replace(',', ', ')}`}</Text>
          )}
        </div>

        <div>
          {audit.prevValue && (
            <Text>{`Previous Value: ${audit.prevValue.replace(
              ',',
              ', ',
            )}`}</Text>
          )}
        </div>
      </Segment>
    </>
  )
}
