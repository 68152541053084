import React, { useState } from 'react'
import styled from 'styled-components'

import { AddOrRemoveButton } from 'components/forms'
import { FormPreviewModal } from 'components/modals'
import { Dropdown, Icon } from 'components/ui'

// This is sooooo DUUUUUMB
declare global {
  // eslint-disable-next-line no-unused-vars
  namespace JSX {
    interface IntrinsicElements {
      strike: any
    }
  }
}
const ButtonIcon = styled(Icon)`
  margin: auto;

  :hover {
    cursor: pointer;
    color: blue;
  }
`

const FormTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75em;
`

interface Props {
  disabled?: boolean
  form: any
  index: number
  isDefault: boolean
  onAddForm: Function
  onChange: Function
  onRemoveForm: Function
  options: any[]
  selectedForms: any[]
  sortable?: boolean
  template: any
}

const FormSelect = (props: Props) => {
  const [showPreview, setShowPreview] = useState(false)

  const fileJson =
    (props.template &&
      props.template[
        (props.options.find((option) => props.form.formID === option.id) || {})
          .id
      ]) ||
    {}

  return (
    <FormTypeContainer key={props.index}>
      <FormPreviewModal
        fileJson={[fileJson]}
        modalOpen={showPreview}
        name={fileJson.name}
        onCancel={() => setShowPreview(false)}
        readonly
      />

      <Dropdown
        disabled={props.disabled || props.isDefault}
        name="formTypeSelected"
        onChange={({ target }) =>
          props.onChange(
            'selectedForms',
            props.options.find((option) => option.name === target.value).id,
            props.index,
          )
        }
        options={
          props.options &&
          props.options.length &&
          props.options.map((option) => ({
            key: option.id,
            id: option.id,
            text: option.deleted ? (
              <strike>
                <i>{`${option.name} (Deleted)`}</i>
              </strike>
            ) : (
              option.name
            ),
            value: option.name,
          }))
        }
        search
        selection
        style={{ width: '25em', height: ' 2.7em', minWidth: '15em' }}
        value={
          (
            props.options.find((option) => props.form.formID === option.id) ||
            {}
          ).name
        }
      />

      <FormTypeContainer>
        {!props.disabled && !props.isDefault && (
          <div>
            <AddOrRemoveButton
              add={props.onAddForm}
              isFirst={props.index + 1 === 1}
              // TODO - SELECTEDFORMS FIX
              isRemove={props.index + 1 !== props.selectedForms.length}
              remove={() => props.onRemoveForm(props.index)}
            />
          </div>
        )}

        {props.sortable &&
          !props.disabled &&
          !props.isDefault &&
          props.selectedForms.length > 1 && (
            <ButtonIcon name="bars" size="big" />
          )}

        {props.form.formID ? (
          <div style={{ margin: 'auto' }}>
            <ButtonIcon
              name="eye"
              onClick={() => setShowPreview(true)}
              size="big"
            />
          </div>
        ) : (
          <div style={{ width: '36.5px' }} />
        )}
      </FormTypeContainer>
    </FormTypeContainer>
  )
}

export default FormSelect
