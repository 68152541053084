import {
  reducer as fetchForms,
  defaultState as fetchFormsDefaultState,
} from './fetch-forms'
import {
  reducer as deleteForm,
  defaultState as deleteFormDefaultState,
} from './delete-form'
import { reducer as restoreForm } from './restore-form'
import { FormsState, IAction } from 'interfaces'
import { Validation } from 'components/forms/interfaces'

const formsAction = (action: string) => `client-trackyr/forms/${action}`
const ON_CHANGE = formsAction('ON_CHANGE')
const VALIDATE_FORM = formsAction('VALIDATE_FORM')
const SAVE_FORM = formsAction('SAVE_FORM')
const SET_FORM_AUTO_SAVING_STATUS = formsAction('SET_FORM_AUTO_SAVING_STATUS')

export const defaultState: FormsState = {
  ...fetchFormsDefaultState,
  ...deleteFormDefaultState,
  fetching: true,
  templates: {},
  autoSavingStatus: '',
}

const reducers = [fetchForms, deleteForm, restoreForm]

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case ON_CHANGE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          [action.name]: action.value,
        },
      }

    case VALIDATE_FORM:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          [`${action.name}-validation`]: action.validation,
        },
      }

    case SAVE_FORM:
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.form.id]: action.form,
        },
      }

    case SET_FORM_AUTO_SAVING_STATUS:
      return {
        ...state,
        autoSavingStatus: action.status,
      }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const onChange = (formID: string, name: string, value: any) => ({
  type: ON_CHANGE,
  formID,
  name,
  value,
})

export const validateForm = (
  formID: string,
  name: string,
  validation: Validation,
) => ({
  type: VALIDATE_FORM,
  formID,
  name,
  validation,
})

export const saveForm = (form: any) => ({
  type: SAVE_FORM,
  form,
})

export const setAutoSavingStatus = (status: any) => ({
  type: SET_FORM_AUTO_SAVING_STATUS,
  status,
})
