import { createSelector } from 'reselect'
import moment from 'moment'
import { pickBy } from 'lodash'
import fuzzysearch from 'fuzzysearch'

import { getSoapsForSelectedClient } from 'containers/soap-notes/selectors'
import { getSelectedClientID$ } from 'containers/clients/redux/selectors'
import { getSeletectedTreatmentNumber$ } from 'containers/treatment/selectors'
import { DEFAULT_EVENT_COLOUR } from 'constants/calendar-colours'
import { getClientTypesNormalized } from 'containers/settings/selectors'
import { getClients$ } from 'containers/clients/redux/selectors'
import colours from 'styles/colours'
import { IAppointment, IState, IAppointments } from 'interfaces'
import {
  AppointmentStatus,
  appointmentStatusToText,
  appointmentStatusToColourNullIfValid,
} from './enums'
import { emptyGuid } from 'lib/guid'

const clientAppointments$ = (state: IState) =>
  state.appointments.clientAppointments
const getAppointmentsByMonth = (state: IState) =>
  state.appointments.appointmentsByMonth
export const appointments$ = (state: IState) => state.appointments.appointments
const allAppointmentsByID$ = (state: IState) =>
  state.appointments.allAppointmentsByID
const allAppointmentsByIDLength = (state: IState) =>
  state.appointments.allAppointmentsByID.length
const getAppointmentFilter = (state: IState) => state.soapNotes.soapSearchTerm

const getClientAppointments$ = createSelector(
  [getSelectedClientID$, clientAppointments$],
  (clientID, clientAppointments) => clientAppointments[clientID] || [],
)

const getAppointmentsForTreatment$ = createSelector(
  [appointments$, getSeletectedTreatmentNumber$],
  (appointments, selectedTreatment) => {
    const results = pickBy(
      appointments,
      (a: IAppointment) => a.treatmentNumber === selectedTreatment,
    )

    return results
  },
)

export const getSelectedClientsAppointments$ = createSelector(
  [getAppointmentsForTreatment$, getClientAppointments$],
  (appointments, clientAppointments) => {
    const appointmentsForClient = []

    for (const clientAppointmentID of clientAppointments) {
      if (appointments[clientAppointmentID]) {
        appointmentsForClient.push(appointments[clientAppointmentID])
      }
    }

    const sortedAppointments = appointmentsForClient.sort(
      (a, b) =>
        // b.appointment.appointmentNumber - a.appointment.appointmentNumber,
        Number(moment(b.start).format('X')) -
        Number(moment(a.start).format('X')),
    )

    return sortedAppointments
  },
)

export const getSelectedClientsAppointmentsFiltered = createSelector(
  [getAppointmentsForTreatment$, getClientAppointments$, getAppointmentFilter],
  (
    appointments: IAppointments,
    clientAppointments: Array<string>,
    filter: string,
  ) => {
    const appointmentsForClient = []
    const lowerFilter = filter.toLowerCase()

    for (const clientAppointmentID of clientAppointments) {
      if (appointments[clientAppointmentID]) {
        const appointment = appointments[clientAppointmentID]
        if (lowerFilter) {
          const apptStart = moment(appointment.start)
            .format('dddd MMMM D YYYY hh:mm a')
            .toLowerCase()

          const apptEnd = moment(appointment.end)
            .format('dddd MMMM D YYYY hh:mm a')
            .toLowerCase()

          if (
            fuzzysearch(lowerFilter, apptStart) ||
            fuzzysearch(lowerFilter, apptEnd) ||
            fuzzysearch(
              lowerFilter,
              appointmentStatusToText(appointment.status).toLowerCase(),
            )
          ) {
            appointmentsForClient.push(appointments[clientAppointmentID])
          }
        } else {
          appointmentsForClient.push(appointments[clientAppointmentID])
        }
      }
    }

    const sortedAppointments = appointmentsForClient.sort(
      (a, b) =>
        Number(moment(b.start).format('X')) -
        Number(moment(a.start).format('X')),
    )

    return sortedAppointments
  },
)

export const getSelectedAppointment$ = (state: IState) =>
  state.appointments.selectedAppointment || ({} as IAppointment)

export const getSelectedAppointmentID$ = createSelector(
  [getSelectedAppointment$],
  (appointment) => (appointment ? appointment.id : ''),
)

export const getAllAppointmentsForCalendar$ = createSelector(
  [appointments$, allAppointmentsByID$, allAppointmentsByIDLength],
  (
    appointments,
    allAppointmentIDs,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    allAppointmentIDsLength, // eslint-disable-line no-unused-vars
  ) => {
    const appts = []

    for (const appointmentID of allAppointmentIDs) {
      if (!appointments[appointmentID]) {
        continue
      }

      const { id, start, end, title, clientID } = appointments[appointmentID]
      appts.push({
        id,
        start,
        end,
        title,
        clientID,
        eventColour: DEFAULT_EVENT_COLOUR,
      })
    }

    return appts
  },
)

export const getPreviousAppointmentFromCurrentDate = createSelector(
  [getSelectedClientsAppointments$, getSoapsForSelectedClient],
  (clientsAppointments /*, clientSoaps*/) => {
    // const now = moment().subtract(1, 'days').format('X')

    return clientsAppointments[0] || ({} as IAppointment)

    // // Since Appointments are sorted from highest to lowest, we can search this way
    // // - Will obviously have to change this if this sorting changes
    // for (const appt of clientsAppointments) {
    //   const apptDate = moment(appt.start).format('X')
    //   if (apptDate > now) {
    //     continue
    //   }

    //   const soap = clientSoaps[appt.id]

    //   if (!soap) {
    //     continue
    //   }

    //   return appt
    // }

    // return {}
  },
)

export const getAllAppointmentsForCalendar = createSelector(
  [
    appointments$,
    allAppointmentsByID$,
    allAppointmentsByIDLength,
    getClientTypesNormalized,
    getClients$,
  ],
  (
    appointments,
    allAppointmentIDs,
    // allAppointmentIDsLength - HACK used to determine if selector should re-compute
    allAppointmentIDsLength, // eslint-disable-line no-unused-vars
    clientTypes,
    clients,
  ) => {
    const appts = []

    for (const appointmentID of allAppointmentIDs) {
      if (!appointmentID || !appointments[appointmentID]) {
        continue
      }

      const { id, start, end, title, clientID, status, treatmentNumber } =
        appointments[appointmentID]
      const client = clients[clientID] || {}
      const clientType = clientTypes[client.clientTypeID] || {}

      let appointmentTitle =
        status === AppointmentStatus.NoShow ||
        status === AppointmentStatus.Cancelled ||
        status === AppointmentStatus.CancelledTooLate ||
        status === AppointmentStatus.Deleted
          ? `${title} (${appointmentStatusToText(status)})`
          : title

      if (treatmentNumber === 0 && clientID !== emptyGuid) {
        appointmentTitle += ' (!)'
      }

      const eventColour =
        appointmentStatusToColourNullIfValid(status) ||
        clientType.colour ||
        DEFAULT_EVENT_COLOUR

      appts.push({
        id,
        title: appointmentTitle,
        clientID,
        appointmentID: id,
        eventColor: eventColour,
        textColor: determineTextColour(eventColour),

        backgroundColor: eventColour || colours.CalendarEventBorder,
        borderColor: eventColour || colours.CalendarEvent,
        display: 'block',
        displayEventEnd: true,
        editable: true,
        end: new Date(moment(end).format()),
        eventDurationEditable: true,
        eventStartEditable: true,
        selectable: true,
        start: new Date(moment(start).format()),
      })
    }

    return appts
  },
)

const determineTextColour = (colour: string) => {
  if (!colour) {
    return '#eee'
  }

  let r: number, g: number, b: number
  if (colour[0] === '#') {
    const c = colour.substring(1) // strip #
    const rgb = parseInt(c, 16) // convert rrggbb to decimal
    r = (rgb >> 16) & 0xff // extract red
    g = (rgb >> 8) & 0xff // extract green
    b = (rgb >> 0) & 0xff // extract blue
  } else if (colour.includes('rgb')) {
    const rgb = colour.replace('rgb(', '').replace(')', '').split(',')

    r = Number(rgb[0])
    g = Number(rgb[1])
    b = Number(rgb[2])
  } else {
    return '#1e1e1e'
  }

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  if (luma < 150) {
    return '#eee'
  } else {
    return '#1e1e1e'
  }
}

export const getAppointmentsForToday = createSelector(
  [getAppointmentsByMonth],
  (state: any) => {
    const today = moment()
    const getter = `${today.get('year')}${today.get('month') + 1}`
    const monthsAppointments: Array<IAppointment> = state[getter]

    if (!monthsAppointments || !monthsAppointments.length) {
      return []
    }

    const todayFormat = today.format('MMDDYYYY')
    const appointments = monthsAppointments
      .filter((a) => {
        return moment(a.start).format('MMDDYYYY') === todayFormat
      })
      .sort((a: IAppointment, b: IAppointment) => {
        return (
          Number(moment(a.start).format('X')) -
          Number(moment(b.start).format('X'))
        )
      })

    return appointments || []
  },
)

export const getClientsForToday = createSelector(
  [getAppointmentsForToday, getClients$],
  (appointments, clients) => {
    return appointments.map((appointment: IAppointment) => {
      if (appointment.status === AppointmentStatus.Cancelled) {
        return null
      }

      const client = clients[appointment.clientID]
      return client
    })
  },
)

export const getClientIDsByAppointmentsForToday = createSelector(
  [getAppointmentsByMonth],
  (state) => {
    const today = moment()
    const getter = `${today.get('year')}${today.get('month') + 1}`
    const monthsAppointments: Array<IAppointment> = state[getter]

    if (!monthsAppointments || !monthsAppointments.length) {
      return []
    }

    const todayFormat = today.format('MMDDYYYY')
    const appointments = monthsAppointments.filter((a) => {
      return moment(a.start).format('MMDDYYYY') === todayFormat
    })

    return appointments.map((a) => a.clientID)
  },
)

export const getAppointmentsForClientForSelect = (
  state: IState,
  clientID: string,
) => {
  if (!clientID) {
    return []
  }
  const clientAppointments: string[] =
    state.appointments.clientAppointments[clientID]
  const appointments = state.appointments.appointments

  if (!clientAppointments || clientAppointments.length === 0) {
    return []
  }

  const appointmentsForSelect = []

  for (const appointmentID of clientAppointments) {
    const appointment = appointments[appointmentID]

    const appointmentSelect = {
      id: appointment.id,
      key: appointment.id,
      value: appointment.id,
      text: moment(appointment.start).format('MMM D YY'),
    }
    appointmentsForSelect.push(appointmentSelect)
  }
}
