export function toCamelCase(key, value) {
  if (value && typeof value === 'object') {
    for (var k in value) {
      if (k === 'ID') {
        value['id'] = value[k]
        delete value[k]
        continue
      }

      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k]
        delete value[k]
      }
    }
  }
  return value
}
