import React, { useState } from 'react'

import { Checkbox, S, Tab, Text } from 'components/ui'
import { IEvent } from 'interfaces'
import { FormTypesList } from './form-types-list'
import { getCurrentFormsForDropdown } from 'containers/forms'
import { useSelector } from 'react-redux'
import { getSoapTypesForDropdown$ } from '..'
import { FormTabs_T, Form_T } from './new-interfaces'
import {
  CLIENT_INFO_TAB_ID,
  FILES_TAB_ID,
  NOTES_TAB_ID,
  SUMMARY_TAB_ID,
} from 'constants/form'

interface Props {
  addForm: (tab: FormTabs_T) => void
  data: Form_T
  disabled?: boolean
  isDefault?: boolean
  onFormTypeChange: ({ target }: IEvent, index: number, tab: FormTabs_T) => void
  onFormTypesSort: (items: any, tab: FormTabs_T) => void
  onTabDetailsChange: ({ target }: IEvent, tab: FormTabs_T) => void
  removeForm: (index: number, tab: FormTabs_T) => void
  tabs: FormTabs_T[]
}

export const FormTypePanel = (props: Props) => {
  const [tabIndex, setTabIndex] = useState(0)

  const formOptions = useSelector(getCurrentFormsForDropdown).map((opt) => ({
    ...opt,
    text: opt.deleted ? <S>{opt.text}</S> : opt.text,
  }))
  const soapTypeOptions = useSelector(getSoapTypesForDropdown$)

  const panes = props.tabs.map((tab: FormTabs_T) => {
    const render = () => {
      const uneditableTab = tab.id === SUMMARY_TAB_ID || tab.id === FILES_TAB_ID
      if (uneditableTab) {
        return <Text>{`${tab.tab} Tab`}</Text>
      }

      const isDefaultTab =
        tab.id === CLIENT_INFO_TAB_ID ||
        tab.id === NOTES_TAB_ID ||
        tab.id === SUMMARY_TAB_ID ||
        tab.id === FILES_TAB_ID

      const name =
        tab.id === CLIENT_INFO_TAB_ID
          ? 'selectedForms'
          : tab.id === NOTES_TAB_ID
          ? 'selectedNotes'
          : tab.tab.replace(/ /, '')
      const isSelectedNotes = name === 'selectedNotes'

      let selectedForms = props.data[name] || [{}]

      if (selectedForms.length === 0) {
        selectedForms = [{}]
      }

      const onTabDetailsChange = ({ target }: IEvent) => {
        props.onTabDetailsChange({ target }, tab)
      }

      const addForm = () => {
        props.addForm(tab)
      }

      const onFormTypeChange = ({ target }: IEvent, index: number) => {
        props.onFormTypeChange({ target }, index, tab)
      }

      const onFormTypesSort = (items: any) => {
        props.onFormTypesSort(items, tab)
      }

      const removeForm = (index: number) => {
        props.removeForm(index, tab)
      }

      return (
        <>
          {!isDefaultTab && (
            <Checkbox
              checked={typeof tab.enabled === 'undefined' ? true : tab.enabled}
              disabled={props.disabled}
              label="Tab Enabled"
              name="enabled"
              onChange={onTabDetailsChange}
              style={{ marginTop: '-2em', marginBottom: '0.5em' }}
              toggle
            />
          )}

          <FormTypesList
            addForm={addForm}
            disabled={props.disabled}
            isDefault={props.isDefault}
            name={name}
            onFormTypeChange={onFormTypeChange}
            onFormTypesSort={onFormTypesSort}
            options={isSelectedNotes ? soapTypeOptions : formOptions}
            removeForm={removeForm}
            selectedForms={tab.forms}
            sortable
          />
        </>
      )
    }

    return {
      menuItem: {
        key: tab.id,
        content: tab.tab,
      },
      render,
    }
  })

  return (
    <Tab
      activeIndex={tabIndex}
      menu={{ secondary: true, pointing: true, attached: false }}
      onTabChange={(_: any, { activeIndex }) => setTabIndex(activeIndex)}
      panes={panes}
    />
  )
}
