import { authPost } from 'lib/http-request'
import { Files } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { IFile, Dispatch, Error, IAction, IResponse } from 'interfaces'
import { invalidateCacheLike } from 'lib'
import { message } from 'components/notifications'

const filesAction = (action: string) => `client-trackyr/files/${action}`
const UPDATE_FILE = filesAction('UPDATE_FILE')
const UPDATE_FILE_SUCCESS = filesAction('UPDATE_FILE_SUCCESS')
const UPDATE_FILE_FAILURE = filesAction('UPDATE_FILE_FAILURE')

export const defaultState = {}

export const reducer = (state: any = defaultState, action: IAction) => {
  switch (action.type) {
    case UPDATE_FILE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          updatingFile: true,
        },
      }
    }

    case UPDATE_FILE_SUCCESS: {
      return {
        ...state,
        files: {
          ...state.files,
          [action.file.id]: action.file,
        },
        flags: {
          ...state.flags,
          updatingFile: false,
        },
      }
    }

    case UPDATE_FILE_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          updatingFile: false,
        },
      }

    default:
      return state
  }
}

export const updateFileData = (file: IFile): any => async (
  dispatch: Dispatch,
): Promise<IResponse<IFile>> => {
  dispatch({ type: UPDATE_FILE, file })

  return authPost(Files.Update, file)
    .then((response: IResponse<IFile>) => {
      dispatch(updateFileSuccess(response.data))
      invalidateCacheLike('/api/files')
      message.success('File Updated')

      return response
    })
    .catch((error) => {
      captureMessage('Failed to update File.', Severity.Error)
      dispatch(updateFileFailure(error))
      message.error('Failed to Update File.')

      return error
    })
}

const updateFileSuccess = (file: IFile) => ({
  type: UPDATE_FILE_SUCCESS,
  file,
})

const updateFileFailure = (error: Error) => ({
  type: UPDATE_FILE_FAILURE,
  error,
})
