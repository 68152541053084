import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { getClientsForSelect$ } from 'containers/clients'
import { IEvent, IQueryParams } from 'interfaces'
import { Form, Segment } from 'components/ui'
import { Checkbox, DatePicker, Dropdown, Input } from 'components/forms'

const ESCAPE_KEY = 'Escape'
const ENTER_KEY = 'Enter'

interface Props {
	onChange: ({ target }: IEvent) => void
	query: IQueryParams
	tempHide?: boolean
}

export const ReceiptQuery = ({ onChange, query, tempHide }: Props) => {
	const clientsForSelect = useSelector(getClientsForSelect$)
	const loading = false
	const [search, setSerach] = useState('')

	const onSearchChange = ({ target }: IEvent) => {
		setSerach(target.value)
	}

	const onClear = () => {
		setSerach('')
		onChange({ target: { name: 'q', value: '' } })
	}

	const onEnter = () => {
		if (search !== query.q) {
			onChange({ target: { name: 'q', value: search } })
		}
	}

	const icon = loading
		? {}
		: search
			? { name: 'close', onClick: onClear, color: 'grey' }
			: {
				name: 'search',
				onClick: onEnter,
				color: 'grey',
			}

	return (
		<Segment>
			<Form style={{ marginTop: '1em', maxWidth: '35em' }}>
				<Dropdown
					clearable
					label='Client'
					name='clientID'
					onChange={onChange}
					options={clientsForSelect}
					value={query.clientID}
				/>

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<DatePicker
						label='From'
						name='from'
						onChange={onChange}
						placeholder='From'
						value={query.from}
					/>
					<DatePicker
						label='To'
						name='to'
						onChange={onChange}
						placeholder='To'
						value={query.to}
					/>
				</div>

				<div style={{ display: 'flex' }}>
					<Checkbox
						label='Deleted?'
						name='deleted'
						onChange={onChange}
						toggle
						value={query.deleted}
					/>
					<Checkbox
						label='Unpaid'
						name='unpaid'
						onChange={onChange}
						style={{ marginLeft: '1em' }}
						toggle
						value={query.unpaid}
					/>
				</div>

				{!tempHide && (
					<Input
						icon={{ link: true, ...icon }}
						label='Search'
						loading={loading}
						name='q'
						onBlur={onEnter}
						onChange={onSearchChange}
						onKeyUp={(e: KeyboardEvent) => {
							if (e.key === ESCAPE_KEY) {
								onClear()
							} else if (e.key === ENTER_KEY) {
								onEnter()
							}
						}}
						type='search'
						value={search}
					/>
				)}
			</Form>
		</Segment>
	)
}
