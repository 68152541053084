import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { DatePicker } from 'components/ui'
import { IEvent } from '../../interfaces'

interface Props {
  date: any
  disabled: boolean
  format: string
  name: string
  onChange: (e: IEvent) => void
}

const ReadOnlyDatePicker = styled(DatePicker)`
  :disabled {
    background-color: ${(props) => props.theme.BackgroundColour};
    color: ${(props) => props.theme.TextColour};
  }
`

const DatePickerText = ({ name, date, format, onChange, disabled }: Props) => (
  <ReadOnlyDatePicker
    defaultValue={moment()}
    disabled={disabled}
    format={format}
    name={name}
    onChange={onChange}
    showTime={{
      format: 'h:mm a',
      use12Hours: true,
    }}
    size="large"
    style={{ width: '100%' }}
    value={moment(date)}
  />
)

DatePickerText.defaultProps = {
  date: moment(),
  format: 'MMMM Do YYYY h:mm a',
}

export default DatePickerText
