import { createSelector } from 'reselect'
import { IState } from 'interfaces'

const getSelectedClientID$ = (state: IState) => state.clients.selectedClient
const getTreatment$ = (state: IState) => state.treatments

export const getTreatmentsForClient$ = createSelector(
  getTreatment$,
  getSelectedClientID$,
  (treatment, clientID) => {
    const clientTreatments = treatment[clientID]

    if (!clientTreatments) {
      return []
    }

    const allByID = clientTreatments.allByID
    const treatments =
      allByID
        .map((id: string) => clientTreatments[id])
        .sort((a: any, b: any) => b.treatmentNumber - a.treatmentNumber) || []

    return treatments
  },
)

export const getTreatmentsObjectsForClient$ = createSelector(
  getTreatment$,
  getSelectedClientID$,
  (treatment, clientID) => {
    if (!treatment[clientID]) {
      return {}
    }

    return treatment[clientID]
  },
)

export const getSeletectedTreatmentNumber$ = createSelector(
  [getTreatment$, getSelectedClientID$],
  (treatments, clientID) =>
    treatments && clientID ? treatments[clientID].selectedTreatment : 0,
)

export const getSeletectedTreatment$ = createSelector(
  [getTreatmentsForClient$, getSeletectedTreatmentNumber$],
  (treatments, selectedTreatment) =>
    treatments.find((t: any) => t.treatmentNumber === selectedTreatment) || {},
)

export const getCurrentTreatmentForClient$ = createSelector(
  [getTreatmentsForClient$],
  (treatments) => treatments.find((t: any) => !t.closed) || treatments[0] || {},
)
