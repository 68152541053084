import { useState } from 'react'
import { useSelector } from 'react-redux'

import { IAppointment } from 'interfaces'
import { getSelectedClientsAppointmentsFiltered } from 'containers/appointments'
import { Button, ButtonContainer, Checkbox, Header, Modal } from 'components/ui'
import moment from 'moment'
import {
	AppointmentStatus,
	appointmentStatusToText,
} from 'containers/appointments/enums'
import { AppointimentsPrint } from './appointments-print'

interface Props {
	open: boolean
	onClose: () => void
}

export const AppointmentsPrintModal = (props: Props) => {
	const appointments = useSelector(getSelectedClientsAppointmentsFiltered)
	const [selectedAppointments, setSelectedAppointments] = useState({})
	const [appointmentsToPrint, setAppointmentsToPrint] = useState([])
	const [step, setStep] = useState(0 as Number)
	const [allSelected, setAllSelected] = useState(false)

	const selectAll = () => {
		const all = {}
		appointments.forEach((a) => (all[a.id] = true))
		setSelectedAppointments(all)
		setAllSelected(true)
	}

	const deselectAll = () => {
		const all = {}
		setSelectedAppointments(all)
		setAllSelected(false)
	}

	const selectOrDeselectAll = () => {
		if (allSelected) {
			deselectAll()
		} else {
			selectAll()
		}
	}

	const onClose = () => {
		setStep(0)
		deselectAll()
		props.onClose()
	}

	const onBack = () => {
		if (step === 1) {
			setStep(0)
		} else {
			onClose()
		}
	}

	const onSubmit = () => {
		if (step === 1) {
			onClose()
			return
		}

		const entries = Object.entries(selectedAppointments)
		const toPrint = entries
			.map((entry) => {
				if (entry[1] === true) {
					return entry[0]
				}
				return null
			})
			.filter((e) => !!e)

		setAppointmentsToPrint(toPrint)
		setStep(1)
	}

	const nextDisabled = !Object.values(selectedAppointments).includes(true)

	return (
		<Modal onClose={props.onClose} open={props.open}>
			<Header>Select Appointments To Print</Header>

			<Modal.Content>
				{step === 0 && (
					<>
						<Button
							content={allSelected ? 'Deselect All' : 'Select All'}
							onClick={selectOrDeselectAll}
							primary
						/>
						<div style={{ overflowY: 'scroll', maxHeight: '25em' }}>
							{appointments.map((appointment: IAppointment) => {
								const appointmentID = appointment.id
								const isSelected = selectedAppointments[appointmentID] === true

								const onSelect = () => {
									setSelectedAppointments((prev) => ({
										...prev,
										[appointmentID]: !isSelected,
									}))
								}

								const statusTest =
									appointment.status === AppointmentStatus.Created
										? ''
										: ` (${appointmentStatusToText(appointment.status)})`

								const label = `${
									appointment.appointmentNumber
								}${statusTest}: ${moment(appointment.start).format(
									'MMM D YYYY',
								)} - ${moment(appointment.end).format('MMM D YYYY')}`

								return (
									<div
										key={appointmentID}
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<Checkbox
											checked={isSelected}
											label={label}
											onChange={onSelect}
										/>
									</div>
								)
							})}
						</div>
					</>
				)}
				{step === 1 && (
					<AppointimentsPrint appointmentsToPrint={appointmentsToPrint} />
				)}
			</Modal.Content>

			<Modal.Actions>
				<ButtonContainer>
					<Button content={step === 0 ? 'Close' : 'Back'} onClick={onBack} />
					<Button
						content={step === 0 ? 'Next' : 'Done'}
						disabled={nextDisabled}
						onClick={onSubmit}
						primary={step !== 1}
					/>
				</ButtonContainer>
			</Modal.Actions>
		</Modal>
	)
}
