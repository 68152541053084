import {
	ADULT_CHILD_SELECTED_TYPE,
	FAMILY_SELECTED_TYPE,
	INDIVIDUAL_SELECTED_TYPE,
} from 'constants/form'
import moment from 'moment'
import { IAppointment } from './appointments'
import { Shape } from './draw'

interface IForm {
	id: string
	dateCreated: Date | moment.Moment | any
	dateModified: Date | moment.Moment | any
	deleted?: boolean
	display?: string // DEPRECATED
	form: Array<IFormItem>
	name: string
	order?: number // DEPRECATED
	orphanedData?: IForm[]
	screen?: string // DEPRECATED
	tab?: FormType
	type: 'psychotherapy' | 'massage' | 'all' | 'user'
}

type FormType =
	| 'client-info'
	| 'notes'
	| 'therapist'
	| 'report'
	| 'misc'
	| 'any'

interface IFormsData {
	id?: string
	shapesSummaryOverride?: Array<any>
	appointment?: IAppointment
	appointmentDate?: any
	appointmentID?: string
	appointmentNumber?: 4
	bodyDiagramID?: string
	clientID?: string
	fetched?: boolean
	shapes?: Shape[]
	soapTypeID?: string
	treatmentNumber?: number

	[key: string]: any
}

interface IValidation {
	required?: boolean
	email?: boolean
	phonenumber?: boolean
	number?: boolean
	alphabetical?: boolean
	alphanumeric?: boolean
	date?: boolean
	includes?: Function
}

interface IFormItem {
	id: string
	autosave: boolean
	deprecated?: boolean
	form?: Array<IFormItem>
	icon?: string
	key: string
	label: string
	multiMap?: Array<any>
	name: string
	placeholder?: string
	required?: boolean
	size?: string
	style?: { [key: string]: string } // TODO - to CSS
	text?: string
	title: string
	type: FormGeneratorComponentType
	validation?: IValidation

	// Date Picker
	startMode?: string

	// Component Specific Props
	// Card
	collapsible?: boolean
	hideTitle?: boolean

	// Group
	showBreak?: boolean
	columnOnPrint?: boolean

	// Multiform
	addText?: string
	removeText?: string

	// Other Individual Props
	numberType?: string // InputNumber
	options?: Array<any> // Checkbox, Dropdown, etc.
	trigger?: any // YesNo
	format?: string // Date

	signatureType?: 'single' | 'multiMap' | 'guardian' // Emergency Contact

	// Form Header
	backgroundColour?: string
	centre?: Array<string>
	dateFormat?: string
	left?: Array<string>
	right?: Array<string>
	view?: Array<any>

	// Divider
	hidden?: boolean

	// Checkbox
	toggle?: boolean

	// Dropdown
	clearable?: boolean
	multiple?: boolean

	// List
	noItemText?: string
}

interface FTForm {
	type: 'stirng'
	id: string
	form?: Array<FTForm>

	[key: string]: any
}

interface FormTemplate {
	name: string
	screen: 'client'
	tab: 'client-info' | 'notes' | 'generic'
	order: number
	type: 'user' | 'all'
	display: 'responsive' | 'full' | 'massage' | 'psychotherapy'
	id: string
	created: Date | moment.Moment
	modified: Date | moment.Moment
	form: Array<FTForm>
}

interface FormTemplates {
	[key: string]: FormTemplate
}

interface IFormTab {
	default: boolean
	id: string
	key: string
	order: number
	tab: string
}

interface IFormType {
	id: string
	additionalTabs: AdditionalTabs // DEPRECATED
	colour: string
	customTabs: Array<any> | any // DEPRECATED
	forms: FormDetails[]
	isDefault: boolean
	isDeleted: boolean
	name: string
	screen: FormScreen
	selectedForms: Array<ISelectedFormType> // DEPRECATED
	selectedNotes?: Array<ISelectedFormType> // DEPRECATED
	selectedType:
		| typeof INDIVIDUAL_SELECTED_TYPE
		| typeof FAMILY_SELECTED_TYPE
		| typeof ADULT_CHILD_SELECTED_TYPE
}

interface FormDetails {
	enabled: boolean
	forms: ISelectedFormType[]
}

interface IFormTypeRequest {
	id: string
	additionalForms: string // Maps to Additional Tabs...
	colour: string
	customTabs: string
	isDefault: boolean
	isDeleted: boolean
	name: string
	screen: FormScreen
	selectedForms: Array<ISelectedFormType>
	selectedNotes?: Array<ISelectedFormType>
	selectedType:
		| typeof INDIVIDUAL_SELECTED_TYPE
		| typeof FAMILY_SELECTED_TYPE
		| typeof ADULT_CHILD_SELECTED_TYPE
}

interface AdditionalTabs {
	[key: string]: AdditionalTab
}

interface AdditionalTab {
	enabled: boolean
	name: string
	selectedForms: Array<ISelectedFormType>
}

interface ISelectedFormType {
	disabled?: boolean
	formID: string
	id: string
	order: number
	parentID: string
	selectedFormType: FormScreen
	value?: any
}

enum FormScreen {
	Unknown = 0,
	Client = 1,
	Soap = 2,
}

export type {
	IForm,
	IFormsData,
	IFormItem,
	IValidation,
	FormTemplate,
	FormTemplates,
	IFormType,
	IFormTypeRequest,
	ISelectedFormType,
	AdditionalTabs,
	AdditionalTab,
	IFormTab,
	FormType,
}

export type FormGeneratorComponentType =
	| 'fragment'
	| 'card'
	| 'group'
	| 'columns'
	| 'printColumns'
	| 'segment'
	| 'input'
	| 'inputNumber'
	| 'email'
	| 'date'
	| 'checkbox'
	| 'checkboxInput'
	| 'checkboxForm'
	| 'checkboxGroup'
	| 'checkboxGroupInput'
	| 'checkboxGroupForm'
	| 'textInput'
	| 'textArea'
	| 'phoneNumber'
	| 'radio'
	| 'yesno'
	| 'yesnoform'
	| 'dropdown'
	| 'address'
	| 'label'
	| 'text'
	| 'finePrint'
	| 'bodyDiagram'
	| 'multiForm'
	| 'signature'
	| 'emergencyContact'
	| 'formHeader'
	| 'divider'
	| 'appointmentNumber'
	| 'list'

export { FormScreen }
