import { authPost } from 'lib/http-request'
import { Services } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { IAction, Dispatch, IResponse, ServiceState } from 'interfaces'
import { Service } from '../interfaces'

const POST_SERVICE = 'client-trackyr/receipts/POST_SERVICE'
const POST_SERVICE_SUCCESS = 'client-trackyr/receipts/POST_SERVICE_SUCCESS'
const POST_SERVICE_FAILURE = 'client-trackyr/receipts/POST_SERVICE_FAILURE'

export const reducer = (state: ServiceState, action: IAction) => {
  switch (action.type) {
    case POST_SERVICE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          posting: true,
        },
      }
    }

    case POST_SERVICE_SUCCESS: {
      let allIDs = state.allIDs
      const services = state.services

      allIDs.push(action.data.id)
      allIDs = [...new Set(allIDs)]
      services[action.data.id] = action.data

      return {
        ...state,
        allIDs,
        services,
        totalReceipts: allIDs.length,
        flags: {
          ...state.flags,
          posting: false,
        },
      }
    }

    case POST_SERVICE_FAILURE: {
      return {
        ...state,
        flags: {
          ...state.flags,
          posting: false,
        },
      }
    }
    default:
      return state
  }
}

export const postService = (service: Service): any => async (
  dispatch: Dispatch,
): Promise<IResponse<Service>> => {
  dispatch({ type: POST_SERVICE, service })

  return authPost(Services.Post, service)
    .then((response) => {
      message.success('Service Saved')
      dispatch(onPostSuccess(response.data))
      return response
    })
    .catch((error) => {
      message.error('Failed to save service')
      captureMessage(`postService Failed. ${error}`, Severity.Error)
      dispatch(onPostFailure(error, service))
      return error
    })
}

const onPostSuccess = (data: any) => ({
  type: POST_SERVICE_SUCCESS,
  data,
})
const onPostFailure = (error: any, service: Service) => ({
  type: POST_SERVICE_FAILURE,
  error,
  service,
})
