import React, { useState } from 'react'
import styled from 'styled-components'

import { H4, Button, Text, Form, SubText } from 'components/ui'

import {
  DataType,
  FormItemComponent,
  FormItemComponentData,
} from '../interfaces'
import { DataItem } from './data-item'
import { IEvent } from 'interfaces'
import { SPLIT_CHAR } from './multi-text-values'
import { ComponentPreview } from './component-preview'

interface ItemProps {
  adding?: boolean
}

const Item = styled.div<ItemProps>`
  /* display: ${(props) => (props.adding ? 'absolute' : 'inherit')}; */
  border: 1px dashed gray;
  padding: 0.5em;
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailsContainer = styled.div`
  border: 1px solid gray;
  padding: 0.5em;
`

interface Props {
  component: FormItemComponent
  onClick: (component: FormItemComponent) => void
}

export const Component = ({ component, onClick }: Props) => {
  const [adding, setAdding] = useState(false)
  const [values, setValues] = useState({} as any)

  const onDataChange = ({ target }: IEvent) => {
    setValues((prev) => ({
      ...prev,
      [target.name]: target.value,
    }))
  }

  const onClickEvent = () => {
    setAdding(!adding)
  }

  const onCancel = () => {
    resetValues()
    setAdding(false)
  }

  const onAddComponent = () => {
    // TODO - dumb! This is mutating for some reason...
    const comp = JSON.parse(JSON.stringify(component))
    const data = comp.data

    for (const dataItem of data) {
      if (typeof values[dataItem.name] !== 'undefined') {
        dataItem.value = values[dataItem.name]
      } else {
        dataItem.value = dataItem.defaultValue
      }
    }

    comp.data = data
    onClick(comp)
    resetValues()
  }

  const resetValues = () => {
    const newVals = {}
    component.data.forEach((d) => (newVals[d.name] = d.defaultValue || ''))
    setValues(newVals)
  }

  const multiTextValid = (multiValues) => {
    const toValidate = multiValues.options?.split(SPLIT_CHAR)

    if (!toValidate) {
      return false
    }

    return toValidate?.filter((v) => !v)?.length === 0
  }

  const dataItemValid = (component: FormItemComponent): boolean => {
    const dataToValidate = component.data.filter((d) => {
      let multiValid = true

      if (d.type === DataType.MultiText) {
        multiValid = multiTextValid(values)
      }

      return !d.optional && !values[d.name] && !multiValid
    })

    return dataToValidate.length > 0
  }

  return (
    <Item>
      <ItemContainer onClick={onClickEvent}>
        <H4>{component.label}</H4>
        <Button
          circular
          icon={adding ? 'minus' : 'plus'}
          onClick={onClickEvent}
          primary={!adding}
          size="tiny"
        />
      </ItemContainer>

      {adding ? (
        <Text>{component.description}</Text>
      ) : (
        <SubText>{component.description}</SubText>
      )}

      {adding && (
        <DetailsContainer>
          <Form>
            {component.data.map((data: FormItemComponentData) => {
              return (
                <div key={data.name}>
                  <DataItem
                    dataItem={data}
                    onChange={onDataChange}
                    value={values[data.name]}
                  />
                </div>
              )
            })}
          </Form>

          <ItemContainer>
            <Button
              content="Add"
              disabled={dataItemValid(component)}
              icon="plus"
              onClick={onAddComponent}
              primary
              size="tiny"
            />
            <Button content="Cancel" onClick={onCancel} secondary size="tiny" />
          </ItemContainer>

          {adding && <ComponentPreview component={component} values={values} />}
        </DetailsContainer>
      )}
    </Item>
  )
}
