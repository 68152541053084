import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ErrorBoundary } from 'components/error'
import { setNavBarText } from 'components/nav'
import { Tab } from 'components/ui'
import ClientSettings from 'containers/settings/components/client-settings'
import SoapSettings from 'containers/settings/components/soap-settings'
// import TabsFormsSettings from 'containers/settings/components/tabs-forms-settings'
import { Dispatch } from 'interfaces'
import { FormSettings } from '.'

const TABS = ['forms', 'client', 'notes', 'tabs']
const NAV_BAR = ['Forms', 'Client Forms', 'Notes Forms', 'Tabs & Forms']

interface Params {
	subtab?: string
}

export const FormSettingsTabs = () => {
	const dispatch: Dispatch = useDispatch()
	const navigate = useNavigate()
	const params: Params = useParams()

	const panes = [
		{
			menuItem: {
				key: 'Forms',
				icon: 'file text',
				content: 'Forms',
			},
			render: () => (
				<ErrorBoundary>
					<FormSettings />
				</ErrorBoundary>
			),
		},
		{
			menuItem: {
				key: 'Client',
				icon: 'users',
				content: 'Client',
			},
			render: () => <ClientSettings />,
		},
		{
			menuItem: {
				key: 'Notes',
				icon: 'edit',
				content: 'Notes',
			},
			render: () => <SoapSettings />,
		},
		// {
		//   menuItem: {
		//     key: 'Tabs & Form',
		//     icon: 'file',
		//     content: 'Tabs & Form',
		//   },
		//   render: () => <TabsFormsSettings />,
		// },
	]

	let activeIndex = TABS.findIndex((tab) => tab === params.subtab)

	if (activeIndex === -1) {
		activeIndex = 0
	}

	useEffect(() => {
		dispatch(setNavBarText(NAV_BAR[activeIndex]))
	}, [dispatch, activeIndex])

	return (
		<Tab
			activeIndex={activeIndex}
			// highlight
			menu={{ secondary: true, pointing: true, attached: false }}
			onTabChange={(_, { activeIndex }) => {
				navigate(`/account/forms/${TABS[activeIndex]}`)
			}}
			panes={panes}
			responsive={false}
		/>
	)
}
