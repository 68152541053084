import React from 'react'
import styled from 'styled-components'

const ColourGrid = styled.div`
  max-width: 20rem;
  display: flex;
  ${'' /* display: grid;
  grid-gap: 5px;
  grid-template-rows: 100% 100%;
  grid-template-columns: repeat(auto-fit, minmax(2rem, 1fr)); */}
`

interface CBProps {
  colour: String
  selected?: boolean
}

const ColourBlock = styled.div<CBProps>`
  width: 2rem;
  height: 2rem;
  ${(props) => `background-color: ${props.colour}`};
  ${(props) =>
    props.selected
      ? props.colour === '#000000'
        ? 'border: 3px solid #6e6e6e'
        : 'border: 3px solid #000000'
      : null};

  @media (max-width: 768px) {
    width: 2.25rem;
    height: 2.25rem;
  }

  :hover {
    cursor: pointer;
  }

  @media (prefers-color-scheme: dark) {
    ${(props) =>
      props.selected
        ? props.colour === '#ffeb3b'
          ? 'border: 3px solid ##6e6e6e'
          : 'border: 3px solid #fff'
        : null};
  }
`

// NOTE - if you add colours make sure to check isDarkColour!!!
export const drawColours = [
  { colour: '#e20000', name: 'Red' },
  { colour: '#ffeb3b', name: 'Yellow' },
  { colour: '#4caf50', name: 'Green' },
  { colour: '#9c27b0', name: 'Purple' },
  { colour: '#2196f3', name: 'Blue' },
  { colour: '#ff9800', name: 'Orange' },
  { colour: '#000000', name: 'Black' },
]

export const isDarkColour = (colour: string) =>
  colour === '#e20000' || colour === '#9c27b0' || colour === '#000000'

interface Props {
  onClick: (colour: string) => void
  selectedColour: string
}

const ColourPicker = ({ onClick, selectedColour }: Props) => (
  <ColourGrid>
    {drawColours.map((colour) => (
      <ColourBlock
        key={colour.colour}
        colour={colour.colour}
        onClick={() => onClick(colour.colour)}
        selected={selectedColour === colour.colour}
      />
    ))}
  </ColourGrid>
)

export default ColourPicker
