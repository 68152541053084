import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { TimePicker } from 'components/ui'

import { AddOrRemoveButton } from 'components/forms'
import { Label } from 'components/ui'

const TimeSlotContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 40%;
`

interface Slots {
  from: string
  to: string
}

interface Props {
  addSlot: Function
  disabled: boolean
  editing: boolean
  onTimeSlotChange: Function
  removeSlot: Function
  slots: Array<Slots>
}

const TimeSlot = ({
  slots,
  addSlot,
  removeSlot,
  onTimeSlotChange,
  disabled,
  editing,
}: Props) => (
  <>
    {slots.map((slot, index) => (
      <TimeSlotContainer key={index}>
        <div style={{ paddingRight: '1em' }}>
          {(editing || index === 0) && <Label>From</Label>}
          <div>
            <TimePicker
              clearIcon={null}
              clockIcon={null}
              defaultValue="09:00"
              disableClock
              disabled={disabled}
              format="h:mm a"
              onChange={(e) => {
                onTimeSlotChange('from', e.target.value, index)
              }}
              readonly={!editing}
              value={slot.from}
            />
          </div>
        </div>
        <div style={{ paddingRight: '1em' }}>
          {(editing || index === 0) && <Label>To</Label>}
          <div>
            <TimePicker
              clearIcon={null}
              clockIcon={null}
              defaultOpenValue={moment('5:00pm', 'H:mm a')}
              defaultValue="17:00"
              disableClock
              disabled={disabled}
              format="h:mm a"
              onChange={(e) => onTimeSlotChange('to', e.target.value, index)}
              readonly={!editing}
              value={slot.to}
            />
          </div>
        </div>
        <div>
          {editing && (
            <div style={{ marginTop: '1.6em' }}>
              <AddOrRemoveButton
                add={addSlot}
                disabled={disabled}
                isFirst={slots.length === 1}
                isRemove={
                  slots.length <= 1 ? false : index + 1 !== slots.length
                }
                remove={() => removeSlot(index)}
              />
            </div>
          )}
        </div>
      </TimeSlotContainer>
    ))}
  </>
)

TimeSlot.defaultProps = {
  disabled: false,
}

export default TimeSlot
