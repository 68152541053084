interface UrlParams<T> {
  params: T
}

interface RouterLocation {
  hash: string
  pathname: string
  search: string
  state: any
}

interface ClientInfoURLParams {
  appointmentID?: string
  clientID?: string
  tab?: string
  treatmentNumber?: number
}

export const parseSearchLocation = (
  search: string,
): { [key: string]: any | any[] } => {
  const parsedQuery = {}
  search
    .replace('?', '')
    .split('&')
    .forEach((s: string) => {
      const query = s.split('=')
      let queryStr: any = query[1]

      if (query[0] === 'auditType' || query[0] === 'location') {
        if (queryStr.includes(',')) {
          queryStr = query[1]
            .split(',')
            .map((s) => (!isNaN(Number(s)) ? Number(s) : s))
        } else {
          queryStr = [!isNaN(Number(queryStr)) ? Number(queryStr) : queryStr]
        }
      }

      parsedQuery[query[0]] = queryStr
    })

  return parsedQuery
}

export type { UrlParams, ClientInfoURLParams, RouterLocation }
