import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Modal, Header, Text, Icon } from 'components/ui'
import { FileDetails } from './file-details'
import ConfirmCancelModal from 'components/modals/confirm-cancel-modal'
import { deleteFile } from 'containers/files'
import { IFile, IState, IEvent, IResponse, Dispatch } from 'interfaces'
import { getContentType, getExtension } from 'lib/file'
import { updateFileData } from '../redux/update-file'

const FileDetailsModal = (props: IFileDetailsModal) => {
	const dispatch: Dispatch = useDispatch()
	const deletingFile = useSelector((state: IState) => state.files.deletingFile)
	const updatingFile = useSelector(
		(state: IState) => state.files.flags.updatingFile,
	)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isDirty, setIsDirty] = useState(false)
	const [file, setFile] = useState(props.file)
	const [fileExtension, setFileExtension] = useState('')

	useEffect(() => {
		if (props.file) {
			setFile(props.file)
			setFileExtension(getExtension(props.file.fileName))
		}
		return () => setFile(null)
	}, [props.file])

	if (!props.modalOpen) {
		return null
	}

	const onClose = () => {
		setIsDirty(false)
		props.onClose()
	}

	if (!props.file) {
		return (
			<Modal onClose={onClose} open={props.modalOpen}>
				<Header>File Details</Header>
				<Modal.Content>
					<Text>No File Selected</Text>
				</Modal.Content>
			</Modal>
		)
	}

	const updateFile = () => {
		let fileName = file.fileName
		if (!fileName.endsWith(fileExtension)) {
			fileName += fileExtension
			file.fileName = fileName
		}

		dispatch(updateFileData(file))
			.then((response: IResponse<IFile>) => {
				if (!response.isAxiosError || response.status === 200) {
					setIsDirty(false)
				}
			})
			.catch(() => {
			})
	}

	const onChange = ({ target }: IEvent) => {
		setIsDirty(true)
		setFile((prev) => ({
			...prev,
			[target.name]: target.value,
		}))
	}

	const storagePath = props.file.storagePath || ''
	const isForm = storagePath.includes('/forms/')
	const contentType = getContentType(props.file)

	return (
		<>
			<ConfirmCancelModal
				content='This is permanent!'
				message='Are you sure you want to delete this file?'
				modalOpen={deleteModalOpen}
				onAccept={() => {
					setDeleteModalOpen(false)
					dispatch(deleteFile(props.file.id)).then(() => {
						props.afterDeleteCallback &&
						props.afterDeleteCallback(props.file.id)
						onClose()
					})
				}}
				onCancel={() => setDeleteModalOpen(false)}
			/>
			<Modal onClose={onClose} open={props.modalOpen}>
				<Header>
					<Text>
						<Icon
							name={contentType}
							size='big'
							style={{ margin: 'auto', marginRight: '0.5em' }}
						/>
						{props.file.fileName}
					</Text>
				</Header>

				<FileDetails
					file={file}
					fileExtension={fileExtension}
					filePathUrl={props.filePathUrl}
					onChange={onChange}
					readonly={isForm}
				/>

				<Modal.Actions>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							{!isForm && (
								<Button
									loading={deletingFile}
									negative
									onClick={() => setDeleteModalOpen(true)}
								>
									Delete
								</Button>
							)}
						</div>
						<div>
							<Button onClick={onClose} secondary>
								Close
							</Button>

							{isDirty ? (
								<Button
									content='Save'
									icon='checkmark'
									loading={updatingFile}
									onClick={updateFile}
									primary
								/>
							) : (
								<Button
									content='Download'
									icon='download'
									onClick={props.onDownload}
									primary
								/>
							)}
						</div>
					</div>
				</Modal.Actions>
			</Modal>
		</>
	)
}

interface IFileDetailsModal {
	file: IFile
	filePathUrl?: string
	modalOpen: boolean
	onClose: Function
	onDownload: Function
	afterDeleteCallback?: Function
}

export { FileDetailsModal }
