import { authDelete } from 'lib/http-request'
import { Account } from 'constants/endpoints'
import { message } from 'components/notifications'
import { invalidateCache } from 'lib'
import { OFFICE_CACHE } from 'constants/cache'
import { captureMessage } from '@sentry/browser'
import { IAction, AccountState, Dispatch, Error } from 'interfaces'
import { Severity } from 'interfaces'

const officeAction = (action: string) => `client-trackyr/offices/${action}`
const ON_DELETE_OFFICE = officeAction('ON_DELETE_OFFICE')
const ON_DELETE_OFFICE_SUCCESS = officeAction('ON_DELETE_OFFICE_SUCCESS')
const ON_DELETE_OFFICE_FAILURE = officeAction('ON_DELETE_OFFICE_FAILURE')

export const defaultState = {} as AccountState

export const reducer = (
  state: AccountState = defaultState,
  action: IAction,
) => {
  switch (action.type) {
    case ON_DELETE_OFFICE:
      return {
        ...state,
      }

    case ON_DELETE_OFFICE_SUCCESS: {
      const offices = { ...state.offices }
      delete offices[action.id]

      const officesByID = state.officesByID.filter((id) => id !== action.id)

      return {
        ...state,
        offices,
        officesByID,
      }
    }

    case ON_DELETE_OFFICE_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export const onDeleteOffice = (officeID: string): any => async (
  dispatch: Dispatch,
): Promise<any> => {
  dispatch({ type: ON_DELETE_OFFICE, officeID })

  if (officeID === 'new') {
    dispatch(onDeleteOfficeSuccess(officeID))
    return
  }

  return authDelete(Account.DeleteOffice(officeID))
    .then((response) => {
      message.success('Office Deleted')
      dispatch(onDeleteOfficeSuccess(officeID))
      invalidateCache(OFFICE_CACHE)

      return response
    })
    .catch((error) => {
      message.error('Failed to Delete Office')

      captureMessage(`onDeleteOffice Failed. ${error}`, Severity.Error)

      dispatch(onDeleteOfficeFailure(error))

      return error
    })
}

const onDeleteOfficeSuccess = (id: string) => ({
  type: ON_DELETE_OFFICE_SUCCESS,
  id,
})

const onDeleteOfficeFailure = (error: Error) => ({
  type: ON_DELETE_OFFICE_FAILURE,
  error,
})
