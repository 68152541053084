import {
	Card,
	FormField,
	Text,
	Label,
	RadioButton,
	RadioGroup,
} from 'components/ui'
import { FeatureToggle } from 'containers/features'
import {
	CanSendAppointmentReminders,
	PublicIntakes,
	GlobalPublicIntakes,
	GlobalAppointmentReminders,
	GlobalEmailInvoices,
	CanEmailInvoices,
} from 'constants/features'

import { PublicIntakeEmailSettings } from './public-intake-email-settings'
import { AppointmentReminderEmailSettings } from './appointment-reminder-email-settings'
import { InvoiceEmailSettings } from '../../receipts/settings/invoice-email-settings'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, IEvent, IState, SettingKey } from 'interfaces'
import { onSettingChanged, saveSettings } from '..'
import Setting from './setting'
import { DEFAULT_SEND_APPOINTMENT_EMAIL } from 'constants/settings'
import { getSetting } from '../selectors'
import { HelperString } from './helper-strings'

export const EmailSettings = () => {
	const dispatch: Dispatch = useDispatch()
	const defaultSendEmail = useSelector((state: IState) =>
		getSetting(state, DEFAULT_SEND_APPOINTMENT_EMAIL),
	)

	const onSettingChange = (event: IEvent) => {
		const setting = {
			setting: event.target.name as SettingKey,
			value: event.target.value,
		}
		return dispatch(saveSettings(setting))
	}

	const settingChanged = (value: string, setting: string) => {
		dispatch(onSettingChanged(value, setting))
	}

	return (
		<>
			<Card hideTitle>
				<FormField>
					<Label>Default Send Appointment Emails</Label>
					<Text>
						When creating appointments, default the initial appointment email
						and the reminder email to be sent or not
					</Text>
					<Setting patch={onSettingChange}>
						<RadioGroup
							defaultValue={true}
							name={DEFAULT_SEND_APPOINTMENT_EMAIL}
							onChange={({ target }) =>
								settingChanged(target.value, DEFAULT_SEND_APPOINTMENT_EMAIL)
							}
							value={defaultSendEmail}
						>
							<RadioButton value='false'>No</RadioButton>
							<RadioButton value='true'>Yes</RadioButton>
						</RadioGroup>
					</Setting>
				</FormField>
			</Card>

			<HelperString />

			<FeatureToggle features={[GlobalPublicIntakes, PublicIntakes]}>
				<PublicIntakeEmailSettings />
			</FeatureToggle>

			<FeatureToggle
				features={[GlobalAppointmentReminders, CanSendAppointmentReminders]}
			>
				<AppointmentReminderEmailSettings />
			</FeatureToggle>

			<FeatureToggle features={[GlobalEmailInvoices, CanEmailInvoices]}>
				<InvoiceEmailSettings />
			</FeatureToggle>
		</>
	)
}
