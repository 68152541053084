import React, { useState } from 'react'

import { Button, Header, Text, Modal } from 'components/ui'
import {
  AppointmentComponents,
  ContainerComponents,
  CTInputComponents,
  InputComponents,
  MultiComponents,
  TextComponents,
} from './component-definition'
import { FormItemComponent } from './interfaces'
import { Component } from './components/component'
import { SearchBox } from 'components/forms'
import { IEvent } from 'interfaces'
import { filterFormComponents } from 'lib'
import styled from 'styled-components'
import { MobileBreakPointMedium } from 'styles'

const MobileView = styled.div`
  @media (min-width: ${MobileBreakPointMedium}) {
    display: none;
  }
`

const LargeView = styled.div`
  @media (max-width: ${MobileBreakPointMedium}) {
    display: none;
  }
`

interface Props {
  onAddComponent: (dataItem: FormItemComponent) => void
}

export const ComponentTools = (props: Props) => {
  const [addComponentModalOpen, setAddComponentModalOpen] = useState(false)
  const onClick = () => setAddComponentModalOpen(true)
  const onClose = () => setAddComponentModalOpen(false)

  const onMobileAdd = (dataItem: FormItemComponent) => {
    onClose()
    props.onAddComponent(dataItem)
  }

  return (
    <>
      <MobileView>
        <Modal onClose={onClose} open={addComponentModalOpen}>
          <Modal.Content>
            <Tools onAddComponent={onMobileAdd} />
          </Modal.Content>

          <Modal.Actions>
            <Button content="Close" icon="x" onClick={onClose} />
          </Modal.Actions>
        </Modal>

        <Button
          content="Add Component"
          icon="plus"
          onClick={onClick}
          primary
          style={{ marginBottom: '1em' }}
        />
      </MobileView>

      <LargeView>
        <Tools onAddComponent={props.onAddComponent} />
      </LargeView>
    </>
  )
}

const Tools = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredContainerComponents = filterFormComponents(
    searchTerm,
    ContainerComponents.filter((c) => !c.hidden),
  )
  const filteredCTComponents = filterFormComponents(
    searchTerm,
    CTInputComponents.filter((c) => !c.hidden),
  )
  const filteredAComponents = filterFormComponents(
    searchTerm,
    AppointmentComponents.filter((c) => !c.hidden),
  )
  const filteredInputComponents = filterFormComponents(
    searchTerm,
    InputComponents.filter((c) => !c.hidden),
  )
  const filteredTextComponents = filterFormComponents(
    searchTerm,
    TextComponents.filter((c) => !c.hidden),
  )
  const filteredMiltiComponents = filterFormComponents(
    searchTerm,
    MultiComponents.filter((c) => !c.hidden),
  )

  const renderContainers = filteredContainerComponents.length > 0
  const renderCTComponents = filteredCTComponents.length > 0
  const renderAComponents = filteredAComponents.length > 0
  const renderInput = filteredInputComponents.length > 0
  const renderText = filteredTextComponents.length > 0
  const renderMulti = filteredMiltiComponents.length > 0

  return (
    <>
      <Header>Form Components</Header>
      <SearchBox
        onChange={({ target }: IEvent) => setSearchTerm(target.value)}
        onClear={() => setSearchTerm('')}
        value={searchTerm}
      />

      {renderContainers && <Header>Containers</Header>}
      {renderContainers && (
        <Text>Containers are used to keep related information together</Text>
      )}

      {filteredContainerComponents.map((component: FormItemComponent) => (
        <div key={component.type}>
          <Component component={component} onClick={props.onAddComponent} />
        </div>
      ))}

      {renderCTComponents && <Header>Pre-defined Client Components</Header>}
      {renderCTComponents && (
        <Text>
          Pre-defined Components are used for Clients. These fields must exist
          once in one Form for each client in order to utilize searching and
          sending texts or emails to clients from the app.
        </Text>
      )}
      {filteredCTComponents.map((component: FormItemComponent) => (
        <div key={component.type}>
          <Component component={component} onClick={props.onAddComponent} />
        </div>
      ))}

      {renderAComponents && <Header>Pre-defined Appointment Components</Header>}
      {renderAComponents && (
        <Text>
          Pre-defined components for appointments can be used to edit or modify
          application data.
        </Text>
      )}
      {filteredAComponents.map((component: FormItemComponent) => (
        <div key={component.type}>
          <Component component={component} onClick={props.onAddComponent} />
        </div>
      ))}

      {renderInput && <Header>Input Components</Header>}
      {renderInput && <Text>Used to accept a variety of inputs</Text>}
      {filteredInputComponents.map((component: FormItemComponent) => (
        <div key={component.type}>
          <Component component={component} onClick={props.onAddComponent} />
        </div>
      ))}

      {renderText && <Header>Text Components</Header>}
      {renderText && <Text>Used to render text for a form</Text>}
      {filteredTextComponents.map((component: FormItemComponent) => (
        <div key={component.type}>
          <Component component={component} onClick={props.onAddComponent} />
        </div>
      ))}

      {renderMulti && <Header>Multi Components</Header>}
      {renderMulti && (
        <Text>
          Multi Components are more special purpose pre-built form items
        </Text>
      )}
      {filteredMiltiComponents.map((component: FormItemComponent) => (
        <div key={component.type}>
          <Component component={component} onClick={props.onAddComponent} />
        </div>
      ))}
    </>
  )
}
