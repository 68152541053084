import React from 'react'

import { Label, Segment, Text } from 'components/ui'
import { toCamelCase } from './json-parsing-fix'

interface Props {
  value: string
}

export const EmailValue = ({ value }: Props) => {
  const email: any = JSON.parse(value, toCamelCase)

  return (
    <Segment>
      <Label>To</Label>
      <Text>{email.to}</Text>

      <Label>Subject</Label>
      <Text>{email.subject}</Text>
    </Segment>
  )
}
