import React from 'react'
import { Progress } from 'semantic-ui-react'
import Dropzone, { Accept } from 'react-dropzone'
import styled from 'styled-components'
import moment from 'moment'

import { Icon, H3, Divider, Button } from 'components/ui'
import { getAuthToken } from 'lib/auth'
import { authPost } from 'lib/http-request'
import { FeatureToggle } from 'containers/features'
import { FileUpload as FileUploadFeature, BetaUser } from 'constants/features'
import { IFile, IEvent } from 'interfaces'
import { FileDetails } from 'containers/files/components/file-details'
import { invalidateCacheLike, getTagFromMimeType, getExtension } from 'lib'

export const UploadArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  border-radius: 5px;
  border: 5px dashed ${(props) => props.theme.MutedBorderColour};

  margin: 1em;
  padding: 1em;

  :hover {
    cursor: pointer;
  }
`

const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
`

interface Props {
	accept: Accept
	multiple?: boolean
	onComplete: Function
	onDropOverride?: Function
	onError?: Function
	onStart?: Function
	hideDetails?: boolean
	status: string
	uploadText: string
	url: string
}

interface State {
	extension: string
	file: IFile
	fsFile: File
	progress: number
	status: 'active' | 'success' | 'error' | 'none'
	tags: string
	token: string
	uploadStarted: boolean
}

class FileUpload extends React.Component<Props> {
	public static defaultProps: Partial<Props> = {
		multiple: false,
		status: 'active',
		uploadText: 'Click or drag a file to this area to upload',
	}

	state: State = {
		extension: '',
		file: null,
		fsFile: null,
		progress: 0,
		status: 'none',
		tags: '',
		token: '',
		uploadStarted: false,
	}

	componentDidMount() {
		getAuthToken().then((token) => this.setState(() => ({ token })))
	}

	onProgress = (percentComplete: number) => {
		this.setState(() => ({
			progress: percentComplete,
			status: percentComplete === 100 ? 'success' : 'active',
		}))
	}

	// TODO - handle multiple one day
	uploadFiles = (files: File | File[], onProgress: Function) => {
		const file = Array.isArray(files) ? files[0] : files
		// const numFiles = files.length
		// TODO - ^ spread progress over number of files
		this.setState(() => ({ status: 'active' }))
		this.props.onStart && this.props.onStart()
		let fileName = this.props.hideDetails ? file.name : this.state.file.fileName
		if (!fileName.endsWith(this.state.extension)) {
			fileName += this.state.extension
		}
		const modifiedFile = new File([file], fileName, {
			type: file.type,
		})

		// for (const file of files) {
		const data = new FormData()
		data.append('file', modifiedFile)

		if (this.state.file?.tags) {
			data.append('tags', this.state.file.tags)
		}
		if (this.state.file?.notes) {
			data.append('notes', this.state.file.notes)
		}

		const config = {
			onUploadProgress: function (progressEvent: any) {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total,
				)

				onProgress(percentCompleted)
			},
			headers: { 'Content-Type': 'multipart/form-data' },
		}

		authPost(this.props.url, data, config)
			.then((response) => {
				invalidateCacheLike('/api/files')
				invalidateCacheLike('/storage/client')
				this.setState(() => ({ status: 'none' }))
				this.props.onComplete(response)
			})
			.catch((error) => {
				this.props.onError(error)
				this.setState(() => ({ status: 'error' }))
			})
		// }
	}

	setFilePreview = (f: Array<File>) => {
		const file = f[0]
		const fileURL = URL.createObjectURL(file)

		const filePreview: IFile = {
			fileName: file.name,
			fileSize: file.size,
			contentType: file.type,
			storagePath: fileURL,
			dateCreated: moment(file.lastModified),
			dateModified: moment(file.lastModified),
		}
		const defaultTags = getTagFromMimeType(filePreview)
		this.setState(
			() => ({
				file: filePreview,
				fsFile: file,
				extension: getExtension(file.name),
			}),
			() => this.onChange({ target: { name: 'tags', value: defaultTags } }),
		)
	}

	onChange = ({ target }: IEvent) => {
		this.setState((prev: State) => ({
			file: {
				...prev.file,
				[target.name]: target.value,
			},
		}))
	}

	render() {
		return (
			<FeatureToggle features={[FileUploadFeature, BetaUser]}>
				<>
					{this.state.file && !this.props.hideDetails && (
						<>
							<FileDetails
								editing
								file={this.state.file}
								fileExtension={this.state.extension}
								filePathUrl={this.state.file.storagePath}
								onChange={this.onChange}
							/>
							{!this.props.onDropOverride && this.state.file && (
								<div style={{ paddingLeft: '2em' }}>
									<Button
										content='Upload'
										icon='cloud upload'
										loading={
											this.state.status === 'active' ||
											this.state.status === 'success'
										}
										onClick={() =>
											this.uploadFiles(this.state.fsFile, this.onProgress)
										}
										primary
									/>
								</div>
							)}
							<Divider />
						</>
					)}

					<Dropzone
						accept={this.props.accept}
						multiple={false}
						// TODO - fix: multiple={this.props.multiple}
						onDrop={(acceptedFiles: any) => {
							if (this.props.onDropOverride) {
								this.setState(() => ({ uploadStarted: true, progress: 0 }))

								this.props.onDropOverride &&
								this.props.onDropOverride(
									acceptedFiles,
									this.onProgress,
									this.props.onStart,
								)
							} else {
								if (!this.props.hideDetails) {
									this.setFilePreview(acceptedFiles)
								} else {
									this.uploadFiles(acceptedFiles, this.onProgress)
								}
							}
						}}
					>
						{({ getRootProps, getInputProps }) => (
							<section>
								<UploadArea {...getRootProps()}>
									<input {...getInputProps()} />
									<Icon name='inbox' size='huge' />
									<H3>{this.props.uploadText}</H3>
								</UploadArea>
							</section>
						)}
					</Dropzone>
					{this.state.uploadStarted && this.state.progress >= 0 && (
						<ProgressContainer>
							<Progress
								error={this.state.status === 'error'}
								indicating={this.state.status !== 'success'}
								percent={this.state.progress}
								size='small'
								style={{ width: '100%' }}
								success={this.state.status === 'success'}
							/>
							<Icon
								color={
									this.state.status === 'success'
										? 'green'
										: this.state.status === 'error'
											? 'red'
											: 'grey'
								}
								name={
									this.state.status === 'success'
										? 'check circle'
										: this.state.status === 'error'
											? 'remove circle'
											: ''
								}
								style={{ marginTop: '-3px' }}
							/>
						</ProgressContainer>
					)}
				</>
			</FeatureToggle>
		)
	}
}

export default FileUpload
