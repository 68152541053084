import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkFeature, FeatureToggle } from 'containers/features'
import {
	PublicIntakes as PublicIntakesFeature,
	GlobalPublicIntakes as GlobalPublicIntakesFeature,
} from 'constants/features'
import { Spinner } from 'components/loaders'
import { Button, Card, H1, Tab } from 'components/ui'

import { PublicIntakes } from './public-intakes'
import { BasicClientSummary } from './basic-client-summary'
import { LastAppointmentSummary } from './last-appointment-summary'
import { Dispatch, IClient, IState, Treatment } from 'interfaces'
import { getSeletectedTreatment$ } from 'containers/treatment'
import { AuditContainer } from 'containers/audits'
import { getClientFullName, getSelectedClient$ } from '../redux/selectors'
import { archiveClient } from '../redux/archive-client'
import { ConfirmCancelModal } from 'components/modals'
import { unArchiveClient } from '../redux/un-archive-client'
import { useNavigate } from 'react-router-dom'

export const ClientSummary = () => {
	const dispatch: Dispatch = useDispatch()
	const navigate = useNavigate()

	const fetchingForms = useSelector(
		(state: IState) => state.publicIntake.fetchingForms,
	)
	const treatment: Treatment = useSelector(getSeletectedTreatment$)
	const client: IClient = useSelector(getSelectedClient$)
	const archiving = useSelector(
		(state: IState) => state.clients.flags.archiving,
	)
	const clientName = useSelector(() => getClientFullName(client))
	const hasPublicIntakeFeature = useSelector((state: IState) =>
		checkFeature(state, PublicIntakesFeature),
	)

	const [archiveClientOpen, setArchiveClientOpen] = useState(false)
	const onArchiveOpen = () => setArchiveClientOpen(true)
	const onArchiveClose = () => setArchiveClientOpen(false)

	const onArchiveClient = () => {
		dispatch(archiveClient(client.id)).then(() => {
			navigate('/clients')
		})
		onArchiveClose()
	}

	const onUnArchiveClient = () => {
		dispatch(unArchiveClient(client.id))
		onArchiveClose()
	}

	let panes = [
		{
			menuItem: { content: 'Client & Appointment', icon: 'user' },
			render: () => {
				return (
					<>
						<ConfirmCancelModal
							content={
								client.archived
									? 'Unarchive this client?'
									: 'Do you want to archive this client?'
							}
							gentle={client.archived}
							loading={archiving}
							message='Archive Client'
							modalOpen={archiveClientOpen}
							onAccept={client.archived ? onUnArchiveClient : onArchiveClient}
							onCancel={onArchiveClose}
						/>
						<H1>{clientName}</H1>

						<BasicClientSummary readonly={treatment.closed} />
						<LastAppointmentSummary
							clientID={client.id}
							treatmentNumber={treatment.treatmentNumber}
						/>

						<Button
							content={client.archived ? 'Un-Archive Client' : 'Archive Client'}
							icon='archive'
							loading={archiving}
							onClick={onArchiveOpen}
						/>
					</>
				)
			},
		},
		{
			menuItem: { content: 'Public Intakes', icon: 'file alternate' },
			render: () => {
				return (
					<FeatureToggle
						features={[PublicIntakesFeature, GlobalPublicIntakesFeature]}
					>
						{client.email && (
							<Card title='Intake Forms'>
								<Spinner spinning={fetchingForms} tip='loading...'>
									<PublicIntakes readonly={treatment.closed} />
								</Spinner>
							</Card>
						)}
					</FeatureToggle>
				)
			},
		},
		{
			menuItem: { content: 'Audit History', icon: 'archive' },
			render: () => {
				return (
					<Card title='Audit History'>
						<AuditContainer entityID={treatment.clientID} pickTable />
					</Card>
				)
			},
		},
	]

	if (!hasPublicIntakeFeature) {
		panes = panes.filter((p) => p.menuItem.content !== 'Public Intakes')
	}

	return (
		<div>
			<div style={{ width: '100%' }}>
				<Tab
					menu={{ secondary: true, pointing: true }}
					panes={panes}
					responsive={false}
				/>
			</div>
		</div>
	)
}
