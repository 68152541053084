import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'
// import registerServiceWorker from './registerServiceWorker'
import store from './store'
import { ravenEnv, currentVersion } from 'config/config'

import 'semantic-ui-css/semantic.min.css'
import 'react-sortable-tree/style.css'

import './styles/style.css'

if (ravenEnv !== 'dev') {
  Sentry.init({
    dsn: 'https://e7d9432444b54eeca37d3d4e46f9372d@o153705.ingest.sentry.io/1207162',
    release: currentVersion,
    environment: ravenEnv,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root'),
    )
  })
}

// registerServiceWorker()
