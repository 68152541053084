import { ClientList } from 'components/clients'
import { Button, Card, Container, ContainerLayout, Popup } from 'components/ui'
import { Client } from 'constants/endpoints'
import { GetAllResponse, IClient, IQueryParams, IResponse } from 'interfaces'
import { authGet } from 'lib/http-request'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const CLIENT_PAGE_SIZE = 10

export const ArchivedClientsView = () => {
  const [archivedClients, setArchivedClients] = useState([])

  useEffect(() => {
    const fetchArchivedClients = async () => {
      const pageData: IQueryParams = {
        take: CLIENT_PAGE_SIZE,
        skip: 0,
        archived: true,
      }

      let clients = []

      const response: IResponse<GetAllResponse<IClient>> = await authGet(
        Client.AllClients(pageData),
      )

      clients = response.data.items

      const totalCount = response?.data?.totalCount || 0
      const additionalFetches = Math.ceil(totalCount / CLIENT_PAGE_SIZE) - 1

      for (let i = 0; i < additionalFetches; i++) {
        pageData.skip = pageData.skip + CLIENT_PAGE_SIZE
        const response: IResponse<GetAllResponse<IClient>> = await authGet(
          Client.AllClients(pageData),
        )

        if (response?.data?.items?.length) {
          clients = [...clients, ...response.data.items]
        }
      }

      setArchivedClients(clients)
    }

    fetchArchivedClients()
  }, [setArchivedClients])

  return (
    <ContainerLayout>
      <Container>
        <Card
          extra={
            <div>
              <Popup
                content="Back to Clients"
                trigger={
                  <Link to="/clients">
                    <Button icon="arrow left" />
                  </Link>
                }
              />
            </div>
          }
          title="Archived Clients"
        >
          <ClientList clients={archivedClients} search={false} />
        </Card>
      </Container>
    </ContainerLayout>
  )
}
