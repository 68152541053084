import { authDelete } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { FormScreen, IAction, Dispatch, SettingsState } from 'interfaces'
import { getFormTypeFromFormScreen } from 'constants/settings'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`

const DELETE_FORM_TYPE = settingsAction('DELETE_FORM_TYPE')
const DELETE_FORM_TYPE_SUCCESS = settingsAction('DELETE_FORM_TYPE_SUCCESS')
const DELETE_FORM_TYPE_FAILURE = settingsAction('DELETE_FORM_TYPE_FAILURE')

export const defaultState = {} as SettingsState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case DELETE_FORM_TYPE:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingForm: true,
        },
      }

    case DELETE_FORM_TYPE_SUCCESS: {
      const key = getFormTypeFromFormScreen(action.formType)
      return {
        ...state,
        [key]: {
          ...state[key],
          [action.id]: {
            ...state[key][action.id],
            isDeleted: true,
          },
        },
        flags: {
          ...state.flags,
          deletingForm: false,
        },
      }
    }

    case DELETE_FORM_TYPE_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingForm: false,
        },
      }

    default:
      return state
  }
}

export const deleteFormType = (id: string, formType: FormScreen): any => async (
  dispatch: Dispatch,
): Promise<any> => {
  dispatch({ type: DELETE_FORM_TYPE, id })

  return authDelete(Settings.DeleteFormType(id))
    .then((response) => {
      message.success('Form Types Deleted')
      dispatch(deleteFormTypeSuccess(response.data, formType))

      return response
    })
    .catch((error) => {
      message.error('Failed to delete Form Type Settings')
      captureMessage(`deleteFormType Failed. ${error}`, Severity.Error)
      dispatch(deleteFormTypeFailure(error, formType))

      return error
    })
}

export const deleteFormTypeSuccess = (id: string, formType: FormScreen) => ({
  type: DELETE_FORM_TYPE_SUCCESS,
  id,
  formType,
})

export const deleteFormTypeFailure = (error: any, formType: FormScreen) => ({
  type: DELETE_FORM_TYPE_FAILURE,
  error,
  formType,
})
