import { FormGeneratorComponentType } from 'interfaces'
import { FormItemComponent } from './interfaces'

export const getComponentType = (type: FormGeneratorComponentType) => {
  switch (type) {
    case 'card':
    case 'group':
    case 'columns':
    case 'segment':
    case 'printColumns':
      return {
        isContainer: true,
        isComponent: false,
        isSubFormComponent: true,
      }

    case 'yesnoform':
    case 'multiForm':
    case 'checkboxForm':
    case 'checkboxGroupForm':
      return {
        isContainer: false,
        isComponent: true,
        isSubFormComponent: true,
      }

    default:
      return {
        isContainer: false,
        isComponent: true,
        isSubFormComponent: false,
      }
  }
}

export const mapToTree = (component: FormItemComponent) => {
  const title: string =
    component.data?.find((d) => d.name === 'label' || d.name === 'title')
      ?.value || component.title
  const label = component.deprecated
    ? `DEPRECATED: ${component.label}`
    : component.label
  const overrideTitle = getOverrideTitle(component)
  const titleLabel = getTreeTitle(title || overrideTitle)

  if (
    component.name === 'firstName-ct-comp' ||
    component.name === 'middleName-ct-comp' ||
    component.name === 'lastName-ct-comp' ||
    component.name === 'phoneNumbers-ct-comp' ||
    component.name === 'email-ct-comp' ||
    component.name === 'appt-number-ct-comp'
  ) {
    let type = 'input'

    switch (component.name) {
      case 'phoneNumbers-ct-comp':
        type = 'phoneNumber'
        break
      case 'email-ct-comp':
        type = 'email'
        break
      case 'appt-number-ct-comp':
        type = 'inputNumber'
        break
      default:
        break
    }
    return {
      id: component.id,
      title: title,
      titleLabel: title,
      subtitle: '',
      name: component.name,
      additionalProps: {
        type: type,
        data: [
          {
            label: 'Label',
            name: 'label',
            type: 'text',
            value: title,
          },
          {
            label: 'Placeholder',
            name: 'placeholder',
            type: 'text',
            value: title,
          },
          {
            label: 'Required',
            name: 'required',
            type: 'boolean',
            value: true,
          },
        ],
      },
      ...getComponentType(component.type),
    }
  }

  return {
    id: component.id,
    title: titleLabel || label,
    titleLabel: titleLabel,
    subtitle: !title ? '' : label,
    additionalProps: { ...component },
    ...getComponentType(component.type),
  }
}

export const getTreeTitle = (title: string) => {
  if (title?.length > 25) {
    return title.substring(0, 25) + '...'
  }

  return title
}

const getOverrideTitle = (component: FormItemComponent) => {
  switch (component.type) {
    case 'finePrint':
      return component.data.find((d) => d.name === 'text')?.value

    default:
      return null
  }
}
