import { ComponentType, DataType, FormItemComponent } from './interfaces'

export const ContainerComponents: Array<FormItemComponent> = [
  {
    type: ComponentType.Card,
    name: 'card',
    label: 'Card',
    description:
      'Card\'s group similar data together, providing a clean seperation between differing data. Use Cards to cleanup the look of your forms.',
    data: [
      {
        name: 'title',
        label: 'Title',
        type: DataType.Text,
        optional: true,
        help: 'The Header Title for the Card',
      },
      {
        name: 'collapsible',
        label: 'Collapsible',
        type: DataType.Boolean,
        optional: true,
        help: 'If enabled, allows you to open and close the card.',
        defaultValue: true,
      },
      {
        name: 'hideTitle',
        label: 'Hide Title',
        type: DataType.Boolean,
        optional: true,
        help: 'Hide the Title. Useful if you don\'t want a title for your card. ',
        defaultValue: false,
      },
    ],
  },
  {
    type: ComponentType.Group,
    name: 'group',
    label: 'Group',
    description:
      'Groups organize small blocks of data with a common theam under a Header.',
    data: [
      {
        name: 'label',
        label: 'Header',
        type: DataType.Text,
        optional: false,
        help: 'The Header for the Group',
      },
      {
        name: 'showBreak',
        label: 'Show Break',
        type: DataType.Boolean,
        optional: true,
        defaultValue: false,
        help: 'Whether to show the break in a group or not',
      },
      {
        name: 'columnOnPrint',
        label: 'Split the Groups Items into Columns when Printing the form',
        type: DataType.Boolean,
        optional: true,
        defaultValue: true,
        help: 'Turns the page into a two column view for printing, helps save on the number of pages',
      },
    ],
  },
  {
    type: ComponentType.Columns,
    name: 'columns',
    label: 'Columns',
    description:
      'Coloums split up your form into two columns. They are recombined on smaller screens',
    data: [],
  },
  {
    type: ComponentType.PrintColumns,
    name: 'printColumns',
    label: 'Printing View Columns',
    description:
      'Form will appear as columns when printing a form, otherwise will be invisible',
    data: [],
  },
  {
    type: ComponentType.Segment,
    name: 'segment',
    label: 'Segment',
    description: 'A small carved out section with a Form',
    data: [],
  },
  {
    type: ComponentType.MultiForm,
    name: 'multiForm',
    label: 'Multi-Form',
    description:
      'A Component that lets you add or remove duplicate form entries. Any Form you nest under this component can have multiple entries.',
    data: [],
  },
  {
    type: ComponentType.Fragment,
    name: 'fragment',
    label: 'Fragment',
    description:
      'A deprecated container that has no style and only holds items. No longer used.',
    hidden: true,
    data: [],
  },
]

export const CTInputComponents: Array<FormItemComponent> = [
  {
    type: ComponentType.Input,
    name: 'firstName-ct-comp',
    label: 'First Name',
    description: 'First Name used by Client Trackyr to organize Clients',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Input Label',
        defaultValue: 'First Name',
      },
    ],
  },
  {
    type: ComponentType.Input,
    name: 'middleName-ct-comp',
    label: 'Middle / Other Names',
    description:
      'Middle or Other Names used by Client Trackyr to organize Clients',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Input Label',
        defaultValue: 'Middle / Other Names',
      },
    ],
  },
  {
    type: ComponentType.Input,
    name: 'lastName-ct-comp',
    label: 'Last Name',
    description: 'Last Name used by Client Trackyr to organize Clients',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Input Label',
        defaultValue: 'Last Name',
      },
    ],
  },
  {
    type: ComponentType.PhoneNumber,
    name: 'phoneNumbers-ct-comp',
    label: 'Phone Numbers',
    description: 'Phone Numbers used by Client Trackyr to organize Clients',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Input Label',
        defaultValue: 'Phone Number',
      },
    ],
  },
  {
    type: ComponentType.Email,
    name: 'email-ct-comp',
    label: 'Email Address',
    description: 'Email Address used by Client Trackyr to organize Clients',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Input Label',
        defaultValue: 'Email Address',
      },
    ],
  },
]

export const AppointmentComponents: Array<FormItemComponent> = [
  {
    type: ComponentType.InputNumber,
    name: 'appt-number-ct-comp',
    label: 'Appointment/Session Number',
    description:
      'Appointment Numbers can be set manually or be auto-incremented',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Input Label',
        defaultValue: 'Session Number',
      },
    ],
  },
]

export const InputComponents: Array<FormItemComponent> = [
  {
    type: ComponentType.Input,
    name: 'input',
    label: 'Input',
    description: 'A one line text input box',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Input Label',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no text is entered. They can provide further information or data to type in.',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'Is this text required?',
      },
    ],
  },
  {
    type: ComponentType.InputNumber,
    name: 'inputNumber',
    label: 'Input Number',
    description: 'A one line text input box that only allows numbers',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Input Label',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no text is entered. They can provide further information or data to type in.',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'Is this text required?',
      },
    ],
  },
  {
    type: ComponentType.TextInput,
    name: 'inputArea',
    label: 'Input Area',
    description: 'An expandable one line text input box',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Input Label',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no text is entered. They can provide further information or data to type in.',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'Is this text required?',
      },
    ],
  },
  {
    type: ComponentType.TextArea,
    name: 'textArea',
    label: 'Text Box',
    description: 'A larger box to type text in',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no text is entered. They can provide further information or data to type in.',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'Is this text required?',
      },
    ],
  },
  {
    type: ComponentType.Email,
    name: 'email',
    label: 'Email Address',
    description: 'An email address text box',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no text is entered. They can provide further information or data to type in.',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'Is this text required?',
      },
      {
        name: 'icon',
        label: '',
        type: DataType.Icon,
        optional: true,
        defaultValue: 'mail',
        hidden: true,
      },
    ],
  },
  {
    type: ComponentType.PhoneNumber,
    name: 'phoneNumber',
    label: 'Phone Number',
    description: 'Phone Number lets you add multiple phone numbers into a form',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'Is this text required?',
      },
    ],
  },
  {
    type: ComponentType.Date,
    name: 'date',
    label: 'Date',
    description: 'Date allows you to pick a date',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
      {
        name: 'format',
        label: 'Date/Time Format',
        type: DataType.Text,
        optional: true,
        help: 'The Date and or Time Format. Example formats are: MM D YYYY, MMMM D YYYY.\nA Time format is h:mm a, hh:mm.\nYou can combine Date and Time Formats like: MM D YYYY hh:mm a',
        defaultValue: 'MMMM D YYYY',
      },
      {
        name: 'showTime',
        label: 'Pick Time',
        type: DataType.Boolean,
        optional: true,
        help: 'Toggle if you can pick the time as well as the date',
        defaultValue: false,
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'Is this text required?',
      },
      {
        name: 'startMode',
        label: 'Start Mode',
        type: DataType.Radio,
        optional: true,
        radioOptions: ['Year', 'Month', 'Day'],
        help: 'Start in Year, Month, Day when picking a date',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no text is entered. They can provide further information or data to type in.',
      },
    ],
  },
  {
    type: ComponentType.Checkbox,
    name: 'checkbox',
    label: 'Checkbox',
    description: 'Simple Checkbox with a label',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
      {
        name: 'toggle',
        label: 'As Toggle',
        type: DataType.Boolean,
        optional: true,
        help: 'Changes the appearance between a checkbox and a toggle',
      },
    ],
  },
  {
    type: ComponentType.CheckboxInput,
    name: 'checkboxInput',
    label: 'Checkbox with Input',
    description:
      'Simple Checkbox with a Label and optional Text Input if checked',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear on Inputs if no text is entered. They can provide further information or data to type in.',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'If checked, is the input required?',
      },
      {
        name: 'toggle',
        label: 'As Toggle',
        type: DataType.Boolean,
        optional: true,
        help: 'Changes the appearance between a checkbox and a toggle',
      },
    ],
  },
  {
    type: ComponentType.CheckboxForm,
    name: 'checkboxForm',
    label: 'Checkbox with Form',
    description:
      'Simple Checkbox with a Form you can build onto. Use if you want to build a more complicated form following a successful checkbox',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
      {
        name: 'toggle',
        label: 'As Toggle',
        type: DataType.Boolean,
        optional: true,
        help: 'Changes the appearance between a checkbox and a toggle',
      },
    ],
  },
  {
    type: ComponentType.CheckboxGroup,
    name: 'checkboxGroup',
    label: 'Checkbox Group',
    description: 'Multiple Checkboxes with Options',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
      {
        name: 'options',
        label: 'Options',
        type: DataType.MultiText,
        optional: false,
        help: 'Options for the Checkbox Group',
      },
      {
        name: 'toggle',
        label: 'As Toggle',
        type: DataType.Boolean,
        optional: true,
        help: 'Changes the appearance between a checkbox and a toggle',
      },
    ],
  },
  {
    type: ComponentType.CheckboxGroupInput,
    name: 'checkboxGroupInput',
    label: 'Checkbox Group with Input',
    description:
      'Multiple Checkboxes with Options with a Text Input if any are checked',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
      {
        name: 'options',
        label: 'Options',
        type: DataType.MultiText,
        optional: false,
        help: 'Options for the Checkbox Group',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no text is entered. They can provide further information or data to type in.',
      },
      {
        name: 'toggle',
        label: 'As Toggle',
        type: DataType.Boolean,
        optional: true,
        help: 'Changes the appearance between a checkbox and a toggle',
      },
      {
        name: 'required',
        label: 'Required',
        type: DataType.Boolean,
        optional: true,
        help: 'If checked, is the input required?',
      },
    ],
  },
  {
    type: ComponentType.CheckboxGroupForm,
    name: 'checkboxGroupForm',
    label: 'Checkbox Group Form',
    description: 'Multiple Checkboxes with Options and a Form',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
      {
        name: 'options',
        label: 'Options',
        type: DataType.MultiText,
        optional: false,
        help: 'Options for the Checkbox Group',
      },
      {
        name: 'toggle',
        label: 'As Toggle',
        type: DataType.Boolean,
        optional: true,
        help: 'Changes the appearance between a checkbox and a toggle',
      },
    ],
  },
  {
    type: ComponentType.Radio,
    name: 'radio',
    label: 'Radio',
    description: 'Radio gives multiple options with only 1 option selectable',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
      {
        name: 'options',
        label: 'Options',
        type: DataType.MultiText,
        optional: false,
        help: 'Radio Select Options',
      },
    ],
  },
  {
    type: ComponentType.Yesno,
    name: 'yesno',
    label: 'Yes/No',
    description: 'A Yes/No Button',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
    ],
  },
  {
    type: ComponentType.Yesnoform,
    name: 'yesnoform',
    label: 'Yes/No with Form',
    description: 'A Yes/No Button with a Form based on a trigger',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
      {
        name: 'trigger',
        label: 'Form Trigger',
        type: DataType.Dropdown,
        optional: false,
        help: 'Trigger option determins if the form will appear.',
        dropdownOptions: ['Yes', 'No'],
      },
    ],
  },
  {
    type: ComponentType.Dropdown,
    name: 'dropdown',
    label: 'Dropdown Select',
    description: 'A dropdown that lets a user select one of many options',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: true,
        help: 'Label',
      },
      {
        name: 'placeholder',
        label: 'Placeholder',
        type: DataType.Text,
        optional: true,
        help: 'Placeholders appear if no item is picked. They can provide further information or data to type in.',
      },
      {
        name: 'options',
        label: 'Options',
        type: DataType.MultiText,
        optional: false,
        help: 'Options for the Dropdown',
      },
      {
        name: 'multiple',
        label: 'Select Multiple',
        type: DataType.Boolean,
        optional: true,
        help: 'Lets multiple dropdown values be selected',
      },
      {
        name: 'clearable',
        label: 'Clearable',
        type: DataType.Boolean,
        optional: true,
        help: 'Lets the dropdown value be cleared',
      },
    ],
  },
]

export const TextComponents: Array<FormItemComponent> = [
  {
    type: ComponentType.Label,
    name: 'label',
    label: 'Label',
    description: 'A Simple Lable',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
    ],
  },
  {
    type: ComponentType.Text,
    name: 'text',
    label: 'Text',
    description: 'A Simple Text',
    data: [
      {
        name: 'label',
        label: 'Text',
        type: DataType.TextArea,
        optional: false,
        help: 'Text you want to display',
      },
    ],
  },
  {
    type: ComponentType.FormHeader,
    name: 'formHeader',
    label: 'Form Header',
    description: 'Header Component you can add to a Form',
    hidden: true,
    data: [
      {
        // TODO - Make Rows
        name: 'left',
        label: 'Left Column Text',
        type: DataType.Text,
        optional: true,
        help: 'Text that appears in the left column',
      },
      {
        name: 'centre',
        label: 'Centre Column Text',
        type: DataType.Text,
        optional: true,
        help: 'Text that appears in the centre column',
      },
      {
        name: 'right',
        label: 'Right Column Text',
        type: DataType.Text,
        optional: true,
        help: 'Text that appears in the right column',
      },
      {
        name: 'date',
        label: 'Show Date',
        type: DataType.Dropdown,
        optional: true,
        help: 'Show the date in a column, will overwrite that column',
        dropdownOptions: ['None', 'Left', 'Centre', 'Right'],
        defaultValue: 'None',
      },
      {
        name: 'format',
        label: 'Date/Time Format',
        type: DataType.Text,
        optional: true,
        help: 'The Date and or Time Format',
        defaultValue: 'D MM YYYY',
      },
    ],
  },
  {
    type: ComponentType.FinePrint,
    name: 'finePrint',
    label: 'Fine Print',
    description:
      'Small Text that enlargens if you hover the mouse over the text',
    data: [
      {
        name: 'text',
        label: 'Fine Print Text',
        type: DataType.TextArea,
        optional: false,
        help: 'Fine Print Text',
      },
    ],
  },
  {
    type: ComponentType.Divider,
    name: 'divider',
    label: 'Divider',
    description:
      'A Divider provides a break or some white space in a form to help break up chucks of information.',
    data: [
      {
        name: 'hidden',
        label: 'Empty Divider',
        type: DataType.Boolean,
        optional: true,
        help: 'An Empty Divider is just white space, a non empty divider shows a line.',
        defaultValue: true,
      },
    ],
  },
]

export const MultiComponents = [
  {
    type: ComponentType.Address,
    name: 'address',
    label: 'Address',
    description: 'Component for an address',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label',
      },
    ],
  },
  {
    type: ComponentType.BodyDiagram,
    name: 'bodyDiagram',
    label: 'Body Diagram',
    description:
      'A Body Diagram for Massage Therapy Work, the background image can be uploaded via settings.',
    hidden: false,
    data: [],
  },
  {
    type: ComponentType.Signature,
    name: 'signature',
    label: 'Digital Signature',
    description:
      'Signature Component that Let&apos;s a user sign using their mousepad or finger on a touch screen. Provides a Typed out Name Area and a Date that is automatically populated to the date they signed.',
    data: [
      {
        name: 'label',
        label: 'Label',
        type: DataType.Text,
        optional: false,
        help: 'Label for the Signature',
      },
    ],
  },
  {
    type: ComponentType.EmergencyContact,
    name: 'emergencyContact',
    label: 'Emergency Contact Subform',
    description:
      'The Emergency Contact Subform contains a form for specifying an emergency contact, the ability to add multiple emergency contacts.',
    data: [],
  },
]

export const AllDefinitions = [
  ...ContainerComponents,
  ...InputComponents,
  ...TextComponents,
  ...MultiComponents,
]
