import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { post } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'
import { IClient, IPublicIntake } from 'interfaces'
import { IResponse } from 'interfaces/fetch'

const completePublicIntake = async (
  id: string,
  clientID: string,
  clientData: IClient,
): Promise<any> => {
  return post(PublicIntake.Complete, {
    id,
    client: {
      id: clientID,
      ...clientData,
    },
    clientID,
  })
    .then((response: IResponse<IPublicIntake>) => response)
    .catch((error) => {
      captureMessage(
        `completePublicIntake Post Failed: ${error}`,
        Severity.Error,
      )

      throw error
    })
}

export { completePublicIntake }
