import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { authDelete } from 'lib/http-request'
import { Features } from 'constants/endpoints'
import { FeaturesState, IAction } from 'interfaces'

const action = (action: string) => `client-trackyr/features/${action}`
const REMOVE_BETA_USER = action('REMOVE_BETA_USER')
const REMOVE_BETA_USER_SUCCESS = action('REMOVE_BETA_USER_SUCCESS')
const REMOVE_BETA_USER_FAILURE = action('REMOVE_BETA_USER_FAILURE')

export const defaultState = {
  removingBetaUser: false,
  removingBetaUserError: { error: false },
} as FeaturesState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case REMOVE_BETA_USER:
      return {
        ...state,
        removingBetaUser: true,
      }

    case REMOVE_BETA_USER_SUCCESS: {
      return {
        ...state,
        removingBetaUser: false,
        betaUsers: JSON.parse(action.response.value),
      }
    }

    case REMOVE_BETA_USER_FAILURE:
      return {
        ...state,
        removingBetaUser: false,
        removingBetaUserError: {
          error: true,
          message: 'Failed to set feature',
        },
      }

    default:
      return state
  }
}

const removeBetaUser = (email) => async (dispatch) => {
  dispatch({ type: REMOVE_BETA_USER, email })

  return authDelete(Features.RemoveBetaUser(email))
    .then((response) => {
      dispatch(fetchSuccess(response.data))
      return response
    })
    .catch((error) => {
      dispatch(fetchFailure(error))
      captureMessage(`removeBetaUser Delete Failed: ${error}`, Severity.Error)

      throw error
    })
}

const fetchSuccess = (response) => ({
  type: REMOVE_BETA_USER_SUCCESS,
  response,
})

const fetchFailure = (error) => ({
  type: REMOVE_BETA_USER_FAILURE,
  error,
})

export { removeBetaUser }
