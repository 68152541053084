import { Image as Img } from 'semantic-ui-react'

import styled from 'styled-components'

interface ImageProps {
  maxHeight?: number | string
  src: any
}
export const Image = styled.img<ImageProps>`
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: ${(props) => props.maxHeight ?? 'auto'};
  object-fit: contain;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export { Img as SImage } // TODO - TEMP clean up images
