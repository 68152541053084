import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Header, Button } from 'components/ui'
import { IState, Dispatch, FormScreen } from 'interfaces'

import { restoreFormType } from '../redux/restore-form-type'
import { deleteFormType } from '../redux/delete-form-types'
import { saveFormType } from '../redux/save-form-type'
import { IResponse } from 'interfaces/fetch'
import { DeleteModal, ConfirmCancelModal } from 'components/modals'
import { FormTypeNotes } from './form-type-notes'
import { NewFormTypeDetails } from './new-form-type-details'
import { Form_T } from './new-interfaces'

interface Props {
  open: boolean
  onClose: Function
  formType: Form_T
  disabled?: boolean
  formTypeName: string
}

export const FormTypeModal = (props: Props) => {
  const dispatch: Dispatch = useDispatch()

  const [data, setData] = useState({} as Form_T)
  const [isDirty, setIsDirty] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const loading = useSelector(
    (state: IState) => state.settings.flags.savingFormType,
  )
  const deleting = useSelector(
    (state: IState) => state.settings.flags.deletingForm,
  )
  const restoring = useSelector(
    (state: IState) => state.settings.flags.restoringForm,
  )

  if (!props.open) {
    return null
  }

  const onClose = () => {
    setIsDirty(false)
    setData({} as Form_T)
    setDeleteModalOpen(false)
    setCancelModalOpen(false)
    props.onClose()
  }

  const onSubmit = () => {
    dispatch(saveFormType(data as Form_T))
      .then((response: IResponse<any>) => {
        if (response?.status === 200) {
          onClose()
        }
      })
      .catch(() => {})
  }

  const onRestore = () => {
    dispatch(restoreFormType(props.formType.id))
      .then((response: IResponse<any>) => {
        if (response?.status === 200) {
          onClose()
        }
      })
      .catch(() => {})
  }

  const onDelete = () => {
    setDeleteModalOpen(false)
    dispatch(deleteFormType(props.formType.id, props.formType.screen))
      .then((response: IResponse<any>) => {
        if (response?.status === 200) {
          onClose()
        }
      })
      .catch(() => {})
  }

  const onDataChange = (d: Form_T) => {
    setIsDirty(true)
    setData({
      ...data,
      ...d,
    })
  }

  const content = () => {
    switch (props.formType.screen) {
      case FormScreen.Client:
        return (
          <NewFormTypeDetails
            disabled={props.disabled}
            formData={props.formType}
            formTypeName={props.formTypeName}
            setFormData={onDataChange}
          />
        )
      case FormScreen.Soap:
        return (
          <FormTypeNotes
            disabled={props.disabled}
            formData={props.formType as any}
            formTypeName={props.formTypeName}
            setFormData={onDataChange}
          />
        )
      default:
        return null
    }
  }

  const onCancel = () => {
    if (isDirty) {
      setCancelModalOpen(true)
    } else {
      onClose()
    }
  }

  return (
    <>
      <DeleteModal
        content="Are you sure you want to delete this form type?"
        message="Delete Form Type"
        modalOpen={deleteModalOpen}
        onAccept={onDelete}
        onCancel={() => setDeleteModalOpen(false)}
      />
      <ConfirmCancelModal
        content="Cancel changes made on this form?"
        message="You have unsaved changes"
        modalOpen={cancelModalOpen}
        onAccept={onClose}
        onCancel={() => setCancelModalOpen(false)}
      />
      <Modal
        closeOnDimmerClick={!isDirty}
        onClose={onClose}
        open={props.open}
        size="large"
      >
        <Header content={`${props.formTypeName} Type`} icon="file alternate" />

        <Modal.Content>{content()}</Modal.Content>

        <Modal.Actions>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {props.formType.id && (
                <Button
                  loading={deleting || restoring}
                  negative={!props.formType.isDeleted}
                  onClick={() =>
                    props.formType.isDeleted
                      ? onRestore()
                      : setDeleteModalOpen(true)
                  }
                >
                  {props.formType.isDeleted ? 'Restore' : 'Delete'}
                </Button>
              )}
            </div>

            {!props.formType.isDeleted && (
              <div>
                <Button
                  onClick={onCancel}
                  secondary
                  style={{ marginRight: '0.5em' }}
                >
                  {isDirty ? 'Cancel' : 'Close'}
                </Button>
                <Button
                  disabled={!isDirty}
                  loading={loading}
                  onClick={onSubmit}
                  primary
                >
                  Save
                </Button>
              </div>
            )}

            {props.formType.isDeleted && (
              <Button
                onClick={onClose}
                secondary
                style={{ marginRight: '0.5em' }}
              >
                Close
              </Button>
            )}
          </div>
        </Modal.Actions>
      </Modal>
    </>
  )
}
