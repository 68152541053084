enum TableName {
  All = 'All',
  Clients = 'Clients',
  Notes = 'Notes',
  Appointments = 'Appointments',
  Treatments = 'Treatments',
  Files = 'Files',
  PublicIntake = 'PublicIntake',
  Email = 'Email',
  SMS = 'SMS',
}

enum AuditType {
  Unknown = 0,
  Add = 1,
  Update = 2,
  Delete = 3,
  Accessed = 4,
  Completed = 5,
  // For automatic ClientTrackyr fetching
  Fetched = 6,
  Archived = 7,
  Sent = 8,
  ReminderSent = 9,
}

enum AuditReason {
  None = 0,
  Email = 1,
  PublicIntake = 2,
  Appointment = 3,
  AppointmentEmail = 4,
  AccessNotes = 5,
  // UserAccessed = 6,
}

interface Audit {
  dateTime: Date
  entityID: string
  prevValue?: Audit | any
  reason?: AuditReason
  subEntityID?: string
  tableName: TableName
  type: AuditType
  userID: string
  value?: string
}

export type { Audit }
export { AuditType, TableName, AuditReason }
