import React from 'react'

import ColouredRectangle from './coloured-rectangle'
import ColouredCircle from './coloured-circle'
import ColouredLines from './coloured-lines'

// import { KonvaEventObject } from 'konva/types/Node'

interface Props {
	isDrawingMode: boolean
	onClick: (evt: any) => void
	onDragEnd: (evt: any) => void
	onTap: (evt: any) => void
	readonly?: boolean
	scale: number
	shape: any
	size: number
}

const ColouredShape = ({
												 shape,
												 isDrawingMode,
												 onClick,
												 onTap,
												 scale,
												 readonly,
												 onDragEnd,
												 size,
											 }: Props) => {
	const props = {
		colour: shape.colour,
		height: shape.height,
		isDrawingMode,
		onClick,
		onDragEnd,
		onTap,
		order: shape.order,
		onMouseLeave: () => {
			if (!readonly) {
				document.body.style.cursor = 'default'
			}
		},
		onMouseOver: () => {
			if (!readonly) {
				document.body.style.cursor = 'move'
			}
		},
		width: size,
		x: shape.relX / scale,
		y: shape.relY / scale,
		readonly,
	}

	if (shape.shapeType === 'Square') {
		return <ColouredRectangle {...props} id={shape.id} />
	}
	if (shape.shapeType === 'Circle') {
		return <ColouredCircle {...props} id={shape.id} />
	}
	if (shape.shapeType === 'Lines') {
		return (
			<ColouredLines {...props} id={shape.id} lines={shape.lines} size={size} />
		)
	}

	return null
}

export default ColouredShape
