import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import moment from 'moment'
import { HeaderButtons } from 'components/calendar'
import { setNavBarText } from 'components/nav'
import { Container } from 'components/ui'
import {
	appointments$,
	getAllAppointmentsForCalendar,
	fetchAppointments as fa,
} from 'containers/appointments'
import { Calendar as CalendarUI } from 'components/calendar'
import { AppointmentModal } from 'containers/calendar'
import { getCalendarDefaultAppointmentDuration } from 'containers/settings'
import {
	FullCalendarEvent,
	CalendarRef,
	IAppointment,
	IState, Dispatch,
} from 'interfaces'
import { ErrorBoundary } from 'components/error'
import {
	getCalendarDefaultView,
	getSetting,
} from 'containers/settings/selectors'
import { CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW } from 'constants/settings'

// import { onDateChange } from './redux/ducks'

interface Params {
	clientID?: string
	view?: string
	date?: moment.Moment | Date
}

// const TABS = {
//   month: 0,
//   week: 1,
//   day: 2,
// }

const Calendar = () => {
	const dispatch: Dispatch = useDispatch()
	const calendarRef: CalendarRef = useRef(null)
	const [activeTab, setActiveTab] = useState(0)
	const [showAppointmentModal, setShowAppointmentModal] = useState(false)
	const [appointmentEvent, setAppointmentEvent] = useState(null)
	const [editingAppointment, setEditingAppointment] = useState(false)
	const [appointmentClientID, setAppointmentClientID] = useState('')
	const [onEventChangeChanged, setOnEventChangeChanged] = useState(false)
	const [oldEvent, setOldEvent] = useState(null as IAppointment)

	const apptByID = useSelector(appointments$)
	const events = useSelector(getAllAppointmentsForCalendar)
	const calendarDefaultAppointmentLengthSettings = useSelector(
		getCalendarDefaultAppointmentDuration,
	)
	const calendarDefaultView = useSelector(getCalendarDefaultView)
	const defaultNewAppointmentView = useSelector((state: IState) =>
		getSetting(state, CALENDAR_DEFAULT_CREATE_APPOINTMENT_VIEW),
	)

	const params: Params = useParams()
	// const view = useSelector((state: IState) => state.calendar.view)
	// const dateView = useSelector((state: IState) => state.calendar.date)

	// const paramDate = props.match.params.date

	// useEffect(() => {
	//   paramDate && calendarRef.current.getApi().gotoDate(paramDate)
	//   // paramDate && dispatch(onDateChange(paramDate))
	// }, [paramDate])

	const fetchAppointments = useCallback(
		(queryParams) => {
			dispatch(fa(queryParams))
		},
		[dispatch],
	)

	useEffect(() => {
		dispatch(setNavBarText('Calendar'))
		calendarRef.current.getApi().changeView(calendarDefaultView)
	}, [dispatch])

	const clientID = params.clientID
	useEffect(() => {
		if (clientID) {
			setAppointmentEvent({
				start: moment().format(),
				end: moment().add(calendarDefaultAppointmentLengthSettings, 'minutes'),
			})
			setEditingAppointment(false)
			setShowAppointmentModal(true)
			setAppointmentClientID(clientID)
		}
	}, [clientID, calendarDefaultAppointmentLengthSettings])

	const onEvent = (event: FullCalendarEvent) => {
		setAppointmentEvent(event)
		setEditingAppointment(false)
		setShowAppointmentModal(true)
		setAppointmentClientID('')
	}

	const onEventCancel = () => {
		setOnEventChangeChanged(false)
		setShowAppointmentModal(false)
		setEditingAppointment(false)
		setAppointmentEvent(null)
		setAppointmentClientID('')

		const resetAppt = apptByID[oldEvent?.id]

		if (resetAppt) {
			resetAppt.start = oldEvent.start
			resetAppt.end = oldEvent.end
			const currentDate = moment(resetAppt.start)
			fetchAppointments({
				year: currentDate.get('year'),
				month: currentDate.get('month') + 1,
			})
		}
	}

	const onEventClick = (info: any) => {
		const appointmentID = info.event._def.extendedProps.appointmentID
		const clientID = info.event._def.extendedProps.clientID
		const appointment = apptByID[appointmentID]

		setAppointmentClientID(clientID)
		setAppointmentEvent(appointment)
		setEditingAppointment(true)
		setShowAppointmentModal(true)
	}

	const onEventChange = (info: FullCalendarEvent) => {
		const appointmentID = info.event._def.extendedProps.appointmentID
		const clientID = info.event._def.extendedProps.clientID
		const appointment = apptByID[appointmentID]

		setOldEvent({
			id: appointment.id,
			start: appointment.start,
			end: appointment.end,
		} as IAppointment)

		appointment.start = info.event.start
		appointment.end = info.event.end

		setOnEventChangeChanged(true)
		setAppointmentClientID(clientID)
		setAppointmentEvent(appointment)
		setEditingAppointment(true)
		setShowAppointmentModal(true)
		// setSelectedAppointmentByID(appointment.id)

		// TODO - handle OLD EVENT, on cancel resets!
	}

	return (
		<Container padded>
			<ErrorBoundary>
				<AppointmentModal
					// clientID={appointmentEvent?.clientID}
					clientID={appointmentClientID}
					event={appointmentEvent}
					gotoNotes={editingAppointment}
					isDirty={onEventChangeChanged}
					isEditMode={editingAppointment}
					modalOpen={showAppointmentModal}
					onCancel={onEventCancel}
				/>
				<HeaderButtons
					activeTab={activeTab}
					calendarRef={calendarRef}
					fetchAppointments={fetchAppointments}
					setActiveTab={setActiveTab}
				/>

				<CalendarUI
					key='CalendarRoute'
					calendarRef={calendarRef}
					dateClick={(e: FullCalendarEvent) => {
						if (calendarRef.current.getApi().view.type === 'dayGridMonth') {
							calendarRef.current.getApi().gotoDate(e.date)
							calendarRef.current.getApi().changeView(defaultNewAppointmentView)
							setActiveTab(2)
						}
					}}
					editable
					eventChange={(event: FullCalendarEvent) => onEventChange(event)}
					eventClick={onEventClick}
					events={events}
					select={(e: FullCalendarEvent) => {
						if (
							e.allDay &&
							moment(e.start).get('day') === moment(e.end).get('day') - 1 &&
							activeTab === 0
						) {
							setActiveTab(2)
							return
						}

						const defaultEnd = moment(e.start).add(
							calendarDefaultAppointmentLengthSettings,
							'minutes',
						)

						if (!moment(e.end).isAfter(defaultEnd)) {
							e.end = defaultEnd.format()
						}

						onEvent(e)
					}}
					selectable
					selectMirror
					// eventMouseEnter={(info) => console.log('eventMouseEnter', info)}
					// eventMouseLeave={(info) => console.log('eventMouseLeave', info)}
				/>
			</ErrorBoundary>
		</Container>
	)
}

export default Calendar
