import { IAction, TaxState } from 'interfaces'

import { reducer as postTax } from './post-tax'
import { reducer as fetchTaxes } from './fetch-taxes'
import { reducer as deleteTax } from './delete-tax'

export const defaultState: TaxState = {
  allIDs: [],
  taxes: {},
  totalTaxes: 0,
  flags: {
    fetching: false,
    posting: false,
    deleting: false,
  },
}

const reducers = [postTax, fetchTaxes, deleteTax]

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    default:
      break
  }

  return reducers.reduce((s: any, r) => r(s, action), state)
}
