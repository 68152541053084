import {
  reducer as fetchAccountData,
  defaultState as fetchAccountDefaultState,
} from './fetch-account-data'
import {
  reducer as fetchAccountForms,
  defaultState as fetchAccountFormsDefaultState,
} from './fetch-account-forms'
import { reducer as saveAccount } from './save-account'
import { reducer as officeReducer } from '../office/redux/ducks'

import {
  reducer as fetchQuota,
  defaultState as fetchQuotaDefaultState,
} from './fetch-quota'
import { AccountState, IAction } from 'interfaces'

const reducers = [
  fetchAccountData,
  fetchAccountForms,
  saveAccount,
  officeReducer,
  fetchQuota,
]

const accountAction = (action: string) => `client-trackyr/account/${action}`
const ON_ACCOUNT_FORM_CHANGE = accountAction('ON_ACCOUNT_FORM_CHANGE')

export const defaultState: AccountState = {
  ...fetchAccountDefaultState,
  ...fetchQuotaDefaultState,
  ...fetchAccountFormsDefaultState,
  changesMade: false,
  error: { hasError: false, msg: '' },
  fetchingAccountData: false,
  fetchingOffice: false,
  forms: [],
  loading: false,
  offices: {},
  officesByID: [],
}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case ON_ACCOUNT_FORM_CHANGE:
      return {
        ...state,
        // accountForm: {
        //   ...state.accountForm,
        //   [action.name]: action.value,
        // },
        changesMade: true,
      }

    default:
      break
  }

  return reducers.reduce((s, r) => r(s, action), state)
}

export const onChange = (name: string, value: string) => ({
  type: ON_ACCOUNT_FORM_CHANGE,
  name,
  value,
})
