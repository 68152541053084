import React from 'react'
import styled from 'styled-components'

import { MobileBreakPoint } from 'styles'
import { Input as I } from 'components/ui'
import { IEvent } from 'interfaces'

const ESCAPE_KEY = 27
const ENTER_KEY = 13

const Input = styled(I)`
  &&&&& {
    width: 100%;

    @media (max-width: ${MobileBreakPoint}) {
      font-size: 16px;
    }
  }
`

interface SearchBoxProps {
	autoFocus?: boolean
	loading?: boolean
	onBlur?: Function
	onChange: (e: IEvent) => void
	onClear: Function
	onEnter?: Function
	onFocus?: Function
	onSearch: Function
	placeholder?: string
	size?: string
	style?: object
	value?: string
}

class SearchBox extends React.Component<SearchBoxProps> {
	public static defaultProps: Partial<SearchBoxProps> = {
		placeholder: 'Search',
	}

	inputRef: any = React.createRef()

	onClear = () => {
		this.props.onClear()
		this.inputRef.focus()
	}

	onSearch = () => {
		this.props.onSearch()
		this.inputRef.focus()
	}

	render() {
		const icon = this.props.loading
			? {}
			: this.props.value
				? { name: 'close', onClick: this.props.onClear, color: 'grey' }
				: {
					name: 'search',
					onClick: this.props.onEnter,
					color: 'grey',
				}

		return (
			<Input
				ref={this.inputRef}
				autoFocus={this.props.autoFocus}
				icon={{ link: true, ...icon }}
				loading={this.props.loading}
				onBlur={this.props.onBlur}
				onChange={this.props.onChange}
				onFocus={this.props.onFocus}
				onKeyUp={(e: KeyboardEvent) => {
					if (e.keyCode === ESCAPE_KEY) {
						this.props.onClear && this.props.onClear()
					} else if (e.keyCode === ENTER_KEY) {
						this.props.onEnter && this.props.onEnter()
					}
				}}
				placeholder={this.props.placeholder}
				size={this.props.size || 'small'}
				style={this.props.style}
				type='search'
				value={this.props.value}
			/>
		)
	}
}

export default SearchBox
