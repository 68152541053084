import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
	getSeletectedTreatmentNumber$,
	getSeletectedTreatment$,
} from 'containers/treatment'
import { getSelectedClientID$ } from 'containers/clients'
import { useEventListener } from 'lib'
import { IState, ClientInfoURLParams, Dispatch } from 'interfaces'

import { onCollapseNotesAppointmentView } from './redux/ducks'
import { fetchSoapByAppointment, getCurrentNotesView } from './'
import Soap from './components/soap'
import { MobileBreakPointSmallNumber } from 'styles'
import {
	getSelectedAppointment$,
	getSelectedAppointmentID$,
	selectMostCurrentSoap,
} from 'containers/appointments'
import { Forms_T } from 'containers/settings/form-types/new-interfaces'
import { Tab } from 'components/ui'
import { SoapFiles } from './components/soap-files'
import { SoapReceipt } from './components/soap-receipt'
import { checkFeature } from 'containers/features'
import { FileUpload } from 'constants/features'

const SoapNotes = (props: ISoapNotes) => {
	const dispatch: Dispatch = useDispatch()
	const params: ClientInfoURLParams = useParams()

	const hasFileUploadFeature = useSelector((state: IState) =>
		checkFeature(state, FileUpload),
	)
	const clientID = useSelector(getSelectedClientID$)
	const selectedTreatmentNumber = useSelector(getSeletectedTreatmentNumber$)
	const selectedAppointment = useSelector(getSelectedAppointment$)
	const selectedAppointmentID = useSelector(getSelectedAppointmentID$)
	const notesView = useSelector(getCurrentNotesView)
	const readonly = useSelector(
		(state: IState) =>
			!state.clients.flags.soapEditing || getSeletectedTreatment$(state).closed,
	)

	useEffect(() => {
		selectMostCurrentSoap(clientID, selectedTreatmentNumber)

		if (!selectedAppointmentID || !selectedTreatmentNumber) {
			return
		}

		dispatch(
			fetchSoapByAppointment(
				clientID,
				selectedAppointmentID,
				selectedTreatmentNumber,
			),
		)
	}, [dispatch, clientID, selectedAppointmentID, selectedTreatmentNumber])

	const collapseShowOnResizeIfMobile = () => {
		if (window.innerWidth <= MobileBreakPointSmallNumber && !notesView) {
			dispatch(onCollapseNotesAppointmentView(clientID))
		}
	}

	useEventListener('resize', collapseShowOnResizeIfMobile)

	const onCancel = () => {
		dispatch(
			fetchSoapByAppointment(
				clientID,
				selectedAppointment.id,
				selectedTreatmentNumber,
			),
		)
	}

	const { forms } = props

	let panes = [
		{
			menuItem: { content: 'Notes', icon: 'edit' },
			render: () => {
				return (
					<Soap
						key='notes'
						appointmentsCollapsed={!notesView}
						clientID={clientID}
						forms={forms}
						onCancel={onCancel}
						onCollapseAppointments={() =>
							dispatch(onCollapseNotesAppointmentView(clientID))
						}
						params={params}
						readonly={readonly}
					/>
				)
			},
		},
		{
			menuItem: { content: 'Files', icon: 'folder' },
			render: () => {
				return (
					<SoapFiles
						key='note-files'
						appointmentID={selectedAppointmentID}
						clientID={clientID}
					/>
				)
			},
		},
		{
			menuItem: { content: 'Receipt', icon: 'print' },
			render: () => {
				return (
					<SoapReceipt appointment={selectedAppointment} clientID={clientID} />
				)
			},
		},
	]

	if (!hasFileUploadFeature) {
		panes = panes.filter((p) => p.menuItem.content !== 'Files')
	}

	return (
		<div style={{ width: '100%' }}>
			<Tab
				menu={{ secondary: true, pointing: true }}
				panes={panes}
				responsive={false}
			/>
		</div>
	)
}

interface ISoapNotes {
	forms: Forms_T[]
}

export default SoapNotes
