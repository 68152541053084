import { authDelete } from 'lib/http-request'
import { Appointments } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { message } from 'components/notifications'
import { invalidateAppointmentCache } from 'lib'
import { IAppointment } from 'interfaces'
import moment from 'moment'

const appointmentAction = (action) => `client-trackyr/appointments/${action}`
const DELETE_APPOINTMENT = appointmentAction('DELETE_APPOINTMENT')
const DELETE_APPOINTMENT_SUCCESS = appointmentAction(
  'DELETE_APPOINTMENT_SUCCESS',
)
const DELETE_APPOINTMENT_ERROR = appointmentAction('DELETE_APPOINTMENT_ERROR')

export const defaultState: IAppointmentState = {
  deletingAppointment: false,
}

interface IAppointmentState {
  deletingAppointment?: boolean
  allAppointmentsByID?: any //TEMP
  appointments?: any // TEMP
  clientAppointments?: any //TEMP
  appointmentsByMonth?: any
}

export const reducer = (state: IAppointmentState = defaultState, action) => {
  switch (action.type) {
    case DELETE_APPOINTMENT: {
      return {
        ...state,
        deletingAppointment: true,
      }
    }

    case DELETE_APPOINTMENT_SUCCESS: {
      const allAppointmentsByID = state.allAppointmentsByID.filter(
        (a) => a.id !== action.payload.appointment.id,
      )
      const appointments = state.appointments
      delete appointments[action.payload.appointment.id]
      const clientAppointments = state.clientAppointments[
        action.payload.clientID
      ].filter((a) => a.id !== action.payload.appointment.id)

      const appointmentStart = moment(action.payload.appointment.start)
      const appointmentsByMonthKey = `${appointmentStart.get('year')}${
        appointmentStart.get('month') + 1
      }`
      const appointmentsByMonth = state.appointmentsByMonth[
        appointmentsByMonthKey
      ].filter((f) => f.id !== action.payload.appointment.id)

      return {
        ...state,
        appointments: { ...appointments },
        allAppointmentsByID: [...allAppointmentsByID],
        clientAppointments: {
          ...state.clientAppointments,
          [action.payload.clientID]: clientAppointments,
        },
        appointmentsByMonth: {
          ...state.appointmentsByMonth,
          [appointmentsByMonthKey]: appointmentsByMonth,
        },
        deletingAppointment: false,
      }
    }

    case DELETE_APPOINTMENT_ERROR: {
      return {
        ...state,
        deletingAppointment: false,
      }
    }

    default:
      return state
  }
}

export const deleteAppointment = (
  appointment: IAppointment,
  clientID: string,
): any => async (dispatch): Promise<any> => {
  dispatch({ type: DELETE_APPOINTMENT, appointment, clientID })

  return authDelete(Appointments.Delete(appointment.id))
    .then((response) => {
      dispatch(deleteAppointmentSuccess(appointment, clientID))
      message.success('Appointment Deleted')
      invalidateAppointmentCache(
        moment(appointment.start).get('year'),
        moment(appointment.start).get('month') + 1,
      )

      return response
    })
    .catch((error) => {
      dispatch(deleteAppointmentError(error))
      message.error('Error deleting appointment')
      captureMessage(`deleteAppointment Failed. ${error}`, Severity.Error)

      throw error
    })
}

const deleteAppointmentSuccess = (appointment, clientID) => ({
  type: DELETE_APPOINTMENT_SUCCESS,
  payload: { appointment, clientID },
})

const deleteAppointmentError = (error) => ({
  type: DELETE_APPOINTMENT_ERROR,
  error,
})
