import styled from 'styled-components'

import { MobileBreakPointSmall } from 'styles'

export const MobileHiddenSmall = styled.div`
  @media (max-width: ${MobileBreakPointSmall}) {
    visibility: hidden;
    display: none;
    width: 0;
    margin: 0;
    height: 0;
    padding: 0;
  }
`

export const MobileVisibleSmall = styled.div`
  @media (min-width: ${MobileBreakPointSmall}) {
    visibility: hidden;
    display: none;
    width: 0;
    margin: 0;
    height: 0;
    padding: 0;
  }
`
