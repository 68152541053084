import { Button } from 'components/ui'
import moment from 'moment'
import React from 'react'

interface Props {
  datePaidMode: DatePaidMode
  onClick: (date: Date | moment.Moment) => void
  appointmentDate: Date | moment.Moment
}

export const DatePaidButton = (props: Props) => {
  const getButtonContent = () => {
    const mode = props.datePaidMode

    switch (mode) {
      case DatePaidMode.InvoiceDate:
        return 'Set to Current Date'
      case DatePaidMode.CurrentDate:
        return 'Set To Appointment Date'
    }
  }

  const onClick = () => {
    if (props.datePaidMode === DatePaidMode.InvoiceDate) {
      props.onClick(moment())
    } else {
      props.onClick(props.appointmentDate)
    }
  }

  return (
    <Button
      content={getButtonContent()}
      icon="calendar alternate outline"
      onClick={onClick}
    />
  )
}

enum DatePaidMode {
  CurrentDate = 1,
  InvoiceDate = 2,
}

export { DatePaidMode }
