import React from 'react'
import moment from 'moment'

import {
  SelectableText,
  LinkText,
  Label,
  FormField,
  Text,
  Popup,
} from 'components/ui'
import { AppointmentEventData } from 'interfaces'

interface Props {
  eventData: AppointmentEventData
  readonly?: boolean
  setCalendarModalOpen: Function
  setEditEventModalOpen: Function
}

export const AppointmentModalTherapistDetails = (props: Props) => {
  const useLongDateFormat =
    moment(props.eventData.event.start).get('day') !==
    moment(props.eventData.event.end).get('day')
  const appointmentText = `${moment(props.eventData.event.start).format(
    'MMMM D YYYY h:mm a',
  )} - ${moment(props.eventData.event.end).format(
    useLongDateFormat ? 'MMMM D YYYY h:mm a' : 'h:mm a',
  )}`

  return (
    <>
      <FormField>
        <Label>Date</Label>
        {props.readonly ? (
          <Text fontSize={18}>{appointmentText}</Text>
        ) : (
          <div style={{ display: 'flex' }}>
            <Popup
              content="Edit Appointment Date"
              trigger={
                <SelectableText
                  fontSize={18}
                  onClick={() => props.setCalendarModalOpen(true)}
                >
                  <LinkText>{appointmentText}</LinkText>
                </SelectableText>
              }
            />

            <SelectableText
              onClick={() => props.setEditEventModalOpen(true)}
              style={{ marginLeft: '1em', marginTop: '0.25em' }}
            >
              <LinkText>(Edit Time)</LinkText>
            </SelectableText>
          </div>
        )}
      </FormField>
    </>
  )
}
