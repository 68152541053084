import React from 'react'
import moment from 'moment'
import { AppointmentEventData, IEvent, Validation } from 'interfaces'

import {
  Button,
  SelectableText,
  LinkText,
  Label,
  FormField,
  Text,
  Popup,
} from 'components/ui'
import { Dropdown, TextArea, Input } from 'components/forms'
import { getClientFullName, getAllClientsList$ } from 'containers/clients'
import { useSelector } from 'react-redux'
import { AppointmentLocation } from './components/appointment-location'
import styled from 'styled-components'
import { MobileBreakPoint } from 'styles'
import { ServiceDropdown } from 'containers/receipts/components/service-dropdown'

const NewClientButton = styled(Button)`
  &&&&& {
    margin-top: 1.85em;

    @media (max-width: ${MobileBreakPoint}) {
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
`

interface Props {
  clientID: string
  eventData: AppointmentEventData
  isEditMode?: boolean
  isForClient: boolean
  locationIndex: number
  onChange: (e: IEvent) => void
  readonly?: boolean
  setCalendarModalOpen: Function
  setEditEventModalOpen: Function
  setLocationIndex: Function
  setNewClientModalOpen: Function
  validation: Validation
}

export const AppointmentModalClientDetails = (props: Props) => {
  const useLongDateFormat =
    moment(props.eventData.event.start).get('day') !==
    moment(props.eventData.event.end).get('day')
  const appointmentText = `${moment(props.eventData.event.start).format(
    'MMMM D YYYY h:mm a',
  )} - ${moment(props.eventData.event.end).format(
    useLongDateFormat ? 'MMMM D YYYY h:mm a' : 'h:mm a',
  )}`
  const clientList = useSelector(getAllClientsList$)

  return (
    <>
      {props.eventData.event.id && props.isForClient && (
        <Input
          label="Session Number"
          name="appointmentNumber"
          onChange={props.onChange}
          type="number"
          value={props.eventData.event.appointmentNumber}
        />
      )}

      {!props.isEditMode && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '1em',
            flexWrap: 'wrap-reverse',
          }}
        >
          <Dropdown
            autoFocus={!props.clientID && !props.eventData.event.clientID}
            clearable
            label="Client"
            name="clientID"
            onChange={props.onChange}
            options={clientList.map((client: any) => ({
              id: client.id,
              key: client.id,
              value: client.id,
              text: getClientFullName(client),
            }))}
            placeholder="Select Client"
            readonly={props.isEditMode || props.readonly}
            style={{ width: '100%' }}
            validation={props.validation?.clientID}
            validationRules={{ required: true }}
            value={props.clientID || props.eventData.event.clientID}
          />

          {!props.isEditMode && !props.clientID && (
            <div>
              <NewClientButton
                content="New Client"
                icon="user"
                onClick={() => props.setNewClientModalOpen(true)}
                primary
              />
            </div>
          )}
        </div>
      )}

      <ServiceDropdown
        onChange={props.onChange}
        readonly={props.isEditMode || props.readonly}
        value={props.eventData.event.serviceID}
      />

      <FormField>
        <Label>Date</Label>
        {props.readonly ? (
          <Text fontSize={18}>{appointmentText}</Text>
        ) : (
          <div style={{ display: 'flex' }}>
            <Popup
              content="Edit Appointment Date"
              trigger={
                <SelectableText
                  fontSize={18}
                  onClick={() => props.setCalendarModalOpen(true)}
                >
                  <LinkText>{appointmentText}</LinkText>
                </SelectableText>
              }
            />

            <SelectableText
              onClick={() => props.setEditEventModalOpen(true)}
              style={{ marginLeft: '1em', marginTop: '0.25em' }}
            >
              <LinkText>(Edit Time)</LinkText>
            </SelectableText>
          </div>
        )}
      </FormField>

      <AppointmentLocation
        appointment={props.eventData.event}
        clientID={props.clientID || props.eventData.event.clientID}
        locationIndex={props.locationIndex}
        onChange={props.onChange}
        readonly={props.readonly}
        setLocationIndex={props.setLocationIndex}
      />

      <TextArea
        label="Additional Notes for Client"
        name="clientNotes"
        onChange={props.onChange}
        placeholder="Any additional notes you have for the client in the reminder email/sms message"
        readonly={props.readonly}
        value={props.eventData.event.clientNotes}
      />
    </>
  )
}
