import { Feature } from 'interfaces'

export const AdminSetFeatures = 'admin:SET-FEATURES'

export const GlobalCustomForms = 'global:CUSTOM-FORMS'
export const GlobalPublicIntakes = 'global:PUBLIC-INTAKES'
export const GlobalSummaryTab = 'global:SUMMARY-TABS'
export const GlobalAppointmentReminders = 'global:APPOINTMENT_REMINDERS'
export const GlobalEmailInvoices = 'global:EMAIl-INVOICES'

export const BetaUser = 'users:BETA-USER'
export const PublicIntakes = 'users:PUBLIC-INTAKES'
export const FileUpload = 'users:FILE-UPLOADS'
export const SummaryTab = 'users:SUMMARY-TABS'
export const CanHaveEmails = 'users:CAN-HAVE-EMAILS'
export const CanSendAppointmentReminders =
  'appointments:CAN_SEND_APPOINTMENT_REMINDERS'
export const CanSendSMSAppointmentReminders =
  'appointments:CAN_SEND_SMS_APPOINTMENT_REMINDERS'
export const CustomForms = 'users:CUSTOM-FORMS'
export const CanEmailInvoices = 'users:CAN-EMAIL-INVOICES'

export const GlobalFeatures = [
  GlobalCustomForms,
  GlobalPublicIntakes,
  GlobalSummaryTab,
  GlobalAppointmentReminders,
  GlobalEmailInvoices,
]

export const AllFeatures = [
  BetaUser,
  SummaryTab,
  PublicIntakes,
  FileUpload,
  CanHaveEmails,
  CanSendAppointmentReminders,
  CanSendSMSAppointmentReminders,
  CustomForms,
  CanEmailInvoices,
]

export const getHumanReadableFeatures = (feature: Feature) => {
  switch (feature) {
    case AdminSetFeatures:
      return 'Admin Set Features'

    case GlobalCustomForms:
      return 'Global Custom Forms'

    case GlobalPublicIntakes:
      return 'Global Public Intakes'

    case GlobalSummaryTab:
      return 'Global Summary Tab'

    case GlobalAppointmentReminders:
      return 'Global Appointment Reminders'

    case GlobalEmailInvoices:
      return 'Global Email Invoices'

    case BetaUser:
      return 'Beta User'

    case PublicIntakes:
      return 'Public Intakes'

    case FileUpload:
      return 'File Uploads'

    case SummaryTab:
      return 'Summary Tab'

    case CanHaveEmails:
      return 'User Can Send Emails'

    case CanSendAppointmentReminders:
      return 'User Can Send Email Appointment Reminders'

    case CanSendSMSAppointmentReminders:
      return 'User Can Send SMS Appointment Reminders'

    case CustomForms:
      return 'User Custom Forms'

    case CanEmailInvoices:
      return 'User Can Email Invoices'

    default:
      return ''
  }
}
