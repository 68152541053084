import React, { useState, useEffect } from 'react'

import { SemanticSIZES } from 'semantic-ui-react'
import { Segment, Loader, Dimmer, Text } from 'components/ui'
import { useGetColourTheme } from 'lib'

const Spinner = ({ spinning, tip, size, children }: ISpinner) => {
  const [isSpinning, setIsSpinning] = useState(false)
  const [timeout, setSpinnerTimeout] = useState(null)
  const theme = useGetColourTheme('dark')

  useEffect(() => {
    setSpinnerTimeout(window.setTimeout(() => setIsSpinning(spinning), 250))

    if (!spinning) {
      setIsSpinning(false)
      window.clearTimeout(timeout)
    }
    // Disabling as we don't want to refetch on every timeout value change,
    // but if we don't include it react gets mad ¯\_(ツ)_/¯
  }, [spinning, setIsSpinning])

  return (
    <>
      <Dimmer active={isSpinning} inverted={theme !== 'dark'}>
        <Loader active={isSpinning} inverted={theme !== 'dark'} size={size}>
          <Text>{tip}</Text>
        </Loader>
      </Dimmer>
      <>{children}</>
    </>
  )
}

interface ISpinner {
  children?: any // anyPropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  size?: SemanticSIZES
  spinning: boolean
  tip?: string
}

Spinner.defaultProps = {
  size: 'medium',
}

const SpinnerInline = ({ spinning, tip, size, children }: ISpinner) => (
  <>
    <Loader active={spinning} inline="centered" size={size}>
      {tip}
    </Loader>
    <>{children}</>
  </>
)

const InlineSpinner = ({ loading, size }: IInlineSpinner) =>
  loading && <Loader active={loading} inline size={size} />

interface IInlineSpinner {
  loading: boolean
  size: SemanticSIZES
}

InlineSpinner.defaultProps = {
  loading: true,
  size: 'small',
}

const SimpleSpinner = () => {
  const theme = useGetColourTheme('dark')
  return (
    <Segment>
      <Dimmer active inverted={theme !== 'dark'}>
        <Loader active inverted={theme !== 'dark'}>
          <Text>Loading</Text>
        </Loader>
      </Dimmer>
    </Segment>
  )
}

export { InlineSpinner, SimpleSpinner, Spinner, SpinnerInline }
