enum NotificationType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

interface Notification {
  content?: string
  id: string
  message: string
  type: NotificationType
}

export type { Notification }
export { NotificationType }
