import { InvoiceReport, InvoiceReportItem } from 'interfaces'
import { DataItem, SList as List, Segment, Text } from 'components/ui'
import React from 'react'
import { InvoiceReportsTableView } from './invoice-reports-table-view'
import { formatAmount } from 'lib'

interface Props {
  details: InvoiceReport
  items: InvoiceReportItem[]
}

export const InvoiceReportsDataView = (props: Props) => {
  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '1em' }}>
        <div style={{width: '25em'}}>
          <Segment style={{ minHeight: '12em'}}>
            <List>
              <List.Item>
                <List.Content>
                  <DataItem>
                    <Text>Total Invoices</Text>
                    <Text>{props.details.totalInvoices}</Text>
                  </DataItem>
                </List.Content>

                {props.details.totalUnpaid > 0 && (
                  <>
                    <List.Content>
                      <DataItem>
                        <Text>Total Paid Invoices</Text>
                        <Text>{props.details.totalPaid}</Text>
                      </DataItem>
                    </List.Content>

                    <List.Content>
                      <DataItem>
                      <Text>Total Unpaid Invoices</Text>
                      <Text>{props.details.totalUnpaid}</Text>
                      </DataItem>
                    </List.Content>

                    <List.Content>
                      <DataItem>
                        <Text>Total Amount Unpaid</Text>
                        <Text>{`$${formatAmount(props.details.amountUnpaid)}`}</Text>
                      </DataItem>
                    </List.Content>
                  </>
                )}
              </List.Item>
            </List>
          </Segment>
        </div>

        <div style={{width: '25em'}}>
          <Segment style={{ minHeight: '12em'}}>
            <List>
              <List.Item>
                <List.Content>
                  <DataItem>
                    <Text>SubTotal (PreTax)</Text>
                    <Text>{`$${formatAmount(props.details.subtotal)}`}</Text>
                  </DataItem>
                </List.Content>

                <List.Content>
                  <DataItem>
                    <Text>Total Taxes</Text>
                    <Text>{`$${formatAmount(props.details.totalTax)}`}</Text>
                  </DataItem>
                </List.Content>

                <List.Content>
                  <DataItem>
                    <Text>Total</Text>
                    <Text>{`$${formatAmount(props.details.total)}`}</Text>
                  </DataItem>
                </List.Content>
              </List.Item>
            </List>
          </Segment>
        </div>
      </div>

      <InvoiceReportsTableView items={props.items} />
    </>
  )
}