import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import copy from 'copy-to-clipboard'

import {
	Button,
	Text,
	LinkText as LT,
	DataItem,
	Modal,
	Header,
	SList as List,
	IconButton,
	Segment,
	Signature,
	Popup,
} from 'components/ui'
import { fetchPublicIntake } from 'containers/public-intake'
import { Spinner } from 'components/loaders'
import { getFormTemplates$ } from 'containers/forms'
import { FormPreviewModal } from 'components/modals'
import { getSelectedClient$ } from 'containers/clients'
import { IResponse, IPublicIntake, Dispatch } from 'interfaces'

interface ILinkText {
	href?: string
}

const LinkText = styled(LT)<ILinkText>`
  max-width: 75%;
`

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.25em;

  :hover {
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }
`

export const PublicIntakeDetailsModal = (props: IPublicIntakeDetailsModal) => {
	const dispatch: Dispatch = useDispatch()

	const formTemplates = useSelector(getFormTemplates$)
	const clientData = useSelector(getSelectedClient$)

	const [loading, setLoading] = useState(false)
	const [data, setData] = useState({} as IPublicIntake)
	const [linkCopied, setLinkCopied] = useState(false)
	const [formPreviewModalState, setFormPreviewModalState] = useState({
		open: false,
		json: [],
		name: '',
	})
	const [signatureData, setSignatureData] = useState({
		signature: '',
		date: '',
		name: '',
	})

	const setModalData = useCallback(
		(data: any) => {
			setData(data)

			const dateCompleted = moment(data.dateCompleted).format('DDMMYYYY')
			const signatureKey = `intakeSig-${dateCompleted}`
			const nameKey = `intakeSig-${dateCompleted}-name`
			const dateKey = `intakeSig-${dateCompleted}-date`

			setSignatureData({
				signature: clientData[signatureKey],
				name: clientData[nameKey],
				date: clientData[dateKey],
			})
		},
		[clientData],
	)

	const fetchDetails = useCallback(
		(id) => {
			setLoading(true)
			dispatch(fetchPublicIntake(id)).then(
				(response: IResponse<IPublicIntake>) => {
					setLoading(false)
					setModalData(response.data)
				},
			)
		},
		[dispatch, setModalData],
	)

	useEffect(() => {
		const dateCompleted = moment(data.dateCompleted).format('DDMMYYYY')
		const signatureKey = `intakeSig-${dateCompleted}`
		const nameKey = `intakeSig-${dateCompleted}-name`
		const dateKey = `intakeSig-${dateCompleted}-date`

		setSignatureData({
			signature: clientData[signatureKey],
			name: clientData[nameKey],
			date: clientData[dateKey],
		})
	}, [data, clientData])

	const { id, visible } = props
	useEffect(() => {
		!!id && visible && fetchDetails(id)
	}, [fetchDetails, id, visible])

	const onClose = () => {
		setData({} as IPublicIntake)
		setSignatureData({
			signature: '',
			date: '',
			name: '',
		})
		setLinkCopied(false)

		props.handleClose()
	}

	const link = `${document.location.origin}/intake-forms/${data.clientID}/${data.link}`

	const onPreviewFormClick = (json: any, name: string) => {
		setFormPreviewModalState({
			...formPreviewModalState,
			open: true,
			json: json,
			name: name,
		})
	}

	const getStatus = (status: string) => {
		if (status === 'FailedToSendEmail') {
			return 'Intake Form Created, Failed to Send Email'
		}

		return status
	}

	return (
		<Modal onClose={onClose} open={props.visible} size='small'>
			<Header content='Intake Details' icon='list alternate outline' />

			<FormPreviewModal
				canDelete={false}
				fileJson={formPreviewModalState.json}
				modalOpen={formPreviewModalState.open}
				name={formPreviewModalState.name}
				onCancel={() =>
					setFormPreviewModalState({
						...formPreviewModalState,
						open: false,
					})
				}
				readonly
			/>

			<Modal.Content>
				<Spinner spinning={loading} tip='loading...'>
					<List>
						<List.Item>
							<List.Content>
								<DataItem>
									<Text>Email</Text>
									<Text>{data.email}</Text>
								</DataItem>
							</List.Content>

							{!data.status ? (
								<List.Content>
									<DataItem>
										<Text>Completed</Text>
										<Text>{data.completed ? 'Yes' : 'No'}</Text>
									</DataItem>
								</List.Content>
							) : (
								<List.Content>
									<DataItem>
										<Text>Status</Text>
										<Text>{getStatus(data.status)}</Text>
									</DataItem>
								</List.Content>
							)}

							{!data.completed && (
								<List.Content>
									<DataItem>
										<Text>Expires</Text>
										<Text>{moment(data.expires).format('MMMM D YYYY')}</Text>
									</DataItem>
								</List.Content>
							)}

							{data.completed && data.dateCompleted && (
								<List.Content>
									<DataItem>
										<Text>
											{data.completed &&
											data.status !== 'Closed' &&
											data.dateCompleted
												? 'Date Completed'
												: 'Date Closed'}
										</Text>
										<Text>
											{moment(data.dateCompleted).format('MMMM D YYYY h:mm a')}
										</Text>
									</DataItem>
								</List.Content>
							)}

							<List.Content>
								<DataItem>
									<Text>Link</Text>
									{data.completed ? (
										<Text>-</Text>
									) : (
										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												alignItems: 'center',
											}}
										>
											<LinkText href={link}>{link}</LinkText>
											<Popup
												content={
													linkCopied ? 'Copied!' : 'Copy Link to Clipboard'
												}
												position='right center'
												trigger={
													<div>
														{linkCopied ? (
															<Text>Copied!</Text>
														) : (
															<IconButton
																name='copy'
																onClick={() => {
																	copy(link)
																	setLinkCopied(true)
																	setTimeout(() => setLinkCopied(false), 2500)
																}}
																size='large'
															/>
														)}
													</div>
												}
											/>
										</div>
									)}
								</DataItem>
							</List.Content>

							{data.passphrase && (
								<List.Content>
									<DataItem>
										<Text>Passphrase</Text>
										<Text>{data.passphrase}</Text>
									</DataItem>
								</List.Content>
							)}

							<List.Content>
								<Header>Forms</Header>
								<Segment>
									{data.forms &&
										data.forms.map((form) => {
											const formTemplate = formTemplates[form.id]

											return (
												<FormContainer key={form.id}>
													<div>
														<Text>{formTemplate.name}</Text>
													</div>
													<IconButton
														name='eye'
														noColour
														onClick={() =>
															onPreviewFormClick(
																formTemplate.form,
																formTemplate.name,
															)
														}
														size='large'
													/>
												</FormContainer>
											)
										})}
								</Segment>
							</List.Content>
						</List.Item>

						{data.completed && data.status === 'Completed' && (
							<List.Item>
								<Header style={{ marginBottom: '1em' }}>Signed Form</Header>
								<Segment>
									<Signature
										dateValue={signatureData.date}
										nameValue={signatureData.name}
										readonly
										signature={signatureData.signature}
									/>
								</Segment>
							</List.Item>
						)}
					</List>
				</Spinner>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose} secondary>
					Close
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

interface IPublicIntakeDetailsModal {
	handleClose: Function
	id: string
	visible: boolean
}
