import { IState } from 'interfaces'
import { createSelector } from 'reselect'

const accountForms = (state: IState) => state.account.forms

export const getAccountForms = createSelector([accountForms], (accountForms) =>
  accountForms.sort((a, b) => a.order - b.order),
)

export const getAccountFormsByKey = createSelector(
  [accountForms],
  (accountForms) => {
    const sortedForms = accountForms.sort((a, b) => a.order - b.order)
    const accountFormsObj = {}

    for (const form of sortedForms) {
      accountFormsObj[form.id] = form
    }

    return accountFormsObj
  },
)

export const getAccountFormsByFormID = createSelector(
  [accountForms],
  (accountForms) => {
    const sortedForms = accountForms.sort((a, b) => a.order - b.order)
    const accountFormsObj = {}

    for (const form of sortedForms) {
      accountFormsObj[form.id] = form
    }

    return accountFormsObj
  },
)

export const getStorageUsed = (state: IState) => state.account.quota.used
