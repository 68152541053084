export const DAYS = [
  {
    id: 0,
    letter: 'S',
    short: 'Sun',
    long: 'Sunday',
  },
  {
    id: 1,
    letter: 'M',
    short: 'Mon',
    long: 'Monday',
  },
  {
    id: 2,
    letter: 'T',
    short: 'Tue',
    long: 'Tuesday',
  },
  {
    id: 3,
    letter: 'W',
    short: 'Wed',
    long: 'Wednesday',
  },
  {
    id: 4,
    letter: 'T',
    short: 'Thu',
    long: 'Thursday',
  },
  {
    id: 5,
    letter: 'F',
    short: 'Fri',
    long: 'Friday',
  },
  {
    id: 6,
    letter: 'S',
    short: 'Sat',
    long: 'Saturday',
  },
]
