import React, { useCallback } from 'react'
import moment from 'moment'

import {
	Button,
	Text,
	Message,
	Signature,
	Modal,
	Header,
	Icon,
	Divider,
} from 'components/ui'
import { getAPIClientRequestObject } from 'containers/clients/client-info/redux/save-client'

import { completePublicIntake } from './redux/complete-public-intake'
import { IClient } from 'interfaces'

export const CompleteModal = (props: ICompleteModal) => {
	const completeForm = useCallback(
		(id, clientID, data) => {
			props.setLoading(true)
			const client: IClient = getAPIClientRequestObject(data, undefined, undefined)
			client.fields.completed = true

			completePublicIntake(id, clientID, client)
				.then(() => {
					props.setSuccess(true)

					for (const key of Object.keys(sessionStorage)) {
						try {
							sessionStorage.removeItem(key)
						} catch {
							/* noop */
						}
					}
				})
				.catch(() => {
					props.setError({
						hasError: true,
						title: 'Error',
						msg: 'Error Completing Form, please try again later',
					})
				})
				.finally(() => props.setLoading(false))
		},
		[props],
	)

	const name = `intakeSig-${moment().format('DDMMYYYY')}`

	return (
		<Modal open={props.showSubmitModal} size='small'>
			<Header content='Submit Form' icon='clipboard outline' />
			<Modal.Content>
				<Message
					content='Once you submit the form you will not be able to make anymore changes'
					info
				/>

				<Divider />
				{/* TODO - Form Completion Text */}
				<Text>I confirm that the intake information is correct:</Text>

				<Signature
					dateValue={moment()}
					name={name}
					nameValue={props.formData[`${name}-name`]}
					onChange={({ target }) =>
						props.onChange(null, target.name, target.value)
					}
					signature={props.formData[`${name}`]}
				/>
			</Modal.Content>

			<Modal.Actions>
				<Button onClick={() => props.setShowSubmitModal(false)} secondary>
					Cancel
				</Button>

				<Button
					disabled={
						!props.formData[`${name}-name`] || !props.formData[`${name}`]
					}
					onClick={() => {
						props.setShowSubmitModal(false)
						completeForm(
							props.publicIntake.id,
							props.publicIntake.clientID,
							props.formData,
						)
					}}
					primary
				>
					<Icon name='checkmark' /> Submit
				</Button>
			</Modal.Actions>
		</Modal>
	)
}

interface ICompleteModal {
	formData: any
	onChange: Function
	publicIntake: any
	setError: Function
	setLoading: Function
	setShowSubmitModal: Function
	setSuccess: Function
	showSubmitModal: boolean
}
