import { authPost } from 'lib/http-request'
import { Settings } from 'constants/endpoints'
import { message } from 'components/notifications'

import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { getFormTypeFromFormScreen } from 'constants/settings'
import { IAction, Dispatch, IFormType, IFormTypeRequest } from 'interfaces'
import { IResponse, SettingsState } from 'interfaces'
import { parseFormTypeSettingFromAPI } from 'lib'

const settingsAction = (action: string) => `client-trackyr/settings/${action}`

const RESTORE_FORM_TYPE = settingsAction('RESTORE_FORM_TYPE')
const RESTORE_FORM_TYPE_SUCCESS = settingsAction('RESTORE_FORM_TYPE_SUCCESS')
const RESTORE_FORM_TYPE_FAILURE = settingsAction('RESTORE_FORM_TYPE_FAILURE')

export const defaultState = {} as SettingsState

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case RESTORE_FORM_TYPE:
      return {
        ...state,
        flags: {
          ...state.flags,
          restoringForm: true,
        },
      }

    case RESTORE_FORM_TYPE_SUCCESS: {
      const id = action.response.id
      const key = getFormTypeFromFormScreen(action.response.screen)

      return {
        ...state,
        [key]: {
          ...state[key],
          [id]: action.response,
        },
        flags: {
          ...state.flags,
          restoringForm: false,
        },
      }
    }

    case RESTORE_FORM_TYPE_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          restoringForm: false,
        },
      }

    default:
      return state
  }
}

export const restoreFormType = (id: string) => async (
  dispatch: Dispatch,
): Promise<any> => {
  dispatch({ type: RESTORE_FORM_TYPE, id })

  return authPost(Settings.RestoreFormType(id), {})
    .then((response: IResponse<IFormTypeRequest>) => {
      message.success('Form Types Saved')
      dispatch(
        restoreFormTypeSuccess(parseFormTypeSettingFromAPI(response.data)),
      )

      return response
    })
    .catch((error) => {
      message.error(`Failed to Restore Form Type: ${error}`)
      captureMessage(`restoreFormType Failed. ${error}`, Severity.Error)

      dispatch(restoreFormTypeFailure(error, id))

      return error
    })
}

export const restoreFormTypeSuccess = (response: IFormType) => ({
  type: RESTORE_FORM_TYPE_SUCCESS,
  response,
})

export const restoreFormTypeFailure = (error: any, formType: string) => ({
  type: RESTORE_FORM_TYPE_FAILURE,
  error,
  formType,
})
