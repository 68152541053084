import { authDelete } from 'lib/http-request'
import { Treatments } from 'constants/endpoints'
import { message } from 'components/notifications'
import { saveTreatment } from './save-treatment'
import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'
import { IAction } from 'interfaces'

const treatmentAction = (action: string) =>
  `client-trackyr/treatments/${action}`
const CLOSE_TREATMENT = treatmentAction('CLOSE_TREATMENT')
const CLOSE_TREATMENT_SUCCESS = treatmentAction('CLOSE_TREATMENT_SUCCESS')
const CLOSE_TREATMENT_FAILURE = treatmentAction('CLOSE_TREATMENT_FAILURE')

export const defaultState = {}

export const reducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case CLOSE_TREATMENT:
      return {
        ...state,
        closingTreatment: true,
      }

    case CLOSE_TREATMENT_SUCCESS:
      return {
        ...state,
        [action.clientID]: {
          ...state[action.clientID],
          [action.treatment.id]: action.treatment,
          fetched: true,
        },
        closingTreatment: false,
      }

    case CLOSE_TREATMENT_FAILURE:
      return {
        ...state,
        closingTreatment: false,
      }

    default:
      return state
  }
}

export const closeTreatment = (
  currentTreatment: any,
  closeMessage: string,
): any => async (dispatch: any): Promise<any> => {
  const clientID = currentTreatment.clientID
  dispatch({ type: CLOSE_TREATMENT, clientID })

  return authDelete(Treatments.DeleteTreatment(clientID))
    .then((response) => {
      const treatment = {
        ...response.data,
        treatmentCloseMessage: closeMessage,
      }

      dispatch(saveTreatment(clientID, treatment.clientTypeID, treatment))
      dispatch(closeTreatmentSuccess(response.data, clientID))
      return response
    })
    .catch((error) => {
      captureMessage(`closeTreatment Failed. ${error}`, Severity.Error)

      dispatch(closeTreatmentFailure(error, clientID))
      message.error('Failed to close treatment')

      return error
    })
}

export const closeTreatmentSuccess = (treatment: any, clientID: string) => ({
  type: CLOSE_TREATMENT_SUCCESS,
  treatment,
  clientID,
})

export const closeTreatmentFailure = (error: any, clientID: string) => ({
  type: CLOSE_TREATMENT_FAILURE,
  error,
  clientID,
})
