import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import shortid from 'shortid'

import {
  DNDList,
  Button,
  Label,
  SelectableText,
  IconButton,
  Input,
  Form,
  FormField,
} from 'components/ui'
import { defaultTabs } from 'constants/settings'
import { useSelector } from 'react-redux'
import { getTabTypes } from '../selectors'
import { MobileBreakPoint } from 'styles'
import { IEvent } from 'interfaces'

const TabLabel = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 1em;
  width: 25em;
`

const TabWidth = styled.div`
  width: 25em;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const TabsContainer = styled.div`
  @media (max-width: ${MobileBreakPoint}) {
    margin-top: 1em;
  }
`

interface Props {
  disabled?: boolean
  tabs: Array<any>
  setTabs: Function
}

export const FormTypeTabs = (props: Props) => {
  const [addingNewFormTab, setAddingNewFormTab] = useState(false)
  const [newTabName, setNewTabName] = useState('')
  const globalTabs = useSelector(getTabTypes)
  const [tabs, setTabs] = useState(
    props.tabs ? [...props.tabs] : [...globalTabs],
  )

  const tabProps = props.tabs
  useEffect(() => {
    tabProps && tabProps.length && setTabs(tabProps)
  }, [tabProps])

  const onTabsChange = (items: Array<any>) => {
    const newTabs = items.map((tab: any, index: number) => ({
      ...tab,
      order: index,
    }))
    setTabs(newTabs)
    props.setTabs(newTabs)
  }

  const handleKeyPress = (e: KeyboardEvent | any) => {
    if (e.keyCode === 13) {
      e.target.blur()
    } else if (e.keyCode === 27) {
      setAddingNewFormTab(false)
      setNewTabName('')
    }
  }

  const onBlur = () => {
    if (newTabName === '') {
      setAddingNewFormTab(false)
      return
    }

    const newTabs = tabs
    newTabs.push({
      id: shortid.generate(),
      order: tabs.length,
      tab: newTabName,
      key: newTabName.toLowerCase().replace(/ /g, '-'),
      enabled: true,
      forms: [],
    })

    setTabs(newTabs)
    props.setTabs(newTabs, newTabName.replace(/ /, ''))

    setNewTabName('')
    setAddingNewFormTab(false)
  }

  const onDelete = (index: number) => {
    const newTabs = tabs
    newTabs.splice(index, 1)
    setTabs(newTabs)
    props.setTabs(newTabs)
  }

  return (
    <TabsContainer>
      <TabLabel>
        <Label>Tabs</Label>
        <Button
          circular
          disabled={props.disabled}
          icon={addingNewFormTab ? 'x' : 'plus'}
          onClick={() => setAddingNewFormTab(!addingNewFormTab)}
          primary={!addingNewFormTab}
          size="small"
        />
      </TabLabel>

      <TabWidth>
        {addingNewFormTab && (
          <Form>
            <FormField>
              <Label>New Tab Name</Label>
              <Input
                action={{
                  color: 'blue',
                  icon: 'checkmark',
                }}
                autoFocus
                name="newTabName"
                onBlur={onBlur}
                onChange={({ target }: IEvent) => setNewTabName(target.value)}
                onKeyDown={handleKeyPress}
                style={{ marginBottom: '0.75em' }}
                value={newTabName}
              />
            </FormField>
          </Form>
        )}
      </TabWidth>

      <TabWidth>
        <DNDList
          disabled={props.disabled}
          items={tabs}
          renderItem={(tab: any, index: number) => {
            return (
              <Container key={tab.id}>
                <SelectableText>{tab.tab}</SelectableText>

                <Container>
                  {!props.disabled &&
                    !tab.default &&
                    !defaultTabs.find(
                      (defaultTab) => defaultTab.id === tab.id,
                    ) &&
                    !globalTabs.find((gt: any) => gt.id === tab.id) && (
                      <IconButton
                        color="red"
                        name="trash alternate outline"
                        onClick={() => onDelete(index)}
                        style={{ marginRight: '1em' }}
                      />
                    )}

                  {!props.disabled && <IconButton name="bars" />}
                </Container>
              </Container>
            )
          }}
          setItems={onTabsChange}
        />
      </TabWidth>
    </TabsContainer>
  )
}
