import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { SimpleSpinner } from 'components/loaders'
import { getActiveClientTypes } from 'containers/settings'
import { Button, Form, Dropdown, Icon, Text, H4 } from 'components/ui'
import { TextArea } from 'components/forms'
import { PositiveModal } from 'components/modals'
import { IEvent, IState } from 'interfaces'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

class EmptyCurrentTreatment extends React.Component<
  EmptyCurrentTreatmentProps
> {
  state = {
    createNewVisible: false,
    createModalOpen: false,
    createTreatmentMessage: '',
    clientTypeID: '',
  }

  showCreateModal = (clientTypeID: string) =>
    this.setState(() => ({ createModalOpen: true, clientTypeID }))

  renderCreateModal = () => {
    const modalProps = {
      modalOpen: this.state.createModalOpen,
      message: 'Create a new Treatment',
      icon: 'address card',
      content: (
        <>
          {this.props.clientTypes.length > 1 && (
            <Container>
              <Text>Pick a Client Type for the new Treatment</Text>
              <Dropdown
                autoFocus
                name="clientType"
                onChange={({ target }) =>
                  this.setState(() => ({ clientTypeID: target.value }))
                }
                options={this.props.clientTypes.map((ct) => ({
                  text: ct.name,
                  key: ct.id,
                  value: ct.id,
                }))}
                selection
                value={this.state.clientTypeID}
              />
            </Container>
          )}
          <Form>
            <TextArea
              label="New Treatment Notes"
              name="treatmentOpenMessage"
              onChange={(e: IEvent) => {
                const value = e.target.value
                this.setState(() => ({ createTreatmentMessage: value }))
              }}
              placeholder="New Treatment Notes"
              value={this.state.createTreatmentMessage}
            />
          </Form>
        </>
      ),
      onAccept: () => {
        this.setState(() => ({ createModalOpen: false }))
        this.props.saveTreatment(
          this.state.clientTypeID,
          this.state.createTreatmentMessage,
        )
      },
      onCancel: () => this.setState(() => ({ createModalOpen: false })),
    }

    return <PositiveModal {...modalProps} />
  }

  onNewTreatmentClick = () => {
    if (this.props.clientTypes.length === 1) {
      this.showCreateModal(this.props.clientTypes[0].id)
    } else {
      this.setState(() => ({ createModalOpen: true }))
    }
  }

  render() {
    return this.props.savingTreatment ? (
      <SimpleSpinner />
    ) : (
      <>
        {this.renderCreateModal()}

        <H4> No Current Case </H4>
        <Button onClick={this.onNewTreatmentClick} type="primary">
          <Icon name="plus" /> New Treatment Case
        </Button>
      </>
    )
  }
}

interface EmptyCurrentTreatmentProps {
  clientTypes: Array<any>
  saveTreatment: Function
  savingTreatment: boolean
}

const mapStateToProps = (state: IState) => ({
  clientTypes: getActiveClientTypes(state),
})

export default connect(mapStateToProps)(EmptyCurrentTreatment)
