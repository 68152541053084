import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getClient, getClientFullName } from 'containers/clients'
import { IState } from 'interfaces'
import { Avatar, Icon, TableRow, TableCell } from 'components/ui'
import { Receipt } from '../interfaces'
import { formatAmount } from 'lib'
import { getServiceObj } from 'containers/services/redux/selectors'
import { ReceiptTableUnpaidRowColour } from 'styles/colours'

interface Props {
  receipt: Receipt
  onClick: (receipt: Receipt) => void
}

export const ReceiptItem = ({ receipt, onClick }: Props) => {
  const client = useSelector((state: IState) =>
    getClient(state, receipt.clientID),
  )
  const clientName = getClientFullName(client)
  const services = useSelector(getServiceObj)

  const onItemClick = () => {
    onClick(receipt)
  }

  const datePaid = receipt.datePaid
    ? `(${moment(receipt.datePaid).format('MMMM D YYYY')})`
    : ''

  return (
    <TableRow
      clickable
      onClick={onItemClick}
      style={{
        backgroundColor: receipt.paid ? null : ReceiptTableUnpaidRowColour,
      }}
    >
      <TableCell style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar size={'40px'}>
          <Icon name="print" size="big" style={{ margin: 'auto' }} />
        </Avatar>
        {clientName}
      </TableCell>
      <TableCell>{`${moment(receipt.date).format('MMMM D YYYY')}`}</TableCell>
      <TableCell>{services[receipt?.serviceID]?.name}</TableCell>
      <TableCell>{receipt.paid ? `Paid ${datePaid}` : 'Unpaid'}</TableCell>
      <TableCell>{`$${formatAmount(receipt.amount as number)}`}</TableCell>
    </TableRow>
  )
}
