import { authGet } from 'lib/http-request'
import { Appointments } from 'constants/endpoints'
import { captureMessage } from '@sentry/browser'
import { Severity } from 'interfaces'

import { message } from 'components/notifications'
import { IResponse } from 'interfaces/fetch'
import { AppointmentState, IAction, IAppointment } from 'interfaces'

const action = (action: string) => `client-trackyr/appointments/${action}`
const FETCH_APPOINTMENT = action('FETCH_APPOINTMENT')
const FETCH_APPOINTMENT_SUCCESS = action('FETCH_APPOINTMENT_SUCCESS')
const FETCH_APPOINTMENT_ERROR = action('FETCH_APPOINTMENT_ERROR')

export const defaultState = {
	flags: {
		fetchingAppointment: false,
	},
} as AppointmentState

export const reducer = (
	state: AppointmentState = defaultState,
	action: IAction,
): AppointmentState => {
	switch (action.type) {
		case FETCH_APPOINTMENT: {
			return {
				...state,
				flags: {
					...state.flags,
					fetchingAppointment: true,
				},
			}
		}

		case FETCH_APPOINTMENT_SUCCESS: {
			const appointment: IAppointment = action.data
			return {
				...state,
				appointments: {
					...state.appointments,
					[appointment.id]: appointment,
				},
				flags: {
					...state.flags,
					fetchingAppointment: false,
				},
			}
		}

		case FETCH_APPOINTMENT_ERROR: {
			return {
				...state,
				flags: {
					...state.flags,
					fetchingAppointment: false,
				},
			}
		}

		default:
			return state
	}
}

/**
 * fetchAppointment - fetches an appointment by ID
 * @param {bool} useCache
 * @return {Promise}
 */
export const fetchAppointment =
	(id: string /*, useCache: boolean = true*/): any =>
		async (dispatch: any): Promise<any> => {
			dispatch({ type: FETCH_APPOINTMENT, id })

			return authGet(Appointments.Get(id))
				.then(async (response: IResponse<IAppointment>) => {
					if (response.status === 200 || response.status === 304) {
						dispatch(fetchAppointmentSuccess(response.data))
					}
					return response
				})
				.catch((error) => {
					dispatch(fetchAppointmentError(error))
					message.error('Failed to fetch appointments')

					captureMessage(`fetchAppointment Failed. ${error}`, Severity.Error)

					throw error
				})
		}

const fetchAppointmentSuccess = (data: IAppointment) => ({
	type: FETCH_APPOINTMENT_SUCCESS,
	data,
})

const fetchAppointmentError = (error: any) => ({
	type: FETCH_APPOINTMENT_ERROR,
	error,
})
