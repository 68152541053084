import { Moment } from 'moment'

interface Task {
  id?: string
  entityID: string
  dateCreated?: Date | Moment
  time: Date | Moment
  type: TaskType
  definition?: string
  userID?: string
}

enum TaskType {
  Unknown = 0,
  EmailReminder = 1,
  SMSReminder = 2,
  Export = 3,
}

export type { Task }
export { TaskType }
