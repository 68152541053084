import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
	Button,
	Checkbox,
	Dropdown,
	Label,
	Message,
	Segment,
	Text,
} from 'components/ui'
import { getTaxesAsOptions$ } from '..'
import { Dispatch, IEvent, IResponse } from 'interfaces'
import { formatAmount, useDebounce } from 'lib'
import { fetchTaxes } from '../redux/fetch-taxes'
import { authPost } from 'lib/http-request'
import { Taxes } from 'constants/endpoints'
import { TaxResponse } from '../interfaces'
import { Spinner } from 'components/loaders'

const SPLIT_TOKEN = ','

interface Props {
	amount: number
	name: string
	onChange: (e: IEvent) => void
	readonly?: boolean
	// in format of: boolean,guid,guid,...,guid
	// first bool is includeInPrice, guids are individual taxes
	taxIDs: string
}

const TaxContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
`

export const TaxList = (props: Props) => {
	const dispatch: Dispatch = useDispatch()
	const taxIDs = props.taxIDs
	const taxOptions = useSelector(getTaxesAsOptions$)

	const getTaxes = (taxList: string): string[] => {
		const t = taxList?.split(SPLIT_TOKEN)

		if (t) {
			if (t[0] === 'true' || t[0] === 'false' || t[0] === 'undefined') {
				t.splice(0, 1)
			}

			return t
		}

		return ['']
	}
	const [taxes, setTaxes] = useState(getTaxes(props.taxIDs))
	const [includedInPrice, setIncludedInPrice] = useState(false)
	const [calculatedTaxes, setCalculatedTaxes] = useState({
		fetching: false,
		error: null,
		taxes: {
			taxes: [],
		} as TaxResponse,
	})

	const joinTaxes = (includedInPrice: boolean, taxToJoin: string[]) => {
		const t = [...taxToJoin]
		if (t[0] === 'true' || t[0] === 'false' || t[0] === 'undefined') {
			t.splice(0, 1)
		}

		return `${includedInPrice}${SPLIT_TOKEN}${t.join(SPLIT_TOKEN)}`
	}

	useEffect(() => {
		if (taxIDs?.length) {
			setTaxes(getTaxes(props.taxIDs))
			setIncludedInPrice(props.taxIDs?.split?.(SPLIT_TOKEN)?.[0] === 'true')
		}
	}, [taxIDs])

	// Fetch Tax List if length is zero
	const numTaxItems = taxOptions.length
	useEffect(() => {
		if (numTaxItems === 0) {
			dispatch(fetchTaxes())
		}
	}, [numTaxItems])

	const onChange = ({ target }: IEvent, index: number) => {
		const newTaxes = [...taxes]
		newTaxes[index] = target.value
		setTaxes(newTaxes)
		props.onChange({
			target: {
				name: props.name,
				value: joinTaxes(includedInPrice, newTaxes),
			},
		})
	}

	const onAdd = () => {
		const newTaxes = [...taxes]
		newTaxes.push('')
		setTaxes(newTaxes)
		props.onChange({
			target: {
				name: props.name,
				value: joinTaxes(includedInPrice, newTaxes),
			},
		})
	}

	const onRemove = (index: number) => {
		const newTaxes = [
			...taxes.slice(0, index),
			...taxes.slice(index + 1, taxes.length),
		]
		setTaxes(newTaxes)
		props.onChange({
			target: {
				name: props.name,
				value: joinTaxes(includedInPrice, newTaxes),
			},
		})
	}

	const onIncludedInPriceChange = ({ target }: IEvent) => {
		setIncludedInPrice(target.value)
		props.onChange({
			target: {
				name: props.name,
				value: joinTaxes(target.value, taxes),
			},
		})
	}

	const calculateTaxes = useCallback(async () => {
		setCalculatedTaxes({
			error: null,
			fetching: true,
			taxes: {} as TaxResponse,
		})

		try {
			const response: IResponse<TaxResponse> = await authPost(Taxes.Calculate, {
				taxIDs: taxes,
				subtotal: props.amount,
				inclusive: includedInPrice,
			})

			setCalculatedTaxes({
				error: null,
				fetching: false,
				taxes: response.data,
			})
		} catch {
			setCalculatedTaxes({
				error: 'Failed to Calculate Tax',
				fetching: false,
				taxes: {} as TaxResponse,
			})
		}
	}, [props.amount, taxes, includedInPrice])

	const onCalculateTaxesDebounce = useDebounce(() => calculateTaxes(), 1000)

	useEffect(() => {
		if (
			!calculatedTaxes.fetching &&
			props.amount > 0 &&
			taxes.length > 0 &&
			taxes[0] !== ''
		) {
			setCalculatedTaxes({
				...calculatedTaxes,
				fetching: true,
			})
			onCalculateTaxesDebounce()
		}
	}, [props.amount, taxes])

	if (taxOptions.length === 0) {
		return null
	}

	const hasTaxes = taxes.length > 1 || (taxes.length === 1 && taxes[0] !== '')

	if (!hasTaxes && props.readonly) {
		return null
	}

	return (
		<>
			<div>
				<Label>Taxes</Label>
			</div>

			{hasTaxes && (
				<div style={{ marginBottom: '1em' }}>
					<Checkbox
						checked={includedInPrice}
						label='Included in Price?'
						name='includedInPrice'
						onChange={onIncludedInPriceChange}
						readonly={props.readonly}
					/>
				</div>
			)}

			{taxes.map((tax: string, index: number) => {
				const onDropdownChange = ({ target }: IEvent) => {
					onChange({ target }, index)
				}
				const onItemRemove = () => onRemove(index)

				return (
					<TaxContainer key={`${tax}${index}`}>
						<Dropdown
							clearable
							onChange={onDropdownChange}
							options={taxOptions}
							placeholder='Select Tax'
							readonly={props.readonly}
							value={tax}
						/>

						{!props.readonly && (
							<>
								{taxes.length > 1 && (
									<Button icon='minus' onClick={onItemRemove} />
								)}
								<Button icon='plus' onClick={onAdd} />
							</>
						)}
					</TaxContainer>
				)
			})}

			{hasTaxes && (
				<Segment style={{ paddingBottom: '1em', minHeight: '6.5em' }}>
					<Spinner size='large' spinning={calculatedTaxes.fetching}>
						<div>
							<Text>{`Subtotal: $${formatAmount(
								calculatedTaxes.taxes.subtotal,
							)}`}</Text>
						</div>

						{calculatedTaxes?.taxes?.taxes?.map((t) => (
							<div key={t.amount}>
								<Text key={t.amount}>{`${t.tax.name}: $${formatAmount(
									t.amount,
								)}`}</Text>
							</div>
						))}

						<Text>{`Total: $${formatAmount(
							calculatedTaxes.taxes.total,
						)}`}</Text>
					</Spinner>
				</Segment>
			)}

			{calculatedTaxes.error && taxes.length !== 0 && !!taxes[0] && (
				<Message content={calculatedTaxes.error} error icon='warning' />
			)}
		</>
	)
}
