import { captureMessage } from '@sentry/browser'; import { Severity } from 'interfaces'

import { get } from 'lib/http-request'
import { PublicIntake } from 'constants/endpoints'

/**
 * Fetches the forms required for public intake by Public Intake ID
 * @param {Guid} id Public Intake ID
 */
const fetchPublicIntakeForms = async (id: string): Promise<any> => {
  return get(PublicIntake.GetForms(id))
    .then((response) => {
      return response
    })
    .catch((error) => {
      captureMessage(
        `fetchPublicIntakeForms Get Failed: ${error}`,
        Severity.Error,
      )

      throw error
    })
}

export { fetchPublicIntakeForms }
