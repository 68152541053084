import React from 'react'
import { Popup as P, PopupProps } from 'semantic-ui-react'
import { useGetColourTheme } from 'lib/get-colour-theme'
import styled from 'styled-components'

const PopupCont = styled(P)`
  &&&&& {
    z-index: 9000 !important;
  }
`

const Popup = (props: IPopup) => {
  const theme = useGetColourTheme('dark')
  return <PopupCont inverted={theme === 'dark'} {...props} />
}

interface IPopup {
  content: string | React.ReactNode
  disabled?: boolean
  flowing?: boolean
  hoverable?: boolean
  inverted?: boolean
  on?: 'hover' | 'click' | 'focus' | ('hover' | 'click' | 'focus')[]
  open?: boolean
  onClose?: (event: React.MouseEvent<HTMLElement>, data: PopupProps) => void
  onOpen?: (event: React.MouseEvent<HTMLElement>, data: PopupProps) => void
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center'
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge'
  title?: string
  trigger: React.ReactNode
  wide?: boolean | 'very'
}

Popup.defaultProps = {
  position: 'top center',
}

export { Popup }
