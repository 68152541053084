import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
	Accordion,
	AccordionTitle,
	AccordionContent,
	List,
	ListHeader,
	ListDescription,
	ActivableListItem,
	ListIcon,
	ListContent,
	Avatar,
	FlexContainer,
	Button,
	Icon,
	Divider,
	H3,
} from 'components/ui'
import { getDefaultFormsType, getPracticeType } from 'containers/settings'
import {
	SOAP_TYPES,
	CLIENT_TYPES,
	getFormScreenFromFormType,
} from 'constants/settings'
import { FormPreviewModal } from 'components/modals'
import { IState, IFormType, Dispatch } from 'interfaces'

import { FormTypeModal } from '../form-types/form-type-modal'
import { Form_T } from '../form-types/new-interfaces'
import { CLIENT_INFO_TAB_ID, INDIVIDUAL_SELECTED_TYPE } from 'constants/form'

interface Props {
	deletedFormTypes: Array<any>
	formTypeName: string
	formTypes: Array<Form_T>
	type: typeof CLIENT_TYPES | typeof SOAP_TYPES
}

const FormTypeSettings = (props: Props) => {
	const templates = useSelector((state: IState) => state.forms.templates)
	const practiceType = useSelector(getPracticeType)
	const fetchingDefaultFormType = useSelector(
		(state: IState) => state.settings.fetchingDefaultFormType,
	)

	const [formTypeModalState, setFormTypeModalState] = useState({
		open: false,
		index: -1,
		formType: {} as any,
	})

	const [formType, setformType] = useState({} as IFormType)

	const dispatch: Dispatch = useDispatch()
	const type = props.type
	const getDefaultFormsTypeCallback = () =>
		dispatch(getDefaultFormsType(practiceType, type))

	const [accordianIndex, setAccordianIndex] = useState(-1)

	const [formPreviewModalState, setFormPreviewModalState] = useState({
		open: false,
		json: [],
		name: '',
	})

	const openNewFormModal = () => {
		const screen = getFormScreenFromFormType(props.type)
		const formType = {
			screen,
			isDefault: false,
			isDeleted: false,
			selectedType: INDIVIDUAL_SELECTED_TYPE,
		} as Form_T

		openFormModal(-1, formType)
	}

	const openFormModal = (index: number, formType: Form_T) => {
		setformType(JSON.parse(JSON.stringify(formType))) // TODO - wtf, { ...formType } still passed by ref?!?!?
		setFormTypeModalState({
			open: true,
			index,
			formType,
		})
	}

	const closeFormModal = () => {
		setformType({} as IFormType)
		setFormTypeModalState({
			open: false,
			index: -1,
			formType: {},
		})
	}
	const renderFormTypeModal = () => {
		return (
			<FormTypeModal
				formType={formType as any}
				formTypeName={props.formTypeName}
				onClose={closeFormModal}
				open={formTypeModalState.open}
			/>
		)
	}

	const renderFormPreviewModal = () => {
		return (
			<FormPreviewModal
				canDelete={false}
				fileJson={formPreviewModalState.json}
				modalOpen={formPreviewModalState.open}
				name={formPreviewModalState.name}
				onCancel={() =>
					setFormPreviewModalState({
						...formPreviewModalState,
						open: false,
					})
				}
				readonly
			/>
		)
	}

	const listRenderer = (formTypes: Array<Form_T>) => {
		return (
			<List
				collapseOnMobile={false}
				items={formTypes}
				itemType='Forms'
				noItemsMessage='No Forms'
				renderer={(formType: Form_T, index: number) => (
					<ActivableListItem key={formType.id} onClick={() => {
					}}
					>
						<ListIcon
							onClick={() => {
								openFormModal(index, formType)
							}}
						>
							<Avatar size={'40px'}>
								<Icon
									name='file alternate outline'
									size='big'
									style={{ margin: 'auto' }}
								/>
							</Avatar>
						</ListIcon>

						<ListContent onClick={() => openFormModal(index, formType)}>
							<ListHeader>
								<div style={{ display: 'flex' }}>
									<H3>{formType.name}</H3>
									<div
										style={{
											borderRadius: '50%',
											width: '1em',
											height: '1em',
											backgroundColor: formType.appointmentColour,
											marginLeft: '1em',
											marginTop: '0.35em',
										}}
									/>
								</div>
							</ListHeader>

							<ListDescription>
								{`Forms: ${formType.formTabs
									?.find((f) => f.id === CLIENT_INFO_TAB_ID)
									?.forms?.map((sf) => {
										const template = templates[sf.formID] || {}
										return template.name
									})
									.join(', ')}`}
							</ListDescription>
						</ListContent>

						{/* <ListIcon
            // TODO - a completely different preview, showing tabs and what not
              onClick={() => {
                const selectedForms = formType.formTabs
                const json = []

                for (const selectedForm of selectedForms) {
                  json.push(templates[selectedForm.id])
                }

                if (json.length) {
                  setFormPreviewModalState({
                    ...formPreviewModalState,
                    open: true,
                    json: json,
                    name: formType.name,
                  })
                }
              }}
            >
              <Avatar asButton backgroundColour="none" size={'40px'}>
                <Icon name="eye" size="big" style={{ margin: 'auto' }} />
              </Avatar>
            </ListIcon> */}
					</ActivableListItem>
				)}
			/>
		)
	}

	return (
		<>
			{renderFormTypeModal()}
			{renderFormPreviewModal()}

			<FlexContainer>
				<Button onClick={openNewFormModal} primary size='small'>
					<Icon name='plus' /> {`New ${props.formTypeName} Type`}
				</Button>

				{!props.formTypes.find((c) => c.name === 'Default' && !c.isDeleted) && (
					<Button
						loading={fetchingDefaultFormType}
						onClick={() => getDefaultFormsTypeCallback()}
						secondary
						size='small'
					>
						Recreate Default
					</Button>
				)}
			</FlexContainer>
			<div style={{ padding: '2em 0' }}>
				{listRenderer(props.formTypes)}
				<Divider hidden />

				{!!props.deletedFormTypes.length && (
					<Accordion style={{ width: '100%' }}>
						<AccordionTitle
							active={accordianIndex === 0}
							index={0}
							onClick={(_: any, titleProps) => {
								const { index } = titleProps
								const newIndex = Number(accordianIndex === index ? -1 : index)
								setAccordianIndex(newIndex)
							}}
						>
							<Icon name='dropdown' />
							Deleted Forms
						</AccordionTitle>
						<AccordionContent active={accordianIndex === 0}>
							{listRenderer(props.deletedFormTypes)}
						</AccordionContent>
					</Accordion>
				)}
			</div>
		</>
	)
}

export default FormTypeSettings
