import React from 'react'
import { Link, useMatch } from 'react-router-dom'
import styled from 'styled-components'

import { IconButton, SImage as Image, Popup, LinkText } from 'components/ui'
import { LinkFont } from 'styles'
import { FeatureToggle } from 'containers/features'
import { AdminSetFeatures, FileUpload } from 'constants/features'

interface IMenu {
	collapsed?: boolean
	defaultSelectedKeys?: any
	vertical?: boolean
	position?: any
}

const Menu = styled.div<IMenu>`
  height: 100vh;
  width: ${(props) => (props.collapsed ? '4em' : '10em')};
  max-width: ${(props) => (props.collapsed ? '4em' : '10em')};
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.BackgroundColour};
`

const SideBarScreen = styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.BackgroundColour};
  color: ${(props) => props.theme.TextColour};
`

const getSelectedKey = (pathname: string) => {
	if (!pathname) {
		return ''
	}

	if (pathname.includes('clients') || pathname.includes('client')) {
		return 'clients'
	}

	if (pathname.includes('dashboard')) {
		return 'dashboard'
	}

	if (pathname.includes('account')) {
		return 'account'
	}

	if (pathname.includes('calendar')) {
		return 'calendar'
	}

	if (pathname.includes('files')) {
		return 'files'
	}

	if (pathname.includes('reports')) {
		return 'reports'
	}

	if (pathname.includes('admin')) {
		return 'admin'
	}

	return ''
}

interface IMenuItem {
	active?: boolean
}

const MenuItem = styled.div<IMenuItem>`
  display: flex;
  justify-content: start;
  align-items: center;

  background-color: ${(props) =>
          props.active ? props.theme.NavActiveColour : props.theme.BackgroundColour};

  max-height: 2em;
  padding: 1.5em;

  font-size: 1em;
`

const MenuText = styled(LinkText)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${LinkFont};
`

const MobileOnlyMenuItem = styled(MenuItem)<IMenu>`
  @media (min-width: 768px) {
    visibility: hidden;
  }
`

const SideBarRoutes = (props: ISideBarRoutes) => {
	const match = useMatch('*')

	const { onItemPress, collapsed } = props
	const activeKey = getSelectedKey(match.pathname)

	return (
		<Menu collapsed={collapsed} defaultSelectedKeys={['dashboard']} vertical>
			<FeatureToggle features={[AdminSetFeatures]}>
				<Link onClick={onItemPress} to='/admin'>
					<Popup
						content='Admin'
						disabled={!collapsed}
						position='right center'
						size='small'
						trigger={
							<MenuItem key='admin' active={activeKey === 'admin'}>
								<div style={{ minWidth: props.isMobile ? '2em' : null }}>
									<IconButton name='sitemap' />
								</div>
								{!collapsed ? (
									<MenuText>Admin</MenuText>
								) : (
									<MenuText>&nbsp;</MenuText>
								)}
							</MenuItem>
						}
					/>
				</Link>
			</FeatureToggle>

			<Link onClick={onItemPress} to='/account'>
				<Popup
					content='Account'
					disabled={!collapsed}
					position='right center'
					size='small'
					trigger={
						<MenuItem key='account' active={activeKey === 'account'}>
							<div style={{ minWidth: props.isMobile ? '2em' : null }}>
								{props.isMobile && props.user.photoURL ? (
									<Image
										size='mini'
										src={props.user.photoURL}
										style={{
											borderRadius: '50%',
											left: '-5px',
										}}
									/>
								) : (
									<IconButton name='user circle' />
								)}
							</div>
							{!collapsed ? (
								<MenuText>Account</MenuText>
							) : (
								<MenuText>&nbsp;</MenuText>
							)}
						</MenuItem>
					}
				/>
			</Link>

			<Link onClick={onItemPress} to='/dashboard'>
				<Popup
					content='Dashboard'
					disabled={!collapsed}
					position='right center'
					size='small'
					trigger={
						<MenuItem key='dashboard' active={activeKey === 'dashboard'}>
							<div>
								<IconButton name='th' />
							</div>
							{!collapsed ? (
								<MenuText>Dashboard</MenuText>
							) : (
								<MenuText>&nbsp;</MenuText>
							)}
						</MenuItem>
					}
				/>
			</Link>

			<Link onClick={onItemPress} to='/calendar'>
				<Popup
					content='Calendar'
					disabled={!collapsed}
					position='right center'
					size='small'
					trigger={
						<MenuItem key='calendar' active={activeKey === 'calendar'}>
							<div>
								<IconButton name='calendar alternate outline' />
							</div>
							{!collapsed ? (
								<MenuText>Calendar</MenuText>
							) : (
								<MenuText>&nbsp;</MenuText>
							)}
						</MenuItem>
					}
				/>
			</Link>

			<Link onClick={onItemPress} to='/clients'>
				<Popup
					content='Clients'
					disabled={!collapsed}
					position='right center'
					size='small'
					trigger={
						<MenuItem key='clients' active={activeKey === 'clients'}>
							<div>
								<IconButton name='users' />
							</div>
							{!collapsed ? (
								<MenuText>Clients</MenuText>
							) : (
								<MenuText>&nbsp;</MenuText>
							)}
						</MenuItem>
					}
				/>
			</Link>

			<FeatureToggle features={[FileUpload]}>
				<Link onClick={onItemPress} to='/files'>
					<Popup
						content='Files'
						disabled={!collapsed}
						position='right center'
						size='small'
						trigger={
							<MenuItem key='clients' active={activeKey === 'files'}>
								<div>
									<IconButton name='folder open' />
								</div>
								{!collapsed ? (
									<MenuText>Files</MenuText>
								) : (
									<MenuText>&nbsp;</MenuText>
								)}
							</MenuItem>
						}
					/>
				</Link>
			</FeatureToggle>

			<Link onClick={onItemPress} to='/reports'>
				<Popup
					content='Reports'
					disabled={!collapsed}
					position='right center'
					size='small'
					trigger={
						<MenuItem key='report' active={activeKey === 'reports'}>
							<div>
								<IconButton name='chart bar' />
							</div>
							{!collapsed ? (
								<MenuText>Reports</MenuText>
							) : (
								<MenuText>&nbsp;</MenuText>
							)}
						</MenuItem>
					}
				/>
			</Link>

			<Link onClick={onItemPress} style={{ marginTop: 'auto' }} to='/logout'>
				<Popup
					content='Clients'
					disabled={!collapsed}
					position='right center'
					size='small'
					trigger={
						<MobileOnlyMenuItem
							key='logout'
							active={false}
							position='right center'
							style={{ marginTop: 'auto' }}
						>
							<div>
								<IconButton name='sign-out' />
							</div>
							{!collapsed ? (
								<MenuText>Logout</MenuText>
							) : (
								<MenuText>&nbsp;</MenuText>
							)}
						</MobileOnlyMenuItem>
					}
				/>
			</Link>
		</Menu>
	)
}

const SideBar = ({
									 collapsed,
									 authenticated,
									 // routerLocation,
									 user,
								 }: ISideBar) =>
	authenticated && (
		<SideBarScreen key='sidebar-screen'>
			<SideBarRoutes
				collapsed={collapsed}
				// routerLocation={routerLocation}
				user={user}
			/>
		</SideBarScreen>
	)

interface ISideBar {
	authenticated: boolean
	collapsed: boolean
	// routerLocation: any
	user: any // TODO - IUser
}

interface ISideBarRoutes {
	collapsed?: boolean
	isMobile: boolean
	onItemPress: Function | any
	// routerLocation: any
	user: any // TODO - IUser
}

SideBarRoutes.defaultProps = {
	isMobile: false,
	onItemPress: () => {
	},
	user: {},
}

export default SideBar
export { SideBarRoutes }
