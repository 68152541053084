import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { DNDList, IconButton } from 'components/ui'
import { Dropdown, AddOrRemoveButton } from 'components/forms'
import { FormPreviewModal } from 'components/modals'
import { ISelectedFormType, IEvent } from 'interfaces'
import { MobileBreakPoint } from 'styles'
import { getFormTemplates$ } from 'containers/forms/selectors'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75em;
  border-radius: 5px;
  padding: 5px;

  :hover {
    background-color: ${(props) => props.theme.ListItemHoverColour};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 25em !important;

    @media (max-width: ${MobileBreakPoint}) {
      width: 10em !important;
    }
  }
`

interface Props {
  addForm: Function
  disabled: boolean
  isDefault: boolean
  name: string
  onFormTypeChange: Function
  onFormTypesSort: Function
  options: Array<any>
  removeForm: Function
  selectedForms: Array<ISelectedFormType> | any
  sortable: boolean
}

export const FormTypesList = (props: Props) => {
  const [showPreview, setShowPreview] = useState(false)
  const [fileJson, setFileJson] = useState({} as any)
  const templates = useSelector(getFormTemplates$)
  const isSelectedNotes = props.name === 'selectedNotes'

  const selectedForms =
    !props.selectedForms || props.selectedForms?.length <= 0
      ? [{}]
      : props.selectedForms

  return (
    <>
      <FormPreviewModal
        fileJson={[fileJson]}
        modalOpen={showPreview}
        name={fileJson.name}
        onCancel={() => setShowPreview(false)}
        readonly
      />

      <DNDList
        disabled={props.disabled || props.isDefault}
        items={selectedForms}
        renderItem={(form: ISelectedFormType, index: number) => {
          const disabled = form.disabled || props.disabled || props.isDefault

          return (
            <Row key={form.id}>
              <div>
                <StyledDropdown
                  disabled={disabled}
                  label=""
                  name={props.name}
                  onChange={(e: IEvent) => props.onFormTypeChange(e, index)}
                  options={props.options}
                  value={form.formID || ''}
                />
              </div>

              <ButtonContainer>
                {!props.disabled && !props.isDefault && (
                  <div>
                    <AddOrRemoveButton
                      add={() => props.addForm(props.name)}
                      isFirst={index + 1 === 1}
                      isRemove={
                        index + 1 !== props.selectedForms?.length &&
                        props.selectedForms?.length > 1
                      }
                      noRemove={disabled}
                      remove={() => props.removeForm(props.name, index)}
                    />
                  </div>
                )}

                <div>
                  {props.sortable &&
                    !props.disabled &&
                    !props.isDefault &&
                    props.selectedForms?.length > 1 && (
                      <IconButton name="bars" size="big" />
                    )}
                </div>

                {!isSelectedNotes && form.formID ? (
                  <div style={{ margin: 'auto' }}>
                    <IconButton
                      name="eye"
                      onClick={() => {
                        setShowPreview(true)
                        setFileJson(templates[form.formID])
                      }}
                      size="big"
                    />
                  </div>
                ) : (
                  <div style={{ width: '36.5px' }} />
                )}
              </ButtonContainer>
            </Row>
          )
        }}
        setItems={(items: Array<any>) =>
          props.onFormTypesSort(items, props.name)
        }
      />
    </>
  )
}
