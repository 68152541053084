import React from 'react'
import { connect } from 'react-redux'

import {
	Card,
	RadioGroup,
	RadioButton,
	Form,
	FormField,
	Label,
	Message,
	Text,
	Dropdown,
	Button,
} from 'components/ui'
import {
	onClientSortOrderChange,
	changeFileDriver,
	onSettingChanged,
} from '../redux/ducks'
import {
	saveSettings,
	getFileDocumentViewDriverSetting,
} from 'containers/settings'
import {
	CLIENT_SORT_ORDER,
	CLIENTS_PER_PAGE,
	PRACTICE_TYPE,
	DOCUMENT_VIEW_DRIVER,
	SHOW_APPOINTMENT_DATE_IN_HEADER,
	THEME_SETTING,
	documentViewDrivers,
} from 'constants/settings'
import Setting from './setting'
import {
	Dispatch,
	IEvent,
	IState,
	Setting as ISetting,
	SettingKey,
} from 'interfaces'
import { getSetting } from '../selectors'
import { clearLocalForageStores } from 'reducers/ducks'

interface Props {
	// onDarkModeChange: Function,
	changeFileDriver: ({ target }: IEvent) => void
	clientSortOrder: string
	clientsPerPage: string
	fileDriver: string
	onClientSortOrderChange: ({ target }: IEvent) => void
	onSettingChanged: (value: string, setting: string) => void
	practiceType: string
	saveSettings: (setting: ISetting) => Promise<any>
	showAppointmentDateInHeaderSettings: string
	themeSetting: string
}

class BasicSettings extends React.Component<Props> {
	state = {
		practiceChanged: false,
	}

	onSettingChange = (event: IEvent): Promise<any> =>
		this.props.saveSettings({
			setting: event.target.name as SettingKey,
			value: event.target.value,
		})

	resetLocalStorage = async () => {
		await clearLocalForageStores()
		window.location.reload()
	}

	render() {
		return (
			<>
				<Card title='Basic Settings'>
					{!this.props.practiceType && (
						<Message content='Select Practice Type' icon='warning' warning />
					)}
					<Form>
						<FormField>
							<Label>Client Sort Order</Label>
							<Setting patch={this.onSettingChange}>
								<RadioGroup
									defaultValue='firstName'
									name={CLIENT_SORT_ORDER}
									onChange={this.props.onClientSortOrderChange}
									value={this.props.clientSortOrder}
								>
									<RadioButton value='firstName'>First Name</RadioButton>
									<RadioButton value='lastName'>Last Name</RadioButton>
								</RadioGroup>
							</Setting>
						</FormField>

						<FormField>
							<Label>Number of Clients Per Page</Label>
							<Setting patch={this.onSettingChange}>
								<Dropdown
									name={CLIENTS_PER_PAGE}
									onChange={({ target }) =>
										this.props.onSettingChanged(target.value, CLIENTS_PER_PAGE)
									}
									options={[
										{
											id: 7,
											key: 7,
											value: '7',
											text: '7',
										},
										{
											id: 15,
											key: 15,
											value: '15',
											text: '15',
										},
										{
											id: 30,
											key: 30,
											value: '30',
											text: '30',
										},
										{
											id: 45,
											key: 45,
											value: '45',
											text: '45',
										},
										{
											id: 100,
											key: 100,
											value: '100',
											text: '100',
										},
									]}
									style={{ maxWidth: '14em' }}
									value={`${this.props.clientsPerPage}`}
								/>
							</Setting>
						</FormField>

						<FormField>
							<Label>Show Appointment Date in Header</Label>
							<Text>
								Whether to show the selected appointment date along side the
								Client&apos;s Name in the Header
							</Text>
							<Setting patch={this.onSettingChange}>
								<RadioGroup
									defaultValue='yes'
									name={SHOW_APPOINTMENT_DATE_IN_HEADER}
									onChange={({ target }) =>
										this.props.onSettingChanged(
											target.value,
											SHOW_APPOINTMENT_DATE_IN_HEADER,
										)
									}
									value={this.props.showAppointmentDateInHeaderSettings}
								>
									<RadioButton value='no'>No</RadioButton>
									<RadioButton value='yes'>Yes</RadioButton>
								</RadioGroup>
							</Setting>
						</FormField>

						<FormField>
							<Label>Colour Theme</Label>
							<Setting patch={this.onSettingChange}>
								<RadioGroup
									defaultValue='system'
									name={THEME_SETTING}
									onChange={({ target }) =>
										this.props.onSettingChanged(target.value, THEME_SETTING)
									}
									value={this.props.themeSetting}
								>
									<RadioButton value='light'>Light</RadioButton>
									<RadioButton value='dark'>Dark</RadioButton>
									<RadioButton value='system'>System Theme</RadioButton>
								</RadioGroup>
							</Setting>
						</FormField>
					</Form>
				</Card>

				<Card title='File Settings'>
					<Form>
						<Label>Files Document View</Label>
						<Text>
							Note: picking Google Docs or Office 365 to view files works by
							uploading your file to the Google or Microsoft Servers
						</Text>
						<Setting patch={this.onSettingChange}>
							<RadioGroup
								defaultValue={documentViewDrivers.default.value}
								name={DOCUMENT_VIEW_DRIVER}
								onChange={this.props.changeFileDriver}
								value={this.props.fileDriver}
							>
								<RadioButton value={documentViewDrivers.default.value}>
									{documentViewDrivers.default.text}
								</RadioButton>
								<RadioButton value={documentViewDrivers.google.value}>
									{documentViewDrivers.google.text}
								</RadioButton>
								<RadioButton value={documentViewDrivers.office.value}>
									{documentViewDrivers.office.text}
								</RadioButton>
							</RadioGroup>
						</Setting>
					</Form>
				</Card>

				<Card title='Misc'>
					<Button
						content='Clear local storage'
						onClick={this.resetLocalStorage}
					/>
				</Card>
			</>
		)
	}
}

const mapStateToProps = (state: IState) => ({
	clientSortOrder: state.settings[CLIENT_SORT_ORDER],
	practiceType: state.settings[PRACTICE_TYPE],
	fileDriver: getFileDocumentViewDriverSetting(state),
	clientsPerPage: getSetting(state, CLIENTS_PER_PAGE),
	showAppointmentDateInHeaderSettings:
		state.settings[SHOW_APPOINTMENT_DATE_IN_HEADER],
	themeSetting: state.settings[THEME_SETTING],
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onClientSortOrderChange: ({ target }: IEvent) =>
		dispatch(onClientSortOrderChange(target.value)),
	saveSettings: (setting: ISetting) => dispatch(saveSettings(setting)),
	changeFileDriver: ({ target }: IEvent) =>
		dispatch(changeFileDriver(target.value)),
	onSettingChanged: (value: string, setting: string) =>
		dispatch(onSettingChanged(value, setting)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicSettings)
