import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Form, Label, FormField, Divider, DNDList } from 'components/ui'
import { Input, Dropdown } from 'components/forms'
import { onFormTypeOrderChange } from 'containers/settings/redux/ducks'

import FormSelect from './form-select'
import { FAMILY_SELECTED_TYPE, INDIVIDUAL_SELECTED_TYPE } from 'constants/form'
import { Dispatch } from '../../../interfaces'

const NameFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: flex-start; */
  width: 100%;
`

const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ContLeft = styled.div`
  width: 35%;
  padding-right: 1em;

  @media (max-width: 760px) {
    width: 100%;
    padding-right: 0;
  }
`
const Cont = styled.div`
  width: 65%;
  padding-left: 1em;

  @media (max-width: 760px) {
    padding-left: 0;
    width: 100%;
  }
`

export const FormType = (props: FormTypeProps) => {
	const dispatch: Dispatch = useDispatch()

	const onFormTypeOrderChangeCallback = useCallback(
		(items) =>
			props.sortable &&
			dispatch(onFormTypeOrderChange(items, props.index, props.type)),
		[dispatch, props],
	)

	return (
		<NameFormContainer>
			<ContLeft>
				<Form>
					{!props.noLabelInput && props.hasText && (
						<Input
							disabled={props.disabled || props.isDefault}
							label={props.label ? `${props.label} Type Name` : ''}
							name='name'
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							placeholder={`${props.label} Type Name`}
							validationRules={{ required: true }}
							value={props.name}
						/>
					)}

					{props.hasSelectType && (
						<>
							<Dropdown
								disabled={props.disabled || props.isDefault}
								label='Type'
								onChange={({ target }) =>
									props.onChange('selectedType', target.value)
								}
								options={[
									{
										key: INDIVIDUAL_SELECTED_TYPE,
										id: INDIVIDUAL_SELECTED_TYPE,
										text: 'Individual',
										value: INDIVIDUAL_SELECTED_TYPE,
									},
									{
										key: FAMILY_SELECTED_TYPE,
										id: FAMILY_SELECTED_TYPE,
										text: 'Family',
										value: FAMILY_SELECTED_TYPE,
									},
								]}
								style={{ width: '10em' }}
								value={props.selectedType || INDIVIDUAL_SELECTED_TYPE}
							/>
							<Divider hidden />
						</>
					)}
				</Form>
			</ContLeft>

			<Cont>
				<Form>
					<FormField>
						<Label>{props.selectLabel}</Label>

						<FormsContainer>
							<DNDList
								items={props.selectedForms.sort((a, b) => a.order - b.order)}
								readonly={!props.sortable}
								renderItem={(form, index) => (
									<FormSelect
										key={form.id}
										disabled={props.disabled}
										form={form}
										index={index}
										isDefault={props.isDefault}
										onAddForm={props.onAddForm}
										onChange={props.onChange}
										onRemoveForm={props.onRemoveForm}
										options={props.options}
										selectedForms={props.selectedForms}
										sortable={props.sortable}
										template={props.template}
									/>
								)}
								setItems={(items) => onFormTypeOrderChangeCallback(items)}
							/>
						</FormsContainer>
					</FormField>
				</Form>
			</Cont>
		</NameFormContainer>
	)
}

interface FormTypeProps {
	disabled?: boolean
	hasSelectType?: boolean
	hasText?: boolean
	index?: number
	isDefault?: boolean
	label?: string
	name?: string
	noLabelInput?: boolean
	onAddForm?: Function | any
	onChange?: Function | any
	onRemoveForm?: Function | any
	options?: Array<any>
	selectedForms?: Array<any>
	selectedType?: string
	sortable?: boolean
	selectLabel?: string
	template?: any
	type?: string
}

FormType.defaultProps = {
	disabled: false,
	hasText: true,
	isDefault: false,
	noLabelInput: false,
	selectLabel: 'Forms',
	selectedForms: [],
	selectedType: INDIVIDUAL_SELECTED_TYPE,
	showDelete: true,
	sortable: true,
}
